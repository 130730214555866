var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "handle-test" }, [
    _c("p", { staticClass: "table-title" }, [_vm._v("Create New Location")]),
    _c(
      "div",
      { staticClass: "innner-container" },
      [
        _c(
          "v-row",
          [
            _c("v-col", { attrs: { md: "10" } }),
            _c(
              "v-col",
              { attrs: { md: "2" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "resetWO-btn",
                    on: { click: _vm.emptyButtons },
                  },
                  [
                    _c("i", { staticClass: "fas fa-redo" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-1" }, [_vm._v("Reset")]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [
                _c("v-autocomplete", {
                  ref: "lcn",
                  class: {
                    "is-invalid":
                      _vm.submitted4 && _vm.$v.branch.companyId.$error,
                  },
                  attrs: {
                    tabindex: "1",
                    outlined: "",
                    label: "Select Company",
                    items: _vm.companies,
                    "item-value": "id",
                    "item-text": "name",
                    "search-input": _vm.search,
                  },
                  on: {
                    "update:searchInput": function ($event) {
                      _vm.search = $event
                    },
                    "update:search-input": function ($event) {
                      _vm.search = $event
                    },
                  },
                  model: {
                    value: _vm.branch.companyId,
                    callback: function ($$v) {
                      _vm.$set(_vm.branch, "companyId", $$v)
                    },
                    expression: "branch.companyId",
                  },
                }),
                _vm.submitted4 && !_vm.$v.branch.companyId.required
                  ? _c("p", { staticClass: "invalid-feedback" }, [
                      _vm._v(" Company is Required "),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [
                _c("v-text-field", {
                  class: {
                    "is-invalid": _vm.submitted4 && _vm.$v.branch.name.$error,
                  },
                  attrs: { tabindex: "2", outlined: "", label: "Name" },
                  model: {
                    value: _vm.branch.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.branch, "name", $$v)
                    },
                    expression: "branch.name",
                  },
                }),
                _vm.submitted4 && !_vm.$v.branch.name.required
                  ? _c("p", { staticClass: "invalid-feedback" }, [
                      _vm._v(" Branch Name is required "),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [
                _c("v-text-field", {
                  staticClass: "mb-3",
                  class: {
                    "is-invalid":
                      _vm.submitted4 && _vm.$v.branch.address.street.$error,
                  },
                  attrs: { tabindex: "3", outlined: "", label: "Street" },
                  model: {
                    value: _vm.branch.address.street,
                    callback: function ($$v) {
                      _vm.$set(_vm.branch.address, "street", $$v)
                    },
                    expression: "branch.address.street",
                  },
                }),
                _vm.submitted4 && !_vm.$v.branch.address.street.required
                  ? _c("p", { staticClass: "invalid-feedback" }, [
                      _vm._v(" Street is Required "),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [
                _c("v-text-field", {
                  staticClass: "mb-3",
                  class: {
                    "is-invalid":
                      _vm.submitted4 && _vm.$v.branch.address.postalCode.$error,
                  },
                  attrs: {
                    tabindex: "5",
                    outlined: "",
                    label: "ZIP / Postal Code",
                  },
                  model: {
                    value: _vm.branch.address.postalCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.branch.address, "postalCode", $$v)
                    },
                    expression: "branch.address.postalCode",
                  },
                }),
                _vm.submitted4 && !_vm.$v.branch.address.postalCode.required
                  ? _c("p", { staticClass: "invalid-feedback" }, [
                      _vm._v(" Postal Code is Required "),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [
                _c("v-text-field", {
                  staticClass: "mb-3",
                  attrs: { tabindex: "6", outlined: "", label: "Details" },
                  model: {
                    value: _vm.branch.address.details,
                    callback: function ($$v) {
                      _vm.$set(_vm.branch.address, "details", $$v)
                    },
                    expression: "branch.address.details",
                  },
                }),
              ],
              1
            ),
            _c("v-col", { attrs: { cols: "12", md: "6" } }),
          ],
          1
        ),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [
                _c("v-autocomplete", {
                  staticClass: "mb-3",
                  class: {
                    "is-invalid":
                      _vm.submitted4 && _vm.$v.branch.address.countryId.$error,
                  },
                  attrs: {
                    tabindex: "7",
                    items: _vm.countries,
                    "item-text": "name",
                    "item-value": "id",
                    label: "Country",
                    outlined: "",
                  },
                  on: { change: _vm.getStates },
                  model: {
                    value: _vm.branch.address.countryId,
                    callback: function ($$v) {
                      _vm.$set(_vm.branch.address, "countryId", $$v)
                    },
                    expression: "branch.address.countryId",
                  },
                }),
                _vm.submitted4 && !_vm.$v.branch.address.countryId.required
                  ? _c("p", { staticClass: "invalid-feedback" }, [
                      _vm._v(" Country is Required "),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [
                _c("v-combobox", {
                  staticClass: "mb-3",
                  class: {
                    "is-invalid":
                      _vm.submitted4 && _vm.$v.branch.address.stateId.$error,
                  },
                  attrs: {
                    tabindex: "8",
                    items: _vm.states,
                    "item-text": "name",
                    "item-value": "id",
                    label: "State",
                    outlined: "",
                    "search-input": _vm.StateSearch,
                    "hide-selected": "",
                    "persistent-hint": "",
                  },
                  on: {
                    change: _vm.getCities,
                    "update:searchInput": function ($event) {
                      _vm.StateSearch = $event
                    },
                    "update:search-input": function ($event) {
                      _vm.StateSearch = $event
                    },
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.createState.apply(null, arguments)
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-data",
                      fn: function () {
                        return [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(' No results matching "'),
                                    _c("strong", [
                                      _vm._v(_vm._s(_vm.StateSearch)),
                                    ]),
                                    _vm._v('". Press '),
                                    _c("kbd", [_vm._v("enter")]),
                                    _vm._v(" to create a new one "),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.branch.address.stateId,
                    callback: function ($$v) {
                      _vm.$set(_vm.branch.address, "stateId", $$v)
                    },
                    expression: "branch.address.stateId",
                  },
                }),
                _vm.submitted4 && !_vm.$v.branch.address.stateId.required
                  ? _c("p", { staticClass: "invalid-feedback" }, [
                      _vm._v(" State is Required "),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [
                _c("v-combobox", {
                  staticClass: "mb-3",
                  class: {
                    "is-invalid":
                      _vm.submitted4 && _vm.$v.branch.address.cityId.$error,
                  },
                  attrs: {
                    tabindex: "9",
                    "item-text": "name",
                    "item-value": "id",
                    label: "City",
                    outlined: "",
                    items: _vm.cities,
                    "search-input": _vm.CitySearch,
                    "hide-selected": "",
                    "persistent-hint": "",
                  },
                  on: {
                    "update:searchInput": function ($event) {
                      _vm.CitySearch = $event
                    },
                    "update:search-input": function ($event) {
                      _vm.CitySearch = $event
                    },
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.createCity.apply(null, arguments)
                    },
                    change: _vm.getCities,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-data",
                      fn: function () {
                        return [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(' No results matching "'),
                                    _c("strong", [
                                      _vm._v(_vm._s(_vm.CitySearch)),
                                    ]),
                                    _vm._v('". Press '),
                                    _c("kbd", [_vm._v("enter")]),
                                    _vm._v(" to create a new one "),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.branch.address.cityId,
                    callback: function ($$v) {
                      _vm.$set(_vm.branch.address, "cityId", $$v)
                    },
                    expression: "branch.address.cityId",
                  },
                }),
                _vm.submitted4 && !_vm.$v.branch.address.cityId.required
                  ? _c("p", { staticClass: "invalid-feedback" }, [
                      _vm._v(" City is Required "),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c("v-col", { attrs: { cols: "12", md: "6" } }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "btn-container" },
          [
            _c(
              "v-btn",
              {
                staticClass: "create-order-btn",
                attrs: { tabindex: "10" },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.check()
                  },
                  click: _vm.check,
                },
              },
              [_vm._v("Create Location")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }