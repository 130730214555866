
import http from "../http"

export default {
    login(data) {
      
        var IsTwoFA = ""
        var TwoFACode = ""
        if (data.IsTwoFA) {
            IsTwoFA = data.IsTwoFA
            TwoFACode = data.TwoFACode
        }
        else {
            IsTwoFA = false
            TwoFACode = ""
        }
        return http.post('api/Authentication/Login', {
            "UserName": data.email,
            "Password": data.password,
            "isTwoFA": IsTwoFA,
            "twoFACode": TwoFACode

        }).then((res) => {
          
           //debugger; // eslint-disable-line no-debugger
            return res
        })
    },
    signUp(data) {
        var userType = localStorage.getItem('userType')
        if (userType) {
            if (userType == 5) {
                return http.post('api/Students/createProfile', data).then((res) => {
                    return res
                })
            }
            if (userType == 4) {
                return http.post('api/Guardians/createProfile', data).then((res) => {
                    return res
                })
            }

        }
    },

    // logout(){
    //     return http.post('Account/Logout').then((res)=>{
    //         return res
    //     })
    // }
}