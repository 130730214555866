<template>
  <div id="tech-list" class="handle-test">
    <div>
      <v-row>
        <v-col cols="12" md="10">
          <p class="table-title">Technician List</p>
        </v-col>
        <v-col md="1" cols="12">
          <!--  -->
          <v-btn
            v-if="can(14)"
            to="/technician/create"
            class="btn-creation wo-diff-style"
            >CREATE TECHNICIAN</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="pad0">
          <v-text-field
            solo
            label="Search Technicians "
            append-icon="mdi-magnify"
            v-model="techFilter.search"
            @keyup="getTechnicians(1)"
            ref="objet"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="8" class="pad0">
          <v-row>
            <v-col cols="12" md="4">
              <v-autocomplete
                :items="countries"
                :item-text="'name'"
                :item-value="'id'"
                label="Country"
                outlined
                v-model="techFilter.countryId"
                class="mb-3"
                @change="onchange()"
              >
              
              </v-autocomplete>
            
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                no-filter
                :items="states"
                :item-text="'name'"
                :item-value="'id'"
                label="State"
                solo
                @change="SearchInCity()"
                v-model="techFilter.stateId"
                :search-input.sync="searchState"
                class="mb-3"
                ref="stateFocus"
              >
              </v-autocomplete>
           
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                :items="cities"
                :item-text="'name'"
                :item-value="'id'"
                label="City"
                solo
                v-model="techFilter.cityId"
                :search-input.sync="searchCity"
                @change="getTechnicians(1)"
                class="mb-3"
                ref="cityFocus"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="pad0"> </v-col>

        <v-col cols="12" md="8" class="pad0">
          <v-row>
            <v-col cols="12" md="4" class="pad0">
              <v-text-field
                v-model="techFilter.zipCode"
                v-on:keyup.enter="getTechnicians(1)"
                solo
                label="Zip Code"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pad0">
              <v-autocomplete
                :items="locations"
                :item-value="'id'"
                :item-text="'name'"
                :search-input.sync="locationSearch"
                ref="locationRef"
                outlined
                label="Place Of Service"
                v-model="techFilter.branchId"
                @change="getTechnicians(1)"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" class="pad0">
              <v-autocomplete
                :items="skills"
                :item-value="'id'"
                :item-text="'name'"
                label="Select Skill"
                solo
                v-model="techFilter.skillId"
                @change="getTechnicians(1)"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="pad0"> </v-col>

        <v-col cols="12" md="8" class="pad0">
          <v-row>
            <v-col cols="12" md="4" class="pad0">
              <v-menu
                ref="menu2"
                v-model="menuStartDate"
                :close-on-content-click="false"
                :return-value.sync="SDFrom"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                   
                    label="Start Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"  hint="MM/DD/YYYY format"
                @blur="SDFrom = parseDate(SDFrom)"
                v-model="dateFormatted"
                    v-on="on"
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="SDFrom"
                  no-title
                  scrollable
                  @input="$refs.menu2.save(SDFrom)"
                  @change="getTechByDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuStartDate = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu2.save(SDFrom)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4" class="pad0">
              <v-menu
                ref="menu"
                v-model="menuEndDate"
                :close-on-content-click="false"
                :return-value.sync="SDTo"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                  hint="MM/DD/YYYY format"
                @blur="SDTo = parseDate(SDTo)"
                v-model="dateFormattedEnd"
              
                
                    label="End Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="SDTo"
                  no-title
                  scrollable
                  @input="$refs.menu.save(SDTo)"
                  @change="getTechByDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuEndDate = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(SDTo)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4" class="pad0">
              <v-select
                :items="techTypes"
                :item-value="'id'"
                :item-text="'name'"
                v-model="techFilter.type"
                @change="getTechnicians(1)"
                label="Technician Type"
                solo
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="pad0"> </v-col>

        <v-col cols="12" md="8" class="pad0">
          <v-row>
            <v-col cols="12" md="2">
              <!-- <p class="filter-label">Pay Rate</p> -->
            </v-col>
            <v-col md="1" id="filter-s-option">
              <!-- <v-select
                label=">"
                v-model="payRate.side1.symbol"
                :items="symbols"
                :item-value="'id'"
                :item-text="'name'"
                solo
                @change="handlePayRate"
              ></v-select> -->
            </v-col>
            <v-col md="1" id="filter-s-option">
              <!-- <v-text-field
                v-model="payRate.side1.rate"
                solo
                v-on:keyup.enter="handlePayRate"
              ></v-text-field> -->
            </v-col>
            <v-col md="1" id="filter-s-option">
              <!-- <v-select
                :item-value="'id'"
                :item-text="'name'"
                :items="operators"
                solo
                v-model="payRate.oPerator"
                @change="handlePayRate"
              ></v-select> -->
            </v-col>
            <v-col md="1" id="filter-s-option">
              <!-- <v-select
                :item-value="'id'"
                :item-text="'name'"
                :items="symbols"
                v-model="payRate.side2.symbol"
                solo
                @change="handlePayRate"
              ></v-select> -->
            </v-col>
            <v-col md="2" id="filter-s-option">
              <!-- <v-text-field
                v-model="payRate.side2.rate"
                solo
                v-on:keyup.enter="handlePayRate"
              ></v-text-field> -->
            </v-col>
            <v-col cols="12" md="4" class="pad0">
              <v-btn
                @click="resetFilter"
                class="btn-creation diff-style mt-1"
                color="#D92020"
                >Reset Filter</v-btn
              >
            </v-col>
          </v-row>
          <br/>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-data-table
        :loading="isLoaded"
        loading-text="Loading... Please wait"
        :headers="headers"
        :items="data"
        class="elevation-1"
        item-key="name"
        hide-default-footer
        :items-per-page="techFilter.pageSize"
        @update:options="handleOptionsUpdate"
      >
        <template v-slot:footer>
          <v-row class="mt-5 fit-width">
            <v-col md="2">
              <v-select
                :items="sizeOptions"
                v-model="techFilter.pageSize"
                :item-value="'id'"
                :item-text="'name'"
                @change="getTechnicians(1)"
                solo
              ></v-select>
            </v-col>
            <v-col md="5">
              <v-pagination
                v-model="techFilter.pageNumber"
                :length="totalPages"
                @input="getTechnicians(techFilter.pageNumber)"
                total-visible="6"
              ></v-pagination>
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.actions="{ item }">
        
          <v-icon
            v-if="can(15)"
            small
            class="mr-2 edit-icon"
            @click="editItem(item)"
          >
            far fa-edit
          </v-icon>
          <v-icon
            v-if="can(15)"
            class="delete-icon"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>

        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Technicians</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="700px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Edit Technician</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col md="12">
                        <v-text-field
                        tabindex="1"
                          outlined
                          v-model="technicianToShow.firstName"
                          :class="{
                            'is-invalid':
                              submitted && $v.technicianToShow.firstName.$error,
                          }"
                          label="First Name"
                        ></v-text-field>
                        <p
                          v-if="
                            submitted && !$v.technicianToShow.firstName.required
                          "
                          class="invalid-feedback mt-2"
                        >
                          First Name is Required
                        </p>
                        <v-text-field
                        tabindex="2"
                          outlined
                          v-model="technicianToShow.lastName"
                          label="Last Name"
                          :class="{
                            'is-invalid':
                              submitted && $v.technicianToShow.lastName.$error,
                          }"
                        ></v-text-field>
                        <p
                          v-if="
                            submitted && !$v.technicianToShow.lastName.required
                          "
                          class="invalid-feedback mt-2"
                        >
                          Name is Required
                        </p>

                        <v-select
                        tabindex="3"
                          :items="techTypes"
                          :item-value="'id'"
                          :item-text="'name'"
                          v-model="technicianToShow.type.id"
                          label="Technician Type"
                          :class="{
                            'is-invalid':
                              submitted && $v.technicianToShow.type.id.$error,
                          }"
                          solo
                        ></v-select>
                        <p
                          v-if="
                            submitted && !$v.technicianToShow.type.id.required
                          "
                          class="invalid-feedback ml-3"
                        >
                          Technician Type is Required
                        </p>
                        <v-autocomplete
                        tabindex="4"
                          :items="levels"
                          :item-text="'name'"
                          :item-value="'id'"
                          label="Level"
                          outlined
                          v-model="technicianToShow.level.id"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                        tabindex="5"
                          outlined
                          class="mb-3"
                          v-model="primary.phoneNumber"
                             @blur="formatPhoneNumber1()"
                          label="Primary Phone"
                        ></v-text-field>

                        <v-text-field
                        tabindex="7"
                          outlined
                          class="mb-3"
                          v-model="technicianToShow.email"
                          label="Email"
                          :class="{
                            'is-invalid':
                              submitted && $v.technicianToShow.email.$error,
                          }"
                        ></v-text-field>
                        <p
                          v-if="
                            submitted && !$v.technicianToShow.email.required
                          "
                          class="invalid-feedback"
                        >
                          Email is Required
                        </p>
                        <p
                          v-if="submitted && !$v.technicianToShow.email.email"
                          class="invalid-feedback"
                        >
                          Email is not valid
                        </p>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                        tabindex="6"
                          outlined
                          class="mb-3"
                          label="Secondary Phone"
                           @blur="formatPhoneNumber2()"
                          v-model="Secondry.phoneNumber"
                        ></v-text-field>
                        <v-text-field
                        tabindex="8"
                          outlined
                          class="mb-3"
                          label="Hourly Rate"
                          v-model="technicianToShow.hourlyRate"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-10"></v-divider>
                    <div>

                
   
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                          tabindex="9"
                            outlined
                            class="mb-3"
                            v-model="technicianToShow.address.street"
                            label="Street"
                           
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                          tabindex="10"
                            v-model="technicianToShow.address.postalCode"
                            outlined
                            class="mb-3"
                            label="ZIP / Postal Code"
                          
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                          tabindex="11"
                            v-model="technicianToShow.address.details"
                            outlined
                            class="mb-3"
                            label="Details"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6"></v-col>
                      </v-row>


                      <v-row>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                          tabindex="12"
                            :items="countries"
                            :item-text="'name'"
                            :item-value="'id'"
                            label="Country"
                            outlined
                            v-model="technicianToShow.address.country.id"
                            class="mb-3"
                            @change="Editonchange()"
                          >
                          </v-autocomplete>
                    
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                          tabindex="13"
                            :items="EditStates"
                            :item-text="'name'"
                            :item-value="'id'"
                            label="State"
                            outlined
                            @change="EditSearchInCity()"
                            :search-input.sync="searchEditState"
                            v-model="technicianToShow.address.state.id"
                            class="mb-3"
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                          tabindex="14"
                            :items="Editcities"
                            :item-text="'name'"
                            :item-value="'id'"
                            label="City"
                            outlined
                            :search-input.sync="searchEditCity"
                            class="mb-3"
                            v-model="technicianToShow.address.city.id"
                           
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6"></v-col>
                      </v-row>

                     
                      <!-- <v-row>
                        <v-col cols="12" md="6"> -->
                          <!-- <p
                           :class="{
                              'is-invalid':
                                submitted &&
                                $v.technicianToShow.address.street.$error,
                            }"
                            v-if="
                              submitted &&
                              !$v.technicianToShow.address.street.required
                            "
                            class="invalid-feedback"
                          >
                            Street is Required
                          </p> -->
                          <!-- <p
                           :class="{
                              'is-invalid':
                                submitted &&
                                $v.technicianToShow.address.city.id.$error,
                            }"
                            v-if="
                              submitted &&
                              !$v.technicianToShow.address.city.id.required
                            "
                            class="invalid-feedback"
                          >
                            City is Required
                          </p> -->
                        <!-- </v-col>
                        <v-col cols="12" md="6"> -->
                          <!-- <v-text-field
                            v-model="technicianToShow.address.buildingNumber"
                            outlined
                            class="mb-3"
                            label="Building Number"
                            
                          ></v-text-field> -->

                          <!-- <p
                          :class="{
                              'is-invalid':
                                submitted &&
                                $v.technicianToShow.address.buildingNumber
                                  .$error,
                            }"
                            v-if="
                              submitted &&
                              !$v.technicianToShow.address.buildingNumber
                                .required
                            "
                            class="invalid-feedback"
                          >
                            Building Number is Required
                          </p> -->
 
                          <!-- <p
                            :class="{
                              'is-invalid':
                                submitted &&
                                $v.technicianToShow.address.postalCode.$error,
                            }"
                            v-if="
                              submitted &&
                              !$v.technicianToShow.address.postalCode.required
                            "
                            class="invalid-feedback"
                          >
                            Postal Code is Required
                          </p> -->                        
                          <!-- 
                          <p
                            v-if="
                              submitted &&
                              !$v.technicianToShow.address.state.id.required
                            "
                            class="invalid-feedback"
                          >
                            State is Required
                          </p> -->
                        <!-- </v-col>
                      </v-row> -->
                    </div>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn tabindex="15" text class="cancel-update-btn" @keyup.enter="close()" @click="close"
                    >Cancel</v-btn
                  >

                  <v-btn tabindex="16" class="create-order-btn mr-1" @keyup.enter="save()" @click="save"
                    >Update</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            {{ snackText }}

            <template v-slot:action="{ attrs }">
              <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
            </template>
          </v-snackbar>
        </template>

        <template v-slot:item.address="{ item }">
          <p v-if="item.address">
            <span>{{ item.address.street }}</span>
            <!-- -
            <span>{{ item.address.buildingNumber }}</span> -->
            -
            <span v-if="item.address.city">{{ item.address.city.name }}</span>
            -
            <span v-if="item.address.state">{{
              item.address.state.target
            }}</span>
            -
            <span v-if="item.address.country">
              {{ item.address.country.name }}
            </span>
          </p>
        </template>

        <template v-slot:item.firstName="{ item }">
          <p>{{ item.firstName }} {{ item.lastName }}</p>
        </template>
        <template v-slot:item.technicianPhones="{ item }">
          <p v-for="(ph, index) in item.technicianPhones" :key="index">
            <span
              :class="{
                'is-Primary': ph.isPrimary,
              }"
              >{{ ph.phoneNumber }}</span
            >
          </p>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { dataService } from "@/services/data-service";
import store from "@/store";
//import { required } from "vuelidate/lib/validators";
import { required, email } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      dateFormattedEnd:null,
      dateFormatted: null,
      searchEditCityTerm: "",
      searchEditStateTerm: "",
      searchStateTerm: "",
      searchCityTerm: "",
      primary: {
        phoneNumber: "",
        isPrimary: true,
      },
      Secondry: {
        phoneNumber: "",
        isPrimary: false,
      },
      isLoaded: true,
      snack: false,
      snackColor: "",
      snackText: "",
      submitted: false,
      technicianToShow: {
        firstName: "",
        lastName: "",
        email: "",
        hourlyRate: 0,
        type: {
          id: null,
          name: "",
        },
        level: {
          id: null,
          name: "",
        },
        address: {
          street: "",
          buildingNumber: "",
          postalCode: "",
          details: "",
          state: {
            id: null,
          },
          country: {
            id: null,
          },
          city: {
            id: null,
          },
        },
        technicianPhones: [
          {
            phoneNumber: "",
            isPrimary: true,
          },
        ],
      },
      dialog: false,
      dialogDelete: false,
      locations: [],
      skills: [],
      EditStates: [],
      symbols: [
        {
          id: 1,
          name: ">",
        },
        {
          id: 2,
          name: "<",
        },
        {
          id: 3,
          name: "=",
        },
      ],
      operators: [
        {
          id: 1,
          name: "&",
        },
        {
          id: 2,
          name: "||",
        },
      ],
      techTypes: [
        {
          id: 1,
          name: "W-2",
        },
        {
          id: 2,
          name: "1099",
        },
        {
          id: 3,
          name: "TEMP",
        },
        {
          id: 4,
          name: "Vendor",
        },
      ],

      SDFrom: null,
      SDTo: null,
      menuStartDate: false,
      menuEndDate: false,
      countries: [],
      states: [],
      cities: [],
      Editcities: [],
      techFilter: {
        pageNumber: 1,
        pageSize: 10,
        search: null,
        countryId: 0,
        stateId: 0,
        cityId: 0,
        zipCode: null,
        branchId: 0,
        type: 0,
        skillId: 0,
        serviceDate: null,
        payRate: null,
        columnIndex: 0,
        isDec: false,
      },
      payRate: {
        oPerator: 1,
        side1: {
          rate: 0,
          symbol: 1,
        },
        side2: {
          rate: 0,
          symbol: 1,
        },
      },
      sizeOptions: [
        {
          id: 10,
          name: 10,
        },
        {
          id: 20,
          name: 20,
        },
        {
          id: 30,
          name: 30,
        },
        {
          id: 50,
          name: 50,
        },
        {
          id: 100,
          name: 100,
        },
      ],
      totalPages: 0,
      search: null,

      headers: [
        {
          text: "Name",
          value: "firstName",
        },
        { text: "Email", value: "email" },
        { text: "Phone ", value: "technicianPhones" },
        { text: "Address ", value: "address" },
        { text: "Hourly Rate ", value: "hourlyRate" },
        { text: "Type ", value: "type.name" },
        { text: "Actions", value: "actions" },
      ],
      data: [],
      locationTerm: "",
      levels: [],
    };
  },
  validations: {
    technicianToShow: {
      firstName: { required },
      lastName: { required },
      type: { id: { required } },
      email: { required, email },

      // address: {
      //   street: { required },
      //   buildingNumber: { required },
      //   postalCode: { required },

      //   city: { id: { required } },
      // },
    },
  },
  methods: {

    formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },



   formatPhoneNumber2() {
    
  var cleaned = ('' + this.Secondry.phoneNumber).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '');
   this.Secondry.phoneNumber= [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
 
},
formatPhoneNumber1() {
    
  var cleaned = ('' + this.primary.phoneNumber).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '');
   this.primary.phoneNumber= [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
 
},
    onchange() {
      this.techFilter.stateId = 0;

      this.SearchInStates();
    },
    SearchInStates() {
      this.cities = [];
      this.techFilter.cityId = 0;
      if (this.techFilter.countryId == null) this.techFilter.countryId = 0;
      if (this.searchStateTerm == null) this.searchStateTerm = "";
    
      dataService.getList(
        "api/States/search_by_name-countryId",
        {
          pageNumber: 1,
          pageSize: 1000,
          subString: this.searchStateTerm,
          countryId: this.techFilter.countryId,
        },
        (response) => {
          this.states = response.data.data.items;

          if (this.searchStateTerm) {
            this.$nextTick(() => this.$refs.stateFocus.focus());
          }
        }
      );
    },
    getCountries() {
      dataService.getAll("/api/Countries", (response) => {
        this.countries = response.data;
        this.isLoaded = false;
      });
    },
    SearchInCity() {
      if (this.searchCityTerm == null) this.searchCityTerm = "";
      if (this.techFilter.stateId == null) this.techFilter.stateId = 0;
    
      dataService.getList(
        "api/Cities/search_sateId_name",

        {
          pageNumber: 1,
          pageSize: 1000,
          subString: this.searchCityTerm,
          stateId: this.techFilter.stateId,
        },
        (response) => {
          this.cities = response.data.data.items;

          if (this.searchCityTerm) {
            this.$nextTick(() => this.$refs.stateFocus.focus());
          }
        }
      );
    },

    Editonchange() {
      this.technicianToShow.address.state.id = 0;

      this.EditSearchInStates();
    },
    EditSearchInStates() {
      this.Editcities = [];

      if (this.technicianToShow.address.country.id == null)
        this.technicianToShow.address.country.id = 0;
      if (this.searchEditStateTerm == null) this.searchEditStateTerm = "";
    
      dataService.getList(
        "api/States/search_by_name-countryId",
        {
          pageNumber: 1,
          pageSize: 1000,
          subString: this.searchEditStateTerm,
          countryId: this.technicianToShow.address.country.id,
        },
        (response) => {
          this.EditStates = response.data.data.items;
        }
      );
    },

    EditSearchInCity() {
      if (this.searchEditCityTerm == null) this.searchEditCityTerm = "";
      if (this.technicianToShow.address.state.id == null)
        this.technicianToShow.address.state.id = 0;
    
      dataService.getList(
        "api/Cities/search_sateId_name",

        {
          pageNumber: 1,
          pageSize: 1000,
          subString: this.searchEditCityTerm,
          stateId: this.technicianToShow.address.state.id,
        },
        (response) => {
          this.Editcities = response.data.data.items;
        }
      );
    },

    async loadLocations() {
      this.locations = await this.getAllLocations(this.locationTerm || "");
    },
    getAllLocations(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyLocations(value);
        }, 1000);
      } else {
        self.applyLocations(value);
      }
    },
    applyLocations(value) {
      dataService.getList(
        "api/Branches/search_by_name",
        {
          pageNumber: 1,
          pageSize: 1000,
          subString: value,
        },
        (response) => {
          this.locations = response.data.data.items;
          if (value) {
            this.$nextTick(() => this.$refs.locationRef.focus());
          }
        }
      );
    },

    getTechByDate() {
      if (this.SDFrom && !this.SDTo) {
        return;
      }
      if (!this.SDFrom) {
        store.commit("SHOW_MESSAGE", {
          text: "Please Select Start Date first",
          color: "error",
          timeout: 3000,
        });
        return;
      } else {
        this.techFilter.serviceDate = {
          from: this.SDFrom + "T00:00:00Z",
          to: this.SDTo + "T00:00:00Z",
        };
        this.getTechnicians(1);
      }
    },

    handleOptionsUpdate({ sortBy, sortDesc }) {
      if (sortBy.length > 0) {
        if (sortBy[0] == "firstName") this.techFilter.columnIndex = 0;
        if (sortBy[0] == "email") this.techFilter.columnIndex = 1;
        if (sortBy[0] == "hourlyRate") this.techFilter.columnIndex = 4;
        if (sortBy[0] == "type") this.techFilter.columnIndex = 4;

        this.techFilter.isDec = sortDesc[0];

        this.getTechnicians(1);
      }
    },
    handlePayRate() {
      if (
        !this.payRate.side1.symbol ||
        !this.payRate.side1.rate ||
        !this.payRate.side2.symbol ||
        !this.payRate.side2.rate ||
        !this.payRate.oPerator
      ) {
        store.commit("SHOW_MESSAGE", {
          text: "Please Complate Pay Rate Info. To Filter",
          color: "error",
          timeout: 3000,
        });
        return;
      } else {
        this.techFilter.payRate = this.payRate;
        this.getTechnicians(1);
      }
    },
    resetFilter() {
      (this.menuStartDate = false),
        (this.menuEndDate = false),
        (this.SDTo = null),
        (this.SDFrom = null),
        (this.techFilter = {
          pageNumber: 1,
          pageSize: 10,
          search: null,
          countryId: 0,
          stateId: 0,
          cityId: 0,
          zipCode: null,
          branchId: 0,
          type: 0,
          skillId: 0,
          serviceDate: null,
          payRate: null,
        });
      this.getTechnicians(1);
    },
    deleteItem(item) {
      this.editedIndex = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      dataService.postDelete(
        "api/Technicians/delete/" + this.editedIndex,
        (response) => {
          if (response.data.succeeded) {
            store.commit("SHOW_MESSAGE", {
              text: "Deleted Successfully",
              color: "success",
              timeout: 3000,
            });
            this.dialogDelete = false;
            this.getTechnicians(1);
          }
        }
      );
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    save() {
      

      this.technicianToShow.address.cityId =
        this.technicianToShow.address.city.id;
      if (this.technicianToShow.type)
        this.technicianToShow.typeId = this.technicianToShow.type.id;
      else {
        this.technicianToShow.typeId = null;
      }
      if (this.technicianToShow.level)
        this.technicianToShow.levelId = this.technicianToShow.level.id;
      else {
        this.technicianToShow.levelId = null;
      }
      if(this.technicianToShow.type.id==0){
        this.technicianToShow.type.id=null
      }

      this.submitted = true;
     
      this.$v.technicianToShow.$touch();
      if (this.$v.technicianToShow.$invalid) {
        return;
      } else {
        let tecPhones = [];
        tecPhones.push(this.primary);

        tecPhones.push(this.Secondry);
        this.technicianToShow.technicianPhones = tecPhones;
      
        dataService.update(
          "api/Technicians/update",
          { technician: this.technicianToShow },
          (response) => {
            if (response.data.succeeded) {
              this.dialog = false;
              this.snack = true;
              this.snackColor = "success";
              this.snackText = "Data Updated";
              this.getTechnicians(1);
            }
          }
        );
      }
    },

    applyFunction() {
      dataService.getList(
        "api/Technicians/get-all",
        this.techFilter,
        (response) => {
          this.isLoaded = false;
          this.totalPages = response.data.data.totalPages;
          this.data = response.data.data.items;
       
          this.$nextTick(() => this.$refs.objet.focus());
        }
      );
    },
    editItem(item) {
    
      this.dialog = true;
      this.getLevels();
      this.editedIndex = item.id;
      dataService.getAll("api/Technicians/" + this.editedIndex, (response) => {
      
        this.technicianToShow = response.data;
        
        if (this.technicianToShow.address == null) {
          this.technicianToShow.address = {
            id: 0,
            street: "",
            buildingNumber: "",
            postalCode: "",
            details: "",

            country: { id: 0 },
            city: { id: 0 },
            state: { id: 0 },
          };
        }

        if (this.technicianToShow.address.country == null) {
          this.technicianToShow.address.country = {
            id: 0,
          };
        }
        if (this.technicianToShow.address.state == null) {
          this.technicianToShow.address.state = {
            id: 0,
          };
        }
        if (this.technicianToShow.address.city == null) {
          this.technicianToShow.address.city = {
            id: 0,
          };
        }
        if (this.technicianToShow.type == null) {
          this.technicianToShow.type = {
            id: 0,
          };
        }
        if (this.technicianToShow.level == null) {
          this.technicianToShow.level = {
            id: 0,
          };
        }
        if (this.technicianToShow.technicianPhones.length == 0) {
          this.technicianToShow.technicianPhones = [
            {
              phoneNumber: "",
              isPrimary: true,
            },
            {
              phoneNumber: "",
              isPrimary: false,
            },
          ];
        }
        this.technicianToShow.technicianPhones.forEach((element) => {
          if (element.isPrimary) {
            this.primary = element;
          } else {
            this.Secondry = element;
          }
        });

        this.EditSearchInStates();
        this.EditSearchInCity();
      });
    },

    getSkills() {
      dataService.getAll("api/ProblemTypes", (response) => {
        this.skills = response.data;
      });
    },
    getLevels() {
      dataService.getAll("api/Enums/levels", (response) => {
        this.levels = response.data;
      });
    },
    getTechnicians(size) {
      this.isLoaded = true;
      var self = this;
      self.techFilter.pageNumber = size;
      if (self.techFilter.search) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyFunction();
        }, 1000);
      } else {
        self.applyFunction();
      }
    },
  },
  watch: {
    SDFrom () {
        this.dateFormatted = this.formatDate(this.SDFrom)
      },
      SDTo(){
        this.dateFormattedEnd = this.formatDate(this.SDTo)
      }
    },
  computed: {
    computedStartDateFormatted () {
        return this.formatDate(this.SDFrom)
      },
      computedEndDateFormatted () {
        return this.formatDate(this.SDTo)
      },

    locationSearch: {
      get() {
        return this.locationTerm;
      },

      set(searchInput) {
        if (this.locationTerm !== searchInput) {
          this.locationTerm = searchInput;
          this.loadLocations();
        }
      },
    },
    searchState: {
      get() {
        return this.searchStateTerm;
      },

      set(searchInput) {
      

        if (this.searchStateTerm !== searchInput) {
          this.searchStateTerm = searchInput;
          this.SearchInStates();
        }
      },
    },
    searchCity: {
      get() {
        return this.searchCityTerm;
      },

      set(searchInput) {
      

        if (this.searchCityTerm !== searchInput) {
          this.searchCityTerm = searchInput;
          this.SearchInCity();
        }
      },
    },
    searchEditState: {
      get() {
        return this.searchEditStateTerm;
      },

      set(searchInput) {
      

        if (this.searchEditStateTerm !== searchInput) {
          this.searchEditStateTerm = searchInput;
          this.EditSearchInStates();
        }
      },
    },
    searchEditCity: {
      get() {
        return this.searchEditCityTerm;
      },

      set(searchInput) {
      

        if (this.searchEditCityTerm !== searchInput) {
          this.searchEditCityTerm = searchInput;
          this.EditSearchInCity();
        }
      },
    },
  },
  created() {
    this.loadLocations();
  },
  mounted() {
    this.getTechnicians(1);
    this.getCountries();
    this.getSkills();
  },
};
</script>