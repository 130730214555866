var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "handle-test", attrs: { id: "report-timsheet" } },
    [
      _c(
        "v-row",
        { staticStyle: { "margin-bottom": "1%", "margin-top": "-18px" } },
        [
          _vm.showInvoiceComponent
            ? _c("v-icon", { on: { click: _vm.change } }, [
                _vm._v("fa fa-arrow-left"),
              ])
            : _vm._e(),
          _c("br"),
        ],
        1
      ),
      !_vm.showInvoiceComponent
        ? _c(
            "div",
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12", md: "10" } }, [
                    _c("p", { staticClass: "table-title" }, [
                      _vm._v("Ready To Invoice"),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "Gml-3", attrs: { md: "1", cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-creation wo-diff-style",
                          on: { click: _vm.exportData },
                        },
                        [
                          _c("img", {
                            staticStyle: { "padding-right": "10%" },
                            attrs: {
                              src: require("@/assets/images/export.png"),
                            },
                          }),
                          _vm._v(" Export File "),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pad0", attrs: { cols: "12", md: "4" } },
                    [
                      _c("v-text-field", {
                        ref: "objet",
                        attrs: {
                          solo: "",
                          label: "Search ",
                          "append-icon": "mdi-magnify",
                        },
                        on: {
                          keyup: function ($event) {
                            return _vm.getData()
                          },
                        },
                        model: {
                          value: _vm.requestBody.subString,
                          callback: function ($$v) {
                            _vm.$set(_vm.requestBody, "subString", $$v)
                          },
                          expression: "requestBody.subString",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pad0", attrs: { cols: "12", md: "8" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  multiple: "",
                                  outlined: "",
                                  label: "Select Clients",
                                  items: _vm.clients,
                                  "item-text": "name",
                                  "item-value": "id",
                                },
                                on: { change: _vm.handleClientFilter },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "v-chip",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "input-value": data.selected,
                                                  close: "",
                                                },
                                                on: {
                                                  click: data.select,
                                                  "click:close": function (
                                                    $event
                                                  ) {
                                                    return _vm.removeClient(
                                                      data.item.id
                                                    )
                                                  },
                                                },
                                              },
                                              "v-chip",
                                              data.attrs,
                                              false
                                            ),
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(data.item.name) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2151565335
                                ),
                                model: {
                                  value: _vm.clientIds,
                                  callback: function ($$v) {
                                    _vm.clientIds = $$v
                                  },
                                  expression: "clientIds",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c("v-autocomplete", {
                                ref: "accountSearch",
                                attrs: {
                                  items: _vm.accounts,
                                  "item-text": "name",
                                  "item-value": "id",
                                  multiple: "",
                                  outlined: "",
                                  label: "Select Accounts",
                                  "search-input": _vm.accountsearch,
                                },
                                on: {
                                  "update:searchInput": function ($event) {
                                    _vm.accountsearch = $event
                                  },
                                  "update:search-input": function ($event) {
                                    _vm.accountsearch = $event
                                  },
                                  change: _vm.handleAccountFilter,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "v-chip",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "input-value": data.selected,
                                                  close: "",
                                                },
                                                on: {
                                                  click: data.select,
                                                  "click:close": function (
                                                    $event
                                                  ) {
                                                    return _vm.removeAccount(
                                                      data.item.id
                                                    )
                                                  },
                                                },
                                              },
                                              "v-chip",
                                              data.attrs,
                                              false
                                            ),
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(data.item.name) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4026349103
                                ),
                                model: {
                                  value: _vm.accountIds,
                                  callback: function ($$v) {
                                    _vm.accountIds = $$v
                                  },
                                  expression: "accountIds",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c("v-autocomplete", {
                                ref: "locationSearch",
                                attrs: {
                                  items: _vm.locations,
                                  "item-text": "name",
                                  "item-value": "id",
                                  multiple: "",
                                  outlined: "",
                                  label: "Select Locations",
                                  "search-input": _vm.locationSearch,
                                },
                                on: {
                                  change: _vm.handleLocationFilter,
                                  "update:searchInput": function ($event) {
                                    _vm.locationSearch = $event
                                  },
                                  "update:search-input": function ($event) {
                                    _vm.locationSearch = $event
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "v-chip",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "input-value": data.selected,
                                                  close: "",
                                                },
                                                on: {
                                                  click: data.select,
                                                  "click:close": function (
                                                    $event
                                                  ) {
                                                    return _vm.removeLocation(
                                                      data.item.id
                                                    )
                                                  },
                                                },
                                              },
                                              "v-chip",
                                              data.attrs,
                                              false
                                            ),
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(data.item.name) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1279539091
                                ),
                                model: {
                                  value: _vm.locationIds,
                                  callback: function ($$v) {
                                    _vm.locationIds = $$v
                                  },
                                  expression: "locationIds",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", {
                    staticClass: "pad0",
                    attrs: { cols: "12", md: "4" },
                  }),
                  _c(
                    "v-col",
                    { staticClass: "pad0", attrs: { cols: "12", md: "8" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "menu",
                                  attrs: {
                                    "close-on-content-click": false,
                                    "return-value": _vm.SDFrom,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "min-width": "300",
                                  },
                                  on: {
                                    "update:returnValue": function ($event) {
                                      _vm.SDFrom = $event
                                    },
                                    "update:return-value": function ($event) {
                                      _vm.SDFrom = $event
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      hint: "MM/DD/YYYY format",
                                                      label:
                                                        "TimeSheet Approval / Start Date",
                                                      "prepend-inner-icon":
                                                        "mdi-calendar",
                                                      readonly: "",
                                                      outlined: "",
                                                    },
                                                    on: {
                                                      blur: function ($event) {
                                                        _vm.SDFrom =
                                                          _vm.parseDate(
                                                            _vm.SDFrom
                                                          )
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.dateFormatted,
                                                      callback: function ($$v) {
                                                        _vm.dateFormatted = $$v
                                                      },
                                                      expression:
                                                        "dateFormatted",
                                                    },
                                                  },
                                                  "v-text-field",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4241957135
                                  ),
                                  model: {
                                    value: _vm.menuStartDate,
                                    callback: function ($$v) {
                                      _vm.menuStartDate = $$v
                                    },
                                    expression: "menuStartDate",
                                  },
                                },
                                [
                                  _c(
                                    "v-date-picker",
                                    {
                                      attrs: { "no-title": "", scrollable: "" },
                                      on: {
                                        input: function ($event) {
                                          return _vm.$refs.menu.save(_vm.SDFrom)
                                        },
                                        change: _vm.getTimesheetByDate,
                                      },
                                      model: {
                                        value: _vm.SDFrom,
                                        callback: function ($$v) {
                                          _vm.SDFrom = $$v
                                        },
                                        expression: "SDFrom",
                                      },
                                    },
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              _vm.menuStartDate = false
                                            },
                                          },
                                        },
                                        [_vm._v(" Cancel ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.menu.save(
                                                _vm.SDFrom
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" OK ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "menu2",
                                  attrs: {
                                    "close-on-content-click": false,
                                    "return-value": _vm.SDTo,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "min-width": "300",
                                  },
                                  on: {
                                    "update:returnValue": function ($event) {
                                      _vm.SDTo = $event
                                    },
                                    "update:return-value": function ($event) {
                                      _vm.SDTo = $event
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      hint: "MM/DD/YYYY format",
                                                      label:
                                                        "TimeSheet Approval / End Date",
                                                      "prepend-inner-icon":
                                                        "mdi-calendar",
                                                      readonly: "",
                                                      outlined: "",
                                                    },
                                                    on: {
                                                      blur: function ($event) {
                                                        _vm.SDTo =
                                                          _vm.parseDate(
                                                            _vm.SDTo
                                                          )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.dateFormattedEnd,
                                                      callback: function ($$v) {
                                                        _vm.dateFormattedEnd =
                                                          $$v
                                                      },
                                                      expression:
                                                        "dateFormattedEnd",
                                                    },
                                                  },
                                                  "v-text-field",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3886202639
                                  ),
                                  model: {
                                    value: _vm.menuEndDate,
                                    callback: function ($$v) {
                                      _vm.menuEndDate = $$v
                                    },
                                    expression: "menuEndDate",
                                  },
                                },
                                [
                                  _c(
                                    "v-date-picker",
                                    {
                                      attrs: { "no-title": "", scrollable: "" },
                                      on: {
                                        input: function ($event) {
                                          return _vm.$refs.menu2.save(_vm.SDTo)
                                        },
                                        change: _vm.getTimesheetByDate,
                                      },
                                      model: {
                                        value: _vm.SDTo,
                                        callback: function ($$v) {
                                          _vm.SDTo = $$v
                                        },
                                        expression: "SDTo",
                                      },
                                    },
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              _vm.menuEndDate = false
                                            },
                                          },
                                        },
                                        [_vm._v(" Cancel ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.menu2.save(
                                                _vm.SDTo
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" OK ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-creation diff-style mt-1",
                                  attrs: { color: "#D92020" },
                                  on: { click: _vm.reset },
                                },
                                [_vm._v("Reset Filter")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.childSelected.length > 0
                ? _c(
                    "v-btn",
                    {
                      staticClass: "float btn-creation wo-diff-style",
                      on: { click: _vm.sendToInvoice },
                    },
                    [_vm._v("send To Invoice")]
                  )
                : _vm._e(),
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  loading: _vm.isLoaded,
                  "loading-text": "Loading... Please wait",
                  headers: _vm.headers,
                  items: _vm.data,
                  "item-key": "id",
                  "server-items-length": _vm.totalLength,
                  "show-expand": "",
                  "show-select": "",
                  expanded: _vm.expanded,
                  "hide-default-footer": "",
                },
                on: {
                  input: function ($event) {
                    return _vm.enterSelect($event)
                  },
                  "update:options": _vm.handleSorting,
                  "update:expanded": function ($event) {
                    _vm.expanded = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.billRate",
                      fn: function ({ item }) {
                        return [
                          _c("p", [
                            _vm._v(" " + _vm._s("$ " + item.billRate) + " "),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "item.orderId",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "label",
                            {
                              staticStyle: {
                                color: "#1976d2cf",
                                "text-decoration": "underline",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.navgate(item.id)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.orderId))]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.totalHours",
                      fn: function ({ item }) {
                        return [
                          _c("p", [
                            _vm._v(" " + _vm._s(item.totalHours + " hr") + " "),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "item.totalBillRate",
                      fn: function ({ item }) {
                        return [
                          _c("p", [
                            _vm._v(
                              " " + _vm._s("$ " + item.totalBillRate) + " "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "item.totalTechniciansPayRates",
                      fn: function ({ item }) {
                        return [
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s("$ " + item.totalTechniciansPayRates) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "item.totalProfit",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "p",
                            {
                              style:
                                item.totalProfit < 0
                                  ? "color:#aa5e68; font-weight: bold;"
                                  : "color:#b2d4b3;font-weight: bold;",
                            },
                            [
                              _vm._v(
                                " " + _vm._s("$ " + item.totalProfit) + " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.paymentTypeName",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "p",
                            {
                              style:
                                item.paymentTypeName == "Hourly"
                                  ? "color:#b2d4b3 ;font-weight: bold;"
                                  : "color:#aa5e68;font-weight: bold;",
                            },
                            [_vm._v(" " + _vm._s(item.paymentTypeName) + " ")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.totalExpense",
                      fn: function ({ item }) {
                        return [
                          _c("p", [
                            _vm._v(
                              " " + _vm._s("$ " + item.totalExpense) + " "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "expanded-item",
                      fn: function ({ headers, item }) {
                        return [
                          _c(
                            "td",
                            {
                              staticClass: "px-0 py-2",
                              attrs: { colspan: headers.length },
                            },
                            [
                              _c("v-data-table", {
                                staticClass: "elevation-1",
                                attrs: {
                                  "sort-by": [
                                    "id",
                                    "tecWoId",
                                    "technicianName",
                                    "approvalDate",
                                    "payRate",
                                    "totalHours",
                                    "totalPayRate",
                                    "billHourlyRate",
                                    "totalExpenseHoures",
                                    "totalBillaleHours",
                                    "totalExpenses",
                                  ],
                                  "server-items-length": item.timeSheets.length,
                                  "sort-desc": [false, true],
                                  "selectable-key": "item",
                                  headers: _vm.timeSheetHeaders,
                                  items: item.timeSheets,
                                  "show-select": "",
                                  "item-key": "id",
                                  "hide-default-footer": "",
                                  colspan: 7,
                                },
                                on: { "click:row": _vm.rowClick },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item.approvalDate",
                                      fn: function ({ item }) {
                                        return [
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm
                                                    .convertUTCDateToLocalDate(
                                                      new Date(
                                                        item.approvalDate
                                                      )
                                                    )
                                                    .toLocaleString()
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                    {
                                      key: "item.payRate",
                                      fn: function ({ item }) {
                                        return [
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s("$" + item.payRate) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                    {
                                      key: "item.totalPayRate",
                                      fn: function ({ item }) {
                                        return [
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  "$" + item.totalPayRate
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                    {
                                      key: "item.billHourlyRate",
                                      fn: function ({ item }) {
                                        return [
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  "$" + item.billHourlyRate
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                    {
                                      key: "item.totalExpenses",
                                      fn: function ({ item }) {
                                        return [
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  "$" + item.totalExpenses
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                    {
                                      key: "item.totalBillaleHours",
                                      fn: function ({ item }) {
                                        return [
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.totalBillaleHours + " hr"
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                    {
                                      key: "item.totalExpenseHoures",
                                      fn: function ({ item }) {
                                        return [
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.totalExpenseHoures +
                                                    " hr"
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                    {
                                      key: "item.totalHours",
                                      fn: function ({ item }) {
                                        return [
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.totalHours + " hr"
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.childSelected,
                                  callback: function ($$v) {
                                    _vm.childSelected = $$v
                                  },
                                  expression: "childSelected",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "v-row",
                            { staticClass: "mt-5 fit-width" },
                            [
                              _c(
                                "v-col",
                                { attrs: { md: "2" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.sizeOptions,
                                      "item-value": "id",
                                      "item-text": "name",
                                      solo: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.resetPageNumber()
                                      },
                                    },
                                    model: {
                                      value: _vm.requestBody.pageSize,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.requestBody,
                                          "pageSize",
                                          $$v
                                        )
                                      },
                                      expression: "requestBody.pageSize",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { md: "5" } },
                                [
                                  _c("v-pagination", {
                                    attrs: {
                                      length: _vm.totalPages,
                                      "total-visible": "6",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.getData()
                                      },
                                    },
                                    model: {
                                      value: _vm.requestBody.pageNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.requestBody,
                                          "pageNumber",
                                          $$v
                                        )
                                      },
                                      expression: "requestBody.pageNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3240176745
                ),
                model: {
                  value: _vm.parentSelected,
                  callback: function ($$v) {
                    _vm.parentSelected = $$v
                  },
                  expression: "parentSelected",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showInvoiceComponent
        ? _c("CreateInvoice", { attrs: { childSelected: _vm.childSelected } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }