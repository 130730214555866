var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "handle-test" },
    [
      _c(
        "v-overlay",
        { attrs: { value: _vm.loading } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { md: "10" } }, [
            _c("p", { staticClass: "table-title" }, [
              _vm._v("Create New Work Order"),
            ]),
          ]),
          _c(
            "v-col",
            { attrs: { md: "2" } },
            [
              _c(
                "v-btn",
                { staticClass: "resetWO-btn", on: { click: _vm.emptyButtons } },
                [
                  _c("i", { staticClass: "fas fa-redo" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-1" }, [_vm._v("Reset")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "outer-container" }, [
        _c(
          "div",
          { staticClass: "innner-container" },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { md: "6", cols: "12" } },
                  [
                    _c("v-autocomplete", {
                      staticClass: "mb-3",
                      class: {
                        "is-invalid":
                          _vm.submitted && _vm.$v.workOrder.clientId.$error,
                      },
                      attrs: {
                        tabindex: "1",
                        items: _vm.clients,
                        "item-text": "name",
                        "item-value": "id",
                        label: "Select Client",
                        outlined: "",
                      },
                      on: { change: _vm.getAccounts },
                      model: {
                        value: _vm.workOrder.clientId,
                        callback: function ($$v) {
                          _vm.$set(_vm.workOrder, "clientId", $$v)
                        },
                        expression: "workOrder.clientId",
                      },
                    }),
                    _c(
                      "v-row",
                      {
                        staticClass: "mrgn-ngtv",
                        class: {
                          "invalid-ngtv":
                            _vm.submitted && _vm.$v.workOrder.clientId.$error,
                        },
                      },
                      [
                        _c("v-col", { attrs: { md: "9" } }, [
                          _vm.submitted && !_vm.$v.workOrder.clientId.required
                            ? _c("p", { staticClass: "invalid-feedback" }, [
                                _vm._v(" Client is Required "),
                              ])
                            : _vm._e(),
                        ]),
                        _c("v-col", { attrs: { md: "3" } }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { md: "6", cols: "12" } },
                  [
                    _c("v-autocomplete", {
                      ref: "accountsearch",
                      staticClass: "mb-3",
                      class: {
                        "is-invalid":
                          _vm.submitted && _vm.$v.workOrder.accountId.$error,
                      },
                      attrs: {
                        tabindex: "2",
                        items: _vm.accounts,
                        "item-text": "name",
                        "item-value": "id",
                        label: "Select Account",
                        outlined: "",
                        "search-input": _vm.accountsearch,
                      },
                      on: {
                        change: _vm.getCompanies,
                        "update:searchInput": function ($event) {
                          _vm.accountsearch = $event
                        },
                        "update:search-input": function ($event) {
                          _vm.accountsearch = $event
                        },
                      },
                      model: {
                        value: _vm.workOrder.accountId,
                        callback: function ($$v) {
                          _vm.$set(_vm.workOrder, "accountId", $$v)
                        },
                        expression: "workOrder.accountId",
                      },
                    }),
                    _c(
                      "v-row",
                      {
                        staticClass: "invalid-ngtv",
                        class: {
                          "mrgn-ngtv":
                            _vm.submitted && _vm.$v.workOrder.accountId.$error,
                        },
                      },
                      [
                        _c("v-col", { attrs: { md: "9" } }, [
                          _vm.submitted && !_vm.$v.workOrder.accountId.required
                            ? _c("p", { staticClass: "invalid-feedback" }, [
                                _vm._v(" Account is Required "),
                              ])
                            : _vm._e(),
                        ]),
                        _c("v-col", { attrs: { md: "3" } }, [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "v-dialog",
                                {
                                  attrs: { width: "500" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.workOrder
                                                          .clientId != null,
                                                      expression:
                                                        "workOrder.clientId != null",
                                                    },
                                                  ],
                                                  staticClass: "on-fly-btn",
                                                  attrs: {
                                                    color: "#162450",
                                                    dark: "",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v(" Add New ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.dialog1,
                                    callback: function ($$v) {
                                      _vm.dialog1 = $$v
                                    },
                                    expression: "dialog1",
                                  },
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass: "text-h5 grey lighten-2",
                                        },
                                        [_vm._v(" Create Account ")]
                                      ),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mt-3",
                                            class: {
                                              "is-invalid":
                                                _vm.submitted1 &&
                                                _vm.$v.accountName.$error,
                                            },
                                            attrs: {
                                              outlined: "",
                                              label: "Name",
                                            },
                                            model: {
                                              value: _vm.accountName,
                                              callback: function ($$v) {
                                                _vm.accountName = $$v
                                              },
                                              expression: "accountName",
                                            },
                                          }),
                                          _vm.submitted1 &&
                                          !_vm.$v.accountName.required
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "invalid-feedback mt-3",
                                                },
                                                [
                                                  _vm._v(
                                                    " Account Name is required "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                text: "",
                                              },
                                              on: { click: _vm.createAccount },
                                            },
                                            [_vm._v(" Create ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c("v-autocomplete", {
                      ref: "companyysearch",
                      staticClass: "mb-3 mt-5",
                      class: {
                        "is-invalid mt-9":
                          _vm.submitted && _vm.$v.workOrder.companyId.$error,
                      },
                      attrs: {
                        tabindex: "3",
                        items: _vm.companies,
                        "item-text": "name",
                        "item-value": "id",
                        label: "Select Company",
                        outlined: "",
                        "search-input": _vm.companysearch,
                      },
                      on: {
                        change: _vm.getBranches,
                        "update:searchInput": function ($event) {
                          _vm.companysearch = $event
                        },
                        "update:search-input": function ($event) {
                          _vm.companysearch = $event
                        },
                      },
                      model: {
                        value: _vm.workOrder.companyId,
                        callback: function ($$v) {
                          _vm.$set(_vm.workOrder, "companyId", $$v)
                        },
                        expression: "workOrder.companyId",
                      },
                    }),
                    _c(
                      "v-row",
                      {
                        staticClass: "invalid-ngtv",
                        class: {
                          "mrgn-ngtv":
                            _vm.submitted && _vm.$v.workOrder.companyId.$error,
                        },
                      },
                      [
                        _c("v-col", { attrs: { md: "9" } }, [
                          _vm.submitted && !_vm.$v.workOrder.companyId.required
                            ? _c("p", { staticClass: "invalid-feedback" }, [
                                _vm._v(" Company is Required "),
                              ])
                            : _vm._e(),
                        ]),
                        _c("v-col", { attrs: { md: "3" } }, [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "v-dialog",
                                {
                                  attrs: { width: "700" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.workOrder
                                                          .accountId != null,
                                                      expression:
                                                        "workOrder.accountId != null",
                                                    },
                                                  ],
                                                  staticClass: "on-fly-btn",
                                                  attrs: {
                                                    color: "#162450",
                                                    dark: "",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v(" Add New ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.dialog3,
                                    callback: function ($$v) {
                                      _vm.dialog3 = $$v
                                    },
                                    expression: "dialog3",
                                  },
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass: "text-h5 grey lighten-2",
                                        },
                                        [_vm._v(" Create Company ")]
                                      ),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mt-3",
                                            class: {
                                              "is-invalid":
                                                _vm.submitted3 &&
                                                _vm.$v.company.name.$error,
                                            },
                                            attrs: {
                                              outlined: "",
                                              label: "Name",
                                            },
                                            model: {
                                              value: _vm.company.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.company,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "company.name",
                                            },
                                          }),
                                          _vm.submitted3 &&
                                          !_vm.$v.company.name.required
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "invalid-feedback",
                                                },
                                                [
                                                  _vm._v(
                                                    " Company Name is required "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    class: {
                                                      "is-invalid":
                                                        _vm.submitted3 &&
                                                        _vm.$v.company
                                                          .primaryPhone.$error,
                                                    },
                                                    attrs: {
                                                      outlined: "",
                                                      label: "Primary Phone",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.company
                                                          .primaryPhone,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.company,
                                                          "primaryPhone",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "company.primaryPhone",
                                                    },
                                                  }),
                                                  _vm.submitted3 &&
                                                  !_vm.$v.company.primaryPhone
                                                    .required
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "invalid-feedback",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Primary Phone is required "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      outlined: "",
                                                      label: "Secondary Phone",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.company
                                                          .secondaryPhone,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.company,
                                                          "secondaryPhone",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "company.secondaryPhone",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                text: "",
                                              },
                                              on: { click: _vm.createCompany },
                                            },
                                            [_vm._v(" Create ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c("v-autocomplete", {
                      ref: "locationSearch",
                      staticClass: "mb-3 mt-5",
                      class: {
                        "is-invalid":
                          _vm.submitted && _vm.$v.workOrder.branchId.$error,
                      },
                      attrs: {
                        tabindex: "4",
                        items: _vm.branches,
                        "item-text": "name",
                        "item-value": "id",
                        label: "Select Location",
                        outlined: "",
                        "search-input": _vm.locationSearch,
                      },
                      on: {
                        change: _vm.getBranchContacts,
                        "update:searchInput": function ($event) {
                          _vm.locationSearch = $event
                        },
                        "update:search-input": function ($event) {
                          _vm.locationSearch = $event
                        },
                      },
                      model: {
                        value: _vm.workOrder.branchId,
                        callback: function ($$v) {
                          _vm.$set(_vm.workOrder, "branchId", $$v)
                        },
                        expression: "workOrder.branchId",
                      },
                    }),
                    _c(
                      "v-row",
                      {
                        staticClass: "invalid-ngtv",
                        class: {
                          "mrgn-ngtv":
                            _vm.submitted && _vm.$v.workOrder.branchId.$error,
                        },
                      },
                      [
                        _c("v-col", { attrs: { md: "9" } }, [
                          _vm.submitted && !_vm.$v.workOrder.branchId.required
                            ? _c("p", { staticClass: "invalid-feedback" }, [
                                _vm._v(" Location is Required "),
                              ])
                            : _vm._e(),
                        ]),
                        _c("v-col", { attrs: { md: "3" } }, [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "v-dialog",
                                {
                                  attrs: { width: "900" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.workOrder
                                                          .companyId != null,
                                                      expression:
                                                        "workOrder.companyId != null",
                                                    },
                                                  ],
                                                  staticClass: "on-fly-btn",
                                                  attrs: {
                                                    color: "#162450",
                                                    dark: "",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v(" Add New ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.dialog5,
                                    callback: function ($$v) {
                                      _vm.dialog5 = $$v
                                    },
                                    expression: "dialog5",
                                  },
                                },
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "px-5" },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass: "text-h5 grey lighten-2",
                                        },
                                        [_vm._v(" Create Location ")]
                                      ),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-3 mt-3",
                                            class: {
                                              "is-invalid":
                                                _vm.submitted4 &&
                                                _vm.$v.branch.name.$error,
                                            },
                                            attrs: {
                                              outlined: "",
                                              label: "Name",
                                            },
                                            model: {
                                              value: _vm.branch.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.branch,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "branch.name",
                                            },
                                          }),
                                          _vm.submitted4 &&
                                          !_vm.$v.branch.name.required
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "invalid-feedback",
                                                },
                                                [
                                                  _vm._v(
                                                    " Branch Name is required "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "mb-3",
                                                class: {
                                                  "is-invalid":
                                                    _vm.submitted4 &&
                                                    _vm.$v.branch.address.street
                                                      .$error,
                                                },
                                                attrs: {
                                                  outlined: "",
                                                  label: "Street",
                                                },
                                                model: {
                                                  value:
                                                    _vm.branch.address.street,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.branch.address,
                                                      "street",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "branch.address.street",
                                                },
                                              }),
                                              _vm.submitted4 &&
                                              !_vm.$v.branch.address.street
                                                .required
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "invalid-feedback",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Street is Required "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "mb-3",
                                                class: {
                                                  "is-invalid":
                                                    _vm.submitted4 &&
                                                    _vm.$v.branch.address
                                                      .postalCode.$error,
                                                },
                                                attrs: {
                                                  outlined: "",
                                                  label: "ZIP / Postal Code",
                                                },
                                                model: {
                                                  value:
                                                    _vm.branch.address
                                                      .postalCode,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.branch.address,
                                                      "postalCode",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "branch.address.postalCode",
                                                },
                                              }),
                                              _vm.submitted4 &&
                                              !_vm.$v.branch.address.postalCode
                                                .required
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "invalid-feedback",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Postal Code is Required "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "mb-3",
                                                attrs: {
                                                  outlined: "",
                                                  label: "Details",
                                                },
                                                model: {
                                                  value:
                                                    _vm.branch.address.details,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.branch.address,
                                                      "details",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "branch.address.details",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-col", {
                                            attrs: { cols: "12", md: "6" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("v-autocomplete", {
                                                staticClass: "mb-3",
                                                class: {
                                                  "is-invalid":
                                                    _vm.submitted4 &&
                                                    _vm.$v.branch.address
                                                      .countryId.$error,
                                                },
                                                attrs: {
                                                  items: _vm.countries,
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  label: "Country",
                                                  outlined: "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.getStates(
                                                      _vm.branch.address
                                                        .countryId
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.branch.address
                                                      .countryId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.branch.address,
                                                      "countryId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "branch.address.countryId",
                                                },
                                              }),
                                              _vm.submitted4 &&
                                              !_vm.$v.branch.address.countryId
                                                .required
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "invalid-feedback",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Country is Required "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("v-autocomplete", {
                                                staticClass: "mb-3",
                                                class: {
                                                  "is-invalid":
                                                    _vm.submitted4 &&
                                                    _vm.$v.branch.address
                                                      .stateId.$error,
                                                },
                                                attrs: {
                                                  items: _vm.states,
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  label: "State",
                                                  outlined: "",
                                                  "search-input":
                                                    _vm.StateSearch,
                                                  "hide-selected": "",
                                                  "persistent-hint": "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.getCities(
                                                      _vm.branch.address.stateId
                                                    )
                                                  },
                                                  "update:searchInput":
                                                    function ($event) {
                                                      _vm.StateSearch = $event
                                                    },
                                                  "update:search-input":
                                                    function ($event) {
                                                      _vm.StateSearch = $event
                                                    },
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    return _vm.createState.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "no-data",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "v-list-item",
                                                          [
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  [
                                                                    _vm._v(
                                                                      ' No results matching "'
                                                                    ),
                                                                    _c(
                                                                      "strong",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.StateSearch
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      '". Press '
                                                                    ),
                                                                    _c("kbd", [
                                                                      _vm._v(
                                                                        "enter"
                                                                      ),
                                                                    ]),
                                                                    _vm._v(
                                                                      " to create a new one "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ]),
                                                model: {
                                                  value:
                                                    _vm.branch.address.stateId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.branch.address,
                                                      "stateId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "branch.address.stateId",
                                                },
                                              }),
                                              _vm.submitted4 &&
                                              !_vm.$v.branch.address.stateId
                                                .required
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "invalid-feedback",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " State is Required "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("v-combobox", {
                                                staticClass: "mb-3",
                                                class: {
                                                  "is-invalid":
                                                    _vm.submitted4 &&
                                                    _vm.$v.branch.address.cityId
                                                      .$error,
                                                },
                                                attrs: {
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  label: "City",
                                                  outlined: "",
                                                  items: _vm.cities,
                                                  "search-input":
                                                    _vm.CitySearch,
                                                  "hide-selected": "",
                                                  "persistent-hint": "",
                                                },
                                                on: {
                                                  "update:searchInput":
                                                    function ($event) {
                                                      _vm.CitySearch = $event
                                                    },
                                                  "update:search-input":
                                                    function ($event) {
                                                      _vm.CitySearch = $event
                                                    },
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    return _vm.createCity.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                  change: function ($event) {
                                                    return _vm.getCities(
                                                      _vm.contact.address
                                                        .stateId
                                                    )
                                                  },
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "no-data",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "v-list-item",
                                                          [
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  [
                                                                    _vm._v(
                                                                      ' No results matching "'
                                                                    ),
                                                                    _c(
                                                                      "strong",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.CitySearch
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      '". Press '
                                                                    ),
                                                                    _c("kbd", [
                                                                      _vm._v(
                                                                        "enter"
                                                                      ),
                                                                    ]),
                                                                    _vm._v(
                                                                      " to create a new one "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ]),
                                                model: {
                                                  value:
                                                    _vm.branch.address.cityId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.branch.address,
                                                      "cityId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "branch.address.cityId",
                                                },
                                              }),
                                              _vm.submitted4 &&
                                              !_vm.$v.branch.address.cityId
                                                .required
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "invalid-feedback",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " City is Required "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c("v-col", {
                                            attrs: { cols: "12", md: "6" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                text: "",
                                              },
                                              on: { click: _vm.createBranch },
                                            },
                                            [_vm._v(" Create ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("br"),
            _c("p", { staticClass: "table-title mt-5" }, [
              _vm._v("contact Information :"),
            ]),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  [
                    _c("v-autocomplete", {
                      ref: "onsitecontact",
                      staticClass: "mb-3",
                      class: {
                        "is-invalid":
                          _vm.submitted &&
                          _vm.$v.workOrder.onSitePrimaryContact.$error,
                      },
                      attrs: {
                        tabindex: "5",
                        items: _vm.onSiteContacts,
                        "item-text": (item) => item.firstName,
                        "item-value": "id",
                        label: "On-Site Primary Contact",
                        outlined: "",
                      },
                      model: {
                        value: _vm.workOrder.onSitePrimaryContact,
                        callback: function ($$v) {
                          _vm.$set(_vm.workOrder, "onSitePrimaryContact", $$v)
                        },
                        expression: "workOrder.onSitePrimaryContact",
                      },
                    }),
                    _c(
                      "v-row",
                      {
                        staticClass: "invalid-ngtv",
                        class: {
                          "mrgn-ngtv":
                            _vm.submitted &&
                            _vm.$v.workOrder.onSitePrimaryContact.$error,
                        },
                      },
                      [
                        _c("v-col", { attrs: { md: "9" } }, [
                          _vm.submitted &&
                          !_vm.$v.workOrder.onSitePrimaryContact.required
                            ? _c("p", { staticClass: "invalid-feedback" }, [
                                _vm._v(" Primary Contact is Required "),
                              ])
                            : _vm._e(),
                        ]),
                        _c("v-col", { attrs: { md: "3" } }, [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "v-dialog",
                                {
                                  attrs: { width: "700" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.workOrder
                                                          .branchId != null,
                                                      expression:
                                                        "workOrder.branchId != null",
                                                    },
                                                  ],
                                                  staticClass: "on-fly-btn",
                                                  attrs: {
                                                    color: "#162450",
                                                    dark: "",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v(" Add New ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.onSitePrimContactDialog,
                                    callback: function ($$v) {
                                      _vm.onSitePrimContactDialog = $$v
                                    },
                                    expression: "onSitePrimContactDialog",
                                  },
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass: "text-h5 grey lighten-2",
                                        },
                                        [
                                          _vm._v(
                                            " Create On-Site Primary contact "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "12",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "mb-3",
                                                    class: {
                                                      "is-invalid":
                                                        _vm.primaryContactSubmitted &&
                                                        _vm.$v.contact.firstName
                                                          .$error,
                                                    },
                                                    attrs: {
                                                      outlined: "",
                                                      label: "Full Name",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contact.firstName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contact,
                                                          "firstName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contact.firstName",
                                                    },
                                                  }),
                                                  _vm.primaryContactSubmitted &&
                                                  !_vm.$v.contact.firstName
                                                    .required
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "invalid-feedback",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Full Name is Required "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "mb-3",
                                                    class: {
                                                      "is-invalid":
                                                        _vm.primaryContactSubmitted &&
                                                        _vm.c.phoneNumber
                                                          .$error,
                                                    },
                                                    attrs: {
                                                      outlined: "",
                                                      set: (_vm.c =
                                                        _vm.$v.contact.contactPhones.$each[0]),
                                                      label: "PrimaryPhone",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contact
                                                          .contactPhones[0]
                                                          .phoneNumber,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contact
                                                            .contactPhones[0],
                                                          "phoneNumber",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contact.contactPhones[0].phoneNumber",
                                                    },
                                                  }),
                                                  _vm.primaryContactSubmitted &&
                                                  !_vm.c.phoneNumber.required
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "invalid-feedback",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Primary Phone is Required "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "mb-3",
                                                    attrs: {
                                                      outlined: "",
                                                      label: "SecondaryPhone",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contactSecondaryPhone,
                                                      callback: function ($$v) {
                                                        _vm.contactSecondaryPhone =
                                                          $$v
                                                      },
                                                      expression:
                                                        "contactSecondaryPhone",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "mb-3",
                                                    class: {
                                                      "is-invalid":
                                                        _vm.primaryContactSubmitted &&
                                                        _vm.$v.contact.email
                                                          .$error,
                                                    },
                                                    attrs: {
                                                      outlined: "",
                                                      label: "Email",
                                                    },
                                                    model: {
                                                      value: _vm.contact.email,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contact,
                                                          "email",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contact.email",
                                                    },
                                                  }),
                                                  _vm.primaryContactSubmitted &&
                                                  !_vm.$v.contact.email.required
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "invalid-feedback",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Email is Required "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.primaryContactSubmitted &&
                                                  !_vm.$v.contact.email.email
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "invalid-feedback",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Email is not valid "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c("v-col", {
                                                attrs: { cols: "12", md: "6" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-divider", {
                                            staticClass: "mb-10",
                                          }),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          outlined: "",
                                                          label: "Street",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .street,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "street",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.street",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          outlined: "",
                                                          label:
                                                            "ZIP / Postal Code",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .postalCode,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "postalCode",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.postalCode",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          outlined: "",
                                                          label: "Details",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .details,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "details",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.details",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-col", {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-autocomplete", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          items: _vm.countries,
                                                          "item-text": "name",
                                                          "item-value": "id",
                                                          label: "Country",
                                                          outlined: "",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.getStates(
                                                              _vm.contact
                                                                .address
                                                                .countryId
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .countryId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "countryId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.countryId",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-autocomplete", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          items: _vm.states,
                                                          "item-text": "name",
                                                          "item-value": "id",
                                                          label: "State",
                                                          outlined: "",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.getCities(
                                                              _vm.contact
                                                                .address.stateId
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .stateId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "stateId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.stateId",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-autocomplete", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          items: _vm.cities,
                                                          "item-text": "name",
                                                          "item-value": "id",
                                                          label: "City",
                                                          outlined: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .cityId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "cityId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.cityId",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-col", {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.createContact(1)
                                                },
                                              },
                                            },
                                            [_vm._v(" Create ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  [
                    _c("v-autocomplete", {
                      staticClass: "mb-3",
                      class: {
                        "is-invalid":
                          _vm.submitted &&
                          _vm.$v.workOrder.onSiteSecondaryContact.$error,
                      },
                      attrs: {
                        tabindex: "6",
                        items: _vm.onSiteContacts,
                        "item-text": (item) => item.firstName,
                        "item-value": "id",
                        label: "On-Site Secondary Contact",
                        outlined: "",
                      },
                      model: {
                        value: _vm.workOrder.onSiteSecondaryContact,
                        callback: function ($$v) {
                          _vm.$set(_vm.workOrder, "onSiteSecondaryContact", $$v)
                        },
                        expression: "workOrder.onSiteSecondaryContact",
                      },
                    }),
                    _c(
                      "v-row",
                      {
                        staticClass: "invalid-ngtv",
                        class: {
                          "mrgn-ngtv":
                            _vm.submitted &&
                            _vm.$v.workOrder.onSiteSecondaryContact.$error,
                        },
                      },
                      [
                        _c("v-col", { attrs: { md: "9" } }, [
                          _vm.submitted &&
                          !_vm.$v.workOrder.onSiteSecondaryContact.required
                            ? _c("p", { staticClass: "invalid-feedback" }, [
                                _vm._v(" Secondary Contact is Required "),
                              ])
                            : _vm._e(),
                        ]),
                        _c("v-col", { attrs: { md: "3" } }, [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "v-dialog",
                                {
                                  attrs: { width: "700" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.workOrder
                                                          .branchId != null,
                                                      expression:
                                                        "workOrder.branchId != null",
                                                    },
                                                  ],
                                                  staticClass: "on-fly-btn",
                                                  attrs: {
                                                    color: "#162450",
                                                    dark: "",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v(" Add New ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.onSiteSecContactDialog,
                                    callback: function ($$v) {
                                      _vm.onSiteSecContactDialog = $$v
                                    },
                                    expression: "onSiteSecContactDialog",
                                  },
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass: "text-h5 grey lighten-2",
                                        },
                                        [
                                          _vm._v(
                                            " Create On-Site Secondary contact "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "12",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "mb-3",
                                                    class: {
                                                      "is-invalid":
                                                        _vm.secondaryContactSubmitted &&
                                                        _vm.$v.contact.firstName
                                                          .$error,
                                                    },
                                                    attrs: {
                                                      outlined: "",
                                                      label: "Full Name",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contact.firstName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contact,
                                                          "firstName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contact.firstName",
                                                    },
                                                  }),
                                                  _vm.secondaryContactSubmitted &&
                                                  !_vm.$v.contact.firstName
                                                    .required
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "invalid-feedback",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Full Name is Required "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "mb-3",
                                                    class: {
                                                      "is-invalid":
                                                        _vm.secondaryContactSubmitted &&
                                                        _vm.c.phoneNumber
                                                          .$error,
                                                    },
                                                    attrs: {
                                                      outlined: "",
                                                      set: (_vm.c =
                                                        _vm.$v.contact.contactPhones.$each[0]),
                                                      label: "PrimaryPhone",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contact
                                                          .contactPhones[0]
                                                          .phoneNumber,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contact
                                                            .contactPhones[0],
                                                          "phoneNumber",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contact.contactPhones[0].phoneNumber",
                                                    },
                                                  }),
                                                  _vm.secondaryContactSubmitted &&
                                                  !_vm.c.phoneNumber.required
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "invalid-feedback",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Primary Phone is Required "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "mb-3",
                                                    attrs: {
                                                      outlined: "",
                                                      label: "SecondaryPhone",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contactSecondaryPhone,
                                                      callback: function ($$v) {
                                                        _vm.contactSecondaryPhone =
                                                          $$v
                                                      },
                                                      expression:
                                                        "contactSecondaryPhone",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "mb-3",
                                                    class: {
                                                      "is-invalid":
                                                        _vm.secondaryContactSubmitted &&
                                                        _vm.$v.contact.email
                                                          .$error,
                                                    },
                                                    attrs: {
                                                      outlined: "",
                                                      label: "Email",
                                                    },
                                                    model: {
                                                      value: _vm.contact.email,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contact,
                                                          "email",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contact.email",
                                                    },
                                                  }),
                                                  _vm.secondaryContactSubmitted &&
                                                  !_vm.$v.contact.email.required
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "invalid-feedback",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Email is Required "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.secondaryContactSubmitted &&
                                                  !_vm.$v.contact.email.email
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "invalid-feedback",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Email is not valid "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c("v-col", {
                                                attrs: { cols: "12", md: "6" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-divider", {
                                            staticClass: "mb-10",
                                          }),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          outlined: "",
                                                          label: "Street",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .street,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "street",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.street",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          outlined: "",
                                                          label:
                                                            "ZIP / Postal Code",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .postalCode,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "postalCode",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.postalCode",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          outlined: "",
                                                          label: "Details",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .details,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "details",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.details",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-col", {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-autocomplete", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          items: _vm.countries,
                                                          "item-text": "name",
                                                          "item-value": "id",
                                                          label: "Country",
                                                          outlined: "",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.getStates(
                                                              _vm.contact
                                                                .address
                                                                .countryId
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .countryId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "countryId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.countryId",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-autocomplete", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          items: _vm.states,
                                                          "item-text": "name",
                                                          "item-value": "id",
                                                          label: "State",
                                                          outlined: "",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.getCities(
                                                              _vm.contact
                                                                .address.stateId
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .stateId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "stateId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.stateId",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-autocomplete", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          items: _vm.cities,
                                                          "item-text": "name",
                                                          "item-value": "id",
                                                          label: "City",
                                                          outlined: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .cityId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "cityId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.cityId",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-col", {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.createContact(2)
                                                },
                                              },
                                            },
                                            [_vm._v(" Create ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  [
                    _c("v-autocomplete", {
                      staticClass: "mb-3",
                      class: {
                        "is-invalid":
                          _vm.submitted &&
                          _vm.$v.workOrder.technicalContact.$error,
                      },
                      attrs: {
                        tabindex: "7",
                        items: _vm.techContacts,
                        "item-text": (item) => item.firstName,
                        "item-value": "id",
                        label: "Technical Contact",
                        outlined: "",
                      },
                      model: {
                        value: _vm.workOrder.technicalContact,
                        callback: function ($$v) {
                          _vm.$set(_vm.workOrder, "technicalContact", $$v)
                        },
                        expression: "workOrder.technicalContact",
                      },
                    }),
                    _c(
                      "v-row",
                      {
                        staticClass: "invalid-ngtv",
                        class: {
                          "mrgn-ngtv":
                            _vm.submitted &&
                            _vm.$v.workOrder.technicalContact.$error,
                        },
                      },
                      [
                        _c("v-col", { attrs: { md: "9" } }, [
                          _vm.submitted &&
                          !_vm.$v.workOrder.technicalContact.required
                            ? _c("p", { staticClass: "invalid-feedback" }, [
                                _vm._v(" Technical Contact is Required "),
                              ])
                            : _vm._e(),
                        ]),
                        _c("v-col", { attrs: { md: "3" } }, [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "v-dialog",
                                {
                                  attrs: { width: "700" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.workOrder
                                                          .branchId != null,
                                                      expression:
                                                        "workOrder.branchId != null",
                                                    },
                                                  ],
                                                  staticClass: "on-fly-btn",
                                                  attrs: {
                                                    color: "#162450",
                                                    dark: "",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v(" Add New ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.onSiteTechContactDialog,
                                    callback: function ($$v) {
                                      _vm.onSiteTechContactDialog = $$v
                                    },
                                    expression: "onSiteTechContactDialog",
                                  },
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass: "text-h5 grey lighten-2",
                                        },
                                        [_vm._v(" Create Technical contact ")]
                                      ),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "12",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "mb-3",
                                                    class: {
                                                      "is-invalid":
                                                        _vm.techContactSubmitted &&
                                                        _vm.$v.contact.firstName
                                                          .$error,
                                                    },
                                                    attrs: {
                                                      outlined: "",
                                                      label: "Full Name",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contact.firstName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contact,
                                                          "firstName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contact.firstName",
                                                    },
                                                  }),
                                                  _vm.techContactSubmitted &&
                                                  !_vm.$v.contact.firstName
                                                    .required
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "invalid-feedback",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Full Name is Required "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "mb-3",
                                                    class: {
                                                      "is-invalid":
                                                        _vm.techContactSubmitted &&
                                                        _vm.c.phoneNumber
                                                          .$error,
                                                    },
                                                    attrs: {
                                                      outlined: "",
                                                      set: (_vm.c =
                                                        _vm.$v.contact.contactPhones.$each[0]),
                                                      label: "PrimaryPhone",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contact
                                                          .contactPhones[0]
                                                          .phoneNumber,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contact
                                                            .contactPhones[0],
                                                          "phoneNumber",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contact.contactPhones[0].phoneNumber",
                                                    },
                                                  }),
                                                  _vm.techContactSubmitted &&
                                                  !_vm.c.phoneNumber.required
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "invalid-feedback",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Primary Phone is Required "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "mb-3",
                                                    attrs: {
                                                      outlined: "",
                                                      label: "SecondaryPhone",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contactSecondaryPhone,
                                                      callback: function ($$v) {
                                                        _vm.contactSecondaryPhone =
                                                          $$v
                                                      },
                                                      expression:
                                                        "contactSecondaryPhone",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "mb-3",
                                                    class: {
                                                      "is-invalid":
                                                        _vm.techContactSubmitted &&
                                                        _vm.$v.contact.email
                                                          .$error,
                                                    },
                                                    attrs: {
                                                      outlined: "",
                                                      label: "Email",
                                                    },
                                                    model: {
                                                      value: _vm.contact.email,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contact,
                                                          "email",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contact.email",
                                                    },
                                                  }),
                                                  _vm.techContactSubmitted &&
                                                  !_vm.$v.contact.email.required
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "invalid-feedback",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Email is Required "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.techContactSubmitted &&
                                                  !_vm.$v.contact.email.email
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "invalid-feedback",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Email is not valid "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c("v-col", {
                                                attrs: { cols: "12", md: "6" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-divider", {
                                            staticClass: "mb-10",
                                          }),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          outlined: "",
                                                          label: "Street",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .street,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "street",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.street",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          outlined: "",
                                                          label:
                                                            "ZIP / Postal Code",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .postalCode,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "postalCode",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.postalCode",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          outlined: "",
                                                          label: "Details",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .details,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "details",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.details",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-col", {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-autocomplete", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          items: _vm.countries,
                                                          "item-text": "name",
                                                          "item-value": "id",
                                                          label: "Country",
                                                          outlined: "",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.getStates(
                                                              _vm.contact
                                                                .address
                                                                .countryId
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .countryId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "countryId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.countryId",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-autocomplete", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          items: _vm.states,
                                                          "item-text": "name",
                                                          "item-value": "id",
                                                          label: "State",
                                                          outlined: "",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.getCities(
                                                              _vm.contact
                                                                .address.stateId
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .stateId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "stateId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.stateId",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-autocomplete", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          items: _vm.cities,
                                                          "item-text": "name",
                                                          "item-value": "id",
                                                          label: "City",
                                                          outlined: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .cityId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "cityId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.cityId",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-col", {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.createContact(3)
                                                },
                                              },
                                            },
                                            [_vm._v(" Create ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  [
                    _c("v-autocomplete", {
                      staticClass: "mb-3",
                      class: {
                        "is-invalid":
                          _vm.submitted &&
                          _vm.$v.workOrder.accontManagerContact.$error,
                      },
                      attrs: {
                        tabindex: "6",
                        items: _vm.AccountManagerContacts,
                        "item-text": (item) => item.firstName,
                        "item-value": "id",
                        label: "Account Manager Contact",
                        outlined: "",
                      },
                      model: {
                        value: _vm.workOrder.accontManagerContact,
                        callback: function ($$v) {
                          _vm.$set(_vm.workOrder, "accontManagerContact", $$v)
                        },
                        expression: "workOrder.accontManagerContact",
                      },
                    }),
                    _c(
                      "v-row",
                      {
                        staticClass: "invalid-ngtv",
                        class: {
                          "mrgn-ngtv":
                            _vm.submitted &&
                            _vm.$v.workOrder.accontManagerContact.$error,
                        },
                      },
                      [
                        _c("v-col", { attrs: { md: "9" } }, [
                          _vm.submitted &&
                          !_vm.$v.workOrder.accontManagerContact.required
                            ? _c("p", { staticClass: "invalid-feedback" }, [
                                _vm._v(" Accont Manager Contact is Required "),
                              ])
                            : _vm._e(),
                        ]),
                        _c("v-col", { attrs: { md: "3" } }, [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "v-dialog",
                                {
                                  attrs: { width: "700" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.workOrder
                                                          .branchId != null,
                                                      expression:
                                                        "workOrder.branchId != null",
                                                    },
                                                  ],
                                                  staticClass: "on-fly-btn",
                                                  attrs: {
                                                    color: "#162450",
                                                    dark: "",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v(" Add New ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.AccountManagerDialog,
                                    callback: function ($$v) {
                                      _vm.AccountManagerDialog = $$v
                                    },
                                    expression: "AccountManagerDialog",
                                  },
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass: "text-h5 grey lighten-2",
                                        },
                                        [
                                          _vm._v(
                                            " Create Account Manager contact "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "12",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "mb-3",
                                                    class: {
                                                      "is-invalid":
                                                        _vm.AccountManagerContactSubmitted &&
                                                        _vm.$v.contact.firstName
                                                          .$error,
                                                    },
                                                    attrs: {
                                                      outlined: "",
                                                      label: "Full Name",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contact.firstName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contact,
                                                          "firstName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contact.firstName",
                                                    },
                                                  }),
                                                  _vm.AccountManagerContactSubmitted &&
                                                  !_vm.$v.contact.firstName
                                                    .required
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "invalid-feedback",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Full Name is Required "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "mb-3",
                                                    class: {
                                                      "is-invalid":
                                                        _vm.AccountManagerContactSubmitted &&
                                                        _vm.c.phoneNumber
                                                          .$error,
                                                    },
                                                    attrs: {
                                                      outlined: "",
                                                      set: (_vm.c =
                                                        _vm.$v.contact.contactPhones.$each[0]),
                                                      label: "PrimaryPhone",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contact
                                                          .contactPhones[0]
                                                          .phoneNumber,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contact
                                                            .contactPhones[0],
                                                          "phoneNumber",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contact.contactPhones[0].phoneNumber",
                                                    },
                                                  }),
                                                  _vm.AccountManagerContactSubmitted &&
                                                  !_vm.c.phoneNumber.required
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "invalid-feedback",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Primary Phone is Required "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "mb-3",
                                                    attrs: {
                                                      outlined: "",
                                                      label: "SecondaryPhone",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contactSecondaryPhone,
                                                      callback: function ($$v) {
                                                        _vm.contactSecondaryPhone =
                                                          $$v
                                                      },
                                                      expression:
                                                        "contactSecondaryPhone",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "mb-3",
                                                    class: {
                                                      "is-invalid":
                                                        _vm.AccountManagerContactSubmitted &&
                                                        _vm.$v.contact.email
                                                          .$error,
                                                    },
                                                    attrs: {
                                                      outlined: "",
                                                      label: "Email",
                                                    },
                                                    model: {
                                                      value: _vm.contact.email,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contact,
                                                          "email",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contact.email",
                                                    },
                                                  }),
                                                  _vm.AccountManagerContactSubmitted &&
                                                  !_vm.$v.contact.email.required
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "invalid-feedback",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Email is Required "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.AccountManagerContactSubmitted &&
                                                  !_vm.$v.contact.email.email
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "invalid-feedback",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Email is not valid "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c("v-col", {
                                                attrs: { cols: "12", md: "6" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-divider", {
                                            staticClass: "mb-10",
                                          }),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          outlined: "",
                                                          label: "Street",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .street,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "street",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.street",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          outlined: "",
                                                          label:
                                                            "ZIP / Postal Code",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .postalCode,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "postalCode",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.postalCode",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          outlined: "",
                                                          label: "Details",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .details,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "details",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.details",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-col", {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-autocomplete", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          items: _vm.countries,
                                                          "item-text": "name",
                                                          "item-value": "id",
                                                          label: "Country",
                                                          outlined: "",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.getStates(
                                                              _vm.contact
                                                                .address
                                                                .countryId
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .countryId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "countryId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.countryId",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-autocomplete", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          items: _vm.states,
                                                          "item-text": "name",
                                                          "item-value": "id",
                                                          label: "State",
                                                          outlined: "",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.getCities(
                                                              _vm.contact
                                                                .address.stateId
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .stateId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "stateId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.stateId",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-autocomplete", {
                                                        staticClass: "mb-3",
                                                        attrs: {
                                                          items: _vm.cities,
                                                          "item-text": "name",
                                                          "item-value": "id",
                                                          label: "City",
                                                          outlined: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contact.address
                                                              .cityId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact
                                                                .address,
                                                              "cityId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.address.cityId",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-col", {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.createContact(4)
                                                },
                                              },
                                            },
                                            [_vm._v(" Create ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "innner-container" },
          [
            _c("p", { staticClass: "table-title" }, [
              _vm._v("Order Details :"),
            ]),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c("v-text-field", {
                      staticClass: "mb-3",
                      class: {
                        "is-invalid":
                          _vm.submitted && _vm.$v.workOrder.orderId.$error,
                      },
                      attrs: { tabindex: "8", label: "Order ID", outlined: "" },
                      model: {
                        value: _vm.workOrder.orderId,
                        callback: function ($$v) {
                          _vm.$set(_vm.workOrder, "orderId", $$v)
                        },
                        expression: "workOrder.orderId",
                      },
                    }),
                    _vm.submitted && !_vm.$v.workOrder.orderId.required
                      ? _c("p", { staticClass: "invalid-feedback" }, [
                          _vm._v(" Order ID is Required "),
                        ])
                      : _vm._e(),
                    _c("v-text-field", {
                      staticClass: "mb-3",
                      class: {
                        "is-invalid":
                          _vm.submitted && _vm.v.numberOfTechnician.$error,
                      },
                      attrs: {
                        tabindex: "10",
                        outlined: "",
                        label: "Number Of Technicians",
                        set: (_vm.v =
                          _vm.$v.workOrder.workOrderTecLevels.$each[0]),
                      },
                      model: {
                        value:
                          _vm.workOrder.workOrderTecLevels[0]
                            .numberOfTechnician,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.workOrder.workOrderTecLevels[0],
                            "numberOfTechnician",
                            $$v
                          )
                        },
                        expression:
                          "workOrder.workOrderTecLevels[0].numberOfTechnician",
                      },
                    }),
                    _vm.submitted && !_vm.v.numberOfTechnician.required
                      ? _c("p", { staticClass: "invalid-feedback" }, [
                          _vm._v(" Number of technician is Required "),
                        ])
                      : _vm._e(),
                    _c("v-combobox", {
                      class: {
                        "is-invalid":
                          _vm.submitted &&
                          _vm.$v.workOrder.problemTypeId.$error,
                      },
                      attrs: {
                        tabindex: "12",
                        items: _vm.problemTypes,
                        "item-text": "name",
                        "item-value": "id",
                        label: "Problem Category",
                        outlined: "",
                        "search-input": _vm.problemSearch,
                        "hide-selected": "",
                        "persistent-hint": "",
                      },
                      on: {
                        "update:searchInput": function ($event) {
                          _vm.problemSearch = $event
                        },
                        "update:search-input": function ($event) {
                          _vm.problemSearch = $event
                        },
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.createProblemType.apply(null, arguments)
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-data",
                          fn: function () {
                            return [
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(' No results matching "'),
                                        _c("strong", [
                                          _vm._v(_vm._s(_vm.problemSearch)),
                                        ]),
                                        _vm._v('". Press '),
                                        _c("kbd", [_vm._v("enter")]),
                                        _vm._v(" to create a new one "),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.workOrder.problemTypeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.workOrder, "problemTypeId", $$v)
                        },
                        expression: "workOrder.problemTypeId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { md: "6" } },
                  [
                    _c("v-combobox", {
                      staticClass: "mb-3",
                      class: {
                        "is-invalid":
                          _vm.submitted && _vm.$v.workOrder.orderTypeId.$error,
                      },
                      attrs: {
                        tabindex: "9",
                        items: _vm.orderTypes,
                        "item-text": "name",
                        "item-value": "id",
                        label: "Select Order Type",
                        "search-input": _vm.orderSearch,
                        "hide-selected": "",
                        "persistent-hint": "",
                        outlined: "",
                      },
                      on: {
                        "update:searchInput": function ($event) {
                          _vm.orderSearch = $event
                        },
                        "update:search-input": function ($event) {
                          _vm.orderSearch = $event
                        },
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.createOrderType.apply(null, arguments)
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-data",
                          fn: function () {
                            return [
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(' No results matching "'),
                                        _c("strong", [
                                          _vm._v(_vm._s(_vm.orderSearch)),
                                        ]),
                                        _vm._v('". Press '),
                                        _c("kbd", [_vm._v("enter")]),
                                        _vm._v(" to create a new one "),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.workOrder.orderTypeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.workOrder, "orderTypeId", $$v)
                        },
                        expression: "workOrder.orderTypeId",
                      },
                    }),
                    _c(
                      "v-row",
                      {
                        staticClass: "invalid-ngtv",
                        class: {
                          "mrgn-ngtv":
                            _vm.submitted &&
                            _vm.$v.workOrder.orderTypeId.$error,
                        },
                      },
                      [
                        _c("v-col", { attrs: { md: "9" } }, [
                          _vm.submitted &&
                          !_vm.$v.workOrder.orderTypeId.required
                            ? _c("p", { staticClass: "invalid-feedback" }, [
                                _vm._v(" Order type is Required "),
                              ])
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                    _c("v-autocomplete", {
                      staticClass: "mb-3 mt-2",
                      class: {
                        "is-invalid":
                          _vm.submitted && _vm.v.technicianLevelId.$error,
                      },
                      staticStyle: { "margin-top": "5% !important" },
                      attrs: {
                        tabindex: "11",
                        items: _vm.levels,
                        "item-text": "name",
                        "item-value": "id",
                        label: "Technician Level",
                        outlined: "",
                        set: (_vm.v =
                          _vm.$v.workOrder.workOrderTecLevels.$each[0]),
                      },
                      model: {
                        value:
                          _vm.workOrder.workOrderTecLevels[0].technicianLevelId,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.workOrder.workOrderTecLevels[0],
                            "technicianLevelId",
                            $$v
                          )
                        },
                        expression:
                          "workOrder.workOrderTecLevels[0].technicianLevelId",
                      },
                    }),
                    _vm.submitted && !_vm.v.technicianLevelId.required
                      ? _c("p", { staticClass: "invalid-feedback" }, [
                          _vm._v(" Technician level is Required "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.userInfo.userTypeId == 201 || _vm.userInfo.userTypeId == 202
              ? _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "6" } },
                      [
                        _c("v-text-field", {
                          staticClass: "mb-3",
                          attrs: {
                            tabindex: "8",
                            label: "field Nation ID",
                            outlined: "",
                          },
                          model: {
                            value: _vm.workOrder.fieldNationId,
                            callback: function ($$v) {
                              _vm.$set(_vm.workOrder, "fieldNationId", $$v)
                            },
                            expression: "workOrder.fieldNationId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { md: "12" } },
                  [
                    _c("v-textarea", {
                      staticClass: "mb-3",
                      class: {
                        "invalid-text-area":
                          _vm.submitted && _vm.$v.workOrder.scopeOfWork.$error,
                      },
                      attrs: {
                        tabindex: "13",
                        outlined: "",
                        label: "Scope Of Work",
                      },
                      model: {
                        value: _vm.workOrder.scopeOfWork,
                        callback: function ($$v) {
                          _vm.$set(_vm.workOrder, "scopeOfWork", $$v)
                        },
                        expression: "workOrder.scopeOfWork",
                      },
                    }),
                    _vm.submitted && !_vm.$v.workOrder.scopeOfWork.required
                      ? _c("p", { staticClass: "invalid-feedback" }, [
                          _vm._v(" Scope of work is Required "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "innner-container" },
          [
            _c("p", { staticClass: "table-title" }, [_vm._v("Service Date :")]),
            _c(
              "div",
              [
                _c(
                  "v-radio-group",
                  {
                    attrs: { tabindex: "14" },
                    on: { change: _vm.reset },
                    model: {
                      value: _vm.workOrder.serviceDateTypeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.workOrder, "serviceDateTypeId", $$v)
                      },
                      expression: "workOrder.serviceDateTypeId",
                    },
                  },
                  _vm._l(_vm.serviceDates, function (srvc) {
                    return _c("v-radio", {
                      key: srvc.id,
                      staticClass: "ml-7 col-md-sm-2",
                      attrs: {
                        color: "#707070",
                        label: srvc.name,
                        value: srvc.id,
                      },
                    })
                  }),
                  1
                ),
                _vm.submitted && !_vm.$v.workOrder.serviceDateTypeId.required
                  ? _c("p", { staticClass: "invalid-feedback" }, [
                      _vm._v(" Service Date is Required "),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm.workOrder.serviceDateTypeId &&
            _vm.workOrder.serviceDateTypeId == 3
              ? _c(
                  "div",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", md: "4" } },
                          [
                            _c("FunctionalCalendar", {
                              attrs: { configs: _vm.calendarConfigs1 },
                              model: {
                                value: _vm.rangeDates,
                                callback: function ($$v) {
                                  _vm.rangeDates = $$v
                                },
                                expression: "rangeDates",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", md: "8" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c("v-col", [_c("label", [_vm._v("From")])]),
                                _c(
                                  "v-col",
                                  { staticStyle: { padding: "16px 1px" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "number",
                                        label: "HH",
                                        min: "1",
                                        max: "12",
                                        solo: "",
                                      },
                                      model: {
                                        value: _vm.RangFromHour,
                                        callback: function ($$v) {
                                          _vm.RangFromHour = $$v
                                        },
                                        expression: "RangFromHour",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "label",
                                  {
                                    staticStyle: {
                                      "padding-top": "1%",
                                      "font-size": "30px",
                                    },
                                  },
                                  [_vm._v(":")]
                                ),
                                _c(
                                  "v-col",
                                  { staticStyle: { padding: "16px 1px" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "number",
                                        label: "MM",
                                        min: "0",
                                        max: "59",
                                        solo: "",
                                      },
                                      model: {
                                        value: _vm.RangFromMin,
                                        callback: function ($$v) {
                                          _vm.RangFromMin = $$v
                                        },
                                        expression: "RangFromMin",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { staticStyle: { padding: "16px 1px" } },
                                  [
                                    _c("v-select", {
                                      attrs: { items: _vm.Time, solo: "" },
                                      model: {
                                        value: _vm.RangFromAmPmTime,
                                        callback: function ($$v) {
                                          _vm.RangFromAmPmTime = $$v
                                        },
                                        expression: "RangFromAmPmTime",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("v-col", [_c("label", [_vm._v("To")])]),
                                _c(
                                  "v-col",
                                  { staticStyle: { padding: "16px 1px" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "number",
                                        label: "HH",
                                        min: "1",
                                        max: "12",
                                        solo: "",
                                      },
                                      model: {
                                        value: _vm.RangToHour,
                                        callback: function ($$v) {
                                          _vm.RangToHour = $$v
                                        },
                                        expression: "RangToHour",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "label",
                                  {
                                    staticStyle: {
                                      "padding-top": "1%",
                                      "font-size": "30px",
                                    },
                                  },
                                  [_vm._v(":")]
                                ),
                                _c(
                                  "v-col",
                                  { staticStyle: { padding: "16px 1px" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "number",
                                        label: "MM",
                                        min: "0",
                                        max: "59",
                                        solo: "",
                                      },
                                      model: {
                                        value: _vm.RangToMin,
                                        callback: function ($$v) {
                                          _vm.RangToMin = $$v
                                        },
                                        expression: "RangToMin",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { staticStyle: { padding: "16px 1px" } },
                                  [
                                    _c("v-select", {
                                      attrs: { items: _vm.Time, solo: "" },
                                      model: {
                                        value: _vm.RangToAmPmTime,
                                        callback: function ($$v) {
                                          _vm.RangToAmPmTime = $$v
                                        },
                                        expression: "RangToAmPmTime",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("v-checkbox", {
                          attrs: { label: "Long Term", color: "#4B5568" },
                          model: {
                            value: _vm.isLongTerm,
                            callback: function ($$v) {
                              _vm.isLongTerm = $$v
                            },
                            expression: "isLongTerm",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.workOrder.serviceDateTypeId &&
            _vm.workOrder.serviceDateTypeId == 2
              ? _c(
                  "div",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", md: "6" } },
                          [
                            _c(
                              "v-menu",
                              {
                                ref: "menu",
                                attrs: {
                                  "close-on-content-click": false,
                                  "return-value": _vm.date,
                                  transition: "scale-transition",
                                  "offset-y": "",
                                  "min-width": "300",
                                },
                                on: {
                                  "update:returnValue": function ($event) {
                                    _vm.date = $event
                                  },
                                  "update:return-value": function ($event) {
                                    _vm.date = $event
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    hint: "MM/DD/YYYY format",
                                                    label: "Start Date",
                                                    "prepend-inner-icon":
                                                      "mdi-calendar",
                                                    readonly: "",
                                                    outlined: "",
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      _vm.date = _vm.parseDate(
                                                        _vm.date
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.dateFormatted,
                                                    callback: function ($$v) {
                                                      _vm.dateFormatted = $$v
                                                    },
                                                    expression: "dateFormatted",
                                                  },
                                                },
                                                "v-text-field",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3904539453
                                ),
                                model: {
                                  value: _vm.menu,
                                  callback: function ($$v) {
                                    _vm.menu = $$v
                                  },
                                  expression: "menu",
                                },
                              },
                              [
                                _c("v-date-picker", {
                                  attrs: { "no-title": "", scrollable: "" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$refs.menu.save(_vm.date)
                                    },
                                    click: function ($event) {
                                      _vm.menu = false
                                    },
                                  },
                                  model: {
                                    value: _vm.date,
                                    callback: function ($$v) {
                                      _vm.date = $$v
                                    },
                                    expression: "date",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", [_c("label", [_vm._v("From")])]),
                        _c(
                          "v-col",
                          { staticStyle: { padding: "16px 1px" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                type: "number",
                                label: "HH",
                                min: "1",
                                max: "12",
                                solo: "",
                              },
                              model: {
                                value: _vm.FromHour,
                                callback: function ($$v) {
                                  _vm.FromHour = $$v
                                },
                                expression: "FromHour",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "label",
                          {
                            staticStyle: {
                              "padding-top": "1%",
                              "font-size": "30px",
                            },
                          },
                          [_vm._v(":")]
                        ),
                        _c(
                          "v-col",
                          { staticStyle: { padding: "16px 1px" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                type: "number",
                                label: "MM",
                                min: "0",
                                max: "59",
                                solo: "",
                              },
                              model: {
                                value: _vm.FromMin,
                                callback: function ($$v) {
                                  _vm.FromMin = $$v
                                },
                                expression: "FromMin",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticStyle: { padding: "16px 1px" } },
                          [
                            _c("v-select", {
                              attrs: { items: _vm.Time, solo: "" },
                              model: {
                                value: _vm.FromAmPmTime,
                                callback: function ($$v) {
                                  _vm.FromAmPmTime = $$v
                                },
                                expression: "FromAmPmTime",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("v-col", [_c("label", [_vm._v("To")])]),
                        _c(
                          "v-col",
                          { staticStyle: { padding: "16px 1px" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                type: "number",
                                label: "HH",
                                min: "1",
                                max: "12",
                                solo: "",
                              },
                              model: {
                                value: _vm.ToHour,
                                callback: function ($$v) {
                                  _vm.ToHour = $$v
                                },
                                expression: "ToHour",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "label",
                          {
                            staticStyle: {
                              "padding-top": "1%",
                              "font-size": "30px",
                            },
                          },
                          [_vm._v(":")]
                        ),
                        _c(
                          "v-col",
                          { staticStyle: { padding: "16px 1px" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                type: "number",
                                label: "MM",
                                min: "0",
                                max: "59",
                                solo: "",
                              },
                              model: {
                                value: _vm.ToMin,
                                callback: function ($$v) {
                                  _vm.ToMin = $$v
                                },
                                expression: "ToMin",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticStyle: { padding: "16px 1px" } },
                          [
                            _c("v-select", {
                              attrs: { items: _vm.Time, solo: "" },
                              model: {
                                value: _vm.ToAmPmTime,
                                callback: function ($$v) {
                                  _vm.ToAmPmTime = $$v
                                },
                                expression: "ToAmPmTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.workOrder.serviceDateTypeId &&
            _vm.workOrder.serviceDateTypeId == 5
              ? _c(
                  "div",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { md: "6" } },
                          [
                            _c(
                              "v-menu",
                              {
                                ref: "menu",
                                attrs: {
                                  "close-on-content-click": false,
                                  "return-value":
                                    _vm.workOrder.repetitionDetails.startDate,
                                  transition: "scale-transition",
                                  "offset-y": "",
                                },
                                on: {
                                  "update:returnValue": function ($event) {
                                    return _vm.$set(
                                      _vm.workOrder.repetitionDetails,
                                      "startDate",
                                      $event
                                    )
                                  },
                                  "update:return-value": function ($event) {
                                    return _vm.$set(
                                      _vm.workOrder.repetitionDetails,
                                      "startDate",
                                      $event
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    label: "Start Date",
                                                    "prepend-inner-icon":
                                                      "mdi-calendar",
                                                    readonly: "",
                                                    outlined: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.workOrder
                                                        .repetitionDetails
                                                        .startDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.workOrder
                                                          .repetitionDetails,
                                                        "startDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "workOrder.repetitionDetails.startDate",
                                                  },
                                                },
                                                "v-text-field",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2594611213
                                ),
                                model: {
                                  value: _vm.menuStartDate,
                                  callback: function ($$v) {
                                    _vm.menuStartDate = $$v
                                  },
                                  expression: "menuStartDate",
                                },
                              },
                              [
                                _c(
                                  "v-date-picker",
                                  {
                                    attrs: { "no-title": "", scrollable: "" },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$refs.menu.save(
                                          _vm.workOrder.repetitionDetails
                                            .startDate
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.workOrder.repetitionDetails
                                          .startDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.workOrder.repetitionDetails,
                                          "startDate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "workOrder.repetitionDetails.startDate",
                                    },
                                  },
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { text: "", color: "primary" },
                                        on: {
                                          click: function ($event) {
                                            _vm.menuStartDate = false
                                          },
                                        },
                                      },
                                      [_vm._v(" Cancel ")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { text: "", color: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$refs.menu.save(
                                              _vm.workOrder.repetitionDetails
                                                .startDate
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" OK ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("v-select", {
                              attrs: {
                                items: _vm.frequencies,
                                "item-text": "name",
                                "item-value": "id",
                                outlined: "",
                                label: "Select Frequency",
                              },
                              model: {
                                value:
                                  _vm.workOrder.repetitionDetails.frequencyId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workOrder.repetitionDetails,
                                    "frequencyId",
                                    $$v
                                  )
                                },
                                expression:
                                  "workOrder.repetitionDetails.frequencyId",
                              },
                            }),
                            _c(
                              "div",
                              [
                                _c("v-date-picker", {
                                  attrs: { "no-title": "", multiple: "" },
                                  model: {
                                    value:
                                      _vm.workOrder.repetitionDetails
                                        .repetitionSchedules[0].dates,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.workOrder.repetitionDetails
                                          .repetitionSchedules[0],
                                        "dates",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n                  workOrder.repetitionDetails.repetitionSchedules[0].dates\n                ",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { md: "6" } },
                          [
                            _c(
                              "v-menu",
                              {
                                ref: "menuEndDate",
                                attrs: {
                                  "close-on-content-click": false,
                                  "return-value":
                                    _vm.workOrder.repetitionDetails.endDate,
                                  transition: "scale-transition",
                                  "offset-y": "",
                                },
                                on: {
                                  "update:returnValue": function ($event) {
                                    return _vm.$set(
                                      _vm.workOrder.repetitionDetails,
                                      "endDate",
                                      $event
                                    )
                                  },
                                  "update:return-value": function ($event) {
                                    return _vm.$set(
                                      _vm.workOrder.repetitionDetails,
                                      "endDate",
                                      $event
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    label: "End Date",
                                                    "prepend-inner-icon":
                                                      "mdi-calendar",
                                                    readonly: "",
                                                    outlined: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.workOrder
                                                        .repetitionDetails
                                                        .endDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.workOrder
                                                          .repetitionDetails,
                                                        "endDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "workOrder.repetitionDetails.endDate",
                                                  },
                                                },
                                                "v-text-field",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1232464365
                                ),
                                model: {
                                  value: _vm.menuEndDate,
                                  callback: function ($$v) {
                                    _vm.menuEndDate = $$v
                                  },
                                  expression: "menuEndDate",
                                },
                              },
                              [
                                _c(
                                  "v-date-picker",
                                  {
                                    attrs: { "no-title": "", scrollable: "" },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$refs.menuEndDate.save(
                                          _vm.workOrder.repetitionDetails
                                            .endDate
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.workOrder.repetitionDetails.endDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.workOrder.repetitionDetails,
                                          "endDate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "workOrder.repetitionDetails.endDate",
                                    },
                                  },
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { text: "", color: "primary" },
                                        on: {
                                          click: function ($event) {
                                            _vm.menuEndDate = false
                                          },
                                        },
                                      },
                                      [_vm._v(" Cancel ")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { text: "", color: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$refs.menuEndDate.save(
                                              _vm.workOrder.repetitionDetails
                                                .endDate
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" OK ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { staticClass: "mt-15" },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { md: "6" } },
                                  [
                                    _c("label", { staticClass: "time-label" }, [
                                      _vm._v("From Time"),
                                    ]),
                                    _c("vue-timepicker", {
                                      staticClass: "time-picker",
                                      attrs: {
                                        "manual-input": "",
                                        "hide-dropdown": "",
                                      },
                                      model: {
                                        value: _vm.fromTime,
                                        callback: function ($$v) {
                                          _vm.fromTime = $$v
                                        },
                                        expression: "fromTime",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { md: "6" } },
                                  [
                                    _c("label", { staticClass: "time-label" }, [
                                      _vm._v("To Time"),
                                    ]),
                                    _c("vue-timepicker", {
                                      staticClass: "time-picker",
                                      attrs: {
                                        "manual-input": "",
                                        "hide-dropdown": "",
                                      },
                                      model: {
                                        value: _vm.toTime,
                                        callback: function ($$v) {
                                          _vm.toTime = $$v
                                        },
                                        expression: "toTime",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c("v-divider", { staticClass: "mb-10" }),
            _c(
              "div",
              [
                _c("p", { staticClass: "table-title" }, [
                  _vm._v("Payment Methods :"),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "v-radio-group",
                      {
                        attrs: { tabindex: "15" },
                        on: { change: _vm.checkPurchase },
                        model: {
                          value: _vm.workOrder.paymentMethodId,
                          callback: function ($$v) {
                            _vm.$set(_vm.workOrder, "paymentMethodId", $$v)
                          },
                          expression: "workOrder.paymentMethodId",
                        },
                      },
                      _vm._l(_vm.paymentMethods, function (method) {
                        return _c("v-radio", {
                          key: method.id,
                          staticClass: "ml-5",
                          attrs: {
                            color: "#707070",
                            label: method.name,
                            value: method.id,
                          },
                        })
                      }),
                      1
                    ),
                    _vm.submitted && !_vm.$v.workOrder.paymentMethodId.required
                      ? _c("p", { staticClass: "invalid-feedback" }, [
                          _vm._v(" Payment Method is Required "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _vm.workOrder.paymentMethodId == 3
                      ? _c(
                          "v-col",
                          { attrs: { md: "6" } },
                          [
                            _c("v-col", [
                              _c(
                                "h4",
                                {
                                  staticStyle: {
                                    "font-family": "Roboto, sans-serif",
                                  },
                                },
                                [_vm._v("Po Color Codes:")]
                              ),
                              _c(
                                "ul",
                                { staticStyle: { display: "inline-flex" } },
                                [
                                  _c(
                                    "li",
                                    {
                                      staticStyle: {
                                        "padding-right": "15%",
                                        color: "#bbba82",
                                        "font-weight": "bolder",
                                      },
                                    },
                                    [_vm._v(" Client ")]
                                  ),
                                  _c(
                                    "li",
                                    {
                                      staticStyle: {
                                        "padding-right": "15%",
                                        color: "#aa5e68",
                                        "font-weight": "bolder",
                                      },
                                    },
                                    [_vm._v(" Account ")]
                                  ),
                                  _c(
                                    "li",
                                    {
                                      staticStyle: {
                                        "padding-right": "15%",
                                        color: "#7cab9a",
                                        "font-weight": "bolder",
                                      },
                                    },
                                    [_vm._v(" Company ")]
                                  ),
                                  _c(
                                    "li",
                                    {
                                      staticStyle: {
                                        "padding-right": "15%",
                                        color: "#5f6b9b",
                                        "font-weight": "bolder",
                                      },
                                    },
                                    [_vm._v(" Location ")]
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "v-col",
                              [
                                _c("v-select", {
                                  staticClass: "mb-2",
                                  attrs: {
                                    items: _vm.purchaseOrders,
                                    "item-text": (item) =>
                                      item.poId +
                                      "" +
                                      " With amount " +
                                      "" +
                                      item.orderAmount,
                                    "item-value": "id",
                                    outlined: "",
                                    label: "Purchase order",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "item",
                                        fn: function ({ item }) {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                class: [
                                                  {
                                                    "yellow-custom":
                                                      item.keyTypeId == 1,
                                                  },
                                                  {
                                                    "red-custom":
                                                      item.keyTypeId == 2,
                                                  },
                                                  {
                                                    "green-custom":
                                                      item.keyTypeId == 3,
                                                  },
                                                  {
                                                    "blue-custom":
                                                      item.keyTypeId == 4,
                                                  },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.poId +
                                                        "/ " +
                                                        "$ " +
                                                        item.orderAmount
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    700299701
                                  ),
                                  model: {
                                    value: _vm.workOrder.purchaseOrderId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.workOrder,
                                        "purchaseOrderId",
                                        $$v
                                      )
                                    },
                                    expression: "workOrder.purchaseOrderId",
                                  },
                                }),
                                _c(
                                  "v-row",
                                  [
                                    _c("v-col", { attrs: { md: "9" } }),
                                    _c(
                                      "v-col",
                                      { attrs: { md: "3" } },
                                      [
                                        _c(
                                          "v-dialog",
                                          {
                                            attrs: { width: "500" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "on-fly-btn mrgn-ngtv",
                                                              attrs: {
                                                                color:
                                                                  "#162450",
                                                                dark: "",
                                                              },
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [_vm._v(" Add New ")]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1319310126
                                            ),
                                            model: {
                                              value: _vm.poDialog,
                                              callback: function ($$v) {
                                                _vm.poDialog = $$v
                                              },
                                              expression: "poDialog",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              [
                                                _c(
                                                  "v-card-title",
                                                  {
                                                    staticClass:
                                                      "text-h5 grey lighten-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Create Purchase Order "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-card-text",
                                                  [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: { md: "12" },
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              staticClass:
                                                                "mt-3",
                                                              class: {
                                                                "is-invalid":
                                                                  _vm.poSubmitted &&
                                                                  _vm.$v.po.poId
                                                                    .$error,
                                                              },
                                                              attrs: {
                                                                outlined: "",
                                                                label: "PO Id",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.po.poId,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.po,
                                                                      "poId",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "po.poId",
                                                              },
                                                            }),
                                                            _vm.poSubmitted &&
                                                            !_vm.$v.po.poId
                                                              .required
                                                              ? _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "invalid-feedback mt-2",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Purchase Order Id is Required "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c("v-text-field", {
                                                              class: {
                                                                "is-invalid":
                                                                  _vm.poSubmitted &&
                                                                  _vm.$v.po
                                                                    .orderAmount
                                                                    .$error,
                                                              },
                                                              attrs: {
                                                                outlined: "",
                                                                label:
                                                                  "Order Amount",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.po
                                                                    .orderAmount,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.po,
                                                                      "orderAmount",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "po.orderAmount",
                                                              },
                                                            }),
                                                            _vm.poSubmitted &&
                                                            !_vm.$v.po
                                                              .orderAmount
                                                              .required
                                                              ? _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "invalid-feedback mt-2",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Purchase Order Amount is Required "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "v-autocomplete",
                                                              {
                                                                staticClass:
                                                                  "mb-3",
                                                                class: {
                                                                  "is-invalid":
                                                                    _vm.poSubmitted &&
                                                                    _vm.$v.po
                                                                      .keyTypeId
                                                                      .$error,
                                                                },
                                                                attrs: {
                                                                  items:
                                                                    _vm.keyTypes,
                                                                  "item-text":
                                                                    "name",
                                                                  "item-value":
                                                                    "id",
                                                                  label:
                                                                    "Key Type",
                                                                  outlined: "",
                                                                },
                                                                on: {
                                                                  change:
                                                                    _vm.getKeyId,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.po
                                                                      .keyTypeId,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.po,
                                                                        "keyTypeId",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "po.keyTypeId",
                                                                },
                                                              }
                                                            ),
                                                            _vm.poSubmitted &&
                                                            !_vm.$v.po.keyTypeId
                                                              .required
                                                              ? _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "invalid-feedback mt-2",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Purchase Order Key Type Id is Required "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "v-autocomplete",
                                                              {
                                                                staticClass:
                                                                  "mb-3",
                                                                class: {
                                                                  "is-invalid":
                                                                    _vm.poSubmitted &&
                                                                    _vm.$v.po
                                                                      .keyId
                                                                      .$error,
                                                                },
                                                                attrs: {
                                                                  items:
                                                                    _vm.keyIds,
                                                                  "item-text":
                                                                    "name",
                                                                  "item-value":
                                                                    "id",
                                                                  label:
                                                                    "Key Id",
                                                                  outlined: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.po
                                                                      .keyId,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.po,
                                                                        "keyId",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "po.keyId",
                                                                },
                                                              }
                                                            ),
                                                            _vm.poSubmitted &&
                                                            !_vm.$v.po.keyId
                                                              .required
                                                              ? _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "invalid-feedback mt-2",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Purchase Order Key Id is Required "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("v-divider"),
                                                _c(
                                                  "v-card-actions",
                                                  [
                                                    _c("v-spacer"),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                          text: "",
                                                        },
                                                        on: {
                                                          click: _vm.createPO,
                                                        },
                                                      },
                                                      [_vm._v(" Create ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c("v-col", { attrs: { md: "6" } }),
                  ],
                  1
                ),
                _c("p", { staticClass: "table-title" }, [
                  _vm._v("Payment Type :"),
                ]),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "4" } },
                      [
                        _c(
                          "v-radio-group",
                          {
                            attrs: { tabindex: "16", row: "" },
                            model: {
                              value: _vm.workOrder.paymentTypeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.workOrder, "paymentTypeId", $$v)
                              },
                              expression: "workOrder.paymentTypeId",
                            },
                          },
                          _vm._l(_vm.paymentTypes, function (type) {
                            return _c("v-radio", {
                              key: type.id,
                              staticClass: "ml-3",
                              attrs: {
                                color: "#707070",
                                label: type.name,
                                value: type.id,
                              },
                            })
                          }),
                          1
                        ),
                        _vm.submitted &&
                        !_vm.$v.workOrder.paymentTypeId.required
                          ? _c("p", { staticClass: "invalid-feedback" }, [
                              _vm._v(" Payment Type is Required "),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "handle-space2",
                        attrs: { id: "paymeny-amount", cols: "4", md: "2" },
                      },
                      [
                        _c("span", { staticClass: "money" }, [_vm._v("$")]),
                        _c("v-select", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false",
                            },
                          ],
                          staticClass: "mb-3 payment-resize",
                          class: {
                            "is-invalid":
                              _vm.submitted &&
                              _vm.$v.workOrder.paymentAmountTypeId.$error,
                          },
                          attrs: {
                            solo: "",
                            "item-text": "name",
                            "item-value": "id",
                            items: _vm.amountTypes,
                            label: "$",
                          },
                          model: {
                            value: _vm.workOrder.paymentAmountTypeId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.workOrder,
                                "paymentAmountTypeId",
                                $$v
                              )
                            },
                            expression: "workOrder.paymentAmountTypeId",
                          },
                        }),
                        _vm.submitted &&
                        !_vm.$v.workOrder.paymentAmountTypeId.required
                          ? _c("p", { staticClass: "invalid-feedback" }, [
                              _vm._v(" Payment amount type is Required "),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "8", md: "4" } },
                      [
                        _c("v-text-field", {
                          staticClass: "mb-3 ml-5",
                          class: {
                            "is-invalid":
                              _vm.submitted &&
                              _vm.$v.workOrder.paymentAmount.$error,
                          },
                          attrs: {
                            type: "number",
                            outlined: "",
                            label: "Payment Amount",
                          },
                          model: {
                            value: _vm.workOrder.paymentAmount,
                            callback: function ($$v) {
                              _vm.$set(_vm.workOrder, "paymentAmount", $$v)
                            },
                            expression: "workOrder.paymentAmount",
                          },
                        }),
                        _vm.submitted &&
                        !_vm.$v.workOrder.paymentAmount.required
                          ? _c("p", { staticClass: "invalid-feedback" }, [
                              _vm._v(" Payment amount is Required "),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { md: "4" } },
                      [
                        _c("v-select", {
                          attrs: {
                            items: _vm.expensesTypes,
                            "item-text": "name",
                            "item-value": "id",
                            label: "Add Fees, Discount, Tax, ... ",
                            dense: "",
                            "prepend-icon": "mdi-plus",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.addFeild(_vm.workOrderExpenseTypeId)
                            },
                          },
                          model: {
                            value: _vm.workOrderExpenseTypeId,
                            callback: function ($$v) {
                              _vm.workOrderExpenseTypeId = $$v
                            },
                            expression: "workOrderExpenseTypeId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._l(_vm.paymentFeilds, function (feild, index) {
                  return _c(
                    "v-row",
                    { key: index },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: feild.name },
                            model: {
                              value: feild.description,
                              callback: function ($$v) {
                                _vm.$set(feild, "description", $$v)
                              },
                              expression: "feild.description",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "handle-space",
                          attrs: { md: "2", cols: "4" },
                        },
                        [
                          _c("v-select", {
                            staticClass: "payment-resize",
                            attrs: {
                              outlined: "",
                              "item-text": "name",
                              "item-value": "id",
                              items: _vm.amountTypes,
                              label: "$",
                            },
                            model: {
                              value: feild.amountTypeId,
                              callback: function ($$v) {
                                _vm.$set(feild, "amountTypeId", $$v)
                              },
                              expression: "feild.amountTypeId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "3", cols: "7" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              type: "number",
                              outlined: "",
                              label: "Amount",
                            },
                            model: {
                              value: feild.expenseAmount,
                              callback: function ($$v) {
                                _vm.$set(feild, "expenseAmount", $$v)
                              },
                              expression: "feild.expenseAmount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "1", cols: "1" } },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "remove-item-icon",
                              on: {
                                click: function ($event) {
                                  return _vm.removeRow(index)
                                },
                              },
                            },
                            [_vm._v("mdi-delete")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                _c("br"),
                _c("hr"),
                _c("br"),
                _c("p", { staticClass: "table-title" }, [
                  _vm._v("Template Types:"),
                ]),
                _c("br"),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "6" } },
                      [
                        _c("v-autocomplete", {
                          staticClass: "mb-3",
                          class: {
                            "is-invalid":
                              _vm.submitted &&
                              _vm.$v.workOrder.creationEmailTemplateId.$error,
                          },
                          attrs: {
                            tabindex: "17",
                            items: _vm.template,
                            "item-text": "name",
                            "item-value": "id",
                            label: "Select Template",
                            outlined: "",
                          },
                          model: {
                            value: _vm.workOrder.creationEmailTemplateId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.workOrder,
                                "creationEmailTemplateId",
                                $$v
                              )
                            },
                            expression: "workOrder.creationEmailTemplateId",
                          },
                        }),
                        _vm.submitted &&
                        !_vm.$v.workOrder.creationEmailTemplateId.required
                          ? _c("p", { staticClass: "invalid-feedback" }, [
                              _vm._v(" Email Template is Required "),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("v-col"),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "btn-container" },
          [
            _c(
              "v-btn",
              {
                staticClass: "create-order-btn",
                attrs: { tabindex: "18" },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.check()
                  },
                  click: _vm.check,
                },
              },
              [_vm._v("Create Order")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }