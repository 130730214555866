var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "handle-test", attrs: { id: "tech-list" } }, [
    _c(
      "div",
      [
        _c(
          "v-row",
          [
            _c("v-col", { attrs: { cols: "12", md: "10" } }, [
              _c("p", { staticClass: "table-title" }, [
                _vm._v("Technician List"),
              ]),
            ]),
            _c(
              "v-col",
              { attrs: { md: "1", cols: "12" } },
              [
                _vm.can(14)
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "btn-creation wo-diff-style",
                        attrs: { to: "/technician/create" },
                      },
                      [_vm._v("CREATE TECHNICIAN")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { staticClass: "pad0", attrs: { cols: "12", md: "4" } },
              [
                _c("v-text-field", {
                  ref: "objet",
                  attrs: {
                    solo: "",
                    label: "Search Technicians ",
                    "append-icon": "mdi-magnify",
                  },
                  on: {
                    keyup: function ($event) {
                      return _vm.getTechnicians(1)
                    },
                  },
                  model: {
                    value: _vm.techFilter.search,
                    callback: function ($$v) {
                      _vm.$set(_vm.techFilter, "search", $$v)
                    },
                    expression: "techFilter.search",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { staticClass: "pad0", attrs: { cols: "12", md: "8" } },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "4" } },
                      [
                        _c("v-autocomplete", {
                          staticClass: "mb-3",
                          attrs: {
                            items: _vm.countries,
                            "item-text": "name",
                            "item-value": "id",
                            label: "Country",
                            outlined: "",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onchange()
                            },
                          },
                          model: {
                            value: _vm.techFilter.countryId,
                            callback: function ($$v) {
                              _vm.$set(_vm.techFilter, "countryId", $$v)
                            },
                            expression: "techFilter.countryId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "4" } },
                      [
                        _c("v-autocomplete", {
                          ref: "stateFocus",
                          staticClass: "mb-3",
                          attrs: {
                            "no-filter": "",
                            items: _vm.states,
                            "item-text": "name",
                            "item-value": "id",
                            label: "State",
                            solo: "",
                            "search-input": _vm.searchState,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.SearchInCity()
                            },
                            "update:searchInput": function ($event) {
                              _vm.searchState = $event
                            },
                            "update:search-input": function ($event) {
                              _vm.searchState = $event
                            },
                          },
                          model: {
                            value: _vm.techFilter.stateId,
                            callback: function ($$v) {
                              _vm.$set(_vm.techFilter, "stateId", $$v)
                            },
                            expression: "techFilter.stateId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "4" } },
                      [
                        _c("v-autocomplete", {
                          ref: "cityFocus",
                          staticClass: "mb-3",
                          attrs: {
                            items: _vm.cities,
                            "item-text": "name",
                            "item-value": "id",
                            label: "City",
                            solo: "",
                            "search-input": _vm.searchCity,
                          },
                          on: {
                            "update:searchInput": function ($event) {
                              _vm.searchCity = $event
                            },
                            "update:search-input": function ($event) {
                              _vm.searchCity = $event
                            },
                            change: function ($event) {
                              return _vm.getTechnicians(1)
                            },
                          },
                          model: {
                            value: _vm.techFilter.cityId,
                            callback: function ($$v) {
                              _vm.$set(_vm.techFilter, "cityId", $$v)
                            },
                            expression: "techFilter.cityId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-row",
          [
            _c("v-col", {
              staticClass: "pad0",
              attrs: { cols: "12", md: "4" },
            }),
            _c(
              "v-col",
              { staticClass: "pad0", attrs: { cols: "12", md: "8" } },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { staticClass: "pad0", attrs: { cols: "12", md: "4" } },
                      [
                        _c("v-text-field", {
                          attrs: { solo: "", label: "Zip Code" },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.getTechnicians(1)
                            },
                          },
                          model: {
                            value: _vm.techFilter.zipCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.techFilter, "zipCode", $$v)
                            },
                            expression: "techFilter.zipCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "pad0", attrs: { cols: "12", md: "4" } },
                      [
                        _c("v-autocomplete", {
                          ref: "locationRef",
                          attrs: {
                            items: _vm.locations,
                            "item-value": "id",
                            "item-text": "name",
                            "search-input": _vm.locationSearch,
                            outlined: "",
                            label: "Place Of Service",
                          },
                          on: {
                            "update:searchInput": function ($event) {
                              _vm.locationSearch = $event
                            },
                            "update:search-input": function ($event) {
                              _vm.locationSearch = $event
                            },
                            change: function ($event) {
                              return _vm.getTechnicians(1)
                            },
                          },
                          model: {
                            value: _vm.techFilter.branchId,
                            callback: function ($$v) {
                              _vm.$set(_vm.techFilter, "branchId", $$v)
                            },
                            expression: "techFilter.branchId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "pad0", attrs: { cols: "12", md: "4" } },
                      [
                        _c("v-autocomplete", {
                          attrs: {
                            items: _vm.skills,
                            "item-value": "id",
                            "item-text": "name",
                            label: "Select Skill",
                            solo: "",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.getTechnicians(1)
                            },
                          },
                          model: {
                            value: _vm.techFilter.skillId,
                            callback: function ($$v) {
                              _vm.$set(_vm.techFilter, "skillId", $$v)
                            },
                            expression: "techFilter.skillId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-row",
          [
            _c("v-col", {
              staticClass: "pad0",
              attrs: { cols: "12", md: "4" },
            }),
            _c(
              "v-col",
              { staticClass: "pad0", attrs: { cols: "12", md: "8" } },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { staticClass: "pad0", attrs: { cols: "12", md: "4" } },
                      [
                        _c(
                          "v-menu",
                          {
                            ref: "menu2",
                            attrs: {
                              "close-on-content-click": false,
                              "return-value": _vm.SDFrom,
                              transition: "scale-transition",
                              "offset-y": "",
                            },
                            on: {
                              "update:returnValue": function ($event) {
                                _vm.SDFrom = $event
                              },
                              "update:return-value": function ($event) {
                                _vm.SDFrom = $event
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-text-field",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              label: "Start Date",
                                              "prepend-inner-icon":
                                                "mdi-calendar",
                                              readonly: "",
                                              hint: "MM/DD/YYYY format",
                                              outlined: "",
                                            },
                                            on: {
                                              blur: function ($event) {
                                                _vm.SDFrom = _vm.parseDate(
                                                  _vm.SDFrom
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.dateFormatted,
                                              callback: function ($$v) {
                                                _vm.dateFormatted = $$v
                                              },
                                              expression: "dateFormatted",
                                            },
                                          },
                                          "v-text-field",
                                          attrs,
                                          false
                                        ),
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.menuStartDate,
                              callback: function ($$v) {
                                _vm.menuStartDate = $$v
                              },
                              expression: "menuStartDate",
                            },
                          },
                          [
                            _c(
                              "v-date-picker",
                              {
                                attrs: { "no-title": "", scrollable: "" },
                                on: {
                                  input: function ($event) {
                                    return _vm.$refs.menu2.save(_vm.SDFrom)
                                  },
                                  change: _vm.getTechByDate,
                                },
                                model: {
                                  value: _vm.SDFrom,
                                  callback: function ($$v) {
                                    _vm.SDFrom = $$v
                                  },
                                  expression: "SDFrom",
                                },
                              },
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "", color: "primary" },
                                    on: {
                                      click: function ($event) {
                                        _vm.menuStartDate = false
                                      },
                                    },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "", color: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$refs.menu2.save(_vm.SDFrom)
                                      },
                                    },
                                  },
                                  [_vm._v(" OK ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "pad0", attrs: { cols: "12", md: "4" } },
                      [
                        _c(
                          "v-menu",
                          {
                            ref: "menu",
                            attrs: {
                              "close-on-content-click": false,
                              "return-value": _vm.SDTo,
                              transition: "scale-transition",
                              "offset-y": "",
                            },
                            on: {
                              "update:returnValue": function ($event) {
                                _vm.SDTo = $event
                              },
                              "update:return-value": function ($event) {
                                _vm.SDTo = $event
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-text-field",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              hint: "MM/DD/YYYY format",
                                              label: "End Date",
                                              "prepend-inner-icon":
                                                "mdi-calendar",
                                              readonly: "",
                                              outlined: "",
                                            },
                                            on: {
                                              blur: function ($event) {
                                                _vm.SDTo = _vm.parseDate(
                                                  _vm.SDTo
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.dateFormattedEnd,
                                              callback: function ($$v) {
                                                _vm.dateFormattedEnd = $$v
                                              },
                                              expression: "dateFormattedEnd",
                                            },
                                          },
                                          "v-text-field",
                                          attrs,
                                          false
                                        ),
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.menuEndDate,
                              callback: function ($$v) {
                                _vm.menuEndDate = $$v
                              },
                              expression: "menuEndDate",
                            },
                          },
                          [
                            _c(
                              "v-date-picker",
                              {
                                attrs: { "no-title": "", scrollable: "" },
                                on: {
                                  input: function ($event) {
                                    return _vm.$refs.menu.save(_vm.SDTo)
                                  },
                                  change: _vm.getTechByDate,
                                },
                                model: {
                                  value: _vm.SDTo,
                                  callback: function ($$v) {
                                    _vm.SDTo = $$v
                                  },
                                  expression: "SDTo",
                                },
                              },
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "", color: "primary" },
                                    on: {
                                      click: function ($event) {
                                        _vm.menuEndDate = false
                                      },
                                    },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "", color: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$refs.menu.save(_vm.SDTo)
                                      },
                                    },
                                  },
                                  [_vm._v(" OK ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "pad0", attrs: { cols: "12", md: "4" } },
                      [
                        _c("v-select", {
                          attrs: {
                            items: _vm.techTypes,
                            "item-value": "id",
                            "item-text": "name",
                            label: "Technician Type",
                            solo: "",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.getTechnicians(1)
                            },
                          },
                          model: {
                            value: _vm.techFilter.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.techFilter, "type", $$v)
                            },
                            expression: "techFilter.type",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-row",
          [
            _c("v-col", {
              staticClass: "pad0",
              attrs: { cols: "12", md: "4" },
            }),
            _c(
              "v-col",
              { staticClass: "pad0", attrs: { cols: "12", md: "8" } },
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { cols: "12", md: "2" } }),
                    _c("v-col", { attrs: { md: "1", id: "filter-s-option" } }),
                    _c("v-col", { attrs: { md: "1", id: "filter-s-option" } }),
                    _c("v-col", { attrs: { md: "1", id: "filter-s-option" } }),
                    _c("v-col", { attrs: { md: "1", id: "filter-s-option" } }),
                    _c("v-col", { attrs: { md: "2", id: "filter-s-option" } }),
                    _c(
                      "v-col",
                      { staticClass: "pad0", attrs: { cols: "12", md: "4" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-creation diff-style mt-1",
                            attrs: { color: "#D92020" },
                            on: { click: _vm.resetFilter },
                          },
                          [_vm._v("Reset Filter")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("br"),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("v-data-table", {
          staticClass: "elevation-1",
          attrs: {
            loading: _vm.isLoaded,
            "loading-text": "Loading... Please wait",
            headers: _vm.headers,
            items: _vm.data,
            "item-key": "name",
            "hide-default-footer": "",
            "items-per-page": _vm.techFilter.pageSize,
          },
          on: { "update:options": _vm.handleOptionsUpdate },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "v-row",
                    { staticClass: "mt-5 fit-width" },
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "2" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.sizeOptions,
                              "item-value": "id",
                              "item-text": "name",
                              solo: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getTechnicians(1)
                              },
                            },
                            model: {
                              value: _vm.techFilter.pageSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.techFilter, "pageSize", $$v)
                              },
                              expression: "techFilter.pageSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "5" } },
                        [
                          _c("v-pagination", {
                            attrs: {
                              length: _vm.totalPages,
                              "total-visible": "6",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.getTechnicians(
                                  _vm.techFilter.pageNumber
                                )
                              },
                            },
                            model: {
                              value: _vm.techFilter.pageNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.techFilter, "pageNumber", $$v)
                              },
                              expression: "techFilter.pageNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "item.actions",
              fn: function ({ item }) {
                return [
                  _vm.can(15)
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "mr-2 edit-icon",
                          attrs: { small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.editItem(item)
                            },
                          },
                        },
                        [_vm._v(" far fa-edit ")]
                      )
                    : _vm._e(),
                  _vm.can(15)
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "delete-icon",
                          attrs: { small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteItem(item)
                            },
                          },
                        },
                        [_vm._v(" mdi-delete ")]
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: "top",
              fn: function () {
                return [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Technicians")]),
                      _c("v-divider", {
                        staticClass: "mx-4",
                        attrs: { inset: "", vertical: "" },
                      }),
                      _c("v-spacer"),
                      _c(
                        "v-dialog",
                        {
                          attrs: { "max-width": "700px" },
                          model: {
                            value: _vm.dialog,
                            callback: function ($$v) {
                              _vm.dialog = $$v
                            },
                            expression: "dialog",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-title", [
                                _c("span", { staticClass: "text-h5" }, [
                                  _vm._v("Edit Technician"),
                                ]),
                              ]),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { md: "12" } },
                                            [
                                              _c("v-text-field", {
                                                class: {
                                                  "is-invalid":
                                                    _vm.submitted &&
                                                    _vm.$v.technicianToShow
                                                      .firstName.$error,
                                                },
                                                attrs: {
                                                  tabindex: "1",
                                                  outlined: "",
                                                  label: "First Name",
                                                },
                                                model: {
                                                  value:
                                                    _vm.technicianToShow
                                                      .firstName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.technicianToShow,
                                                      "firstName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "technicianToShow.firstName",
                                                },
                                              }),
                                              _vm.submitted &&
                                              !_vm.$v.technicianToShow.firstName
                                                .required
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "invalid-feedback mt-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " First Name is Required "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("v-text-field", {
                                                class: {
                                                  "is-invalid":
                                                    _vm.submitted &&
                                                    _vm.$v.technicianToShow
                                                      .lastName.$error,
                                                },
                                                attrs: {
                                                  tabindex: "2",
                                                  outlined: "",
                                                  label: "Last Name",
                                                },
                                                model: {
                                                  value:
                                                    _vm.technicianToShow
                                                      .lastName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.technicianToShow,
                                                      "lastName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "technicianToShow.lastName",
                                                },
                                              }),
                                              _vm.submitted &&
                                              !_vm.$v.technicianToShow.lastName
                                                .required
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "invalid-feedback mt-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Name is Required "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("v-select", {
                                                class: {
                                                  "is-invalid":
                                                    _vm.submitted &&
                                                    _vm.$v.technicianToShow.type
                                                      .id.$error,
                                                },
                                                attrs: {
                                                  tabindex: "3",
                                                  items: _vm.techTypes,
                                                  "item-value": "id",
                                                  "item-text": "name",
                                                  label: "Technician Type",
                                                  solo: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.technicianToShow.type
                                                      .id,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.technicianToShow.type,
                                                      "id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "technicianToShow.type.id",
                                                },
                                              }),
                                              _vm.submitted &&
                                              !_vm.$v.technicianToShow.type.id
                                                .required
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "invalid-feedback ml-3",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Technician Type is Required "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("v-autocomplete", {
                                                attrs: {
                                                  tabindex: "4",
                                                  items: _vm.levels,
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  label: "Level",
                                                  outlined: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.technicianToShow.level
                                                      .id,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.technicianToShow
                                                        .level,
                                                      "id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "technicianToShow.level.id",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "mb-3",
                                                attrs: {
                                                  tabindex: "5",
                                                  outlined: "",
                                                  label: "Primary Phone",
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.formatPhoneNumber1()
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.primary.phoneNumber,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.primary,
                                                      "phoneNumber",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "primary.phoneNumber",
                                                },
                                              }),
                                              _c("v-text-field", {
                                                staticClass: "mb-3",
                                                class: {
                                                  "is-invalid":
                                                    _vm.submitted &&
                                                    _vm.$v.technicianToShow
                                                      .email.$error,
                                                },
                                                attrs: {
                                                  tabindex: "7",
                                                  outlined: "",
                                                  label: "Email",
                                                },
                                                model: {
                                                  value:
                                                    _vm.technicianToShow.email,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.technicianToShow,
                                                      "email",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "technicianToShow.email",
                                                },
                                              }),
                                              _vm.submitted &&
                                              !_vm.$v.technicianToShow.email
                                                .required
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "invalid-feedback",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Email is Required "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.submitted &&
                                              !_vm.$v.technicianToShow.email
                                                .email
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "invalid-feedback",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Email is not valid "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "mb-3",
                                                attrs: {
                                                  tabindex: "6",
                                                  outlined: "",
                                                  label: "Secondary Phone",
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.formatPhoneNumber2()
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.Secondry.phoneNumber,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.Secondry,
                                                      "phoneNumber",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "Secondry.phoneNumber",
                                                },
                                              }),
                                              _c("v-text-field", {
                                                staticClass: "mb-3",
                                                attrs: {
                                                  tabindex: "8",
                                                  outlined: "",
                                                  label: "Hourly Rate",
                                                },
                                                model: {
                                                  value:
                                                    _vm.technicianToShow
                                                      .hourlyRate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.technicianToShow,
                                                      "hourlyRate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "technicianToShow.hourlyRate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-divider", { staticClass: "mb-10" }),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "mb-3",
                                                    attrs: {
                                                      tabindex: "9",
                                                      outlined: "",
                                                      label: "Street",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.technicianToShow
                                                          .address.street,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.technicianToShow
                                                            .address,
                                                          "street",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "technicianToShow.address.street",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "mb-3",
                                                    attrs: {
                                                      tabindex: "10",
                                                      outlined: "",
                                                      label:
                                                        "ZIP / Postal Code",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.technicianToShow
                                                          .address.postalCode,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.technicianToShow
                                                            .address,
                                                          "postalCode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "technicianToShow.address.postalCode",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "mb-3",
                                                    attrs: {
                                                      tabindex: "11",
                                                      outlined: "",
                                                      label: "Details",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.technicianToShow
                                                          .address.details,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.technicianToShow
                                                            .address,
                                                          "details",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "technicianToShow.address.details",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("v-col", {
                                                attrs: { cols: "12", md: "6" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-autocomplete", {
                                                    staticClass: "mb-3",
                                                    attrs: {
                                                      tabindex: "12",
                                                      items: _vm.countries,
                                                      "item-text": "name",
                                                      "item-value": "id",
                                                      label: "Country",
                                                      outlined: "",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.Editonchange()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.technicianToShow
                                                          .address.country.id,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.technicianToShow
                                                            .address.country,
                                                          "id",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "technicianToShow.address.country.id",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-autocomplete", {
                                                    staticClass: "mb-3",
                                                    attrs: {
                                                      tabindex: "13",
                                                      items: _vm.EditStates,
                                                      "item-text": "name",
                                                      "item-value": "id",
                                                      label: "State",
                                                      outlined: "",
                                                      "search-input":
                                                        _vm.searchEditState,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.EditSearchInCity()
                                                      },
                                                      "update:searchInput":
                                                        function ($event) {
                                                          _vm.searchEditState =
                                                            $event
                                                        },
                                                      "update:search-input":
                                                        function ($event) {
                                                          _vm.searchEditState =
                                                            $event
                                                        },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.technicianToShow
                                                          .address.state.id,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.technicianToShow
                                                            .address.state,
                                                          "id",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "technicianToShow.address.state.id",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-autocomplete", {
                                                    staticClass: "mb-3",
                                                    attrs: {
                                                      tabindex: "14",
                                                      items: _vm.Editcities,
                                                      "item-text": "name",
                                                      "item-value": "id",
                                                      label: "City",
                                                      outlined: "",
                                                      "search-input":
                                                        _vm.searchEditCity,
                                                    },
                                                    on: {
                                                      "update:searchInput":
                                                        function ($event) {
                                                          _vm.searchEditCity =
                                                            $event
                                                        },
                                                      "update:search-input":
                                                        function ($event) {
                                                          _vm.searchEditCity =
                                                            $event
                                                        },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.technicianToShow
                                                          .address.city.id,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.technicianToShow
                                                            .address.city,
                                                          "id",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "technicianToShow.address.city.id",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("v-col", {
                                                attrs: { cols: "12", md: "6" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "cancel-update-btn",
                                      attrs: { tabindex: "15", text: "" },
                                      on: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.close()
                                        },
                                        click: _vm.close,
                                      },
                                    },
                                    [_vm._v("Cancel")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "create-order-btn mr-1",
                                      attrs: { tabindex: "16" },
                                      on: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.save()
                                        },
                                        click: _vm.save,
                                      },
                                    },
                                    [_vm._v("Update")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-snackbar",
                    {
                      attrs: { timeout: 3000, color: _vm.snackColor },
                      scopedSlots: _vm._u([
                        {
                          key: "action",
                          fn: function ({ attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._b(
                                  {
                                    attrs: { text: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.snack = false
                                      },
                                    },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                [_vm._v(" Close ")]
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.snack,
                        callback: function ($$v) {
                          _vm.snack = $$v
                        },
                        expression: "snack",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.snackText) + " ")]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "item.address",
              fn: function ({ item }) {
                return [
                  item.address
                    ? _c("p", [
                        _c("span", [_vm._v(_vm._s(item.address.street))]),
                        _vm._v(" - "),
                        item.address.city
                          ? _c("span", [_vm._v(_vm._s(item.address.city.name))])
                          : _vm._e(),
                        _vm._v(" - "),
                        item.address.state
                          ? _c("span", [
                              _vm._v(_vm._s(item.address.state.target)),
                            ])
                          : _vm._e(),
                        _vm._v(" - "),
                        item.address.country
                          ? _c("span", [
                              _vm._v(
                                " " + _vm._s(item.address.country.name) + " "
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "item.firstName",
              fn: function ({ item }) {
                return [
                  _c("p", [
                    _vm._v(
                      _vm._s(item.firstName) + " " + _vm._s(item.lastName)
                    ),
                  ]),
                ]
              },
            },
            {
              key: "item.technicianPhones",
              fn: function ({ item }) {
                return _vm._l(item.technicianPhones, function (ph, index) {
                  return _c("p", { key: index }, [
                    _c(
                      "span",
                      {
                        class: {
                          "is-Primary": ph.isPrimary,
                        },
                      },
                      [_vm._v(_vm._s(ph.phoneNumber))]
                    ),
                  ])
                })
              },
            },
          ]),
        }),
        _c(
          "v-dialog",
          {
            attrs: { "max-width": "500px" },
            model: {
              value: _vm.dialogDelete,
              callback: function ($$v) {
                _vm.dialogDelete = $$v
              },
              expression: "dialogDelete",
            },
          },
          [
            _c(
              "v-card",
              [
                _c("v-card-title", { staticClass: "text-h5" }, [
                  _vm._v("Are you sure you want to delete this item?"),
                ]),
                _c(
                  "v-card-actions",
                  [
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "blue darken-1", text: "" },
                        on: { click: _vm.closeDelete },
                      },
                      [_vm._v("Cancel")]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "blue darken-1", text: "" },
                        on: { click: _vm.deleteItemConfirm },
                      },
                      [_vm._v("OK")]
                    ),
                    _c("v-spacer"),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }