
import http from "../http"
export const dataService = {


    getAll(endPoint, callback_success) {
       
        http.get(endPoint).then(response => {

            callback_success(response.data)

        });
    },

    getList(endPoint, data, callback_success) {
        http.post(endPoint, JSON.stringify(data)).then(response => {

            callback_success(response)

        });
    },

    create(endPoint, data, callback_success,callback_fail) {
      
        http.post(endPoint, JSON.stringify(data)).then(response => {
           
          
            callback_success(response)

        }).catch(er => {

          

         
                callback_fail(er.errors)
              
            
        });
    },
    update(endPoint, data, callback_success) {
        http.post(endPoint, JSON.stringify(data)).then(response => {
            callback_success(response)

        });
    },
    delete(endPoint, data, callback_success) {
        http.post(endPoint, JSON.stringify(data)).then(response => {
            callback_success(response)

        });
    },
    postDelete(endPoint, callback_success) {
        http.post(endPoint).then(response => {
            callback_success(response)

        });
    },

}
