var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload-section", attrs: { id: "users" } },
    [
      !_vm.$vuetify.breakpoint.xsOnly ? _c("SideMenu") : _vm._e(),
      _c("div", { staticClass: "user-creation ml-4 mt-4" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "profile-card" },
            [
              _c("i", {
                staticClass: "fas fa-edit edit-profile",
                on: {
                  click: function ($event) {
                    _vm.editMode = !_vm.editMode
                  },
                },
              }),
              _c("br"),
              _c(
                "v-avatar",
                {
                  staticStyle: { width: "48px", height: "48px" },
                  attrs: { color: "#172450" },
                },
                [
                  _vm.profilepic
                    ? _c("span", { staticClass: "white--text text-h6" }, [
                        _vm._v(_vm._s(_vm.profilepic)),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "Profile-data",
                  staticStyle: {
                    color: "rgb(23, 36, 80)",
                    "font-size": "18px",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.profileName) + " ")]
              ),
              _vm.editMode != true
                ? _c(
                    "v-row",
                    { staticStyle: { "margin-left": "9%" } },
                    [
                      _c("v-col", { attrs: { md: "2" } }, [
                        _c("p", { staticClass: "info-lebel" }, [
                          _vm._v("First Name"),
                        ]),
                        _c("p", { staticClass: "info-lebel" }, [
                          _vm._v("Last Name"),
                        ]),
                        _c("p", { staticClass: "info-lebel" }, [
                          _vm._v("Address"),
                        ]),
                        _c("p", { staticClass: "info-lebel" }, [
                          _vm._v("Email"),
                        ]),
                        _c("p", { staticClass: "info-lebel" }, [
                          _vm._v("Phone"),
                        ]),
                      ]),
                      _c("v-col", { attrs: { md: "3" } }, [
                        _vm.userProfile.firstName
                          ? _c("p", { staticClass: "info-value" }, [
                              _vm._v(
                                " " + _vm._s(_vm.userProfile.firstName) + " "
                              ),
                            ])
                          : _c("p", { staticClass: "info-value" }, [
                              _vm._v("-"),
                            ]),
                        _vm.userProfile.lastName
                          ? _c("p", { staticClass: "info-value" }, [
                              _vm._v(
                                " " + _vm._s(_vm.userProfile.lastName) + " "
                              ),
                            ])
                          : _c("p", { staticClass: "info-value" }, [
                              _vm._v("-"),
                            ]),
                        _vm.userProfile.address
                          ? _c("p", { staticClass: "info-value" }, [
                              _vm._v(
                                " " + _vm._s(_vm.userProfile.address) + " "
                              ),
                            ])
                          : _c("p", { staticClass: "info-value" }, [
                              _vm._v("-"),
                            ]),
                        _vm.userProfile.email
                          ? _c("p", { staticClass: "info-value" }, [
                              _vm._v(" " + _vm._s(_vm.userProfile.email) + " "),
                            ])
                          : _c("p", { staticClass: "info-value" }, [
                              _vm._v("-"),
                            ]),
                        _vm.userProfile.phoneNumber
                          ? _c("p", { staticClass: "info-value" }, [
                              _vm._v(
                                " " + _vm._s(_vm.userProfile.phoneNumber) + " "
                              ),
                            ])
                          : _c("p", { staticClass: "info-value" }, [
                              _vm._v("-"),
                            ]),
                      ]),
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    { staticStyle: { "margin-left": "9%" } },
                    [
                      _c("v-col", { attrs: { md: "2" } }, [
                        _c("p", { staticClass: "info-lebel" }, [
                          _vm._v("First Name"),
                        ]),
                        _c("p", { staticClass: "info-lebel mt-11" }, [
                          _vm._v("Last Name"),
                        ]),
                        _c("p", { staticClass: "info-lebel mt-11" }, [
                          _vm._v("Address"),
                        ]),
                        _c("p", { staticClass: "info-lebel" }, [
                          _vm._v("Phone"),
                        ]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { id: "editProfile", md: "3" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-7",
                            attrs: { solo: "" },
                            model: {
                              value: _vm.userProfile.firstName,
                              callback: function ($$v) {
                                _vm.$set(_vm.userProfile, "firstName", $$v)
                              },
                              expression: "userProfile.firstName",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: { solo: "" },
                            model: {
                              value: _vm.userProfile.lastName,
                              callback: function ($$v) {
                                _vm.$set(_vm.userProfile, "lastName", $$v)
                              },
                              expression: "userProfile.lastName",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: { solo: "" },
                            model: {
                              value: _vm.userProfile.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.userProfile, "address", $$v)
                              },
                              expression: "userProfile.address",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: { solo: "" },
                            on: {
                              blur: function ($event) {
                                return _vm.formatPhoneNumber()
                              },
                            },
                            model: {
                              value: _vm.userProfile.phoneNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.userProfile, "phoneNumber", $$v)
                              },
                              expression: "userProfile.phoneNumber",
                            },
                          }),
                          _c(
                            "v-btn",
                            {
                              staticClass: "edit-profile-btn",
                              on: { click: _vm.updateProfileData },
                            },
                            [_vm._v("Save")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _vm.userProfile.lastResetURL !== ""
                ? _c(
                    "span",
                    {
                      staticStyle: {
                        color: "rgb(23, 36, 80)",
                        "font-size": "18px",
                      },
                    },
                    [
                      _c(
                        "a",
                        { attrs: { href: _vm.userProfile.lastResetURL } },
                        [
                          _vm._v(
                            " Reset Link Is available: Please right click and copy link "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "profile-card" }, [
            _c(
              "div",
              [
                _c("v-row", { staticStyle: { margin: "0px" } }, [
                  _c(
                    "span",
                    {
                      staticClass: "Profile-data",
                      staticStyle: {
                        color: "rgb(23, 36, 80)",
                        "font-size": "18px",
                      },
                    },
                    [_vm._v(" System Settings")]
                  ),
                  _c("i", {
                    staticClass: "fas fa-edit edit-profile",
                    on: {
                      click: function ($event) {
                        _vm.editModeSyatemSettings = !_vm.editModeSyatemSettings
                      },
                    },
                  }),
                ]),
                _c("br"),
                _vm.editModeSyatemSettings == true
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { md: "6" } },
                              [
                                _vm.userProfile.systemUserTypes
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        items: _vm.userTypes,
                                        "item-value": "id",
                                        "item-text": "name",
                                        outlined: "",
                                        label: "Select Type",
                                      },
                                      on: { change: _vm.getGroups },
                                      model: {
                                        value:
                                          _vm.userProfile.systemUserTypes[0].id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.userProfile.systemUserTypes[0],
                                            "id",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "userProfile.systemUserTypes[0].id",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("v-col", { attrs: { md: "6" } }),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticStyle: { "margin-top": "2%" } },
                          [
                            _c("v-col", { attrs: { md: "4" } }),
                            _c("v-col", { attrs: { md: "4" } }),
                            _c(
                              "v-col",
                              { attrs: { md: "4" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "edit-profile-btn",
                                    on: { click: _vm.updatePermission },
                                  },
                                  [_vm._v("Save")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-chip",
                              {
                                staticStyle: {
                                  "margin-bottom": "4%",
                                  "margin-left": "5%",
                                  "margin-top": "1%",
                                },
                                attrs: {
                                  color: "green",
                                  "text-color": "white",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.userProfile.systemUserTypes[0].name
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "profile-card" },
            [
              _c("v-row", { staticStyle: { margin: "0px" } }, [
                _c(
                  "span",
                  {
                    staticClass: "Profile-data",
                    staticStyle: {
                      color: "rgb(23, 36, 80)",
                      "font-size": "18px",
                    },
                  },
                  [_vm._v(" Client Settings")]
                ),
                _c("i", {
                  staticClass: "fas fa-edit edit-profile",
                  on: {
                    click: function ($event) {
                      _vm.editClientsSettings = !_vm.editClientsSettings
                    },
                  },
                }),
              ]),
              _vm.editClientsSettings == true
                ? _c(
                    "div",
                    { staticStyle: { "margin-top": "2%" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "12" } },
                            [
                              _c("v-autocomplete", {
                                ref: "clientSearch",
                                attrs: {
                                  multiple: "",
                                  outlined: "",
                                  label: "Select Clients",
                                  items: _vm.clients,
                                  "item-text": "name",
                                  "item-value": "id",
                                },
                                on: { change: _vm.getAccounts },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "v-chip",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "input-value": data.selected,
                                                  close: "",
                                                },
                                                on: {
                                                  click: data.select,
                                                  "click:close": function (
                                                    $event
                                                  ) {
                                                    return _vm.RemoveClient(
                                                      data.item.id
                                                    )
                                                  },
                                                },
                                              },
                                              "v-chip",
                                              data.attrs,
                                              false
                                            ),
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(data.item.name) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3635343991
                                ),
                                model: {
                                  value: _vm.clientPrivillages,
                                  callback: function ($$v) {
                                    _vm.clientPrivillages = $$v
                                  },
                                  expression: "clientPrivillages",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { md: "12" } },
                            [
                              _c("v-autocomplete", {
                                ref: "accountsearch",
                                attrs: {
                                  outlined: "",
                                  chips: "",
                                  items: _vm.accounts,
                                  "item-value": "id",
                                  "item-text": "name",
                                  label: "Select Account",
                                  multiple: "",
                                  "search-input": _vm.accountsearch,
                                },
                                on: {
                                  change: _vm.getCompanies,
                                  "update:searchInput": function ($event) {
                                    _vm.accountsearch = $event
                                  },
                                  "update:search-input": function ($event) {
                                    _vm.accountsearch = $event
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "v-chip",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  color: "#7cab9a54",
                                                  "input-value": data.selected,
                                                  close: "",
                                                },
                                                on: {
                                                  click: data.select,
                                                  "click:close": function (
                                                    $event
                                                  ) {
                                                    return _vm.RemoveAccount(
                                                      data.item.id
                                                    )
                                                  },
                                                },
                                              },
                                              "v-chip",
                                              data.attrs,
                                              false
                                            ),
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(data.item.name) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1629426441
                                ),
                                model: {
                                  value: _vm.accountPrivillages,
                                  callback: function ($$v) {
                                    _vm.accountPrivillages = $$v
                                  },
                                  expression: "accountPrivillages",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { md: "12" } },
                            [
                              _c("v-autocomplete", {
                                ref: "companysearch",
                                attrs: {
                                  outlined: "",
                                  chips: "",
                                  items: _vm.companies,
                                  "item-value": "id",
                                  "item-text": "name",
                                  label: "Select Company",
                                  multiple: "",
                                  "search-input": _vm.companysearch,
                                },
                                on: {
                                  change: _vm.getLocations,
                                  "update:searchInput": function ($event) {
                                    _vm.companysearch = $event
                                  },
                                  "update:search-input": function ($event) {
                                    _vm.companysearch = $event
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "v-chip",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "input-value": data.selected,
                                                  close: "",
                                                  color: "#7cab9a54",
                                                },
                                                on: {
                                                  click: data.select,
                                                  "click:close": function (
                                                    $event
                                                  ) {
                                                    return _vm.RemoveCompany(
                                                      data.item.id
                                                    )
                                                  },
                                                },
                                              },
                                              "v-chip",
                                              data.attrs,
                                              false
                                            ),
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(data.item.name) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2045270895
                                ),
                                model: {
                                  value: _vm.companyPrivillages,
                                  callback: function ($$v) {
                                    _vm.companyPrivillages = $$v
                                  },
                                  expression: "companyPrivillages",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { md: "12" } },
                            [
                              _c("v-autocomplete", {
                                ref: "locationSearch",
                                attrs: {
                                  outlined: "",
                                  chips: "",
                                  items: _vm.locations,
                                  "item-value": "id",
                                  "item-text": "name",
                                  label: "Select Location",
                                  multiple: "",
                                  "search-input": _vm.locationSearch,
                                },
                                on: {
                                  "update:searchInput": function ($event) {
                                    _vm.locationSearch = $event
                                  },
                                  "update:search-input": function ($event) {
                                    _vm.locationSearch = $event
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "v-chip",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  color: "#7cab9a54",
                                                  "input-value": data.selected,
                                                  close: "",
                                                },
                                                on: {
                                                  click: data.select,
                                                  "click:close": function (
                                                    $event
                                                  ) {
                                                    return _vm.RemoveLocation(
                                                      data.item.id
                                                    )
                                                  },
                                                },
                                              },
                                              "v-chip",
                                              data.attrs,
                                              false
                                            ),
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(data.item.name) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2480598581
                                ),
                                model: {
                                  value: _vm.locationPrivillages,
                                  callback: function ($$v) {
                                    _vm.locationPrivillages = $$v
                                  },
                                  expression: "locationPrivillages",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { md: "4" } }),
                          _c("v-col", { attrs: { md: "4" } }),
                          _c(
                            "v-col",
                            { attrs: { md: "4" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "edit-profile-btn",
                                  on: { click: _vm.updateDataPrivillages },
                                },
                                [_vm._v("Save")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticStyle: { "margin-top": "2%" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "12" } },
                            [
                              _c("v-autocomplete", {
                                ref: "clientSearch",
                                attrs: {
                                  disabled: "",
                                  multiple: "",
                                  outlined: "",
                                  label: "Select Clients",
                                  items: _vm.clients,
                                  "item-text": "name",
                                  "item-value": "id",
                                },
                                on: { change: _vm.getAccounts },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selection",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "v-chip",
                                          _vm._b(
                                            {
                                              attrs: {
                                                "input-value": data.selected,
                                                color: "#7cab9a54",
                                              },
                                            },
                                            "v-chip",
                                            data.attrs,
                                            false
                                          ),
                                          [
                                            _vm._v(
                                              " " + _vm._s(data.item.name) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.clientPrivillages,
                                  callback: function ($$v) {
                                    _vm.clientPrivillages = $$v
                                  },
                                  expression: "clientPrivillages",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { md: "12" } },
                            [
                              _c("v-autocomplete", {
                                ref: "accountsearch",
                                attrs: {
                                  disabled: "",
                                  outlined: "",
                                  chips: "",
                                  items: _vm.accounts,
                                  "item-value": "id",
                                  "item-text": "name",
                                  label: "Select Account",
                                  multiple: "",
                                  "search-input": _vm.accountsearch,
                                },
                                on: {
                                  change: _vm.getCompanies,
                                  "update:searchInput": function ($event) {
                                    _vm.accountsearch = $event
                                  },
                                  "update:search-input": function ($event) {
                                    _vm.accountsearch = $event
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selection",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "v-chip",
                                          _vm._b(
                                            {
                                              attrs: {
                                                "input-value": data.selected,
                                                color: "#7cab9a54",
                                              },
                                            },
                                            "v-chip",
                                            data.attrs,
                                            false
                                          ),
                                          [
                                            _vm._v(
                                              " " + _vm._s(data.item.name) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.accountPrivillages,
                                  callback: function ($$v) {
                                    _vm.accountPrivillages = $$v
                                  },
                                  expression: "accountPrivillages",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { md: "12" } },
                            [
                              _c("v-autocomplete", {
                                ref: "companysearch",
                                attrs: {
                                  disabled: "",
                                  outlined: "",
                                  chips: "",
                                  items: _vm.companies,
                                  "item-value": "id",
                                  "item-text": "name",
                                  label: "Select Company",
                                  multiple: "",
                                  "search-input": _vm.companysearch,
                                },
                                on: {
                                  change: _vm.getLocations,
                                  "update:searchInput": function ($event) {
                                    _vm.companysearch = $event
                                  },
                                  "update:search-input": function ($event) {
                                    _vm.companysearch = $event
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selection",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "v-chip",
                                          _vm._b(
                                            {
                                              attrs: {
                                                "input-value": data.selected,
                                                color: "#7cab9a54",
                                              },
                                            },
                                            "v-chip",
                                            data.attrs,
                                            false
                                          ),
                                          [
                                            _vm._v(
                                              " " + _vm._s(data.item.name) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.companyPrivillages,
                                  callback: function ($$v) {
                                    _vm.companyPrivillages = $$v
                                  },
                                  expression: "companyPrivillages",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { md: "12" } },
                            [
                              _c("v-autocomplete", {
                                ref: "locationSearch",
                                attrs: {
                                  disabled: "",
                                  outlined: "",
                                  chips: "",
                                  items: _vm.locations,
                                  "item-value": "id",
                                  "item-text": "name",
                                  label: "Select Location",
                                  multiple: "",
                                  "search-input": _vm.locationSearch,
                                },
                                on: {
                                  "update:searchInput": function ($event) {
                                    _vm.locationSearch = $event
                                  },
                                  "update:search-input": function ($event) {
                                    _vm.locationSearch = $event
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selection",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "v-chip",
                                          _vm._b(
                                            {
                                              attrs: {
                                                "input-value": data.selected,
                                                color: "#7cab9a54",
                                              },
                                            },
                                            "v-chip",
                                            data.attrs,
                                            false
                                          ),
                                          [
                                            _vm._v(
                                              " " + _vm._s(data.item.name) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.locationPrivillages,
                                  callback: function ($$v) {
                                    _vm.locationPrivillages = $$v
                                  },
                                  expression: "locationPrivillages",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }