<template>
  <div id="work-order" class="handle-test">
    <v-row>
      <v-col cols="12" md="10">
        <p class="table-title">Technician Payroll</p>
      </v-col>

      <v-col md="1" cols="12" class="Gml-3"> </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6" class="pad0">
        <v-row>
          <v-col cols="12" md="4">
            <v-autocomplete :items="filterBy" :item-value="'id'" :item-text="'name'" v-model="dateRange" label="Date"
              outlined>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-menu v-if="dateRange == 6" ref="menu" v-model="menuStartDate" :close-on-content-click="false"
              :return-value.sync="SDFrom" transition="scale-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="SDFrom" label="   Service Date Start Date" prepend-inner-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on" outlined></v-text-field>
              </template>
              <v-date-picker v-model="SDFrom" no-title scrollable @input="$refs.menu.save(SDFrom)" @change="getWOByDate">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuStartDate = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(SDFrom)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4">
            <v-menu v-if="dateRange == 6" ref="menu2" v-model="menuEndDate" :close-on-content-click="false"
              :return-value.sync="SDTo" transition="scale-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="SDTo" label="Service Date End Date" prepend-inner-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on" outlined></v-text-field>
              </template>
              <v-date-picker v-model="SDTo" no-title scrollable @input="$refs.menu2.save(SDTo)" @change="getWOByDate">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuEndDate = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu2.save(SDTo)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="6" class="pad0">
        <v-row>
          <v-col cols="12" md="4"> </v-col>


          <v-col md="2" style="margin-left: 2%">
            <v-btn @click="getData" class="btn-creation diff-style mt-1" color="#D92020">
              <v-icon>fa-solid fa-play</v-icon>

            </v-btn>



          </v-col>
          <v-col md="2">
            <v-btn @click="exportData" class="btn-creation wo-diff-style">
              <img style="padding-right: 10%" src="@/assets/images/export.png" />
              <!-- <v-icon>fa-solid fa-paperclip</v-icon> -->
              Export File
            </v-btn>
          </v-col>

        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" class="pad0">
        <v-row>
          <v-col cols="12" md="4">
            <v-autocomplete :items="techTypes" :item-value="'id'" :item-text="'name'"
              v-model="requestData.technicianTypeIds" label="Technician Type" outlined multiple>
              <template v-slot:selection="data">
                <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select"
                  @click:close="removeTechType(data.item.id)">
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete :items="technicians" v-model="requestData.technicianIds" :item-text="
              (item) =>
                item.firstName +
                ' ' +
                item.lastName +
                ' ' +
                '-' +
                ' ' +
                item.email
            " item-value="id" label="Select Technicians" :search-input.sync="techsearch" outlined multiple
              ref="techsearch">
              <template v-slot:selection="data">
                <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select"
                  @click:close="removeTech(data.item.id)">
                  {{ data.item.firstName + " " + data.item.lastName }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete ref="clientSearch" multiple outlined label="Select Clients" :items="clients" item-text="name"
              item-value="id" v-model="requestData.clientIds" @change="handleClientFilter">
              <template v-slot:selection="data">
                <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select"
                  @click:close="removeClient(data.item.id)">
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="6" class="pad0">
        <v-row>
          <v-col cols="12" md="4">
            <main id="container" v-scroll:#container="fixPosition">
              <v-autocomplete ref="accountSearch" :items="accounts" item-text="name" item-value="id"
                v-model="requestData.accountIds" multiple outlined label="Select Accounts"
                :search-input.sync="accountsearch" @change="handleAccountFilter">
                <template v-slot:selection="data">
                  <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select"
                    @click:close="removeAccount(data.item.id)">
                    {{ data.item.name }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </main>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete ref="locationSearch" :items="locations" item-text="name" item-value="id"
              v-model="requestData.branchIds" multiple outlined label="Select Locations" @change="handleLocationFilter"
              :search-input.sync="locationSearch">
              <template v-slot:selection="data">
                <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select"
                  @click:close="removelocation(data.item.id)">
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn @click="reset" class="btn-creation diff-style mt-1" color="#D92020">Reset Filter</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- ==========================Table =================================== -->
    <div style="margin-bottom: 3%;">
      <v-data-table :loading="isLoaded" loading-text="Loading... Please wait" :headers="headers"
        :items-per-page="reportData.length" :items="reportData" class="elevation-1" item-key="id" show-expand
        hide-default-footer :expanded.sync="expanded">
        <template v-slot:item.totalPayableAmount="{ item }">
          <p>
            {{ "$ " + item.totalPayableAmount }}
          </p>
        </template>
        <template v-slot:item.totalAttendHours="{ item }">
          <p>
            {{ item.totalAttendHours + " hr" }}
          </p>
        </template>

        <template #expanded-item="{ headers, item }">
          <td class="px-0 py-2" :colspan="headers.length">
            <v-data-table :items-per-page="item.wotClockingReportDTOs.length" :headers="SubHeaders"
              :items="item.wotClockingReportDTOs" class="elevation-1" hide-default-footer :colspan="7">
            

            
              <template v-slot:item.serviceDate="{ item }">
                {{
                   new Date(
                    item.serviceDate
                            ).toLocaleDateString() 
                }}
              </template>
              <template v-slot:item.totalPayableAmount="{ item }">
                <p>
                  {{ "$ " + item.totalPayableAmount }}
                </p>
              </template>

              <template v-slot:item.billRate="{ item }">
                <p>
                  {{ "$ " + item.billRate }}
                </p>
              </template>

              <template v-slot:item.travel="{ item }">
                <p>
                  {{ "$ " + item.travel }}
                </p>
              </template>

              <template v-slot:item.totalBillRate="{ item }">
                <p>
                  {{ "$ " + item.totalBillRate }}
                </p>
              </template>

              <template v-slot:item.cleaningSupplies="{ item }">
                <p>
                  {{ "$ " + item.cleaningSupplies }}
                </p>
              </template>
              <template v-slot:item.hourlyRate="{ item }">
                <p>
                  {{ "$ " + item.hourlyRate }}
                </p>
              </template>
              <template v-slot:item.totalAttendHours="{ item }">
                <p>
                  {{ item.totalAttendHours + " hr" }}
                </p>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </div>
    <br />
    <br />

  </div>
</template>
<script>
import { dataService } from "@/services/data-service";
import store from "@/store";
import { excelParser } from "@/excel-parser";

export default {
  data() {
    return {
      //play: false,

      dateRange: null,
      reportData: [],
      //============== Me
      requestData: {
        technicianIds: null,
        technicianTypeIds: null,
        branchIds: null,
        companyIds: null,
        accountIds: null,
        clientIds: null,
        createdOn: null,
        serviceDateOn: {
          from: null,
          to: null,
        },
        dateRange: null,
      },

      expanded: [],

      // woClockingTimeSheetReportDTO: (...)

      SubHeaders: [
        // { text: "Timesheet ID", value: "wotClockingTimeSheetId" },
        { text: "Field Nation Id", value: "fieldNationId" },
        { text: "order Id", value: "orderId" },
        { text: "Bill Rate", value: "billRate" },
        { text: "Payment Type", value: "paymentType" },

        { text: "Σ Billable Amount", value: "totalBillRate" },
        { text: "Travel", value: "travel" },
        { text: "Cleaning Supplies", value: "cleaningSupplies" },
        { text: "Σ Attend Hours", value: "totalAttendHours" },
       
        { text: "Σ Payable Amount", value: "totalPayableAmount" },
        { text: "Service Date", value: "serviceDate" },
      
 // { text: "hourly Rate", value: "hourlyRate" },
        // { text: "Clocking In Date", value: "clockingInDate" },
        // { text: "Clocking Out Date", value: "clockingOutDate" },
        // {
        //   text: "Approval Date",
        //   value: "woClockingTimeSheetReportDTO.approved",
        // // },
        // { text: "Client Name", value: "clientName" },
        { text: "Account Name", value: "accountName" },
        { text: "Location Name", value: "branchName" },
        { text: "Address", value: "address" },
      ],

      totalLength: 0,

      headers: [
        { text: "Technician Name", value: "technicianName" },

        // { text: "Technician Type", value: "technicianTypeName" },
        { text: "Σ Attend Hours", value: "totalAttendHours" },
        { text: "Σ Payable Amount", value: "totalPayableAmount" },
      ],
      filterId: 0,
      techTypeId: 0,
      techTypes: [
        {
          id: 1,
          name: "W-2",
        },
        {
          id: 2,
          name: "1099",
        },
        {
          id: 3,
          name: "TEMP",
        },
        {
          id: 4,
          name: "Vendor",
        },
      ],
      filterBy: [
        {
          id: 1,
          name: "Last Week",
        },
        {
          id: 2,
          name: " Last Month From ",
        },
        {
          id: 3,
          name: "  Last Quarter",
        },
        {
          id: 4,
          name: "Year To Date",
        },
        {
          id: 5,
          name: " Last Fiscal Year",
        },
        {
          id: 6,
          name: " Custom Date",
        },
      ],
      ExportData: [],
      isLoaded: false,
      // expanded: [],
      singleExpand: false,
      techIds: [],
      techniciansToAssign: [],
      menuEndDate: false,
      menuStartDate: false,
      searchInput: "",
      diffTechTerm: "",
      technicianIds: [],
      clientIds: [],
      clients: [],
      accounts: [],
      accountIds: [],
      locations: [],
      locationIds: [],
      sizeOptions: [
        {
          id: 10,
          name: 10,
        },
        {
          id: 20,
          name: 20,
        },
        {
          id: 30,
          name: 30,
        },
        {
          id: 50,
          name: 50,
        },
        {
          id: 100,
          name: 100,
        },
      ],
      queryTerm: "",
      accountTerm: "",
      locationTerm: "",
      technicianID: "",
      totalPages: 0,
      technicians: [],
      selected: [],
      techArray: [],
      tableSearch: null,
      value: null,
      data: [],
      SDFrom: null,
      SDTo: null,
    };
  },
  methods: {
    fixPosition() {
      const menu = document.getElementById("app").nextElementSibling
      if (!menu) return
      const input = this.$refs['scroll-track-input'].$el.getBoundingClientRect()
      menu.style.top = input.bottom - 50 + 'px'
    },
    removeAccount(item) {


      const index = this.requestData.accountIds.indexOf(item);
      if (index >= 0) this.requestData.accountIds.splice(index, 1);
    },
    removelocation(item) {


      const index = this.requestData.branchIds.indexOf(item);
      if (index >= 0) this.requestData.branchIds.splice(index, 1);
    },

    removeTechType(item) {


      const index = this.requestData.technicianTypeIds.indexOf(item);
      if (index >= 0) this.requestData.technicianTypeIds.splice(index, 1);
    },
    removeTech(item) {


      const index = this.requestData.technicianIds.indexOf(item);
      if (index >= 0) this.requestData.technicianIds.splice(index, 1);
    },
    removeClient(item) {


      const index = this.requestData.clientIds.indexOf(item);
      if (index >= 0) this.requestData.clientIds.splice(index, 1);
    },
    exportData() {
      debugger; // eslint-disable-line no-debugger
      let sampleData = this.ExportData;
      sampleData.forEach(element => {
      //   element.clockingIn = this.convertUTCDateToLocalDate(
      //     new Date(element.clockingIn)
      //   ).toLocaleString()

      //   element.clockingOut = this.convertUTCDateToLocalDate(
      //     new Date(element.clockingOut)
      //   ).toLocaleString()
        
        element.serviceDate =  new Date(
          element.serviceDate 
                            ).toLocaleDateString() 
      
        const serviceDateArray = element.serviceDate.split(",");
        element.serviceDate=serviceDateArray[0]
      //   const approvedDateArray = element.approved.split(",");
      //   let ApprovedDate = approvedDateArray[0];
      //   element.approved=ApprovedDate
      //   const clockInTimeAttay = element.clockingIn.split(",");
      //   let clockingIn = clockInTimeAttay[1];
      //   const clockOutTimeAttay = element.clockingOut.split(",");
      //   let clockingOut = clockOutTimeAttay[1];
      //   element.clockingIn =clockingIn,
      //   element.clockingOut=clockingOut
      
      
      
      })


      var currentdate = new Date();
      var fileName =
        "Report " +
        currentdate.getDate() +
        "/" +
        (currentdate.getMonth() + 1) +
        "/" +
        currentdate.getFullYear();

      excelParser().exportDataFromJSON(sampleData, fileName, null);


    },
    getData() {
      this.isLoaded = true;
      //this.play = false;
      if (this.dateRange == 6) {
        this.requestData.dateRange = null;
      } else {
        this.requestData.dateRange = this.dateRange;
      }

      dataService.getList(
        "api/Reports/get-expayroll-report",
        this.requestData,
        (response) => {
          this.isLoaded = false;

          debugger; // eslint-disable-line no-debugger
          this.reportData = response.data.data.groupedList;
          this.ExportData = response.data.data.exportlist;

        }
      );
    },

    handleClientFilter() {

      if (this.requestData.clientIds.length > 0) {
        dataService.create(
          "api/Accounts/clients",
          this.requestData.clientIds,
          (response) => {
            this.accounts = response.data.data;
          }
        );
      } else {
        this.loadAccounts();
      }
      ///Call Api filter
    },
    handleAccountFilter() {
      var companyIds = [];

      if (this.requestData.accountIds.length > 0) {
        dataService.create(
          "api/Companies/accounts",
          this.requestData.accountIds,
          (response) => {
            this.companies = response.data.data;
            for (var c = 0; c < this.companies.length; c++) {
              companyIds.push(this.companies[c].id);
            }
            dataService.create(
              "api/Branches/companies",
              companyIds,
              (response) => {
                this.locations = response.data.data;
              }
            );

            ///Call Api filter
            // this.getData();
          }
        );
      } else {
        this.loadAccounts();
        this.loadLocations();
        ///Call Api filter
        // this.getData();
      }
    },
    handleLocationFilter() {
      ///Call Api filter
    },

    getAccounts(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyAccounts(value);
        }, 1000);
      } else {
        self.applyAccounts(value);
      }
    },

    applyAccounts(value) {
      dataService.getList(
        "api/Accounts/search_by_name",
        {
          pageNumber: 1,
          pageSize: 100,
          subString: value,
        },
        (response) => {
          this.accounts = response.data.data.items;
          if (value) {
            this.$nextTick(() => this.$refs.accountSearch.focus());
          }
        }
      );
    },
    getLocations(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyLocations(value);
        }, 1000);
      } else {
        self.applyLocations(value);
      }
    },
    applyLocations(value) {
      dataService.getList(
        "api/Branches/search_by_name",
        {
          pageNumber: 1,
          pageSize: 100,
          subString: value,
        },
        (response) => {
          this.locations = response.data.data.items;
          if (value) {
            this.$nextTick(() => this.$refs.locationSearch.focus());
          }
        }
      );
    },

    async loadAccounts() {
      this.accounts = await this.getAccounts(this.accountTerm || "");
    },
    async loadLocations() {
      this.accounts = await this.getLocations(this.locationTerm || "");
    },

    convertUTCDateToLocalDate(date) {
      var newDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60 * 1000
      );

      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();

      newDate.setHours(hours - offset);

      return newDate;
    },

    getWOByDate() {
      if (this.SDFrom && !this.SDTo) {
        return;
      }
      if (!this.SDFrom) {
        store.commit("SHOW_MESSAGE", {
          text: "Please Select Start Date first",
          color: "error",
          timeout: 3000,
        });
        return;
      } else {
        this.requestData.serviceDateOn = {
          from: this.SDFrom + "T00:00:00Z",
          to: this.SDTo + "T00:00:00Z",
        };

      }
    },



    reset() {


      this.requestData.technicianTypeIds = null;
      this.requestData.clientIds = null;
      this.requestData.accountIds = null;
      this.requestData.dateRange = null;
      this.dateRange = null;
      this.companyIds = null;
      this.requestData.branchIds = null;
      this.requestData.technicianIds = null;
      this.reportData = [];
      (this.SDFrom = null), (this.SDTo = null);

    },

    async loadTechs(term, pointer) {
      this.technicians = await this.getTechnicians(term || "", pointer);
    },
    getTechnicians(value, pointer) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyTech(value, pointer);
        }, 1000);
      } else {
        self.applyTech(value, pointer);
      }
    },

    applyTech(value, pointer) {
      dataService.getList(
        "api/Technicians/get-all",
        {
          pageNumber: 1,
          pageSize: 100,
          search: value,
        },
        (response) => {
          this.technicians = response.data.data.items;
          if (value) {
            if (pointer == 1)
              this.$nextTick(() => this.$refs.techsearch.focus());
            if (pointer == 2) this.$nextTick(() => this.$refs.diffTech.focus());
          }
        }
      );
    },
    getClientsFromApi() {
      dataService.getAll(
        "api/Clients/get-all",

        (response) => {
          this.clients = response.data;
        }
      );
    },
  },
  computed: {
    techsearch: {
      get() {
        return this.queryTerm;
      },

      set(searchInput) {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput;
          this.loadTechs(this.queryTerm, 1);
        }
      },
    },

    diffTech: {
      get() {
        return this.diffTechTerm;
      },

      set(searchInput) {
        if (this.diffTechTerm !== searchInput) {
          this.diffTechTerm = searchInput;
          this.loadTechs(this.diffTechTerm, 2);
        }
      },
    },
    locationSearch: {
      get() {
        return this.locationTerm;
      },

      set(searchInput) {
        if (this.locationTerm !== searchInput) {
          this.locationTerm = searchInput;
          this.loadLocations();
        }
      },
    },

    accountsearch: {
      get() {
        return this.accountTerm;
      },

      set(searchInput) {
        if (this.accountTerm !== searchInput) {
          this.accountTerm = searchInput;
          this.loadAccounts();
        }
      },
    },
  },
  mounted() {
    // this.getData();
    //=============
    this.getClientsFromApi();
    this.loadTechs();
  },
  created() {
    this.loadAccounts();
    this.loadLocations();
  },
};
</script>
<style >


.v-date-picker-table table {
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    top: 0;
    table-layout: fixed;
    width: 83% !important;
}
</style>