<template>
  <div class="upload-section" id="users">
    <SideMenu v-if="!$vuetify.breakpoint.xsOnly"></SideMenu>
    <div class="user-creation ml-4 mt-4">
      <p class="table-title">{{ title }}</p>

      <v-row class="styling-selection-box">
        <v-col col="12">
          <v-autocomplete
          :disabled="disabled"
          :items="TemplateTypes"
          item-text="name"
          item-value="id"
          v-model="TypeId"
          outlined
          label="Select Template Type"
          @change="GetTemplateVariables"
        >
        </v-autocomplete>
        </v-col>
        <v-col md="6" col="12">
        <v-autocomplete
           multiple
          :disabled="disabled"
          :items="clients"
          item-text="name"
          item-value="id"
          v-model="template.clientIds"
          outlined
          label="Select Clients"
       
        >
        </v-autocomplete>
        </v-col>
      </v-row>
      
   
      <v-row class="styling-selection-box">
        <v-chip
          style=""
          v-for="item in items"
          :key="item.id"
          @click="AddTechName(item.value)"
          class="hoverele-class ma-2"
          color="indigo darken-3"
          outlined
        >
          {{ item.name }}
        </v-chip>
      </v-row>

      <div>
        <v-btn
          v-if="isVisable"
          style="z-index: 1; bottom: 4%; right: 7%"
          class="float btn-creation wo-diff-style"
          @click="CreateEmailTemp"
        >
          Create
        </v-btn>
        <br />
        <editor
          v-model="emailTemp"
          :api-key="apiKey"
          :init="{
            height: 700,

            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'table',
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help | \ table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
          }"
        />
      </div>
    </div>

    <v-dialog v-model="dialog" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Create Template</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="12">
                <v-text-field
                  outlined
                  v-model="template.name"
                  :class="{
                    'is-invalid': Submitted && $v.template.name.$error,
                  }"
                  label="Template Name"
                ></v-text-field>
                <p
                  v-if="Submitted && !$v.template.name.required"
                  class="invalid-feedback mt-2"
                >
                  Name is Required
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <v-text-field
                  outlined
                  v-model="template.description"
                  label="Description"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider class="mb-10"></v-divider>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text class="cancel-update-btn" @click="close">Discard</v-btn>

          <v-btn class="create-order-btn mr-1" @click="save">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import store from "@/store";
import { required } from "vuelidate/lib/validators";
import { dataService } from "@/services/data-service";
import SideMenu from "@/components/SettingsSideMenu.vue";
import Editor from "@tinymce/tinymce-vue";
export default {
  data() {
    return {
      clients: [],
      Submitted: false,
      isVisable: false,
      disabled: false,
      dialog: false,
      template: {
        type: 0,
        content: "",
        description: "",
        name: "",
      },
      TemplateTypes: [
        { id: 1, name: "Assign Work Order" },
         
        { id: 9, name: "Create Work Order" },
      ],
      title: "",
      TypeId: 0,
      items: [],
      apiKey: "wr0wwetnxiw85h19dzyabmnqrvx3r5p025xik9y1wb5lio0a",
      emailTemp: "",
    };
  },
  validations: {
    template: {
      name: { required },
    },
  },
  methods: {
    getClientsFromApi() {
      dataService.getAll(
        "api/Clients/get-all",

        (response) => {
          this.clients = response.data;
        }
      );
    },
    
    save() {
      this.Submitted = true;

      this.$v.template.$touch();
      if (this.$v.template.$invalid) {
        return;
      }

      dataService.create(
        "api/Mails/create-template",
        this.template,
        (response) => {
          if (response.data.succeeded == true) {
            store.commit("SHOW_MESSAGE", {
              text: "Created Successfully",
              color: "success",
              timeout: 3000,
            });

            this.dialog = false;
              this.Submitted = false;
              this.$router.push("/settings/EmailSettings");
          
          }
        }
      );
      // dataService.update("api/Mails/update-template", obj, () => {
      //   this.$router.push("/settings/EmailSettings");
      // });
    },
    close() {
      this.dialog = false;
      this.Submitted = false;
    },
    GetTemplateVariables() {
      this.isVisable = true;
      this.getVariables();
    },
    CreateEmailTemp() {
      this.template.type = this.TypeId;
      this.template.content = this.emailTemp;
      this.dialog = true;
    },

    AddTechName(value) {
      this.disabled = true;
      navigator.clipboard.writeText(value);
      navigator.clipboard.readText();

      this.emailTemp = this.emailTemp + value;
    },

    getVariables() {
      dataService.getAll(
        "api/Mails/template-variables-bytype/" + this.TypeId,

        (response) => {
          this.items = response.data;
        }
      );
    },
  },

  mounted() {
    this.title = localStorage.emailtemplate + " Email Template";
    this.getClientsFromApi();
  },

  components: {
    SideMenu,
    editor: Editor,
  },
};
</script>
<style scoped>
 #users .v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections {
    height: 30px !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}
</style>