<template>
  <div class="login-background newPadding">
    <div>
      <v-stepper class="login-form newHeight" v-model="e1">
        <v-stepper-items>
          <v-stepper-content step="1" id="stepOne">
            <div class="mb-12">
              <p class="login-label">Reset your Password</p>

              <v-tooltip v-model="show" right>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    @click="show = true"
                    v-bind="attrs"
                    v-on="on"
                    label="Password"
                    solo
                    type="password"
                    v-model="user.newPassword"
                    :class="{
                      'is-invalid':
                        activationSubmite && $v.user.newPassword.$error,
                    }"
                  ></v-text-field>
                </template>
                <div>
                  <p>Password need at least :</p>
                  <ul>
                    <li class="validation-list">
                      <i
                        :class="{
                          'is-checked': user.newPassword.length >= 8,
                        }"
                        class="fas fa-check-circle mr-2"
                      ></i>
                      <span> 8 characters</span>
                    </li>
                    <li class="validation-list">
                      <i
                        :class="{
                          'is-checked': checkAlphanumeric(user.newPassword),
                        }"
                        class="fas fa-check-circle mr-2"
                      ></i>
                      <span>contain alphanumeric characters </span>
                    </li>
                    <li class="validation-list mb-7">
                      <i
                        :class="{
                          'is-checked': containUpper(user.newPassword),
                        }"
                        class="fas fa-check-circle mr-2"
                      ></i>
                      <span>contain uppercase and lowercase characters </span>
                    </li>
                  </ul>
                </div>
              </v-tooltip>

              <p
                v-if="activationSubmite && !$v.user.newPassword.required"
                class="invalid-feedback mt-3"
              >
                Password is Required
              </p>
              <p
                class="invalid-feedback mt-3"
                v-if="activationSubmite && !$v.user.newPassword.minLength"
              >
                Password must be at least 8 characters and must contain
                alphanumeric and uppercase characters
              </p>

              <v-text-field
                label="Confirm Password"
                type="password"
                solo
                :class="{
                  'is-invalid':
                    activationSubmite && $v.user.confirmationPassword.$error,
                }"
                v-model="user.confirmationPassword"
              ></v-text-field>
              <p
                v-if="
                  activationSubmite && !$v.user.confirmationPassword.required
                "
                class="invalid-feedback mt-3"
              >
                Confirmation Password is Required
              </p>
              <p
                v-if="
                  activationSubmite &&
                  !$v.user.confirmationPassword.sameAsPassword
                "
                class="invalid-feedback mt-3"
              >
                Confirmation Password must match Password
              </p>

              <v-btn
                color="#172450"
                class="nextBtn"
                @click="validateActivation"
              >
                Reset
              </v-btn>
            </div>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div class="mb-12">
              <p class="login-label">Login</p>
              <p class="confirmation-link">
                Please Check your email for the Confirmation Link
              </p>
              <v-btn color="#172450" class="nextBtn" @click="e1 = 1">
                Done
              </v-btn>
            </div>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div class="mb-12">
              <p class="login-label">Login</p>
              <v-text-field
                v-model="email"
                solo
                label="Enter your email"
                :class="{ 'is-invalid': submitted && $v.email.$error }"
                v-on:keyup.enter="validateStepOne"
              ></v-text-field>
              <p
                v-if="submitted && !$v.email.required"
                class="invalid-feedback mt-3"
              >
                email is Required
              </p>
              <p
                v-if="submitted && !$v.email.email"
                class="invalid-feedback mt-3"
              >
                email is Invalid
              </p>

              <v-btn color="#172450" class="nextBtn" @click="validateStepOne">
                Next
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content step="4" id="stepOne">
            <div class="mb-12">
              <p class="login-label">Login</p>
              <v-text-field
                solo
                type="password"
                v-model="password"
                label="Enter Your Password"
                :class="{ 'is-invalid': submitted && $v.password.$error }"
                v-on:keyup.enter="login"
              >
              </v-text-field>
              <p
                v-if="submitted && !$v.password.required"
                class="invalid-feedback mt-3"
              >
                Password is Invalid
              </p>
              <v-btn color="#172450" class="nextBtn" @click="login">
                Login
              </v-btn>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>
</template>
<script>
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";
import { dataService } from "@/services/data-service";
import store from "@/store";

export default {
  data() {
    return {
      show: false,
      genders: [
        {
          id: 1,
          name: "Male",
        },
        {
          id: 2,
          name: "Female",
        },
      ],
      e1: 1,
      email: "",
      password: "",

      submitted: false,
      activationSubmite: false,
      menu: false,
      user: {
        email: "",
        newPassword: "",
        confirmationPassword: "",
        token: "",
      },
    };
  },
  validations: {
    email: { required, email },
    password: { required },
    user: {
      newPassword: { required, minLength: minLength(8) },
      confirmationPassword: { required, sameAsPassword: sameAs("newPassword") },
    },
  },
  methods: {
    containUpper(str) {
      return /[A-Z]/.test(str) && /[a-z]/.test(str);
    },
    checkAlphanumeric(str) {
      return str.match(/^[A-Za-z0-9]/) ? true : false;
    },
    validateStepOne() {
      this.submitted = true;
      this.$v.email.$touch();
      if (this.$v.email.$invalid) {
        return;
      } else {
        this.checkEmail();
      }
    },
    checkEmail() {
      dataService.getAll(
        "api/Authentication/loged-in-before/" + this.email,
        (response) => {
          if (response.data) {
            var isFirstTime = response.data.isFirstLogin;

            if (isFirstTime == true) {
              this.e1 = 2;
            } else {
              this.e1 = 4;
            }
          } else {
            store.commit("SHOW_MESSAGE", {
              text: "Invalid Email",
              color: "error",
              timeout: 3000,
            });
          }
        }
      );
    },
    login() {
      this.submitted = true;
      const email = this.email;
      const password = this.password;
      this.$v.email.$touch();
      this.$v.password.$touch();

      if (this.$v.email.$invalid || this.$v.email.$invalid) {
        return;
      } else {
        this.$store
          .dispatch("AuthModule/signIn", {
            email,
            password,
          })
          .catch((err) => {
            if (err) {
              this.invalidData = err.message;
            }
          });

        // this.$router.push("dashboard");
      }
    },
    validateActivation() {
      this.activationSubmite = true;
      this.$v.user.$touch();
      if (this.$v.user.$invalid) {
        return;
      } else {
        this.resetPassword();
      }
    },
    resetPassword() {
      this.user.token = this.$route.query.token;
      this.user.email = this.$route.query.email;

      dataService.create(
        "api/Authentication/reset-password",
        this.user,
        (response) => {
          if (response.data.succeeded == true) {
            store.commit("SHOW_MESSAGE", {
              text: "Your Account is Active now Please Login ",
              color: "success",
              timeout: 3000,
            });
            this.e1 = 3;
          } else {
            store.commit("SHOW_MESSAGE", {
              text: response.data.errors[0],
              color: "error",
              timeout: 3000,
            });
          }
        }
      );
    },
  },
};
</script>

