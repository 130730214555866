<template>
  <div class="text-center">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
export default {
   props: ["isLoaded"],
  data: () => ({
    overlay:true,
  }),
   mounted() {
      
this.overlay=this.isLoaded;
  },
};
</script>