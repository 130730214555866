<template>
  <div class="handle-test">
    <p class="table-title">New Client</p>
    <div class="innner-container">
     <!-- =========== btn reset =======-->
        <v-row>
          <v-col md="10"></v-col>
          <v-col md="2">
            <v-btn @click="emptyButtons" class="resetWO-btn">
              <i class="fas fa-redo"></i> <span class="ml-1">Reset</span>
            </v-btn>
          </v-col>
        </v-row>






      <v-row>
        <v-col md="12">
          <v-text-field
          tabindex="1"
            outlined
            v-model="client.name"
            :class="{
              'is-invalid': submitted && $v.client.name.$error,
            }"
            label="Full Name"
          ></v-text-field>
          <p
            v-if="submitted && !$v.client.name.required"
            class="invalid-feedback mt-2"
          >
            Name is Required
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
          tabindex="2"
            outlined
            class="mb-3"
            :set="(v = $v.client.clientPhones.$each[0])"
            v-model="client.clientPhones[0].phoneNumber"
            :class="{
              'is-invalid': submitted && v.phoneNumber.$error,
            }"
            label="Primary Phone"
            @blur="formatPhoneNumber(1)"
          ></v-text-field>
          <p
            v-if="submitted && !v.phoneNumber.required"
            class="invalid-feedback"
          >
            Primary Phone is Required
          </p>

          <v-text-field
          tabindex="4"
            outlined
            class="mb-3"
            :class="{
              'is-invalid': submitted && $v.client.email.$error,
            }"
            v-model="client.email"
            label="Email"
          ></v-text-field>
          <p
            v-if="submitted && !$v.client.email.required"
            class="invalid-feedback"
          >
            Email is Required
          </p>
          <p
            v-if="submitted && !$v.client.email.email"
            class="invalid-feedback"
          >
            Email is not valid
          </p>
        
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
          tabindex="3"
            outlined
            class="mb-3"
            label="Secondary Phone"
            v-model="secondaryPhone"
            @blur="formatPhoneNumber(2)"
          ></v-text-field>

       
        </v-col>
      </v-row>
      <v-divider class="mb-10"></v-divider>
      <div>

<v-row>
  <v-col cols="12" md="6">
                <v-text-field
            tabindex="5"
              outlined
              class="mb-3"
              :class="{
                'is-invalid': submitted && $v.client.address.street.$error,
              }"
              v-model="client.address.street"
              label="Street"
            ></v-text-field>
            <p
              v-if="submitted && !$v.client.address.street.required"
              class="invalid-feedback"
            >
              Street is Required
            </p>
  </v-col>
  <v-col cols="12" md="6">
        <v-text-field
            tabindex="6"
              :class="{
                'is-invalid': submitted && $v.client.address.postalCode.$error,
              }"
              v-model="client.address.postalCode"
              outlined
              class="mb-3"
              label="ZIP / Postal Code"
            ></v-text-field>
            <p
              v-if="submitted && !$v.client.address.postalCode.required"
              class="invalid-feedback"
            >
              Postal Code is Required
            </p>

  </v-col>
</v-row>

<v-row>
  <v-col cols="12" md="6">
    
            <v-text-field
            tabindex="7"
              v-model="client.address.details"
              outlined
              class="mb-3"
              label="Details"
            ></v-text-field>
  </v-col>
  <v-col cols="12" md="6"></v-col>
</v-row>

<v-row>
  <v-col cols="12" md="6">
         <v-autocomplete
            tabindex="8"
              :items="countries"
              :item-text="'name'"
              :item-value="'id'"
              label="Country"
              outlined
              v-model="client.address.countryId"
              class="mb-3"
              :class="{
                'is-invalid': submitted && $v.client.address.countryId.$error,
              }"
              @change="getStates"
            >
            </v-autocomplete>
            <p
              v-if="submitted && !$v.client.address.countryId.required"
              class="invalid-feedback"
            >
              Country is Required
            </p>
  </v-col>
  <v-col cols="12" md="6">
           <v-autocomplete
            tabindex="9"
              :items="states"
              :item-text="'name'"
              :item-value="'id'"
              label="State"
              outlined
              v-model="client.address.stateId"
              class="mb-3"
              :class="{
                'is-invalid': submitted && $v.client.address.stateId.$error,
              }"
              @change="getCities"
            >
            </v-autocomplete>
            <p
              v-if="submitted && !$v.client.address.stateId.required"
              class="invalid-feedback"
            >
              State is Required
            </p>
  </v-col>
</v-row>

<v-row>
  <v-col cols="12" md="6">
                <v-autocomplete
            tabindex="10"
              :items="cities"
              :item-text="'name'"
              :item-value="'id'"
              label="City"
              outlined
              class="mb-3"
              :class="{
                'is-invalid': submitted && $v.client.address.cityId.$error,
              }"
              v-model="client.address.cityId"
            >
            </v-autocomplete>
            <p
              v-if="submitted && !$v.client.address.cityId.required"
              class="invalid-feedback"
            >
              City is Required
            </p>
  </v-col>
  <v-col cols="12" md="6"></v-col>
</v-row>


<!-- 
        <v-row>
          <v-col cols="12" md="6">



       

          </v-col>
          <v-col cols="12" md="6"> -->
            <!-- <v-text-field
            tabindex="6"
              :class="{
                'is-invalid':
                  submitted && $v.client.address.buildingNumber.$error,
              }"
              v-model="client.address.buildingNumber"
              outlined
              class="mb-3"
              label="Building Number"
            ></v-text-field>
            <p
              v-if="submitted && !$v.client.address.buildingNumber.required"
              class="invalid-feedback"
            >
              Building Number is Required
            </p> -->
          <!-- </v-col>
        </v-row> -->


      </div>

      <div class="btn-container">
        <v-btn tabindex="11" class="create-order-btn mr-1"  @keyup.enter="check()" @click="check"
          >Create Client</v-btn
        >
      </div>
    </div>
  </div>
</template>
<script>
import { dataService } from "@/services/data-service";
import { required, email } from "vuelidate/lib/validators";


export default {
  data() {
    return {
      isprimary: 1,
      clients: [],
      checkbox: false,
      countries: [],
      cities: [],
      levels: [],
      states: [],
      submitted: false,
      secondaryPhone: "",

      client: {
        name: "",

        email: "",
        userTypeId: 2,
        address: {
          street: "",
          buildingNumber: "",
          postalCode: "",
          details: "",
          stateId: null,
          countryId: null,
          cityId: null,
        },
        clientPhones: [
          {
            phoneNumber: "",
            isPrimary: true,
          },
        ],
      },
    };
  },
  validations: {
    client: {
      name: { required },

      email: { required, email },

      address: {
        street: { required },
       
        postalCode: { required },
        stateId: { required },
        countryId: { required },
        cityId: { required },
      },
      clientPhones: {
        $each: {
          phoneNumber: { required },
        },
      },
    },
  },
  methods: {
    // ========= btn reset ==========
    emptyButtons(){
      this.secondaryPhone= null,

      this.client= {
        name: null,

        email:null,
        userTypeId: 2,
        address: {
          street: null,
          buildingNumber: null,
          postalCode: null,
          details:null,
          stateId: null,
          countryId: null,
          cityId: null,
        },
        clientPhones: [
          {
            phoneNumber: null,
            isPrimary: true,
          },
        ],
      }
    },

    formatPhoneNumber(isprimary) {
      if (isprimary == 1) {
      
        var cleanedprimaryphonenumber = ('' + this.client.clientPhones[0].phoneNumber).replace(/\D/g, '');
        var matchprimaryphonenumber = cleanedprimaryphonenumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (matchprimaryphonenumber) {
          var intlCodeprimaryphonenumber = (matchprimaryphonenumber[1] ? '+1 ' : '');
          this.client.clientPhones[0].phoneNumber = [intlCodeprimaryphonenumber, '(', matchprimaryphonenumber[2], ') ', matchprimaryphonenumber[3], '-', matchprimaryphonenumber[4]].join('');
        }
      }
      else {
      
        var cleanedsecondryphonenumber = ('' + this.secondaryPhone).replace(/\D/g, '');
        var matchsecondryphonenumber = cleanedsecondryphonenumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (matchsecondryphonenumber) {
          var intlCodesecondryphonenumber = (matchsecondryphonenumber[1] ? '+1 ' : '');
          this.secondaryPhone = [intlCodesecondryphonenumber, '(', matchsecondryphonenumber[2], ') ', matchsecondryphonenumber[3], '-', matchsecondryphonenumber[4]].join('');
        }
      }

    },
    //======================================
    check() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.createClient();
      }
    },
    createClient() {
      if (this.secondaryPhone) {
        this.client.clientPhones.push({
          phoneNumber: this.secondaryPhone,
          isPrimary: false,
        });
      }
      if (this.checkbox == true) {
        this.client.userTypeId = 1;
      }

      dataService.create("api/Clients/create", this.client, () => {
        //console.log(response);
     
      });
      this.$router.push("/clients/list");
    },
    getCountries() {
      dataService.getAll("api/Countries", (response) => {
        this.countries = response.data;
      });
    },
    getCities() {
      dataService.getAll(
        "api/Cities/get_city/" + this.client.address.stateId,
        (response) => {
          this.cities = response.data;
        }
      );
    },
    getStates() {
      this.cities=[];
      dataService.getAll(
        "api/States/get_state/" + this.client.address.countryId,
        (response) => {
          this.states = response.data;
        }
      );
    },
  },
  mounted() {
    this.getCountries();
  },
};
</script>