var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "handle-test" }, [
    _c("p", { staticClass: "table-title" }, [_vm._v("New Client")]),
    _c(
      "div",
      { staticClass: "innner-container" },
      [
        _c(
          "v-row",
          [
            _c("v-col", { attrs: { md: "10" } }),
            _c(
              "v-col",
              { attrs: { md: "2" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "resetWO-btn",
                    on: { click: _vm.emptyButtons },
                  },
                  [
                    _c("i", { staticClass: "fas fa-redo" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-1" }, [_vm._v("Reset")]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { md: "12" } },
              [
                _c("v-text-field", {
                  class: {
                    "is-invalid": _vm.submitted && _vm.$v.client.name.$error,
                  },
                  attrs: { tabindex: "1", outlined: "", label: "Full Name" },
                  model: {
                    value: _vm.client.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.client, "name", $$v)
                    },
                    expression: "client.name",
                  },
                }),
                _vm.submitted && !_vm.$v.client.name.required
                  ? _c("p", { staticClass: "invalid-feedback mt-2" }, [
                      _vm._v(" Name is Required "),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [
                _c("v-text-field", {
                  staticClass: "mb-3",
                  class: {
                    "is-invalid": _vm.submitted && _vm.v.phoneNumber.$error,
                  },
                  attrs: {
                    tabindex: "2",
                    outlined: "",
                    set: (_vm.v = _vm.$v.client.clientPhones.$each[0]),
                    label: "Primary Phone",
                  },
                  on: {
                    blur: function ($event) {
                      return _vm.formatPhoneNumber(1)
                    },
                  },
                  model: {
                    value: _vm.client.clientPhones[0].phoneNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.client.clientPhones[0], "phoneNumber", $$v)
                    },
                    expression: "client.clientPhones[0].phoneNumber",
                  },
                }),
                _vm.submitted && !_vm.v.phoneNumber.required
                  ? _c("p", { staticClass: "invalid-feedback" }, [
                      _vm._v(" Primary Phone is Required "),
                    ])
                  : _vm._e(),
                _c("v-text-field", {
                  staticClass: "mb-3",
                  class: {
                    "is-invalid": _vm.submitted && _vm.$v.client.email.$error,
                  },
                  attrs: { tabindex: "4", outlined: "", label: "Email" },
                  model: {
                    value: _vm.client.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.client, "email", $$v)
                    },
                    expression: "client.email",
                  },
                }),
                _vm.submitted && !_vm.$v.client.email.required
                  ? _c("p", { staticClass: "invalid-feedback" }, [
                      _vm._v(" Email is Required "),
                    ])
                  : _vm._e(),
                _vm.submitted && !_vm.$v.client.email.email
                  ? _c("p", { staticClass: "invalid-feedback" }, [
                      _vm._v(" Email is not valid "),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [
                _c("v-text-field", {
                  staticClass: "mb-3",
                  attrs: {
                    tabindex: "3",
                    outlined: "",
                    label: "Secondary Phone",
                  },
                  on: {
                    blur: function ($event) {
                      return _vm.formatPhoneNumber(2)
                    },
                  },
                  model: {
                    value: _vm.secondaryPhone,
                    callback: function ($$v) {
                      _vm.secondaryPhone = $$v
                    },
                    expression: "secondaryPhone",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("v-divider", { staticClass: "mb-10" }),
        _c(
          "div",
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c("v-text-field", {
                      staticClass: "mb-3",
                      class: {
                        "is-invalid":
                          _vm.submitted && _vm.$v.client.address.street.$error,
                      },
                      attrs: { tabindex: "5", outlined: "", label: "Street" },
                      model: {
                        value: _vm.client.address.street,
                        callback: function ($$v) {
                          _vm.$set(_vm.client.address, "street", $$v)
                        },
                        expression: "client.address.street",
                      },
                    }),
                    _vm.submitted && !_vm.$v.client.address.street.required
                      ? _c("p", { staticClass: "invalid-feedback" }, [
                          _vm._v(" Street is Required "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c("v-text-field", {
                      staticClass: "mb-3",
                      class: {
                        "is-invalid":
                          _vm.submitted &&
                          _vm.$v.client.address.postalCode.$error,
                      },
                      attrs: {
                        tabindex: "6",
                        outlined: "",
                        label: "ZIP / Postal Code",
                      },
                      model: {
                        value: _vm.client.address.postalCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.client.address, "postalCode", $$v)
                        },
                        expression: "client.address.postalCode",
                      },
                    }),
                    _vm.submitted && !_vm.$v.client.address.postalCode.required
                      ? _c("p", { staticClass: "invalid-feedback" }, [
                          _vm._v(" Postal Code is Required "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c("v-text-field", {
                      staticClass: "mb-3",
                      attrs: { tabindex: "7", outlined: "", label: "Details" },
                      model: {
                        value: _vm.client.address.details,
                        callback: function ($$v) {
                          _vm.$set(_vm.client.address, "details", $$v)
                        },
                        expression: "client.address.details",
                      },
                    }),
                  ],
                  1
                ),
                _c("v-col", { attrs: { cols: "12", md: "6" } }),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c("v-autocomplete", {
                      staticClass: "mb-3",
                      class: {
                        "is-invalid":
                          _vm.submitted &&
                          _vm.$v.client.address.countryId.$error,
                      },
                      attrs: {
                        tabindex: "8",
                        items: _vm.countries,
                        "item-text": "name",
                        "item-value": "id",
                        label: "Country",
                        outlined: "",
                      },
                      on: { change: _vm.getStates },
                      model: {
                        value: _vm.client.address.countryId,
                        callback: function ($$v) {
                          _vm.$set(_vm.client.address, "countryId", $$v)
                        },
                        expression: "client.address.countryId",
                      },
                    }),
                    _vm.submitted && !_vm.$v.client.address.countryId.required
                      ? _c("p", { staticClass: "invalid-feedback" }, [
                          _vm._v(" Country is Required "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c("v-autocomplete", {
                      staticClass: "mb-3",
                      class: {
                        "is-invalid":
                          _vm.submitted && _vm.$v.client.address.stateId.$error,
                      },
                      attrs: {
                        tabindex: "9",
                        items: _vm.states,
                        "item-text": "name",
                        "item-value": "id",
                        label: "State",
                        outlined: "",
                      },
                      on: { change: _vm.getCities },
                      model: {
                        value: _vm.client.address.stateId,
                        callback: function ($$v) {
                          _vm.$set(_vm.client.address, "stateId", $$v)
                        },
                        expression: "client.address.stateId",
                      },
                    }),
                    _vm.submitted && !_vm.$v.client.address.stateId.required
                      ? _c("p", { staticClass: "invalid-feedback" }, [
                          _vm._v(" State is Required "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c("v-autocomplete", {
                      staticClass: "mb-3",
                      class: {
                        "is-invalid":
                          _vm.submitted && _vm.$v.client.address.cityId.$error,
                      },
                      attrs: {
                        tabindex: "10",
                        items: _vm.cities,
                        "item-text": "name",
                        "item-value": "id",
                        label: "City",
                        outlined: "",
                      },
                      model: {
                        value: _vm.client.address.cityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.client.address, "cityId", $$v)
                        },
                        expression: "client.address.cityId",
                      },
                    }),
                    _vm.submitted && !_vm.$v.client.address.cityId.required
                      ? _c("p", { staticClass: "invalid-feedback" }, [
                          _vm._v(" City is Required "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c("v-col", { attrs: { cols: "12", md: "6" } }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "btn-container" },
          [
            _c(
              "v-btn",
              {
                staticClass: "create-order-btn mr-1",
                attrs: { tabindex: "11" },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.check()
                  },
                  click: _vm.check,
                },
              },
              [_vm._v("Create Client")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }