var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload-section", attrs: { id: "permissions" } },
    [
      _c("SideMenu"),
      _c(
        "v-card",
        [
          _c(
            "v-tabs",
            {
              attrs: { vertical: "" },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _vm._l(_vm.permissions, function (p) {
                return _c("v-tab", { key: p.id + "P" }, [
                  _vm._v(" " + _vm._s(p.name) + " "),
                ])
              }),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-section mt-5 ml-5",
                      attrs: { id: "role-selection" },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.userTypes,
                          "item-text": "name",
                          "item-value": "id",
                          label:
                            "\n                Select Role To Show Its Permissions ",
                        },
                        on: { change: _vm.getPermissions },
                        model: {
                          value: _vm.userTypeId,
                          callback: function ($$v) {
                            _vm.userTypeId = $$v
                          },
                          expression: "userTypeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._l(_vm.permissions, function (item) {
                    return _c(
                      "div",
                      { key: item.id + "I" },
                      [
                        _c(
                          "v-tab-item",
                          _vm._l(item.groups, function (group) {
                            return _c("v-checkbox", {
                              key: group.id + "G",
                              staticClass: "role-name",
                              attrs: {
                                color: "#2A8D3D",
                                label: group.description,
                                disabled: "",
                              },
                              model: {
                                value: group.checkBox,
                                callback: function ($$v) {
                                  _vm.$set(group, "checkBox", $$v)
                                },
                                expression: "group.checkBox",
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }