<template>
  <div class="handle-test">
    <p class="table-title">New Account</p>
    <div class="innner-container">

     <!-- =========== btn reset me =======-->
        <v-row>
          <v-col md="10"></v-col>
          <v-col md="2">
            <v-btn @click="emptyButtons" class="resetWO-btn">
              <i class="fas fa-redo"></i> <span class="ml-1">Reset</span>
            </v-btn>
          </v-col>
        </v-row>


      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
          tabindex="1"
            v-model="account.name"
            :class="{
              'is-invalid': submitted && $v.account.name.$error,
            }"
            outlined
            class="mb-3"
            label="Name"
          ></v-text-field>
          <p
            v-if="submitted && !$v.account.name.required"
            class="invalid-feedback"
          >
            Name is Required
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
          tabindex="2"
            :items="clients"
            :item-text="'name'"
            :item-value="'id'"
            label="Select Client"
            outlined
            class="mb-3"
            v-model="account.clientId"
            :class="{
              'is-invalid': submitted && $v.account.clientId.$error,
            }"
          >
          </v-autocomplete>

          <p
            v-if="submitted && !$v.account.clientId.required"
            class="invalid-feedback"
          >
            Client is Required
          </p>
        </v-col>
      </v-row>

      <div class="btn-container">
        <v-btn class="create-order-btn mr-1" tabindex="3"  @keyup.enter="check()" @click="check"
          >Create Account</v-btn
        >
      </div>
    </div>
  </div>
</template>
<script>
import { dataService } from "@/services/data-service";
import { required } from "vuelidate/lib/validators";


export default {
  data() {
    return {
      isLastPage: false,
      loading: false,
      queryTerm: "",
      submitted: false,
      clients: [],

      account: {
        name: "",
        clientId: null,
      },
    };
  },
  validations: {
    account: {
      name: { required },
      clientId: { required },
    },
  },
  methods: {
    // ========= btn reset me ==========
    emptyButtons(){
      
      this.account= {
        name:null,
        clientId: null,
      }
    },


    getClients() {
      dataService.getAll(
        "api/Clients/get-all",

        (response) => {
          this.clients = response.data;
        }
      );
    },

    check() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.createAccount();
      }
    },
    createAccount() {
      dataService.create("api/Accounts/create", this.account, () => {
        //console.log(response);
      
        this.$router.push("/accounts/list");
      });
    },
  },

  mounted() {
    this.getClients();
  },
};
</script>