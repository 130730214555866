var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "handle-test", attrs: { id: "work-order" } },
    [
      _c(
        "div",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12", md: "10" } }, [
                _c("p", { staticClass: "table-title" }, [
                  _vm._v("Locations List"),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { md: "1", cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-creation wo-diff-style",
                      attrs: { to: "/location/create", color: "#D92020" },
                    },
                    [_vm._v("Create Location")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pad0", attrs: { cols: "12", md: "4" } },
                [
                  _c("v-text-field", {
                    ref: "objet",
                    attrs: {
                      solo: "",
                      label: "Search Location ",
                      "append-icon": "mdi-magnify",
                    },
                    on: {
                      keyup: function ($event) {
                        return _vm.realSearch(1)
                      },
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
              _c("v-col", {
                staticClass: "pad0",
                attrs: { cols: "12", md: "8" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              loading: _vm.isLoaded,
              "loading-text": "Loading... Please wait",
              headers: _vm.headers,
              items: _vm.data,
              "item-key": "id",
              "hide-default-footer": "",
              "items-per-page": _vm.pagination.pageSize,
            },
            on: { "update:options": _vm.handleOptionsUpdate },
            scopedSlots: _vm._u([
              {
                key: "footer",
                fn: function () {
                  return [
                    _c(
                      "v-row",
                      { staticClass: "mt-5 fit-width" },
                      [
                        _c(
                          "v-col",
                          { attrs: { md: "2" } },
                          [
                            _c("v-select", {
                              attrs: {
                                items: _vm.sizeOptions,
                                "item-value": "id",
                                "item-text": "name",
                                solo: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.realSearch(1)
                                },
                              },
                              model: {
                                value: _vm.pagination.pageSize,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pagination, "pageSize", $$v)
                                },
                                expression: "pagination.pageSize",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { md: "5" } },
                          [
                            _c("v-pagination", {
                              attrs: {
                                length: _vm.totalPages,
                                "total-visible": "6",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.realSearch(
                                    _vm.pagination.pageNumber
                                  )
                                },
                              },
                              model: {
                                value: _vm.pagination.pageNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pagination, "pageNumber", $$v)
                                },
                                expression: "pagination.pageNumber",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "top",
                fn: function () {
                  return [
                    _c(
                      "v-toolbar",
                      { attrs: { flat: "" } },
                      [
                        _c("v-toolbar-title", [_vm._v("Locations")]),
                        _c("v-divider", {
                          staticClass: "mx-4",
                          attrs: { inset: "", vertical: "" },
                        }),
                        _c("v-spacer"),
                        _c(
                          "v-dialog",
                          {
                            attrs: { "max-width": "700px" },
                            model: {
                              value: _vm.dialog,
                              callback: function ($$v) {
                                _vm.dialog = $$v
                              },
                              expression: "dialog",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c("v-card-title", [
                                  _c("span", { staticClass: "text-h5" }, [
                                    _vm._v("Edit Branch"),
                                  ]),
                                ]),
                                _c(
                                  "v-card-text",
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { md: "12" } },
                                              [
                                                _c("v-text-field", {
                                                  class: {
                                                    "is-invalid":
                                                      _vm.submitted &&
                                                      _vm.$v.branch.name.$error,
                                                  },
                                                  attrs: {
                                                    tabindex: "1",
                                                    outlined: "",
                                                    label: "Full Name",
                                                  },
                                                  model: {
                                                    value: _vm.branch.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.branch,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "branch.name",
                                                  },
                                                }),
                                                _vm.submitted &&
                                                !_vm.$v.branch.name.required
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "invalid-feedback mt-2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Name is Required "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c("v-autocomplete", {
                                                  ref: "lcn",
                                                  attrs: {
                                                    tabindex: "2",
                                                    outlined: "",
                                                    label: "Select Company",
                                                    items: _vm.companies,
                                                    "item-value": "id",
                                                    "item-text": "name",
                                                    "search-input":
                                                      _vm.locationSearch,
                                                  },
                                                  on: {
                                                    "update:searchInput":
                                                      function ($event) {
                                                        _vm.locationSearch =
                                                          $event
                                                      },
                                                    "update:search-input":
                                                      function ($event) {
                                                        _vm.locationSearch =
                                                          $event
                                                      },
                                                  },
                                                  model: {
                                                    value: _vm.branch.companyId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.branch,
                                                        "companyId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "branch.companyId",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          staticClass: "mb-10",
                                        }),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "mb-3",
                                                      class: {
                                                        "is-invalid":
                                                          _vm.submitted &&
                                                          _vm.$v.branch.address
                                                            .street.$error,
                                                      },
                                                      attrs: {
                                                        tabindex: "3",
                                                        outlined: "",
                                                        label: "Street",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.branch.address
                                                            .street,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.branch.address,
                                                            "street",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "branch.address.street",
                                                      },
                                                    }),
                                                    _vm.submitted &&
                                                    !_vm.$v.branch.address
                                                      .street.required
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "invalid-feedback",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Street is Required "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "mb-3",
                                                      class: {
                                                        "is-invalid":
                                                          _vm.submitted &&
                                                          _vm.$v.branch.address
                                                            .postalCode.$error,
                                                      },
                                                      attrs: {
                                                        tabindex: "4",
                                                        outlined: "",
                                                        label:
                                                          "ZIP / Postal Code",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.branch.address
                                                            .postalCode,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.branch.address,
                                                            "postalCode",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "branch.address.postalCode",
                                                      },
                                                    }),
                                                    _vm.submitted &&
                                                    !_vm.$v.branch.address
                                                      .postalCode.required
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "invalid-feedback",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Postal Code is Required "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "mb-3",
                                                      attrs: {
                                                        tabindex: "5",
                                                        outlined: "",
                                                        label: "Details",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.branch.address
                                                            .details,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.branch.address,
                                                            "details",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "branch.address.details",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c("v-col", {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-autocomplete", {
                                                      staticClass: "mb-3",
                                                      class: {
                                                        "is-invalid":
                                                          _vm.submitted &&
                                                          _vm.$v.branch.address
                                                            .countryId.$error,
                                                      },
                                                      attrs: {
                                                        tabindex: "6",
                                                        items: _vm.countries,
                                                        "item-text": "name",
                                                        "item-value": "id",
                                                        label: "Country",
                                                        outlined: "",
                                                      },
                                                      on: {
                                                        change: _vm.getStates,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.branch.address
                                                            .countryId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.branch.address,
                                                            "countryId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "branch.address.countryId",
                                                      },
                                                    }),
                                                    _vm.submitted &&
                                                    !_vm.$v.branch.address
                                                      .countryId.required
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "invalid-feedback",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Country is Required "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-autocomplete", {
                                                      staticClass: "mb-3",
                                                      class: {
                                                        "is-invalid":
                                                          _vm.submitted &&
                                                          _vm.$v.branch.address
                                                            .stateId.$error,
                                                      },
                                                      attrs: {
                                                        tabindex: "7",
                                                        items: _vm.EditStates,
                                                        "item-text": "name",
                                                        "item-value": "id",
                                                        label: "State",
                                                        outlined: "",
                                                        "search-input":
                                                          _vm.StateSearch,
                                                        "hide-selected": "",
                                                        "persistent-hint": "",
                                                      },
                                                      on: {
                                                        change: _vm.getCities,
                                                        "update:searchInput":
                                                          function ($event) {
                                                            _vm.StateSearch =
                                                              $event
                                                          },
                                                        "update:search-input":
                                                          function ($event) {
                                                            _vm.StateSearch =
                                                              $event
                                                          },
                                                        keyup: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          return _vm.createState.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "no-data",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "v-list-item",
                                                                [
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _vm._v(
                                                                            ' No results matching "'
                                                                          ),
                                                                          _c(
                                                                            "strong",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.StateSearch
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            '". Press '
                                                                          ),
                                                                          _c(
                                                                            "kbd",
                                                                            [
                                                                              _vm._v(
                                                                                "enter"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " to create a new one "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ]),
                                                      model: {
                                                        value:
                                                          _vm.branch.address
                                                            .stateId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.branch.address,
                                                            "stateId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "branch.address.stateId",
                                                      },
                                                    }),
                                                    _vm.submitted &&
                                                    !_vm.$v.branch.address
                                                      .stateId.required
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "invalid-feedback",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " State is Required "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-autocomplete", {
                                                      staticClass: "mb-3",
                                                      class: {
                                                        "is-invalid":
                                                          _vm.submitted &&
                                                          _vm.$v.branch.address
                                                            .cityId.$error,
                                                      },
                                                      attrs: {
                                                        tabindex: "8",
                                                        items: _vm.Editcities,
                                                        "item-text": "name",
                                                        "item-value": "id",
                                                        label: "City",
                                                        outlined: "",
                                                        "search-input":
                                                          _vm.CitySearch,
                                                        "hide-selected": "",
                                                        "persistent-hint": "",
                                                      },
                                                      on: {
                                                        "update:searchInput":
                                                          function ($event) {
                                                            _vm.CitySearch =
                                                              $event
                                                          },
                                                        "update:search-input":
                                                          function ($event) {
                                                            _vm.CitySearch =
                                                              $event
                                                          },
                                                        keyup: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          return _vm.createCity.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "no-data",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "v-list-item",
                                                                [
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _vm._v(
                                                                            ' No results matching "'
                                                                          ),
                                                                          _c(
                                                                            "strong",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.CitySearch
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            '". Press '
                                                                          ),
                                                                          _c(
                                                                            "kbd",
                                                                            [
                                                                              _vm._v(
                                                                                "enter"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " to create a new one "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ]),
                                                      model: {
                                                        value:
                                                          _vm.branch.address
                                                            .cityId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.branch.address,
                                                            "cityId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "branch.address.cityId",
                                                      },
                                                    }),
                                                    _vm.submitted &&
                                                    !_vm.$v.branch.address
                                                      .cityId.required
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "invalid-feedback",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " City is Required "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c("v-col", {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "cancel-update-btn",
                                        attrs: { tabindex: "9", text: "" },
                                        on: { click: _vm.close },
                                      },
                                      [_vm._v("Cancel")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "create-order-btn mr-1",
                                        attrs: { tabindex: "10" },
                                        on: { click: _vm.save },
                                      },
                                      [_vm._v("Update")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-snackbar",
                      {
                        attrs: { timeout: 3000, color: _vm.snackColor },
                        scopedSlots: _vm._u([
                          {
                            key: "action",
                            fn: function ({ attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._b(
                                    {
                                      attrs: { text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.snack = false
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  [_vm._v(" Close ")]
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.snack,
                          callback: function ($$v) {
                            _vm.snack = $$v
                          },
                          expression: "snack",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.snackText) + " ")]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "item.company",
                fn: function ({ item }) {
                  return [
                    _c("p", [_c("span", [_vm._v(_vm._s(item.company.name))])]),
                  ]
                },
              },
              {
                key: "item.address",
                fn: function ({ item }) {
                  return [
                    item.address
                      ? _c("p", [
                          _c("span", [_vm._v(_vm._s(item.address.street))]),
                          _vm._v(" -"),
                          item.address.city
                            ? _c("span", [
                                _vm._v(" " + _vm._s(item.address.city.name)),
                              ])
                            : _vm._e(),
                          _vm._v("-"),
                          item.address.state
                            ? _c("span", [
                                _vm._v(_vm._s(item.address.state.target)),
                              ])
                            : _vm._e(),
                          _vm._v("-"),
                          item.address.country
                            ? _c("span", [
                                _vm._v(" " + _vm._s(item.address.country.name)),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-2 edit-icon",
                        attrs: { small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.editItem(item)
                          },
                        },
                      },
                      [_vm._v(" far fa-edit ")]
                    ),
                    _c(
                      "v-icon",
                      {
                        staticClass: "delete-icon",
                        attrs: { small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteItem(item)
                          },
                        },
                      },
                      [_vm._v(" mdi-delete ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.dialogDelete,
            callback: function ($$v) {
              _vm.dialogDelete = $$v
            },
            expression: "dialogDelete",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v("Are you sure you want to delete this item?"),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.deleteItemConfirm = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.deleteItemConfirm },
                    },
                    [_vm._v("OK")]
                  ),
                  _c("v-spacer"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }