import Vue from 'vue'

import Spinner from './helpers/spinner'
import Preloader from './helpers/preloader'
import Snackbar from './helpers/snackbar'

import AuthModule from './auth'

import Schedule from './helpers/schedule'

import Vuex from 'vuex'

Vue.use(Vuex)
var userPermissions = localStorage.getItem("user_permissions")
Vue.mixin({
  methods: {
    can: (key) => userPermissions.includes(key)
  }
})
export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {

    Spinner,
    Snackbar,
    Preloader,
    AuthModule,
    Schedule

  }
})