var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "handle-test", attrs: { id: "work-order" } },
    [
      _c(
        "div",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12", md: "10" } }, [
                _c("p", { staticClass: "table-title" }, [
                  _vm._v("Company List"),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { md: "1", cols: "12" } },
                [
                  _vm.can(23)
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "btn-creation wo-diff-style",
                          attrs: { to: "/company/create", color: "#D92020" },
                        },
                        [_vm._v("Create Company")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pad0", attrs: { cols: "12", md: "4" } },
                [
                  _c("v-text-field", {
                    ref: "objet",
                    attrs: {
                      solo: "",
                      label: "Search Company ",
                      "append-icon": "mdi-magnify",
                    },
                    on: {
                      keyup: function ($event) {
                        return _vm.realSearch(1)
                      },
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
              _c("v-col", {
                staticClass: "pad0",
                attrs: { cols: "12", md: "8" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              loading: _vm.isLoaded,
              "loading-text": "Loading... Please wait",
              headers: _vm.headers,
              items: _vm.data,
              "item-key": "name",
              "hide-default-footer": "",
              "items-per-page": _vm.pagination.pageSize,
            },
            on: { "update:options": _vm.handleOptionsUpdate },
            scopedSlots: _vm._u([
              {
                key: "footer",
                fn: function () {
                  return [
                    _c(
                      "v-row",
                      { staticClass: "mt-5 fit-width" },
                      [
                        _c(
                          "v-col",
                          { attrs: { md: "2" } },
                          [
                            _c("v-select", {
                              attrs: {
                                items: _vm.sizeOptions,
                                "item-value": "id",
                                "item-text": "name",
                                solo: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.realSearch(1)
                                },
                              },
                              model: {
                                value: _vm.pagination.pageSize,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pagination, "pageSize", $$v)
                                },
                                expression: "pagination.pageSize",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { md: "5" } },
                          [
                            _c("v-pagination", {
                              attrs: {
                                length: _vm.totalPages,
                                "total-visible": "6",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.realSearch(
                                    _vm.pagination.pageNumber
                                  )
                                },
                              },
                              model: {
                                value: _vm.pagination.pageNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pagination, "pageNumber", $$v)
                                },
                                expression: "pagination.pageNumber",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "top",
                fn: function () {
                  return [
                    _c(
                      "v-toolbar",
                      { attrs: { flat: "" } },
                      [
                        _c("v-toolbar-title", [_vm._v("Companies")]),
                        _c("v-divider", {
                          staticClass: "mx-4",
                          attrs: { inset: "", vertical: "" },
                        }),
                        _c("v-spacer"),
                        _c(
                          "v-dialog",
                          {
                            attrs: { "max-width": "700px" },
                            model: {
                              value: _vm.dialog,
                              callback: function ($$v) {
                                _vm.dialog = $$v
                              },
                              expression: "dialog",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c("v-card-title", [
                                  _c("span", { staticClass: "text-h5" }, [
                                    _vm._v("Edit Company"),
                                  ]),
                                ]),
                                _c(
                                  "v-card-text",
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { md: "12" } },
                                              [
                                                _c("v-text-field", {
                                                  class: {
                                                    "is-invalid":
                                                      _vm.submitted &&
                                                      _vm.$v.company.name
                                                        .$error,
                                                  },
                                                  attrs: {
                                                    outlined: "",
                                                    label: "Full Name",
                                                  },
                                                  model: {
                                                    value: _vm.company.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.company,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "company.name",
                                                  },
                                                }),
                                                _vm.submitted &&
                                                !_vm.$v.company.name.required
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "invalid-feedback mt-2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Name is Required "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c("v-autocomplete", {
                                                  ref: "objet",
                                                  staticClass: "mb-3",
                                                  class: {
                                                    "is-invalid":
                                                      _vm.submitted &&
                                                      _vm.$v.company.accountId
                                                        .$error,
                                                  },
                                                  attrs: {
                                                    items: _vm.accounts,
                                                    "item-text": "name",
                                                    "item-value": "id",
                                                    label: "Select Account",
                                                    outlined: "",
                                                    "search-input":
                                                      _vm.accountSearch,
                                                  },
                                                  on: {
                                                    "update:searchInput":
                                                      function ($event) {
                                                        _vm.accountSearch =
                                                          $event
                                                      },
                                                    "update:search-input":
                                                      function ($event) {
                                                        _vm.accountSearch =
                                                          $event
                                                      },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.company.accountId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.company,
                                                        "accountId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "company.accountId",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "6" },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  staticClass: "mb-3",
                                                  class: {
                                                    "is-invalid":
                                                      _vm.submitted &&
                                                      _vm.v.phoneNumber.$error,
                                                  },
                                                  attrs: {
                                                    outlined: "",
                                                    set: (_vm.v =
                                                      _vm.$v.company.companyPhones.$each[0]),
                                                    label: "Primary Phone",
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.formatPhoneNumber(
                                                        1
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.company
                                                        .companyPhones[0]
                                                        .phoneNumber,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.company
                                                          .companyPhones[0],
                                                        "phoneNumber",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "company.companyPhones[0].phoneNumber",
                                                  },
                                                }),
                                                _vm.submitted &&
                                                !_vm.v.phoneNumber.required
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "invalid-feedback",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Primary Phone is Required "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "6" },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  staticClass: "mb-3",
                                                  attrs: {
                                                    outlined: "",
                                                    label: "Secondary Phone",
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.formatPhoneNumber(
                                                        2
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.company
                                                        .companyPhones[1]
                                                        .phoneNumber,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.company
                                                          .companyPhones[1],
                                                        "phoneNumber",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "company.companyPhones[1].phoneNumber",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          staticClass: "mb-10",
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "cancel-update-btn",
                                        attrs: { text: "" },
                                        on: { click: _vm.close },
                                      },
                                      [_vm._v("Cancel")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "create-order-btn mr-1",
                                        on: { click: _vm.save },
                                      },
                                      [_vm._v("Update")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-snackbar",
                      {
                        attrs: { timeout: 3000, color: _vm.snackColor },
                        scopedSlots: _vm._u([
                          {
                            key: "action",
                            fn: function ({ attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._b(
                                    {
                                      attrs: { text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.snack = false
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  [_vm._v(" Close ")]
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.snack,
                          callback: function ($$v) {
                            _vm.snack = $$v
                          },
                          expression: "snack",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.snackText) + " ")]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "item.clientPhones",
                fn: function ({ item }) {
                  return _vm._l(item.clientPhones, function (ph, index) {
                    return _c("p", { key: index }, [
                      _c(
                        "span",
                        {
                          class: {
                            "is-Primary": ph.isPrimary,
                          },
                        },
                        [_vm._v(_vm._s(ph.phoneNumber))]
                      ),
                    ])
                  })
                },
              },
              {
                key: "item.account",
                fn: function ({ item }) {
                  return [
                    item.account
                      ? _c("p", [
                          _c("span", [_vm._v(_vm._s(item.account.name))]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-2 edit-icon",
                        attrs: { small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.editItem(item)
                          },
                        },
                      },
                      [_vm._v(" far fa-edit ")]
                    ),
                    _c(
                      "v-icon",
                      {
                        staticClass: "delete-icon",
                        attrs: { small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteItem(item)
                          },
                        },
                      },
                      [_vm._v(" mdi-delete ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "500px" },
              model: {
                value: _vm.dialogDelete,
                callback: function ($$v) {
                  _vm.dialogDelete = $$v
                },
                expression: "dialogDelete",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "text-h5" }, [
                    _vm._v("Are you sure you want to delete this item?"),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: { click: _vm.closeDelete },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: { click: _vm.deleteItemConfirm },
                        },
                        [_vm._v("OK")]
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }