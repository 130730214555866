<template>
  <div class="handle-test">
    <p class="table-title">Invoice</p>
    <div class="innner-invoice-container">
      <v-row>
        <v-col md="8" sm="7">
          <v-col md="4">
            <p class="label2-key">Client</p>
            <span class="Value-data">
              {{ invoice.clientName }}
            </span>
          </v-col>
        </v-col>
        <v-col md="4" sm="5">
          <v-col md="10" class="auto">
            <v-btn v-if="invoice.status.id == 1" class="btn-inv" @click="sendByemail">Change To Paid</v-btn>
            <export-excel class="btn-inv" type="csv" :name="invoiceFileName"
              style="padding: 18px 22px; display: inline-block; margin: 1%" :data="invoice.invoiceWordOrderDetails">
              Download Data
            </export-excel>

            <p class="lbl-inv">
              Order Status:
              <v-chip class="ma-2" style="background: #83b5ff52">
                {{ invoice.status.name }}
              </v-chip>
            </p>
            <p class="lbl-inv">
              Invoice Number:<span style="font-size: 15px">
                {{ invoice.invoiceNumber }}</span>
            </p>

            <p class="lbl-inv">
              creation Date:
              {{ formatDate2(new Date(invoice.creationDate)) }}
            </p>
          </v-col>
        </v-col>
      </v-row>
      <v-data-table
       
       
       
      :headers="headers"
       
        :items="invoice.invoiceWordOrderDetails"
        hide-default-footer

        item-key="id"
      
        
     
      >
    
      <template v-slot:item.timeSheetBillablelHours="{ item }">
          <p>
            {{ item.timeSheetBillablelHours + " hr" }}
          </p>
        </template>
        <template v-slot:item.totalBillablelHours="{ item }">
          <p>
            {{ item.totalBillablelHours + " hr" }}
          </p>
        </template>


        <template v-slot:item.billRate="{ item }">
          <p>
            {{ "$ " + item.billRate }}
          </p>
        </template>
        <template v-slot:item.totalBillablelAmount="{ item }">
          <p>
            {{ "$ " + item.timeSheetBillablelAmount }}
          </p>
        </template>
        <template v-slot:item.expensesAmount="{ item }">
          <p>
            {{ "$ " + item.expensesAmount }}
          </p>
        </template>
        <template v-slot:item.payableAmount="{ item }">
          <p>
            {{ "$ " + item.payableAmount }}
          </p>
        </template>

        <template v-slot:item.timeSheetBillablelAmount="{ item }">
          <p>
            {{ "$ " + item.timeSheetBillablelAmount }}
          </p>
        </template>

        <template v-slot:item.timeSheetExpensesAmount="{ item }">
          <p>
            {{ "$ " + item.timeSheetExpensesAmount }}
          </p>
        </template>


      </v-data-table>

      <!-- <v-row>
        <v-expansion-panels class="expansion-panels-custom mt-5" style="border: 0px">
          <v-expansion-panel>
            <v-expansion-panel-header class="v-card" style="background: transparent !important">
              <v-card class="collapse-box" style="background: transparent !important">
                <v-card flat style="padding-left: 3%">
                  <div>
                    <v-row style="padding-left: 14%">
                      <v-col class="invoice-table-headers" md="2">
                        <p class="values-main-bill">Billable Hours</p>
                        <p style="color: #afaeae !important" class="invoice-table-values values-main-bill">
                          {{ invoice.totalBillablelHours + " hr" }}
                        </p>
                      </v-col>
                      <v-col class="invoice-table-headers values-main-bill" md="2">
                        <p class="values-main-bill">Expenses Amount</p>
                        <p style="color: #afaeae !important" class="invoice-table-values values-main-bill">
                          {{ "$" + invoice.expensesAmount }}
                        </p>
                      </v-col>

                      <v-col class="invoice-table-headers" md="2">
                        <p class="values-main-bill">payableAmount</p>
                        <p class="invoice-table-values values-main-bill">
                          {{ "$" + invoice.payableAmount }}
                        </p>
                      </v-col>
                      <v-col md="2">
                        <p class="invoice-table-headers values-main-bill">
                          Billable Amount
                        </p>
                        <p class="invoice-table-values values-main-bill">
                          {{ "$" + invoice.billableAmount }}
                        </p>
                      </v-col>
                      <v-col md="2">

                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-card>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels v-for="account in invoice.accounts" :key="account.accountId"
                class="expansion-panels-custom mt-5" style="
                  border: 0px;
                  background: transparent !important;
                  margin-top: 3px !important;
                ">
                <v-expansion-panel>
                  <v-expansion-panel-header class="v-card" style="background: #22336B3B 0% 0% no-repeat padding-box;
opacity: 1; !important">
                    <v-card class="collapse-box" style="background: transparent !important">
                      <v-card style="background: transparent !important" flat>
                        <v-row>
                          <v-col class="invoice-table-headers" md="3">
                            <p class="label2-key bill-headers">
                              {{ account.accountName + " Account" }}
                            </p>
                          </v-col>

                          <v-col class="invoice-table-headers" md="3">
                            <span class="values-bill">
                              <b style="color: #43425d">Billable Hours: &nbsp;
                              </b>
                              <span style="color: #afaeae !important" class="invoice-table-values values-bill">
                                <b>
                                  {{
                                    " " + account.totalBillablelHours + " hr"
                                  }}</b>
                              </span>
                            </span>
                          </v-col>
                          <v-col md="3">
                            <span class="invoice-table-headers values-bill">
                              <b style="color: #43425d">
                                Billable Amount: &nbsp;
                              </b>
                              <span style="color: #afaeae !important" class="invoice-table-values values-bill">
                                <b>
                                  {{
                                    " " + "$" + account.totalBillablelAmount
                                  }}</b>
                              </span>
                            </span>
                          </v-col>

                          <v-col md="2"> </v-col>
                        </v-row>
                      </v-card>
                    </v-card>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-expansion-panels v-for="workOrder in account.workOrders" :key="workOrder.id"
                      class="expansion-panels-custom mt-5" style="border: 0px; background: transparent !important">
                      <v-card class="collapse-box" style="background: transparent !important">
                        <v-card flat style="
                            background: #9b35352b 0% 0% no-repeat padding-box;
                            opacity: 1;
                            padding: 3%;
                            margin-top: -2%;
                          ">
                          <v-row class="label2-key">
                            <v-col cols="12" md="6">
                              Master WorkOrderId:
                              <span style="color: #afaeae !important" class="bill-value-id">
                                {{ " " + workOrder.orderId }}
                              </span>
                            </v-col>

                            <v-col cols="12" md="6">
                              purchase order :
                              <span v-if="workOrder.poNumber != '/$'" style="color: #afaeae !important"
                                class="bill-value-id">
                                {{ " " + workOrder.poNumber }}
                              </span>

                              <span v-else style="color: #afaeae !important" class="bill-value-id">
                                ---------
                              </span>
                            </v-col>
                          </v-row>

                          <v-row class="label2-key" style="margin-top: -10px">
                            <v-col cols="12" md="6">
                              Location:
                              <span style="color: #afaeae !important" class="bill-value-id">
                                {{ " " + workOrder.location }}
                              </span>
                            </v-col>
                            <v-col cols="12" md="3">
                              State :
                              <span v-if="workOrder.stateName != ''" style="color: #afaeae !important"
                                class="bill-value-id">
                                {{ " " + workOrder.stateName }}
                              </span>
                              <span v-else style="color: #afaeae !important" class="bill-value-id">
                                ----------
                              </span>
                            </v-col>
                            <v-col cols="12" md="1"></v-col>
                            <v-col cols="12" md="2">
                              City :
                              <span v-if="workOrder.cityName != ''" style="color: #afaeae !important"
                                class="bill-value-id">
                                {{ " " + workOrder.cityName }}
                              </span>
                              <span v-else style="color: #afaeae !important" class="bill-value-id">
                                {{ " " + workOrder.cityName }}
                              </span>
                            </v-col>
                          </v-row>
                         
                          <div>
                            <v-row>
                              <v-col class="invoice-table-headers" cols="0" md="2">
                              </v-col>

                              <v-col cols="6" class="invoice-table-headers values-bill" md="2">
                                <p>Billable Hours</p>
                                <p style="color: #afaeae !important" class="invoice-table-values values-bill">
                                  {{ workOrder.totalBillablelHours + " hr" }}
                                </p>
                              </v-col>
                              <v-col cols="6" md="2">
                                <p class="invoice-table-headers values-bill">
                                  Billable Amount
                                </p>
                                <p style="color: #afaeae !important" class="invoice-table-values values-bill">
                                  {{ "$" + workOrder.totalBillablelAmount }}
                                </p>
                              </v-col>
                              <v-col cols="6" md="2">
                                <p class="invoice-table-headers values-bill">
                                  Expenses Amount
                                </p>
                                <p style="color: #afaeae !important" class="invoice-table-values values-bill">
                                  {{ "$" + workOrder.expensesAmount }}
                                </p>
                              </v-col>
                              <v-col cols="6" md="2">
                                <p class="invoice-table-headers values-bill">
                                  Bill Rate
                                </p>
                                <p style="color: #afaeae !important" class="invoice-table-values values-bill">
                                  {{ "$" + workOrder.billRate }}
                                </p>
                              </v-col>
                            </v-row>
                          </div>

                      
                          <v-card v-for="timeSheet in workOrder.timeSheets" :key="timeSheet.timeSheetId">
                            <v-card flat style="
                               background: #226B4929 0% 0% no-repeat padding-box;
opacity: 1; !important;
                                padding-left: 2%;
                              ">
                              <br />

                              <v-row class="label2-key">
                                <v-col cols="12" md="6">
                                  Technician Name:
                                  <span style="color: #afaeae !important" class="bill-value-id">
                                    {{ " " + timeSheet.technicianName }}
                                  </span>
                                </v-col>
                                <v-col cols="12" md="6">
                                  Sub WorkOrderId:
                                  <span style="color: #afaeae !important" class="bill-value-id">
                                    {{ " " + timeSheet.subWorkOrderId }}
                                  </span>
                                </v-col>
                              </v-row>

                              <v-row class="label2-key" style="margin-top: -10px">
                                <v-col cols="12" md="6">
                                  Start Date:
                                  <span style="color: #afaeae !important" class="bill-value-id">
                                    {{
                                      " " +
                                        formatDate2(new Date(timeSheet.startDate))
                                    }}
                                  </span>
                                </v-col>
                                <v-col cols="12" md="6">
                                  End Date:
                                  <span style="color: #afaeae !important" class="bill-value-id">
                                    {{
                                      " " +
                                        formatDate2(new Date(timeSheet.endDate))
                                    }}
                                  </span>
                                </v-col>
                              </v-row>

                              <div>
                                <v-row>
                                  <v-col class="invoice-table-headers" cols="0" md="2">
                                  </v-col>

                                  <v-col cols="6" class="invoice-table-headers values-bill" md="2">
                                    <p class="values-bill">Billable Hours</p>
                                    <p style="color: #afaeae !important" class="invoice-table-values values-bill">
                                      {{ timeSheet.billablelHours + " hr" }}
                                    </p>
                                  </v-col>
                                  <v-col md="2" cols="6">
                                    <p class="invoice-table-headers values-bill">
                                      Billable Amount
                                    </p>
                                    <p style="color: #afaeae !important" class="invoice-table-values values-bill">
                                      {{ "$" + timeSheet.billablelAmount }}
                                    </p>
                                  </v-col>
                                  <v-col cols="6" md="2">
                                    <p class="invoice-table-headers values-bill">
                                      Expenses Amount
                                    </p>
                                    <p style="color: #afaeae !important" class="invoice-table-values values-bill">
                                      {{ "$" + timeSheet.expensesAmount }}
                                    </p>
                                  </v-col>
                                  <v-col cols="6" md="2">
                                    <p class="invoice-table-headers values-bill">
                                      Bill Rate
                                    </p>
                                    <p style="color: #afaeae !important" class="invoice-table-values values-bill">
                                      {{ "$" + timeSheet.billRate }}
                                    </p>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-card>
                          </v-card>
                        </v-card>
                      </v-card>
                    </v-expansion-panels>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row> -->

      <div v-if="invoice.status.id == 2" style="margin-top: 4%">
        <v-divider class="mb-6"></v-divider>
        <v-row>
          <p class="label2-key">Payment Details</p>
          <v-col md="8" sm="8">
            <v-col md="10" class="auto">
              <p class="lbl-inv">
                payment_way: {{ invoice.paymentDetail.paymentWay }}
              </p>
              <p class="lbl-inv">
                source Number:
                {{ invoice.paymentDetail.sourceNumber }}
              </p>
              <p class="lbl-inv">
                paymentDate:
                {{ formatDate2(new Date(invoice.paymentDetail.paymentDate)) }}
              </p>
            </v-col>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-dialog v-model="dialog" class="dialog" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Payment Details</span>
        </v-card-title>
        <v-card-text>
          <div>
            <v-divider class="mb-6"></v-divider>

            <v-radio-group mandatory v-model="Payment.paymentWayId" row>
              <v-radio class="col-md-4" v-for="item in paymentWays" :key="item.id" :label="item.name"
                :value="item.id"></v-radio>
            </v-radio-group>

            <v-row>
              <v-col cols="12" lg="6">
                <v-text-field outlined v-model="Payment.sourceNumber" label="Source Number" :class="{
                  'is-invalid': submit && $v.Payment.sourceNumber.$error,
                }"></v-text-field>
                <p v-if="submit && !$v.Payment.sourceNumber.required" class="invalid-feedback mt-2 ml-1">
                  Source Number Is Required
                </p>
              </v-col>

              <v-col cols="12" lg="6">
                <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition"
                  offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="Payment.paymentDate" label="Payment Date" prepend-icon="mdi-calendar"
                      v-bind="attrs" outlined @blur="date = parseDate(Payment.paymentDate)" v-on="on" :class="{
                        'is-invalid': submit && $v.Payment.paymentDate.$error,
                      }"></v-text-field>
                  </template>

                  <v-date-picker v-model="date" no-title @input="menu1 = false"></v-date-picker>
                </v-menu>
                <p v-if="submit && !$v.Payment.paymentDate.required" class="invalid-feedback mt-2 ml-1">
                  paymentDate Is Required
                </p>
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text class="cancel-update-btn" @click="close">Cancel</v-btn>

          <v-btn class="create-order-btn mr-1" @click="save">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { dataService } from "@/services/data-service";
import { required } from "vuelidate/lib/validators";

export default {
  data: (vm) => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    menu1: false,
    menu2: false,

    headers: [
       
    
        { text: "Order ID", value: "orderId" },
        { text: "account", value: "accountName" },
        { text: "Σ WorkOrder Billablel Hours", value: "totalBillablelHours" },
        { text: "Σ workOrder Billablel Amount", value: "totalBillablelAmount" },
        { text: "Σ workOrder Expense Amount", value: "expensesAmount" }, 
      
        { text: "workOrder bill Rate", value: "billRate" },



        { text: "Technician Name", value: "technicianName" },
        { text: "Σ TimeSheet Billablel Hours", value: "timeSheetBillablelHours" },
        { text: "Σ TimeSheet Billablel Amount", value: "timeSheetBillablelAmount" },
        { text: "Σ TimeSheet Expenses Amount", value: "timeSheetExpensesAmount" },
       
      ],
    invoice: {},
    dialog: false,
    invoiceFileName: "",
    paymentWays: [],
    submit: false,
    Payment: {
      invoiceId: 0,
      paymentWayId: 0,
      paymentDate: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      sourceNumber: "",
    },
  }),
  methods: {
    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },
    formatDate2(date) {
      return [
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
        date.getFullYear(),
      ].join("/");
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    convertUTCDateToLocalDate(date) {
      var newDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60 * 1000
      );

      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();

      newDate.setHours(hours - offset);

      return newDate;
    },
    close() {
      this.dialog = false;
    },
    save() {
      this.submit = true;
      this.$v.$touch();
      if (this.$v.Payment.$invalid) {
        return;
      }

      this.invoicePaymet();

    },
    invoicePaymet() {
      this.Payment.paymentDate = new Date(this.Payment.paymentDate);
      dataService.create(
        "api/Invoices/change-status",
        this.Payment,
        () => {
          //console.log(response.data.data);

          this.$router.push({ name: "invoices" });
        }
      );
    },
    getInvoice(id) {
      dataService.create(
        "api/Invoices/get-invoice-group-by-wo",
        { invoiceId: id },
        (response) => {
          this.invoice = response.data.data;
           this.invoice.invoiceWordOrderDetails.forEach(element => {

element.startDate= this.formatDate2(new Date(element.startDate))

element.endDate= this.formatDate2(new Date(element.endDate))
            
          });

          this.invoiceFileName =
            this.invoice.clientName +
            this.formatDate2(new Date(this.invoice.creationDate)) +
            ".xls";
          this.Payment.invoiceId = id;

        }
      );
    },
    getPaymenWays() {
      dataService.getAll("api/Enums/payment_way", (response) => {
        this.paymentWays = response.data;

      });
    },
    backToInvoice() {
      this.$router.push({ name: "invoices" });
    },
    sendByemail() {
      this.dialog = true;
      this.getPaymenWays();
    },
  },

  mounted() {
    var invoiceId = this.$router.currentRoute.params.id;
    this.getInvoice(invoiceId);
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    date() {
      this.Payment.paymentDate = this.formatDate(this.date);
    },
  },
  validations: {
    Payment: {
      paymentDate: { required },
      sourceNumber: { required },
    },
  },
};
</script>