var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { md: "3", cols: "3" } }, [_c("SideMenu")], 1),
          _c(
            "v-col",
            { attrs: { md: "9", cols: "9", id: "notification" } },
            [
              _c("v-simple-table", {
                staticClass: "mt-9 mr-15",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticClass: "text-left" }),
                            _c("th", { staticClass: "text-left header-name" }, [
                              _vm._v("Email"),
                            ]),
                            _c("th", { staticClass: "text-left header-name" }, [
                              _vm._v("Text"),
                            ]),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.topics, function (item) {
                            return _c("tr", { key: item.id }, [
                              item.topic.id < 6
                                ? _c("td", [
                                    _c("p", { staticClass: "body-name" }, [
                                      _vm._v(_vm._s(item.topic.name)),
                                    ]),
                                  ])
                                : _vm._e(),
                              item.topic.id < 6
                                ? _c(
                                    "td",
                                    [
                                      _c("v-switch", {
                                        attrs: { color: "#2A8D3D", inset: "" },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updateNotification(item)
                                          },
                                        },
                                        model: {
                                          value: item.email,
                                          callback: function ($$v) {
                                            _vm.$set(item, "email", $$v)
                                          },
                                          expression: "item.email",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.topic.id < 6
                                ? _c(
                                    "td",
                                    [
                                      item.disabled
                                        ? _c("v-switch", {
                                            attrs: {
                                              color: "#2A8D3D",
                                              inset: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.updateNotification(
                                                  item
                                                )
                                              },
                                            },
                                            model: {
                                              value: item.text,
                                              callback: function ($$v) {
                                                _vm.$set(item, "text", $$v)
                                              },
                                              expression: "item.text",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ])
                          }),
                          0
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }