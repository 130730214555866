var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isOpenB
      ? _c(
          "div",
          { staticClass: "test" },
          [
            _c("v-icon", { staticClass: "btnbar", on: { click: _vm.click } }, [
              _vm._v("X"),
            ]),
            _c("br"),
            _c("br"),
            _c(
              "div",
              { staticClass: "px-4 py-0" },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "FinRepo-sty",
                        attrs: { cols: "12", md: "8" },
                      },
                      [
                        _c("p", { staticClass: "table-title" }, [
                          _vm._v("Financial Report"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c("p", { staticClass: "Report-color" }, [
                  _vm._v("Service date :"),
                ]),
                _c("br"),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "FinRepo-sty",
                        staticStyle: { "margin-bottom": "-14px" },
                        attrs: { cols: "12", md: "12" },
                      },
                      [
                        _c("v-autocomplete", {
                          ref: "clientSearch",
                          attrs: {
                            multiple: "",
                            outlined: "",
                            label: "Custom date",
                            items: _vm.clients,
                            "item-text": "name",
                            "item-value": "id",
                          },
                          on: { change: _vm.handleClientFilter },
                          model: {
                            value: _vm.clientIds,
                            callback: function ($$v) {
                              _vm.clientIds = $$v
                            },
                            expression: "clientIds",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { staticStyle: { "margin-bottom": "-35px" } },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "FinRepo-sty",
                        attrs: { cols: "6", md: "6" },
                      },
                      [
                        _c("v-autocomplete", {
                          ref: "DateFrom",
                          attrs: {
                            "prepend-inner-icon": "mdi-calendar",
                            multiple: "",
                            outlined: "",
                            label: "From",
                            items: _vm.From,
                            "item-text": "name",
                            "item-value": "id",
                          },
                          on: { change: _vm.handleClientFilter },
                          model: {
                            value: _vm.clientIds,
                            callback: function ($$v) {
                              _vm.clientIds = $$v
                            },
                            expression: "clientIds",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "FinRepo-sty",
                        attrs: { cols: "6", md: "6" },
                      },
                      [
                        _c("v-autocomplete", {
                          ref: "DateTo",
                          attrs: {
                            "prepend-inner-icon": "mdi-calendar",
                            multiple: "",
                            outlined: "",
                            label: "To",
                            items: _vm.To,
                            "item-text": "name",
                            "item-value": "id",
                          },
                          on: { change: _vm.handleClientFilter },
                          model: {
                            value: _vm.clientIds,
                            callback: function ($$v) {
                              _vm.clientIds = $$v
                            },
                            expression: "clientIds",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("hr"),
                _c("br"),
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { cols: "12", md: "8" } }, [
                      _c("p", { staticClass: "FinRepo-sty" }, [
                        _vm._v("Filter By Client"),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "FinRepo-sty",
                    attrs: { cols: "12", md: "12" },
                  },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", md: "12" } },
                          [
                            _c("v-autocomplete", {
                              ref: "clientSearch",
                              staticStyle: { "margin-bottom": "-35px" },
                              attrs: {
                                multiple: "",
                                outlined: "",
                                label: "Select",
                                items: _vm.clients,
                                "item-text": "name",
                                "item-value": "id",
                              },
                              on: { change: _vm.handleClientFilter },
                              model: {
                                value: _vm.clientIds,
                                callback: function ($$v) {
                                  _vm.clientIds = $$v
                                },
                                expression: "clientIds",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("hr"),
                _c("br"),
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { cols: "12", md: "8" } }, [
                      _c("p", { staticClass: "FinRepo-sty" }, [
                        _vm._v("Filter By Account"),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "FinRepo-sty",
                    attrs: { cols: "12", md: "12" },
                  },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", md: "12" } },
                          [
                            _c("v-autocomplete", {
                              ref: "clientSearch",
                              staticStyle: { "margin-bottom": "-35px" },
                              attrs: {
                                multiple: "",
                                outlined: "",
                                label: "Select",
                                items: _vm.clients,
                                "item-text": "name",
                                "item-value": "id",
                              },
                              on: { change: _vm.handleClientFilter },
                              model: {
                                value: _vm.clientIds,
                                callback: function ($$v) {
                                  _vm.clientIds = $$v
                                },
                                expression: "clientIds",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("hr"),
                _c("br"),
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { cols: "12", md: "8" } }, [
                      _c("p", { staticClass: "FinRepo-sty" }, [
                        _vm._v("Filter By Location"),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "FinRepo-sty",
                    attrs: { cols: "12", md: "12" },
                  },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", md: "12" } },
                          [
                            _c("v-autocomplete", {
                              ref: "clientSearch",
                              staticStyle: { "margin-bottom": "-35px" },
                              attrs: {
                                multiple: "",
                                outlined: "",
                                label: "Select",
                                items: _vm.clients,
                                "item-text": "name",
                                "item-value": "id",
                              },
                              on: { change: _vm.handleClientFilter },
                              model: {
                                value: _vm.clientIds,
                                callback: function ($$v) {
                                  _vm.clientIds = $$v
                                },
                                expression: "clientIds",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("hr"),
                _c("br"),
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { cols: "12", md: "8" } }, [
                      _c("p", { staticClass: "FinRepo-sty" }, [
                        _vm._v("Filter By Vendor"),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "FinRepo-sty",
                    attrs: { cols: "12", md: "12" },
                  },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", md: "12" } },
                          [
                            _c("v-autocomplete", {
                              ref: "clientSearch",
                              staticStyle: { "margin-bottom": "-29px" },
                              attrs: {
                                multiple: "",
                                outlined: "",
                                label: "TECH",
                                items: _vm.clients,
                                "item-text": "name",
                                "item-value": "id",
                              },
                              on: { change: _vm.handleClientFilter },
                              model: {
                                value: _vm.clientIds,
                                callback: function ($$v) {
                                  _vm.clientIds = $$v
                                },
                                expression: "clientIds",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "test2", attrs: { id: "fixed" } },
      [
        !_vm.isOpenB
          ? _c("v-icon", { on: { click: _vm.click } }, [
              _vm._v("fa-regular fa-bars"),
            ])
          : _vm._e(),
        _vm._m(0),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c(
        "span",
        {
          staticClass: "FinancialTab",
          staticStyle: { "text-align": "center", padding: "20%" },
        },
        [
          _c("span", { staticStyle: { color: "#1B7BF9" } }, [
            _vm._v("Report from"),
          ]),
          _vm._v(" Jan 1,2022 "),
          _c("span", { staticStyle: { color: "#1B7BF9" } }, [_vm._v("to")]),
          _vm._v(" March 20,2022 "),
          _c("span", { staticStyle: { color: "lightslategrey" } }, [
            _vm._v("Custom date"),
          ]),
        ]
      ),
      _c("a", { staticClass: "FinancialTab", attrs: { href: "#" } }, [
        _c("b", [_vm._v("Invoces")]),
      ]),
      _c(
        "table",
        {
          staticClass: "table table-hover",
          staticStyle: {
            "text-align": "center",
            display: "block",
            height: "100%",
            width: "100%",
          },
        },
        [
          _c("thead", { staticStyle: { "text-align": "center" } }, [
            _c("tr", [
              _c(
                "th",
                { staticClass: "colsrowpad px-8", attrs: { scope: "col" } },
                [_vm._v("Client")]
              ),
              _c(
                "th",
                { staticClass: "colsrowpad px-8", attrs: { scope: "col" } },
                [_vm._v("time")]
              ),
              _c(
                "th",
                { staticClass: "colsrowpad px-8", attrs: { scope: "col" } },
                [_vm._v("time")]
              ),
              _c(
                "th",
                { staticClass: "colsrowpad px-8", attrs: { scope: "col" } },
                [_vm._v("time")]
              ),
              _c(
                "th",
                { staticClass: "colsrowpad px-8", attrs: { scope: "col" } },
                [_vm._v("time")]
              ),
              _c(
                "th",
                { staticClass: "colsrowpad px-8", attrs: { scope: "col" } },
                [_vm._v("time")]
              ),
              _c(
                "th",
                { staticClass: "colsrowpad px-8", attrs: { scope: "col" } },
                [_vm._v("Total")]
              ),
            ]),
          ]),
          _c("tbody", [
            _c("tr", [
              _c("td", [_vm._v("Stefanini")]),
              _c("td", { staticClass: "FinancialTabColor" }, [_vm._v("$192")]),
              _c("td", { staticClass: "FinancialTabColor" }, [_vm._v("$234")]),
              _c("td", { staticClass: "FinancialTabColor" }, [_vm._v("$378")]),
              _c("td", { staticClass: "FinancialTabColor" }, [_vm._v("$120")]),
              _c("td", { staticClass: "FinancialTabColor" }, [_vm._v("$120")]),
              _c("td", { staticClass: "FinancialTabColor" }, [
                _vm._v("$1.044"),
              ]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("LVN")]),
              _c("td", { staticClass: "FinancialTabColor" }, [_vm._v("$192")]),
              _c("td", { staticClass: "FinancialTabColor" }, [_vm._v("$234")]),
              _c("td", { staticClass: "FinancialTabColor" }, [_vm._v("$378")]),
              _c("td", { staticClass: "FinancialTabColor" }, [_vm._v("$120")]),
              _c("td", { staticClass: "FinancialTabColor" }, [_vm._v("$120")]),
              _c("td", { staticClass: "FinancialTabColor" }, [
                _vm._v("$1.044"),
              ]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("Nike")]),
              _c("td", { staticClass: "FinancialTabColor" }, [_vm._v("$192")]),
              _c("td", { staticClass: "FinancialTabColor" }, [_vm._v("$234")]),
              _c("td", { staticClass: "FinancialTabColor" }, [_vm._v("$378")]),
              _c("td", { staticClass: "FinancialTabColor" }, [_vm._v("$120")]),
              _c("td", { staticClass: "FinancialTabColor" }, [_vm._v("$120")]),
              _c("td", { staticClass: "FinancialTabColor" }, [
                _vm._v("$1.044"),
              ]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("Refund")]),
              _c("td", { staticClass: "FinancialTabColor" }, [_vm._v("$192")]),
              _c("td", { staticClass: "FinancialTabColor" }, [_vm._v("$234")]),
              _c("td", { staticClass: "FinancialTabColor" }, [_vm._v("$378")]),
              _c("td", { staticClass: "FinancialTabColor" }, [_vm._v("$120")]),
              _c("td", { staticClass: "FinancialTabColor" }, [_vm._v("$120")]),
              _c("td", { staticClass: "FinancialTabColor" }, [
                _vm._v("$1.044"),
              ]),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }