var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "handle-test" }, [
    _c("p", { staticClass: "table-title" }, [_vm._v("New Account")]),
    _c(
      "div",
      { staticClass: "innner-container" },
      [
        _c(
          "v-row",
          [
            _c("v-col", { attrs: { md: "10" } }),
            _c(
              "v-col",
              { attrs: { md: "2" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "resetWO-btn",
                    on: { click: _vm.emptyButtons },
                  },
                  [
                    _c("i", { staticClass: "fas fa-redo" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-1" }, [_vm._v("Reset")]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [
                _c("v-text-field", {
                  staticClass: "mb-3",
                  class: {
                    "is-invalid": _vm.submitted && _vm.$v.account.name.$error,
                  },
                  attrs: { tabindex: "1", outlined: "", label: "Name" },
                  model: {
                    value: _vm.account.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.account, "name", $$v)
                    },
                    expression: "account.name",
                  },
                }),
                _vm.submitted && !_vm.$v.account.name.required
                  ? _c("p", { staticClass: "invalid-feedback" }, [
                      _vm._v(" Name is Required "),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [
                _c("v-autocomplete", {
                  staticClass: "mb-3",
                  class: {
                    "is-invalid":
                      _vm.submitted && _vm.$v.account.clientId.$error,
                  },
                  attrs: {
                    tabindex: "2",
                    items: _vm.clients,
                    "item-text": "name",
                    "item-value": "id",
                    label: "Select Client",
                    outlined: "",
                  },
                  model: {
                    value: _vm.account.clientId,
                    callback: function ($$v) {
                      _vm.$set(_vm.account, "clientId", $$v)
                    },
                    expression: "account.clientId",
                  },
                }),
                _vm.submitted && !_vm.$v.account.clientId.required
                  ? _c("p", { staticClass: "invalid-feedback" }, [
                      _vm._v(" Client is Required "),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "btn-container" },
          [
            _c(
              "v-btn",
              {
                staticClass: "create-order-btn mr-1",
                attrs: { tabindex: "3" },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.check()
                  },
                  click: _vm.check,
                },
              },
              [_vm._v("Create Account")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }