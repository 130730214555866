var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload-section", attrs: { id: "users" } },
    [
      !_vm.$vuetify.breakpoint.xsOnly ? _c("SideMenu") : _vm._e(),
      _c(
        "div",
        { staticClass: "user-creation ml-4 mt-4" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12", md: "10" } }, [
                _c("p", { staticClass: "table-title" }, [
                  _vm._v("Email Templates"),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { md: "1", cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-creation wo-diff-style",
                      attrs: {
                        to: "/settings/CreateEmailTemplatesSettings",
                        color: "#D92020",
                      },
                    },
                    [_vm._v("Create Template ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("br"),
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.data,
                  "item-key": "id",
                },
                on: { "click:row": _vm.rowClick },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }