<template>
  <div class="login-container">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div>
      <img class="axa-logo" src="@/assets/images/AXA LOGO TRANPARENT.png" />
    </div>
    <div class="login-box">
      <v-row>
        <v-col cols="12" md="6">
          <div class="st-box" id="login-feild">
            <p class="login-text">Login</p>
            <v-text-field
              :class="{ 'is-invalid': submitted && $v.userName.$error }"
              v-model="userName"
              solo
              label="Email"
            >
            </v-text-field>
            <p
              v-if="submitted && !$v.userName.required"
              class="invalid-feedback mt-3"
            >
              email is Required
            </p>
            <p
              v-if="submitted && !$v.userName.email"
              class="invalid-feedback mt-3"
            >
              email is Invalid
            </p>

            <v-text-field
              v-model="password"
              type="password"
              solo
              label="Password"
              :class="{ 'is-invalid': submitted && $v.password.$error }"
              v-on:keyup.enter="handleSubmit"
            >
            </v-text-field>
            <p
              v-if="submitted && !$v.password.required"
              class="invalid-feedback mt-3"
            >
              Password is Required
            </p>
            <p class="fb-text">FORGET PASSWORD?</p>
            <v-btn @click="handleSubmit()" class="signin-btn">SIGN IN</v-btn>
          </div>
        </v-col>
        <v-col class="rspnsv-hide" md="6">
          <img class="nd-box" src="@/assets/images/work.png" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      invalidData: false,
      submitted: false,
      userName: "",
      password: "",
    };
  },
  validations: {
    userName: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  methods: {
    handleSubmit() {
      this.loading=true;
      this.submitted = true;
      const email = this.userName;
      const password = this.password;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.loading=false;
        return;
      } else {
        this.$store
          .dispatch("AuthModule/signIn", {
            email,
            password,
          })
          .catch((err) => {
            if (err) {
              this.invalidData = err.message;
            }
          });

          this.loading=false;

        // this.$router.push("dashboard");
      }
    },
  },
  computed: {
    isLoggining() {
      return this.$store.getters.authStatus;
    },
  },
};
</script>