var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "handle-test", attrs: { id: "work-order" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12", md: "10" } }, [
            _c("p", { staticClass: "table-title" }, [
              _vm._v("Technician Payroll"),
            ]),
          ]),
          _c("v-col", { staticClass: "Gml-3", attrs: { md: "1", cols: "12" } }),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pad0", attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.filterBy,
                          "item-value": "id",
                          "item-text": "name",
                          label: "Date",
                          outlined: "",
                        },
                        model: {
                          value: _vm.dateRange,
                          callback: function ($$v) {
                            _vm.dateRange = $$v
                          },
                          expression: "dateRange",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _vm.dateRange == 6
                        ? _c(
                            "v-menu",
                            {
                              ref: "menu",
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.SDFrom,
                                transition: "scale-transition",
                                "offset-y": "",
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  _vm.SDFrom = $event
                                },
                                "update:return-value": function ($event) {
                                  _vm.SDFrom = $event
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label:
                                                    "   Service Date Start Date",
                                                  "prepend-inner-icon":
                                                    "mdi-calendar",
                                                  readonly: "",
                                                  outlined: "",
                                                },
                                                model: {
                                                  value: _vm.SDFrom,
                                                  callback: function ($$v) {
                                                    _vm.SDFrom = $$v
                                                  },
                                                  expression: "SDFrom",
                                                },
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                845963563
                              ),
                              model: {
                                value: _vm.menuStartDate,
                                callback: function ($$v) {
                                  _vm.menuStartDate = $$v
                                },
                                expression: "menuStartDate",
                              },
                            },
                            [
                              _c(
                                "v-date-picker",
                                {
                                  attrs: { "no-title": "", scrollable: "" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$refs.menu.save(_vm.SDFrom)
                                    },
                                    change: _vm.getWOByDate,
                                  },
                                  model: {
                                    value: _vm.SDFrom,
                                    callback: function ($$v) {
                                      _vm.SDFrom = $$v
                                    },
                                    expression: "SDFrom",
                                  },
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.menuStartDate = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.menu.save(_vm.SDFrom)
                                        },
                                      },
                                    },
                                    [_vm._v(" OK ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _vm.dateRange == 6
                        ? _c(
                            "v-menu",
                            {
                              ref: "menu2",
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.SDTo,
                                transition: "scale-transition",
                                "offset-y": "",
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  _vm.SDTo = $event
                                },
                                "update:return-value": function ($event) {
                                  _vm.SDTo = $event
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label:
                                                    "Service Date End Date",
                                                  "prepend-inner-icon":
                                                    "mdi-calendar",
                                                  readonly: "",
                                                  outlined: "",
                                                },
                                                model: {
                                                  value: _vm.SDTo,
                                                  callback: function ($$v) {
                                                    _vm.SDTo = $$v
                                                  },
                                                  expression: "SDTo",
                                                },
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3178829289
                              ),
                              model: {
                                value: _vm.menuEndDate,
                                callback: function ($$v) {
                                  _vm.menuEndDate = $$v
                                },
                                expression: "menuEndDate",
                              },
                            },
                            [
                              _c(
                                "v-date-picker",
                                {
                                  attrs: { "no-title": "", scrollable: "" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$refs.menu2.save(_vm.SDTo)
                                    },
                                    change: _vm.getWOByDate,
                                  },
                                  model: {
                                    value: _vm.SDTo,
                                    callback: function ($$v) {
                                      _vm.SDTo = $$v
                                    },
                                    expression: "SDTo",
                                  },
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.menuEndDate = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.menu2.save(_vm.SDTo)
                                        },
                                      },
                                    },
                                    [_vm._v(" OK ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pad0", attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12", md: "4" } }),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "margin-left": "2%" },
                      attrs: { md: "2" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-creation diff-style mt-1",
                          attrs: { color: "#D92020" },
                          on: { click: _vm.getData },
                        },
                        [_c("v-icon", [_vm._v("fa-solid fa-play")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { md: "2" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-creation wo-diff-style",
                          on: { click: _vm.exportData },
                        },
                        [
                          _c("img", {
                            staticStyle: { "padding-right": "10%" },
                            attrs: {
                              src: require("@/assets/images/export.png"),
                            },
                          }),
                          _vm._v(" Export File "),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pad0", attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.techTypes,
                          "item-value": "id",
                          "item-text": "name",
                          label: "Technician Type",
                          outlined: "",
                          multiple: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function (data) {
                              return [
                                _c(
                                  "v-chip",
                                  _vm._b(
                                    {
                                      attrs: {
                                        "input-value": data.selected,
                                        close: "",
                                      },
                                      on: {
                                        click: data.select,
                                        "click:close": function ($event) {
                                          return _vm.removeTechType(
                                            data.item.id
                                          )
                                        },
                                      },
                                    },
                                    "v-chip",
                                    data.attrs,
                                    false
                                  ),
                                  [_vm._v(" " + _vm._s(data.item.name) + " ")]
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.requestData.technicianTypeIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.requestData, "technicianTypeIds", $$v)
                          },
                          expression: "requestData.technicianTypeIds",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c("v-autocomplete", {
                        ref: "techsearch",
                        attrs: {
                          items: _vm.technicians,
                          "item-text": (item) =>
                            item.firstName +
                            " " +
                            item.lastName +
                            " " +
                            "-" +
                            " " +
                            item.email,
                          "item-value": "id",
                          label: "Select Technicians",
                          "search-input": _vm.techsearch,
                          outlined: "",
                          multiple: "",
                        },
                        on: {
                          "update:searchInput": function ($event) {
                            _vm.techsearch = $event
                          },
                          "update:search-input": function ($event) {
                            _vm.techsearch = $event
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function (data) {
                              return [
                                _c(
                                  "v-chip",
                                  _vm._b(
                                    {
                                      attrs: {
                                        "input-value": data.selected,
                                        close: "",
                                      },
                                      on: {
                                        click: data.select,
                                        "click:close": function ($event) {
                                          return _vm.removeTech(data.item.id)
                                        },
                                      },
                                    },
                                    "v-chip",
                                    data.attrs,
                                    false
                                  ),
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          data.item.firstName +
                                            " " +
                                            data.item.lastName
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.requestData.technicianIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.requestData, "technicianIds", $$v)
                          },
                          expression: "requestData.technicianIds",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c("v-autocomplete", {
                        ref: "clientSearch",
                        attrs: {
                          multiple: "",
                          outlined: "",
                          label: "Select Clients",
                          items: _vm.clients,
                          "item-text": "name",
                          "item-value": "id",
                        },
                        on: { change: _vm.handleClientFilter },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function (data) {
                              return [
                                _c(
                                  "v-chip",
                                  _vm._b(
                                    {
                                      attrs: {
                                        "input-value": data.selected,
                                        close: "",
                                      },
                                      on: {
                                        click: data.select,
                                        "click:close": function ($event) {
                                          return _vm.removeClient(data.item.id)
                                        },
                                      },
                                    },
                                    "v-chip",
                                    data.attrs,
                                    false
                                  ),
                                  [_vm._v(" " + _vm._s(data.item.name) + " ")]
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.requestData.clientIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.requestData, "clientIds", $$v)
                          },
                          expression: "requestData.clientIds",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pad0", attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12", md: "4" } }, [
                    _c(
                      "main",
                      {
                        directives: [
                          {
                            name: "scroll",
                            rawName: "v-scroll:#container",
                            value: _vm.fixPosition,
                            expression: "fixPosition",
                            arg: "#container",
                          },
                        ],
                        attrs: { id: "container" },
                      },
                      [
                        _c("v-autocomplete", {
                          ref: "accountSearch",
                          attrs: {
                            items: _vm.accounts,
                            "item-text": "name",
                            "item-value": "id",
                            multiple: "",
                            outlined: "",
                            label: "Select Accounts",
                            "search-input": _vm.accountsearch,
                          },
                          on: {
                            "update:searchInput": function ($event) {
                              _vm.accountsearch = $event
                            },
                            "update:search-input": function ($event) {
                              _vm.accountsearch = $event
                            },
                            change: _vm.handleAccountFilter,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "selection",
                              fn: function (data) {
                                return [
                                  _c(
                                    "v-chip",
                                    _vm._b(
                                      {
                                        attrs: {
                                          "input-value": data.selected,
                                          close: "",
                                        },
                                        on: {
                                          click: data.select,
                                          "click:close": function ($event) {
                                            return _vm.removeAccount(
                                              data.item.id
                                            )
                                          },
                                        },
                                      },
                                      "v-chip",
                                      data.attrs,
                                      false
                                    ),
                                    [_vm._v(" " + _vm._s(data.item.name) + " ")]
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.requestData.accountIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.requestData, "accountIds", $$v)
                            },
                            expression: "requestData.accountIds",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c("v-autocomplete", {
                        ref: "locationSearch",
                        attrs: {
                          items: _vm.locations,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          outlined: "",
                          label: "Select Locations",
                          "search-input": _vm.locationSearch,
                        },
                        on: {
                          change: _vm.handleLocationFilter,
                          "update:searchInput": function ($event) {
                            _vm.locationSearch = $event
                          },
                          "update:search-input": function ($event) {
                            _vm.locationSearch = $event
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function (data) {
                              return [
                                _c(
                                  "v-chip",
                                  _vm._b(
                                    {
                                      attrs: {
                                        "input-value": data.selected,
                                        close: "",
                                      },
                                      on: {
                                        click: data.select,
                                        "click:close": function ($event) {
                                          return _vm.removelocation(
                                            data.item.id
                                          )
                                        },
                                      },
                                    },
                                    "v-chip",
                                    data.attrs,
                                    false
                                  ),
                                  [_vm._v(" " + _vm._s(data.item.name) + " ")]
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.requestData.branchIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.requestData, "branchIds", $$v)
                          },
                          expression: "requestData.branchIds",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-creation diff-style mt-1",
                          attrs: { color: "#D92020" },
                          on: { click: _vm.reset },
                        },
                        [_vm._v("Reset Filter")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-bottom": "3%" } },
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              loading: _vm.isLoaded,
              "loading-text": "Loading... Please wait",
              headers: _vm.headers,
              "items-per-page": _vm.reportData.length,
              items: _vm.reportData,
              "item-key": "id",
              "show-expand": "",
              "hide-default-footer": "",
              expanded: _vm.expanded,
            },
            on: {
              "update:expanded": function ($event) {
                _vm.expanded = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.totalPayableAmount",
                fn: function ({ item }) {
                  return [
                    _c("p", [
                      _vm._v(
                        " " + _vm._s("$ " + item.totalPayableAmount) + " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "item.totalAttendHours",
                fn: function ({ item }) {
                  return [
                    _c("p", [
                      _vm._v(" " + _vm._s(item.totalAttendHours + " hr") + " "),
                    ]),
                  ]
                },
              },
              {
                key: "expanded-item",
                fn: function ({ headers, item }) {
                  return [
                    _c(
                      "td",
                      {
                        staticClass: "px-0 py-2",
                        attrs: { colspan: headers.length },
                      },
                      [
                        _c("v-data-table", {
                          staticClass: "elevation-1",
                          attrs: {
                            "items-per-page": item.wotClockingReportDTOs.length,
                            headers: _vm.SubHeaders,
                            items: item.wotClockingReportDTOs,
                            "hide-default-footer": "",
                            colspan: 7,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item.serviceDate",
                                fn: function ({ item }) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          new Date(
                                            item.serviceDate
                                          ).toLocaleDateString()
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "item.totalPayableAmount",
                                fn: function ({ item }) {
                                  return [
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            "$ " + item.totalPayableAmount
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                              {
                                key: "item.billRate",
                                fn: function ({ item }) {
                                  return [
                                    _c("p", [
                                      _vm._v(
                                        " " + _vm._s("$ " + item.billRate) + " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                              {
                                key: "item.travel",
                                fn: function ({ item }) {
                                  return [
                                    _c("p", [
                                      _vm._v(
                                        " " + _vm._s("$ " + item.travel) + " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                              {
                                key: "item.totalBillRate",
                                fn: function ({ item }) {
                                  return [
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s("$ " + item.totalBillRate) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                              {
                                key: "item.cleaningSupplies",
                                fn: function ({ item }) {
                                  return [
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s("$ " + item.cleaningSupplies) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                              {
                                key: "item.hourlyRate",
                                fn: function ({ item }) {
                                  return [
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s("$ " + item.hourlyRate) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                              {
                                key: "item.totalAttendHours",
                                fn: function ({ item }) {
                                  return [
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.totalAttendHours + " hr"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("br"),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }