<template>
  <div>
    <div v-if="isOpenB" class="test">
      <v-icon @click="click" class="btnbar">X</v-icon>
      <br>
      <br>
      <div class="px-3 py-2">
        <v-row>
          <v-col cols="12" md="8" class="FinRepo-sty">
            <p class="table-title">Financial Report</p>
          </v-col>
        </v-row>
        <!-- List Custom Date -->

        <p class="Report-color">Service date :</p><br>
        <v-row>
          <v-col cols="12" md="12" style="margin-bottom:-14px ;" class="FinRepo-sty">
            <v-autocomplete class="" ref="clientSearch" multiple outlined label="Custom date" :items="clients"
              item-text="name" item-value="id" v-model="clientIds" @change="handleClientFilter">
            </v-autocomplete>
          </v-col>
        </v-row>
        <!-- From -->
        <v-row style="margin-bottom:-28px ;">
          <v-col cols="6" md="6" class="FinRepo-sty">
            <v-autocomplete class="" ref="DateFrom" prepend-inner-icon="mdi-calendar" multiple outlined label="From"
              :items="From" item-text="name" item-value="id" v-model="clientIds" @change="handleClientFilter">
            </v-autocomplete>
          </v-col>
          <!-- Date To -->
          <v-col cols="6" md="6" class="FinRepo-sty">
            <v-autocomplete class="" ref="DateTo" prepend-inner-icon="mdi-calendar" multiple outlined label="To"
              :items="To" item-text="name" item-value="id" v-model="clientIds" @change="handleClientFilter">
            </v-autocomplete>
          </v-col>
        </v-row>

        <hr />
        <br>
        <!-- ======= Payment Type ======= -->
        <v-row>
          <v-col cols="12" md="8" class="reportCols">
            <p class="Report-color" style="padding: 15px;">Payment Type:</p> <br>
          </v-col>

          <v-col cols="12" md="12" class="pad0">
            <v-row>
              <v-col cols="12" md="4" class="reportCols" style="margin-bottom:-16px ;">
                <v-checkbox class="reportCols" v-model="isLongTerm" label="w2" color="#4B5568"></v-checkbox>
                <v-checkbox class="reportCols" v-model="isLongTerm" label="1099" color="#4B5568"></v-checkbox>
                <v-checkbox class="reportCols" v-model="isLongTerm" label="vendor" color="#4B5568"></v-checkbox>
                <v-checkbox class="reportCols" v-model="isLongTerm" label="TEMP" color="#4B5568"></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <br>
        <!-- ============= Filter By technician  -->
        <hr />
        <br />
        <v-row>
          <v-col cols="12" md="8" class="">
            <p class="FinRepo-sty">Filter By technician</p>
          </v-col>
        </v-row>
        <!-- List TECH -->
        <v-col cols="12" md="12" class="FinRepo-sty">
          <v-row>
            <v-col cols="12" md="12">
              <v-autocomplete class="" style="margin-bottom:-29px ;" ref="clientSearch" multiple outlined label="TECH"
                :items="clients" item-text="name" item-value="id" v-model="clientIds" @change="handleClientFilter">
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
        <!-- Grouped by Account -->
        <hr />
        <br />
        <v-row style="padding-top: 3%;">
          <v-col cols="12" md="8" class="FinRepo-sty">
            <p class="">Grouped by Account</p>
          </v-col>
        </v-row>
        <v-col cols="12" md="12" class="FinRepo-sty">
          <v-row>
            <v-col cols="12" md="12">
              <v-autocomplete class="" ref="clientSearch" multiple outlined label="Select" :items="clients"
                item-text="name" item-value="id" v-model="clientIds" @change="handleClientFilter">
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </div>
    </div>

    <div id="fixed" class="test2">
      <v-icon v-if="!isOpenB" @click="click">fa-regular fa-bars</v-icon>
      <!--  -->
      <v-btn style="float: right;" to="/Reports/FinancialReports2" class="btn-creation wo-diff-style">Next Financial
        Report</v-btn>
      <span>
        <table class=" FinancialTab table table-hover">
          <thead>
            <tr style="height: 50px;text-align: left;">
              <th scope="col">Name</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="colscolor">Year to year report</td>
              <td >{{ Date().substring(0, 10) }} &nbsp; {{ currentDateTime() }}</td>
            </tr>
            <tr>
              <td class="colscolor">Financial Analysis Active report</td>
              <td >{{ Date().substring(0, 10) }} &nbsp; {{ currentDateTime() }}</td>
            </tr>
            <tr>
              <td class="colscolor">Waterfall</td>
              <td >{{ Date().substring(0, 10) }} &nbsp; {{ currentDateTime() }}</td>
            </tr>
          </tbody>
        </table>
      </span>


    </div>
  </div>
</template>




<script>
export default {
  data() {
    return {
      isOpenB: false,
    };
  },
  methods: {
    click() {
      this.isOpenB = !this.isOpenB;
      if (this.isOpenB) {
        const elem = document.getElementById("fixed");
        elem.style.width = "75%";
        elem.style.float = "right";
      } else {
        const elem = document.getElementById("fixed");
        elem.style.width = "100%";
        elem.style.float = "none";
      }
    },

    currentDateTime() {
      const current = new Date();
      const date = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
      const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
      const dateTime = date + ' ' + time;

      return dateTime;
    },
    // fulldate() {
    //   const current = new Date();
    //   return current;
    // },
  },
  computed:{ accountsearch: {
      get() {
        return this.accountTerm;
      },

      set(searchInput) {
        if (this.accountTerm !== searchInput) {
          this.accountTerm = searchInput;
          this.loadAccounts();
        }
      },
    },}
};
</script>