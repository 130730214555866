var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "v-overlay",
        { attrs: { value: _vm.loading } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "login-box" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                _c(
                  "div",
                  { staticClass: "st-box", attrs: { id: "login-feild" } },
                  [
                    _c("p", { staticClass: "login-text" }, [_vm._v("Login")]),
                    _c("v-text-field", {
                      class: {
                        "is-invalid": _vm.submitted && _vm.$v.userName.$error,
                      },
                      attrs: { solo: "", label: "Email" },
                      model: {
                        value: _vm.userName,
                        callback: function ($$v) {
                          _vm.userName = $$v
                        },
                        expression: "userName",
                      },
                    }),
                    _vm.submitted && !_vm.$v.userName.required
                      ? _c("p", { staticClass: "invalid-feedback mt-3" }, [
                          _vm._v(" email is Required "),
                        ])
                      : _vm._e(),
                    _vm.submitted && !_vm.$v.userName.email
                      ? _c("p", { staticClass: "invalid-feedback mt-3" }, [
                          _vm._v(" email is Invalid "),
                        ])
                      : _vm._e(),
                    _c("v-text-field", {
                      class: {
                        "is-invalid": _vm.submitted && _vm.$v.password.$error,
                      },
                      attrs: { type: "password", solo: "", label: "Password" },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handleSubmit.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.password,
                        callback: function ($$v) {
                          _vm.password = $$v
                        },
                        expression: "password",
                      },
                    }),
                    _vm.submitted && !_vm.$v.password.required
                      ? _c("p", { staticClass: "invalid-feedback mt-3" }, [
                          _vm._v(" Password is Required "),
                        ])
                      : _vm._e(),
                    _c("p", { staticClass: "fb-text" }, [
                      _vm._v("FORGET PASSWORD?"),
                    ]),
                    _c(
                      "v-btn",
                      {
                        staticClass: "signin-btn",
                        on: {
                          click: function ($event) {
                            return _vm.handleSubmit()
                          },
                        },
                      },
                      [_vm._v("SIGN IN")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("v-col", { staticClass: "rspnsv-hide", attrs: { md: "6" } }, [
                _c("img", {
                  staticClass: "nd-box",
                  attrs: { src: require("@/assets/images/work.png") },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        staticClass: "axa-logo",
        attrs: { src: require("@/assets/images/AXA LOGO TRANPARENT.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }