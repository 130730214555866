<template>
  <div>
    <v-row>
      <v-col md="10"></v-col>
      <v-col>
        <v-btn
          style="z-index: 1"
          class="float btn-creation wo-diff-style"
          @click="createInvoice"
        >
          Create Invoice</v-btn
        ></v-col
      >
    </v-row>

    <!-- ===================== -->
    <div class="mail-box">
      <div style="margin-left: -38px; margin-bottom: 1%"><b>Send To : </b></div>
      <div style="overflow-y: scroll; height: 74px">
        <v-chip
          style="margin: 2px"
          v-for="it in sendtoarr"
          :key="it.id"
          close
          @click="data.select"
          @click:close="RemoveContact(it.id)"
        >
          {{ it.firstName }}
        </v-chip>
      </div>
    </div>
    <v-row style="margin-bottom: 2%">
      <v-col md="12" style="margin-top: -2%">
        <v-autocomplete
          style="height: 46px"
          ref="ContactSearch"
          :items="Contacts"
          item-text="firstName"
          item-value="id"
          v-model="ContactsIds"
          multiple
          outlined
          placeholder="Send Email To"
          :search-input.sync="ContactSearch"
          @change="(event) => AddContact(event)"
        >
        
        </v-autocomplete></v-col
      >
    </v-row>

    <v-expansion-panels class="expansion-panels-custom mt-5">
      <v-expansion-panel v-for="item in data" :key="item.id">
        <v-expansion-panel-header class="v-card">
          <v-card class="collapse-box">
            <v-row>
              <v-col>
                <span style="color: #6276b8"
                  ><b>Master Work OrderId: </b>{{ item.orderId }}</span
                >
              </v-col>
              <v-col>
                <span><b>Client: </b>{{ item.clientName }}</span>, 
                <span><b>Account: </b>{{ item.accountName }}</span>, 
                <span><b>Location: </b>{{ item.location }}</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <span v-if="item.totalProfit < 0" style="color: #aa5e68">
                  <b>Profit: </b
                  ><b style="font-size: 17px; font-family: 'Montserrat'"
                    >$ {{ item.totalProfit }}</b
                  ></span
                >

                <span v-else style="color: green">
                  <b>Profit: </b
                  ><b style="font-size: 17px; font-family: 'Montserrat'"
                    >$ {{ item.totalProfit }}</b
                  ></span
                >
              </v-col>

              <v-col>
                <span style="color: #aa5e68"
                  ><b>Payment Type: </b>{{ item.paymentTypeName }}</span
                >,  
                <span><b>Bill Rate: </b>$ {{ item.billRate }}</span>,
                <span><b>Billable Hours: </b>{{ item.totalHours }}hr</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span><b>Billable Amount: </b>$ {{ item.totalBillRate }}</span
                >,

                <span><b>Expenses Amount: </b>$ {{ item.totalExpense }}</span
                >,

                <span
                  ><b>Payable Amount: </b>$
                  {{ item.totalTechniciansPayRates }}</span
                >
              </v-col>
              <v-col></v-col>
            </v-row>
          </v-card>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-for="item2 in item.timeSheets" :key="item2.id">
            <div class="sty-div-expense">
              <v-row>
                <v-col>
                  <span style="color: #6276b8"
                    ><b>Technician Name: </b>{{ item2.technicianName }}</span
                  >
                </v-col>
                <v-col></v-col>
              </v-row>

              <v-row>
                <v-col>
                  <span style="color: #7cab9a"
                    ><b>Payable Amount: </b>$ {{ item2.totalPayRate }}</span
                  >
                </v-col>
                <v-col>
                  <span style="color: #7cab9a"
                    ><b>Pay Rate: </b>$ {{ item2.payRate }}</span
                  >,
                  <span
                    ><b>Expenses Hours </b>$
                    {{ item2.totalExpenseHoures }}</span
                  >
                </v-col>
              </v-row>

              <span>
                <span
                  ><b>Billable Hours: </b>{{ item2.totalBillaleHours }} hr</span
                >,
                <span><b>TimeSheet Hours: </b>{{ item2.totalHours }} hr</span>,
                <span
                  ><b>Expenses Amount: </b>{{ item2.totalExpenses }} hr</span
                >
              </span>
            </div>
            <br />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { dataService } from "@/services/data-service";
export default {
  props: ["childSelected"],
  data: () => ({
    ClientId: 0,
    ContactsIds: [],
    Contacts: [],
    sendtoarr: [],
    paginationObj: { pageNumber: 1, pageSize: 10, subString: "", clientId: 0 },
    ContactTerm: "",
    data: {},
    Invoice: { timeSheetsIds: [] },
    timeSheetsIds: [],
  }),
  methods: {
    AddContact(items) {
      items.forEach((item) => {
      
        let hasvalue = this.sendtoarr.find((x) => x.id == item);
        if (hasvalue) {
          //console.log(hasvalue);
        } else {
          this.sendtoarr.push(this.Contacts.find((x) => x.id == item));
        }
      });
    },
    //===========
    RemoveContact(item) {
      const index = this.ContactsIds.indexOf(item);
      if (index >= 0) this.ContactsIds.splice(index, 1);
      const index2 = this.sendtoarr.indexOf(
        this.sendtoarr.find((x) => x.id == item)
      );
      if (index2 >= 0) this.sendtoarr.splice(index2, 1);
    },
    //==========================
    loadContacts() {
      this.paginationObj.subString = this.ContactTerm;

      this.paginationObj.clientId = this.ClientId;
      dataService.getList(
        "api/Contacts/get-approve-invoice-contacts-by-clientId",
        this.paginationObj,
        (response) => {
        
          this.Contacts = response.data.data.items;
        }
      );
    },
    createInvoice() {
      this.childSelected.forEach((element) =>
        this.Invoice.timeSheetsIds.push(element.id)
      );
      this.Invoice.contactsIds = this.ContactsIds;

      dataService.create("api/Invoices", this.Invoice, (response) => {
        if (response.data.succeeded == true)
          this.$router.push({
            name: "showInvoice",
            params: { id: response.data.data },
          });
      });
    },

    getData() {
      this.isLoaded = true;

      dataService.create(
        "api/Invoices/get-invoice-prepartion-time-sheet-detail",
        {
          timeSheetsIds: this.timeSheetsIds,
        },
        (response) => {
          this.data = response.data.data;
          this.ClientId = this.data[0].clientId;
          this.loadContacts();
        }
      );
    },
  },
  computed: {
    ContactSearch: {
      get() {
        return this.ContactTerm;
      },
      set(searchInput) {
        if (this.ContactTerm !== searchInput) {
          this.ContactTerm = searchInput;
          this.loadContacts();
        }
      },
    },
  },
  mounted() {
    this.childSelected.forEach((element) => {
      this.timeSheetsIds.push(element.id);
    });

    this.getData();
  },
};
</script>