var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.userInfo.userTypeId == 201 ||
    _vm.userInfo.userTypeId == 202 ||
    _vm.userInfo.userTypeId == 203 ||
    _vm.userInfo.userTypeId == 204 ||
    _vm.userInfo.userTypeId == 205
    ? _c(
        "div",
        { staticClass: "handle-test", attrs: { id: "work-order" } },
        [
          _c(
            "v-overlay",
            { attrs: { value: _vm.loading } },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "64" },
              }),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12", md: "8" } }, [
                _c("p", { staticClass: "table-title" }, [
                  _vm._v("Work Orders List"),
                ]),
              ]),
              _c(
                "v-col",
                { staticClass: "Gml-3", attrs: { md: "1", cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-creation wo-diff-style",
                      on: { click: _vm.exportData },
                    },
                    [
                      _c("img", {
                        staticStyle: { "padding-right": "10%" },
                        attrs: { src: require("@/assets/images/export.png") },
                      }),
                      _vm._v(" Export File "),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "Gml-3",
                  staticStyle: { "padding-left": "2%" },
                  attrs: { md: "2", cols: "12" },
                },
                [
                  _vm.userInfo.userTypeId == 201 ||
                  _vm.userInfo.userTypeId == 202 ||
                  _vm.userInfo.userTypeId == 203 ||
                  _vm.userInfo.userTypeId == 204
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "btn-creation wo-diff-style",
                          attrs: { to: "/create-work-order" },
                        },
                        [_vm._v("Create WorkOrder")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pad0", attrs: { cols: "12", md: "4" } },
                [
                  _c("v-text-field", {
                    ref: "objet",
                    attrs: {
                      solo: "",
                      label: "Search Work Order ",
                      "append-icon": "mdi-magnify",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.getWorkOrders(1)
                      },
                    },
                    model: {
                      value: _vm.pagination.subString,
                      callback: function ($$v) {
                        _vm.$set(_vm.pagination, "subString", $$v)
                      },
                      expression: "pagination.subString",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pad0", attrs: { cols: "12", md: "8" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-autocomplete", {
                            ref: "clientSearch",
                            attrs: {
                              multiple: "",
                              outlined: "",
                              label: "Select Clients",
                              items: _vm.clients,
                              "item-text": "name",
                              "item-value": "id",
                            },
                            on: { change: _vm.handleClientFilter },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function (data) {
                                    return [
                                      _c(
                                        "v-chip",
                                        _vm._b(
                                          {
                                            attrs: {
                                              "input-value": data.selected,
                                              close: "",
                                            },
                                            on: {
                                              click: data.select,
                                              "click:close": function ($event) {
                                                return _vm.RemoveClient(
                                                  data.item.id
                                                )
                                              },
                                            },
                                          },
                                          "v-chip",
                                          data.attrs,
                                          false
                                        ),
                                        [
                                          _vm._v(
                                            " " + _vm._s(data.item.name) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3635343991
                            ),
                            model: {
                              value: _vm.clientIds,
                              callback: function ($$v) {
                                _vm.clientIds = $$v
                              },
                              expression: "clientIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-autocomplete", {
                            ref: "accountSearch",
                            attrs: {
                              items: _vm.accounts,
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              outlined: "",
                              label: "Select Accounts",
                              "search-input": _vm.accountsearch,
                            },
                            on: {
                              "update:searchInput": function ($event) {
                                _vm.accountsearch = $event
                              },
                              "update:search-input": function ($event) {
                                _vm.accountsearch = $event
                              },
                              change: _vm.handleAccountFilter,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function (data) {
                                    return [
                                      _c(
                                        "v-chip",
                                        _vm._b(
                                          {
                                            attrs: {
                                              "input-value": data.selected,
                                              close: "",
                                            },
                                            on: {
                                              click: data.select,
                                              "click:close": function ($event) {
                                                return _vm.removeAccount(
                                                  data.item.id
                                                )
                                              },
                                            },
                                          },
                                          "v-chip",
                                          data.attrs,
                                          false
                                        ),
                                        [
                                          _vm._v(
                                            " " + _vm._s(data.item.name) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4026349103
                            ),
                            model: {
                              value: _vm.accountIds,
                              callback: function ($$v) {
                                _vm.accountIds = $$v
                              },
                              expression: "accountIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-autocomplete", {
                            ref: "locationSearch",
                            attrs: {
                              items: _vm.locations,
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              outlined: "",
                              label: "Select Locations",
                              "search-input": _vm.locationSearch,
                            },
                            on: {
                              change: _vm.handleLocationFilter,
                              "update:searchInput": function ($event) {
                                _vm.locationSearch = $event
                              },
                              "update:search-input": function ($event) {
                                _vm.locationSearch = $event
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function (data) {
                                    return [
                                      _c(
                                        "v-chip",
                                        _vm._b(
                                          {
                                            attrs: {
                                              "input-value": data.selected,
                                              close: "",
                                            },
                                            on: {
                                              click: data.select,
                                              "click:close": function ($event) {
                                                return _vm.removeLocatins(
                                                  data.item.id
                                                )
                                              },
                                            },
                                          },
                                          "v-chip",
                                          data.attrs,
                                          false
                                        ),
                                        [
                                          _vm._v(
                                            " " + _vm._s(data.item.name) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3732054191
                            ),
                            model: {
                              value: _vm.locationIds,
                              callback: function ($$v) {
                                _vm.locationIds = $$v
                              },
                              expression: "locationIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pad0", attrs: { cols: "12", md: "4" } },
                [
                  _vm.userInfo.userTypeId == 201 ||
                  _vm.userInfo.userTypeId == 202
                    ? _c("v-autocomplete", {
                        ref: "techsearch",
                        attrs: {
                          items: _vm.technicians,
                          "item-text": (item) =>
                            item.firstName +
                            " " +
                            item.lastName +
                            " " +
                            "-" +
                            " " +
                            item.email,
                          "item-value": "id",
                          label: "Select Technicians",
                          "search-input": _vm.techsearch,
                          outlined: "",
                          multiple: "",
                        },
                        on: {
                          "update:searchInput": function ($event) {
                            _vm.techsearch = $event
                          },
                          "update:search-input": function ($event) {
                            _vm.techsearch = $event
                          },
                          change: _vm.handleTechFilter,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "selection",
                              fn: function (data) {
                                return [
                                  _c(
                                    "v-chip",
                                    _vm._b(
                                      {
                                        attrs: {
                                          "input-value": data.selected,
                                          close: "",
                                        },
                                        on: {
                                          click: data.select,
                                          "click:close": function ($event) {
                                            return _vm.removeTechnician(
                                              data.item.id
                                            )
                                          },
                                        },
                                      },
                                      "v-chip",
                                      data.attrs,
                                      false
                                    ),
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            data.item.firstName +
                                              " " +
                                              data.item.lastName
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3477840324
                        ),
                        model: {
                          value: _vm.technicianIds,
                          callback: function ($$v) {
                            _vm.technicianIds = $$v
                          },
                          expression: "technicianIds",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pad0", attrs: { cols: "12", md: "8" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menu",
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.SDFrom,
                                transition: "scale-transition",
                                "offset-y": "",
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  _vm.SDFrom = $event
                                },
                                "update:return-value": function ($event) {
                                  _vm.SDFrom = $event
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  hint: "MM/DD/YYYY format",
                                                  label:
                                                    " Service Date / Start Date",
                                                  "prepend-inner-icon":
                                                    "mdi-calendar",
                                                  readonly: "",
                                                  outlined: "",
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    _vm.SDFrom = _vm.parseDate(
                                                      _vm.SDFrom
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.dateFormatted,
                                                  callback: function ($$v) {
                                                    _vm.dateFormatted = $$v
                                                  },
                                                  expression: "dateFormatted",
                                                },
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                513417211
                              ),
                              model: {
                                value: _vm.menuStartDate,
                                callback: function ($$v) {
                                  _vm.menuStartDate = $$v
                                },
                                expression: "menuStartDate",
                              },
                            },
                            [
                              _c(
                                "v-date-picker",
                                {
                                  attrs: { "no-title": "", scrollable: "" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$refs.menu.save(_vm.SDFrom)
                                    },
                                    change: _vm.getWOByDate,
                                  },
                                  model: {
                                    value: _vm.SDFrom,
                                    callback: function ($$v) {
                                      _vm.SDFrom = $$v
                                    },
                                    expression: "SDFrom",
                                  },
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.menuStartDate = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.menu.save(_vm.SDFrom)
                                        },
                                      },
                                    },
                                    [_vm._v(" OK ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menu2",
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.SDTo,
                                transition: "scale-transition",
                                "offset-y": "",
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  _vm.SDTo = $event
                                },
                                "update:return-value": function ($event) {
                                  _vm.SDTo = $event
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  hint: "MM/DD/YYYY format",
                                                  label:
                                                    "Service Date / End Date",
                                                  "prepend-inner-icon":
                                                    "mdi-calendar",
                                                  readonly: "",
                                                  outlined: "",
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    _vm.SDTo = _vm.parseDate(
                                                      _vm.SDTo
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.dateFormattedEnd,
                                                  callback: function ($$v) {
                                                    _vm.dateFormattedEnd = $$v
                                                  },
                                                  expression:
                                                    "dateFormattedEnd",
                                                },
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1312335707
                              ),
                              model: {
                                value: _vm.menuEndDate,
                                callback: function ($$v) {
                                  _vm.menuEndDate = $$v
                                },
                                expression: "menuEndDate",
                              },
                            },
                            [
                              _c(
                                "v-date-picker",
                                {
                                  attrs: { "no-title": "", scrollable: "" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$refs.menu2.save(_vm.SDTo)
                                    },
                                    change: _vm.getWOByDate,
                                  },
                                  model: {
                                    value: _vm.SDTo,
                                    callback: function ($$v) {
                                      _vm.SDTo = $$v
                                    },
                                    expression: "SDTo",
                                  },
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.menuEndDate = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.menu2.save(_vm.SDTo)
                                        },
                                      },
                                    },
                                    [_vm._v(" OK ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-autocomplete", {
                            ref: "pad0",
                            staticClass: "space-handling",
                            attrs: {
                              items: _vm.WoStatus,
                              multiple: "",
                              outlined: "",
                              label: "Select Status",
                              "item-text": "name",
                              "item-value": "id",
                            },
                            on: { change: _vm.handleStatusIds },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function (data) {
                                    return [
                                      _c(
                                        "v-chip",
                                        _vm._b(
                                          {
                                            attrs: {
                                              "input-value": data.selected,
                                              close: "",
                                            },
                                            on: {
                                              click: data.select,
                                              "click:close": function ($event) {
                                                return _vm.removeStatus(
                                                  data.item.id
                                                )
                                              },
                                            },
                                          },
                                          "v-chip",
                                          data.attrs,
                                          false
                                        ),
                                        [
                                          _vm._v(
                                            " " + _vm._s(data.item.name) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3822360026
                            ),
                            model: {
                              value: _vm.statusIds,
                              callback: function ($$v) {
                                _vm.statusIds = $$v
                              },
                              expression: "statusIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticStyle: { "margin-top": "29px" } },
            [
              _c(
                "v-col",
                { staticClass: "all-items", attrs: { cols: "3", md: "1" } },
                [
                  _c("p", { staticClass: "text-size" }, [_vm._v("Posted")]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "filter-btn posted-btn",
                      on: {
                        click: function ($event) {
                          return _vm.handleStatusFilter(1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.statusCounts.postedEntityCount))]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "all-items", attrs: { cols: "3", md: "1" } },
                [
                  _c("p", { staticClass: "text-size" }, [_vm._v("P1/4Hrs")]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "filter-btn fh-btn",
                      on: {
                        click: function ($event) {
                          return _vm.handleStatusFilter(3)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.statusCounts.within_4hEntityCount))]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "all-items", attrs: { cols: "3", md: "1" } },
                [
                  _c("p", { staticClass: "text-size" }, [_vm._v("Pending")]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "filter-btn pending-btn",
                      on: {
                        click: function ($event) {
                          return _vm.handleStatusFilter(5)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.statusCounts.pendingEntityCount))]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "all-items", attrs: { cols: "3", md: "1" } },
                [
                  _c("p", { staticClass: "text-size" }, [_vm._v("Assigned")]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "filter-btn assigned-btn",
                      on: {
                        click: function ($event) {
                          return _vm.handleStatusFilter(2)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.statusCounts.assignedEntityCount))]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "all-items", attrs: { cols: "3", md: "1" } },
                [
                  _c("p", { staticClass: "text-size" }, [_vm._v("All")]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "filter-btn all-btn",
                      on: {
                        click: function ($event) {
                          return _vm.handleStatusFilter(0)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.statusCounts.allEntityCount))]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "all-items", attrs: { cols: "3", md: "1" } },
                [
                  _c("p", { staticClass: "text-size" }, [_vm._v("Due Today")]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "filter-btn fh-btn2",
                      on: {
                        click: function ($event) {
                          return _vm.handleStatusFilter(6)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.statusCounts.dueToDayCount))]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "all-items", attrs: { cols: "3", md: "1" } },
                [
                  _c(
                    "p",
                    {
                      staticClass: "text-size",
                      staticStyle: { width: "max-content" },
                    },
                    [_vm._v("Due Tomorrow")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "filter-btn fh-btnFollowindDay",
                      on: {
                        click: function ($event) {
                          return _vm.handleStatusFilter(7)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.statusCounts.followingDayCount))]
                  ),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "9", md: "3" } }),
              _c(
                "v-col",
                { attrs: { cols: "3", md: "2" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-creation diff-style mt-1",
                      attrs: { color: "#D92020" },
                      on: { click: _vm.reset },
                    },
                    [_vm._v("Reset Filter")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _vm.userInfo.userTypeId == 201 || _vm.userInfo.userTypeId == 202
                ? _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      loading: _vm.isLoaded,
                      "loading-text": "Loading... Please wait",
                      headers: _vm.headers,
                      items: _vm.data,
                      "show-select": "",
                      "item-key": "id",
                      "item-class": _vm.itemRowBackground,
                      "hide-default-footer": "",
                      "items-per-page": _vm.pagination.pageSize,
                      "show-expand": "",
                      expanded: _vm.expanded,
                    },
                    on: {
                      "click:row": _vm.rowClick,
                      "update:options": _vm.handleOptionsUpdate,
                      "update:expanded": function ($event) {
                        _vm.expanded = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.id",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "", color: "#172450" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticStyle: {
                                                      color: "#172450",
                                                    },
                                                    attrs: { dark: "" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.changeTab(
                                                          item.id,
                                                          7
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v("fa-solid fa-file ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Documents")])]
                              ),
                              _vm._v("   "),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "", color: "#172450" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticStyle: {
                                                      color: "#172450",
                                                    },
                                                    attrs: { dark: "" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.changeTab(
                                                          item.id,
                                                          5
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v("fa-solid fa-calendar")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("WorkOrders")])]
                              ),
                              _vm._v("   "),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "", color: "#172450" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticStyle: {
                                                      color: "#172450",
                                                    },
                                                    attrs: { dark: "" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.changeTab(
                                                          item.id,
                                                          4
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v("fa-solid fa-id-badge")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Technicians")])]
                              ),
                              _vm._v("   "),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "", color: "#172450" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticStyle: {
                                                      color: "#172450",
                                                    },
                                                    attrs: { dark: "" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.changeTab(
                                                          item.id,
                                                          6
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v("fa-duotone fa-comment")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Notes")])]
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.serviceDateFromDate",
                          fn: function ({ headers, item }) {
                            return [
                              item.serviceDateFromDate?.length > 0
                                ? _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          new Date(
                                            item.serviceDateFromDate[0]?.serviceDateFromDate
                                          ).toLocaleDateString()
                                        ) +
                                        " " +
                                        _vm._s(
                                          item.serviceDateFromDate[0]?.fromTime
                                        ) +
                                        " "
                                    ),
                                    _c("span", { staticClass: "toDate" }, [
                                      _vm._v(" To"),
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          new Date(
                                            item.serviceDateFromDate[0]?.serviceDateToDate
                                          ).toLocaleDateString()
                                        ) +
                                        " " +
                                        _vm._s(
                                          item.serviceDateFromDate[0]?.toTime
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "expanded-item",
                          fn: function ({ headers, item }) {
                            return [
                              _c(
                                "td",
                                { attrs: { colspan: headers.length } },
                                [
                                  _c("strong", [_vm._v("Service date")]),
                                  _vm._l(
                                    item.serviceDateFromDate,
                                    function (date) {
                                      return _c(
                                        "p",
                                        { key: date.serviceDateFromDate },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                new Date(
                                                  date.serviceDateFromDate
                                                ).toLocaleDateString() +
                                                  " " +
                                                  date.fromTime
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "toDate" },
                                            [_vm._v(" To")]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                new Date(
                                                  date.serviceDateToDate
                                                ).toLocaleDateString() +
                                                  " " +
                                                  date.toTime
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          },
                        },
                        {
                          key: "footer",
                          fn: function () {
                            return [
                              _c(
                                "v-row",
                                { staticClass: "mt-5 fit-width" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { md: "2" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.sizeOptions,
                                          "item-value": "id",
                                          "item-text": "name",
                                          solo: "",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.getWorkOrders(1)
                                          },
                                        },
                                        model: {
                                          value: _vm.pagination.pageSize,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.pagination,
                                              "pageSize",
                                              $$v
                                            )
                                          },
                                          expression: "pagination.pageSize",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { md: "5" } },
                                    [
                                      _c("v-pagination", {
                                        attrs: {
                                          length: _vm.totalPages,
                                          "total-visible": "6",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.getWorkOrders(
                                              _vm.pagination.pageNumber
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.pagination.pageNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.pagination,
                                              "pageNumber",
                                              $$v
                                            )
                                          },
                                          expression: "pagination.pageNumber",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        _vm.selected.length != 0
                          ? {
                              key: "top",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "wo-actions" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "rspnsv-hide",
                                              attrs: { md: "1" },
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "select-key" },
                                                [_vm._v("Select")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "border-item",
                                              attrs: { md: "2" },
                                            },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: { "offset-y": "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      text: "",
                                                                      dark: "",
                                                                    },
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "header-item changed-color",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Change Status "
                                                                    ),
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fas fa-caret-down ml-1",
                                                                    }),
                                                                  ]
                                                                ),
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fas fa-caret-down ml-1",
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3285053332
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "v-list",
                                                    _vm._l(
                                                      _vm.Status,
                                                      function (item, index) {
                                                        return _c(
                                                          "v-list-item",
                                                          { key: index },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "dropdown-item",
                                                                attrs: {
                                                                  text: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.changeStatus(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.name
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                        {
                          key: "item.address",
                          fn: function ({ item }) {
                            return [
                              item.address
                                ? _c("p", [
                                    _c("span", [
                                      _vm._v(_vm._s(item.address.street)),
                                    ]),
                                    _vm._v(" - "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(item.address.buildingNumber)
                                      ),
                                    ]),
                                    item.address.city
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(item.address.city.name)
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" -"),
                                    item.address.state
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(item.address.state.target)
                                          ),
                                        ])
                                      : _vm._e(),
                                    item.address.country
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.address.country.name)
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "item.orderType",
                          fn: function ({ item }) {
                            return [
                              _c("p", [
                                _vm._v(" " + _vm._s(item.orderType.name) + " "),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "item.workOrderStatus",
                          fn: function ({ item }) {
                            return [
                              item.workOrderStatus
                                ? _c("p", [
                                    _vm._v(_vm._s(item.workOrderStatus.name)),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "item.createdOn",
                          fn: function ({ item }) {
                            return [
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm
                                        .convertUTCDateToLocalDate(
                                          new Date(item.createdOn)
                                        )
                                        .toLocaleString()
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  })
                : _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      loading: _vm.isLoaded,
                      "loading-text": "Loading... Please wait",
                      headers: _vm.clientHeader,
                      items: _vm.data,
                      "show-select": "",
                      "item-key": "id",
                      "item-class": _vm.itemRowBackground,
                      "hide-default-footer": "",
                      "items-per-page": _vm.pagination.pageSize,
                      "show-expand": "",
                      expanded: _vm.expanded,
                    },
                    on: {
                      "click:row": _vm.rowClick,
                      "update:options": _vm.handleOptionsUpdate,
                      "update:expanded": function ($event) {
                        _vm.expanded = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.id",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "", color: "#172450" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticStyle: {
                                                      color: "#172450",
                                                    },
                                                    attrs: { dark: "" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.changeTab(
                                                          item.id,
                                                          7
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v("fa-solid fa-file ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Documents")])]
                              ),
                              _vm._v("   "),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "", color: "#172450" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticStyle: {
                                                      color: "#172450",
                                                    },
                                                    attrs: { dark: "" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.changeTab(
                                                          item.id,
                                                          5
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v("fa-solid fa-calendar")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("WorkOrders")])]
                              ),
                              _vm._v("   "),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "", color: "#172450" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticStyle: {
                                                      color: "#172450",
                                                    },
                                                    attrs: { dark: "" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.changeTab(
                                                          item.id,
                                                          4
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v("fa-solid fa-id-badge")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Technicians")])]
                              ),
                              _vm._v("   "),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "", color: "#172450" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticStyle: {
                                                      color: "#172450",
                                                    },
                                                    attrs: { dark: "" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.changeTab(
                                                          item.id,
                                                          6
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v("fa-duotone fa-comment")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Notes")])]
                              ),
                            ]
                          },
                        },
                        {
                          key: "footer",
                          fn: function () {
                            return [
                              _c(
                                "v-row",
                                { staticClass: "mt-5 fit-width" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { md: "2" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.sizeOptions,
                                          "item-value": "id",
                                          "item-text": "name",
                                          solo: "",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.getWorkOrders(1)
                                          },
                                        },
                                        model: {
                                          value: _vm.pagination.pageSize,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.pagination,
                                              "pageSize",
                                              $$v
                                            )
                                          },
                                          expression: "pagination.pageSize",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { md: "5" } },
                                    [
                                      _c("v-pagination", {
                                        attrs: {
                                          length: _vm.totalPages,
                                          "total-visible": "6",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.getWorkOrders(
                                              _vm.pagination.pageNumber
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.pagination.pageNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.pagination,
                                              "pageNumber",
                                              $$v
                                            )
                                          },
                                          expression: "pagination.pageNumber",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        _vm.selected.length != 0 &&
                        _vm.userInfo.userTypeId != 205
                          ? {
                              key: "top",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "wo-actions" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "rspnsv-hide",
                                              attrs: { md: "1" },
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "select-key" },
                                                [_vm._v("Select")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "border-item",
                                              attrs: { md: "2" },
                                            },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: { "offset-y": "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      text: "",
                                                                      dark: "",
                                                                    },
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "header-item changed-color",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Change Status "
                                                                    ),
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fas fa-caret-down ml-1",
                                                                    }),
                                                                  ]
                                                                ),
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fas fa-caret-down ml-1",
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3285053332
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "v-list",
                                                    _vm._l(
                                                      _vm.Status,
                                                      function (item, index) {
                                                        return _c(
                                                          "v-list-item",
                                                          { key: index },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "dropdown-item",
                                                                attrs: {
                                                                  text: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.changeStatus(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.name
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                        {
                          key: "item.address",
                          fn: function ({ item }) {
                            return [
                              item.address
                                ? _c("p", [
                                    _c("span", [
                                      _vm._v(_vm._s(item.address.street)),
                                    ]),
                                    _vm._v(" - "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(item.address.buildingNumber)
                                      ),
                                    ]),
                                    item.address.city
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(item.address.city.name)
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" -"),
                                    item.address.state
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(item.address.state.target)
                                          ),
                                        ])
                                      : _vm._e(),
                                    item.address.country
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.address.country.name)
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "item.orderType",
                          fn: function ({ item }) {
                            return [
                              _c("p", [
                                _vm._v(" " + _vm._s(item.orderType.name) + " "),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "item.workOrderStatus",
                          fn: function ({ item }) {
                            return [
                              item.workOrderStatus
                                ? _c("p", [
                                    _vm._v(_vm._s(item.workOrderStatus.name)),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "item.createdOn",
                          fn: function ({ item }) {
                            return [
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm
                                        .convertUTCDateToLocalDate(
                                          new Date(item.createdOn)
                                        )
                                        .toLocaleString()
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }