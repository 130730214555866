var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "outer-page",
      staticStyle: { "padding-top": "0.5% !important" },
    },
    [
      _c(
        "div",
        { staticClass: "mb-7" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-btn",
                {
                  staticStyle: { "padding-top": "1%", "padding-left": "6%" },
                  attrs: { text: "", color: "primary" },
                  on: { click: _vm.goback },
                },
                [_vm._v(" Go Back ")]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "handle-timesheet",
              staticStyle: { "margin-bottom": "-1%" },
            },
            [
              _c("v-col", { attrs: { cols: "6", md: "9" } }, [
                _c("p", { staticClass: "table-title ml-10 mt-10" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.clockingData.TechName + " Clocking Details") +
                      " "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-left": "6%" } },
            [
              _c(
                "v-row",
                { staticClass: "handle-timesheet" },
                [
                  _c("v-col", { attrs: { cols: "4", md: "4" } }, [
                    _c("span", [
                      _c(
                        "span",
                        {
                          staticClass: "label-key",
                          staticStyle: { "margin-right": "2%" },
                        },
                        [_vm._v(" Master WorkOrderId : ")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "label-key",
                          staticStyle: { "margin-right": "2%" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.clockingData.MasterWorkOrderId) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("v-col", { attrs: { cols: "4", md: "4" } }),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "handle-timesheet" },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "padding-left": "2%" },
                      attrs: { cols: "4", md: "4" },
                    },
                    [
                      _c("span", [
                        _c(
                          "span",
                          {
                            staticClass: "label-key",
                            staticStyle: { "margin-right": "2%" },
                          },
                          [_vm._v(" Client : ")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "label-key",
                            staticStyle: { "margin-right": "2%" },
                          },
                          [_vm._v(" " + _vm._s(_vm.clockingData.Client) + " ")]
                        ),
                      ]),
                    ]
                  ),
                  _c("v-col", { attrs: { cols: "4", md: "4" } }, [
                    _c("span", [
                      _c(
                        "span",
                        {
                          staticClass: "label-key",
                          staticStyle: { "margin-right": "2%" },
                        },
                        [_vm._v(" Account : ")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "label-key",
                          staticStyle: { "margin-right": "2%" },
                        },
                        [_vm._v(" " + _vm._s(_vm.clockingData.Account) + " ")]
                      ),
                    ]),
                  ]),
                  _c("v-col", { attrs: { cols: "4", md: "4" } }, [
                    _c("span", [
                      _c(
                        "span",
                        {
                          staticClass: "label-key",
                          staticStyle: { "margin-right": "2%" },
                        },
                        [_vm._v(" Location : ")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "label-key",
                          staticStyle: { "margin-right": "2%" },
                        },
                        [_vm._v(" " + _vm._s(_vm.clockingData.Location) + " ")]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "handle-timesheet" },
                [
                  _c("v-col", { staticStyle: { "padding-left": "2%" } }, [
                    _c("span", [
                      _c(
                        "span",
                        {
                          staticClass: "label-key",
                          staticStyle: { "margin-right": "1%" },
                        },
                        [_vm._v(" Scope Of Work : ")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "label-key",
                          staticStyle: { "margin-right": "2%" },
                        },
                        [_vm._v(" hhh ")]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "mb-7" }, [
        _c(
          "div",
          { staticClass: "top-box" },
          [
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { md: "9" } }, [
                  _c("p", { staticClass: "clocking-date" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm
                            .convertUTCDateToLocalDate(new Date(_vm.item.date))
                            .toLocaleString()
                            .substr(0, 10)
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("v-col", { attrs: { md: "3" } }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "clocking-box" },
          [
            _c(
              "v-row",
              [
                _c("v-col", { staticClass: "resize", attrs: { md: "1" } }),
                _c(
                  "v-col",
                  { attrs: { md: "5" } },
                  [
                    _c("v-text-field", {
                      class: {
                        "is-invalid":
                          _vm.submitted && _vm.$v.item.scopeOfFinishWork.$error,
                      },
                      attrs: { outlined: "", label: "Closing Notes" },
                      model: {
                        value: _vm.item.scopeOfFinishWork,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "scopeOfFinishWork", $$v)
                        },
                        expression: "item.scopeOfFinishWork",
                      },
                    }),
                    _vm.submitted && !_vm.$v.item.scopeOfFinishWork.required
                      ? _c("p", { staticClass: "invalid-feedback mt-2" }, [
                          _vm._v(" Closing Notes Required "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { md: "1", id: "time-box" } },
                  [
                    _c("vue-timepicker", {
                      class: {
                        "is-invalid":
                          _vm.submitted && _vm.$v.item.startTime.$error,
                      },
                      attrs: { "manual-input": "", "hide-dropdown": "" },
                      on: { change: _vm.calcutateDiffTime },
                      model: {
                        value: _vm.item.startTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "startTime", $$v)
                        },
                        expression: "item.startTime",
                      },
                    }),
                    _vm.submitted && !_vm.$v.item.startTime.required
                      ? _c("p", { staticClass: "invalid-feedback" }, [
                          _vm._v(" startTime is Required "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { md: "1", id: "time-box" } },
                  [
                    _c("vue-timepicker", {
                      class: {
                        "is-invalid":
                          _vm.submitted && _vm.$v.item.endTime.$error,
                      },
                      attrs: { "manual-input": "", "hide-dropdown": "" },
                      on: { change: _vm.calcutateDiffTime },
                      model: {
                        value: _vm.item.endTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "endTime", $$v)
                        },
                        expression: "item.endTime",
                      },
                    }),
                    _vm.submitted && !_vm.$v.item.startTime.required
                      ? _c("p", { staticClass: "invalid-feedback" }, [
                          _vm._v(" endTime is Required "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { md: "1" } },
                  [
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          "close-on-content-click": false,
                          transition: "scale-transition",
                          "offset-y": "",
                          "min-width": "300",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "i",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass:
                                          "fas fa-calendar-alt day-icon",
                                      },
                                      "i",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c(
                          "v-date-picker",
                          {
                            attrs: { "no-title": "", scrollable: "" },
                            model: {
                              value: _vm.item.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "date", $$v)
                              },
                              expression: "item.date",
                            },
                          },
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "primary" },
                                on: {
                                  click: function ($event) {
                                    _vm.item.menu = false
                                  },
                                },
                              },
                              [_vm._v(" Cancel ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.submitted && !_vm.$v.item.date.required
                      ? _c("p", { staticClass: "invalid-feedback" }, [
                          _vm._v(" date is Required "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { md: "2", id: "time-box" } },
                  [
                    _c("vue-timepicker", {
                      attrs: {
                        disabled: "",
                        "manual-input": "",
                        "hide-dropdown": "",
                      },
                      model: {
                        value: _vm.item.total,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "total", $$v)
                        },
                        expression: "item.total",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { md: "1" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "ma-2",
                        staticStyle: {
                          width: "130px",
                          "justify-content": "flex-start",
                          padding: "0 10px",
                        },
                        attrs: { color: "primary", dark: "" },
                        on: {
                          click: function ($event) {
                            return _vm.AddClocking()
                          },
                        },
                      },
                      [
                        _vm._v(" Add Clocking "),
                        _c("v-icon", { attrs: { dark: "", right: "" } }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { md: "5" } }),
                _c("v-col", [
                  _vm.VaildationError2
                    ? _c("p", { staticClass: "invalid-feedback" }, [
                        _vm._v(" endTime should be greater than start time "),
                      ])
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._l(_vm.woClocking, function (clock) {
        return _c(
          "div",
          { key: clock.date, staticClass: "mb-7" },
          [
            _c(
              "div",
              { staticClass: "top-box" },
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { md: "9" } }, [
                      _c("p", { staticClass: "clocking-date" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm
                                .convertUTCDateToLocalDate(new Date(clock.date))
                                .toLocaleString()
                                .substr(0, 10)
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("v-col", { attrs: { md: "3" } }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._l(clock.items, function (item, index) {
              return _c(
                "div",
                { key: item.id, staticClass: "clocking-box" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "resize", attrs: { md: "1" } },
                        [
                          _c("v-checkbox", {
                            attrs: { color: "green" },
                            on: {
                              click: function ($event) {
                                return _vm.pushClock(item)
                              },
                            },
                            model: {
                              value: item.check,
                              callback: function ($$v) {
                                _vm.$set(item, "check", $$v)
                              },
                              expression: "item.check",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "5" } },
                        [
                          _c("v-text-field", {
                            staticClass: "description-feild",
                            attrs: { outlined: "", label: "Closing Notes" },
                            on: {
                              blur: function ($event) {
                                return _vm.UpdateClocking(item)
                              },
                            },
                            model: {
                              value: item.scopeOfFinishWork,
                              callback: function ($$v) {
                                _vm.$set(item, "scopeOfFinishWork", $$v)
                              },
                              expression: "item.scopeOfFinishWork",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "1", id: "time-box" } },
                        [
                          _c("vue-timepicker", {
                            attrs: { "manual-input": "", "hide-dropdown": "" },
                            on: {
                              blur: function ($event) {
                                return _vm.UpdateClocking(item)
                              },
                            },
                            model: {
                              value: item.startTime,
                              callback: function ($$v) {
                                _vm.$set(item, "startTime", $$v)
                              },
                              expression: "item.startTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "1", id: "time-box" } },
                        [
                          _c("vue-timepicker", {
                            attrs: { "manual-input": "", "hide-dropdown": "" },
                            on: {
                              blur: function ($event) {
                                return _vm.UpdateClocking(item)
                              },
                            },
                            model: {
                              value: item.endTime,
                              callback: function ($$v) {
                                _vm.$set(item, "endTime", $$v)
                              },
                              expression: "item.endTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "1" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: index,
                              refInFor: true,
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": clock.date,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "300",
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  return _vm.$set(clock, "date", $event)
                                },
                                "update:return-value": function ($event) {
                                  return _vm.$set(clock, "date", $event)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "i",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "fas fa-calendar-alt day-icon",
                                              },
                                              "i",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: item.menu,
                                callback: function ($$v) {
                                  _vm.$set(item, "menu", $$v)
                                },
                                expression: "item.menu",
                              },
                            },
                            [
                              _c(
                                "v-date-picker",
                                {
                                  attrs: { "no-title": "", scrollable: "" },
                                  on: {
                                    input: function ($event) {
                                      item.menu = false
                                    },
                                  },
                                  model: {
                                    value: clock.date,
                                    callback: function ($$v) {
                                      _vm.$set(clock, "date", $$v)
                                    },
                                    expression: "clock.date",
                                  },
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          item.menu = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "2", id: "time-box" } },
                        [
                          _c("vue-timepicker", {
                            attrs: {
                              disabled: "",
                              "manual-input": "",
                              "hide-dropdown": "",
                            },
                            model: {
                              value: item.total,
                              callback: function ($$v) {
                                _vm.$set(item, "total", $$v)
                              },
                              expression: "item.total",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "1" } },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "delete-timesheet",
                              attrs: { color: "#A23D3D" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteItem(item)
                                },
                              },
                            },
                            [_vm._v("mdi-delete")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
          ],
          2
        )
      }),
      _c(
        "div",
        { staticClass: "btn-container" },
        [
          _vm.timesheet.length > 0
            ? _c(
                "v-btn",
                {
                  staticClass: "create-order-btn float3",
                  attrs: { to: "/timesheet/" + JSON.stringify(_vm.timesheet) },
                },
                [_vm._v("Create Timesheet")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.dialogDelete,
            callback: function ($$v) {
              _vm.dialogDelete = $$v
            },
            expression: "dialogDelete",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v("Are you sure you want to delete this item?"),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.closeDelete },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.deleteItemConfirm },
                    },
                    [_vm._v("OK")]
                  ),
                  _c("v-spacer"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            padding: "10px 8px 60px 8px",
            "border-radius": "10px",
            margin: "12px",
          },
        },
        [
          _c(
            "h3",
            {
              staticClass: "header-styling",
              staticStyle: { "font-size": "25px", color: "#172450" },
            },
            [_vm._v(" Created Timesheets ")]
          ),
          _vm._l(_vm.timesheets, function (timesheet) {
            return _c(
              "div",
              { key: timesheet.id },
              [
                _c(
                  "div",
                  {
                    staticClass: "top-box",
                    staticStyle: {
                      "border-top-left-radius": "0",
                      "border-top-right-radius": "0",
                    },
                  },
                  [
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { md: "9" } }, [
                          _c("p", { staticClass: "clocking-date" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .convertUTCDateToLocalDate(
                                      new Date(timesheet.created)
                                    )
                                    .toLocaleString()
                                    .substr(0, 10)
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c(
                          "v-col",
                          {
                            staticStyle: { color: "#172450" },
                            attrs: { md: "3" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  timesheet.workorderTechnicianClockings.reduce(
                                    (total, obj) => total + obj.totalAttend,
                                    0
                                  ) / 60
                                ) +
                                " hr "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._l(timesheet.workorderTechnicianClockings, function (item) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "clocking-box" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { md: "3" } }, [
                            _c("p", { staticClass: "clocking-date" }, [
                              _vm._v(
                                " Closing Notes : " +
                                  _vm._s(item.scopeOfFinishWork) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("v-col", { attrs: { md: "3" } }, [
                            _c("p", { staticClass: "clocking-date" }, [
                              _vm._v(
                                " Clocking In : " +
                                  _vm._s(
                                    new Date(
                                      _vm
                                        .convertUTCDateToLocalDate(
                                          new Date(item.clockingInDate)
                                        )
                                        .toLocaleString()
                                    )
                                      .getHours()
                                      .toString()
                                      .padStart(2, "0") +
                                      ":" +
                                      new Date(
                                        _vm
                                          .convertUTCDateToLocalDate(
                                            new Date(item.clockingInDate)
                                          )
                                          .toLocaleString()
                                      )
                                        .getMinutes()
                                        .toString()
                                        .padStart(2, "0")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("v-col", { attrs: { md: "3" } }, [
                            _c("p", { staticClass: "clocking-date" }, [
                              _vm._v(
                                " Clocking Out : " +
                                  _vm._s(
                                    new Date(
                                      _vm
                                        .convertUTCDateToLocalDate(
                                          new Date(item.clockingOutDate)
                                        )
                                        .toLocaleString()
                                    )
                                      .getHours()
                                      .toString()
                                      .padStart(2, "0") +
                                      ":" +
                                      new Date(
                                        _vm
                                          .convertUTCDateToLocalDate(
                                            new Date(item.clockingOutDate)
                                          )
                                          .toLocaleString()
                                      )
                                        .getMinutes()
                                        .toString()
                                        .padStart(2, "0")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
              ],
              2
            )
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }