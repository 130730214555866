import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import navirouter from "@/router";

//auth
import Login2 from '../views/Auth/login.vue'
import Login from '../views/Auth/login2.vue'
import Activation from '../views/Auth/activation.vue'
import ResetPassword from '../views/Auth/resetPassword.vue'



import TwoFactorAuth from '../views/Auth/twoFactorAuth.vue'

//word order
import workOrderList from '../views/WorkOrder/work-order-list.vue'
import CretaeWorkOrder from '../views/WorkOrder/create.vue'
import ShowWorkOrder from '../views/WorkOrder/show.vue'
import Duplicate from '../views/WorkOrder/Duplicate.vue'


//companies
import companyList from '../views/Companies/list.vue'
import CreateCompany from '../views/Companies/create.vue'

//clients

import clientsList from '../views/Clients/list.vue'
import CreateClient from '../views/Clients/create.vue'

//technicians
import techniciansList from '../views/Technicians/list.vue'
import CreateTechnician from '../views/Technicians/create.vue'

//accounts
import CreateAccount from '../views/Accounts/create.vue'
import accountsList from '../views/Accounts/list.vue'

//locations
import locationsList from '../views/Locations/list.vue'
import createLocation from '../views/Locations/create.vue'


//contacts
import createContact from '../views/Contacts/create.vue'
import contactsList from '../views/Contacts/list.vue'




import Home from '../views/home.vue'

//settings
import Profile from '../views/Settings/profile.vue'
import EmailSettings from '../views/Settings/EmailTemplatesSettings.vue'
import EmailTemplatesSettingsEdit from '../views/Settings/EmailTemplatesSettingsEdit.vue'
import CreateEmailTemplatesSettings from '../views/Settings/CreateEmailTemplatesSettings.vue'

import Notifications from '../views/Settings/notifications.vue'
import Permissions from '../views/Settings/permissions.vue'
import Users from '../views/Settings/Users/users.vue'
import createUser from '../views/Settings/Users/createUser.vue'
import editUser from '../views/Settings/Users/edit.vue'
import SMTP from '../views/Settings/SMTP/smtp.vue'
import SMTPList from '../views/Settings/SMTP/list.vue'

import Security from '../views/Settings/security.vue'

//=========== Financila Reports 1 Me
import FinanicialReport from '../views/Reports/FinancialReports.vue'
//=========== Financila Reports 2 Me
import FinanicialReport2 from '../views/Reports/FinancialReports2.vue'
//=========== Payroll 2 Me
import PayrollReport from '../views/Reports/Payroll.vue'
//=========== Invoices Me
//=========== Invoices 1 Me
import Invoices from '../views/Reports/Invoices.vue'


//timesheet
import Clocking from '../views/Timesheet/clocking.vue'
import Timesheet from '../views/Timesheet/timesheet.vue'
import timesheetNeedToApproved from '../views/Timesheet/timesheetNeedToApproved.vue'
import TimeSheetDetails from '../views/Timesheet/TimeSheetDetails.vue'


//reports

import adminTimesheet from '../views/Invoices/TimeSheetlistToInvoice.vue'
import InvoiceDetails from '../views/Invoices/show.vue'
import showInvoice from '../views/Invoices/showDetails.vue'
import invoices from '../views/Invoices/list.vue'







Vue.use(VueRouter)

const routes = [
  {
    path: '/loginn',
    name: 'Login2',
    component: Login2,
    meta: {

      notRequiresAuth: true,
    },
  },
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {

      notRequiresAuth: true,
    },
  },
  {

    path: '/reset',
    name: 'Activation',
    component: Activation,
    props: (route) => ({ email: route.query.email }, { token: route.query.token }),
    meta: {

      notRequiresAuth: true,
    },
  },
  {

    path: '/password/reset',
    name: 'ResetPassword',
    component: ResetPassword,
    props: (route) => ({ email: route.query.email }, { token: route.query.token }),
    meta: {
      notRequiresAuth: true,
    },
  },
  // ========= Reports Me
  // ========= Financial Reports 1 Me
  {
    path: '/reports/FinancialReports',
    name: 'FinanicialReport',
    component: FinanicialReport,
    meta: {
      role: 'admin'
    }

  },
  // ========= Financial Reports 2 Me
  {
    path: '/reports/FinancialReports2',
    name: 'FinanicialReport2',
    component: FinanicialReport2,
    meta: {
      role: 'admin'
    }
  },
   // ========= Payroll Me
   {
    path: '/reports/Payroll',
    name: 'PayrollReport',
    component: PayrollReport,
    meta: {
      role: 'admin'
    }
  },
 //====== Invoices  Me
 //======== Invoice 1 Me
 {
  path : '/reports/Invoices' ,
  name : 'Invoices' ,
  component : Invoices ,
  meta : {
    role : 'admin'
  }
 },

  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {

      notRequiresAuth: true,
    },
  },

  {
    path: '/work-order-list',
    name: 'workOrderList',
    component: workOrderList,
    meta: {
      role: 'admin'
    }

  },
  {
    path: '/create-work-order',
    name: 'CretaeWorkOrder',
    component: CretaeWorkOrder,
    meta: {
      role: 'admin'
    }

  },
  {
    path: '/Duplicate/:id',
    name: 'Duplicate',
    component: Duplicate,
    meta: {
      role: 'admin'
    }

  },
  {
    path: '/show-work-order/:id',
    name: 'ShowWorkOrder',
    component: ShowWorkOrder,
    meta: {
      role: 'admin'
    }

  },
  {
    path: '/companies/list',
    name: 'companyList',
    component: companyList,
    meta: {
      role: 'admin'
    }

  },
  {
    path: '/company/create',
    name: 'CreateCompany',
    component: CreateCompany,
    meta: {
      role: 'admin'
    }

  },

  {
    path: '/location/create',
    name: 'createLocation',
    component: createLocation,
    meta: {
      role: 'admin'
    }

  },
  {
    path: '/locations/list',
    name: 'locationsList',
    component: locationsList,
    meta: {
      role: 'admin'
    }

  },
  {
    path: '/contacts/list',
    name: 'contactsList',
    component: contactsList,
    meta: {
      role: 'admin'
    }

  },
  {
    path: '/contact/create',
    name: 'createContact',
    component: createContact,
    meta: {
      role: 'admin'
    }

  },

  {
    path: '/clients/list',
    name: 'clientsList',
    component: clientsList,
    meta: {
      role: 'admin'
    }

  },
  {
    path: '/clients/create',
    name: 'CreateClient',
    component: CreateClient,
    meta: {
      role: 'admin'
    }

  },
  {
    path: '/technicians/list',
    name: 'techniciansList',
    component: techniciansList,
    meta: {
      role: 'admin'
    }
  },
  {
    path: '/technician/create',
    name: 'CreateTechnician',
    component: CreateTechnician,
    meta: {
      role: 'admin'
    }

  },
  {
    path: '/account/create',
    name: 'CreateAccount',
    component: CreateAccount,
    meta: {
      role: 'admin'
    }

  },
  {
    path: '/accounts/list',
    name: 'accountsList',
    component: accountsList,
    meta: {
      role: 'admin'
    }

  },
  {
    path: '/clocking/:woId',
    name: 'Clocking',
    component: Clocking,
    meta: {
      role: 'admin'
    }

  },
  {
    path: '/timesheet/:selectedClocks?',
    name: 'Timesheet',
    component: Timesheet,
    props: true,
    meta: {
      role: 'admin'
    }

  },
  {
    path: '/settings/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      role: 'admin'
    }

  },
  {
    path: '/settings/permissions',
    name: 'Permissions',
    component: Permissions,
    meta: {
      role: 'admin'
    }

  },
  {
    path: '/settings/EmailSettings',
    name: 'EmailSettings',
    component: EmailSettings,
    meta: {
      role: 'admin'
    }

  },
  {
    path: '/settings/CreateEmailTemplatesSettings',
    name: 'CreateEmailTemplatesSettings',
    component: CreateEmailTemplatesSettings,
    meta: {
      role: 'admin'
    }

  },
  {
    path: '/settings/EmailTemplatesSettingsEdit/:id',
    name: 'EmailTemplatesSettingsEdit',
    component: EmailTemplatesSettingsEdit,
    meta: {
      role: 'admin'
    }

  },
  
  {
    path: '/settings/notifications',
    name: 'Notifications',
    component: Notifications,
    meta: {
      role: 'admin'
    }

  },
  {
    path: '/settings/users',
    name: 'Users',
    component: Users,
    meta: {

      role: 'admin'
    },
  },
  {
    path: '/users/edit/:userId',
    name: 'editUser',
    component: editUser,
    meta: {

      role: 'admin'
    },
  },
  {
    path: '/settings/smtp',
    name: 'SMTP',
    component: SMTP,
    meta: {

      role: 'admin'
    },
  },
  {
    path: '/smtp/list',
    name: 'SMTPList',
    component: SMTPList,
    meta: {

      role: 'admin'
    },
  },
  {
    path: '/settings/security',
    name: 'Security',
    component: Security,
    meta: {

      role: 'admin'
    },
  },

  {
    path: '/verify',
    name: 'TwoFactorAuth',
    component: TwoFactorAuth,
    meta: {

      notRequiresAuth: true,
    },
  },
  {
    path: '/users/create',
    name: 'createUser',
    component: createUser,
    meta: {

      role: 'admin'
    },
  },
  // {
  //   path: '/reports/timesheets',
  //   path: '/reports/report1',
  //   name: 'reports',
  //   component: reports,
  //   meta: {

  //     role: 'admin'
  //   },
  // },
  {
    path: '/reports/ApprovedTimesheets',
    name: 'adminTimesheet',
    component: adminTimesheet,
    meta: {

      role: 'admin'
    },
  },

  {
    path: '/reports/TimeSheets',
    name: 'timesheetNeedToApproved',
    component: timesheetNeedToApproved,
    meta: {

      role: 'admin'
    },
  },
  {
    path: '/reports/TimeSheetDetails/:id',
    name: 'TimeSheetDetails',
    component: TimeSheetDetails,
    meta: {

      role: 'admin'
    },
  },

  {
    path: '/invoice/details/:id',
    name: 'InvoiceDetails',
    component: InvoiceDetails,
    meta: {

      role: 'admin'
    },
  },
  {
    path: '/invoice/list',
    name: 'invoices',
    component: invoices,
    meta: {

      role: 'admin'
    },
  },


  {
    path: '/invoice/ShowDetails/:id',
    name: 'showInvoice',
    component: showInvoice,
    meta: {

      role: 'admin'
    },
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {


  var role=localStorage.getItem("userType");
  // console.log(role,"role");

  if  (role==205){
  store.dispatch("AuthModule/logout");

    navirouter.push("/");
  }

  // store.dispatch("toggleShowPreloader");

  // AUTH GAURD

  if (to.meta.notRequiresAuth) {

    if ((store.getters["AuthModule/isLoggedIn"] && to.path == "/") || (store.getters["AuthModule/isLoggedIn"] && to.path == "/verify")) {

      next({ path: "/work-order-list" });

      return;
    }



    if (store.getters["AuthModule/isLoggedIn"] == false) {
      next();
      return;
    }
  } else {

    if (store.getters["AuthModule/isLoggedIn"]) {
      next();
      return;
    }
    next({ name: "Login" });
  }
  // next();
});







export default router
