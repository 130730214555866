<template>
  <div class="handle-test pb-10">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-tabs
      active-class="active-tab"
      background-color="#F8F8F8"
      :centered="true"
      v-model="tab"
      align-with-title
      class="wo-details"
    >
      <v-tabs-slider color="#4B5568"></v-tabs-slider>

      <v-tab class="single-tab" key="k1"> Technicians </v-tab>
      <v-tab @click="docsCount = 0" class="single-tab" key="k2">
        <v-badge
          v-if="docsCount > 0"
          :inline="true"
          small
          color="red"
          :content="docsCount"
          class="mr-2"
        >
        </v-badge>
        Documents
      </v-tab>
      <v-tab @click="notesCount = 0" class="single-tab" key="k3">
        <v-badge
          v-if="notesCount > 0"
          :inline="true"
          small
          color="red"
          :content="notesCount"
          class="mr-2"
        >
        </v-badge>
        Notes
      </v-tab>
      <v-tab class="single-tab" key="k4"> Status Log </v-tab>
      <v-tab class="single-tab" key="k5"> Actions log </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="k1">
        <v-card flat>
          <div
            class="tab1-card"
            v-if="userInfo.userTypeId == 201 || userInfo.userTypeId == 202"
          >
            <v-row class="pl-10">
              <v-col md="6">
                <v-row>
                  <v-col md="5">
                    <v-autocomplete
                      v-if="can(4)"
                      v-model="assignedTechs"
                      :items="technicians"
                      chips
                      outlined
                      color="blue-grey lighten-2"
                      label="Select a technician"
                      :item-text="
                        (item) => item.firstName + ' ' + item.lastName
                      "
                      :item-value="'id'"
                      multiple
                      :search-input.sync="search"
                      ref="techassign"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col md="5">
                    <!-- ======= Select Template ======-->
                    <v-autocomplete
                      chips
                      :items="template"
                      :item-text="'name'"
                      :item-value="'id'"
                      label="Select Template"
                      outlined
                      v-model="templateobj.templateId"
                      class="mb-3"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col md="2" style="padding-top: 4%">
                    <v-btn
                      v-if="
                        assignedTechs.length > 0 &&
                        templateobj.templateId != null
                      "
                      @click="assignTechs"
                      color="#4B5568"
                      class="assign-btn"
                    >
                      Assign</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div>
              <p class="table-title ml-10">Assigned Technicians</p>
              <div class="assigned-tech-table">
                <v-data-table :headers="techHeaders" :items="workOrderTech">
                  <template v-slot:item.firstName="{ item }">
                    <p>{{ item.firstName }} &nbsp; {{ item.lastName }}</p>
                  </template>
                  <template v-slot:item.hourlyRate="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.hourlyRate"
                      persistent
                      @save="save(props.item)"
                      @cancel="cancel"
                      @open="open"
                      @close="close"
                      large
                    >
                      <div>{{ props.item.hourlyRate }}</div>
                      <template v-slot:input>
                        <div class="mt-4 text-h6">Update Hourly Rate</div>
                        <v-text-field
                          v-model="props.item.hourlyRate"
                          label="hourly Rate"
                          single-line
                          counter
                          autofocus
                        >
                        </v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.technicianPhones="{ item }">
                    <p v-for="phone in item.technicianPhones" :key="phone.id">
                      {{ phone.phoneNumber }}
                    </p>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      class="ma-2"
                      @click="createTimeSheet(item)"
                      color="primary"
                      dark
                    >
                      Create TimeSheet

                      <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
                    </v-btn>
                    <v-btn
                      class="ma-2"
                      @click="removeThisTech(item)"
                      color="red"
                      dark
                    >
                      Remove
                      <v-icon dark right> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                </v-data-table>

                <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                  {{ snackText }}

                  <template v-slot:action="{ attrs }">
                    <v-btn v-bind="attrs" text @click="snack = false">
                      Close
                    </v-btn>
                  </template>
                </v-snackbar>
              </div>
            </div>
          </div>
          <div class="tab1-card" v-else>
            <v-row class="pl-10">
              <div style="width: 100%">
                <p class="table-title ml-10">Assigned Technicians</p>
                <div class="assigned-tech-table">
                  <v-data-table :headers="techHeaders2" :items="workOrderTech2">
                    <template v-slot:item.firstName="{ item }">
                      <p>{{ item.firstName }} &nbsp; {{ item.lastName }}</p>
                    </template>

                    <template v-slot:item.technicianPhones="{ item }">
                      <p v-for="phone in item.technicianPhones" :key="phone.id">
                        {{ phone.phoneNumber }}
                      </p>
                    </template>
                  </v-data-table>

                  <v-snackbar
                    v-model="snack"
                    :timeout="3000"
                    :color="snackColor"
                  >
                    {{ snackText }}

                    <template v-slot:action="{ attrs }">
                      <v-btn v-bind="attrs" text @click="snack = false">
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>
                </div>
              </div>
            </v-row>
          </div>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete = false"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-tab-item>
      <v-tab-item key="k2">
        <v-card flat>
          <div class="tab1-card">
            <v-row class="pl-10">
              <v-col md="6">
                <v-row>
                  <v-col md="7">
                    <input
                      id="FileInput"
                      @change="onUploadDoc($event)"
                      accept=".pdf, .doc, .docx, .txt,.gif, .jpg, .jpeg, .png"
                      class="file-feild"
                      type="file"
                    />
                  </v-col>
                  <v-col md="3">
                    <v-btn
                      v-if="fileToUpload != null"
                      color="#4B5568"
                      @click="uploadDoc"
                      class="assign-btn"
                      >Upload
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mb-5"></v-divider>
            <div class="tab2-details">
              <v-row>
                <v-col md="4">
                  <p class="tab2-item">Document</p>
                  <p v-for="doc in docs" :key="doc.guidFileName">
                    <a target="_blank" :href="doc.filePath">
                      {{ doc.fileName }}
                    </a>
                  </p>
                </v-col>
                <v-col md="3">
                  <p class="tab2-item">Uploaded on</p>
                  <p v-for="doc in docs" :key="doc.guidFileName">
                    {{ new Date(doc.created).toLocaleString() }}
                  </p>
                </v-col>
                <v-col md="3">
                  <p class="tab2-item">Uploaded by</p>
                  <p v-for="doc in docs" :key="doc.guidFileName">
                    {{ doc.createdBy }}
                  </p>
                </v-col>
                <v-col md="2">
                  <p class="tab2-item">Actions</p>
                  <p v-for="doc in docs" :key="doc.filePath">
                    <v-icon
                      class="delete-icon ml-4"
                      small
                      @click="removeThisDoc(doc)"
                    >
                      mdi-delete
                    </v-icon>
                  </p>
                </v-col>
              </v-row>
            </div>
            <v-dialog v-model="deleteDocDialog" max-width="600px">
              <v-card>
                <v-card-title class="text-h5"
                  >Are you sure you want to delete this Document?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteDocDialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="confirmDeleteDoc"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-card>
      </v-tab-item>

      <v-dialog v-model="contactDialog" max-width="700px">
        <v-card>
          <v-card-title class="text-h5">Contacts</v-card-title>

          <v-card-text>
            <v-autocomplete
              @change="updated"
              :items="onSiteContacts"
              :item-text="(item) => item.firstName"
              :item-value="'id'"
              v-model="workOrder.onSitePrimaryContact"
              label="On-Site Primary Contact"
              outlined
              class="mb-3"
            >
            </v-autocomplete>
            <v-autocomplete
              @change="updated"
              :items="onSiteContacts"
              :item-text="(item) => item.firstName"
              :item-value="'id'"
              v-model="workOrder.onSiteSecondaryContact"
              label="On-Site SecondaryContact"
              outlined
              class="mb-3"
            >
            </v-autocomplete>
            <v-autocomplete
              @change="updated"
              :items="techContacts"
              :item-text="(item) => item.firstName"
              :item-value="'id'"
              v-model="workOrder.technicalContact"
              label="Technical Contact"
              outlined
              class="mt-5 mb-3"
            >
            </v-autocomplete>
            <v-autocomplete
              @change="updated"
              :items="AccountManagerContacts"
              :item-text="(item) => item.firstName"
              :item-value="'id'"
              v-model="workOrder.accountManagerContact"
              label="Account Manager"
              outlined
              class="mt-5 mb-3"
            >
            </v-autocomplete>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="blue darken-1" text @click="contactDialog = false"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-tab-item key="k3">
        <v-card flat>
          <div class="tab1-card">
            <v-card-text>
              <!-- note list -->
              <!-- ========================= -->
              <div
                v-if="notes.length != 0"
                style="overflow: scroll; height: 340px; overflow-x: hidden"
              >
                <div
                  v-for="(note, index) in notes"
                  :key="index + 'N'"
                  class="upload-section note-box"
                >
                  <v-avatar
                    color="#172450"
                    style="height: 38px; min-width: 38px; width: 38px"
                  >
                    <span
                      v-if="note.createdBy != null"
                      class="white--text text-h5"
                      style="font-weight: 60px; font-size: 1rem !important"
                    >
                      {{ note.createdBy.charAt(0).toUpperCase() }}
                    </span>
                    <span
                      v-else
                      class="white--text text-h5"
                      style="font-weight: 60px; font-size: 1rem !important"
                    >
                      {{ note.createdBy }}
                    </span>
                  </v-avatar>

                  <span
                    v-if="note.createdBy != null"
                    class=""
                    style="
                      width: 100%;
                      margin-top: 0%;
                      margin-left: 1%;
                      font-size: 15px;
                      font-weight: 600;
                    "
                  >
                    {{ note.createdBy.toUpperCase() }}

                    <span
                      style="
                        margin-left: 2% !important;
                        font-size: 12px;
                        color: #8f8b8b;
                      "
                    >
                      {{
                        convertUTCDateToLocalDate(
                          new Date(note.created)
                        ).toLocaleString()
                      }}

                      <span>
                        <v-icon
                          style="
                            font-size: 13px;
                            margin-left: 1%;
                            margin-bottom: 1%;
                          "
                          @click="editThisNote(note)"
                        >
                          far fa-edit
                        </v-icon>
                      </span>
                    </span>

                    <p>
                      <span style="font-weight: normal">
                        {{ note.text }}
                      </span>
                      <v-icon
                        style="float: right; color: #f44336; margin-top: 1%"
                        @click="removeThisNote(note.id)"
                      >
                        mdi-delete
                      </v-icon>
                    </p>
                  </span>
                  <span
                    v-else
                    class=""
                    style="
                      width: 100%;
                      margin-top: 0%;
                      margin-left: 1%;
                      font-size: 15px;
                      font-weight: 600;
                    "
                  >
                    {{ note.createdBy }}

                    <span
                      style="
                        margin-left: 2% !important;
                        font-size: 12px;
                        color: #8f8b8b;
                      "
                    >
                      {{
                        convertUTCDateToLocalDate(
                          new Date(note.created)
                        ).toLocaleString()
                      }}

                      <span>
                        <v-icon
                          style="
                            font-size: 13px;
                            margin-left: 1%;
                            margin-bottom: 1%;
                          "
                          @click="editThisNote(note)"
                        >
                          far fa-edit
                        </v-icon>
                      </span>
                    </span>

                    <p>
                      <span style="font-weight: normal">
                        {{ note.text }}
                      </span>
                      <v-icon
                        style="float: right; color: #f44336; margin-top: 1%"
                        @click="removeThisNote(note.id)"
                      >
                        mdi-delete
                      </v-icon>
                    </p>
                  </span>
                </div>
              </div>

              <div v-else style="text-align: center; color: #837878">
                No Data Available
              </div>
              <!-- create New Note -->
              <!-- ====================== -->
              <div style="margin-top: 2%">
                <v-row>
                  <v-col cols="12" md="1">
                    <v-chip
                      class=""
                      style="margin-bottom: 15%"
                      color="red"
                      text-color="white"
                      v-if="note.workOrderNoteTypeId == 2"
                    >
                      Private
                    </v-chip>

                    <v-chip
                      class=""
                      style="margin-bottom: 15%"
                      color="green"
                      text-color="white"
                      v-if="note.workOrderNoteTypeId == 1"
                    >
                      Public
                    </v-chip>
                  </v-col>

                  <v-col md="4">
                    <v-select
                      v-if="note.workOrderNoteTypeId == 2"
                      label="Share with"
                      solo
                      :items="shareWithList"
                      :item-text="'name'"
                      :item-value="'id'"
                      v-model="note.noteAccess[0].typeId"
                    >
                    </v-select>
                  </v-col>
                  <v-col md="4">
                    <v-text-field
                      v-if="note.noteAccess[0].typeId == 3"
                      label="Type User Email"
                      solo
                      type="email"
                      v-model="note.noteAccess[0].typeValue"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <textarea
                  placeholder="Write Your Note"
                  style="
                    padding: 10px 10px 10px 2%;
                    border: 1px solid lightgray;
                    border-radius: 14px;
                  "
                  class="textarea-note-box"
                  v-model="note.text"
                ></textarea>
                <div style="height: 50px">
                  <v-icon
                    style="float: right; margin-top: 2%; margin-right: 2%"
                    @click="addNote"
                    >fa-solid fa-arrow-right
                  </v-icon>

                  <v-icon
                    style="margin-top: 2%; margin-right: 3%; margin-left: 2%"
                    @click="onchangetoprivate"
                    >fa-solid fa-unlock
                  </v-icon>
                  <p
                    style="
                      width: 5%;
                      text-align: center;
                      margin-top: 0%;
                      margin-left: 3px;
                    "
                  >
                    Public
                  </p>

                  <span> </span>

                  <v-icon
                    style="margin-top: -120px; margin-left: 128px"
                    @click="onchangetopublic"
                    >fa-solid fa-lock
                  </v-icon>
                  <p
                    style="
                      width: 5%;
                      text-align: center;
                      margin-top: -59px;
                      margin-left: 98px;
                    "
                  >
                    Private
                  </p>
                </div>
                <br />
              </div>
              <div class="btn-container"></div>
              <!-- ======================================= -->
            </v-card-text>
            <v-spacer></v-spacer>

            <!-- ================= note button -->

            <v-dialog v-model="editeNote" max-width="600px">
              <v-card>
                <v-card-title class="text-h5">Note Text</v-card-title>

                <v-card-text>
                  <v-textarea v-model="editedNoteObj.text" solo></v-textarea>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="EditeCancelNote"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="EditeConfirmedNote"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="deleteNoteDialog" max-width="600px">
              <v-card>
                <v-card-title class="text-h5"
                  >Are you sure you want to delete this note?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteNoteDialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="confirmDeleteNote"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item key="k4">
        <v-card flat>
          <div class="tab1-card">
            <div class="tab4-logs">
              <v-data-table
                :headers="headers"
                :items="statusLogs"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:item.created="{ item }">
                  <p>
                    {{
                      convertUTCDateToLocalDate(
                        new Date(item.created)
                      ).toLocaleString()
                    }}
                  </p>
                </template>
              </v-data-table>
            </div>
          </div>
        </v-card>
      </v-tab-item>

      <v-tab-item key="k5">
        <v-card flat>
          <div class="tab1-card">
            <v-row>
              <v-col cols="12" md="1" style="margin-left: 3%">
                <p style="margin-top: -1%; font-size: 13px; margin-bottom: 0%">
                  Documents
                </p>
                <v-col cols="3" md="1" style="margin-top: -11%">
                  <span v-if="actionTabValue == 7">
                    <v-icon
                      @click="changeTab(7)"
                      style="font-size: 40px; color: #172450"
                      >fa-solid fa-file</v-icon
                    >
                  </span>
                  <span v-else>
                    <v-icon @click="changeTab(7)" style="font-size: 40px"
                      >fa-solid fa-file</v-icon
                    >
                  </span>
                </v-col>
                <p style="margin-top: 25%; font-size: 13px; margin-bottom: 0%">
                  WorkOrders
                </p>
                <v-col cols="3" md="1" style="margin-top: -11%">
                  <span v-if="actionTabValue == 5">
                    <v-icon
                      @click="changeTab(5)"
                      style="font-size: 40px; color: #172450"
                      >fa-solid fa-calendar</v-icon
                    >
                  </span>
                  <span v-else>
                    <v-icon @click="changeTab(5)" style="font-size: 40px"
                      >fa-solid fa-calendar</v-icon
                    >
                  </span>
                </v-col>
                <p style="margin-top: 25%; font-size: 13px; margin-bottom: 0%">
                  Technicians
                </p>
                <v-col cols="3" md="1" style="margin-top: -11%">
                  <span v-if="actionTabValue == 4">
                    <v-icon
                      @click="changeTab(4)"
                      style="font-size: 40px; color: #172450"
                      >fa-solid fa-id-badge</v-icon
                    >
                  </span>
                  <span v-else>
                    <v-icon @click="changeTab(4)" style="font-size: 40px"
                      >fa-solid fa-id-badge</v-icon
                    >
                  </span>
                </v-col>
                <p
                  style="
                    margin-top: 25%;
                    margin-left: 20%;
                    font-size: 13px;
                    margin-bottom: 0%;
                  "
                >
                  Notes
                </p>
                <v-col cols="3" md="1" style="margin-top: -11%">
                  <span v-if="actionTabValue == 6">
                    <v-icon
                      @click="changeTab(6)"
                      style="font-size: 40px; color: #172450"
                      >fa-duotone fa-comment</v-icon
                    >
                  </span>
                  <span v-else>
                    <v-icon @click="changeTab(6)" style="font-size: 40px"
                      >fa-duotone fa-comment</v-icon
                    >
                  </span>
                </v-col>
              </v-col>
              <v-col md="10">
                <div
                  class="tab4-logs"
                  style="width: 98%"
                  v-if="actionTabValue == 4"
                >
                  <v-data-table
                    :headers="technicianlogHeaders"
                    :items="technicianlogData"
                  >
                    <template v-slot:item.auditDate="{ item }">
                      <p>
                        {{
                          convertUTCDateToLocalDate(
                            new Date(item.auditDate)
                          ).toLocaleString()
                        }}
                      </p>
                    </template>
                  </v-data-table>
                </div>
                <div
                  class="tab4-logs"
                  style="width: 98%"
                  v-if="actionTabValue == 5"
                >
                  <v-data-table
                    :headers="WorkorderlogHeaders"
                    :items="workOrderActionLogData"
                  >
                    <template v-slot:item.auditDate="{ item }">
                      <p>
                        {{
                          convertUTCDateToLocalDate(
                            new Date(item.auditDate)
                          ).toLocaleString()
                        }}
                      </p>
                    </template>

                    <template
                      v-slot:item.changedDataObj.billRate.expenseAmount="{
                        item,
                      }"
                    >
                      <p v-if="item.changedDataObj != null">
                        <span
                          v-if="
                            item.changedDataObj.billRate != null &&
                            item.oldDataObj.billRate.expenseAmount !=
                              item.changedDataObj.billRate.expenseAmount
                          "
                        >
                          <span style="text-decoration: line-through">
                            Old :{{
                              item.oldDataObj.billRate.expenseAmount
                            }}</span
                          >
                          <br />
                          <span>
                            New :{{
                              item.changedDataObj.billRate.expenseAmount
                            }}</span
                          >
                        </span>
                        <span v-else> No Change </span>
                      </p>
                      <p v-else>No Change</p>
                    </template>
                    <template v-slot:item.changedDataObj.orderId="{ item }">
                      <p v-if="item.changedDataObj != null">
                        <span v-if="item.changedDataObj.orderId != null">
                          <span style="text-decoration: line-through">
                            Old :{{ item.oldDataObj.orderId }}</span
                          >
                          <br />
                          <span> New :{{ item.changedDataObj.orderId }}</span>
                        </span>
                        <span v-else> No Change </span>
                      </p>
                      <p v-else>No Change</p>
                    </template>
                    <template v-slot:item.changedDataObj.orderType="{ item }">
                      <p v-if="item.changedDataObj != null">
                        <span v-if="item.changedDataObj.orderType != null">
                          <span style="text-decoration: line-through">
                            Old :{{ item.oldDataObj.orderType.name }}</span
                          >
                          <br />
                          <span>
                            New :{{ item.changedDataObj.orderType.name }}</span
                          >
                        </span>
                        <span v-else> No Change </span>
                      </p>
                      <p v-else>No Change</p>
                    </template>
                    <template
                      v-slot:item.changedDataObj.paymentMethod="{ item }"
                    >
                      <p v-if="item.changedDataObj != null">
                        <span v-if="item.changedDataObj.paymentMethod != null">
                          <span style="text-decoration: line-through">
                            Old :{{ item.oldDataObj.paymentMethod.name }}</span
                          >
                          <br />
                          <span>
                            New :{{
                              item.changedDataObj.paymentMethod.name
                            }}</span
                          >
                        </span>
                        <span v-else> No Change </span>
                      </p>
                      <p v-else>No Change</p>
                    </template>
                    <template v-slot:item.changedDataObj.problemType="{ item }">
                      <p v-if="item.changedDataObj != null">
                        <span v-if="item.changedDataObj.problemType != null">
                          <span style="text-decoration: line-through">
                            Old :{{ item.oldDataObj.problemType.name }}</span
                          >
                          <br />
                          <span>
                            New :{{
                              item.changedDataObj.problemType.name
                            }}</span
                          >
                        </span>
                        <span v-else> No Change </span>
                      </p>
                      <p v-else>No Change</p>
                    </template>
                    <template v-slot:item.changedDataObj.branchName="{ item }">
                      <p v-if="item.changedDataObj != null">
                        <span v-if="item.changedDataObj.branchName != null">
                          <span style="text-decoration: line-through">
                            Old :{{ item.oldDataObj.branchName }}</span
                          >
                          <br />
                          <span>
                            New :{{ item.changedDataObj.branchName }}</span
                          >
                        </span>
                        <span v-else> No Change </span>
                      </p>
                      <p v-else>No Change</p>
                    </template>
                    <template v-slot:item.changedDataObj.scopeOfWork="{ item }">
                      <p v-if="item.changedDataObj != null">
                        <span v-if="item.changedDataObj.scopeOfWork != null">
                          <span style="text-decoration: line-through">
                            Old :{{ item.oldDataObj.scopeOfWork }}</span
                          >
                          <br />
                          <span>
                            New :{{ item.changedDataObj.scopeOfWork }}</span
                          >
                        </span>
                        <span v-else> No Change </span>
                      </p>
                      <p v-else>No Change</p>
                    </template>
                    <template
                      v-slot:item.changedDataObj.workOrderTechnicianLevels="{
                        item,
                      }"
                    >
                      <p v-if="item.changedDataObj != null">
                        <span
                          v-if="
                            item.changedDataObj.workOrderTechnicianLevels !=
                              null &&
                            item.changedDataObj.workOrderTechnicianLevels
                              .length > 0
                          "
                        >
                          <span style="text-decoration: line-through">
                            Old Number Of Technician:{{
                              item.oldDataObj.workOrderTechnicianLevels[0]
                                .numberOfTechnician
                            }}</span
                          >
                          <br />
                          <span style="text-decoration: line-through">
                            Old Level:{{
                              item.oldDataObj.workOrderTechnicianLevels[0]
                                .technicianLevel
                            }}</span
                          >

                          <br />
                          <span>
                            New Number Of Technician:{{
                              item.changedDataObj.workOrderTechnicianLevels[0]
                                .numberOfTechnician
                            }}</span
                          >
                          <br />
                          <span>
                            New Level:{{
                              item.changedDataObj.workOrderTechnicianLevels[0]
                                .technicianLevel
                            }}</span
                          >

                          <br />
                        </span>
                        <span v-else> No Change </span>
                      </p>
                      <p v-else>No Change</p>
                    </template>

                    <template
                      v-slot:item.changedDataObj.serviceDateFromDate="{ item }"
                    >
                      <p v-if="item.changedDataObj != null">
                        <span
                          v-if="
                            item.changedDataObj.serviceDateFromDate != null &&
                            item.changedDataObj.serviceDateFromDate
                          "
                        >
                          <span
                            v-for="(dateService, index) in item.oldDataObj
                              .serviceDateFromDate"
                            :key="index"
                          >
                            <span style="text-decoration: line-through">
                              Old Date From:{{
                                convertUTCDateToLocalDate(
                                  new Date(dateService.serviceDateFromDate)
                                ).toLocaleString()
                              }}</span
                            >

                            <span style="text-decoration: line-through">
                              To:{{
                                convertUTCDateToLocalDate(
                                  new Date(dateService.serviceDateToDate)
                                ).toLocaleString()
                              }}</span
                            >

                            <br />
                          </span>
                          <span
                            v-for="(dateService, index) in item.changedDataObj
                              .serviceDateFromDate"
                            :key="index"
                          >
                            <span>
                              New Date From:{{
                                convertUTCDateToLocalDate(
                                  new Date(dateService.serviceDateFromDate)
                                ).toLocaleString()
                              }}</span
                            >

                            <span>
                              To:{{
                                convertUTCDateToLocalDate(
                                  new Date(dateService.serviceDateToDate)
                                ).toLocaleString()
                              }}</span
                            >

                            <br />
                          </span>
                        </span>
                        <span v-else> No Change </span>
                      </p>
                      <p v-else>No Change</p>
                    </template>
                  </v-data-table>
                </div>
                <div
                  class="tab4-logs"
                  style="width: 98%"
                  v-if="actionTabValue == 6"
                >
                  <v-data-table :headers="notelogHeaders" :items="NotesLogData">
                    <template v-slot:item.auditDate="{ item }">
                      <p>
                        {{
                          convertUTCDateToLocalDate(
                            new Date(item.auditDate)
                          ).toLocaleString()
                        }}
                      </p>
                    </template>
                  </v-data-table>
                </div>
                <div
                  class="tab4-logs"
                  style="width: 98%"
                  v-if="actionTabValue == 7"
                >
                  <v-data-table
                    :headers="documentHeaders"
                    :items="DocumentLogData"
                  >
                    <template v-slot:item.auditDate="{ item }">
                      <p>
                        {{
                          convertUTCDateToLocalDate(
                            new Date(item.auditDate)
                          ).toLocaleString()
                        }}
                      </p>
                    </template>

                    <template v-slot:item.oldDataObj="{ item }">
                      <a
                        v-if="item.oldDataObj != null"
                        target="_blank"
                        :href="item.oldDataObj.filePath"
                      >
                        {{ item.oldDataObj.fileName }}
                      </a>
                      <p v-else>-------------------------</p>
                    </template>

                    <template v-slot:item.newDataObj="{ item }">
                      <a
                        v-if="item.newDataObj != null"
                        target="_blank"
                        :href="item.newDataObj.filePath"
                      >
                        {{ item.newDataObj.fileName }}
                      </a>
                      <p v-else>-------------------------</p>
                    </template>
                  </v-data-table>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-tab-item>

      <!-- ============================ -->
    </v-tabs-items>

    <div class="mt-8 ml-16">
      <v-row>
        <v-col cols="12" md="6">
          <p>
            <span class="table-title">{{ workOrder.orderId }}</span>
          </p>
        </v-col>
        <v-col cols="12" md="4"></v-col>
        <v-col cols="12" md="2">
          <v-select
            class="mr-16"
            label="Actions"
            solo
            :items="woActions"
            :item-text="'name'"
            :item-value="'id'"
            v-model="actionId"
            @change="handleAction()"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-row  v-if="userInfo.userTypeId == 201 || userInfo.userTypeId == 202">
              <v-col md="6" cols="6">
                <p class="label-key">Field Nation ID</p>
              </v-col>
              <v-col md="6" cols="6">
               
                <p  v-if="!editMode" class="value-key colored">{{ workOrder.fieldNationId }}</p>
                <v-text-field v-else
                  class="mr-3"
                  v-model="
                    workOrder.fieldNationId
                  "
                  solo
                  @change="updated"
                >
                </v-text-field>

              </v-col>
            
            </v-row>
    </div>

    <div class="wo-boxes">
      <v-row>
        <v-col cols="12" md="6">
          <div class="wo-box">
            <v-row>
              <v-col md="10" cols="10"> </v-col>
              <v-col md="2" cols="2">
                <v-icon @click="changeEditModeSection1" small class="edit-wo">
                  far fa-edit
                </v-icon>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="5" cols="6">
                <p class="label-key">Company</p>
              </v-col>
              <v-col md="6" cols="6">
                <p v-if="workOrder.company" class="value-key colored">
                  {{ workOrder.company.name }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="5" cols="6">
                <p class="label-key">Client</p>
              </v-col>
              <v-col md="7" cols="6">
                <p v-if="workOrder.client" class="value-key colored">
                  {{ workOrder.client.name }}.
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="5" cols="6">
                <p class="label-key">Created by</p>
              </v-col>
              <v-col md="7" cols="6">
                <p class="value-key">
                  {{ workOrder.createdBy }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="5" cols="6">
                <p class="label-key">Created at</p>
              </v-col>
              <v-col md="7" cols="6">
                <p class="value-key">
                  {{
                    convertUTCDateToLocalDate(
                      new Date(workOrder.createdOn)
                    ).toLocaleString()
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="5" cols="8">
                <span class="label-key">Location</span>
              </v-col>
              <v-col v-if="editModeSection1" md="7" cols="12">
                <v-autocomplete
                  :items="branches"
                  :item-text="'name'"
                  :item-value="'id'"
                  label="Select Location"
                  outlined
                  class="mr-3"
                  @change="onchangebranch"
                  v-model="workOrder.branchId"
                  :search-input.sync="locationSearch"
                  ref="locationSearch"
                  :class="{
                    'is-invalid':
                      updateSubmitted && $v.workOrder.branchId.$error,
                  }"
                >
                </v-autocomplete>
                <p
                  v-if="updateSubmitted && !$v.workOrder.branchId.required"
                  class="invalid-feedback mt-2"
                >
                  Location is Required
                </p>
              </v-col>
              <v-col v-if="!editModeSection1" md="7" cols="4">
                <v-btn small @click="openGoogleMap(workOrder.address)">
                  <v-icon class="mr-1">mdi-map-marker</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="5" cols="0"></v-col>

              <v-col v-if="!editModeSection1" md="7" cols="12">
                <span v-if="workOrder.address" class="value-key">
                  {{ workOrder.address.buildingNumber }}
                  {{ workOrder.address.street }}-
                  <span v-if="workOrder.address.city"
                    >{{ workOrder.address.city.name }} -</span
                  >
                  <span v-if="workOrder.address.state"
                    >{{ workOrder.address.state.name }} -</span
                  >
                  <span v-if="workOrder.address.country">
                    {{ workOrder.address.country.name }}</span
                  >
                </span>
              </v-col>
            </v-row>
          </div>

          <div class="wo-box">
            <v-row>
              <v-col cols="12" md="10">
                <p class="label-key">Service date</p>
              </v-col>

              <v-col md="2">
                <v-icon @click="enableDateEditMode" small class="edit-wo">
                  far fa-edit
                </v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12"  style="padding: 4%;" >
                <div style="height: 116px; overflow-y: scroll">
                <div
                  class="value-key"
                  style="height: 116px; overflow-y: scroll"
                  v-for="date in dates"
                  :key="date.serviceDateFromDate"
                >
                <span>
                    <span class="toDate"> From &nbsp; </span>
                    <span v-if="workOrder.serviceDateTypeId != 5">
                      {{
                       new Date(date.serviceDateFromDate).toLocaleDateString()+" "+date.fromTime
                      }}
                      <span class="toDate"> To &nbsp; </span>
                      {{
                         new Date(date.serviceDateToDate).toLocaleDateString()+" "+date.toTime
                      
                      }}
                    </span>
                    <span v-else>
                      {{
                         new Date(date.serviceDateFromDate).toLocaleDateString()+" "+date.fromTime
                      }}
                      <span class="toDate">To</span>
                      {{
                      new Date(date.serviceDateToDate).toLocaleDateString()+" "+date.toTime
                      }}
                    </span></span
                  >

                  <br />
                  <br />
                </div>
              </div> 
              </v-col>
            </v-row>
            <br />

            <v-row>
              <v-col md="10" cols="10"> </v-col>
              <v-col md="2" cols="2">
                <v-icon
                  @click="editModePaymentMethod = !editModePaymentMethod"
                  small
                  class="edit-wo"
                >
                  far fa-edit
                </v-icon>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="5" cols="6">
                <p class="label-key">Payment Method</p>
              </v-col>
              <v-col md="7" cols="6" v-if="!editModePaymentMethod">
                <p v-if="workOrder.paymentMethod" class="value-key colored">
                  {{ workOrder.paymentMethod.name }}.
                </p>
              </v-col>
              <v-col md="7" cols="6" v-else>
                <v-combobox
                  v-model="workOrder.paymentMethod"
                  :items="paymentMethods"
                  :item-text="'name'"
                  :item-value="'id'"
                  label="Select Payment Method"
                  @change="updatePayment"
                  hide-selected
                  persistent-hint
                  outlined
                >
                </v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="5" cols="6">
                <p class="label-key">Purchase Order</p>
              </v-col>
              <v-col md="7" cols="6" v-if="!editModePaymentMethod">
                <p
                  v-if="workOrder.purchaseOrder.id != 0"
                  :class="[
                    { 'yellow-custom': workOrder.purchaseOrder.keyTypeId == 1 },
                    { 'red-custom': workOrder.purchaseOrder.keyTypeId == 2 },
                    { 'green-custom': workOrder.purchaseOrder.keyTypeId == 3 },
                    { 'blue-custom': workOrder.purchaseOrder.keyTypeId == 4 },
                  ]"
                >
                  {{
                    workOrder.purchaseOrder.poId +
                    "/ " +
                    "$ " +
                    workOrder.purchaseOrder.orderAmount
                  }}
                </p>
                <p class="value-key" v-else>-------</p>
              </v-col>
              <v-col
                md="7"
                cols="6"
                v-if="editModePaymentMethod && workOrder.paymentMethod.id == 3"
              >
                <v-select
                  :items="purchaseOrders"
                  :item-text="
                    (item) => item.poId + ' / ' + ' $  ' + item.orderAmount
                  "
                  :item-value="'id'"
                  outlined
                  label="Purchase order"
                  v-model="workOrder.purchaseOrder.id"
                  @change="updated"
                  class="mb-2"
                >
                  <template #item="{ item }">
                    <span
                      :class="[
                        { 'yellow-custom': item.keyTypeId == 1 },
                        { 'red-custom': item.keyTypeId == 2 },
                        { 'green-custom': item.keyTypeId == 3 },
                        { 'blue-custom': item.keyTypeId == 4 },
                      ]"
                    >
                      {{
                        item.poId + "" + " With amount " + "" + item.orderAmount
                      }}
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-dialog v-model="editDateDialog" width="900">
              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Service Date
                </v-card-title>

                <v-card-text>
                  <div class="nd-group">
                    <v-radio-group v-model="workOrder.serviceDateTypeId" row>
                      <v-radio
                        v-for="srvc in serviceDates"
                        :key="srvc.id"
                        color="#707070"
                        :label="srvc.name"
                        :value="srvc.id"
                        class="ml-7"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                  <!-- range -->
                  <div
                    v-if="
                      workOrder.serviceDateTypeId &&
                      workOrder.serviceDateTypeId == 3
                    "
                  >
                    <v-row>
                      <v-col cols="12" md="6">
                        <FunctionalCalendar
                          :configs="calendarConfigs1"
                          v-model="rangeDates"
                        ></FunctionalCalendar>
                      </v-col>
                      <v-col cols="12" md="3">
                        <vue-timepicker
                          ref="rangeFromm"
                          style="height: 3.2em"
                          v-model="timeRangeFrom"
                          manual-input
                          hide-dropdown
                          label="From Time"
                        >
                        </vue-timepicker>
                      </v-col>
                      <v-col md="3">
                        <vue-timepicker
                          ref="rangeToo"
                          v-model="timeRangeTo"
                          style="height: 3.2em"
                          manual-input
                          hide-dropdown
                          label="To Time"
                        >
                        </vue-timepicker>
                      </v-col>
                    </v-row>

                    <div>
                      <v-checkbox
                        v-model="isLongTerm"
                        label="Long Term"
                        color="#4B5568"
                      ></v-checkbox>
                    </div>
                  </div>
                  <!-- fixed dates -->
                  <div
                    v-if="
                      workOrder.serviceDateTypeId &&
                      workOrder.serviceDateTypeId == 2
                    "
                  >
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="date"
                          transition="scale-transition"
                          offset-y
                          min-width="300"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date"
                              label="Start Date"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              outlined
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            @input="$refs.menu.save(date)"
                            @click="menu = false"
                            v-model="date"
                            no-title
                            scrollable
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="3">
                        <vue-timepicker
                          style="height: 3.2em"
                          v-model="fixedFrom"
                          manual-input
                          hide-dropdown
                        >
                        </vue-timepicker>
                      </v-col>
                      <v-col cols="12" md="3">
                        <vue-timepicker
                          style="height: 3.2em"
                          v-model="fixedToo"
                          manual-input
                          hide-dropdown
                        >
                        </vue-timepicker>
                      </v-col>
                    </v-row>
                  </div>
                  <!-- repeating -->
                  <div
                    v-if="
                      workOrder.serviceDateTypeId &&
                      workOrder.serviceDateTypeId == 5
                    "
                  >
                    <v-row>
                      <v-col md="6">
                        <v-menu
                          ref="menu"
                          v-model="menuStartDate"
                          :close-on-content-click="false"
                          :return-value.sync="repetitionDetails.startDate"
                          transition="scale-transition"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="repetitionDetails.startDate"
                              label="Start Date"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              outlined
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="repetitionDetails.startDate"
                            no-title
                            scrollable
                            @input="
                              $refs.menu.save(repetitionDetails.startDate)
                            "
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="menuStartDate = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                $refs.menu.save(repetitionDetails.startDate)
                              "
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                        <v-select
                          :items="frequencies"
                          :item-text="'name'"
                          :item-value="'id'"
                          v-model="repetitionDetails.frequencyId"
                          outlined
                          label="Select Frequency"
                        ></v-select>
                        <div>
                          <v-date-picker
                            v-model="
                              repetitionDetails.repetitionSchedules[0].dates
                            "
                            no-title
                            multiple
                          >
                          </v-date-picker>
                        </div>
                      </v-col>
                      <v-col md="6">
                        <v-menu
                          ref="menuEndDate"
                          v-model="menuEndDate"
                          :close-on-content-click="false"
                          :return-value.sync="repetitionDetails.endDate"
                          transition="scale-transition"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="repetitionDetails.endDate"
                              label="End Date"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              outlined
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="repetitionDetails.endDate"
                            no-title
                            scrollable
                            @input="
                              $refs.menuEndDate.save(repetitionDetails.endDate)
                            "
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="menuEndDate = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                $refs.menuEndDate.save(
                                  repetitionDetails.endDate
                                )
                              "
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                        <v-row class="mt-15">
                          <v-col md="6">
                            <label class="time-label">From Time</label>
                            <vue-timepicker
                              class="time-picker"
                              v-model="fromTime"
                            ></vue-timepicker>
                          </v-col>
                          <v-col md="6">
                            <label class="time-label">To Time</label>
                            <vue-timepicker
                              class="time-picker"
                              v-model="toTime"
                            ></vue-timepicker>
                            <v-btn
                              color="primary"
                              text
                              @click="updateServiseDate"
                            >
                              update
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                  <div>
                    <v-btn color="primary" text @click="updateServiseDate">
                      update
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
        </v-col>

        <v-col cols="12" md="6">
          <div class="wo-box">
            <v-row>
              <v-col md="10" cols="10"> </v-col>
              <v-col md="2" cols="2">
                <v-icon @click="editMode = !editMode" small class="edit-wo">
                  far fa-edit
                </v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="6">
                <p class="label-key">Order ID</p>
              </v-col>
              <v-col md="6" cols="6">
                <p class="value-key colored">{{ workOrder.orderId }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="6">
                <p class="label-key">Order Type</p>
              </v-col>
              <v-col md="6" cols="6" v-if="!editMode">
                <p class="value-key colored">{{ workOrder.orderType.name }}</p>
              </v-col>
              <v-col md="6" cols="6" v-if="editMode">
                <v-combobox
                  v-model="workOrder.orderType"
                  :items="orderTypes"
                  :item-text="'name'"
                  :item-value="'id'"
                  label="Select Order Type"
                  @change="updated"
                  hide-selected
                  persistent-hint
                  outlined
                  :class="{
                    'is-invalid':
                      updateSubmitted && $v.workOrder.orderType.id.$error,
                  }"
                >
                </v-combobox>
                <p
                  v-if="updateSubmitted && !$v.workOrder.orderType.id.required"
                  class="invalid-feedback mt-2"
                >
                  Order type is Required
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="6">
                <p class="label-key">Problem Category</p>
              </v-col>
              <v-col md="6" cols="6" v-if="!editMode">
                <p class="value-key" v-if="workOrder.problemType">
                  {{ workOrder.problemType.name }}
                </p>
              </v-col>
              <v-col md="6" cols="6" v-if="editMode">
                <v-autocomplete
                  class="mr-3"
                  :items="problemTypes"
                  :item-text="'name'"
                  :item-value="'id'"
                  label="Problem Category"
                  outlined
                  @change="updated"
                  v-model="workOrder.problemType.id"
                  :class="{
                    'is-invalid':
                      updateSubmitted && $v.workOrder.problemType.id.$error,
                  }"
                >
                </v-autocomplete>
                <p
                  v-if="
                    updateSubmitted && !$v.workOrder.problemType.id.required
                  "
                  class="invalid-feedback mt-2"
                >
                  Problem Category is Required
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="6">
                <p class="label-key">Technician Level</p>
              </v-col>
              <v-col md="6" cols="6" v-if="!editMode">
                <p v-if="workOrder.workOrderTechnicianLevels" class="value-key">
                  {{ workOrder.workOrderTechnicianLevels[0].technicianLevel }}
                </p>
              </v-col>
              <v-col md="6" cols="6" v-if="editMode">
                <v-autocomplete
                  :items="levels"
                  :item-text="'name'"
                  :item-value="'id'"
                  label="Technician Level"
                  outlined
                  class="mr-3"
                  v-model="
                    workOrder.workOrderTechnicianLevels[0].technicianLevelId
                  "
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="6">
                <p class="label-key">No. of Technicians</p>
              </v-col>
              <v-col md="6" cols="6" v-if="!editMode">
                <p v-if="workOrder.workOrderTechnicianLevels" class="value-key">
                  {{
                    workOrder.workOrderTechnicianLevels[0].numberOfTechnician
                  }}
                </p>
              </v-col>
              <v-col md="6" cols="6" v-if="editMode">
                <v-text-field
                  class="mr-3"
                  v-model="
                    workOrder.workOrderTechnicianLevels[0].numberOfTechnician
                  "
                  solo
                  @change="updated"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </div>

          <div class="wo-box">
            <v-row>
              <v-col md="10" cols="10"></v-col>

              <v-col md="2" cols="2">
                <v-icon @click="changePaymentFlage" small class="edit-wo">
                  far fa-edit
                </v-icon>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="6" cols="6">
                <p class="label-key">Payment_Type</p>
              </v-col>
              <v-col md="6" cols="6" v-if="!PaymentSectionFlag">
                <p
                  v-if="workOrder.paymentType"
                  :class="[
                    ,
                    { 'red-custom2': workOrder.paymentType.id == 1 },
                    { 'green-custom2': workOrder.paymentType.id == 2 },
                  ]"
                >
                  {{ workOrder.paymentType.name }}.
                </p>
              </v-col>

              <v-col md="6" cols="6" v-if="PaymentSectionFlag">
                <v-combobox
                  v-model="workOrder.paymentType"
                  :items="paymentTypes"
                  :item-text="'name'"
                  :item-value="'id'"
                  label="Select Payment Type"
                  @change="updatePayment"
                  hide-selected
                  persistent-hint
                  outlined
                  :class="{
                    'is-invalid':
                      updateSubmitted && $v.workOrder.paymentType.id.$error,
                  }"
                >
                </v-combobox>
                <p
                  v-if="
                    updateSubmitted && !$v.workOrder.paymentType.id.required
                  "
                  class="invalid-feedback mt-2"
                >
                  payment Type is Required
                </p>
              </v-col>
            </v-row>

            <v-row v-for="p in workOrder.workOrderExpenses" :key="p.id + 'P'">
              <v-col md="6" cols="6" v-if="p.workOrderExpenseType == 'Payment'">
                Bill Rate
              </v-col>
              <v-col v-else md="6" cols="6">
                {{ p.workOrderExpenseType }}
              </v-col>

              <v-col
                v-if="PaymentSectionFlag && p.workOrderExpenseType == 'Payment'"
                md="6"
                cols="6"
              >
                <v-text-field
                  solo
                  class="mr-3"
                  v-model="p.expenseAmount"
                  @change="updated"
                ></v-text-field>
              </v-col>
              <v-col
                v-if="PaymentSectionFlag && p.workOrderExpenseType != 'Payment'"
                md="6"
                cols="6"
              >
                <v-row>
                  <v-col cols="6" md="6">
                    <v-select
                      class="mr-0"
                      solo
                      :item-text="'name'"
                      :item-value="'id'"
                      :items="amountTypes"
                      label="$"
                      v-model="p.amountTypeId"
                      @change="updated"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field
                      solo
                      class="mr-3 ml-0"
                      v-model="p.expenseAmount"
                      @change="updated"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col v-if="!PaymentSectionFlag" md="6" cols="6">
                <span v-if="p.amountTypeId == 2"
                  >{{ p.amountType }}{{ p.expenseAmount }}</span
                >
                <span v-else>{{ p.expenseAmount }}{{ p.amountType }}</span>
              </v-col>
            </v-row>
            <v-row v-if="PaymentSectionFlag">
              <v-col md="10"></v-col>
              <v-col md="2">
                <v-icon @click="ShowExpenseDialog" small>
                  fa-solid fa-plus
                </v-icon>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="scope-box">
      <v-row>
        <v-col md="11" sm="11" cols="9">
          <p class="label-key">Scope of work :</p>
        </v-col>
        <v-col md="1" sm="1" cols="2">
          <v-icon @click="changeWorkOrderScope" small class="edit-wo">
            far fa-edit
          </v-icon>
        </v-col>
      </v-row>

      <p v-show="!flag1" class="value-key scop-clr">
        {{ workOrder.scopeOfWork }}
      </p>

      <v-col v-show="flag1">
        <v-textarea
          v-model="workOrder.scopeOfWork"
          outlined
          label="Scope Of Work"
          @change="updated"
          class="mb-3"
        >
        </v-textarea>
      </v-col>

      <v-row>
        <v-col md="11" sm="11" cols="9">
          <p class="table-title">Contact Information:</p>
        </v-col>
        <v-col md="1" sm="1" cols="2">
          <v-icon @click="ShowDialog" small class="edit-wo">
            far fa-edit
          </v-icon>
        </v-col>
      </v-row>

      <v-tabs class="mb-5" v-model="contactTab">
        <v-tab> On-Site Contacts </v-tab>
        <v-tab> Technical Contacts </v-tab>
        <v-tab> Account Manager Contacts </v-tab>
      </v-tabs>
      <v-tabs-items v-model="contactTab">
        <v-tab-item>
          <div
            v-for="(item, index) in workOrderContacts"
            :key="item.id + index"
          >
            <div v-if="item && item.contactType.name == 'On_Site'">
              <v-row>
                <v-col md="6">
                  <p>
                    <span class="contact-key"> Name:</span>
                    <span class="contact-value">
                      {{ item.firstName }} &nbsp; {{ item.lastName }}
                    </span>
                  </p>
                  <p>
                    <span class="contact-key">Email Address:</span>
                    <span class="contact-value"> {{ item.email }} </span>
                  </p>
                  <p>
                    <span class="contact-key"> Address:</span>
                    <span v-if="item.address" class="contact-value">
                      {{ item.address.buildingNumber }} -
                      {{ item.address.street }}-
                      <span v-if="item.address.city"
                        >{{ item.address.city.name }} -</span
                      >
                      <span v-if="item.address.state"
                        >{{ item.address.state.name }} -</span
                      >
                      <span v-if="item.address.country">
                        {{ item.address.country.name }}</span
                      >
                    </span>
                  </p>
                </v-col>
                <v-col md="6">
                  <p>
                    <span class="contact-key">Phone Number:</span>
                    <span class="contact-value">
                      {{ item.contactPhones[0].phoneNumber }}
                    </span>
                  </p>
                  <p>
                    <span class="contact-key">Branch:</span>
                    <span v-if="item.branch" class="contact-value">
                      {{ item.branch.name }}
                    </span>
                  </p>
                </v-col>
              </v-row>

              <v-divider class="mb-2"></v-divider>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div
            v-for="(item, index) in workOrderContacts"
            :key="item.id + index"
          >
            <div v-if="item && item.contactType.name == 'Technical'">
              <v-row>
                <v-col md="6">
                  <p>
                    <span class="contact-key"> Name:</span>
                    <span class="contact-value">
                      {{ item.firstName }} &nbsp; {{ item.lastName }}
                    </span>
                  </p>
                  <p>
                    <span class="contact-key">Email Address:</span>
                    <span class="contact-value"> {{ item.email }} </span>
                  </p>
                  <p>
                    <span class="contact-key"> Address:</span>
                    <span v-if="item.address" class="contact-value">
                      {{ item.address.buildingNumber }} -
                      {{ item.address.street }}-
                      <span v-if="item.address.city"
                        >{{ item.address.city.name }} -</span
                      >
                      <span v-if="item.address.state"
                        >{{ item.address.state.name }} -</span
                      >
                      <span v-if="item.address.country">
                        {{ item.address.country.name }}</span
                      >
                    </span>
                  </p>
                </v-col>
                <v-col md="6">
                  <p>
                    <span class="contact-key">Phone Number:</span>
                    <span class="contact-value">
                      {{ item.contactPhones[0].phoneNumber }}
                    </span>
                  </p>
                  <p>
                    <span class="contact-key">Branch:</span>
                    <span class="contact-value">
                      {{ item.branch.name }}
                    </span>
                  </p>
                </v-col>
              </v-row>

              <v-divider class="mb-2"></v-divider>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div
            v-for="(item, index) in workOrderContacts"
            :key="item.id + index"
          >
            <div v-if="item && item.contactType.name == 'Account_Manager'">
              <v-row>
                <v-col md="6">
                  <p>
                    <span class="contact-key"> Name:</span>
                    <span class="contact-value">
                      {{ item.firstName }} &nbsp; {{ item.lastName }}
                    </span>
                  </p>
                  <p>
                    <span class="contact-key">Email Address:</span>
                    <span class="contact-value"> {{ item.email }} </span>
                  </p>
                  <p>
                    <span class="contact-key"> Address:</span>
                    <span v-if="item.address" class="contact-value">
                      {{ item.address.buildingNumber }} -
                      {{ item.address.street }}-
                      <span v-if="item.address.city"
                        >{{ item.address.city.name }} -</span
                      >
                      <span v-if="item.address.state"
                        >{{ item.address.state.name }} -</span
                      >
                      <span v-if="item.address.country">
                        {{ item.address.country.name }}</span
                      >
                    </span>
                  </p>
                </v-col>
                <v-col md="6">
                  <p>
                    <span class="contact-key">Phone Number:</span>
                    <span class="contact-value">
                      {{ item.contactPhones[0].phoneNumber }}
                    </span>
                  </p>
                  <p>
                    <span class="contact-key">Branch:</span>
                    <span class="contact-value">
                      {{ item.branch.name }}
                    </span>
                  </p>
                </v-col>
              </v-row>

              <v-divider class="mb-2"></v-divider>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <v-btn
      v-if="isUpdated"
      class="float btn-creation wo-diff-style"
      @click="updateWorkOrder"
      >Save Changes</v-btn
    >
    <v-btn
      v-if="isUpdated"
      class="float2 btn-creation wo-diff-style"
      @click="refreshPage"
      color="red"
      >Discard Changes
    </v-btn>

    <v-dialog v-model="expenseDialoge" max-width="700px">
      <v-card>
        <v-card-title class="text-h5">New Expense</v-card-title>

        <v-card-text>
          <br />
          <v-select
            :items="expensesTypes"
            :item-text="'name'"
            :item-value="'id'"
            label="Add Fees,Discount,Tax, ... "
            solo
            v-model="feild.workOrderExpenseTypeId"
            :class="{
              'is-invalid': submitted && $v.feild.workOrderExpenseTypeId.$error,
            }"
          ></v-select>
          <p
            v-if="submitted && !$v.feild.workOrderExpenseTypeId.required"
            class="invalid-feedback mt-2"
          >
            ExpenseType is required
          </p>

          <br />
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                placeholder="Description"
                v-model="feild.description"
                :label="feild.name"
                :class="{
                  'is-invalid': submitted && $v.feild.description.$error,
                }"
              ></v-text-field>
              <p
                v-if="submitted && !$v.feild.description.required"
                class="invalid-feedback mt-2"
              >
                description is required
              </p>
            </v-col>
            <v-col md="3" cols="4" class="handle-space">
              <v-select
                class="payment-resize"
                outlined
                :item-text="'name'"
                :item-value="'id'"
                :items="amountTypes"
                v-model="feild.amountTypeId"
                :class="{
                  'is-invalid': submitted && $v.feild.amountTypeId.$error,
                }"
              >
              </v-select>
              <p
                v-if="submitted && !$v.feild.amountTypeId.required"
                class="invalid-feedback mt-2"
              >
                amount type is required
              </p>
            </v-col>

            <v-col md="5" cols="7">
              <v-text-field
                v-model="feild.expenseAmount"
                outlined
                label="Amount"
                :class="{
                  'is-invalid': submitted && $v.feild.expenseAmount.$error,
                }"
              >
              </v-text-field>
              <p
                v-if="submitted && !$v.feild.expenseAmount.required"
                class="invalid-feedback mt-2"
              >
                amount is required
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="blue darken-1" text @click="addFeild">Add</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { dataService } from "@/services/data-service";
import store from "@/store";
import { FunctionalCalendar } from "vue-functional-calendar";
import VueTimepicker from "vue2-timepicker";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
// import  Loader from "../../components/Loader.vue"
export default {
  data() {
    return {
      loading: false,
      activeId: 4,
      actionTabValue: 4,

      technicianlogData: [],
      workOrderActionLogData: [],
      NotesLogData: [],
      DocumentLogData: [],

      items: [
        { key: 4, value: "Technician log" },
        { key: 5, value: "workOrder Action Log" },
        { key: 6, value: "Notes Log" },

        { key: 7, value: "Document Log" },
      ],
      fixedToo: null,
      orderTypes: [],
      serviceDateIsUpdated: false,
      repetitionDetails: {
        frequencyId: null,
        startDate: null,
        endDate: null,
        repetitionSchedules: [
          {
            dates: [],
            fromTime: {
              hours: 0,
              minutes: 0,
            },
            toTime: {
              hours: 0,
              minutes: 0,
            },
          },
        ],
      },
      workOrder: {
        purchaseOrder: { poId: null, orderAmount: null },
        orderType: { name: "", id: 0 },
        repetitionDetails: {},
        serviceDate: [],
        workOrderExpenses: [],
        technicalContact: [],
        serviceDateTypeId: 0,
        paymentMethod: {},
        paymentType: {},
        problemType: {},
        orderId: 0,
        branchId: 0,
        scopeOfWork: "",
      },
      menu: false,
      date: null,
      updateSubmitted: false,
      submitted: false,
      feild: {},
      workOrderExpenseTypeId: 0,
      paymentFeilds: [],
      editedNoteObj: {},
      editeNote: false,
      contactDialog: false,
      frequencies: [
        {
          id: 1,
          name: "Weekly",
        },
        {
          id: 2,
          name: "Monthly By Days",
        },
        {
          id: 3,
          name: "Monthly By Date",
        },
      ],
      PaymentSectionFlag: false,
      servicedates: [],
      updatedObj: {},
      isUpdated: false,
      flag1: false,
      menu2: false,
      date2: null,
      menu3: false,
      timeRangeFrom: null,
      timeRangeTo: null,
      timeFixedFrom: null,
      fixedFrom: false,
      timeFixedTo: null,
      rangeFrom: false,
      rangeTo: false,

      fixedTo: false,
      time2: null,
      editModePaymentMethod: false,
      editModeSection1: false,
      isLongTerm: false,
      expenseDialoge: false,
      calendarConfigs1: {
        sundayStart: true,
        dateFormat: "mm-dd-yyyy",
        isDatePicker: false,
        isDateRange: true,
        isMultipleDateRange: true,
      },
      fixedDates: {},
      rangeDates: {},
      serviceDates: [
        {
          id: 1,
          name: "Within 4 Houres",
        },
        {
          id: 2,
          name: "Fixed Date",
        },
        {
          id: 3,
          name: "Range",
        },
        // {
        //   id: 5,
        //   name: "Repeating",
        // },
      ],
      purchaseOrders: [],
      paymentTypes: [],
      editDateDialog: false,
      problemSearch: "",
      problemTypes: [],
      editMode: false,
      deleteNoteDialog: false,
      deleteDocDialog: false,
      techHeaders: [
        { text: "Name", value: "firstName" },
        { text: "Email", value: "email" },
        { text: "Phone numbers", value: "technicianPhones" },

        { text: "Original Rate", value: "technicianHourlyRate" },

        { text: "Hourly Rate", value: "hourlyRate" },
        { text: "Actions", value: "actions" },
      ],
      techHeaders2: [
        { text: "Name", value: "firstName" },
        { text: "Email", value: "email" },
        { text: "Phone numbers", value: "technicianPhones" },
      ],
      amountTypes: [
        {
          id: 1,
          name: "%",
        },
        {
          id: 2,
          name: "$",
        },
        {
          id: 3,
          name: "Hr",
        },
      ],
      paymentMethods: [
        {
          id: 1,
          name: "Post Paid / Invoice",
        },
        {
          id: 2,
          name: "Pre Paid",
        },
        {
          id: 3,
          name: "Purchase Order",
        },
      ],
      onSiteContacts: [],
      techContacts: [],
      snack: false,
      snackColor: "",
      snackText: "",
      shareWithList: [],
      dialogDelete: false,
      actionId: null,
      locationTerm: "",
      woActions: [
        {
          name: "Duplicate",
          id: 1,
        },
        // {
        //   id: 2,

        //   name: "Create Timesheet",
        // },
        {
          id: 3,

          name: "Cancel ",
        },
      ],
      fileToUpload: null,
      Duplicate: {
        workOrderId: 0,
      },
      menuStartDate: false,
      menuEndDate: false,
      fromTime: {
        hh: "00",
        mm: "00",
      },
      toTime: {
        hh: "00",
        mm: "00",
      },
      contactTab: 0,
      workOrderTech: [],
      docsCount: null,
      notesCount: null,
      dates: [],
      dialog: false,
      queryTerm: "",
      noteDialog: false,
      docs: [],
      notes: [],
      branches: [],
      workOrderContacts: [],
      expensesTypes: [],
      techArray: [],
      workOrderId: this.$route.params.id,
      workOrderTech2: [],
      technicians: [],
      assignedTechs: [],
      noteTypes: [],
      clients: [],
      levels: [],
      note: {
        text: "",
        workOrderNoteTypeId: 1,
        workOrderId: this.$route.params.id,
        noteAccess: [
          {
            typeId: null,
            typeValue: null,
          },
        ],
      },
      tab: "k1",

      headers: [
        { text: "Name", value: "createdByName" },

        { text: "What's the action", value: "name" },
        { text: "Date/time", value: "created" },
      ],

      technicianlogHeaders: [
        { text: "Name", value: "userName" },
        { text: "What's the action", value: "auditChangeTypeName" },
        { text: "Assigned Technicians", value: "newDataObj.technicianName" },
        { text: "Removed Technicians", value: "oldDataObj.technicianName" },
        { text: "Action Date", value: "auditDate" },
      ],

      documentHeaders: [
        { text: "What's the action", value: "auditChangeTypeName" },
        { text: "Uploaded Document", value: "newDataObj" },
        { text: "Removed Document", value: "oldDataObj" },
        { text: "Action Date", value: "auditDate" },
      ],
      notelogHeaders: [
        { text: "Name", value: "userName" },
        { text: "What's the action", value: "auditChangeTypeName" },
        { text: "Added Note", value: "newDataObj.text" },
        { text: "Removed Note", value: "oldDataObj.text" },
        { text: "Action Date", value: "auditDate" },
      ],

      WorkorderlogHeaders: [
        {
          text: "Technician Levels ",
          value: "changedDataObj.workOrderTechnicianLevels",
        },

        { text: "scope Of Work", value: "changedDataObj.scopeOfWork" },
        { text: "payment Method", value: "changedDataObj.paymentMethod" },
        { text: "problem Type", value: "changedDataObj.problemType" },
        { text: "order Id", value: "changedDataObj.orderId" },
        { text: "order Type", value: "changedDataObj.orderType" },
        { text: "Location", value: "changedDataObj.branchName" },
        { text: "Bill Rate", value: "changedDataObj.billRate.expenseAmount" },
        {
          text: "service Dates",
          value: "changedDataObj.serviceDateFromDate",
        },
        { text: "Name", value: "userName" },
        { text: "Action Date", value: "auditDate" },
      ],
      //===== template
      template: [],
      templateobj: {
        templateId: null,
      },
      //==================
      data: [
        {
          name: "",
          action: "",
          wh_action: "",
          date: "",
        },
      ],
      removedTech: null,
      statusLogs: [],
      docId: false,
    };
  },
  validations: {
    feild: {
      expenseAmount: { required },
      amountTypeId: { required },
      description: { required },
      workOrderExpenseTypeId: { required },
    },
    workOrder: {
      orderId: { required },
      problemType: {
        id: { required },
      },
      orderType: {
        id: { required },
      },

      branchId: { required },
    },
  },
  methods: {
    //=======================
    getTemplate() {
      
      dataService.getAll("/api/Mails/get-template-bytype/" + 1, (response) => {
        this.template = response.data;
      });
    },

    //=======================

    onchangetoprivate() {
      //  note: {
      //       text: "",
      //       workOrderNoteTypeId: 1,
      this.note.workOrderNoteTypeId = 1;
    },
    onchangetopublic() {
      this.note.workOrderNoteTypeId = 2;
    },

    //===========================

    getactionlog() {
   

      dataService.create(
        //    "keyId": "15808",
        // "auditChange": 1
        "api/AuditChanges/get-audit-changes",
        {
          keyId: this.$route.params.id.toString(),
          auditChange: 1,
        },
        (reponse) => {
         
          let logData = reponse.data.data;

          logData.forEach((log) => {
            if (log.tableName === "WorkOrderTechnicianAudit") {
              log.data.forEach((data) => {
                if (data.newDataObj == null) {
                  data.newDataObj = {
                    technicianName: "- - - - - - - - - - - -",
                  };
                }
                if (data.oldDataObj == null) {
                  data.oldDataObj = {
                    technicianName: "- - - - - - - - - - - -",
                  };
                }
              });
              this.technicianlogData = log.data;
            } else if (log.tableName === "WorkOrderDetails") {
              this.workOrderActionLogData = log.data;
            } else if (log.tableName === "FileListDTO") {
              this.DocumentLogData = log.data;
            } else if (log.tableName === "WorkOrderNoteDTO") {
              log.data.forEach((data) => {
                if (data.newDataObj == null) {
                  data.newDataObj = {
                    text: "- - - - - - - - - - - -",
                  };
                }
                if (data.oldDataObj == null) {
                  data.oldDataObj = {
                    text: "- - - - - - - - - - - -",
                  };
                }
              });

              this.NotesLogData = log.data;
            }
          });

          if (localStorage.actionid) {
            this.tab = 4;
            this.actionTabValue = localStorage.actionid;
            localStorage.removeItem("actionid");
          }

          //this.workOrderContacts = [];
          // this.getWorkOrderDetails();
        }
      );
    },

    changeTab(id) {
      this.actionTabValue = id;
      this.getactionlog();

      // this.tab = 4;
    },
    RemoveAssigContact() {
      dataService.getAll(
        "api/WorkOrders/remove_contact/" + this.workOrderId,
        () => {
          this.workOrderContacts = [];
          // this.getWorkOrderDetails();
        }
      );
    },

    onchangebranch() {
      this.updated();
      this.getOnsiteContacts();
      this.getTechContacts();
      this.getAccountManagerContacts();
      this.RemoveAssigContact();
    },
    //========================================
    updatePayment() {
      this.updated();
      this.checkPurchase();
    },
    applyLocations(value) {
      dataService.getList(
        "api/Branches/search_by_name",
        {
          pageNumber: 1,
          pageSize: 2000,
          subString: value,
        },
        (response) => {
          this.branches = response.data.data.items;
        }
      );
    },
    updateServiseDate() {
      this.editDateDialog = false;
      this.isUpdated = true;
      this.serviceDateIsUpdated = true;
      var rangeObj = [];
      //range
      if (this.workOrder.serviceDateTypeId == 3) {
        for (var r = 0; r < this.rangeDates.multipleDateRange.length; r++) {
          this.timeRangeFrom.HH =
            this.timeRangeFrom.HH && this.timeRangeFrom.HH != ""
              ? this.timeRangeFrom.HH
              : "00";
          this.timeRangeFrom.mm =
            this.timeRangeFrom.mm && this.timeRangeFrom.mm != ""
              ? this.timeRangeFrom.mm
              : "00";
          let from =
            this.timeRangeFrom.HH + ":" + this.timeRangeFrom.mm + ":00";

          // let from = this.timeRangeFrom + ":00";
          let fromdate = new Date(
            this.rangeDates.multipleDateRange[r].start.split("T")[0] +
              " " +
              from
          );

          this.timeRangeTo.HH =
            this.timeRangeTo.HH && this.timeRangeTo.HH != ""
              ? this.timeRangeTo.HH
              : "00";
          this.timeRangeTo.mm =
            this.timeRangeTo.mm && this.timeRangeTo.mm != ""
              ? this.timeRangeTo.mm
              : "00";
          let to = this.timeRangeTo.HH + ":" + this.timeRangeTo.mm + ":00";

          // let from = this.timeRangeFrom + ":00";
          // let fromdate = new Date(
          //   this.rangeDates.multipleDateRange[r].start.split("T")[0] +
          //     " " +
          //     from
          // );
          // let to = this.timeRangeTo + ":00";
          let todate = new Date(
            this.rangeDates.multipleDateRange[r].end.split("T")[0] + " " + to
          );
          rangeObj.push({
            serviceDateFromDate: fromdate,
            serviceDateToDate: todate,
          });
        }

        if (this.isLongTerm == true) {
          this.workOrder.serviceDateTypeId = 4;
        }
        // this.repetitionDetails = null;
      }
      //fixed
      if (this.workOrder.serviceDateTypeId == 2) {
        let testdate = this.date.split("T")[0];

        this.fixedFrom.HH =
          this.fixedFrom.HH && this.fixedFrom.HH != ""
            ? this.fixedFrom.HH
            : "00";
        this.fixedFrom.mm =
          this.fixedFrom.mm && this.fixedFrom.mm != ""
            ? this.fixedFrom.mm
            : "00";
        let from = this.fixedFrom.HH + ":" + this.fixedFrom.mm + ":00";
        let testFixedStartTime = new Date(testdate + " " + from);

        // let from = this.fixedFrom + ":00";
        this.fixedToo.HH =
          this.fixedToo.HH && this.fixedToo.HH != "" ? this.fixedToo.HH : "00";
        this.fixedToo.mm =
          this.fixedToo.mm && this.fixedToo.mm != "" ? this.fixedToo.mm : "00";
        let to = this.fixedToo.HH + ":" + this.fixedToo.mm + ":00";
        let testFixedEnd = new Date(testdate + " " + to);

        // let fromdate = new Date(this.date.split("T")[0] + " " + from);

        // let todate = new Date(this.date.split("T")[0] + " " + to);

        rangeObj.push({
          serviceDateFromDate: testFixedStartTime,
          serviceDateToDate: testFixedEnd,
        });

        //this.repetitionDetails = null;
      }
      //repitation
      if (this.workOrder.serviceDateTypeId == 5) {
        this.fromTime.HH =
          this.fromTime.HH && this.fromTime.HH != "" ? this.fromTime.HH : "00";
        this.fromTime.mm =
          this.fromTime.mm && this.fromTime.mm != "" ? this.fromTime.mm : "00";

        this.toTime.HH =
          this.toTime.HH && this.toTime.HH != "" ? this.toTime.HH : "00";
        this.fromTime.mm =
          this.toTime.mm && this.toTime.mm != "" ? this.toTime.mm : "00";

        // let to = this.toTime.HH + this.toTime.mm + ":00";
        // let todate = new Date(this.date.split("T")[0] + " " + to);
        this.repetitionDetails.repetitionSchedules[0].fromTime.hours =
          this.fromTime.HH;
        this.repetitionDetails.repetitionSchedules[0].fromTime.minutes =
          this.fromTime.mm;

        this.repetitionDetails.repetitionSchedules[0].toTime.hours =
          this.toTime.HH;
        this.repetitionDetails.repetitionSchedules[0].toTime.minutes =
          this.toTime.mm;

        let from = this.fromTime.HH + ":" + this.fromTime.mm + ":00";
        let testStartTime = new Date(
          this.repetitionDetails.startDate.split("T")[0] + " " + from
        );
        let to = this.toTime.HH + ":" + this.toTime.mm + ":00";
        let testEndTime = new Date(
          this.repetitionDetails.startDate.split("T")[0] + " " + to
        );
        this.repetitionDetails.repetitionSchedules[0].fromDateTime =
          testStartTime;
        this.repetitionDetails.repetitionSchedules[0].toDateTime = testEndTime;

        this.repetitionDetails.startDate = new Date(
          this.repetitionDetails.startDate.split("T")[0]
        );
        this.repetitionDetails.endDate = new Date(
          this.repetitionDetails.endDate.split("T")[0]
        );

        for (
          var d = 0;
          d < this.repetitionDetails.repetitionSchedules[0].dates.length;
          d++
        ) {
          this.repetitionDetails.repetitionSchedules[0].dates[d] = new Date(
            this.repetitionDetails.repetitionSchedules[0].dates[d].split("T")[0]
          );
        }
      }

      //within 4 hours

      this.servicedates = rangeObj;
    },
    getAllLocations(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyLocations(value);
        }, 1000);
        this.$nextTick(() => this.$refs.locationSearch.focus());
      } else {
        self.applyLocations(value);
      }
    },
    getPaymentTypes() {
      dataService.getAll("api/Enums/payment-types", (response) => {
        this.paymentTypes = response.data;
      });
    },
    async loadLocations() {
      this.branches = await this.getAllLocations(this.locationTerm || "");
    },
    refreshPage() {
      (this.flag1 = false), (this.isUpdated = false);
      this.editMode = false;
      this.editModePaymentMethod = false;
      this.PaymentSectionFlag = false;
      this.editModeSection1 = false;
      this.getStatusLogs();
      this.getWorkOrderDetails();
      this.getAssignedTech();
      this.getNoteTypes();
      this.getContacts();
      this.getDocs();
      this.getNotes();
      this.getShareList();
      this.getProblemTypes();
    },
    getOnsiteContacts() {
      dataService.getAll(
        "api/Contacts/" + this.workOrder.branchId + "/1",
        (response) => {
          this.onSiteContacts = response.data;
        }
      );
    },
    addFeild() {
      this.submitted = true;

      this.$v.$touch();
      if (this.$v.feild.$invalid) {
        return;
      }
      this.expensesTypes.forEach((element) => {
        if (element.id == this.feild.workOrderExpenseTypeId)
          this.feild.workOrderExpenseType = element.name;
      });
      this.workOrder.workOrderExpenses.push(this.feild);

      this.expenseDialoge = false;
      this.feild = {};
      this.isUpdated = true;
      this.submitted = false;
    },
    getExpensesTypes() {
      dataService.getAll("api/Enums/work-order-expense-types", (response) => {
        this.expensesTypes = response.data;
      });
    },

    getBranches() {
      dataService.getAll(
        "api/Branches/company/" + this.workOrder.company.id,
        (response) => {
          this.branches = response.data;
        }
      );
    },
    changeEditModeSection1() {
      if (this.editModeSection1) {
        this.editModeSection1 = false;
      } else {
        this.editModeSection1 = true;
      }
    },

    ShowExpenseDialog() {
      this.expenseDialoge = true;
    },

    ShowDialog() {
      this.contactDialog = true;
    },
    getLevels() {
      dataService.getAll("api/Enums/levels", (response) => {
        this.levels = response.data;
      });
    },
    changePaymentFlage() {
      if (this.PaymentSectionFlag) {
        this.PaymentSectionFlag = false;
      } else {
        this.PaymentSectionFlag = true;
      }
    },
    getAccountManagerContacts() {
      dataService.getAll(
        "api/Contacts/" + this.workOrder.branchId + "/3",
        (response) => {

          this.AccountManagerContacts = response.data;

        }
      );
    },
    changeWorkOrderScope() {
      if (this.flag1) {
        this.flag1 = false;
      } else {
        this.flag1 = true;
      }
    },
    updateWorkOrder() {
      this.loading = true;

      this.changeEditModeSection1 = true;
      this.editModeSection1 = true;
      this.editMode = true;
      this.editModePaymentMethod = true;
      this.flag1 = true;
      this.PaymentSectionFlag = true;
      this.updateSubmitted = true;
      this.$v.$touch();
      if (this.$v.workOrder.$invalid) {
        return;
      }

      this.updatedObj.serviceDateIsUpdated = this.serviceDateIsUpdated;
      this.updatedObj.fieldNationId=this.workOrder.fieldNationId;
      this.updatedObj.id = this.workOrder.id;
      this.updatedObj.scopeOfWork = this.workOrder.scopeOfWork;
      this.updatedObj.branchId = this.workOrder.branchId;
      this.updatedObj.orderId = this.workOrder.orderId;
      this.updatedObj.accountManagerContact = this.workOrder.accountManagerContact;

      if (this.workOrder.paymentMethod.id == 3) {
        this.updatedObj.purchaseOrderId = this.workOrder.purchaseOrder.id;
      } else {
        this.updatedObj.purchaseOrderId = null;
      }
      if (this.repetitionDetails.frequencyId != null) {
        this.updatedObj.repetitionDetails = this.repetitionDetails;
      }

      this.updatedObj.orderTypeId = this.workOrder.orderType.id;
      this.updatedObj.problemTypeId = this.workOrder.problemType.id;
      this.updatedObj.paymentTypeId = this.workOrder.paymentType.id;
      this.updatedObj.paymentMethodId = this.workOrder.paymentMethod.id;
      this.updatedObj.ServiceDateTypeId = this.workOrder.serviceDateTypeId;
      this.updatedObj.onSitePrimaryContact =
        this.workOrder.onSitePrimaryContact;
      this.updatedObj.onSiteSecondaryContact =
        this.workOrder.onSiteSecondaryContact;
      this.updatedObj.technicalContact = this.workOrder.technicalContact;
      this.workOrder.workOrderExpenses.forEach((element) => {
        if (element.description == null) {
          element.description = "";
        }
      });
      this.updatedObj.workOrderExpense = this.workOrder.workOrderExpenses;
      this.updatedObj.workOrderTecLevels =
        this.workOrder.workOrderTechnicianLevels;
      this.updatedObj.serviceDate = this.servicedates;

      dataService.update(
        "api/WorkOrders/update",
        this.updatedObj,

        () => {
          this.loading = false;
          // this.refreshPage();
          this.refreshPage();
        }
      );

      // /api/WorkOrders/update
    },
    updated() {
      this.isUpdated = true;
    },
    enableDateEditMode() {
      this.editDateDialog = true;
    },

    createProblemType() {
      dataService.create(
        "api/ProblemTypes/create",
        {
          name: this.problemSearch,
        },
        (response) => {
          this.getProblemTypes();
          this.workOrder.problemTypeId = response.data.data;

          // this.dialog = false;
          store.commit("SHOW_MESSAGE", {
            text: "Created Successfully",
            color: "success",
            timeout: 3000,
          });
        }
      );
      // }
    },
    getOrderTypes() {
      dataService.getAll("api/Enums/order-types", (response) => {
        this.orderTypes = response.data;
      });
    },
    checkPurchase() {
      if (this.workOrder.paymentMethod.id == 3) {
        if (this.workOrder.branchId != null) {
          dataService.create(
            "api/PurchaseOrders/get-by-key-type",
            {
              clientId: this.workOrder.client.id,
              accountId: this.workOrder.account.id,
              branchId: this.workOrder.branchId,
              companyId: this.workOrder.company.id,
            },
            (response) => {
              this.purchaseOrders = response.data.data;
            }
          );
        }
      }
    },
    getProblemTypes() {
      dataService.getAll("api/ProblemTypes", (response) => {
        this.problemTypes = response.data;
      });
    },
    editThisNote(item) {
      this.editedNoteObj = item;
      if (this.editeNote) {
        this.editeNote = false;
      } else {
        this.editeNote = true;
      }
    },

    removeThisNote(id) {
      this.noteId = id;
      this.deleteNoteDialog = true;
    },
    removeThisDoc(doc) {
      this.docId = doc.id;
      this.deleteDocDialog = true;
    },
    EditeConfirmedNote() {
      dataService.update(
        "/api/WorkOrders/update-note",
        this.editedNoteObj,
        (response) => {
          if (response.data.succeeded == true) {
            this.snack = true;
            this.snackColor = "success";
            this.snackText = "Document Deleted ";
            this.getNotes();
            this.getactionlog();
          }
        }
      );
      this.editeNote = false;
    },

    EditeCancelNote() {
      this.getNotes();
      this.editeNote = false;
    },
    confirmDeleteNote() {
      this.loading = true;
      dataService.postDelete(
        "api/WorkOrders/delete-Note/" + this.noteId,
        (response) => {
          if (response.data.succeeded == true) {
            this.snack = true;
            this.snackColor = "success";
            this.snackText = "Document Deleted ";
            this.getNotes();
            this.getactionlog();
            this.loading = false;
          }
        }
      );
      this.deleteNoteDialog = false;
    },
    confirmDeleteDoc() {
      dataService.postDelete(
        "api/WorkOrders/delete_workOrder_Document/" + this.docId,
        (response) => {
          if (response.data.succeeded == true) {
            this.snack = true;
            this.snackColor = "success";
            this.snackText = "Document Deleted ";
            this.getDocs();
            this.getactionlog();
          }
        }
      );
    },
    save(item) {
      dataService.create(
        "api/WorkOrders/update-technician-workOrder-hourly-rate",
        {
          workOrderTechnicianHourlyRateDTO: {
            id: item.technicianWorkOrderId,
            hourlyRate: item.hourlyRate,
          },
        },
        (response) => {
          if (response.data.succeeded == true) {
            this.getactionlog();
            this.snack = true;
            this.snackColor = "success";
            this.snackText = "Data saved";
          }
        }
      );
    },
    getTechContacts() {
      dataService.getAll(
        "api/Contacts/" + this.workOrder.branchId + "/2",
        (response) => {
          this.techContacts = response.data;
        }
      );
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    close() {},
    getShareList() {
      dataService.getAll("api/Enums/share-note-types", (response) => {
        this.shareWithList = response.data;
      });
    },
    getStatusLogs() {
      dataService.getAll(
        "api/WorkOrders/get_status_log/" + this.workOrderId,
        (response) => {
          this.statusLogs = response.data;
        }
      );
    },
    removeThisTech(item) {
      this.removedTech = item.technicianWorkOrderId;
      this.dialogDelete = true;
    },
    createTimeSheet(item) {
      localStorage.WorkOrderId = this.workOrderId;
      localStorage.TechName = item.firstName + " " + item.lastName;
      localStorage.SubWorkOrder = item.technicianWorkOrderId;
      localStorage.MasterWorkOrderId = this.workOrder.orderId;
      localStorage.ScopeOfWork = this.workOrder.scopeOfWork;
      localStorage.Client = this.workOrder.client.name;
      localStorage.Account = this.workOrder.account.name;
      if (this.workOrder.address == null) {
        this.workOrder.address = "";
      } else {
        localStorage.Location =
          this.workOrder.address.street +
          "-" +
          this.workOrder.address.buildingNumber +
          "-" +
          this.workOrder.address.city.name +
          "-" +
          this.workOrder.address.state.name +
          "-" +
          this.workOrder.address.country.name;
      }

      this.$router.push({
        name: "Clocking",
        params: { woId: item.technicianWorkOrderId },
      });
    },
    deleteItemConfirm() {
      dataService.postDelete(
        "api/WorkOrders/remove-assigned_Technician/" + this.removedTech,
        (response) => {
          if (response.data.succeeded == true) {
            store.commit("SHOW_MESSAGE", {
              text: "Removed Successfully",
              color: "success",
              timeout: 3000,
            });
            this.dialogDelete = false;
            this.getAssignedTech();
            this.getactionlog();
          } else {
            store.commit("SHOW_MESSAGE", {
              text: response.data.errors[0],
              color: "error",
              timeout: 3000,
            });
          }
        }
      );
    },
    handleAction() {
      if (this.actionId == 1) {
        this.Duplicate.workOrderId = this.$router.currentRoute.params.id;
        dataService.create(
          "api/WorkOrders/Duplicate",
          this.Duplicate,
          (response) => {
            localStorage.Duplicate = "true";
          
            if (response.data.succeeded == true) {
                this.$router.push({
              name: "ShowWorkOrder",
              params: { id: response.data.data.id },
            });
              store.commit("SHOW_MESSAGE", {
                text: "Created Successfully",
                color: "success",
                timeout: 3000,
              });

              // dataService.getAll(
              //   "api/WorkOrders/" + response.data.data.id,
              //   (response) => {
              //     this.$router.currentRoute.params.id = response.data.data.id;
              //     this.workOrder = response.data;
              //     this.getOnsiteContacts();
              //     this.getTechContacts();
              //     this.getBranches();
              //     for (
              //       var d = 0;
              //       d < this.workOrder.serviceDateFromDate.length;
              //       d++
              //     ) {
              //       var from = new Date(
              //         this.workOrder.serviceDateFromDate[d].serviceDateFromDate
              //       ).toLocaleString();
              //       var to = new Date(
              //         this.workOrder.serviceDateFromDate[d].serviceDateToDate
              //       ).toLocaleString();
              //       this.dates.push({
              //         serviceDateFromDate: from,
              //         serviceDateToDate: to,
              //       });
              //     }

              //     this.changeEditModeSection1 = true;
              //     this.editModePaymentMethod = true;
              //     this.editModeSection1 = true;
              //     this.editMode = true;
              //     this.flag1 = true;
              //     this.PaymentSectionFlag = true;
              //     this.actionId = 0;
              //   }
              // );
            }
          }
        );
      }
      if (this.actionId == 3) {
        dataService.create(
          "api/WorkOrders/Change_Status",
          {
            workOrderId: this.workOrderId,
            workOrderStatusId: 6,
          },
          (response) => {
            if (response.data.succeeded == true) {
              store.commit("SHOW_MESSAGE", {
                text: "Status Updated Successfully",
                color: "success",
                timeout: 3000,
              });

              this.getWorkOrders(1);
            }
          }
        );
      }
    },
    getAssignedTech() {
      if (this.userInfo.userTypeId == 201 || this.userInfo.userTypeId == 202) {
        dataService.getAll(
          "api/Technicians/get/" + this.workOrderId,
          (response) => {
            this.workOrderTech = response.data;
          }
        );
      } else {
        dataService.getAll(
          "api/Technicians/get/client/" + this.workOrderId,
          (response) => {
            this.workOrderTech2 = response.data;
          }
        );
      }
    },
    convertUTCDateToLocalDate(date) {
      var newDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60 * 1000
      );

      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();

      newDate.setHours(hours - offset);

      return newDate;
    },
    getWorkOrderDetails() {
      dataService.getAll("api/WorkOrders/" + this.workOrderId, (response) => {
        this.workOrder = response.data;

        if (this.workOrder.purchaseOrder == null) {
          this.workOrder.purchaseOrder = { id: 0, name: "" };
        }

        this.checkPurchase();
        for (var d = 0; d < this.workOrder.serviceDateFromDate.length; d++) {
          var from = this.workOrder.serviceDateFromDate[d].serviceDateFromDate;

          var to = this.workOrder.serviceDateFromDate[d].serviceDateToDate;

          this.dates.push({
            serviceDateFromDate: from,
            serviceDateToDate: to,
            fromTime:this.workOrder.serviceDateFromDate[d].fromTime,
            toTime:this.workOrder.serviceDateFromDate[d].toTime

          });
        }

        this.getOnsiteContacts();
        this.getTechContacts();
        this.getAccountManagerContacts();
        this.getBranches();
        if (localStorage.Duplicate == "true") {
          this.changeEditModeSection1 = true;
          this.editModePaymentMethod = true;
          this.editModeSection1 = true;
          this.editMode = true;
          this.flag1 = true;
          this.PaymentSectionFlag = true;
          this.actionId = 0;
          localStorage.Duplicate="false"
          
        }
       
      });
    },
    getContacts() {
      dataService.getAll(
        "api/WorkOrders/get_contacts/" + this.workOrderId,
        (response) => {
          var tempWorkOrderContacts = response.data;
          var makeUnique = (array = [], keys = []) => {
            if (!keys.length || !array.length) return [];
            return array.reduce((list, item) => {
              const hasItem = list.find((listItem) =>
                keys.every((key) => listItem[key] === item[key])
              );
              if (!hasItem) list.push(item);
              return list;
            }, []);
          };
          this.workOrderContacts = makeUnique(tempWorkOrderContacts, ["id"]);

          //console.log(this.workOrderContacts);
        }
      );
    },
    getTechnicians(queryTerm) {
      var self = this;
      if (queryTerm) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyTech(queryTerm);
        }, 1000);
      } else {
        self.applyTech(queryTerm);
      }
    },
    applyTech(value) {
      var self = this;
      dataService.getList(
        "api/Technicians/get-all",
        {
          pageNumber: 1,
          pageSize: 100,
          search: value,
        },
        (response) => {
          this.technicians = response.data.data.items;
          self.$nextTick(() => self.$refs.techassign.focus());
        }
      );
    },
    async loadEntries() {
      this.technicians = await this.getTechnicians(this.queryTerm || "");
    },
    assignTechs() {
      for (var t = 0; t < this.assignedTechs.length; t++) {
        this.techArray.push({
          workOrderId: this.workOrderId,
          technicianId: this.assignedTechs[t],
          isCaptain: true,
        });
      }
      this.loading = true;
      let obj = {
        emailTemplateId: this.templateobj.templateId,
        workOrderTech: this.techArray,
      };
      dataService.create(
        "api/WorkOrders/assign-technicians",
        obj,
        (response) => {
          this.loading = false;
          this.techArray = [];
          this.assignedTechs = [];
          this.templateobj.templateId = 0;
          if (response.data.succeeded) {
         
            this.getAssignedTech();
            this.getStatusLogs();
            this.getactionlog();
          } 
        }
      );
    },
    getNoteTypes() {
      dataService.getAll("api/Enums/work-order-note-types", (response) => {
        this.noteTypes = response.data;
      });
    },
    addNote() {
      this.loading = true;
      if (this.note.workOrderNoteTypeId == 1) {
        this.note.noteAccess = null;
      }

      dataService.create("api/WorkOrders/add_Note", this.note, () => {
        this.noteDialog = false;

        this.note = {
          text: "",
          workOrderNoteTypeId: 1,
          workOrderId: this.$route.params.id,
          noteAccess: [
            {
              typeId: null,
              typeValue: null,
            },
          ],
        };
        this.getNotes();
        this.getNoteTypes();
        this.getactionlog();
        this.loading = false;
      
      });
    },
    onUploadDoc(event) {
      this.fileToUpload = event.target.files[0];
    },
    uploadDoc() {
      let formData = new FormData();
      formData.append("Document", this.fileToUpload);
      formData.append("WorkOrderId", this.$route.params.id);
      this.loading = true;
      this.$http.post("api/WorkOrders/upload_document", formData).then(() => {
        this.loading = false;
        this.getDocs();
        this.getactionlog();
        document.getElementById("FileInput").value = null;
        this.fileToUpload = null;
      });
    },
    getDocs() {
      dataService.getAll(
        "api/WorkOrders/get_Document/" + this.workOrderId,
        (response) => {
          this.docs = response.data;
          if (this.docs.length > 0) {
            this.docsCount = this.docs.length;
          }
        }
      );
    },
    getNotes() {
      dataService.getAll(
        "api/WorkOrders/get_Note/" + this.workOrderId,
        (response) => {
          this.notes = response.data;

          if (this.notes.length > 0) {
            this.notesCount = this.notes.length;
          }
        }
      );
    },
    openNoteDialog() {
      this.noteDialog = true;
    },
    openGoogleMap(item) {
      const urlSuffix = item["latitude_longitude"]
        ? item["latitude_longitude"]
        : item.buildingNumber +
          " " +
          item.street +
          " " +
          item.state.name +
          " " +
          item.city.name +
          " " +
          item.postalCode;

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
  },

  computed: {
    ...mapGetters({
      userInfo: "AuthModule/userData",
    }),
    locationSearch: {
      get() {
        return this.locationTerm;
      },

      set(searchInput) {
        if (this.locationTerm !== searchInput) {
          this.locationTerm = searchInput;
          this.loadLocations();
        }
      },
    },
    search: {
      get() {
        return this.queryTerm;
      },

      set(searchInput) {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput;
          this.loadEntries();
        }
      },
    },
  },
  mounted() {
    //==========
    this.getTemplate();

    this.getactionlog();
    this.getPaymentTypes();
    this.getOrderTypes();
    this.getStatusLogs();
    this.getWorkOrderDetails();

    this.getAssignedTech();
    this.getNoteTypes();

    this.getContacts();

    this.getDocs();
    this.getNotes();
    this.getShareList();
    this.getProblemTypes();
    this.getLevels();
    this.getExpensesTypes();
  },
  components: {
    VueTimepicker,
    FunctionalCalendar,
  },
};
</script>