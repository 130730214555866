<template>
  <div class="login-background">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div>
      <v-stepper class="login-form" v-model="e1">
        <v-stepper-items>
          <v-stepper-content step="1" id="stepOne">
            <div class="mb-12">
              <p class="login-label">Login</p>
              <v-text-field
                v-model="email"
                solo
                label="Enter your email"
                :class="{ 'is-invalid': submitted && $v.email.$error }"
                v-on:keyup.enter="validateStepOne"
              ></v-text-field>
              <p
                v-if="submitted && !$v.email.required"
                class="invalid-feedback mt-3"
              >
                email is Required
              </p>
              <p
                v-if="submitted && !$v.email.email"
                class="invalid-feedback mt-3"
              >
                email is Invalid
              </p>
              <a @click="forgetPassword" class="fp-link">Forget Password</a>

              <v-btn color="#172450" class="nextBtn" @click="validateStepOne">
                Next
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content step="2">
            <div class="mb-12">
              <p class="login-label">Login</p>
              <p class="confirmation-link">
                Please Check your email for the Confirmation Link
              </p>
              <v-btn color="#172450" class="nextBtn" @click="e1 = 1">
                Done
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content step="3">
            <div class="mb-12">
              <p class="login-label">Activate your account</p>
              <v-text-field
                v-model="systemUserInfo.firstName"
                solo
                label="Enter your email"
                :class="{
                  'is-invalid': submitted && $v.systemUserInfo.firstName.$error,
                }"
              ></v-text-field>
              <p
                v-if="submitted && !$v.systemUserInfo.firstName.required"
                class="invalid-feedback mt-3"
              >
                First Name is Required
              </p>

              <v-text-field
                v-model="systemUserInfo.lastName"
                solo
                label="Enter your email"
                :class="{
                  'is-invalid': submitted && $v.systemUserInfo.lastName.$error,
                }"
              ></v-text-field>
              <p
                v-if="submitted && !$v.systemUserInfo.lastName.required"
                class="invalid-feedback mt-3"
              >
                Last Name is Required
              </p>

              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Picker in menu"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    solo
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

          
              <v-tooltip v-model="show" right>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    @click="show = true"
                    v-bind="attrs"
                    v-on="on"
                    label="Password"
                    solo
                    type="password"
                    v-model="newPassword"
                    :class="{
                      'is-invalid': submitted && $v.newPassword.$error,
                    }"
                  ></v-text-field>
                </template>
                <div>
                  <p>Password need at least :</p>
                  <ul>
                    <li class="validation-list">
                      <i
                        :class="{
                          'is-checked': newPassword.length >= 8,
                        }"
                        class="fas fa-check-circle mr-2"
                      ></i>
                      <span> 8 characters</span>
                    </li>
                    <li class="validation-list">
                      <i
                        :class="{
                          'is-checked': checkAlphanumeric(newPassword),
                        }"
                        class="fas fa-check-circle mr-2"
                      ></i>
                      <span>contain alphanumeric characters </span>
                    </li>
                    <li class="validation-list mb-7">
                      <i
                        :class="{
                          'is-checked': containUpper(newPassword),
                        }"
                        class="fas fa-check-circle mr-2"
                      ></i>
                      <span>contain uppercase and lowercase characters </span>
                    </li>
                  </ul>
                </div>
              </v-tooltip>
             
              <p
                v-if="submitted && !$v.newPassword.required"
                class="invalid-feedback mt-3"
              >
                Password is Required
              </p>
              <p
                class="invalid-feedback mt-3"
                v-if="submitted && !$v.newPassword.minLength"
              >
                Password must be at least 8 characters and must contain
                alphanumeric and uppercase characters
              </p>
              <v-text-field
                label="Password"
                solo
                :class="{
                  'is-invalid': submitted && $v.confirmationPassword.$error,
                }"
                v-model="confirmationPassword"
              ></v-text-field>
              <p
                v-if="submitted && !$v.confirmationPassword.required"
                class="invalid-feedback mt-3"
              >
                Confirmation Password is Required
              </p>
              <p
                v-if="submitted && !$v.confirmationPassword.sameAsPassword"
                class="invalid-feedback mt-3"
              >
                Confirmation Password must match Password
              </p>

              <v-btn color="#172450" class="nextBtn" @click="validateStepThree">
                Done
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content step="4" id="stepOne">
            <div class="mb-12">
              <p class="login-label">Login</p>
              <v-text-field
                solo
                type="password"
                v-model="password"
                label="Enter Your Password"
                :class="{ 'is-invalid': submitted && $v.password.$error }"
                v-on:keyup.enter="login"
              >
                <p
                  v-if="submitted && !$v.password.required"
                  class="invalid-feedback mt-3"
                >
                  Password is Required
                </p>
              </v-text-field>
              <v-btn color="#172450" class="nextBtn" @click="login">
                Login
              </v-btn>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>
</template>
<script>
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";
import { dataService } from "@/services/data-service";
import store from "@/store";

export default {
  data() {
    return {
      loading:false,
      show: false,
      genders: [
        {
          id: 1,
          name: "Male",
        },
        {
          id: 1,
          name: "Female",
        },
      ],
      e1: 1,
      email: "",
      password: "",
      newPassword: "",
      confirmationPassword: "",
      submitted: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      systemUserInfo: {
        firstName: "",
        lastName: "",
      },
    };
  },
  validations: {
    email: { required, email },
    password: { required },

    newPassword: { required, minLength: minLength(8) },
    confirmationPassword: { required, sameAsPassword: sameAs("newPassword") },
    systemUserInfo: {
      firstName: { required },
      lastName: { required },
    },
  },
  methods: {
    forgetPassword() {
      if (!this.email) {
        store.commit("SHOW_MESSAGE", {
          text: "Invalid Email",
          color: "error",
          timeout: 3000,
        });
      } else {
        dataService.create(
          "api/Authentication/forget-password",
          {
            email: this.email,
          },
          (response) => {
            if (response.data.succeeded == true) {
              store.commit("SHOW_MESSAGE", {
                text: " Please Check your email",
                color: "success",
                timeout: 3000,
              });
            }
          }
        );
      }
    },
    containUpper(str) {
      return /[A-Z]/.test(str) && /[a-z]/.test(str);
    },
    checkAlphanumeric(str) {
      return str.match(/^[A-Za-z0-9]/) ? true : false;
    },
    validateStepOne() {
      this.loading=true;
      this.submitted = true;
      this.$v.email.$touch();
      if (this.$v.email.$invalid) {
        this.loading=false;
        return;
      } else {
        this.checkEmail();
      
      }
    
    },
    checkEmail() {
    

      dataService.getAll(
        "api/Authentication/loged-in-before/" + this.email,
        (response) => {
        
          if (response.succeeded == true) {
            var isFirstTime = response.data.isFirstLogin;
            // isFirstTime = true;
            if (isFirstTime == true) {
              this.e1 = 2;
            } else {
              this.e1 = 4;
            }
          } else {
            store.commit("SHOW_MESSAGE", {
              text: "Invalid Email",
              color: "error",
              timeout: 3000,
            });
          }
          this.loading=false;
        }
      );
    },
    login() {
     
      this.submitted = true;
      const email = this.email;
      const password = this.password;
      // this.$v.$touch();
      if (this.$v.email.$invalid || this.$v.password.$invalid) {
        return;
      } else {
        this.$store
          .dispatch("AuthModule/signIn", {
            email,
            password,
          })
          .catch((err) => {
            if (err) {
             
              this.invalidData = err.message;
             
            }
          });
          

        // this.$router.push("dashboard");
      }
    },
    validateStepThree() {},
  },
};
</script>

