const schedule = {
    state: {
        teachers: localStorage.getItem("teachers")||[],
    },
    mutations: {
        SET_Teachers(){
            //console.log(data,"mut")
           
        
          },
    },
    actions: {
        setTeachers({commit},data) {
           
            localStorage.setItem("teachers", JSON.stringify(data));

            commit("SET_Teachers", data);
        },
        
    },
    getters: {
        Teachers:state =>state.teachers

    }
};
export default schedule;
