var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "handle-test pb-10" },
    [
      _c(
        "v-overlay",
        { attrs: { value: _vm.loading } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
      _c(
        "v-tabs",
        {
          staticClass: "wo-details",
          attrs: {
            "active-class": "active-tab",
            "background-color": "#F8F8F8",
            centered: true,
            "align-with-title": "",
          },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("v-tabs-slider", { attrs: { color: "#4B5568" } }),
          _c("v-tab", { key: "k1", staticClass: "single-tab" }, [
            _vm._v(" Technicians "),
          ]),
          _c(
            "v-tab",
            {
              key: "k2",
              staticClass: "single-tab",
              on: {
                click: function ($event) {
                  _vm.docsCount = 0
                },
              },
            },
            [
              _vm.docsCount > 0
                ? _c("v-badge", {
                    staticClass: "mr-2",
                    attrs: {
                      inline: true,
                      small: "",
                      color: "red",
                      content: _vm.docsCount,
                    },
                  })
                : _vm._e(),
              _vm._v(" Documents "),
            ],
            1
          ),
          _c(
            "v-tab",
            {
              key: "k3",
              staticClass: "single-tab",
              on: {
                click: function ($event) {
                  _vm.notesCount = 0
                },
              },
            },
            [
              _vm.notesCount > 0
                ? _c("v-badge", {
                    staticClass: "mr-2",
                    attrs: {
                      inline: true,
                      small: "",
                      color: "red",
                      content: _vm.notesCount,
                    },
                  })
                : _vm._e(),
              _vm._v(" Notes "),
            ],
            1
          ),
          _c("v-tab", { key: "k4", staticClass: "single-tab" }, [
            _vm._v(" Status Log "),
          ]),
          _c("v-tab", { key: "k5", staticClass: "single-tab" }, [
            _vm._v(" Actions log "),
          ]),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "v-tab-item",
            { key: "k1" },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _vm.userInfo.userTypeId == 201 ||
                  _vm.userInfo.userTypeId == 202
                    ? _c(
                        "div",
                        { staticClass: "tab1-card" },
                        [
                          _c(
                            "v-row",
                            { staticClass: "pl-10" },
                            [
                              _c(
                                "v-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { md: "5" } },
                                        [
                                          _vm.can(4)
                                            ? _c("v-autocomplete", {
                                                ref: "techassign",
                                                attrs: {
                                                  items: _vm.technicians,
                                                  chips: "",
                                                  outlined: "",
                                                  color: "blue-grey lighten-2",
                                                  label: "Select a technician",
                                                  "item-text": (item) =>
                                                    item.firstName +
                                                    " " +
                                                    item.lastName,
                                                  "item-value": "id",
                                                  multiple: "",
                                                  "search-input": _vm.search,
                                                },
                                                on: {
                                                  "update:searchInput":
                                                    function ($event) {
                                                      _vm.search = $event
                                                    },
                                                  "update:search-input":
                                                    function ($event) {
                                                      _vm.search = $event
                                                    },
                                                },
                                                model: {
                                                  value: _vm.assignedTechs,
                                                  callback: function ($$v) {
                                                    _vm.assignedTechs = $$v
                                                  },
                                                  expression: "assignedTechs",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { md: "5" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-3",
                                            attrs: {
                                              chips: "",
                                              items: _vm.template,
                                              "item-text": "name",
                                              "item-value": "id",
                                              label: "Select Template",
                                              outlined: "",
                                            },
                                            model: {
                                              value: _vm.templateobj.templateId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.templateobj,
                                                  "templateId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "templateobj.templateId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticStyle: { "padding-top": "4%" },
                                          attrs: { md: "2" },
                                        },
                                        [
                                          _vm.assignedTechs.length > 0 &&
                                          _vm.templateobj.templateId != null
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass: "assign-btn",
                                                  attrs: { color: "#4B5568" },
                                                  on: {
                                                    click: _vm.assignTechs,
                                                  },
                                                },
                                                [_vm._v(" Assign")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", [
                            _c("p", { staticClass: "table-title ml-10" }, [
                              _vm._v("Assigned Technicians"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "assigned-tech-table" },
                              [
                                _c("v-data-table", {
                                  attrs: {
                                    headers: _vm.techHeaders,
                                    items: _vm.workOrderTech,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "item.firstName",
                                        fn: function ({ item }) {
                                          return [
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(item.firstName) +
                                                  "   " +
                                                  _vm._s(item.lastName)
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                      {
                                        key: "item.hourlyRate",
                                        fn: function (props) {
                                          return [
                                            _c(
                                              "v-edit-dialog",
                                              {
                                                attrs: {
                                                  "return-value":
                                                    props.item.hourlyRate,
                                                  persistent: "",
                                                  large: "",
                                                },
                                                on: {
                                                  "update:returnValue":
                                                    function ($event) {
                                                      return _vm.$set(
                                                        props.item,
                                                        "hourlyRate",
                                                        $event
                                                      )
                                                    },
                                                  "update:return-value":
                                                    function ($event) {
                                                      return _vm.$set(
                                                        props.item,
                                                        "hourlyRate",
                                                        $event
                                                      )
                                                    },
                                                  save: function ($event) {
                                                    return _vm.save(props.item)
                                                  },
                                                  cancel: _vm.cancel,
                                                  open: _vm.open,
                                                  close: _vm.close,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "input",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mt-4 text-h6",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Update Hourly Rate"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              label:
                                                                "hourly Rate",
                                                              "single-line": "",
                                                              counter: "",
                                                              autofocus: "",
                                                            },
                                                            model: {
                                                              value:
                                                                props.item
                                                                  .hourlyRate,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    props.item,
                                                                    "hourlyRate",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "props.item.hourlyRate",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.item.hourlyRate
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "item.technicianPhones",
                                        fn: function ({ item }) {
                                          return _vm._l(
                                            item.technicianPhones,
                                            function (phone) {
                                              return _c(
                                                "p",
                                                { key: phone.id },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        phone.phoneNumber
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          )
                                        },
                                      },
                                      {
                                        key: "item.actions",
                                        fn: function ({ item }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "ma-2",
                                                attrs: {
                                                  color: "primary",
                                                  dark: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.createTimeSheet(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(" Create TimeSheet "),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      dark: "",
                                                      right: "",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " mdi-checkbox-marked-circle "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "ma-2",
                                                attrs: {
                                                  color: "red",
                                                  dark: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeThisTech(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(" Remove "),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      dark: "",
                                                      right: "",
                                                    },
                                                  },
                                                  [_vm._v(" mdi-delete ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1231652484
                                  ),
                                }),
                                _c(
                                  "v-snackbar",
                                  {
                                    attrs: {
                                      timeout: 3000,
                                      color: _vm.snackColor,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "action",
                                          fn: function ({ attrs }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._b(
                                                  {
                                                    attrs: { text: "" },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.snack = false
                                                      },
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                [_vm._v(" Close ")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3090489768
                                    ),
                                    model: {
                                      value: _vm.snack,
                                      callback: function ($$v) {
                                        _vm.snack = $$v
                                      },
                                      expression: "snack",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.snackText) + " ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "tab1-card" },
                        [
                          _c("v-row", { staticClass: "pl-10" }, [
                            _c("div", { staticStyle: { width: "100%" } }, [
                              _c("p", { staticClass: "table-title ml-10" }, [
                                _vm._v("Assigned Technicians"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "assigned-tech-table" },
                                [
                                  _c("v-data-table", {
                                    attrs: {
                                      headers: _vm.techHeaders2,
                                      items: _vm.workOrderTech2,
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "item.firstName",
                                        fn: function ({ item }) {
                                          return [
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(item.firstName) +
                                                  "   " +
                                                  _vm._s(item.lastName)
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                      {
                                        key: "item.technicianPhones",
                                        fn: function ({ item }) {
                                          return _vm._l(
                                            item.technicianPhones,
                                            function (phone) {
                                              return _c(
                                                "p",
                                                { key: phone.id },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        phone.phoneNumber
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          )
                                        },
                                      },
                                    ]),
                                  }),
                                  _c(
                                    "v-snackbar",
                                    {
                                      attrs: {
                                        timeout: 3000,
                                        color: _vm.snackColor,
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "action",
                                          fn: function ({ attrs }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._b(
                                                  {
                                                    attrs: { text: "" },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.snack = false
                                                      },
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                [_vm._v(" Close ")]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                      model: {
                                        value: _vm.snack,
                                        callback: function ($$v) {
                                          _vm.snack = $$v
                                        },
                                        expression: "snack",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.snackText) + " ")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                  _c(
                    "v-dialog",
                    {
                      attrs: { "max-width": "500px" },
                      model: {
                        value: _vm.dialogDelete,
                        callback: function ($$v) {
                          _vm.dialogDelete = $$v
                        },
                        expression: "dialogDelete",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-title", { staticClass: "text-h5" }, [
                            _vm._v(
                              "Are you sure you want to delete this item?"
                            ),
                          ]),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "blue darken-1", text: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.dialogDelete = false
                                    },
                                  },
                                },
                                [_vm._v("Cancel")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "blue darken-1", text: "" },
                                  on: { click: _vm.deleteItemConfirm },
                                },
                                [_vm._v("OK")]
                              ),
                              _c("v-spacer"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tab-item",
            { key: "k2" },
            [
              _c("v-card", { attrs: { flat: "" } }, [
                _c(
                  "div",
                  { staticClass: "tab1-card" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "pl-10" },
                      [
                        _c(
                          "v-col",
                          { attrs: { md: "6" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c("v-col", { attrs: { md: "7" } }, [
                                  _c("input", {
                                    staticClass: "file-feild",
                                    attrs: {
                                      id: "FileInput",
                                      accept:
                                        ".pdf, .doc, .docx, .txt,.gif, .jpg, .jpeg, .png",
                                      type: "file",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.onUploadDoc($event)
                                      },
                                    },
                                  }),
                                ]),
                                _c(
                                  "v-col",
                                  { attrs: { md: "3" } },
                                  [
                                    _vm.fileToUpload != null
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "assign-btn",
                                            attrs: { color: "#4B5568" },
                                            on: { click: _vm.uploadDoc },
                                          },
                                          [_vm._v("Upload ")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-divider", { staticClass: "mb-5" }),
                    _c(
                      "div",
                      { staticClass: "tab2-details" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { md: "4" } },
                              [
                                _c("p", { staticClass: "tab2-item" }, [
                                  _vm._v("Document"),
                                ]),
                                _vm._l(_vm.docs, function (doc) {
                                  return _c("p", { key: doc.guidFileName }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          target: "_blank",
                                          href: doc.filePath,
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(doc.fileName) + " ")]
                                    ),
                                  ])
                                }),
                              ],
                              2
                            ),
                            _c(
                              "v-col",
                              { attrs: { md: "3" } },
                              [
                                _c("p", { staticClass: "tab2-item" }, [
                                  _vm._v("Uploaded on"),
                                ]),
                                _vm._l(_vm.docs, function (doc) {
                                  return _c("p", { key: doc.guidFileName }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          new Date(doc.created).toLocaleString()
                                        ) +
                                        " "
                                    ),
                                  ])
                                }),
                              ],
                              2
                            ),
                            _c(
                              "v-col",
                              { attrs: { md: "3" } },
                              [
                                _c("p", { staticClass: "tab2-item" }, [
                                  _vm._v("Uploaded by"),
                                ]),
                                _vm._l(_vm.docs, function (doc) {
                                  return _c("p", { key: doc.guidFileName }, [
                                    _vm._v(" " + _vm._s(doc.createdBy) + " "),
                                  ])
                                }),
                              ],
                              2
                            ),
                            _c(
                              "v-col",
                              { attrs: { md: "2" } },
                              [
                                _c("p", { staticClass: "tab2-item" }, [
                                  _vm._v("Actions"),
                                ]),
                                _vm._l(_vm.docs, function (doc) {
                                  return _c(
                                    "p",
                                    { key: doc.filePath },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "delete-icon ml-4",
                                          attrs: { small: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeThisDoc(doc)
                                            },
                                          },
                                        },
                                        [_vm._v(" mdi-delete ")]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-dialog",
                      {
                        attrs: { "max-width": "600px" },
                        model: {
                          value: _vm.deleteDocDialog,
                          callback: function ($$v) {
                            _vm.deleteDocDialog = $$v
                          },
                          expression: "deleteDocDialog",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c("v-card-title", { staticClass: "text-h5" }, [
                              _vm._v(
                                "Are you sure you want to delete this Document?"
                              ),
                            ]),
                            _c(
                              "v-card-actions",
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "blue darken-1", text: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.deleteDocDialog = false
                                      },
                                    },
                                  },
                                  [_vm._v("Cancel")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "blue darken-1", text: "" },
                                    on: { click: _vm.confirmDeleteDoc },
                                  },
                                  [_vm._v("OK")]
                                ),
                                _c("v-spacer"),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "700px" },
              model: {
                value: _vm.contactDialog,
                callback: function ($$v) {
                  _vm.contactDialog = $$v
                },
                expression: "contactDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "text-h5" }, [
                    _vm._v("Contacts"),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-autocomplete", {
                        staticClass: "mb-3",
                        attrs: {
                          items: _vm.onSiteContacts,
                          "item-text": (item) => item.firstName,
                          "item-value": "id",
                          label: "On-Site Primary Contact",
                          outlined: "",
                        },
                        on: { change: _vm.updated },
                        model: {
                          value: _vm.workOrder.onSitePrimaryContact,
                          callback: function ($$v) {
                            _vm.$set(_vm.workOrder, "onSitePrimaryContact", $$v)
                          },
                          expression: "workOrder.onSitePrimaryContact",
                        },
                      }),
                      _c("v-autocomplete", {
                        staticClass: "mb-3",
                        attrs: {
                          items: _vm.onSiteContacts,
                          "item-text": (item) => item.firstName,
                          "item-value": "id",
                          label: "On-Site SecondaryContact",
                          outlined: "",
                        },
                        on: { change: _vm.updated },
                        model: {
                          value: _vm.workOrder.onSiteSecondaryContact,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.workOrder,
                              "onSiteSecondaryContact",
                              $$v
                            )
                          },
                          expression: "workOrder.onSiteSecondaryContact",
                        },
                      }),
                      _c("v-autocomplete", {
                        staticClass: "mt-5 mb-3",
                        attrs: {
                          items: _vm.techContacts,
                          "item-text": (item) => item.firstName,
                          "item-value": "id",
                          label: "Technical Contact",
                          outlined: "",
                        },
                        on: { change: _vm.updated },
                        model: {
                          value: _vm.workOrder.technicalContact,
                          callback: function ($$v) {
                            _vm.$set(_vm.workOrder, "technicalContact", $$v)
                          },
                          expression: "workOrder.technicalContact",
                        },
                      }),
                      _c("v-autocomplete", {
                        staticClass: "mt-5 mb-3",
                        attrs: {
                          items: _vm.AccountManagerContacts,
                          "item-text": (item) => item.firstName,
                          "item-value": "id",
                          label: "Account Manager",
                          outlined: "",
                        },
                        on: { change: _vm.updated },
                        model: {
                          value: _vm.workOrder.accountManagerContact,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.workOrder,
                              "accountManagerContact",
                              $$v
                            )
                          },
                          expression: "workOrder.accountManagerContact",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.contactDialog = false
                            },
                          },
                        },
                        [_vm._v("OK")]
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tab-item",
            { key: "k3" },
            [
              _c("v-card", { attrs: { flat: "" } }, [
                _c(
                  "div",
                  { staticClass: "tab1-card" },
                  [
                    _c("v-card-text", [
                      _vm.notes.length != 0
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                overflow: "scroll",
                                height: "340px",
                                "overflow-x": "hidden",
                              },
                            },
                            _vm._l(_vm.notes, function (note, index) {
                              return _c(
                                "div",
                                {
                                  key: index + "N",
                                  staticClass: "upload-section note-box",
                                },
                                [
                                  _c(
                                    "v-avatar",
                                    {
                                      staticStyle: {
                                        height: "38px",
                                        "min-width": "38px",
                                        width: "38px",
                                      },
                                      attrs: { color: "#172450" },
                                    },
                                    [
                                      note.createdBy != null
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "white--text text-h5",
                                              staticStyle: {
                                                "font-weight": "60px",
                                                "font-size": "1rem !important",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    note.createdBy
                                                      .charAt(0)
                                                      .toUpperCase()
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticClass:
                                                "white--text text-h5",
                                              staticStyle: {
                                                "font-weight": "60px",
                                                "font-size": "1rem !important",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(note.createdBy) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                    ]
                                  ),
                                  note.createdBy != null
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            "margin-top": "0%",
                                            "margin-left": "1%",
                                            "font-size": "15px",
                                            "font-weight": "600",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                note.createdBy.toUpperCase()
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "2% !important",
                                                "font-size": "12px",
                                                color: "#8f8b8b",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .convertUTCDateToLocalDate(
                                                        new Date(note.created)
                                                      )
                                                      .toLocaleString()
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "13px",
                                                        "margin-left": "1%",
                                                        "margin-bottom": "1%",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.editThisNote(
                                                            note
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" far fa-edit ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "normal",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(note.text) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    float: "right",
                                                    color: "#f44336",
                                                    "margin-top": "1%",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeThisNote(
                                                        note.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" mdi-delete ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            "margin-top": "0%",
                                            "margin-left": "1%",
                                            "font-size": "15px",
                                            "font-weight": "600",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(note.createdBy) + " "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "2% !important",
                                                "font-size": "12px",
                                                color: "#8f8b8b",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .convertUTCDateToLocalDate(
                                                        new Date(note.created)
                                                      )
                                                      .toLocaleString()
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "13px",
                                                        "margin-left": "1%",
                                                        "margin-bottom": "1%",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.editThisNote(
                                                            note
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" far fa-edit ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "normal",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(note.text) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    float: "right",
                                                    color: "#f44336",
                                                    "margin-top": "1%",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeThisNote(
                                                        note.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" mdi-delete ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "center",
                                color: "#837878",
                              },
                            },
                            [_vm._v(" No Data Available ")]
                          ),
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "2%" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "1" } },
                                [
                                  _vm.note.workOrderNoteTypeId == 2
                                    ? _c(
                                        "v-chip",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "15%",
                                          },
                                          attrs: {
                                            color: "red",
                                            "text-color": "white",
                                          },
                                        },
                                        [_vm._v(" Private ")]
                                      )
                                    : _vm._e(),
                                  _vm.note.workOrderNoteTypeId == 1
                                    ? _c(
                                        "v-chip",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "15%",
                                          },
                                          attrs: {
                                            color: "green",
                                            "text-color": "white",
                                          },
                                        },
                                        [_vm._v(" Public ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { md: "4" } },
                                [
                                  _vm.note.workOrderNoteTypeId == 2
                                    ? _c("v-select", {
                                        attrs: {
                                          label: "Share with",
                                          solo: "",
                                          items: _vm.shareWithList,
                                          "item-text": "name",
                                          "item-value": "id",
                                        },
                                        model: {
                                          value: _vm.note.noteAccess[0].typeId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.note.noteAccess[0],
                                              "typeId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "note.noteAccess[0].typeId",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { md: "4" } },
                                [
                                  _vm.note.noteAccess[0].typeId == 3
                                    ? _c("v-text-field", {
                                        attrs: {
                                          label: "Type User Email",
                                          solo: "",
                                          type: "email",
                                        },
                                        model: {
                                          value:
                                            _vm.note.noteAccess[0].typeValue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.note.noteAccess[0],
                                              "typeValue",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "note.noteAccess[0].typeValue",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.note.text,
                                expression: "note.text",
                              },
                            ],
                            staticClass: "textarea-note-box",
                            staticStyle: {
                              padding: "10px 10px 10px 2%",
                              border: "1px solid lightgray",
                              "border-radius": "14px",
                            },
                            attrs: { placeholder: "Write Your Note" },
                            domProps: { value: _vm.note.text },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(_vm.note, "text", $event.target.value)
                              },
                            },
                          }),
                          _c(
                            "div",
                            { staticStyle: { height: "50px" } },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticStyle: {
                                    float: "right",
                                    "margin-top": "2%",
                                    "margin-right": "2%",
                                  },
                                  on: { click: _vm.addNote },
                                },
                                [_vm._v("fa-solid fa-arrow-right ")]
                              ),
                              _c(
                                "v-icon",
                                {
                                  staticStyle: {
                                    "margin-top": "2%",
                                    "margin-right": "3%",
                                    "margin-left": "2%",
                                  },
                                  on: { click: _vm.onchangetoprivate },
                                },
                                [_vm._v("fa-solid fa-unlock ")]
                              ),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    width: "5%",
                                    "text-align": "center",
                                    "margin-top": "0%",
                                    "margin-left": "3px",
                                  },
                                },
                                [_vm._v(" Public ")]
                              ),
                              _c("span"),
                              _c(
                                "v-icon",
                                {
                                  staticStyle: {
                                    "margin-top": "-120px",
                                    "margin-left": "128px",
                                  },
                                  on: { click: _vm.onchangetopublic },
                                },
                                [_vm._v("fa-solid fa-lock ")]
                              ),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    width: "5%",
                                    "text-align": "center",
                                    "margin-top": "-59px",
                                    "margin-left": "98px",
                                  },
                                },
                                [_vm._v(" Private ")]
                              ),
                            ],
                            1
                          ),
                          _c("br"),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "btn-container" }),
                    ]),
                    _c("v-spacer"),
                    _c(
                      "v-dialog",
                      {
                        attrs: { "max-width": "600px" },
                        model: {
                          value: _vm.editeNote,
                          callback: function ($$v) {
                            _vm.editeNote = $$v
                          },
                          expression: "editeNote",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c("v-card-title", { staticClass: "text-h5" }, [
                              _vm._v("Note Text"),
                            ]),
                            _c(
                              "v-card-text",
                              [
                                _c("v-textarea", {
                                  attrs: { solo: "" },
                                  model: {
                                    value: _vm.editedNoteObj.text,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.editedNoteObj, "text", $$v)
                                    },
                                    expression: "editedNoteObj.text",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-card-actions",
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "blue darken-1", text: "" },
                                    on: { click: _vm.EditeCancelNote },
                                  },
                                  [_vm._v("Cancel")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "blue darken-1", text: "" },
                                    on: { click: _vm.EditeConfirmedNote },
                                  },
                                  [_vm._v("OK")]
                                ),
                                _c("v-spacer"),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-dialog",
                      {
                        attrs: { "max-width": "600px" },
                        model: {
                          value: _vm.deleteNoteDialog,
                          callback: function ($$v) {
                            _vm.deleteNoteDialog = $$v
                          },
                          expression: "deleteNoteDialog",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c("v-card-title", { staticClass: "text-h5" }, [
                              _vm._v(
                                "Are you sure you want to delete this note?"
                              ),
                            ]),
                            _c(
                              "v-card-actions",
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "blue darken-1", text: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.deleteNoteDialog = false
                                      },
                                    },
                                  },
                                  [_vm._v("Cancel")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "blue darken-1", text: "" },
                                    on: { click: _vm.confirmDeleteNote },
                                  },
                                  [_vm._v("OK")]
                                ),
                                _c("v-spacer"),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-tab-item",
            { key: "k4" },
            [
              _c("v-card", { attrs: { flat: "" } }, [
                _c("div", { staticClass: "tab1-card" }, [
                  _c(
                    "div",
                    { staticClass: "tab4-logs" },
                    [
                      _c("v-data-table", {
                        staticClass: "elevation-1",
                        attrs: {
                          headers: _vm.headers,
                          items: _vm.statusLogs,
                          "hide-default-footer": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item.created",
                            fn: function ({ item }) {
                              return [
                                _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm
                                          .convertUTCDateToLocalDate(
                                            new Date(item.created)
                                          )
                                          .toLocaleString()
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-tab-item",
            { key: "k5" },
            [
              _c("v-card", { attrs: { flat: "" } }, [
                _c(
                  "div",
                  { staticClass: "tab1-card" },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticStyle: { "margin-left": "3%" },
                            attrs: { cols: "12", md: "1" },
                          },
                          [
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "margin-top": "-1%",
                                  "font-size": "13px",
                                  "margin-bottom": "0%",
                                },
                              },
                              [_vm._v(" Documents ")]
                            ),
                            _c(
                              "v-col",
                              {
                                staticStyle: { "margin-top": "-11%" },
                                attrs: { cols: "3", md: "1" },
                              },
                              [
                                _vm.actionTabValue == 7
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticStyle: {
                                              "font-size": "40px",
                                              color: "#172450",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeTab(7)
                                              },
                                            },
                                          },
                                          [_vm._v("fa-solid fa-file")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticStyle: {
                                              "font-size": "40px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeTab(7)
                                              },
                                            },
                                          },
                                          [_vm._v("fa-solid fa-file")]
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "margin-top": "25%",
                                  "font-size": "13px",
                                  "margin-bottom": "0%",
                                },
                              },
                              [_vm._v(" WorkOrders ")]
                            ),
                            _c(
                              "v-col",
                              {
                                staticStyle: { "margin-top": "-11%" },
                                attrs: { cols: "3", md: "1" },
                              },
                              [
                                _vm.actionTabValue == 5
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticStyle: {
                                              "font-size": "40px",
                                              color: "#172450",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeTab(5)
                                              },
                                            },
                                          },
                                          [_vm._v("fa-solid fa-calendar")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticStyle: {
                                              "font-size": "40px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeTab(5)
                                              },
                                            },
                                          },
                                          [_vm._v("fa-solid fa-calendar")]
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "margin-top": "25%",
                                  "font-size": "13px",
                                  "margin-bottom": "0%",
                                },
                              },
                              [_vm._v(" Technicians ")]
                            ),
                            _c(
                              "v-col",
                              {
                                staticStyle: { "margin-top": "-11%" },
                                attrs: { cols: "3", md: "1" },
                              },
                              [
                                _vm.actionTabValue == 4
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticStyle: {
                                              "font-size": "40px",
                                              color: "#172450",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeTab(4)
                                              },
                                            },
                                          },
                                          [_vm._v("fa-solid fa-id-badge")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticStyle: {
                                              "font-size": "40px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeTab(4)
                                              },
                                            },
                                          },
                                          [_vm._v("fa-solid fa-id-badge")]
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "margin-top": "25%",
                                  "margin-left": "20%",
                                  "font-size": "13px",
                                  "margin-bottom": "0%",
                                },
                              },
                              [_vm._v(" Notes ")]
                            ),
                            _c(
                              "v-col",
                              {
                                staticStyle: { "margin-top": "-11%" },
                                attrs: { cols: "3", md: "1" },
                              },
                              [
                                _vm.actionTabValue == 6
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticStyle: {
                                              "font-size": "40px",
                                              color: "#172450",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeTab(6)
                                              },
                                            },
                                          },
                                          [_vm._v("fa-duotone fa-comment")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticStyle: {
                                              "font-size": "40px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeTab(6)
                                              },
                                            },
                                          },
                                          [_vm._v("fa-duotone fa-comment")]
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("v-col", { attrs: { md: "10" } }, [
                          _vm.actionTabValue == 4
                            ? _c(
                                "div",
                                {
                                  staticClass: "tab4-logs",
                                  staticStyle: { width: "98%" },
                                },
                                [
                                  _c("v-data-table", {
                                    attrs: {
                                      headers: _vm.technicianlogHeaders,
                                      items: _vm.technicianlogData,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item.auditDate",
                                          fn: function ({ item }) {
                                            return [
                                              _c("p", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm
                                                        .convertUTCDateToLocalDate(
                                                          new Date(
                                                            item.auditDate
                                                          )
                                                        )
                                                        .toLocaleString()
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      640618794
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.actionTabValue == 5
                            ? _c(
                                "div",
                                {
                                  staticClass: "tab4-logs",
                                  staticStyle: { width: "98%" },
                                },
                                [
                                  _c("v-data-table", {
                                    attrs: {
                                      headers: _vm.WorkorderlogHeaders,
                                      items: _vm.workOrderActionLogData,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item.auditDate",
                                          fn: function ({ item }) {
                                            return [
                                              _c("p", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm
                                                        .convertUTCDateToLocalDate(
                                                          new Date(
                                                            item.auditDate
                                                          )
                                                        )
                                                        .toLocaleString()
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.changedDataObj.billRate.expenseAmount",
                                          fn: function ({ item }) {
                                            return [
                                              item.changedDataObj != null
                                                ? _c("p", [
                                                    item.changedDataObj
                                                      .billRate != null &&
                                                    item.oldDataObj.billRate
                                                      .expenseAmount !=
                                                      item.changedDataObj
                                                        .billRate.expenseAmount
                                                      ? _c("span", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "text-decoration":
                                                                  "line-through",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Old :" +
                                                                  _vm._s(
                                                                    item
                                                                      .oldDataObj
                                                                      .billRate
                                                                      .expenseAmount
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("br"),
                                                          _c("span", [
                                                            _vm._v(
                                                              " New :" +
                                                                _vm._s(
                                                                  item
                                                                    .changedDataObj
                                                                    .billRate
                                                                    .expenseAmount
                                                                )
                                                            ),
                                                          ]),
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(" No Change "),
                                                        ]),
                                                  ])
                                                : _c("p", [
                                                    _vm._v("No Change"),
                                                  ]),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.changedDataObj.orderId",
                                          fn: function ({ item }) {
                                            return [
                                              item.changedDataObj != null
                                                ? _c("p", [
                                                    item.changedDataObj
                                                      .orderId != null
                                                      ? _c("span", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "text-decoration":
                                                                  "line-through",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Old :" +
                                                                  _vm._s(
                                                                    item
                                                                      .oldDataObj
                                                                      .orderId
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("br"),
                                                          _c("span", [
                                                            _vm._v(
                                                              " New :" +
                                                                _vm._s(
                                                                  item
                                                                    .changedDataObj
                                                                    .orderId
                                                                )
                                                            ),
                                                          ]),
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(" No Change "),
                                                        ]),
                                                  ])
                                                : _c("p", [
                                                    _vm._v("No Change"),
                                                  ]),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.changedDataObj.orderType",
                                          fn: function ({ item }) {
                                            return [
                                              item.changedDataObj != null
                                                ? _c("p", [
                                                    item.changedDataObj
                                                      .orderType != null
                                                      ? _c("span", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "text-decoration":
                                                                  "line-through",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Old :" +
                                                                  _vm._s(
                                                                    item
                                                                      .oldDataObj
                                                                      .orderType
                                                                      .name
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("br"),
                                                          _c("span", [
                                                            _vm._v(
                                                              " New :" +
                                                                _vm._s(
                                                                  item
                                                                    .changedDataObj
                                                                    .orderType
                                                                    .name
                                                                )
                                                            ),
                                                          ]),
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(" No Change "),
                                                        ]),
                                                  ])
                                                : _c("p", [
                                                    _vm._v("No Change"),
                                                  ]),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.changedDataObj.paymentMethod",
                                          fn: function ({ item }) {
                                            return [
                                              item.changedDataObj != null
                                                ? _c("p", [
                                                    item.changedDataObj
                                                      .paymentMethod != null
                                                      ? _c("span", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "text-decoration":
                                                                  "line-through",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Old :" +
                                                                  _vm._s(
                                                                    item
                                                                      .oldDataObj
                                                                      .paymentMethod
                                                                      .name
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("br"),
                                                          _c("span", [
                                                            _vm._v(
                                                              " New :" +
                                                                _vm._s(
                                                                  item
                                                                    .changedDataObj
                                                                    .paymentMethod
                                                                    .name
                                                                )
                                                            ),
                                                          ]),
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(" No Change "),
                                                        ]),
                                                  ])
                                                : _c("p", [
                                                    _vm._v("No Change"),
                                                  ]),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.changedDataObj.problemType",
                                          fn: function ({ item }) {
                                            return [
                                              item.changedDataObj != null
                                                ? _c("p", [
                                                    item.changedDataObj
                                                      .problemType != null
                                                      ? _c("span", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "text-decoration":
                                                                  "line-through",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Old :" +
                                                                  _vm._s(
                                                                    item
                                                                      .oldDataObj
                                                                      .problemType
                                                                      .name
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("br"),
                                                          _c("span", [
                                                            _vm._v(
                                                              " New :" +
                                                                _vm._s(
                                                                  item
                                                                    .changedDataObj
                                                                    .problemType
                                                                    .name
                                                                )
                                                            ),
                                                          ]),
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(" No Change "),
                                                        ]),
                                                  ])
                                                : _c("p", [
                                                    _vm._v("No Change"),
                                                  ]),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.changedDataObj.branchName",
                                          fn: function ({ item }) {
                                            return [
                                              item.changedDataObj != null
                                                ? _c("p", [
                                                    item.changedDataObj
                                                      .branchName != null
                                                      ? _c("span", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "text-decoration":
                                                                  "line-through",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Old :" +
                                                                  _vm._s(
                                                                    item
                                                                      .oldDataObj
                                                                      .branchName
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("br"),
                                                          _c("span", [
                                                            _vm._v(
                                                              " New :" +
                                                                _vm._s(
                                                                  item
                                                                    .changedDataObj
                                                                    .branchName
                                                                )
                                                            ),
                                                          ]),
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(" No Change "),
                                                        ]),
                                                  ])
                                                : _c("p", [
                                                    _vm._v("No Change"),
                                                  ]),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.changedDataObj.scopeOfWork",
                                          fn: function ({ item }) {
                                            return [
                                              item.changedDataObj != null
                                                ? _c("p", [
                                                    item.changedDataObj
                                                      .scopeOfWork != null
                                                      ? _c("span", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "text-decoration":
                                                                  "line-through",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Old :" +
                                                                  _vm._s(
                                                                    item
                                                                      .oldDataObj
                                                                      .scopeOfWork
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("br"),
                                                          _c("span", [
                                                            _vm._v(
                                                              " New :" +
                                                                _vm._s(
                                                                  item
                                                                    .changedDataObj
                                                                    .scopeOfWork
                                                                )
                                                            ),
                                                          ]),
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(" No Change "),
                                                        ]),
                                                  ])
                                                : _c("p", [
                                                    _vm._v("No Change"),
                                                  ]),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.changedDataObj.workOrderTechnicianLevels",
                                          fn: function ({ item }) {
                                            return [
                                              item.changedDataObj != null
                                                ? _c("p", [
                                                    item.changedDataObj
                                                      .workOrderTechnicianLevels !=
                                                      null &&
                                                    item.changedDataObj
                                                      .workOrderTechnicianLevels
                                                      .length > 0
                                                      ? _c("span", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "text-decoration":
                                                                  "line-through",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Old Number Of Technician:" +
                                                                  _vm._s(
                                                                    item
                                                                      .oldDataObj
                                                                      .workOrderTechnicianLevels[0]
                                                                      .numberOfTechnician
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("br"),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "text-decoration":
                                                                  "line-through",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Old Level:" +
                                                                  _vm._s(
                                                                    item
                                                                      .oldDataObj
                                                                      .workOrderTechnicianLevels[0]
                                                                      .technicianLevel
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("br"),
                                                          _c("span", [
                                                            _vm._v(
                                                              " New Number Of Technician:" +
                                                                _vm._s(
                                                                  item
                                                                    .changedDataObj
                                                                    .workOrderTechnicianLevels[0]
                                                                    .numberOfTechnician
                                                                )
                                                            ),
                                                          ]),
                                                          _c("br"),
                                                          _c("span", [
                                                            _vm._v(
                                                              " New Level:" +
                                                                _vm._s(
                                                                  item
                                                                    .changedDataObj
                                                                    .workOrderTechnicianLevels[0]
                                                                    .technicianLevel
                                                                )
                                                            ),
                                                          ]),
                                                          _c("br"),
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(" No Change "),
                                                        ]),
                                                  ])
                                                : _c("p", [
                                                    _vm._v("No Change"),
                                                  ]),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.changedDataObj.serviceDateFromDate",
                                          fn: function ({ item }) {
                                            return [
                                              item.changedDataObj != null
                                                ? _c("p", [
                                                    item.changedDataObj
                                                      .serviceDateFromDate !=
                                                      null &&
                                                    item.changedDataObj
                                                      .serviceDateFromDate
                                                      ? _c(
                                                          "span",
                                                          [
                                                            _vm._l(
                                                              item.oldDataObj
                                                                .serviceDateFromDate,
                                                              function (
                                                                dateService,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "span",
                                                                  {
                                                                    key: index,
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "text-decoration":
                                                                              "line-through",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Old Date From:" +
                                                                            _vm._s(
                                                                              _vm
                                                                                .convertUTCDateToLocalDate(
                                                                                  new Date(
                                                                                    dateService.serviceDateFromDate
                                                                                  )
                                                                                )
                                                                                .toLocaleString()
                                                                            )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "text-decoration":
                                                                              "line-through",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " To:" +
                                                                            _vm._s(
                                                                              _vm
                                                                                .convertUTCDateToLocalDate(
                                                                                  new Date(
                                                                                    dateService.serviceDateToDate
                                                                                  )
                                                                                )
                                                                                .toLocaleString()
                                                                            )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c("br"),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            _vm._l(
                                                              item
                                                                .changedDataObj
                                                                .serviceDateFromDate,
                                                              function (
                                                                dateService,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "span",
                                                                  {
                                                                    key: index,
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        " New Date From:" +
                                                                          _vm._s(
                                                                            _vm
                                                                              .convertUTCDateToLocalDate(
                                                                                new Date(
                                                                                  dateService.serviceDateFromDate
                                                                                )
                                                                              )
                                                                              .toLocaleString()
                                                                          )
                                                                      ),
                                                                    ]),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        " To:" +
                                                                          _vm._s(
                                                                            _vm
                                                                              .convertUTCDateToLocalDate(
                                                                                new Date(
                                                                                  dateService.serviceDateToDate
                                                                                )
                                                                              )
                                                                              .toLocaleString()
                                                                          )
                                                                      ),
                                                                    ]),
                                                                    _c("br"),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _c("span", [
                                                          _vm._v(" No Change "),
                                                        ]),
                                                  ])
                                                : _c("p", [
                                                    _vm._v("No Change"),
                                                  ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3832171219
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.actionTabValue == 6
                            ? _c(
                                "div",
                                {
                                  staticClass: "tab4-logs",
                                  staticStyle: { width: "98%" },
                                },
                                [
                                  _c("v-data-table", {
                                    attrs: {
                                      headers: _vm.notelogHeaders,
                                      items: _vm.NotesLogData,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item.auditDate",
                                          fn: function ({ item }) {
                                            return [
                                              _c("p", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm
                                                        .convertUTCDateToLocalDate(
                                                          new Date(
                                                            item.auditDate
                                                          )
                                                        )
                                                        .toLocaleString()
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      640618794
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.actionTabValue == 7
                            ? _c(
                                "div",
                                {
                                  staticClass: "tab4-logs",
                                  staticStyle: { width: "98%" },
                                },
                                [
                                  _c("v-data-table", {
                                    attrs: {
                                      headers: _vm.documentHeaders,
                                      items: _vm.DocumentLogData,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item.auditDate",
                                          fn: function ({ item }) {
                                            return [
                                              _c("p", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm
                                                        .convertUTCDateToLocalDate(
                                                          new Date(
                                                            item.auditDate
                                                          )
                                                        )
                                                        .toLocaleString()
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.oldDataObj",
                                          fn: function ({ item }) {
                                            return [
                                              item.oldDataObj != null
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        target: "_blank",
                                                        href: item.oldDataObj
                                                          .filePath,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.oldDataObj
                                                              .fileName
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _c("p", [
                                                    _vm._v(
                                                      "-------------------------"
                                                    ),
                                                  ]),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.newDataObj",
                                          fn: function ({ item }) {
                                            return [
                                              item.newDataObj != null
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        target: "_blank",
                                                        href: item.newDataObj
                                                          .filePath,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.newDataObj
                                                              .fileName
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _c("p", [
                                                    _vm._v(
                                                      "-------------------------"
                                                    ),
                                                  ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3668278122
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-8 ml-16" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                _c("p", [
                  _c("span", { staticClass: "table-title" }, [
                    _vm._v(_vm._s(_vm.workOrder.orderId)),
                  ]),
                ]),
              ]),
              _c("v-col", { attrs: { cols: "12", md: "4" } }),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c("v-select", {
                    staticClass: "mr-16",
                    attrs: {
                      label: "Actions",
                      solo: "",
                      items: _vm.woActions,
                      "item-text": "name",
                      "item-value": "id",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.handleAction()
                      },
                    },
                    model: {
                      value: _vm.actionId,
                      callback: function ($$v) {
                        _vm.actionId = $$v
                      },
                      expression: "actionId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.userInfo.userTypeId == 201 || _vm.userInfo.userTypeId == 202
            ? _c(
                "v-row",
                [
                  _c("v-col", { attrs: { md: "6", cols: "6" } }, [
                    _c("p", { staticClass: "label-key" }, [
                      _vm._v("Field Nation ID"),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { md: "6", cols: "6" } },
                    [
                      !_vm.editMode
                        ? _c("p", { staticClass: "value-key colored" }, [
                            _vm._v(_vm._s(_vm.workOrder.fieldNationId)),
                          ])
                        : _c("v-text-field", {
                            staticClass: "mr-3",
                            attrs: { solo: "" },
                            on: { change: _vm.updated },
                            model: {
                              value: _vm.workOrder.fieldNationId,
                              callback: function ($$v) {
                                _vm.$set(_vm.workOrder, "fieldNationId", $$v)
                              },
                              expression:
                                "\n                    workOrder.fieldNationId\n                  ",
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wo-boxes" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                _c(
                  "div",
                  { staticClass: "wo-box" },
                  [
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { md: "10", cols: "10" } }),
                        _c(
                          "v-col",
                          { attrs: { md: "2", cols: "2" } },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "edit-wo",
                                attrs: { small: "" },
                                on: { click: _vm.changeEditModeSection1 },
                              },
                              [_vm._v(" far fa-edit ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { md: "5", cols: "6" } }, [
                          _c("p", { staticClass: "label-key" }, [
                            _vm._v("Company"),
                          ]),
                        ]),
                        _c("v-col", { attrs: { md: "6", cols: "6" } }, [
                          _vm.workOrder.company
                            ? _c("p", { staticClass: "value-key colored" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.workOrder.company.name) + " "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { md: "5", cols: "6" } }, [
                          _c("p", { staticClass: "label-key" }, [
                            _vm._v("Client"),
                          ]),
                        ]),
                        _c("v-col", { attrs: { md: "7", cols: "6" } }, [
                          _vm.workOrder.client
                            ? _c("p", { staticClass: "value-key colored" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.workOrder.client.name) + ". "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { md: "5", cols: "6" } }, [
                          _c("p", { staticClass: "label-key" }, [
                            _vm._v("Created by"),
                          ]),
                        ]),
                        _c("v-col", { attrs: { md: "7", cols: "6" } }, [
                          _c("p", { staticClass: "value-key" }, [
                            _vm._v(" " + _vm._s(_vm.workOrder.createdBy) + " "),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { md: "5", cols: "6" } }, [
                          _c("p", { staticClass: "label-key" }, [
                            _vm._v("Created at"),
                          ]),
                        ]),
                        _c("v-col", { attrs: { md: "7", cols: "6" } }, [
                          _c("p", { staticClass: "value-key" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .convertUTCDateToLocalDate(
                                      new Date(_vm.workOrder.createdOn)
                                    )
                                    .toLocaleString()
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { md: "5", cols: "8" } }, [
                          _c("span", { staticClass: "label-key" }, [
                            _vm._v("Location"),
                          ]),
                        ]),
                        _vm.editModeSection1
                          ? _c(
                              "v-col",
                              { attrs: { md: "7", cols: "12" } },
                              [
                                _c("v-autocomplete", {
                                  ref: "locationSearch",
                                  staticClass: "mr-3",
                                  class: {
                                    "is-invalid":
                                      _vm.updateSubmitted &&
                                      _vm.$v.workOrder.branchId.$error,
                                  },
                                  attrs: {
                                    items: _vm.branches,
                                    "item-text": "name",
                                    "item-value": "id",
                                    label: "Select Location",
                                    outlined: "",
                                    "search-input": _vm.locationSearch,
                                  },
                                  on: {
                                    change: _vm.onchangebranch,
                                    "update:searchInput": function ($event) {
                                      _vm.locationSearch = $event
                                    },
                                    "update:search-input": function ($event) {
                                      _vm.locationSearch = $event
                                    },
                                  },
                                  model: {
                                    value: _vm.workOrder.branchId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.workOrder, "branchId", $$v)
                                    },
                                    expression: "workOrder.branchId",
                                  },
                                }),
                                _vm.updateSubmitted &&
                                !_vm.$v.workOrder.branchId.required
                                  ? _c(
                                      "p",
                                      { staticClass: "invalid-feedback mt-2" },
                                      [_vm._v(" Location is Required ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.editModeSection1
                          ? _c(
                              "v-col",
                              { attrs: { md: "7", cols: "4" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { small: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openGoogleMap(
                                          _vm.workOrder.address
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", { staticClass: "mr-1" }, [
                                      _vm._v("mdi-map-marker"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { md: "5", cols: "0" } }),
                        !_vm.editModeSection1
                          ? _c("v-col", { attrs: { md: "7", cols: "12" } }, [
                              _vm.workOrder.address
                                ? _c("span", { staticClass: "value-key" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.workOrder.address.buildingNumber
                                        ) +
                                        " " +
                                        _vm._s(_vm.workOrder.address.street) +
                                        "- "
                                    ),
                                    _vm.workOrder.address.city
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.workOrder.address.city.name
                                            ) + " -"
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.workOrder.address.state
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.workOrder.address.state.name
                                            ) + " -"
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.workOrder.address.country
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.workOrder.address.country
                                                  .name
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "wo-box" },
                  [
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { cols: "12", md: "10" } }, [
                          _c("p", { staticClass: "label-key" }, [
                            _vm._v("Service date"),
                          ]),
                        ]),
                        _c(
                          "v-col",
                          { attrs: { md: "2" } },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "edit-wo",
                                attrs: { small: "" },
                                on: { click: _vm.enableDateEditMode },
                              },
                              [_vm._v(" far fa-edit ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticStyle: { padding: "4%" },
                            attrs: { md: "12" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  height: "116px",
                                  "overflow-y": "scroll",
                                },
                              },
                              _vm._l(_vm.dates, function (date) {
                                return _c(
                                  "div",
                                  {
                                    key: date.serviceDateFromDate,
                                    staticClass: "value-key",
                                    staticStyle: {
                                      height: "116px",
                                      "overflow-y": "scroll",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _c("span", { staticClass: "toDate" }, [
                                        _vm._v(" From   "),
                                      ]),
                                      _vm.workOrder.serviceDateTypeId != 5
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  new Date(
                                                    date.serviceDateFromDate
                                                  ).toLocaleDateString() +
                                                    " " +
                                                    date.fromTime
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "toDate" },
                                              [_vm._v(" To   ")]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  new Date(
                                                    date.serviceDateToDate
                                                  ).toLocaleDateString() +
                                                    " " +
                                                    date.toTime
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  new Date(
                                                    date.serviceDateFromDate
                                                  ).toLocaleDateString() +
                                                    " " +
                                                    date.fromTime
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "toDate" },
                                              [_vm._v("To")]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  new Date(
                                                    date.serviceDateToDate
                                                  ).toLocaleDateString() +
                                                    " " +
                                                    date.toTime
                                                ) +
                                                " "
                                            ),
                                          ]),
                                    ]),
                                    _c("br"),
                                    _c("br"),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("br"),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { md: "10", cols: "10" } }),
                        _c(
                          "v-col",
                          { attrs: { md: "2", cols: "2" } },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "edit-wo",
                                attrs: { small: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.editModePaymentMethod =
                                      !_vm.editModePaymentMethod
                                  },
                                },
                              },
                              [_vm._v(" far fa-edit ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { md: "5", cols: "6" } }, [
                          _c("p", { staticClass: "label-key" }, [
                            _vm._v("Payment Method"),
                          ]),
                        ]),
                        !_vm.editModePaymentMethod
                          ? _c("v-col", { attrs: { md: "7", cols: "6" } }, [
                              _vm.workOrder.paymentMethod
                                ? _c(
                                    "p",
                                    { staticClass: "value-key colored" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.workOrder.paymentMethod.name
                                          ) +
                                          ". "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _c(
                              "v-col",
                              { attrs: { md: "7", cols: "6" } },
                              [
                                _c("v-combobox", {
                                  attrs: {
                                    items: _vm.paymentMethods,
                                    "item-text": "name",
                                    "item-value": "id",
                                    label: "Select Payment Method",
                                    "hide-selected": "",
                                    "persistent-hint": "",
                                    outlined: "",
                                  },
                                  on: { change: _vm.updatePayment },
                                  model: {
                                    value: _vm.workOrder.paymentMethod,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.workOrder,
                                        "paymentMethod",
                                        $$v
                                      )
                                    },
                                    expression: "workOrder.paymentMethod",
                                  },
                                }),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { md: "5", cols: "6" } }, [
                          _c("p", { staticClass: "label-key" }, [
                            _vm._v("Purchase Order"),
                          ]),
                        ]),
                        !_vm.editModePaymentMethod
                          ? _c("v-col", { attrs: { md: "7", cols: "6" } }, [
                              _vm.workOrder.purchaseOrder.id != 0
                                ? _c(
                                    "p",
                                    {
                                      class: [
                                        {
                                          "yellow-custom":
                                            _vm.workOrder.purchaseOrder
                                              .keyTypeId == 1,
                                        },
                                        {
                                          "red-custom":
                                            _vm.workOrder.purchaseOrder
                                              .keyTypeId == 2,
                                        },
                                        {
                                          "green-custom":
                                            _vm.workOrder.purchaseOrder
                                              .keyTypeId == 3,
                                        },
                                        {
                                          "blue-custom":
                                            _vm.workOrder.purchaseOrder
                                              .keyTypeId == 4,
                                        },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.workOrder.purchaseOrder.poId +
                                              "/ " +
                                              "$ " +
                                              _vm.workOrder.purchaseOrder
                                                .orderAmount
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c("p", { staticClass: "value-key" }, [
                                    _vm._v("-------"),
                                  ]),
                            ])
                          : _vm._e(),
                        _vm.editModePaymentMethod &&
                        _vm.workOrder.paymentMethod.id == 3
                          ? _c(
                              "v-col",
                              { attrs: { md: "7", cols: "6" } },
                              [
                                _c("v-select", {
                                  staticClass: "mb-2",
                                  attrs: {
                                    items: _vm.purchaseOrders,
                                    "item-text": (item) =>
                                      item.poId +
                                      " / " +
                                      " $  " +
                                      item.orderAmount,
                                    "item-value": "id",
                                    outlined: "",
                                    label: "Purchase order",
                                  },
                                  on: { change: _vm.updated },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "item",
                                        fn: function ({ item }) {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                class: [
                                                  {
                                                    "yellow-custom":
                                                      item.keyTypeId == 1,
                                                  },
                                                  {
                                                    "red-custom":
                                                      item.keyTypeId == 2,
                                                  },
                                                  {
                                                    "green-custom":
                                                      item.keyTypeId == 3,
                                                  },
                                                  {
                                                    "blue-custom":
                                                      item.keyTypeId == 4,
                                                  },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.poId +
                                                        "" +
                                                        " With amount " +
                                                        "" +
                                                        item.orderAmount
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    985782907
                                  ),
                                  model: {
                                    value: _vm.workOrder.purchaseOrder.id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.workOrder.purchaseOrder,
                                        "id",
                                        $$v
                                      )
                                    },
                                    expression: "workOrder.purchaseOrder.id",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-dialog",
                      {
                        attrs: { width: "900" },
                        model: {
                          value: _vm.editDateDialog,
                          callback: function ($$v) {
                            _vm.editDateDialog = $$v
                          },
                          expression: "editDateDialog",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c(
                              "v-card-title",
                              { staticClass: "text-h5 grey lighten-2" },
                              [_vm._v(" Service Date ")]
                            ),
                            _c("v-card-text", [
                              _c(
                                "div",
                                { staticClass: "nd-group" },
                                [
                                  _c(
                                    "v-radio-group",
                                    {
                                      attrs: { row: "" },
                                      model: {
                                        value: _vm.workOrder.serviceDateTypeId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.workOrder,
                                            "serviceDateTypeId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "workOrder.serviceDateTypeId",
                                      },
                                    },
                                    _vm._l(_vm.serviceDates, function (srvc) {
                                      return _c("v-radio", {
                                        key: srvc.id,
                                        staticClass: "ml-7",
                                        attrs: {
                                          color: "#707070",
                                          label: srvc.name,
                                          value: srvc.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.workOrder.serviceDateTypeId &&
                              _vm.workOrder.serviceDateTypeId == 3
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("FunctionalCalendar", {
                                                attrs: {
                                                  configs: _vm.calendarConfigs1,
                                                },
                                                model: {
                                                  value: _vm.rangeDates,
                                                  callback: function ($$v) {
                                                    _vm.rangeDates = $$v
                                                  },
                                                  expression: "rangeDates",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "3" } },
                                            [
                                              _c("vue-timepicker", {
                                                ref: "rangeFromm",
                                                staticStyle: {
                                                  height: "3.2em",
                                                },
                                                attrs: {
                                                  "manual-input": "",
                                                  "hide-dropdown": "",
                                                  label: "From Time",
                                                },
                                                model: {
                                                  value: _vm.timeRangeFrom,
                                                  callback: function ($$v) {
                                                    _vm.timeRangeFrom = $$v
                                                  },
                                                  expression: "timeRangeFrom",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c("vue-timepicker", {
                                                ref: "rangeToo",
                                                staticStyle: {
                                                  height: "3.2em",
                                                },
                                                attrs: {
                                                  "manual-input": "",
                                                  "hide-dropdown": "",
                                                  label: "To Time",
                                                },
                                                model: {
                                                  value: _vm.timeRangeTo,
                                                  callback: function ($$v) {
                                                    _vm.timeRangeTo = $$v
                                                  },
                                                  expression: "timeRangeTo",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              label: "Long Term",
                                              color: "#4B5568",
                                            },
                                            model: {
                                              value: _vm.isLongTerm,
                                              callback: function ($$v) {
                                                _vm.isLongTerm = $$v
                                              },
                                              expression: "isLongTerm",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.workOrder.serviceDateTypeId &&
                              _vm.workOrder.serviceDateTypeId == 2
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  ref: "menu",
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    "return-value": _vm.date,
                                                    transition:
                                                      "scale-transition",
                                                    "offset-y": "",
                                                    "min-width": "300",
                                                  },
                                                  on: {
                                                    "update:returnValue":
                                                      function ($event) {
                                                        _vm.date = $event
                                                      },
                                                    "update:return-value":
                                                      function ($event) {
                                                        _vm.date = $event
                                                      },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-text-field",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "Start Date",
                                                                      "prepend-inner-icon":
                                                                        "mdi-calendar",
                                                                      readonly:
                                                                        "",
                                                                      outlined:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.date,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.date =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "date",
                                                                    },
                                                                  },
                                                                  "v-text-field",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              )
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    4225183735
                                                  ),
                                                  model: {
                                                    value: _vm.menu,
                                                    callback: function ($$v) {
                                                      _vm.menu = $$v
                                                    },
                                                    expression: "menu",
                                                  },
                                                },
                                                [
                                                  _c("v-date-picker", {
                                                    attrs: {
                                                      "no-title": "",
                                                      scrollable: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.$refs.menu.save(
                                                          _vm.date
                                                        )
                                                      },
                                                      click: function ($event) {
                                                        _vm.menu = false
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.date,
                                                      callback: function ($$v) {
                                                        _vm.date = $$v
                                                      },
                                                      expression: "date",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "3" } },
                                            [
                                              _c("vue-timepicker", {
                                                staticStyle: {
                                                  height: "3.2em",
                                                },
                                                attrs: {
                                                  "manual-input": "",
                                                  "hide-dropdown": "",
                                                },
                                                model: {
                                                  value: _vm.fixedFrom,
                                                  callback: function ($$v) {
                                                    _vm.fixedFrom = $$v
                                                  },
                                                  expression: "fixedFrom",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "3" } },
                                            [
                                              _c("vue-timepicker", {
                                                staticStyle: {
                                                  height: "3.2em",
                                                },
                                                attrs: {
                                                  "manual-input": "",
                                                  "hide-dropdown": "",
                                                },
                                                model: {
                                                  value: _vm.fixedToo,
                                                  callback: function ($$v) {
                                                    _vm.fixedToo = $$v
                                                  },
                                                  expression: "fixedToo",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.workOrder.serviceDateTypeId &&
                              _vm.workOrder.serviceDateTypeId == 5
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  ref: "menu",
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    "return-value":
                                                      _vm.repetitionDetails
                                                        .startDate,
                                                    transition:
                                                      "scale-transition",
                                                    "offset-y": "",
                                                  },
                                                  on: {
                                                    "update:returnValue":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          _vm.repetitionDetails,
                                                          "startDate",
                                                          $event
                                                        )
                                                      },
                                                    "update:return-value":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          _vm.repetitionDetails,
                                                          "startDate",
                                                          $event
                                                        )
                                                      },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-text-field",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "Start Date",
                                                                      "prepend-inner-icon":
                                                                        "mdi-calendar",
                                                                      readonly:
                                                                        "",
                                                                      outlined:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .repetitionDetails
                                                                          .startDate,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.repetitionDetails,
                                                                            "startDate",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "repetitionDetails.startDate",
                                                                    },
                                                                  },
                                                                  "v-text-field",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              )
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2335814028
                                                  ),
                                                  model: {
                                                    value: _vm.menuStartDate,
                                                    callback: function ($$v) {
                                                      _vm.menuStartDate = $$v
                                                    },
                                                    expression: "menuStartDate",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-date-picker",
                                                    {
                                                      attrs: {
                                                        "no-title": "",
                                                        scrollable: "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.$refs.menu.save(
                                                            _vm
                                                              .repetitionDetails
                                                              .startDate
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.repetitionDetails
                                                            .startDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.repetitionDetails,
                                                            "startDate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "repetitionDetails.startDate",
                                                      },
                                                    },
                                                    [
                                                      _c("v-spacer"),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            text: "",
                                                            color: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.menuStartDate = false
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" Cancel ")]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            text: "",
                                                            color: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.$refs.menu.save(
                                                                _vm
                                                                  .repetitionDetails
                                                                  .startDate
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" OK ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("v-select", {
                                                attrs: {
                                                  items: _vm.frequencies,
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  outlined: "",
                                                  label: "Select Frequency",
                                                },
                                                model: {
                                                  value:
                                                    _vm.repetitionDetails
                                                      .frequencyId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.repetitionDetails,
                                                      "frequencyId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "repetitionDetails.frequencyId",
                                                },
                                              }),
                                              _c(
                                                "div",
                                                [
                                                  _c("v-date-picker", {
                                                    attrs: {
                                                      "no-title": "",
                                                      multiple: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.repetitionDetails
                                                          .repetitionSchedules[0]
                                                          .dates,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.repetitionDetails
                                                            .repetitionSchedules[0],
                                                          "dates",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "\n                              repetitionDetails.repetitionSchedules[0].dates\n                            ",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  ref: "menuEndDate",
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    "return-value":
                                                      _vm.repetitionDetails
                                                        .endDate,
                                                    transition:
                                                      "scale-transition",
                                                    "offset-y": "",
                                                  },
                                                  on: {
                                                    "update:returnValue":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          _vm.repetitionDetails,
                                                          "endDate",
                                                          $event
                                                        )
                                                      },
                                                    "update:return-value":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          _vm.repetitionDetails,
                                                          "endDate",
                                                          $event
                                                        )
                                                      },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-text-field",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "End Date",
                                                                      "prepend-inner-icon":
                                                                        "mdi-calendar",
                                                                      readonly:
                                                                        "",
                                                                      outlined:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .repetitionDetails
                                                                          .endDate,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.repetitionDetails,
                                                                            "endDate",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "repetitionDetails.endDate",
                                                                    },
                                                                  },
                                                                  "v-text-field",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              )
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    1419942572
                                                  ),
                                                  model: {
                                                    value: _vm.menuEndDate,
                                                    callback: function ($$v) {
                                                      _vm.menuEndDate = $$v
                                                    },
                                                    expression: "menuEndDate",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-date-picker",
                                                    {
                                                      attrs: {
                                                        "no-title": "",
                                                        scrollable: "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.$refs.menuEndDate.save(
                                                            _vm
                                                              .repetitionDetails
                                                              .endDate
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.repetitionDetails
                                                            .endDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.repetitionDetails,
                                                            "endDate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "repetitionDetails.endDate",
                                                      },
                                                    },
                                                    [
                                                      _c("v-spacer"),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            text: "",
                                                            color: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.menuEndDate = false
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" Cancel ")]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            text: "",
                                                            color: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.$refs.menuEndDate.save(
                                                                _vm
                                                                  .repetitionDetails
                                                                  .endDate
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" OK ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-15" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { md: "6" } },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "time-label",
                                                        },
                                                        [_vm._v("From Time")]
                                                      ),
                                                      _c("vue-timepicker", {
                                                        staticClass:
                                                          "time-picker",
                                                        model: {
                                                          value: _vm.fromTime,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.fromTime = $$v
                                                          },
                                                          expression:
                                                            "fromTime",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { md: "6" } },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "time-label",
                                                        },
                                                        [_vm._v("To Time")]
                                                      ),
                                                      _c("vue-timepicker", {
                                                        staticClass:
                                                          "time-picker",
                                                        model: {
                                                          value: _vm.toTime,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.toTime = $$v
                                                          },
                                                          expression: "toTime",
                                                        },
                                                      }),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            text: "",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.updateServiseDate,
                                                          },
                                                        },
                                                        [_vm._v(" update ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary", text: "" },
                                      on: { click: _vm.updateServiseDate },
                                    },
                                    [_vm._v(" update ")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                _c(
                  "div",
                  { staticClass: "wo-box" },
                  [
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { md: "10", cols: "10" } }),
                        _c(
                          "v-col",
                          { attrs: { md: "2", cols: "2" } },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "edit-wo",
                                attrs: { small: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.editMode = !_vm.editMode
                                  },
                                },
                              },
                              [_vm._v(" far fa-edit ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { md: "6", cols: "6" } }, [
                          _c("p", { staticClass: "label-key" }, [
                            _vm._v("Order ID"),
                          ]),
                        ]),
                        _c("v-col", { attrs: { md: "6", cols: "6" } }, [
                          _c("p", { staticClass: "value-key colored" }, [
                            _vm._v(_vm._s(_vm.workOrder.orderId)),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { md: "6", cols: "6" } }, [
                          _c("p", { staticClass: "label-key" }, [
                            _vm._v("Order Type"),
                          ]),
                        ]),
                        !_vm.editMode
                          ? _c("v-col", { attrs: { md: "6", cols: "6" } }, [
                              _c("p", { staticClass: "value-key colored" }, [
                                _vm._v(_vm._s(_vm.workOrder.orderType.name)),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.editMode
                          ? _c(
                              "v-col",
                              { attrs: { md: "6", cols: "6" } },
                              [
                                _c("v-combobox", {
                                  class: {
                                    "is-invalid":
                                      _vm.updateSubmitted &&
                                      _vm.$v.workOrder.orderType.id.$error,
                                  },
                                  attrs: {
                                    items: _vm.orderTypes,
                                    "item-text": "name",
                                    "item-value": "id",
                                    label: "Select Order Type",
                                    "hide-selected": "",
                                    "persistent-hint": "",
                                    outlined: "",
                                  },
                                  on: { change: _vm.updated },
                                  model: {
                                    value: _vm.workOrder.orderType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.workOrder, "orderType", $$v)
                                    },
                                    expression: "workOrder.orderType",
                                  },
                                }),
                                _vm.updateSubmitted &&
                                !_vm.$v.workOrder.orderType.id.required
                                  ? _c(
                                      "p",
                                      { staticClass: "invalid-feedback mt-2" },
                                      [_vm._v(" Order type is Required ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { md: "6", cols: "6" } }, [
                          _c("p", { staticClass: "label-key" }, [
                            _vm._v("Problem Category"),
                          ]),
                        ]),
                        !_vm.editMode
                          ? _c("v-col", { attrs: { md: "6", cols: "6" } }, [
                              _vm.workOrder.problemType
                                ? _c("p", { staticClass: "value-key" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.workOrder.problemType.name) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm.editMode
                          ? _c(
                              "v-col",
                              { attrs: { md: "6", cols: "6" } },
                              [
                                _c("v-autocomplete", {
                                  staticClass: "mr-3",
                                  class: {
                                    "is-invalid":
                                      _vm.updateSubmitted &&
                                      _vm.$v.workOrder.problemType.id.$error,
                                  },
                                  attrs: {
                                    items: _vm.problemTypes,
                                    "item-text": "name",
                                    "item-value": "id",
                                    label: "Problem Category",
                                    outlined: "",
                                  },
                                  on: { change: _vm.updated },
                                  model: {
                                    value: _vm.workOrder.problemType.id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.workOrder.problemType,
                                        "id",
                                        $$v
                                      )
                                    },
                                    expression: "workOrder.problemType.id",
                                  },
                                }),
                                _vm.updateSubmitted &&
                                !_vm.$v.workOrder.problemType.id.required
                                  ? _c(
                                      "p",
                                      { staticClass: "invalid-feedback mt-2" },
                                      [_vm._v(" Problem Category is Required ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { md: "6", cols: "6" } }, [
                          _c("p", { staticClass: "label-key" }, [
                            _vm._v("Technician Level"),
                          ]),
                        ]),
                        !_vm.editMode
                          ? _c("v-col", { attrs: { md: "6", cols: "6" } }, [
                              _vm.workOrder.workOrderTechnicianLevels
                                ? _c("p", { staticClass: "value-key" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.workOrder
                                            .workOrderTechnicianLevels[0]
                                            .technicianLevel
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm.editMode
                          ? _c(
                              "v-col",
                              { attrs: { md: "6", cols: "6" } },
                              [
                                _c("v-autocomplete", {
                                  staticClass: "mr-3",
                                  attrs: {
                                    items: _vm.levels,
                                    "item-text": "name",
                                    "item-value": "id",
                                    label: "Technician Level",
                                    outlined: "",
                                  },
                                  model: {
                                    value:
                                      _vm.workOrder.workOrderTechnicianLevels[0]
                                        .technicianLevelId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.workOrder
                                          .workOrderTechnicianLevels[0],
                                        "technicianLevelId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n                    workOrder.workOrderTechnicianLevels[0].technicianLevelId\n                  ",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { md: "6", cols: "6" } }, [
                          _c("p", { staticClass: "label-key" }, [
                            _vm._v("No. of Technicians"),
                          ]),
                        ]),
                        !_vm.editMode
                          ? _c("v-col", { attrs: { md: "6", cols: "6" } }, [
                              _vm.workOrder.workOrderTechnicianLevels
                                ? _c("p", { staticClass: "value-key" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.workOrder
                                            .workOrderTechnicianLevels[0]
                                            .numberOfTechnician
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm.editMode
                          ? _c(
                              "v-col",
                              { attrs: { md: "6", cols: "6" } },
                              [
                                _c("v-text-field", {
                                  staticClass: "mr-3",
                                  attrs: { solo: "" },
                                  on: { change: _vm.updated },
                                  model: {
                                    value:
                                      _vm.workOrder.workOrderTechnicianLevels[0]
                                        .numberOfTechnician,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.workOrder
                                          .workOrderTechnicianLevels[0],
                                        "numberOfTechnician",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n                    workOrder.workOrderTechnicianLevels[0].numberOfTechnician\n                  ",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "wo-box" },
                  [
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { md: "10", cols: "10" } }),
                        _c(
                          "v-col",
                          { attrs: { md: "2", cols: "2" } },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "edit-wo",
                                attrs: { small: "" },
                                on: { click: _vm.changePaymentFlage },
                              },
                              [_vm._v(" far fa-edit ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { md: "6", cols: "6" } }, [
                          _c("p", { staticClass: "label-key" }, [
                            _vm._v("Payment_Type"),
                          ]),
                        ]),
                        !_vm.PaymentSectionFlag
                          ? _c("v-col", { attrs: { md: "6", cols: "6" } }, [
                              _vm.workOrder.paymentType
                                ? _c(
                                    "p",
                                    {
                                      class: [
                                        ,
                                        {
                                          "red-custom2":
                                            _vm.workOrder.paymentType.id == 1,
                                        },
                                        {
                                          "green-custom2":
                                            _vm.workOrder.paymentType.id == 2,
                                        },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.workOrder.paymentType.name
                                          ) +
                                          ". "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm.PaymentSectionFlag
                          ? _c(
                              "v-col",
                              { attrs: { md: "6", cols: "6" } },
                              [
                                _c("v-combobox", {
                                  class: {
                                    "is-invalid":
                                      _vm.updateSubmitted &&
                                      _vm.$v.workOrder.paymentType.id.$error,
                                  },
                                  attrs: {
                                    items: _vm.paymentTypes,
                                    "item-text": "name",
                                    "item-value": "id",
                                    label: "Select Payment Type",
                                    "hide-selected": "",
                                    "persistent-hint": "",
                                    outlined: "",
                                  },
                                  on: { change: _vm.updatePayment },
                                  model: {
                                    value: _vm.workOrder.paymentType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.workOrder,
                                        "paymentType",
                                        $$v
                                      )
                                    },
                                    expression: "workOrder.paymentType",
                                  },
                                }),
                                _vm.updateSubmitted &&
                                !_vm.$v.workOrder.paymentType.id.required
                                  ? _c(
                                      "p",
                                      { staticClass: "invalid-feedback mt-2" },
                                      [_vm._v(" payment Type is Required ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._l(_vm.workOrder.workOrderExpenses, function (p) {
                      return _c(
                        "v-row",
                        { key: p.id + "P" },
                        [
                          p.workOrderExpenseType == "Payment"
                            ? _c("v-col", { attrs: { md: "6", cols: "6" } }, [
                                _vm._v(" Bill Rate "),
                              ])
                            : _c("v-col", { attrs: { md: "6", cols: "6" } }, [
                                _vm._v(
                                  " " + _vm._s(p.workOrderExpenseType) + " "
                                ),
                              ]),
                          _vm.PaymentSectionFlag &&
                          p.workOrderExpenseType == "Payment"
                            ? _c(
                                "v-col",
                                { attrs: { md: "6", cols: "6" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "mr-3",
                                    attrs: { solo: "" },
                                    on: { change: _vm.updated },
                                    model: {
                                      value: p.expenseAmount,
                                      callback: function ($$v) {
                                        _vm.$set(p, "expenseAmount", $$v)
                                      },
                                      expression: "p.expenseAmount",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.PaymentSectionFlag &&
                          p.workOrderExpenseType != "Payment"
                            ? _c(
                                "v-col",
                                { attrs: { md: "6", cols: "6" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6", md: "6" } },
                                        [
                                          _c("v-select", {
                                            staticClass: "mr-0",
                                            attrs: {
                                              solo: "",
                                              "item-text": "name",
                                              "item-value": "id",
                                              items: _vm.amountTypes,
                                              label: "$",
                                            },
                                            on: { change: _vm.updated },
                                            model: {
                                              value: p.amountTypeId,
                                              callback: function ($$v) {
                                                _vm.$set(p, "amountTypeId", $$v)
                                              },
                                              expression: "p.amountTypeId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6", md: "6" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mr-3 ml-0",
                                            attrs: { solo: "" },
                                            on: { change: _vm.updated },
                                            model: {
                                              value: p.expenseAmount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  p,
                                                  "expenseAmount",
                                                  $$v
                                                )
                                              },
                                              expression: "p.expenseAmount",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.PaymentSectionFlag
                            ? _c("v-col", { attrs: { md: "6", cols: "6" } }, [
                                p.amountTypeId == 2
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(p.amountType) +
                                          _vm._s(p.expenseAmount)
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(p.expenseAmount) +
                                          _vm._s(p.amountType)
                                      ),
                                    ]),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    _vm.PaymentSectionFlag
                      ? _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { md: "10" } }),
                            _c(
                              "v-col",
                              { attrs: { md: "2" } },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: { small: "" },
                                    on: { click: _vm.ShowExpenseDialog },
                                  },
                                  [_vm._v(" fa-solid fa-plus ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "scope-box" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { md: "11", sm: "11", cols: "9" } }, [
                _c("p", { staticClass: "label-key" }, [
                  _vm._v("Scope of work :"),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { md: "1", sm: "1", cols: "2" } },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "edit-wo",
                      attrs: { small: "" },
                      on: { click: _vm.changeWorkOrderScope },
                    },
                    [_vm._v(" far fa-edit ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.flag1,
                  expression: "!flag1",
                },
              ],
              staticClass: "value-key scop-clr",
            },
            [_vm._v(" " + _vm._s(_vm.workOrder.scopeOfWork) + " ")]
          ),
          _c(
            "v-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.flag1,
                  expression: "flag1",
                },
              ],
            },
            [
              _c("v-textarea", {
                staticClass: "mb-3",
                attrs: { outlined: "", label: "Scope Of Work" },
                on: { change: _vm.updated },
                model: {
                  value: _vm.workOrder.scopeOfWork,
                  callback: function ($$v) {
                    _vm.$set(_vm.workOrder, "scopeOfWork", $$v)
                  },
                  expression: "workOrder.scopeOfWork",
                },
              }),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { md: "11", sm: "11", cols: "9" } }, [
                _c("p", { staticClass: "table-title" }, [
                  _vm._v("Contact Information:"),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { md: "1", sm: "1", cols: "2" } },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "edit-wo",
                      attrs: { small: "" },
                      on: { click: _vm.ShowDialog },
                    },
                    [_vm._v(" far fa-edit ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tabs",
            {
              staticClass: "mb-5",
              model: {
                value: _vm.contactTab,
                callback: function ($$v) {
                  _vm.contactTab = $$v
                },
                expression: "contactTab",
              },
            },
            [
              _c("v-tab", [_vm._v(" On-Site Contacts ")]),
              _c("v-tab", [_vm._v(" Technical Contacts ")]),
              _c("v-tab", [_vm._v(" Account Manager Contacts ")]),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.contactTab,
                callback: function ($$v) {
                  _vm.contactTab = $$v
                },
                expression: "contactTab",
              },
            },
            [
              _c(
                "v-tab-item",
                _vm._l(_vm.workOrderContacts, function (item, index) {
                  return _c("div", { key: item.id + index }, [
                    item && item.contactType.name == "On_Site"
                      ? _c(
                          "div",
                          [
                            _c(
                              "v-row",
                              [
                                _c("v-col", { attrs: { md: "6" } }, [
                                  _c("p", [
                                    _c("span", { staticClass: "contact-key" }, [
                                      _vm._v(" Name:"),
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "contact-value" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.firstName) +
                                            "   " +
                                            _vm._s(item.lastName) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("p", [
                                    _c("span", { staticClass: "contact-key" }, [
                                      _vm._v("Email Address:"),
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "contact-value" },
                                      [_vm._v(" " + _vm._s(item.email) + " ")]
                                    ),
                                  ]),
                                  _c("p", [
                                    _c("span", { staticClass: "contact-key" }, [
                                      _vm._v(" Address:"),
                                    ]),
                                    item.address
                                      ? _c(
                                          "span",
                                          { staticClass: "contact-value" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.address.buildingNumber
                                                ) +
                                                " - " +
                                                _vm._s(item.address.street) +
                                                "- "
                                            ),
                                            item.address.city
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.address.city.name
                                                    ) + " -"
                                                  ),
                                                ])
                                              : _vm._e(),
                                            item.address.state
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.address.state.name
                                                    ) + " -"
                                                  ),
                                                ])
                                              : _vm._e(),
                                            item.address.country
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.address.country
                                                          .name
                                                      )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                                _c("v-col", { attrs: { md: "6" } }, [
                                  _c("p", [
                                    _c("span", { staticClass: "contact-key" }, [
                                      _vm._v("Phone Number:"),
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "contact-value" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.contactPhones[0].phoneNumber
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("p", [
                                    _c("span", { staticClass: "contact-key" }, [
                                      _vm._v("Branch:"),
                                    ]),
                                    item.branch
                                      ? _c(
                                          "span",
                                          { staticClass: "contact-value" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.branch.name) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c("v-divider", { staticClass: "mb-2" }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                }),
                0
              ),
              _c(
                "v-tab-item",
                _vm._l(_vm.workOrderContacts, function (item, index) {
                  return _c("div", { key: item.id + index }, [
                    item && item.contactType.name == "Technical"
                      ? _c(
                          "div",
                          [
                            _c(
                              "v-row",
                              [
                                _c("v-col", { attrs: { md: "6" } }, [
                                  _c("p", [
                                    _c("span", { staticClass: "contact-key" }, [
                                      _vm._v(" Name:"),
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "contact-value" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.firstName) +
                                            "   " +
                                            _vm._s(item.lastName) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("p", [
                                    _c("span", { staticClass: "contact-key" }, [
                                      _vm._v("Email Address:"),
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "contact-value" },
                                      [_vm._v(" " + _vm._s(item.email) + " ")]
                                    ),
                                  ]),
                                  _c("p", [
                                    _c("span", { staticClass: "contact-key" }, [
                                      _vm._v(" Address:"),
                                    ]),
                                    item.address
                                      ? _c(
                                          "span",
                                          { staticClass: "contact-value" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.address.buildingNumber
                                                ) +
                                                " - " +
                                                _vm._s(item.address.street) +
                                                "- "
                                            ),
                                            item.address.city
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.address.city.name
                                                    ) + " -"
                                                  ),
                                                ])
                                              : _vm._e(),
                                            item.address.state
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.address.state.name
                                                    ) + " -"
                                                  ),
                                                ])
                                              : _vm._e(),
                                            item.address.country
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.address.country
                                                          .name
                                                      )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                                _c("v-col", { attrs: { md: "6" } }, [
                                  _c("p", [
                                    _c("span", { staticClass: "contact-key" }, [
                                      _vm._v("Phone Number:"),
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "contact-value" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.contactPhones[0].phoneNumber
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("p", [
                                    _c("span", { staticClass: "contact-key" }, [
                                      _vm._v("Branch:"),
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "contact-value" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.branch.name) + " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c("v-divider", { staticClass: "mb-2" }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                }),
                0
              ),
              _c(
                "v-tab-item",
                _vm._l(_vm.workOrderContacts, function (item, index) {
                  return _c("div", { key: item.id + index }, [
                    item && item.contactType.name == "Account_Manager"
                      ? _c(
                          "div",
                          [
                            _c(
                              "v-row",
                              [
                                _c("v-col", { attrs: { md: "6" } }, [
                                  _c("p", [
                                    _c("span", { staticClass: "contact-key" }, [
                                      _vm._v(" Name:"),
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "contact-value" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.firstName) +
                                            "   " +
                                            _vm._s(item.lastName) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("p", [
                                    _c("span", { staticClass: "contact-key" }, [
                                      _vm._v("Email Address:"),
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "contact-value" },
                                      [_vm._v(" " + _vm._s(item.email) + " ")]
                                    ),
                                  ]),
                                  _c("p", [
                                    _c("span", { staticClass: "contact-key" }, [
                                      _vm._v(" Address:"),
                                    ]),
                                    item.address
                                      ? _c(
                                          "span",
                                          { staticClass: "contact-value" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.address.buildingNumber
                                                ) +
                                                " - " +
                                                _vm._s(item.address.street) +
                                                "- "
                                            ),
                                            item.address.city
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.address.city.name
                                                    ) + " -"
                                                  ),
                                                ])
                                              : _vm._e(),
                                            item.address.state
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.address.state.name
                                                    ) + " -"
                                                  ),
                                                ])
                                              : _vm._e(),
                                            item.address.country
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.address.country
                                                          .name
                                                      )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                                _c("v-col", { attrs: { md: "6" } }, [
                                  _c("p", [
                                    _c("span", { staticClass: "contact-key" }, [
                                      _vm._v("Phone Number:"),
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "contact-value" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.contactPhones[0].phoneNumber
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("p", [
                                    _c("span", { staticClass: "contact-key" }, [
                                      _vm._v("Branch:"),
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "contact-value" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.branch.name) + " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c("v-divider", { staticClass: "mb-2" }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isUpdated
        ? _c(
            "v-btn",
            {
              staticClass: "float btn-creation wo-diff-style",
              on: { click: _vm.updateWorkOrder },
            },
            [_vm._v("Save Changes")]
          )
        : _vm._e(),
      _vm.isUpdated
        ? _c(
            "v-btn",
            {
              staticClass: "float2 btn-creation wo-diff-style",
              attrs: { color: "red" },
              on: { click: _vm.refreshPage },
            },
            [_vm._v("Discard Changes ")]
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700px" },
          model: {
            value: _vm.expenseDialoge,
            callback: function ($$v) {
              _vm.expenseDialoge = $$v
            },
            expression: "expenseDialoge",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v("New Expense"),
              ]),
              _c(
                "v-card-text",
                [
                  _c("br"),
                  _c("v-select", {
                    class: {
                      "is-invalid":
                        _vm.submitted &&
                        _vm.$v.feild.workOrderExpenseTypeId.$error,
                    },
                    attrs: {
                      items: _vm.expensesTypes,
                      "item-text": "name",
                      "item-value": "id",
                      label: "Add Fees,Discount,Tax, ... ",
                      solo: "",
                    },
                    model: {
                      value: _vm.feild.workOrderExpenseTypeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.feild, "workOrderExpenseTypeId", $$v)
                      },
                      expression: "feild.workOrderExpenseTypeId",
                    },
                  }),
                  _vm.submitted && !_vm.$v.feild.workOrderExpenseTypeId.required
                    ? _c("p", { staticClass: "invalid-feedback mt-2" }, [
                        _vm._v(" ExpenseType is required "),
                      ])
                    : _vm._e(),
                  _c("br"),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            class: {
                              "is-invalid":
                                _vm.submitted &&
                                _vm.$v.feild.description.$error,
                            },
                            attrs: {
                              placeholder: "Description",
                              label: _vm.feild.name,
                            },
                            model: {
                              value: _vm.feild.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.feild, "description", $$v)
                              },
                              expression: "feild.description",
                            },
                          }),
                          _vm.submitted && !_vm.$v.feild.description.required
                            ? _c(
                                "p",
                                { staticClass: "invalid-feedback mt-2" },
                                [_vm._v(" description is required ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "handle-space",
                          attrs: { md: "3", cols: "4" },
                        },
                        [
                          _c("v-select", {
                            staticClass: "payment-resize",
                            class: {
                              "is-invalid":
                                _vm.submitted &&
                                _vm.$v.feild.amountTypeId.$error,
                            },
                            attrs: {
                              outlined: "",
                              "item-text": "name",
                              "item-value": "id",
                              items: _vm.amountTypes,
                            },
                            model: {
                              value: _vm.feild.amountTypeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.feild, "amountTypeId", $$v)
                              },
                              expression: "feild.amountTypeId",
                            },
                          }),
                          _vm.submitted && !_vm.$v.feild.amountTypeId.required
                            ? _c(
                                "p",
                                { staticClass: "invalid-feedback mt-2" },
                                [_vm._v(" amount type is required ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "5", cols: "7" } },
                        [
                          _c("v-text-field", {
                            class: {
                              "is-invalid":
                                _vm.submitted &&
                                _vm.$v.feild.expenseAmount.$error,
                            },
                            attrs: { outlined: "", label: "Amount" },
                            model: {
                              value: _vm.feild.expenseAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.feild, "expenseAmount", $$v)
                              },
                              expression: "feild.expenseAmount",
                            },
                          }),
                          _vm.submitted && !_vm.$v.feild.expenseAmount.required
                            ? _c(
                                "p",
                                { staticClass: "invalid-feedback mt-2" },
                                [_vm._v(" amount is required ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.addFeild },
                    },
                    [_vm._v("Add")]
                  ),
                  _c("v-spacer"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }