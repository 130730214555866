<template>
  <div class="upload-section" id="users">
    <SideMenu v-if="!$vuetify.breakpoint.xsOnly"></SideMenu>
    <div class="user-creation ml-4 mt-4">
      <p class="page-label">Create User</p>
      <div class="profile-card">

        <v-row id="user-email">
          <v-col md="6" cols="12" id="user-email">
            <v-text-field v-model="user.email" outlined label="Enter User Email Address" :class="{
              'is-invalid': submitted && $v.user.email.$error,
            }"></v-text-field>
            <p v-if="submitted && !$v.user.email.required" class="invalid-feedback mt-2">
              Email is Required
            </p>
            <p v-if="submitted && !$v.user.email.email" class="invalid-feedback mt-2">
              Email is Invalid
            </p>
          </v-col>
        </v-row>
      </div>
      <div class="profile-card">
        <v-row style="margin: 0px">
          <span class="Profile-data" style="color: rgb(23, 36, 80); font-size: 18px">
            System Settings</span>
        </v-row>

        <br />
        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete :items="userTypes" :item-value="'id'" :item-text="'name'" outlined label="Select Type"
              v-model="user.userTypes[0]" @change="getGroups"></v-autocomplete> </v-col></v-row>

        <!-- <div id="creae-user">
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="white"></v-tabs-slider>
            <v-tab active-class="activeTab" v-for="item in permissions" :key="item.id" class="non-active">
              {{ item.name }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <div v-for="item in permissions" :key="item.id + 'I'">
              <v-tab-item>
                <v-checkbox v-for="group in item.groups" :key="group.id + 'G'" color="#3ac127" v-model="group.checkBox"
                  :label="group.description" class="role-name" @click="pushRoles(group)"></v-checkbox>
              </v-tab-item>
            </div>
          </v-tabs-items>
        </div> -->
      </div>
      <div class="profile-card">
        <v-row style="margin: 0px">
          <span class="Profile-data" style="color: rgb(23, 36, 80); font-size: 18px">
            Client Settings</span>

        </v-row>
        <v-row>
          <v-col md="12">
            <v-autocomplete v-model="selectedClients" outlined :items="clients" :item-value="'id'" :item-text="'name'"
              @change="getAccounts" multiple label="Select Client">
              <template v-slot:selection="data">
                <v-chip v-bind="data.attrs" :input-value="data.selected" color="#7cab9a54" close @click="data.select"
                  @click:close="RemoveClient(data.item.id)">
                  {{ data.item.name }}
                </v-chip>
              </template></v-autocomplete>

          </v-col>
          <v-col md="12">
            <v-autocomplete v-model="selectedAccounts" outlined :items="accounts" :item-value="'id'" :item-text="'name'"
              @change="getCompanies" label="Select Account" multiple>
              <template v-slot:selection="data">
                <v-chip color="#7cab9a54" v-bind="data.attrs" :input-value="data.selected" close @click="data.select"
                  @click:close="RemoveAccount(data.item.id)">
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>

          </v-col>
          <v-col md="12">

            <v-autocomplete v-model="selectedCompanies" outlined :items="companies" :item-value="'id'"
              :item-text="'name'" @change="getLocations" label="Select Company" multiple> <template
                v-slot:selection="data">
                <v-chip v-bind="data.attrs" :input-value="data.selected" close color="#7cab9a54" @click="data.select"
                  @click:close="RemoveCompany(data.item.id)">
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col md="12">

            <v-autocomplete v-model="selectedLocations" outlined :items="locations" :item-value="'id'"
              :item-text="'name'" label="Select Location" multiple>
              <template v-slot:selection="data">
                <v-chip color="#7cab9a54" v-bind="data.attrs" :input-value="data.selected" close @click="data.select"
                  @click:close="RemoveLocation(data.item.id)">
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>

        </v-row>
      </div>




      <div class="btn-container">
        <v-btn class="create-order-btn" @click="check">Create User</v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import SideMenu from "@/components/SettingsSideMenu.vue";
import { dataService } from "@/services/data-service";
import store from "@/store";
import { required, email } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      selectedClients: [],
      selectedAccounts: [],
      selectedCompanies: [],
      selectedLocations: [],
      clients: [],
      accounts: [],
      companies: [],
      locations: [],
      submitted: false,
      queryTerm: "",
      userPermissions: [],
      permissions: [],
      newArr: [],
      allGroups: [],
      tab: null,
      accessLevels: [
        {
          id: 1,
          name: "Client",
        },
        {
          id: 2,
          name: "Account",
        },
        {
          id: 3,
          name: "Company",
        },
        {
          id: 4,
          name: "Branch",
        },
      ],
      userTypes: [

      ],
      tempRoles: [],
      user: {
        email: "",
        userTypes: [],
        rolIds: [],
        privileges: [],
        groupIds: [],
      },
      concatenatedRoles: [],
    };
  },
  validations: {
    user: {
      email: { required, email },
    },
    accessLevelsFeilds: {
      $each: {
        levelID: { required },
        data: { required },
        dataIds: { required },
      },
    },
  },
  computed: {
    search: {
      get() {
        return this.queryTerm;
      },
      set(searchInput) {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput;
          // this.loadEntries();
        }
      },
    },
  },
  methods: {
    RemoveClient(item) {
      const index = this.selectedClients.indexOf(item);
      if (index >= 0) this.selectedClients.splice(index, 1);
    },
    RemoveCompany(item) {
      const index = this.selectedCompanies.indexOf(item);
      if (index >= 0) this.selectedCompanies.splice(index, 1);
    },
    RemoveAccount(item) {
      const index = this.selectedAccounts.indexOf(item);
      if (index >= 0) this.selectedAccounts.splice(index, 1);
    },
    RemoveLocation(item) {
      const index = this.selectedLocations.indexOf(item);
      if (index >= 0) this.selectedLocations.splice(index, 1);
    },
    pushRoles(group) {
      var allRoles = [];
      if (group.checkBox == true) {
        this.user.groupIds.push(group.id);

        if (group.roles.length > 0) {
          for (var role = 0; role < group.roles.length; role++) {
            this.user.rolIds.push(group.roles[role].roleId);
          }
        }

      } else {
        var removedRoles = [];
        for (var i = 0; i < group.roles.length; i++) {
          removedRoles.push(group.roles[i].roleId);
        }
        allRoles = Array.prototype.concat.apply([], this.user.rolIds);

        for (var l = 0; l < allRoles.length; l++) {
          for (var r = 0; r < removedRoles.length; r++) {
            if (allRoles[l] == removedRoles[r]) {
              allRoles.splice(l, 1);
            }
          }
        }

        this.user.rolIds = allRoles;
      }
    },
    getGroups() {
      this.permissions = [];
      dataService.getAll("api/SystemUsers/groups", (response) => {
        var allPermissions = response.data;
        for (var pr = 0; pr < allPermissions.length; pr++) {
          this.permissions.push({
            id: allPermissions[pr].id,
            name: allPermissions[pr].name,
            groups: allPermissions[pr].groups,
          });
        }
        if (this.user.userTypes) {
          dataService.getAll(
            "api/SystemUsers/role-by_systemtype/" + this.user.userTypes[0],
            (response) => {
              var userRoles = response.data.data;
              for (var t = 0; t < this.permissions.length; t++) {
                for (var pg = 0; pg < this.permissions[t].groups.length; pg++) {
                  for (var ur = 0; ur < userRoles.length; ur++) {
                    if (this.permissions[t].groups[pg].id == userRoles[ur].id) {
                      this.permissions[t].groups[pg].checkBox = true;
                      this.user.rolIds.push(userRoles[ur].roles);
                    }
                  }
                }
              }
            }
          );
        }
      });
    },
    check() {
      this.submitted = true;
      this.$v.user.$touch();
      if (this.$v.user.$invalid) {
        return;
      } else {
        var resultArray = Array.prototype.concat.apply([], this.user.rolIds);
        this.user.rolIds = resultArray;

        if (this.selectedLocations.length > 0) {
          this.selectedLocations.
            forEach(element => {
              this.user.privileges.push({
                keyId: element,
                keyTypeId: 4,
              })
            });

        }
        if (
          this.selectedLocations.length == 0 &&
          this.selectedCompanies.length > 0
        ) {
          this.selectedCompanies.
            forEach(element => {
              this.user.privileges.push({
                keyId: element,
                keyTypeId: 3,
              })
            });
        }

        if (
          this.selectedLocations.length == 0 &&
          this.selectedCompanies.length == 0 &&
          this.selectedAccounts.length > 0

        ) {
          this.selectedAccounts.
            forEach(element => {
              this.user.privileges.push({
                keyId: element,
                keyTypeId: 2,
              })
            });
        }
        if (
          this.selectedLocations.length == 0 &&
          this.selectedCompanies.length == 0 &&
          this.selectedAccounts.length == 0 &&
          this.selectedClients.length > 0

        ) {
        this.selectedClients.
          forEach(element => {
            this.user.privileges.push({
              keyId: element,
              keyTypeId: 1,
            })
          });
        }



        dataService.create("api/SystemUsers", this.user, (response) => {

          if (response.data.succeeded == true) {
            store.commit("SHOW_MESSAGE", {
              text: "Created Successfully",
              color: "success",
              timeout: 3000,
            });
            this.$router.push("/settings/users");
          } else {
            store.commit("SHOW_MESSAGE", {
              text: "The Email you entered is already exist !",
              color: "error",
              timeout: 3000,
            });
          }
        });



      }
    },
    getClients() {
      dataService.getList(
        "api/Clients/search_by_name",
        {
          pageNumber: 1,
          pageSize: 100,
          subString: "",
        },
        (response) => {
          this.clients = response.data.data.items;
        }
      );
    },
    getAccounts() {
      dataService.create(
        "api/Accounts/clients",
        this.selectedClients,
        (response) => {
          this.accounts = response.data.data;
        }
      );
    },
    getCompanies() {
      dataService.create(
        "api/Companies/accounts",
        this.selectedAccounts,
        (response) => {
          this.companies = response.data.data;
        }
      );
    },

    getUseType() {
      dataService.getAll(
        "api/Enums/Axa-User",

        (response) => {
          this.userTypes = response.data;

          //console.log(this.userTypes, "types")
        }
      );
    },
    getLocations() {
      dataService.create(
        "api/Branches/companies",
        this.selectedCompanies,
        (response) => {
          this.locations = response.data.data;
        }
      );
    },
    updatePrivillages(index) {
      for (var p = 0; p < this.accessLevelsFeilds[index].dataIds.length; p++) {
        this.user.privileges.push({
          keyId: this.accessLevelsFeilds[index].levelID,
          keyTypeId: this.accessLevelsFeilds[index].dataIds[p],
        });
      }
      var makeUnique = (array = [], keys = []) => {
        if (!keys.length || !array.length) return [];
        return array.reduce((list, item) => {
          const hasItem = list.find((listItem) =>
            keys.every((key) => listItem[key] === item[key])
          );
          if (!hasItem) list.push(item);
          return list;
        }, []);
      };
      this.newArr = makeUnique(this.user.privileges, ["keyId", "keyTypeId"]);

    },
  },
  components: {
    SideMenu,
  },
  mounted() {
    this.getUseType();
    this.getGroups();
    this.getClients();
  },
};
</script>