var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "handle-test", attrs: { id: "work-order" } },
    [
      _c(
        "div",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12", md: "10" } }, [
                _c("p", { staticClass: "table-title" }, [
                  _vm._v("Contacts List"),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { md: "1", cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-creation wo-diff-style",
                      attrs: { to: "/contact/create", color: "#D92020" },
                    },
                    [_vm._v("Create Contact")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pad0", attrs: { cols: "12", md: "4" } },
                [
                  _c("v-text-field", {
                    ref: "objet",
                    attrs: {
                      solo: "",
                      label: "Search Contact ",
                      "append-icon": "mdi-magnify",
                    },
                    on: {
                      keyup: function ($event) {
                        return _vm.realSearch(1)
                      },
                    },
                    model: {
                      value: _vm.ContactFilter.subString,
                      callback: function ($$v) {
                        _vm.$set(_vm.ContactFilter, "subString", $$v)
                      },
                      expression: "ContactFilter.subString",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pad0", attrs: { cols: "12", md: "8" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-autocomplete", {
                            staticClass: "mb-3",
                            attrs: {
                              items: _vm.countries,
                              "item-text": "name",
                              "item-value": "id",
                              label: "Country",
                              solo: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onchange()
                              },
                            },
                            model: {
                              value: _vm.ContactFilter.countryId,
                              callback: function ($$v) {
                                _vm.$set(_vm.ContactFilter, "countryId", $$v)
                              },
                              expression: "ContactFilter.countryId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-autocomplete", {
                            ref: "stateFocus",
                            staticClass: "mb-3",
                            attrs: {
                              "no-filter": "",
                              items: _vm.states,
                              "item-text": "name",
                              "item-value": "id",
                              label: "State",
                              solo: "",
                              "search-input": _vm.searchState,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.SearchInCity()
                              },
                              "update:searchInput": function ($event) {
                                _vm.searchState = $event
                              },
                              "update:search-input": function ($event) {
                                _vm.searchState = $event
                              },
                            },
                            model: {
                              value: _vm.ContactFilter.stateId,
                              callback: function ($$v) {
                                _vm.$set(_vm.ContactFilter, "stateId", $$v)
                              },
                              expression: "ContactFilter.stateId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-autocomplete", {
                            ref: "cityFocus",
                            staticClass: "mb-3",
                            attrs: {
                              items: _vm.cities,
                              "item-text": "name",
                              "item-value": "id",
                              label: "City",
                              solo: "",
                              "search-input": _vm.searchCity,
                            },
                            on: {
                              "update:searchInput": function ($event) {
                                _vm.searchCity = $event
                              },
                              "update:search-input": function ($event) {
                                _vm.searchCity = $event
                              },
                              change: function ($event) {
                                return _vm.realSearch(1)
                              },
                            },
                            model: {
                              value: _vm.ContactFilter.cityId,
                              callback: function ($$v) {
                                _vm.$set(_vm.ContactFilter, "cityId", $$v)
                              },
                              expression: "ContactFilter.cityId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", {
                staticClass: "pad0",
                attrs: { cols: "12", md: "4" },
              }),
              _c(
                "v-col",
                { staticClass: "pad0", attrs: { cols: "12", md: "8" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: { solo: "", label: "Zip Code" },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.realSearch(1)
                              },
                            },
                            model: {
                              value: _vm.ContactFilter.zipCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.ContactFilter, "zipCode", $$v)
                              },
                              expression: "ContactFilter.zipCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.contactTypes,
                              "item-value": "id",
                              "item-text": "name",
                              label: "Contact Type",
                              solo: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.realSearch(1)
                              },
                            },
                            model: {
                              value: _vm.ContactFilter.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.ContactFilter, "type", $$v)
                              },
                              expression: "ContactFilter.type",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-creation diff-style mt-1",
                              attrs: { color: "#D92020" },
                              on: { click: _vm.resetFilter },
                            },
                            [_vm._v("Reset Filter")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              loading: _vm.isLoaded,
              "loading-text": "Loading... Please wait",
              headers: _vm.headers,
              items: _vm.data,
              "item-key": "id",
              "hide-default-footer": "",
              "items-per-page": _vm.ContactFilter.pageSize,
            },
            on: { "update:options": _vm.handleOptionsUpdate },
            scopedSlots: _vm._u([
              {
                key: "item.client",
                fn: function ({ item }) {
                  return [
                    item.client
                      ? _c("span", [_vm._v(_vm._s(item.client.name))])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "item.company",
                fn: function ({ item }) {
                  return [
                    item.company
                      ? _c("span", [_vm._v(_vm._s(item.company.name))])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "item.account",
                fn: function ({ item }) {
                  return [
                    item.account
                      ? _c("span", [_vm._v(_vm._s(item.account.name))])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "item.branch",
                fn: function ({ item }) {
                  return [
                    item.branch
                      ? _c("span", [_vm._v(_vm._s(item.branch.name))])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "footer",
                fn: function () {
                  return [
                    _c(
                      "v-row",
                      { staticClass: "mt-5 fit-width" },
                      [
                        _c(
                          "v-col",
                          { attrs: { md: "2" } },
                          [
                            _c("v-select", {
                              attrs: {
                                items: _vm.sizeOptions,
                                "item-value": "id",
                                "item-text": "name",
                                solo: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.realSearch(1)
                                },
                              },
                              model: {
                                value: _vm.ContactFilter.pageSize,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ContactFilter, "pageSize", $$v)
                                },
                                expression: "ContactFilter.pageSize",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { md: "5" } },
                          [
                            _c("v-pagination", {
                              attrs: {
                                length: _vm.totalPages,
                                "total-visible": "6",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.realSearch(
                                    _vm.ContactFilter.pageNumber
                                  )
                                },
                              },
                              model: {
                                value: _vm.ContactFilter.pageNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ContactFilter, "pageNumber", $$v)
                                },
                                expression: "ContactFilter.pageNumber",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "top",
                fn: function () {
                  return [
                    _c(
                      "v-toolbar",
                      { attrs: { flat: "" } },
                      [
                        _c("v-toolbar-title", [_vm._v("contacts")]),
                        _c("v-divider", {
                          staticClass: "mx-4",
                          attrs: { inset: "", vertical: "" },
                        }),
                        _c("v-spacer"),
                        _c(
                          "v-dialog",
                          {
                            attrs: { "max-width": "700px" },
                            model: {
                              value: _vm.dialog,
                              callback: function ($$v) {
                                _vm.dialog = $$v
                              },
                              expression: "dialog",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c("v-card-title", [
                                  _c("span", { staticClass: "text-h5" }, [
                                    _vm._v("Edit Contact"),
                                  ]),
                                ]),
                                _c(
                                  "v-card-text",
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { md: "12" } },
                                              [
                                                _c("v-text-field", {
                                                  staticClass: "mb-3",
                                                  class: {
                                                    "is-invalid":
                                                      _vm.submitted &&
                                                      _vm.$v.contact.firstName
                                                        .$error,
                                                  },
                                                  attrs: {
                                                    tabindex: "1",
                                                    outlined: "",
                                                    label: "Full Name",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.contact.firstName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.contact,
                                                        "firstName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "contact.firstName",
                                                  },
                                                }),
                                                _vm.submitted &&
                                                !_vm.$v.contact.firstName
                                                  .required
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "invalid-feedback",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " First Name Is Required "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "6" },
                                              },
                                              [
                                                _c("v-autocomplete", {
                                                  ref: "clientSearch",
                                                  attrs: {
                                                    tabindex: "2",
                                                    outlined: "",
                                                    label: "Select Client",
                                                    items: _vm.clients,
                                                    "item-text": "name",
                                                    "item-value": "id",
                                                  },
                                                  model: {
                                                    value: _vm.contact.clientId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.contact,
                                                        "clientId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "contact.clientId",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "6" },
                                              },
                                              [
                                                _c("v-autocomplete", {
                                                  attrs: {
                                                    tabindex: "3",
                                                    items: _vm.accounts,
                                                    "item-text": "name",
                                                    "item-value": "id",
                                                    outlined: "",
                                                    label: "Select Account",
                                                    "search-input":
                                                      _vm.accountsearch,
                                                  },
                                                  on: {
                                                    "update:searchInput":
                                                      function ($event) {
                                                        _vm.accountsearch =
                                                          $event
                                                      },
                                                    "update:search-input":
                                                      function ($event) {
                                                        _vm.accountsearch =
                                                          $event
                                                      },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.contact.accountId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.contact,
                                                        "accountId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "contact.accountId",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "6" },
                                              },
                                              [
                                                _c("v-autocomplete", {
                                                  ref: "lcn",
                                                  attrs: {
                                                    tabindex: "4",
                                                    outlined: "",
                                                    label: "Select Company",
                                                    items: _vm.companies,
                                                    "item-value": "id",
                                                    "item-text": "name",
                                                    "search-input":
                                                      _vm.Companysearch,
                                                  },
                                                  on: {
                                                    "update:searchInput":
                                                      function ($event) {
                                                        _vm.Companysearch =
                                                          $event
                                                      },
                                                    "update:search-input":
                                                      function ($event) {
                                                        _vm.Companysearch =
                                                          $event
                                                      },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.contact.companyId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.contact,
                                                        "companyId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "contact.companyId",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "6" },
                                              },
                                              [
                                                _c("v-autocomplete", {
                                                  ref: "locationSearch",
                                                  attrs: {
                                                    tabindex: "5",
                                                    items: _vm.locations,
                                                    "item-text": "name",
                                                    "item-value": "id",
                                                    outlined: "",
                                                    label: "Select Location",
                                                    "search-input":
                                                      _vm.locationSearch,
                                                  },
                                                  on: {
                                                    "update:searchInput":
                                                      function ($event) {
                                                        _vm.locationSearch =
                                                          $event
                                                      },
                                                    "update:search-input":
                                                      function ($event) {
                                                        _vm.locationSearch =
                                                          $event
                                                      },
                                                  },
                                                  model: {
                                                    value: _vm.contact.branchId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.contact,
                                                        "branchId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "contact.branchId",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "6" },
                                              },
                                              [
                                                _c("v-autocomplete", {
                                                  class: {
                                                    "is-invalid":
                                                      _vm.submitted &&
                                                      _vm.$v.contact
                                                        .contactTypeId.$error,
                                                  },
                                                  attrs: {
                                                    tabindex: "6",
                                                    items: _vm.contactTypes,
                                                    "item-text": "name",
                                                    "item-value": "id",
                                                    label: "Contact Type",
                                                    outlined: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.contact.contactTypeId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.contact,
                                                        "contactTypeId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "contact.contactTypeId",
                                                  },
                                                }),
                                                _vm.submitted &&
                                                !_vm.$v.contact.contactTypeId
                                                  .required
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "invalid-feedback",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Contact Type Is Required "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c("v-col", {
                                              attrs: { cols: "12", md: "6" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "6" },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  staticClass: "mb-3",
                                                  attrs: {
                                                    tabindex: "7",
                                                    outlined: "",
                                                    label: "Primary Phone",
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.formatPhoneNumber(
                                                        1
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.primary.phoneNumber,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.primary,
                                                        "phoneNumber",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "primary.phoneNumber",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "6" },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  staticClass: "mb-3",
                                                  attrs: {
                                                    tabindex: "8",
                                                    outlined: "",
                                                    label: "Secondary Phone",
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.formatPhoneNumber(
                                                        2
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.Secondry.phoneNumber,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.Secondry,
                                                        "phoneNumber",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "Secondry.phoneNumber",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { md: "6" } },
                                              [
                                                _c("v-text-field", {
                                                  staticClass: "mb-3",
                                                  class: {
                                                    "is-invalid":
                                                      _vm.submitted &&
                                                      _vm.$v.contact.email
                                                        .$error,
                                                  },
                                                  attrs: {
                                                    tabindex: "9",
                                                    outlined: "",
                                                    label: "Email",
                                                  },
                                                  model: {
                                                    value: _vm.contact.email,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.contact,
                                                        "email",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "contact.email",
                                                  },
                                                }),
                                                _vm.submitted &&
                                                !_vm.$v.contact.email.required
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "invalid-feedback",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Email Is Required "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.submitted &&
                                                !_vm.$v.contact.email.email
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "invalid-feedback",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Email Is Not Valid "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticStyle: {
                                                  "margin-top": "-3%",
                                                },
                                                attrs: { md: "6" },
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  {},
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticStyle: {
                                                          "max-width":
                                                            "49% !important",
                                                        },
                                                        attrs: { md: "3" },
                                                      },
                                                      [
                                                        _c("v-checkbox", {
                                                          attrs: {
                                                            tabindex: "10",
                                                            label:
                                                              "Approve Invoice",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.contact
                                                                .isInvoiceApproval,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.contact,
                                                                "isInvoiceApproval",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "contact.isInvoiceApproval",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticStyle: {
                                                          "max-width":
                                                            "49% !important",
                                                        },
                                                        attrs: { md: "3" },
                                                      },
                                                      [
                                                        _c("v-checkbox", {
                                                          attrs: {
                                                            tabindex: "11",
                                                            label:
                                                              "Approve Timesheet",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.contact
                                                                .isTimesheetApproval,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.contact,
                                                                "isTimesheetApproval",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "contact.isTimesheetApproval",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          staticClass: "mb-10",
                                        }),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "mb-3",
                                                      attrs: {
                                                        tabindex: "12",
                                                        outlined: "",
                                                        label: "Street",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.contact.address
                                                            .street,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.contact.address,
                                                            "street",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "contact.address.street",
                                                      },
                                                    }),
                                                    _c("v-autocomplete", {
                                                      staticClass: "mb-3",
                                                      attrs: {
                                                        tabindex: "14",
                                                        items: _vm.countries,
                                                        "item-text": "name",
                                                        "item-value": "id",
                                                        label: "Country",
                                                        outlined: "",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.Editonchange()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.contact.address
                                                            .countryId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.contact.address,
                                                            "countryId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "contact.address.countryId",
                                                      },
                                                    }),
                                                    _c("v-autocomplete", {
                                                      staticClass: "mb-3",
                                                      attrs: {
                                                        tabindex: "16",
                                                        items: _vm.Editcities,
                                                        "item-text": "name",
                                                        "item-value": "id",
                                                        label: "City",
                                                        outlined: "",
                                                        "search-input":
                                                          _vm.searchEditCity,
                                                      },
                                                      on: {
                                                        "update:searchInput":
                                                          function ($event) {
                                                            _vm.searchEditCity =
                                                              $event
                                                          },
                                                        "update:search-input":
                                                          function ($event) {
                                                            _vm.searchEditCity =
                                                              $event
                                                          },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.contact.address
                                                            .cityId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.contact.address,
                                                            "cityId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "contact.address.cityId",
                                                      },
                                                    }),
                                                    _c("v-text-field", {
                                                      staticClass: "mb-3",
                                                      attrs: {
                                                        tabindex: "17",
                                                        outlined: "",
                                                        label: "Details",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.contact.address
                                                            .details,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.contact.address,
                                                            "details",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "contact.address.details",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "mb-3",
                                                      attrs: {
                                                        tabindex: "13",
                                                        outlined: "",
                                                        label:
                                                          "ZIP / Postal Code",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.contact.address
                                                            .postalCode,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.contact.address,
                                                            "postalCode",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "contact.address.postalCode",
                                                      },
                                                    }),
                                                    _c("v-autocomplete", {
                                                      staticClass: "mb-3",
                                                      attrs: {
                                                        tabindex: "15",
                                                        items: _vm.EditStates,
                                                        "item-text": "name",
                                                        "item-value": "id",
                                                        label: "State",
                                                        outlined: "",
                                                        "search-input":
                                                          _vm.searchEditState,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.EditSearchInCity()
                                                        },
                                                        "update:searchInput":
                                                          function ($event) {
                                                            _vm.searchEditState =
                                                              $event
                                                          },
                                                        "update:search-input":
                                                          function ($event) {
                                                            _vm.searchEditState =
                                                              $event
                                                          },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.contact.address
                                                            .stateId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.contact.address,
                                                            "stateId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "contact.address.stateId",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "cancel-update-btn",
                                        attrs: { tabindex: "18", text: "" },
                                        on: { click: _vm.close },
                                      },
                                      [_vm._v("Cancel")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "create-order-btn mr-1",
                                        attrs: { tabindex: "19" },
                                        on: { click: _vm.save },
                                      },
                                      [_vm._v("Update")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-snackbar",
                      {
                        attrs: { timeout: 3000, color: _vm.snackColor },
                        scopedSlots: _vm._u([
                          {
                            key: "action",
                            fn: function ({ attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._b(
                                    {
                                      attrs: { text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.snack = false
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  [_vm._v(" Close ")]
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.snack,
                          callback: function ($$v) {
                            _vm.snack = $$v
                          },
                          expression: "snack",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.snackText) + " ")]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "item.clientPhones",
                fn: function ({ item }) {
                  return _vm._l(item.clientPhones, function (ph, index) {
                    return _c("p", { key: index }, [
                      _c(
                        "span",
                        {
                          class: {
                            "is-Primary": ph.isPrimary,
                          },
                        },
                        [_vm._v(_vm._s(ph.phoneNumber))]
                      ),
                    ])
                  })
                },
              },
              {
                key: "item.contactType",
                fn: function ({ item }) {
                  return [
                    _c("p", [
                      _c("span", [_vm._v(_vm._s(item.contactType.name))]),
                    ]),
                  ]
                },
              },
              {
                key: "item.address",
                fn: function ({ item }) {
                  return [
                    item.address
                      ? _c("p", [
                          _c("span", [_vm._v(_vm._s(item.address.street))]),
                          _vm._v(" -"),
                          item.address.city
                            ? _c("span", [
                                _vm._v(_vm._s(item.address.city.name)),
                              ])
                            : _vm._e(),
                          _vm._v(" -"),
                          item.address.state
                            ? _c("span", [
                                _vm._v(_vm._s(item.address.state.target)),
                              ])
                            : _vm._e(),
                          _vm._v("-"),
                          item.address.country
                            ? _c("span", [
                                _vm._v(" " + _vm._s(item.address.country.name)),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-2 edit-icon",
                        attrs: { small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.editItem(item)
                          },
                        },
                      },
                      [_vm._v(" far fa-edit ")]
                    ),
                    _c(
                      "v-icon",
                      {
                        staticClass: "delete-icon",
                        attrs: { small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteItem(item)
                          },
                        },
                      },
                      [_vm._v(" mdi-delete ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "500px" },
              model: {
                value: _vm.dialogDelete,
                callback: function ($$v) {
                  _vm.dialogDelete = $$v
                },
                expression: "dialogDelete",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "text-h5" }, [
                    _vm._v("Are you sure you want to delete this item?"),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.deleteItemConfirm = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: { click: _vm.deleteItemConfirm },
                        },
                        [_vm._v("OK")]
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }