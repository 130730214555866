<template>
  <div id="work-order" class="handle-test">
    <div>
    <v-row>
      <v-col cols="12" md="10">
         <p class="table-title">Accounts List</p>
      </v-col>

      <v-col md="1" cols="12">
         <v-btn to="/account/create"
          class="btn-creation wo-diff-style"
            color="#D92020"
            >Create Account</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4" class="pad0">
         <v-text-field
            solo
            label="Search Account "
            append-icon="mdi-magnify"
            v-model="search"
            ref="objet"
            @keyup="realSearch(1)"
          ></v-text-field>
      </v-col>

      <v-col cols="12" md="8" class="pad0"> </v-col>
    </v-row>

    </div>

    <div>
      <v-data-table
      :loading="isLoaded" 
      loading-text="Loading... Please wait"
        :headers="headers"
        :items="data"
        class="elevation-1"
        item-key="name"
        hide-default-footer
        :items-per-page="pagination.pageSize"
        @update:options="handleOptionsUpdate"
      >
        <template v-slot:footer>
          <v-row class="mt-5 fit-width">
            <v-col md="2">
              <v-select
                :items="sizeOptions"
                v-model="pagination.pageSize"
                :item-value="'id'"
                :item-text="'name'"
                @change="realSearch(1)"
                solo
              ></v-select>
            </v-col>
            <v-col md="5">
              <v-pagination
                v-model="pagination.pageNumber"
                :length="totalPages"
                @input="realSearch(pagination.pageNumber)"
                total-visible="6"
              ></v-pagination>
            </v-col>
          </v-row>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Accounts</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Edit Account</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col md="12">
                        <v-text-field
                          solo
                          v-model="account.name"
                          label="Full Name"
                        ></v-text-field>
                        <v-autocomplete
                          solo
                          label="Select Client"
                          :items="clients"
                          :item-value="'id'"
                          :item-text="'name'"
                          v-model="account.clientId"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn text class="cancel-update-btn" @click="close"
                    >Cancel</v-btn
                  >

                  <v-btn class="create-order-btn mr-1" @click="save"
                    >Update</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.client="{ item }">
          <p>
            <span>{{ item.client.name }}</span>
          </p>
        </template>
        <template v-slot:item.actions="{ item }">
          <!-- <v-icon small class="mr-2 show-icon" @click="editItem(item)">
            far fa-eye
          </v-icon> -->
          <v-icon     
           small class="mr-2 edit-icon" @click="editItem(item)">
            far fa-edit
          </v-icon>
          <v-icon     
          class="delete-icon" small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        {{ snackText }}

        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>
<script>
import { dataService } from "@/services/data-service";


export default {
  data() {
    return {
      isLoaded: true,
      dialogDelete: false,
      snack: false,
      snackColor: "",
      snackText: "",
      columnIndex: 0,
      isDec: false,
      sizeOptions: [
        {
          id: 10,
          name: 10,
        },
        {
          id: 20,
          name: 20,
        },
        {
          id: 30,
          name: 30,
        },
        {
          id: 50,
          name: 50,
        },
        {
          id: 100,
          name: 100,
        },
      ],
      totalPages: 0,
      pagination: {
        pageNumber: 1,
        pageSize: 10,
      },
      dialog: false,
      editedIndex: null,
      clients: [],
      account: {
        clientId: null,
        name: "",
      },
      search: null,
      items: ["foo", "bar", "fizz", "buzz"],

      headers: [
        {
          text: "Name",
          align: "start",

          value: "name",
        },
        { text: "Client", value: "client" },

        { text: "Actions", value: "actions" },
      ],
      data: [],
      value: "",
      outputValue: "",
      timeout: null,
    };
  },
  methods: {
    deleteItem(item) {
      this.editedIndex = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      dataService.postDelete(
        "api/Accounts/delete/" + this.editedIndex,
        (response) => {
          if (response.data.succeeded) {
        
            this.dialogDelete = false;
            this.realSearch(1);
          }
        }
      );
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    handleOptionsUpdate({ sortBy, sortDesc }) {
      if (sortBy.length > 0) {
        if (sortBy[0] == "name") this.columnIndex = 0;
        if (sortBy[0] == "client") this.columnIndex = 1;

        this.isDec = sortDesc[0];

        this.realSearch(1);
      }
    },
    realSearch(size) {
      // clear timeout variable
    
      var self = this;
      self.pagination.pageNumber = size;
        this.isLoaded = true;

    

        dataService.getList(
          "api/Accounts/search_by_name",
          {
            pageNumber: size,
            pageSize: self.pagination.pageSize,
            subString: self.search,
            sortedColumnIndex: self.columnIndex,
            isDec: self.isDec,
          },
          (response) => {
         
            this.isLoaded = false;
            self.data = response.data.data.items;
            self.totalPages = response.data.data.totalPages;
            self.$nextTick(() => self.$refs.objet.focus());
                      

          }
        );
  
    },

    getClients() {
     // this.isLoaded = true
      dataService.getAll("api/Clients/get-all", (response) => {
     // this.isLoaded = false;
        this.clients = response.data;
      });
    },

    editItem(item) {
      this.editedIndex = item.id;
      this.getClients();
      dataService.getAll("api/Accounts/" + item.id, (response) => {
        this.account = response.data;

        this.dialog = true;
      });
    },
    save() {
      dataService.update("api/Accounts/update", this.account, (response) => {
        if (response.data.succeeded) {
          this.dialog = false;
          this.snack = true;
          this.snackColor = "success";
          this.snackText = "Data Updated";
          this.realSearch(1);
        }
      });
    },
    close() {
      this.dialog = false;
    },
  },
  mounted() {
    this.realSearch(1);
  },
};
</script>