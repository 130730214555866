import Vue from "vue";
import axios from "axios";
import router from "@/router";
import store from '@/store';


//const $domain = 'https://localhost:7130/'
//const $domain = 'https://axaportal.axapro.com/api/'
const $domain = '/api'


const $domain_api = $domain


const token = localStorage.getItem('tod_token')

const base = axios.create({
  baseURL: $domain_api,
  headers: !token ? {

    "Accept": "multipart/form-data",
    "Content-Type": "application/json",
  } : {
    "Content-Type": "application/json",
    'Authorization': 'Bearer ' + token
  }
})

// before a request is made start the nprogress
base.interceptors.request.use(config => {
  // store.dispatch('loader/show');
  // store.dispatch("toggleShowPreloader");

  return config
})

base.interceptors.response.use(function (response) {
  // store.dispatch("toggleShowPreloader");

  
  if (response.status == 200) {
     if (response.data.succeeded == true) {
    //   store.commit("SHOW_MESSAGE", {
    //     text: "Done Successfully",
    //     color: "success",
    //     timeout: 3000,
    //   });
      // window.location.reload();
      //store.dispatch("toggleHide");
      // store.dispatch("toggleHidePreloader");
    } else if (response.data.succeeded == false) {
      if (response.data.errors) {
        store.commit("SHOW_MESSAGE", {
           text:response.data.errors.toString(),
         
          color: "red darken-2",
          timeout: 3500,
        });
      }
    }

  }

  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  // store.dispatch('loader/hide');

  store.dispatch("toggleHide");
  // store.dispatch("toggleHidePreloader");

  return response;
}
  ,
  function (error) {
   //debugger; // eslint-disable-line no-debugger
    //    Any status codes that falls outside the range of 2xx cause this function to trigger
    //    Do something with response error
    const status = error.response.status
    const succeeded = error.response.data.succeeded



    if (
      error.response.config.url.indexOf("/admin/login") == -1
    ) {
      if (status == 401) {
        store.commit("SHOW_MESSAGE", {
          text: "Failed !",
          color: "red darken-2",
          timeout: 3500,
        });
        // store.commit("AuthModule/REMOVE_USER_DATA");
        // window.location.reload();
        // router.push("/login");
      }
      if (status == 403) {

        store.commit("AuthModule/REMOVE_USER_DATA");
        window.location.reload();

        router.push("/");
      }
     //debugger; // eslint-disable-line no-debugger
      if (succeeded == false) {
        store.commit("SHOW_MESSAGE", {
          text: "Failed !",
          color: "red darken-2",
          timeout: 3500,
        });
        // store.commit("AuthModule/REMOVE_USER_DATA");
        // window.location.reload();
        // router.push("/login");
      }


    }
   //debugger; // eslint-disable-line no-debugger
    // store.dispatch("toggleHidePreloader");
    if (succeeded == false) {
      store.commit("SHOW_MESSAGE", {
         text: error.response.data.data.toString(),
      
        color: "red darken-2",
        timeout: 3500,
      });
    }


    return Promise.reject(error.response.data);

  }
);


Vue.prototype.$http = base
Vue.prototype.$domain_api = $domain_api
Vue.prototype.$domain = $domain
export default base


