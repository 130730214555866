var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _vm.modalShow
        ? _c("v-progress-linear", {
            attrs: { indeterminate: "", color: "primary", value: "15" },
          })
        : _vm._e(),
      _vm.isLoggining && this.$route.meta.notRequiresAuth != true
        ? _c(
            "v-main",
            [
              _c(
                "v-card",
                { staticClass: "overflow-hidden" },
                [
                  !_vm.$vuetify.breakpoint.xsOnly
                    ? _c(
                        "v-app-bar",
                        {
                          attrs: {
                            absolute: "",
                            "elevate-on-scroll": "",
                            "scroll-target": "#scrolling-techniques-7",
                            color: "#22336B",
                          },
                        },
                        [
                          _c("v-toolbar-title", [
                            _c("img", {
                              staticClass: "header-logo",
                              attrs: {
                                src: require("@/assets/images/AXA LOGO TRANPARENT.png"),
                              },
                            }),
                          ]),
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "ml-8",
                                attrs: {
                                  text: "",
                                  dark: "",
                                  to: _vm.workOrder.to,
                                },
                              },
                              [
                                _c("span", { staticClass: "header-item" }, [
                                  _vm._v(" workOrders "),
                                ]),
                              ]
                            ),
                          ],
                          _vm.userInfo.userTypeId != 205 &&
                          _vm.userInfo.userTypeId != 203 &&
                          _vm.userInfo.userTypeId != 204
                            ? [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-1",
                                    attrs: {
                                      text: "",
                                      dark: "",
                                      to: _vm.tec.to,
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "header-item" }, [
                                      _vm._v(" Technicians "),
                                    ]),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                          _vm.userInfo.userTypeId != 205 ||
                          _vm.userInfo.userTypeId == 203 ||
                          _vm.userInfo.userTypeId == 204
                            ? [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-1",
                                    attrs: {
                                      text: "",
                                      dark: "",
                                      to: _vm.Contacts.to,
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "header-item" }, [
                                      _vm._v(" Contacts "),
                                    ]),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                          _vm.userInfo.userTypeId != 205 &&
                          _vm.userInfo.userTypeId != 203 &&
                          _vm.userInfo.userTypeId != 204
                            ? _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "ml-1",
                                                    attrs: {
                                                      text: "",
                                                      dark: "",
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "header-item",
                                                  },
                                                  [_vm._v("Clients")]
                                                ),
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-caret-down ml-1",
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    249267776
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    _vm._l(
                                      _vm.companyList,
                                      function (item, index) {
                                        return _c(
                                          "v-list-item",
                                          { key: index },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "dropdown-item menu-links",
                                                attrs: {
                                                  to: item.to,
                                                  text: "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "router-link-deco",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.title)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.userInfo.userTypeId != 201 &&
                          _vm.userInfo.userTypeId != 202 &&
                          _vm.userInfo.userTypeId != 205
                            ? _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "ml-1",
                                                    attrs: {
                                                      text: "",
                                                      dark: "",
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "header-item",
                                                  },
                                                  [_vm._v("Accounts")]
                                                ),
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-caret-down ml-1",
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3852611704
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    _vm._l(
                                      _vm.ClientList,
                                      function (item, index) {
                                        return _c(
                                          "v-list-item",
                                          { key: index },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "dropdown-item menu-links",
                                                attrs: {
                                                  to: item.to,
                                                  text: "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "router-link-deco",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.title)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.userInfo.userTypeId != 205 &&
                          _vm.userInfo.userTypeId != 203 &&
                          _vm.userInfo.userTypeId != 204
                            ? _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      dark: "",
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "header-item",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " TimeSheets/Invoices"
                                                    ),
                                                  ]
                                                ),
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-caret-down ml-1",
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2608212691
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    _vm._l(
                                      _vm.reportList,
                                      function (item, index) {
                                        return _c(
                                          "v-list-item",
                                          { key: index },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "dropdown-item menu-links",
                                                attrs: {
                                                  to: item.to,
                                                  text: "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "router-link-deco",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.title)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.userInfo.userTypeId != 205 &&
                          _vm.userInfo.userTypeId != 203 &&
                          _vm.userInfo.userTypeId != 204
                            ? _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "ml-1",
                                                    attrs: {
                                                      text: "",
                                                      dark: "",
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "header-item",
                                                  },
                                                  [_vm._v("Reports")]
                                                ),
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-caret-down ml-3",
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2197946773
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    _vm._l(_vm.Reports, function (item, index) {
                                      return _c(
                                        "v-list-item",
                                        { key: index },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "dropdown-item menu-links ml-1",
                                              attrs: { to: item.to, text: "" },
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "router-link-deco",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "ml-2" },
                                                    [_vm._v(_vm._s(item.title))]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-spacer"),
                          _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { text: "", dark: "" } },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "header-item" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.userInfo.userName
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c("i", {
                                              staticClass:
                                                "fas fa-caret-down ml-1",
                                            }),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1628992189
                              ),
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  _vm._l(_vm.userList, function (item, index) {
                                    return _c(
                                      "v-list-item",
                                      { key: index },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "dropdown-item menu-links pb-4",
                                            attrs: { to: item.to, text: "" },
                                          },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "router-link-deco",
                                                attrs: { to: item.to },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "ml-2" },
                                                  [_vm._v(_vm._s(item.title))]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "dropdown-item menu-links",
                                          attrs: { text: "" },
                                          on: { click: _vm.logout },
                                        },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "router-link-deco" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "ml-2" },
                                                [_vm._v("Logout")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _c(
                        "v-app-bar",
                        {
                          attrs: {
                            absolute: "",
                            "elevate-on-scroll": "",
                            "scroll-target": "#scrolling-techniques-7",
                            color: "#172450",
                          },
                        },
                        [
                          _c("v-app-bar-nav-icon", {
                            attrs: { color: "white" },
                            on: {
                              click: function ($event) {
                                _vm.rspnsvMenu = !_vm.rspnsvMenu
                              },
                            },
                          }),
                          _vm.rspnsvMenu
                            ? _c(
                                "div",
                                { staticClass: "responsive-menu" },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-home"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-title",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "dropdown-item menu-links",
                                                  attrs: { text: "" },
                                                },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "router-link-deco",
                                                      attrs: {
                                                        to: _vm.workOrder.to,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-2" },
                                                        [_vm._v("WorkOrders")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-home"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-title",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "dropdown-item menu-links",
                                                  attrs: { text: "" },
                                                },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "router-link-deco",
                                                      attrs: { to: _vm.tec.to },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-2" },
                                                        [_vm._v("Technicians")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-home"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-title",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "dropdown-item menu-links",
                                                  attrs: { text: "" },
                                                },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "router-link-deco",
                                                      attrs: {
                                                        to: _vm.Contacts.to,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-2" },
                                                        [_vm._v("Contacts")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-home"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-title",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "dropdown-item menu-links",
                                                  attrs: { text: "" },
                                                },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "router-link-deco",
                                                      attrs: {
                                                        to: "/clients/list",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-2" },
                                                        [_vm._v("Clients")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-home"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-title",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "dropdown-item menu-links",
                                                  attrs: { text: "" },
                                                },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "router-link-deco",
                                                      attrs: {
                                                        to: "/accounts/list",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-2" },
                                                        [_vm._v("Accounts")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-home"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-title",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "dropdown-item menu-links",
                                                  attrs: { text: "" },
                                                },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "router-link-deco",
                                                      attrs: {
                                                        to: "/companies/list",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-2" },
                                                        [_vm._v("Companies")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-home"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-title",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "dropdown-item menu-links",
                                                  attrs: { text: "" },
                                                },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "router-link-deco",
                                                      attrs: {
                                                        to: "/locations/list",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-2" },
                                                        [_vm._v("Locations")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-group",
                                        {
                                          attrs: {
                                            value: true,
                                            "prepend-icon":
                                              "mdi-account-circle",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function () {
                                                  return [
                                                    _c("v-list-item-title", [
                                                      _vm._v("Settings"),
                                                    ]),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            3742243682
                                          ),
                                        },
                                        [
                                          _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-home"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c("v-list-item-title", [
                                                _vm._v("Notification"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-home"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c("v-list-item-title", [
                                                _vm._v("Permissions"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-group",
                                            {
                                              attrs: {
                                                value: true,
                                                "no-action": "",
                                                "sub-group": "",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [_vm._v("Users")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                1440671018
                                              ),
                                            },
                                            [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v("Create User"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                  _c(
                    "v-sheet",
                    {
                      staticClass: "overflow-y-auto",
                      attrs: {
                        id: "scrolling-techniques-7",
                        "max-height": "100vh",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "main-view",
                          staticStyle: { height: "100vh", "margin-top": "4%" },
                        },
                        [_c("router-view")],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isLoggining || this.$route.meta.notRequiresAuth == true
        ? _c("v-main", [_c("router-view")], 1)
        : _vm._e(),
      _c("Snackbar"),
      _vm.loading ? _c("Loader") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }