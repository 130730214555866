<template>
  <div class="upload-section" id="users">
    <SideMenu v-if="!$vuetify.breakpoint.xsOnly"></SideMenu>
    <div class="user-creation ml-4 mt-4">
      <!-- <v-row>
        <v-col md="9"></v-col>
        <v-col cols="12" md="3"> </v-col>
      </v-row> -->
       <v-row>
      <v-col cols="12" md="10">
        <p class="table-title">Email Templates</p>
      </v-col>

      <v-col md="1" cols="12">
        <v-btn
         
          to="/settings/CreateEmailTemplatesSettings"
          class="btn-creation wo-diff-style"
          color="#D92020"
          >Create Template
        </v-btn>
      </v-col>
    </v-row>
      <div>
        <br />
        <v-data-table
          :headers="headers"
          :items="data"
          class="elevation-1"
          item-key="id"
        
          @click:row="rowClick"
        >
        </v-data-table>
      </div>
    </div>
  </div>
</template>
<script>
import { dataService } from "@/services/data-service";
import SideMenu from "@/components/SettingsSideMenu.vue";

export default {
  data() {
    return {
      selectedUsers: [],

      value: null,
      headers: [
        {
          text: "Name",

          value: "name",
        },
        {
          text: "Type",

          value: "type.name",
        },
        {
          text: "Description",

          value: "description",
        },
      ],
      data: [],
      dialogDelete: false,
      itemID: null,
    };
  },
  methods: {
    rowClick: function (item, row) {
      localStorage.emailtemplate=item.type.name;
      row.select(true);
      this.$router.push("/settings/EmailTemplatesSettingsEdit/" + item.id);
    },

    getData() {
      dataService.getAll(
        "api/Mails/templates",

        (response) => {
          this.data = response.data;
        }
      );
    },
  },
  mounted() {
    this.getData();
  },
  components: {
    SideMenu,
  },
};
</script>