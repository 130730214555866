<template>
  <div class="handle-test">
    <p class="table-title">Invoice</p>
    <div class="innner-invoice-container">
      <v-row>
        <v-col md="8" sm="8">
          <v-col md="4">
            <p class="label2-key">Accounts</p>
            <span v-for="item in invoice.workOrders" :key="item.id"
            class="Value-data"> {{item.account.name}},</span>
          </v-col>
          
        </v-col>
        <v-col md="4" sm="4">
          <v-col md="10" class="auto">
            <v-btn
              class="btn-inv"
              @click="sendByemail"
              >Send by Email</v-btn
            >

            <p class="lbl-inv">Order Status: {{invoice.status.name}}</p>
              <p class="lbl-inv">creation Date:  {{ new Date(invoice.creationDate).toLocaleString() }}</p>
          </v-col>
        </v-col>
        <v-col md="4" sm="4"></v-col>
      </v-row>
      <v-row>
        <v-col sm="12" md="12">
          <v-card flat>
            <div class="invoice-card">
              <v-row>
                <v-col md="3">
                  <p class="invoice-table-headers">Invoice Number</p>
                  <p class="invoice-table-values">
                   
                    {{ invoice.invoiceNumber}}
                  </p>
                </v-col>
                <v-col class="invoice-table-headers" md="2">
                  <p>Total Invoice cost</p>
                  <p class="invoice-table-values">
                     {{ invoice.totalBillRate}}
                  </p>
                </v-col>
                <v-col class="invoice-table-headers" md="2">
                  <p>Expenses cost</p>
                  <p class="invoice-table-values">
                      {{ invoice.totalExpenses}}
                  
                  </p>
                </v-col>

                <v-col class="invoice-table-headers" md="2">
                  <p>No. of hrs</p>
                  <p class="invoice-table-values">
                   {{ invoice.totalHours}}
                  </p>
                </v-col>

                   <v-col class="invoice-table-headers" md="2">
                  <p>No. of Technician</p>
                  <p class="invoice-table-values">
                   {{ invoice.numberOfTechnicians}}
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="4" sm="4">
          <v-col md="8" sm="12" class="auto">
            <span class="Value2-data">Total :{{invoice.totalBillRate}} $</span>
           
          </v-col>
          
        </v-col>
        <v-col md="4" sm="4">
        
        </v-col>
        <v-col md="4" sm="4">
          <v-col md="10" class="auto">
            <v-btn
              class="btn-inv"
              @click="backToTimeSheet"
              >Back to TimeSheet</v-btn
            >
          </v-col>
        </v-col>
       
      </v-row>
    </div>
  </div>
</template>

<script>
import { dataService } from "@/services/data-service";
export default {

  data: () => ({
    invoice:{}
    
  }),
   methods: 
   {   
    
    getInvoice(id)
   {
   dataService.getAll("api/Invoices/" + id, (response) => {
        this.invoice = response.data;
        
       
      });

   },
   backToTimeSheet(){
    this.$router.push({ name:"adminTimesheet"});

   }
,sendByemail(){}

   },
    mounted() {
  var invoiceId= this.$router.currentRoute.params.id;
    this.getInvoice(invoiceId);
    
  },

};
</script>