var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isOpenB
      ? _c(
          "div",
          { staticClass: "test" },
          [
            _c("v-icon", { staticClass: "btnbar", on: { click: _vm.click } }, [
              _vm._v("X"),
            ]),
            _c(
              "div",
              { staticClass: "px-3 py-2" },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "FinRepo-sty",
                        attrs: { cols: "12", md: "8" },
                      },
                      [
                        _c("p", { staticClass: "table-title" }, [
                          _vm._v("Financial Report"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c("p", { staticClass: "Report-color" }, [
                  _vm._v("Service date :"),
                ]),
                _c("br"),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "FinRepo-sty",
                        staticStyle: { "margin-bottom": "-14px" },
                        attrs: { cols: "12", md: "12" },
                      },
                      [
                        _c("v-autocomplete", {
                          ref: "clientSearch",
                          attrs: {
                            multiple: "",
                            outlined: "",
                            label: "Custom date",
                            items: _vm.clients,
                            "item-text": "name",
                            "item-value": "id",
                          },
                          on: { change: _vm.handleClientFilter },
                          model: {
                            value: _vm.clientIds,
                            callback: function ($$v) {
                              _vm.clientIds = $$v
                            },
                            expression: "clientIds",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { staticStyle: { "margin-bottom": "-28px" } },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "FinRepo-sty",
                        attrs: { cols: "6", md: "6" },
                      },
                      [
                        _c("v-autocomplete", {
                          ref: "DateFrom",
                          attrs: {
                            "prepend-inner-icon": "mdi-calendar",
                            multiple: "",
                            outlined: "",
                            label: "From",
                            items: _vm.From,
                            "item-text": "name",
                            "item-value": "id",
                          },
                          on: { change: _vm.handleClientFilter },
                          model: {
                            value: _vm.clientIds,
                            callback: function ($$v) {
                              _vm.clientIds = $$v
                            },
                            expression: "clientIds",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "FinRepo-sty",
                        attrs: { cols: "6", md: "6" },
                      },
                      [
                        _c("v-autocomplete", {
                          ref: "DateTo",
                          attrs: {
                            "prepend-inner-icon": "mdi-calendar",
                            multiple: "",
                            outlined: "",
                            label: "To",
                            items: _vm.To,
                            "item-text": "name",
                            "item-value": "id",
                          },
                          on: { change: _vm.handleClientFilter },
                          model: {
                            value: _vm.clientIds,
                            callback: function ($$v) {
                              _vm.clientIds = $$v
                            },
                            expression: "clientIds",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("hr"),
                _c("br"),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "reportCols",
                        attrs: { cols: "12", md: "8" },
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass: "Report-color",
                            staticStyle: { padding: "15px" },
                          },
                          [_vm._v("Payment Type:")]
                        ),
                        _c("br"),
                      ]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "pad0", attrs: { cols: "12", md: "12" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "reportCols",
                                staticStyle: { "margin-bottom": "-16px" },
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c("v-checkbox", {
                                  staticClass: "reportCols",
                                  attrs: { label: "w2", color: "#4B5568" },
                                  model: {
                                    value: _vm.isLongTerm,
                                    callback: function ($$v) {
                                      _vm.isLongTerm = $$v
                                    },
                                    expression: "isLongTerm",
                                  },
                                }),
                                _c("v-checkbox", {
                                  staticClass: "reportCols",
                                  attrs: { label: "1099", color: "#4B5568" },
                                  model: {
                                    value: _vm.isLongTerm,
                                    callback: function ($$v) {
                                      _vm.isLongTerm = $$v
                                    },
                                    expression: "isLongTerm",
                                  },
                                }),
                                _c("v-checkbox", {
                                  staticClass: "reportCols",
                                  attrs: { label: "vendor", color: "#4B5568" },
                                  model: {
                                    value: _vm.isLongTerm,
                                    callback: function ($$v) {
                                      _vm.isLongTerm = $$v
                                    },
                                    expression: "isLongTerm",
                                  },
                                }),
                                _c("v-checkbox", {
                                  staticClass: "reportCols",
                                  attrs: { label: "TEMP", color: "#4B5568" },
                                  model: {
                                    value: _vm.isLongTerm,
                                    callback: function ($$v) {
                                      _vm.isLongTerm = $$v
                                    },
                                    expression: "isLongTerm",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("br"),
                _c("hr"),
                _c("br"),
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { cols: "12", md: "8" } }, [
                      _c("p", { staticClass: "FinRepo-sty" }, [
                        _vm._v("Filter By technician"),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "FinRepo-sty",
                    attrs: { cols: "12", md: "12" },
                  },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", md: "12" } },
                          [
                            _c("v-autocomplete", {
                              ref: "techassign",
                              staticClass: "mb-2",
                              attrs: {
                                items: _vm.technicians,
                                chips: "",
                                solo: "",
                                color: "blue-grey lighten-2",
                                label: "Select a technician",
                                "item-text": (item) =>
                                  item.firstName + " " + item.lastName,
                                "item-value": "id",
                                multiple: "",
                                "search-input": _vm.search,
                              },
                              on: {
                                "update:searchInput": function ($event) {
                                  _vm.search = $event
                                },
                                "update:search-input": function ($event) {
                                  _vm.search = $event
                                },
                              },
                              model: {
                                value: _vm.techIds,
                                callback: function ($$v) {
                                  _vm.techIds = $$v
                                },
                                expression: "techIds",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("hr"),
                _c("br"),
                _c(
                  "v-row",
                  { staticStyle: { "padding-top": "3%" } },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "FinRepo-sty",
                        attrs: { cols: "12", md: "8" },
                      },
                      [_c("p", {}, [_vm._v("Grouped by Account")])]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "FinRepo-sty",
                    attrs: { cols: "12", md: "12" },
                  },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", md: "12" } },
                          [
                            _c("v-autocomplete", {
                              ref: "accountSearch",
                              attrs: {
                                items: _vm.accounts,
                                "item-text": "name",
                                "item-value": "id",
                                multiple: "",
                                outlined: "",
                                label: "select Accounts",
                                "search-input": _vm.accountsearch,
                              },
                              on: {
                                "update:searchInput": function ($event) {
                                  _vm.accountsearch = $event
                                },
                                "update:search-input": function ($event) {
                                  _vm.accountsearch = $event
                                },
                                change: function ($event) {
                                  return _vm.getData()
                                },
                              },
                              model: {
                                value: _vm.accountIds,
                                callback: function ($$v) {
                                  _vm.accountIds = $$v
                                },
                                expression: "accountIds",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "test2", attrs: { id: "fixed" } },
      [
        !_vm.isOpenB
          ? _c("v-icon", { on: { click: _vm.click } }, [
              _vm._v("fa-regular fa-bars click"),
            ])
          : _vm._e(),
        _c("span", { staticStyle: { margin: "auto" } }, [
          _vm._m(0),
          _c(
            "table",
            {
              staticClass: "FinancialTab table table-hover",
              staticStyle: {
                "text-align": "center",
                margin: "auto",
                "margin-top": "5%",
              },
            },
            [
              _vm._m(1),
              _c(
                "tbody",
                _vm._l(_vm.Report, function (item) {
                  return _c("tr", { key: item.invoiceId }, [
                    _c("td", { staticStyle: { "padding-right": "100px" } }, [
                      _vm._v(_vm._s(item.accountName)),
                    ]),
                    _c(
                      "td",
                      {
                        staticClass: "colsrowpad",
                        staticStyle: {
                          "padding-bottom": "4% !important",
                          "padding-top": "4%",
                        },
                      },
                      [_vm._v(" " + _vm._s(item.invoiceCreationDate))]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "colsrowpad",
                        staticStyle: {
                          "padding-bottom": "4% !important",
                          "padding-top": "4%",
                        },
                      },
                      _vm._l(item.techPaymentTypeDtos, function (item2) {
                        return _c("div", { key: item2.technicianName }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                item2.technicianName + " " + item2.paymentType
                              ) +
                              " "
                          ),
                        ])
                      }),
                      0
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "colsrowpad",
                        staticStyle: {
                          "padding-bottom": "4% !important",
                          "padding-top": "4%",
                        },
                      },
                      [_vm._v(_vm._s(item.totalBillRate) + " ")]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "colsrowpad",
                        staticStyle: {
                          "padding-bottom": "4% !important",
                          "padding-top": "4%",
                        },
                      },
                      [_vm._v(_vm._s(item.totalPayRates) + " ")]
                    ),
                  ])
                }),
                0
              ),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticClass: "FinancialTab",
        staticStyle: { "text-align": "center", padding: "20%" },
      },
      [
        _c("span", { staticStyle: { color: "#1b7bf9" } }, [
          _vm._v("Report from"),
        ]),
        _vm._v(" Jan 1,2022 "),
        _c("span", { staticStyle: { color: "#1b7bf9" } }, [_vm._v("to")]),
        _vm._v(" March 20,2022 "),
        _c("span", { staticStyle: { color: "lightslategrey" } }, [
          _vm._v("Custom date"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticStyle: { height: "50px" } }, [
      _c("tr", [
        _c("th", { staticClass: "colsrowpad", attrs: { scope: "col" } }, [
          _vm._v("Account"),
        ]),
        _c("th", { staticClass: "colsrowpad", attrs: { scope: "col" } }, [
          _vm._v("Date"),
        ]),
        _c("th", { staticClass: "colsrowpad", attrs: { scope: "col" } }, [
          _vm._v("tech name / Type"),
        ]),
        _c("th", { staticClass: "colsrowpad", attrs: { scope: "col" } }, [
          _vm._v("Bill Rate"),
        ]),
        _c("th", { staticClass: "colsrowpad", attrs: { scope: "col" } }, [
          _vm._v("Pay rate"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }