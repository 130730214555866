<template>
  <div class="upload-section" id="users">
    <SideMenu v-if="!$vuetify.breakpoint.xsOnly"></SideMenu>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div id="security" class="user-creation ml-4 mt-4">
      <v-switch v-model="faSwitch" inset label="Two Factor Authentication" color="#2A8D3D" @change="Mainswitcht()">
      </v-switch>

      <v-switch v-if="faSwitch" v-model="emailCode" inset label="Send Code via Email" color="#2A8D3D"
        @click="switchtwofact(1, emailCode)"></v-switch>
      <v-switch v-if="faSwitch" v-model="phoneCode" inset label="Send Code via Phone Number" color="#2A8D3D"
        @click="switchtwofact(2, phoneCode)"></v-switch>
    </div>
  </div>
</template>
<script>
import SideMenu from "@/components/SettingsSideMenu.vue";
import { dataService } from "@/services/data-service";
// import { required, email } from "vuelidate/lib/validators";
//import store from "@/store";
export default {
  data() {
    return {
      loading:true,
      faSwitch: false,
      phoneCode: false,
      emailCode: false,
    };
  },

  methods: {
    Get2fa() {
      dataService.getAll(
        "api/SystemUsers/current-2fa",
        (response) => {
          let data = response.data;

          //console.log(data)
          this.faSwitch = data.twoFactorEnabled
         
          if (data.twoFATypeId == 2) {
            this.phoneCode = true;
            this.emailCode = false;

          }
          if (data.twoFATypeId == 1) {
            this.phoneCode =false;
            this.emailCode =true;

          }
this.loading=false;


        }
      );
    },
    Mainswitcht() {
      let obj = {


        twoFactorEnabled: this.faSwitch,
      }
      dataService.create(
        "/api/SystemUsers/update-current-user-2fa",
        obj,
        () => {
          //console.log(response);

          // this.workOrder.orderTypeId = response.data.data;

          // store.commit("SHOW_MESSAGE", {
          //   text: "Created Successfully",
          //   color: "success",
          //   timeout: 3000,
          // });
        }
      );

    },
    switchtwofact(index, Value) {
    
      let obj = {

        twoFATypeId: 0,
        twoFactorEnabled: this.faSwitch,
      }

      obj.twoFATypeId = index;
      if (index == 1 && Value) { this.phoneCode = false; }
      if (index == 2 && Value) { this.emailCode = false; }


      dataService.create(
        "/api/SystemUsers/update-current-user-2fa",
        obj,
        () => {
          //console.log(response);

          // this.workOrder.orderTypeId = response.data.data;

          // store.commit("SHOW_MESSAGE", {
          //   text: "Created Successfully",
          //   color: "success",
          //   timeout: 3000,
          // });
        }
      );


    },
  },
  components: {
    SideMenu,
  },
  mounted() {
    this.Get2fa();
  },

};
</script>