var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outer-page" },
    [
      _c("p", { staticClass: "timesheet-lable" }, [_vm._v("Your Timesheet")]),
      _c(
        "div",
        _vm._l(_vm.timesheet, function (item, index) {
          return _c("div", { key: item.id, staticClass: "mb-6" }, [
            _c(
              "div",
              { staticClass: "top-box" },
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { md: "9" } }, [
                      _c("p", { staticClass: "clocking-date" }, [
                        _vm._v(_vm._s(item.date)),
                      ]),
                    ]),
                    _c("v-col", { attrs: { md: "3" } }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "clocking-box" },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { md: "5" } },
                      [
                        _c("v-text-field", {
                          staticClass: "description-feild",
                          attrs: {
                            outlined: "",
                            label: "What are you working on?",
                          },
                          model: {
                            value: item.scopeOfFinishWork,
                            callback: function ($$v) {
                              _vm.$set(item, "scopeOfFinishWork", $$v)
                            },
                            expression: "item.scopeOfFinishWork",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { md: "1", id: "time-box" } },
                      [
                        _c("vue-timepicker", {
                          attrs: { format: "HH:mm", "manual-input": "" },
                          model: {
                            value: item.startTime,
                            callback: function ($$v) {
                              _vm.$set(item, "startTime", $$v)
                            },
                            expression: "item.startTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { md: "1", id: "time-box" } },
                      [
                        _c("vue-timepicker", {
                          attrs: { format: "HH:mm", "manual-input": "" },
                          model: {
                            value: item.endTime,
                            callback: function ($$v) {
                              _vm.$set(item, "endTime", $$v)
                            },
                            expression: "item.endTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { md: "1" } },
                      [
                        _c(
                          "v-menu",
                          {
                            ref: index,
                            refInFor: true,
                            attrs: {
                              "close-on-content-click": false,
                              "return-value": item.date,
                              transition: "scale-transition",
                              "offset-y": "",
                              "min-width": "300",
                            },
                            on: {
                              "update:returnValue": function ($event) {
                                return _vm.$set(item, "date", $event)
                              },
                              "update:return-value": function ($event) {
                                return _vm.$set(item, "date", $event)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "i",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "fas fa-calendar-alt day-icon",
                                            },
                                            "i",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: item.menu,
                              callback: function ($$v) {
                                _vm.$set(item, "menu", $$v)
                              },
                              expression: "item.menu",
                            },
                          },
                          [
                            _c(
                              "v-date-picker",
                              {
                                attrs: { "no-title": "", scrollable: "" },
                                on: {
                                  input: function ($event) {
                                    item.menu = false
                                  },
                                },
                                model: {
                                  value: item.date,
                                  callback: function ($$v) {
                                    _vm.$set(item, "date", $$v)
                                  },
                                  expression: "item.date",
                                },
                              },
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "", color: "primary" },
                                    on: {
                                      click: function ($event) {
                                        item.menu = false
                                      },
                                    },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { md: "2", id: "time-box" } },
                      [
                        _c("vue-timepicker", {
                          attrs: { format: "HH:mm:ss", "manual-input": "" },
                          model: {
                            value: item.total,
                            callback: function ($$v) {
                              _vm.$set(item, "total", $$v)
                            },
                            expression: "item.total",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { md: "1" } },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "delete-timesheet",
                            attrs: { color: "#A23D3D" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteItem(item)
                              },
                            },
                          },
                          [_vm._v("mdi-delete")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "btn-container" },
        [
          _c(
            "v-btn",
            {
              staticClass: "create-order-btn",
              on: { click: _vm.createTimeSheet },
            },
            [_vm._v("Send To Approve")]
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.dialogDelete,
            callback: function ($$v) {
              _vm.dialogDelete = $$v
            },
            expression: "dialogDelete",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v(
                  "Are you sure you want to delete this item From Timesheet?"
                ),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.closeDelete },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.deleteItemConfirm },
                    },
                    [_vm._v("OK")]
                  ),
                  _c("v-spacer"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }