<template   >
  <div id="settings">
    <v-navigation-drawer height="100vh" v-model="drawer" :mini-variant.sync="mini" permanent>
      <v-list dense>
        <v-list-item>
          <v-list-item-icon>
            <div v-if="!mini" class="user-info">
              <p style="width:209%">{{ userInfo.userName }}</p>
              <p style="width:209%">{{ userInfo.email }}</p>
            </div>
            <v-icon class="pointer" @click="mini = !mini">fas fa-bars</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <!-- ========= Admin  all data  201 ================ -->
        <div class="mt-7" v-if="userInfo.userTypeId == 201">
          <v-divider class="pt-4"></v-divider>
          <div v-for="item in items" :key="item.title">
            <v-list-item link :to="item.to" :class="[$route.fullPath == item.to ? 'active-item' : '']">
              <v-list-item-icon>
                <v-icon :class="[$route.fullPath == item.to ? 'active-color' : '']" color="#5B5B5B">{{ item.icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  <router-link :class="[$route.fullPath == item.to ? 'active-color' : '']" class="side-menu-item"
                    :to="item.to">{{ item.title }}
                  </router-link>

                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>


        </div>
       
         <!-- ========= Client Admin && Rep profile ================ -->
         <div class="mt-7" v-else-if="userInfo.userTypeId == 204 || userInfo.userTypeId == 203||userInfo.userTypeId == 202">
          <v-divider class="pt-4"></v-divider>
          <div v-for="item in itemsClientAdminRep" :key="item.title">
            <v-list-item link :to="item.to" :class="[$route.fullPath == item.to ? 'active-item' : '']">
              <v-list-item-icon>
                <v-icon :class="[$route.fullPath == item.to ? 'active-color' : '']" color="#5B5B5B">{{ item.icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  <router-link :class="[$route.fullPath == item.to ? 'active-color' : '']" class="side-menu-item"
                    :to="item.to">{{ item.title }}
                  </router-link>

                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>


        </div>

        <!-- =========  User technician profile ================ -->
        <div class="mt-7" v-else>
          <v-divider class="pt-4"></v-divider>
          <div v-for="item in itemsUser" :key="item.title">
            <v-list-item link :to="item.to" :class="[$route.fullPath == item.to ? 'active-item' : '']">
              <v-list-item-icon>
                <v-icon :class="[$route.fullPath == item.to ? 'active-color' : '']" color="#5B5B5B">{{ item.icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  <router-link :class="[$route.fullPath == item.to ? 'active-color' : '']" class="side-menu-item"
                    :to="item.to">{{ item.title }}
                  </router-link>

                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>


        </div>

      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      drawer: true,

      items: [
        { title: "Your Account", icon: "mdi-account", to: "/settings/profile" },
        {
          title: "Notifications",
          icon: "mdi-bell",
          to: "/settings/notifications",
        },
        {
          title: "Permissions",
          icon: "mdi-check-circle",
          to: "/settings/permissions",
        },
        {
          title: "Users",
          icon: "fas fa-users",
          to: "/settings/users",
        },
        {
          title: "SMTP",
          icon: "fas fa-cog",
          to: "/smtp/list",
        },
        {
          title: "Email Templates",
          icon: "fa-solid fa-envelope",
          to: "/settings/EmailSettings",
        },
        {
          title: "Security",
          icon: "fas fa-lock",
          to: "/settings/security",
        },
      ],

      //========= client admin && client rep 203 && 204
      itemsClientAdminRep: [
        
        {
          title: "Your Account",
          icon: "mdi-account",
          to: "/settings/profile"
        },
          {
          title: "Notifications",
          icon: "mdi-bell",
          to: "/settings/notifications",
        },
        {
          title: "Users",
          icon: "fas fa-users",
          to: "/settings/users",
        },
        {
          title: "Security",
          icon: "fas fa-lock",
          to: "/settings/security",
        },
      ],


      itemsUser: [
        { title: "Your Account", icon: "mdi-account", to: "/settings/profile" }
      ],


      mini: false,
    };
  },
  mounted() {
    this.check();
  },
  methods: {
    check() {
      var link = this.$route.fullPath;
      if (link == "/settings/permissions") {
        this.mini = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      userInfo: "AuthModule/userData",
    }),
  },
};
</script>