<template>
  <div class="handle-test">
    <p class="table-title">New Contact</p>
    <div class="innner-container">

     <!-- =========== btn reset me =======-->
        <v-row>
          <v-col md="10"></v-col>
          <v-col md="2">
            <v-btn @click="emptyButtons" class="resetWO-btn">
              <i class="fas fa-redo"></i> <span class="ml-1">Reset</span>
            </v-btn>
          </v-col>
        </v-row>

      <v-row>
        <v-col cols="12" md="12">
          <v-text-field
          tabindex="1"
            :class="{
              'is-invalid': submitted && $v.contact.firstName.$error,
            }"
            v-model="contact.firstName"
            outlined
            class="mb-3"
            label="Full Name"
          ></v-text-field>
          <p
            v-if="submitted && !$v.contact.firstName.required"
            class="invalid-feedback"
          >
            First Name is Required
          </p>
        </v-col>

      
        <v-col cols="12" md="6">
          <v-autocomplete
          tabindex="2"
            ref="clientSearch"
            outlined
            label="Select Client"
            :items="clients"
            item-text="name"
            item-value="id"
            v-model="contact.ClientId"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
          tabindex="3"
            ref="accountSearch"
            :items="accounts"
            item-text="name"
            item-value="id"
            v-model="contact.AccountId"
            outlined
            label="Select Account"
            :search-input.sync="accountsearch"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
          tabindex="4"
            outlined
            v-model="contact.companyId"
            label="Select Company"
            :items="companies"
            :item-value="'id'"
            :item-text="'name'"
            :search-input.sync="Companysearch"
            ref="lcn"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
          tabindex="5"
            outlined
            :items="locations"
            :item-text="'name'"
            :item-value="'id'"
            label="Select Location"
            v-model="contact.branchId"
            :search-input.sync="search"
            ref="locationSearch"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
          tabindex="6"
            :items="contactTypes"
            :item-text="'name'"
            :item-value="'id'"
            label="Contact Type"
            outlined
            :class="{
              'is-invalid': submitted && $v.contact.contactTypeId.$error,
            }"
            v-model="contact.contactTypeId"
          >
          </v-autocomplete>
          <p
            v-if="submitted && !$v.contact.contactTypeId.required"
            class="invalid-feedback"
          >
            Contact Type Is Required
          </p>

          <v-text-field
          tabindex="8"
            outlined
            class="mb-3"
            v-model="contact.contactPhones[0].phoneNumber"
            :set="(v = $v.contact.contactPhones.$each[0])"
            :class="{
              'is-invalid': submitted && v.phoneNumber.$error,
            }"
            label="Primary Phone"
            @blur="formatPhoneNumber(1)"
          ></v-text-field>
          <p
            v-if="submitted && !v.phoneNumber.required"
            class="invalid-feedback"
          >
            Primary Phone Is Required
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
          tabindex="7"
            :class="{
              'is-invalid': submitted && $v.contact.email.$error,
            }"
            v-model="contact.email"
            outlined
            class="mb-3"
            label="Email"
          ></v-text-field>
          <p
            v-if="submitted && !$v.contact.email.required"
            class="invalid-feedback"
          >
            Email Is Required
          </p>
          <p
            v-if="submitted && !$v.contact.email.email"
            class="invalid-feedback"
          >
            Email Is Not Valid
          </p>
          <v-text-field
          tabindex="9"
            outlined
            class="mb-3"
            v-model="secondaryPhone"
            label="Secondary Phone"
            @blur="formatPhoneNumber(2)"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- ====================================================== -->

      <v-row>
        <v-col   md="6" style="margin-top: -6%">
          <v-checkbox tabindex="10" v-model="contact.approveInvoice" label="Approve Invoice"></v-checkbox>
        </v-col>
        <v-col md="6" style="margin-top: -6%">
          <v-checkbox
          tabindex="11"
            v-model="contact.approveTimeSheet"
            label="Approve Timesheet"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="6"> </v-col>
      </v-row>
      <v-divider class="mb-10"></v-divider>
      <div>
        <v-row>
           <v-col cols="12" md="6">
                <v-text-field
              outlined
              class="mb-3"
              tabindex="12"
              v-model="contact.address.street"
              label="Street"
            ></v-text-field>
           </v-col>
            <v-col cols="12" md="6">
                <v-text-field
            tabindex="14"
              v-model="contact.address.postalCode"
              outlined
              class="mb-3"
              label="ZIP / Postal Code"
            ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
           <v-col cols="12" md="6">
                   <v-text-field
            tabindex="15"
              v-model="contact.address.details"
              outlined
              class="mb-3"
              label="Details"
            ></v-text-field>

           </v-col>
            <v-col cols="12" md="6">
            </v-col>
        </v-row>
        <v-row>
           <v-col cols="12" md="6">
                <v-autocomplete
            tabindex="16"
              :items="countries"
              :item-text="'name'"
              :item-value="'id'"
              label="Country"
              outlined
              v-model="contact.address.countryId"
              class="mb-3"
              @change="getStates"
            >
            </v-autocomplete>

           </v-col>
            <v-col cols="12" md="6">
                 <v-autocomplete
            tabindex="17"
              :items="states"
              :item-text="'name'"
              :item-value="'id'"
              label="State"
              outlined
              v-model="contact.address.stateId"
              class="mb-3"
              @change="getCities"
            >
            </v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
           <v-col cols="12" md="6">
                <v-autocomplete
            tabindex="18"
              :items="cities"
              :item-text="'name'"
              :item-value="'id'"
              label="City"
              outlined
              class="mb-3"
             
              v-model="contact.address.cityId"
            >
            </v-autocomplete>
           </v-col>
            <v-col cols="12" md="6"></v-col>
        </v-row>

        <!-- <v-row>
          <v-col cols="12" md="6">
            
        
            
            <p
             :class="{
                'is-invalid': submitted && $v.contact.address.street.$error,
              }"
              v-if="submitted && !$v.contact.address.street.required"
              class="invalid-feedback"
            >
              Street Is Required
            </p> -->
     


        
            <!-- <p
             :class="{
                'is-invalid': submitted && $v.contact.address.cityId.$error,
              }"
              v-if="submitted && !$v.contact.address.cityId.required"
              class="invalid-feedback"
            >
              City Is Required
            </p> -->
          <!-- </v-col>
          <v-col cols="12" md="6"> -->
            
            <!-- <v-text-field
            tabindex="13"
              v-model="contact.address.buildingNumber"
              outlined
              class="mb-3"
              label="Building Number"
            ></v-text-field> -->
            <!-- <p
             :class="{
                'is-invalid':
                  submitted && $v.contact.address.buildingNumber.$error,
              }"
              v-if="submitted && !$v.contact.address.buildingNumber.required"
              class="invalid-feedback"
            >
              Building Number Is Required
            </p> -->
          
            <!-- <p
             :class="{
                'is-invalid': submitted && $v.contact.address.postalCode.$error,
              }"
              v-if="submitted && !$v.contact.address.postalCode.required"
              class="invalid-feedback"
            >
              Postal Code Is Required
            </p> -->

         
          <!-- </v-col>
        </v-row>  -->
      </div>
      <div class="btn-container">
        <v-btn  tabindex="19" class="create-order-btn mr-1"  @keyup.enter="check()" @click="check"
          >Create Contact</v-btn
        >
      </div>
    </div>
  </div>
</template>
<script>
import { dataService } from "@/services/data-service";
import { required, email } from "vuelidate/lib/validators";
import store from "@/store";

export default {
  data() {
    return {
      CompanyqueryTerm:"",
      isprimary: 1,
      //============ me
      checkbox2: false,
      checkbox1: false,

      companies: [],
      clients: [],
      //clientIds: [],
      accountTerm: "",
      accounts: [],
      queryTerm: "",
      countries: [],
      cities: [],
      states: [],
      locations: [],
      contactTypes: [
        {
          id: 1,
          name: "On Site",
        },
        {
          id: 2,
          name: "Technical",
        },
        {
          id: 3,
          name: "Account Manager",
        },
      ],
      submitted: false,

      secondaryPhone: "",
      contact: {
        firstName: "",
        lastName: " ",
        email: "",
        approveTimeSheet: false,
        approveInvoice: false,
        address: {
          street: "",
          buildingNumber: "",
          postalCode: "",
          details: "",
          cityId: 0,
        },

        branchId: null,
        companyId: null,
        AccountId: null,
        ClientId: null,
        contactTypeId: null,
        contactPhones: [
          {
            phoneNumber: "",
            isPrimary: true,
          },
        ],
      },
      ContactOnId: null,
      ContactOn: [
        {
          id: 1,
          name: "location",
        },
        {
          id: 2,
          name: "Company",
        },
        {
          id: 3,
          name: "Client",
        },
        {
          id: 4,
          name: "Account",
        },
      ],
    };
  },
  validations: {
    contact: {
      firstName: { required },

      email: { required, email },
      contactTypeId: { required },

      // address: {
      //   street: { required },
      //   buildingNumber: { required },
      //   postalCode: { required },

      //   cityId: { required },
      // },
      contactPhones: {
        $each: {
          phoneNumber: { required },
        },
      },
    },
  },
  methods: {
    //================ btn reset me ========= 
    emptyButtons(){
    this.secondaryPhone= null,
      this.contact= {
        firstName:null,
        lastName: null,
        email: null,
        approveTimeSheet: false,
        approveInvoice: false,
        address: {
          street:null,
          buildingNumber:null,
          postalCode: null,
          details: null,
          cityId: 0,
        },

        branchId: null,
        companyId: null,
        AccountId: null,
        ClientId: null,
        contactTypeId: null,
        contactPhones: [
          {
            phoneNumber:null,
            isPrimary: true,
          },
        ],
      }
    },

    formatPhoneNumber(isprimary) {
      if (isprimary == 1) {
      
        var cleanedprimaryphonenumber = ('' + this.contact.contactPhones[0].phoneNumber).replace(/\D/g, '');
        var matchprimaryphonenumber = cleanedprimaryphonenumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (matchprimaryphonenumber) {
          var intlCodeprimaryphonenumber = (matchprimaryphonenumber[1] ? '+1 ' : '');
          this.contact.contactPhones[0].phoneNumber = [intlCodeprimaryphonenumber, '(', matchprimaryphonenumber[2], ') ', matchprimaryphonenumber[3], '-', matchprimaryphonenumber[4]].join('');
        }
      }
      else {
      
        var cleanedsecondryphonenumber = ('' + this.secondaryPhone).replace(/\D/g, '');
      
        var matchsecondryphonenumber = cleanedsecondryphonenumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (matchsecondryphonenumber) {
          var intlCodesecondryphonenumber = (matchsecondryphonenumber[1] ? '+1 ' : '');
          this.secondaryPhone = [intlCodesecondryphonenumber, '(', matchsecondryphonenumber[2], ') ', matchsecondryphonenumber[3], '-', matchsecondryphonenumber[4]].join('');
        }
      }

    },
    //======================================
    applyAccounts(value) {
      dataService.getList(
        "api/Accounts/search_by_name",
        {
          pageNumber: 1,
          pageSize: 100,
          subString: value,
        },
        (response) => {
          this.accounts = response.data.data.items;
          if (value) {
            this.$nextTick(() => this.$refs.accountSearch.focus());
          }
        }
      );
    },

    getAccounts(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyAccounts(value);
        }, 1000);
      } else {
        self.applyAccounts(value);
      }
    },

    async loadAccounts() {
      this.accounts = await this.getAccounts(this.accountTerm || "");
    },
    //==============================================================
    getClientsFromApi() {
      dataService.getAll(
        "api/Clients/get-all",

        (response) => {
          this.clients = response.data;
        }
      );
    },
    //====================================

    applyCompanies(value) {
      dataService.getList(
        "api/Companies/search_by_name",
        {
          pageNumber: 1,
          pageSize: 1000,
          subString: value,
        },
        (response) => {
          this.companies = response.data.data.items;
          this.$nextTick(() => this.$refs.lcn.focus());
        }
      );
    },

    allCompanies(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyCompanies(value);
        }, 1000);
        self.$nextTick(() => self.$refs.lcn.focus());
      } else {
        self.applyCompanies(value);
      }
    },
    async loadCompany() {
      await this.allCompanies(this.queryTerm || "");
    },
    //=====================
    getContactOn() {
      if (this.ContactOnId == 1) {
        this.contact.companyId = null;
        this.contact.AccountId = null;
        this.contact.ClientId = null;
      } else if (this.ContactOnId == 2) {
        this.contact.branchId = null;
        this.contact.AccountId = null;
        this.contact.ClientId = null;
      } else if (this.ContactOnId == 3) {
        this.contact.companyId = null;
        this.contact.AccountId = null;
        this.contact.branchId = null;
      } else if (this.ContactOnId == 4) {
        this.contact.companyId = null;
        this.contact.ClientId = null;
        this.contact.branchId = null;
      }
    },

    check() {
      this.submitted = true;
      this.$v.contact.$touch();
      if (this.$v.contact.$invalid) {
        return;
      } else {
        this.createContact();
      }
    },
    createContact() {
    
      if (this.secondaryPhone) {
        this.contact.contactPhones.push({
          phoneNumber: this.secondaryPhone,
          isPrimary: false,
        });
      }
      dataService.create("api/Contacts/create", this.contact, () => {
        //console.log(response);

      
        store.commit("SHOW_MESSAGE", {
          text: "Created Successfully",
          color: "success",
          timeout: 3000,
        });
      });
      this.$router.push("/contacts/list");
    },
    getLocations(queryTerm) {
      var self = this;
      if (queryTerm) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyLocations(queryTerm);
        }, 1000);
      } else {
        self.applyLocations(queryTerm);
      }
    },
    applyLocations(queryTerm) {
      dataService.getList(
        "api/Branches/search_by_name",
        { pageNumber: 1, pageSize: 200, subString: queryTerm },
        (response) => {
          this.locations = response.data.data.items;
          this.$nextTick(() => this.$refs.locationSearch.focus());
        }
      );
    },
    async loadEntries() {
      await this.getLocations(this.queryTerm || "");
    },
    getCountries() {
      dataService.getAll("/api/Countries", (response) => {
        this.countries = response.data;
      });
    },
    getCities() {
      dataService.getAll(
        "api/Cities/get_city/" + this.contact.address.stateId,
        (response) => {
          this.cities = response.data;
        }
      );
    },
    getStates() {
      dataService.getAll(
        "api/States/get_state/" + this.contact.address.countryId,
        (response) => {
          this.states = response.data;
        }
      );
    },
  },
  computed: {
    search: {
      get() {
        return this.queryTerm;
      },

      set(searchInput) {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput;
          this.loadEntries();
        }
      },
    },
    //====================================
    accountsearch: {
      get() {
        return this.accountTerm;
      },

      set(searchInput) {
        if (this.accountTerm !== searchInput) {
          this.accountTerm = searchInput;
          this.loadAccounts();
        }
      },
    },

    //===================================
    locationSearch: {
      get() {
        return this.queryTerm;
      },

      set(searchInput) {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput;
          this.loadEntries();
          //this.loadCompany();
        }
      },
    },

    Companysearch: {
      get() {
        return this.CompanyqueryTerm;
      },

      set(searchInput) {
        if (this.CompanyqueryTerm !== searchInput) {
          this.CompanyqueryTerm = searchInput;
          this.loadCompany();
        }
      },
    },
  },
  created() {
    this.loadEntries();
    this.loadAccounts();
  },
  mounted() {
    this.getCountries();
    this.getClientsFromApi();
  },
};
</script>