<template>
  <div class="upload-section" id="users">
    <SideMenu v-if="!$vuetify.breakpoint.xsOnly"></SideMenu>
    <div class="user-creation ml-4 mt-4">
      <p class="page-label">General Setting</p>
      <div>
        <v-row>
          <v-col md="3">
            <p class="smtp-key">NickName</p>
            <p class="smtp-key fn-mt">From Email Address</p>
            <p class="smtp-key fn-mt">From Name</p>
            <p class="smtp-key fn-mt">Reply To Email</p>
            <p class="smtp-key tn-mt">Reply To Name</p>
            <p class="smtp-key fn-mt">Address</p>
          </v-col>
          <v-col md="3">
            <input
              :class="{
                'is-invalid-smtp': submitted && $v.smtp.nickname.$error,
              }"
              v-model="smtp.nickname"
              class="smtp-input"
            />
            <p
              v-if="submitted && !$v.smtp.nickname.required"
              class="invalid-feedback mt-1"
            >
              Nickname is Required
            </p>
            <input
              v-model="smtp.from.email"
              class="smtp-input"
              :class="{
                'is-invalid-smtp': submitted && $v.smtp.from.email.$error,
              }"
            />
            <p v-if="!smtp.from.email.includes('@axapro.com')" class="hint">
              From Email must end With @axapro.com
            </p>
            <p
              v-if="submitted && !$v.smtp.from.email.required"
              class="invalid-feedback mt-1"
            >
              Email is Required
            </p>
            <p
              v-if="submitted && !$v.smtp.from.email.email"
              class="invalid-feedback mt-1"
            >
              Email is Invalid
            </p>
            <input
              :class="{
                'is-invalid-smtp': submitted && $v.smtp.from.name.$error,
              }"
              v-model="smtp.from.name"
              class="smtp-input"
            />
            <p
              v-if="submitted && !$v.smtp.from.name.required"
              class="invalid-feedback mt-1"
            >
              From Name is Required
            </p>
            <input
              :class="{
                'is-invalid-smtp': submitted && $v.smtp.reply_to.email.$error,
              }"
              v-model="smtp.reply_to.email"
              class="smtp-input"
            />
            <p
              v-if="submitted && !$v.smtp.reply_to.email.required"
              class="invalid-feedback mt-1"
            >
              Email is Required
            </p>
            <p
              v-if="submitted && !$v.smtp.reply_to.email.email"
              class="invalid-feedback mt-1"
            >
              Email is Invalid
            </p>

            <input
              :class="{
                'is-invalid-smtp': submitted && $v.smtp.reply_to.name.$error,
              }"
              v-model="smtp.reply_to.name"
              class="smtp-input"
            />
            <p
              v-if="submitted && !$v.smtp.reply_to.name.required"
              class="invalid-feedback mt-1"
            >
              To Name is Required
            </p>
            <input
              :class="{
                'is-invalid-smtp': submitted && $v.smtp.address.$error,
              }"
              v-model="smtp.address"
              class="smtp-input"
            />
            <p
              v-if="submitted && !$v.smtp.address.required"
              class="invalid-feedback mt-1"
            >
              Address is Required
            </p>
          </v-col>
          <v-col md="3">
            <p class="smtp-key ml-10">Secondary Address</p>

            <p class="smtp-key fn-mt ml-10">Country</p>
            <p class="smtp-key fn-mt ml-10">City</p>
            <p class="smtp-key fn-mt ml-10">State</p>
            <p class="smtp-key fn-mt ml-10">Zip Code</p>
          </v-col>
          <v-col md="3">
            <input
              :class="{
                'is-invalid-smtp': submitted && $v.smtp.address_2.$error,
              }"
              v-model="smtp.address_2"
              class="smtp-input"
            />
            <p
              v-if="submitted && !$v.smtp.address_2.required"
              class="invalid-feedback mt-1"
            >
              Secondary Address is Required
            </p>
            <input
              :class="{
                'is-invalid-smtp': submitted && $v.smtp.country.$error,
              }"
              v-model="smtp.country"
              class="smtp-input"
            />
            <p
              v-if="submitted && !$v.smtp.country.required"
              class="invalid-feedback mt-1"
            >
              Country is Required
            </p>
            <input
              :class="{
                'is-invalid-smtp': submitted && $v.smtp.city.$error,
              }"
              v-model="smtp.city"
              class="smtp-input"
            />
            <p
              v-if="submitted && !$v.smtp.city.required"
              class="invalid-feedback mt-1"
            >
              City is Required
            </p>
            <input
              :class="{
                'is-invalid-smtp': submitted && $v.smtp.state.$error,
              }"
              v-model="smtp.state"
              class="smtp-input"
            />
            <p
              v-if="submitted && !$v.smtp.state.required"
              class="invalid-feedback mt-1"
            >
              State is Required
            </p>
            <input
              :class="{
                'is-invalid-smtp': submitted && $v.smtp.zip.$error,
              }"
              v-model="smtp.zip"
              class="smtp-input"
            />
            <p
              v-if="submitted && !$v.smtp.zip.required"
              class="invalid-feedback mt-1"
            >
              ZIP is Required
            </p>

            <v-btn @click="createSmtp" class="create-smtp">Create</v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import SideMenu from "@/components/SettingsSideMenu.vue";
import { dataService } from "@/services/data-service";
import { required, email } from "vuelidate/lib/validators";
import store from "@/store";
export default {
  data() {
    return {
      submitted: false,
      smtp: {
        nickname: "",
        from: {
          email: "...@axapro.com",
          name: "",
        },
        reply_to: {
          email: "",
          name: "",
        },
        address: "",
        address_2: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      },
    };
  },
  validations: {
    smtp: {
      nickname: { required },
      from: {
        email: { required, email },
        name: { required },
      },
      reply_to: {
        email: { required, email },
        name: { required },
      },
      address: { required },
      address_2: { required },
      city: { required },
      state: { required },
      zip: { required },
      country: { required },
    },
  },
  methods: {
    createSmtp() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid || !this.smtp.from.email.includes("@axapro.com")) {
        return;
      } else {
        dataService.create("api/Mails/sender", this.smtp, (response) => {
          if (response.data.succeeded == true) {
            store.commit("SHOW_MESSAGE", {
              text: "Created Successfully",
              color: "success",
              timeout: 3000,
            });
          }
        });
      }
    },
  },
  components: {
    SideMenu,
  },
};
</script>