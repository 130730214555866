<template>
  <div class="upload-section" id="permissions">
    <SideMenu></SideMenu>

    <v-card>
      <v-tabs v-model="tab" vertical>
        <v-tab v-for="p in permissions" :key="p.id + 'P'">
          {{ p.name }}
        </v-tab>
        <v-tabs-items v-model="tab">
          <div class="upload-section mt-5 ml-5" id="role-selection">
          
            <v-select
              :items="userTypes"
              :item-text="'name'"
              :item-value="'id'"
              label="
                Select Role To Show Its Permissions "
              v-model="userTypeId"
              @change="getPermissions"
            ></v-select>
          </div>
          <div v-for="item in permissions" :key="item.id + 'I'">
            <v-tab-item>
              <v-checkbox
                v-for="group in item.groups"
                color="#2A8D3D"
                v-model="group.checkBox"
                :label="group.description"
                :key="group.id + 'G'"
                class="role-name"
                disabled
              ></v-checkbox>
            </v-tab-item>
          </div>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </div>
</template>
<script>
import SideMenu from "@/components/SettingsSideMenu.vue";
import { dataService } from "@/services/data-service";

export default {
  data() {
    return {
      tab: null,
      userTypeId: "",
      permissions: [],
      userTypes: [
        {
          id: 201,
          name: "AXA Admin",
        },
        {
          id: 202,
          name: "AXA Rep",
        },
        {
          id: 203,
          name: "AXA Client Admin",
        },
        {
          id: 204,
          name: "AXA Client Rep",
        },
        {
          id: 205,
          name: "AXA Technician",
        },
      ],
    };
  },
  components: {
    SideMenu,
  },
  methods: {
    getPermissions() {
      this.permissions = [];
      dataService.getAll("api/SystemUsers/groups", (response) => {
        var allPermissions = response.data;
 
        for (var pr = 0; pr < allPermissions.length; pr++) {
          this.permissions.push({
            id: allPermissions[pr].id,
            name: allPermissions[pr].name,
            groups: allPermissions[pr].groups,
          });
        }
        if (this.userTypeId) {
          dataService.getAll(
            "api/SystemUsers/role-by_systemtype/" + this.userTypeId,
            (response) => {
              var userRoles = response.data.data;

              for (var t = 0; t < this.permissions.length; t++) {
                for (var pg = 0; pg < this.permissions[t].groups.length; pg++) {
                  for (var ur = 0; ur < userRoles.length; ur++) {
                    if (this.permissions[t].groups[pg].id == userRoles[ur].id) {
                      this.permissions[t].groups[pg].checkBox = true;

                      // this.user.rolIds.push(userRoles[ur].roles);
                    }
                  }
                }
              }
            }
          );
        }
      });
    
    },
  },
  mounted() {
    this.getPermissions();
  },
};
</script>