<template>
  <div
    id="work-order"
    class="handle-test"
    v-if="
      userInfo.userTypeId == 201 ||
      userInfo.userTypeId == 202 ||
      userInfo.userTypeId == 203 ||
      userInfo.userTypeId == 204 ||
      userInfo.userTypeId == 205
    "
  >
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col cols="12" md="8">
        <p class="table-title">Work Orders List</p>
      </v-col>
      <v-col md="1" cols="12" class="Gml-3">
        <v-btn @click="exportData" class="btn-creation wo-diff-style">
          <img style="padding-right: 10%" src="@/assets/images/export.png" />
          <!-- <v-icon>fa-solid fa-paperclip</v-icon> -->
          Export File
        </v-btn>

        <!--  -->
      </v-col>
      <v-col md="2" cols="12" style="padding-left: 2%" class="Gml-3">
        <v-btn
          v-if="
            userInfo.userTypeId == 201 ||
            userInfo.userTypeId == 202 ||
            userInfo.userTypeId == 203 ||
            userInfo.userTypeId == 204
          "
          to="/create-work-order"
          class="btn-creation wo-diff-style"
          >Create WorkOrder</v-btn
        >

        <!--  -->
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4" class="pad0">
        <v-text-field
          solo
          label="Search Work Order "
          append-icon="mdi-magnify"
          v-model="pagination.subString"
          @input="getWorkOrders(1)"
          ref="objet"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="8" class="pad0">
        <v-row>
          <v-col cols="12" md="4">
            <v-autocomplete
              ref="clientSearch"
              multiple
              outlined
              label="Select Clients"
              :items="clients"
              item-text="name"
              item-value="id"
              v-model="clientIds"
              @change="handleClientFilter"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="RemoveClient(data.item.id)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              ref="accountSearch"
              :items="accounts"
              item-text="name"
              item-value="id"
              v-model="accountIds"
              multiple
              outlined
              label="Select Accounts"
              :search-input.sync="accountsearch"
              @change="handleAccountFilter"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeAccount(data.item.id)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              ref="locationSearch"
              :items="locations"
              item-text="name"
              item-value="id"
              v-model="locationIds"
              multiple
              outlined
              label="Select Locations"
              @change="handleLocationFilter"
              :search-input.sync="locationSearch"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeLocatins(data.item.id)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" class="pad0">
        <v-autocomplete
          v-if="userInfo.userTypeId == 201 || userInfo.userTypeId == 202"
          :items="technicians"
          v-model="technicianIds"
          :item-text="
            (item) =>
              item.firstName +
              ' ' +
              item.lastName +
              ' ' +
              '-' +
              ' ' +
              item.email
          "
          item-value="id"
          label="Select Technicians"
          :search-input.sync="techsearch"
          @change="handleTechFilter"
          outlined
          multiple
          ref="techsearch"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="removeTechnician(data.item.id)"
            >
              {{ data.item.firstName + " " + data.item.lastName }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" md="8" class="pad0">
        <v-row>
          <v-col cols="12" md="4">
            <v-menu
              ref="menu"
              v-model="menuStartDate"
              :close-on-content-click="false"
              :return-value.sync="SDFrom"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hint="MM/DD/YYYY format"
                  @blur="SDFrom = parseDate(SDFrom)"
                  v-model="dateFormatted"
                  label=" Service Date / Start Date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="SDFrom"
                no-title
                scrollable
                @input="$refs.menu.save(SDFrom)"
                @change="getWOByDate"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuStartDate = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(SDFrom)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4">
            <v-menu
              ref="menu2"
              v-model="menuEndDate"
              :close-on-content-click="false"
              :return-value.sync="SDTo"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hint="MM/DD/YYYY format"
                  @blur="SDTo = parseDate(SDTo)"
                  v-model="dateFormattedEnd"
                  label="Service Date / End Date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="SDTo"
                no-title
                scrollable
                @input="$refs.menu2.save(SDTo)"
                @change="getWOByDate"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuEndDate = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu2.save(SDTo)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              ref="pad0"
              :items="WoStatus"
              multiple
              class="space-handling"
              outlined
              label="Select Status"
              item-text="name"
              item-value="id"
              v-model="statusIds"
              @change="handleStatusIds"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeStatus(data.item.id)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row style="margin-top: 29px">
      <v-col class="all-items" cols="3" md="1">
        <!-- <p><img src="@/assets/images/Posted.png" /></p> -->
        <p class="text-size">Posted</p>
        <v-btn @click="handleStatusFilter(1)" class="filter-btn posted-btn">{{
          statusCounts.postedEntityCount
        }}</v-btn>
      </v-col>

      <!-- <v-col class="all-items" cols="3" md="1">
        <p><img src="@/assets/images/Over Due.png" /></p>
        <v-btn @click="handleStatusFilter(4)" class="filter-btn ovdue-btn">{{
          statusCounts.overDueEntityCount
        }}</v-btn>
      </v-col> -->

      <v-col class="all-items" cols="3" md="1">
        <!-- <p><img src="@/assets/images/P1- 4Hrs.png" /></p> -->
        <p class="text-size">P1/4Hrs</p>
        <v-btn @click="handleStatusFilter(3)" class="filter-btn fh-btn">{{
          statusCounts.within_4hEntityCount
        }}</v-btn>
      </v-col>

      <v-col class="all-items" cols="3" md="1">
        <!-- <p><img src="@/assets/images/Pending.png" /></p> -->
        <p class="text-size">Pending</p>
        <v-btn @click="handleStatusFilter(5)" class="filter-btn pending-btn">{{
          statusCounts.pendingEntityCount
        }}</v-btn>
      </v-col>

      <v-col class="all-items" cols="3" md="1">
        <!-- <p><img src="@/assets/images/Assigned.png" /></p> -->
        <p class="text-size">Assigned</p>
        <v-btn @click="handleStatusFilter(2)" class="filter-btn assigned-btn">
          {{ statusCounts.assignedEntityCount }}</v-btn
        >
      </v-col>
      <v-col class="all-items" cols="3" md="1">
        <!-- <p><img src="@/assets/images/All.png" /></p> -->
        <p class="text-size">All</p>

        <v-btn @click="handleStatusFilter(0)" class="filter-btn all-btn">{{
          statusCounts.allEntityCount
        }}</v-btn>
      </v-col>

      <v-col class="all-items" cols="3" md="1">
        <!-- <p><img src="@/assets/images/DueToday.png"  style="width:86%"/></p> -->
        <p class="text-size">Due Today</p>

        <v-btn @click="handleStatusFilter(6)" class="filter-btn fh-btn2">{{
          statusCounts.dueToDayCount
        }}</v-btn>
      </v-col>

      <v-col class="all-items" cols="3" md="1">
        <!-- <p><img src="@/assets/images/DueToday.png"  style="width:86%"/></p> -->
        <p class="text-size" style="width: max-content">Due Tomorrow</p>

        <v-btn
          @click="handleStatusFilter(7)"
          class="filter-btn fh-btnFollowindDay"
          >{{ statusCounts.followingDayCount }}</v-btn
        >
      </v-col>

      <v-col cols="9" md="3"></v-col>
      <v-col cols="3" md="2">
        <v-btn
          @click="reset"
          class="btn-creation diff-style mt-1"
          color="#D92020"
          >Reset Filter</v-btn
        >
      </v-col>
    </v-row>
    <div>
      <v-data-table
        v-if="userInfo.userTypeId == 201 || userInfo.userTypeId == 202"
        :loading="isLoaded"
        loading-text="Loading... Please wait"
        @click:row="rowClick"
        :headers="headers"
        :items="data"
        class="elevation-1"
        show-select
        item-key="id"
        v-model="selected"
        :item-class="itemRowBackground"
        hide-default-footer
        :items-per-page="pagination.pageSize"
        @update:options="handleOptionsUpdate"
        show-expand
        :expanded.sync="expanded"
      >
        <template v-slot:item.id="{ item }">
          <v-tooltip bottom color="#172450">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                dark
                v-bind="attrs"
                v-on="on"
                @click="changeTab(item.id, 7)"
                style="color: #172450"
                >fa-solid fa-file
              </v-icon>
            </template>
            <span>Documents</span>
          </v-tooltip>

          &nbsp;
          <v-tooltip bottom color="#172450">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                dark
                v-bind="attrs"
                v-on="on"
                @click="changeTab(item.id, 5)"
                style="color: #172450"
                >fa-solid fa-calendar</v-icon
              >
            </template>
            <span>WorkOrders</span>
          </v-tooltip>

          &nbsp;

          <v-tooltip bottom color="#172450">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                dark
                v-bind="attrs"
                v-on="on"
                @click="changeTab(item.id, 4)"
                style="color: #172450"
                >fa-solid fa-id-badge</v-icon
              >
            </template>
            <span>Technicians</span>
          </v-tooltip>

          &nbsp;
          <v-tooltip bottom color="#172450">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                dark
                v-bind="attrs"
                v-on="on"
                @click="changeTab(item.id, 6)"
                style="color: #172450"
                >fa-duotone fa-comment</v-icon
              >
            </template>
            <span>Notes</span>
          </v-tooltip>
        </template>
        <template v-slot:item.serviceDateFromDate="{ headers, item }">
          <p v-if="item.serviceDateFromDate?.length>0">
            {{
              new Date(
                item.serviceDateFromDate[0]?.serviceDateFromDate
              ).toLocaleDateString()
            }}
            {{ item.serviceDateFromDate[0]?.fromTime }}
            <span class="toDate"> To</span>
            {{
              new Date(
                item.serviceDateFromDate[0]?.serviceDateToDate
              ).toLocaleDateString()
            }}
            {{ item.serviceDateFromDate[0]?.toTime }}
          </p>

          <!-- <p v-if="pagination.workOrderFilterType == 7">
            <span
              v-for="(date, index) in item.serviceDateFromDate"
              :key="index"
            >
              <div v-if="isDateInRange(date)">
                <span>
                  {{ new Date(date.serviceDateFromDate).toLocaleDateString() }}
                </span>
                <span class="toDate"> To</span>
                <span>
                  {{ new Date(date.serviceDateToDate).toLocaleDateString() }}
                </span>
              </div>
            </span>
          </p> -->
          <!-- <p v-if="pagination.workOrderFilterType == 6">
            <span
              v-for="(date, index) in item.serviceDateFromDate"
              :key="index"
            >
              <div v-if="isDateInRangeToday(date)">
                <span>
                  {{ new Date(date.serviceDateFromDate).toLocaleDateString() }}
                </span>
                <span class="toDate"> To</span>
                <span>
                  {{ new Date(date.serviceDateToDate).toLocaleDateString() }}
                </span>
              </div>
            </span>
          </p> -->
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <strong>Service date</strong>
            <p
              v-for="date in item.serviceDateFromDate"
              :key="date.serviceDateFromDate"
            >
              {{
                new Date(date.serviceDateFromDate).toLocaleDateString() +
                " " +
                date.fromTime
              }}

              <span class="toDate"> To</span>

              {{
                new Date(date.serviceDateToDate).toLocaleDateString() +
                " " +
                date.toTime
              }}
            </p>
          </td>
        </template>
        <template v-slot:footer>
          <v-row class="mt-5 fit-width">
            <v-col md="2">
              <v-select
                :items="sizeOptions"
                v-model="pagination.pageSize"
                :item-value="'id'"
                :item-text="'name'"
                @change="getWorkOrders(1)"
                solo
              ></v-select>
            </v-col>
            <v-col md="5">
              <v-pagination
                v-model="pagination.pageNumber"
                :length="totalPages"
                @input="getWorkOrders(pagination.pageNumber)"
                total-visible="6"
              ></v-pagination>
            </v-col>
          </v-row>
        </template>
        <template v-if="selected.length != 0" v-slot:top>
          <div>
            <v-row class="wo-actions">
              <v-col class="rspnsv-hide" md="1">
                <p class="select-key">Select</p>
              </v-col>
              <v-col md="2" class="border-item">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn text dark v-bind="attrs" v-on="on">
                      <span class="header-item changed-color">
                        Change Status <i class="fas fa-caret-down ml-1"></i
                      ></span>
                      <i class="fas fa-caret-down ml-1"></i>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item v-for="(item, index) in Status" :key="index">
                      <v-btn
                        @click="changeStatus(item)"
                        text
                        class="dropdown-item"
                      >
                        <span class="ml-2">{{ item.name }}</span>
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>

              <!-- <v-col md="2" class="border-item">
                <v-menu offset-y>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in assignList"
                      :key="index"
                    >
                      <v-btn text class="dropdown-item">
                        <span class="ml-2">{{ item.title }}</span>
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col> -->
            </v-row>
          </div>
        </template>
        <template v-slot:item.address="{ item }">
          <p v-if="item.address">
            <span>{{ item.address.street }}</span>
            -
            <span>{{ item.address.buildingNumber }}</span>
            <span v-if="item.address.city">{{ item.address.city.name }}</span>
            -<span v-if="item.address.state">{{
              item.address.state.target
            }}</span>
            <span v-if="item.address.country">
              {{ item.address.country.name }}</span
            >
          </p>
        </template>
        <template v-slot:item.orderType="{ item }">
          <p>
            {{ item.orderType.name }}
          </p>
        </template>

        <template v-slot:item.workOrderStatus="{ item }">
          <p v-if="item.workOrderStatus">{{ item.workOrderStatus.name }}</p>
        </template>
        <template v-slot:item.createdOn="{ item }">
          <p>
            {{
              convertUTCDateToLocalDate(
                new Date(item.createdOn)
              ).toLocaleString()
            }}
          </p>
        </template>
      </v-data-table>

      <v-data-table
        v-else
        :loading="isLoaded"
        loading-text="Loading... Please wait"
        @click:row="rowClick"
        :headers="clientHeader"
        :items="data"
        class="elevation-1"
        show-select
        item-key="id"
        v-model="selected"
        :item-class="itemRowBackground"
        hide-default-footer
        :items-per-page="pagination.pageSize"
        @update:options="handleOptionsUpdate"
        show-expand
        :expanded.sync="expanded"
      >
        <template v-slot:item.id="{ item }">
          <v-tooltip bottom color="#172450">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                dark
                v-bind="attrs"
                v-on="on"
                @click="changeTab(item.id, 7)"
                style="color: #172450"
                >fa-solid fa-file
              </v-icon>
            </template>
            <span>Documents</span>
          </v-tooltip>

          &nbsp;
          <v-tooltip bottom color="#172450">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                dark
                v-bind="attrs"
                v-on="on"
                @click="changeTab(item.id, 5)"
                style="color: #172450"
                >fa-solid fa-calendar</v-icon
              >
            </template>
            <span>WorkOrders</span>
          </v-tooltip>

          &nbsp;

          <v-tooltip bottom color="#172450">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                dark
                v-bind="attrs"
                v-on="on"
                @click="changeTab(item.id, 4)"
                style="color: #172450"
                >fa-solid fa-id-badge</v-icon
              >
            </template>
            <span>Technicians</span>
          </v-tooltip>

          &nbsp;
          <v-tooltip bottom color="#172450">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                dark
                v-bind="attrs"
                v-on="on"
                @click="changeTab(item.id, 6)"
                style="color: #172450"
                >fa-duotone fa-comment</v-icon
              >
            </template>
            <span>Notes</span>
          </v-tooltip>
        </template>
        <!-- <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <strong>Service date</strong>
            <p
              v-for="date in item.serviceDateFromDate"
              :key="date.serviceDateFromDate"
            >
              {{
                new Date(date.serviceDateFromDate).toLocaleDateString() +
                " " +
                date.fromTime
              }}

              <span class="toDate"> To</span>

              {{
                new Date(date.serviceDateToDate).toLocaleDateString() +
                " " +
                date.toTime
              }}
            </p>
          </td>
        </template> -->
        <template v-slot:footer>
          <v-row class="mt-5 fit-width">
            <v-col md="2">
              <v-select
                :items="sizeOptions"
                v-model="pagination.pageSize"
                :item-value="'id'"
                :item-text="'name'"
                @change="getWorkOrders(1)"
                solo
              ></v-select>
            </v-col>
            <v-col md="5">
              <v-pagination
                v-model="pagination.pageNumber"
                :length="totalPages"
                @input="getWorkOrders(pagination.pageNumber)"
                total-visible="6"
              ></v-pagination>
            </v-col>
          </v-row>
        </template>
        <template
          v-if="selected.length != 0 && userInfo.userTypeId != 205"
          v-slot:top
        >
          <div>
            <v-row class="wo-actions">
              <v-col class="rspnsv-hide" md="1">
                <p class="select-key">Select</p>
              </v-col>
              <v-col md="2" class="border-item">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn text dark v-bind="attrs" v-on="on">
                      <span class="header-item changed-color">
                        Change Status <i class="fas fa-caret-down ml-1"></i
                      ></span>
                      <i class="fas fa-caret-down ml-1"></i>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item v-for="(item, index) in Status" :key="index">
                      <v-btn
                        @click="changeStatus(item)"
                        text
                        class="dropdown-item"
                      >
                        <span class="ml-2">{{ item.name }}</span>
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>

              <!-- <v-col md="2" class="border-item">
                <v-menu offset-y>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in assignList"
                      :key="index"
                    >
                      <v-btn text class="dropdown-item">
                        <span class="ml-2">{{ item.title }}</span>
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col> -->
            </v-row>
          </div>
        </template>
        <template v-slot:item.address="{ item }">
          <p v-if="item.address">
            <span>{{ item.address.street }}</span>
            -
            <span>{{ item.address.buildingNumber }}</span>
            <span v-if="item.address.city">{{ item.address.city.name }}</span>
            -<span v-if="item.address.state">{{
              item.address.state.target
            }}</span>
            <span v-if="item.address.country">
              {{ item.address.country.name }}</span
            >
          </p>
        </template>
        <template v-slot:item.orderType="{ item }">
          <p>
            {{ item.orderType.name }}
          </p>
        </template>

        <template v-slot:item.workOrderStatus="{ item }">
          <p v-if="item.workOrderStatus">{{ item.workOrderStatus.name }}</p>
        </template>
        <template v-slot:item.createdOn="{ item }">
          <p>
            {{
              convertUTCDateToLocalDate(
                new Date(item.createdOn)
              ).toLocaleString()
            }}
          </p>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { dataService } from "@/services/data-service";
import store from "@/store";
import { excelParser } from "@/excel-parser";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      nextDay: new Date(),
      dateFormattedEnd: null,
      dateFormatted: null,
      loading: false,
      templateId: 0,
      isLoaded: true,
      expanded: [],
      singleExpand: false,
      techIds: [],
      techniciansToAssign: [],
      menuEndDate: false,
      menuStartDate: false,
      searchInput: "",
      diffTechTerm: "",
      technicianIds: [],
      statusIds: [],
      clientIds: [],
      clients: [],
      accounts: [],
      accountIds: [],
      locations: [],
      locationIds: [],
      Status: [],

      WoStatus: [
        {
          id: 1,
          name: "Posted",
        },
        {
          id: 11,
          name: "Pending",
        },
        {
          id: 2,
          name: "Assigned",
        },
        {
          id: 3,
          name: "In Route",
        },
        {
          id: 4,
          name: "In Progress",
        },
        {
          id: 5,
          name: "Timesheet",
        },
        {
          id: 6,
          name: "Cancelled",
        },
        {
          id: 7,
          name: "Completed",
        },
        {
          id: 8,
          name: "Closed",
        },
        {
          id: 9,
          name: "Invoiced",
        },
        {
          id: 10,
          name: "Other",
        },
      ],
      sizeOptions: [
        {
          id: 10,
          name: 10,
        },
        {
          id: 20,
          name: 20,
        },
        {
          id: 30,
          name: 30,
        },
        {
          id: 50,
          name: 50,
        },
        {
          id: 100,
          name: 100,
        },
      ],
      queryTerm: "",
      accountTerm: "",
      locationTerm: "",

      technicianID: "",
      totalPages: 0,
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        workOrderFilterType: 1,
        subString: "",
        columnIndex: 0,
        isDec: true,
      },
      technicians: [],
      selected: [],
      techArray: [],
      assignList: [
        {
          title: "Title A",
        },
        {
          title: "Title B",
        },
      ],
      tableSearch: null,
      items: ["foo", "bar", "fizz", "buzz"],
      value: null,
      clientHeader: [
        {
          text: " Address",
          align: "start",
          sortable: false,
          value: "address",
        },
        { text: "Changes", value: "id" },
        { text: "Order ID", value: "orderId" },

        { text: "Order Type", value: "orderType" },
        { text: "Status", value: "workOrderStatus" },
        { text: "Client", value: "clientName" },
        { text: "Service Date", value: "serviceDateFromDate" },

        { text: "Account", value: "accountName" },

        // { text: "Company", value: "companyName" },
        { text: "Created On", value: "createdOn" },
        { text: "Created By", value: "createdBy" },
      ],
      headers: [
        {
          text: " Address",
          align: "start",
          sortable: false,
          value: "address",
        },
        { text: "Changes", value: "id" },
        { text: "Order ID", value: "orderId" },
        { text: "Field Nation ID", value: "fieldNationId" },

        { text: "Order Type", value: "orderType" },
        { text: "Status", value: "workOrderStatus" },
        { text: "Client", value: "clientName" },
        { text: "Service Date", value: "serviceDateFromDate" },

        { text: "Account", value: "accountName" },

        // { text: "Company", value: "companyName" },
        { text: "Created On", value: "createdOn" },
        { text: "Created By", value: "createdBy" },
      ],
      data: [],
      statusCounts: {
        allEntityCount: 0,
        pendingEntityCount: 0,
        postedEntityCount: 0,
        assignedEntityCount: 0,
        overDueEntityCount: 0,
      },
      SDFrom: null,
      SDTo: null,
    };
  },
  methods: {
    isDateInRange(date) {
      const nextDay = this.getNextDay();
      const nextDayDate = new Date(
        nextDay.getFullYear(),
        nextDay.getMonth(),
        nextDay.getDate()
      );
      const fromDate = new Date(date.serviceDateFromDate);
      const toDate = new Date(date.serviceDateToDate);
      return fromDate >= nextDayDate && toDate <= nextDayDate;
    },
    getNextDay() {
      const nextDay = new Date(this.nextDay);
      nextDay.setDate(nextDay.getDate());
      return nextDay;
    },
    isDateInRangeToday(date) {
      const today = this.getToday();
      const fromDate = new Date(date.serviceDateFromDate);
      const toDate = new Date(date.serviceDateToDate);
      return fromDate <= today && toDate >= today;
    },
    getToday() {
      const today = new Date();
      // Set time to midnight to only compare dates
      today.setHours(0, 0, 0, 0);
      return today;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    exportData() {
      let sampleData = [
        // { id: 1, name: "Night", email: "nightprogrammer95@gmail.com" },
        // { id: 2, name: "Gautam", email: "mailgautam@test.com" },
        // { id: 3, name: "Alex", email: "xalex@testmail.com" },
        // { id: 4, name: "Lina", email: "lina@mail.com" },
        // { id: 5, name: "Peter", email: "peter.me@test.com" }
      ];

      // this.data.forEach((element) => {
      //   let obj = {};
      //   let ServiceDates1 = "";
      //   let i = 1;
      //   obj.id = element.id;
      //   obj.address =
      //     element.address.street +
      //     "-" +
      //     element.address.buildingNumber +
      //     "-" +
      //     element.address.city.name +
      //     "-" +
      //     element.address.state.name +
      //     "-" +
      //     element.address.country.name;
      //   obj.orderId = element.orderId;
      //   obj.orderType = element.orderType.name;
      //   obj.clientName = element.clientName;
      //   obj.accountName = element.accountName;
      //   obj.companyName = element.companyName;
      //   obj.createdOn = this.convertUTCDateToLocalDate(
      //     new Date(element.createdOn)
      //   ).toLocaleString();

      //   obj.createdBy = element.createdBy;
      //   obj.status = element.workOrderStatus.name;

      //   element.serviceDateFromDate.forEach((item) => {
      //     ServiceDates1 =
      //       ServiceDates1 +
      //       "\n" +
      //       i +
      //       "- From: " +
      //       this.convertUTCDateToLocalDate(
      //         new Date(item.serviceDateFromDate)
      //       ).toLocaleString() +
      //       " To: " +
      //       this.convertUTCDateToLocalDate(
      //         new Date(item.serviceDateToDate)
      //       ).toLocaleString();
      //   });
      //   obj.serviceDates = ServiceDates1;

      //   sampleData.push(obj);
      //   i = i + 1;
      // });
      //console.log("sampleData", sampleData);

      this.isLoaded = true;
      var self = this;

      dataService.getList(
        "api/WorkOrders/get-GetExport",
        self.pagination,
        (response) => {
          this.isLoaded = false;

          debugger; // eslint-disable-line no-debugger

          sampleData = response.data.data;

          var currentdate = new Date();
          var fileName =
            "WorkOrderList " +
            currentdate.getDate() +
            "/" +
            (currentdate.getMonth() + 1) +
            "/" +
            currentdate.getFullYear();

          excelParser().exportDataFromJSON(sampleData, fileName, null);
        }
      );
    },
    getTemplate() {
      dataService.getAll("/api/Mails/get-template-bytype/" + 1, (response) => {
        this.template = response.data;
      });
    },
    changeTab(wido, actionid) {
      localStorage.actionid = actionid;
    },
    removeStatus(item) {
      const index = this.statusIds.indexOf(item);
      if (index >= 0) this.statusIds.splice(index, 1);
      this.pagination.statuses = this.statusIds;
      this.getWorkOrders(1);
    },
    removeTechnician(item) {
      const index = this.technicianIds.indexOf(item);
      if (index >= 0) this.technicianIds.splice(index, 1);
      this.pagination.technicianIds = this.technicianIds;
      this.getWorkOrders(1);
    },
    removeLocatins(item) {
      const index = this.locationIds.indexOf(item);
      if (index >= 0) this.locationIds.splice(index, 1);
      this.pagination.branchIds = this.locationIds;
      this.getWorkOrders(1);
    },
    removeAccount(item) {
      const index = this.accountIds.indexOf(item);
      if (index >= 0) this.accountIds.splice(index, 1);
      this.pagination.accountIds = this.accountIds;
      this.getWorkOrders(1);
    },

    RemoveClient(item) {
      const index = this.clientIds.indexOf(item);
      if (index >= 0) this.clientIds.splice(index, 1);

      this.pagination.clientIds = this.clientIds;
      this.getWorkOrders(1);
    },
    handleClientFilter() {
      this.pagination.clientIds = this.clientIds;

      if (this.clientIds.length > 0) {
        dataService.create(
          "api/Accounts/clients",
          this.clientIds,
          (response) => {
            this.accounts = response.data.data;
          }
        );
      } else {
        this.loadAccounts();
      }
      this.getWorkOrders(1);
    },
    handleAccountFilter() {
      var companyIds = [];
      this.pagination.accountIds = this.accountIds;
      if (this.accountIds.length > 0) {
        dataService.create(
          "api/Companies/accounts",
          this.accountIds,
          (response) => {
            this.companies = response.data.data;
            for (var c = 0; c < this.companies.length; c++) {
              companyIds.push(this.companies[c].id);
            }
            dataService.create(
              "api/Branches/companies",
              companyIds,
              (response) => {
                this.locations = response.data.data;
              }
            );
            localStorage.setItem("woObj", JSON.stringify(this.pagination));

            this.getWorkOrders(1);
          }
        );
      } else {
        this.loadAccounts();
        this.loadLocations();
        this.getWorkOrders(1);
      }
    },
    handleLocationFilter() {
      this.pagination.branchIds = this.locationIds;
      localStorage.setItem("woObj", JSON.stringify(this.pagination));

      this.getWorkOrders(1);
    },

    getAccounts(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyAccounts(value);
        }, 1000);
      } else {
        self.applyAccounts(value);
      }
    },
    getStatus() {
      dataService.getAll(
        "api/Enums/work-order-statuses",

        (response) => {
          this.WoStatus = response.data;
        }
      );
    },

    applyAccounts(value) {
      dataService.getList(
        "api/Accounts/search_by_name",
        {
          pageNumber: 1,
          pageSize: 100,
          subString: value,
        },
        (response) => {
          this.accounts = response.data.data.items;
          if (value) {
            this.$nextTick(() => this.$refs.accountSearch.focus());
          }
        }
      );
    },
    getLocations(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyLocations(value);
        }, 1000);
      } else {
        self.applyLocations(value);
      }
    },
    applyLocations(value) {
      dataService.getList(
        "api/Branches/search_by_name",
        {
          pageNumber: 1,
          pageSize: 100,
          subString: value,
        },
        (response) => {
          this.locations = response.data.data.items;
          if (value) {
            this.$nextTick(() => this.$refs.locationSearch.focus());
          }
        }
      );
    },

    async loadAccounts() {
      this.accounts = await this.getAccounts(this.accountTerm || "");
    },
    async loadLocations() {
      this.accounts = await this.getLocations(this.locationTerm || "");
    },

    convertUTCDateToLocalDate(date) {
      var newDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60 * 1000
      );

      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();

      newDate.setHours(hours - offset);

      return newDate;
    },
    handleOptionsUpdate({ sortBy, sortDesc }) {
      if (sortBy.length > 0) {
        if (sortBy[0] == "createdOn") this.pagination.sortedColumnIndex = 0;
        if (sortBy[0] == "address") this.pagination.sortedColumnIndex = 1;
        if (sortBy[0] == "orderId") this.pagination.sortedColumnIndex = 2;

        if (sortBy[0] == "companyName") this.pagination.sortedColumnIndex = 6;
        if (sortBy[0] == "accountName") this.pagination.sortedColumnIndex = 5;
        if (sortBy[0] == "clientName") this.pagination.sortedColumnIndex = 4;
        if (sortBy[0] == "orderType") this.pagination.sortedColumnIndex = 3;
        if (sortBy[0] == "workOrderStatus")
          this.pagination.sortedColumnIndex = 9;

        this.pagination.isDec = sortDesc[0];
        localStorage.setItem("woObj", JSON.stringify(this.pagination));

        this.getWorkOrders(1);
      }
    },
    getWOByDate() {
      if (this.SDFrom && !this.SDTo) {
        return;
      }
      if (!this.SDFrom) {
        store.commit("SHOW_MESSAGE", {
          text: "Please Select Start Date first",
          color: "error",
          timeout: 3000,
        });
        return;
      } else {
        this.pagination.serviceDate = {
          from: this.SDFrom + "T00:00:00Z",
          to: this.SDTo + "T00:00:00Z",
        };
        localStorage.setItem("woObj", JSON.stringify(this.pagination));

        this.getWorkOrders(1);
      }
    },
    changeStatus(item) {
      this.loading = true;
      for (var s = 0; s < this.selected.length; s++) {
        dataService.create(
          "api/WorkOrders/Change_Status",
          {
            workOrderId: this.selected[s].id,
            workOrderStatusId: item.id,
          },
          (response) => {
            if (response.data.succeeded == true) {
              this.getWorkOrders(1);
            }
          }
        );
      }
      this.loading = false;
    },
    itemRowBackground: function (item) {
      var styleClass = "";
      if (item.orderType.id == 1) {
        styleClass = "stType";
      }
      if (item.orderType.id == 2) {
        styleClass = "ndType";
      }
      if (item.orderType.id == 3) {
        styleClass = "rdType";
      }
      if (item.orderType.id == 4) {
        styleClass = "thType";
      }
      return styleClass;
    },

    rowClick: function (item, row) {
      row.select(true);
      this.$router.push("/show-work-order/" + item.id);
    },
    handleStatusFilter(index) {
      this.pagination.workOrderFilterType = index;

      localStorage.setItem("woObj", JSON.stringify(this.pagination));

      this.getWorkOrders(1);
    },

    handleStatusIds() {
      this.pagination.statuses = this.statusIds;
      localStorage.setItem("woObj", JSON.stringify(this.pagination));

      this.getWorkOrders(1);
    },
    handleTechFilter() {
      this.pagination.technicianIds = this.technicianIds;
      localStorage.setItem("woObj", JSON.stringify(this.pagination));

      this.getWorkOrders(1);
    },
    getWorkOrders(size) {
      this.isLoaded = true;
      var self = this;
      self.pagination.pageNumber = size;
      if (self.pagination.subString) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          localStorage.setItem("woObj", JSON.stringify(self.pagination));

          self.applyFunction();
        }, 1000);
      } else {
        localStorage.setItem("woObj", JSON.stringify(self.pagination));

        self.applyFunction();
      }
    },

    reset() {
      this.clientIds = [];
      this.accountIds = [];
      this.companyIds = [];
      this.locationIds = [];
      this.technicianIds = [];
      this.statusIds = [];
      this.pagination = {
        pageNumber: 1,
        pageSize: 10,
      };
      localStorage.setItem("woObj", JSON.stringify(this.pagination));

      this.getWorkOrders(1);
    },
    applyFunction() {
      var self = this;
      if (localStorage.getItem("woObj") != "undefined") {
        var cashedobj =
          JSON.parse(localStorage.getItem("woObj")) || this.pagination;
        if (cashedobj) {
          this.pagination = cashedobj;
        }
      }

      dataService.getList(
        "api/WorkOrders/get-filterd",
        this.pagination,
        (response) => {
          this.totalPages = response.data.data.totalPages;

          this.isLoaded = false;
          this.data = response.data.data.workOrderViewList;
          // console.log(this.data)
          if (response.data.data.jellyBeans) {
            this.statusCounts = response.data.data.jellyBeans;
          } else {
            this.statusCounts = {
              allEntityCount: 0,
              pendingEntityCount: 0,
              postedEntityCount: 0,
              assignedEntityCount: 0,
              overDueEntityCount: 0,
            };
          }

          self.$nextTick(() => this.$refs.objet.focus());
        }
      );
    },
    assignTechnician() {
      for (var t = 0; t < this.selected.length; t++) {
        for (var m = 0; m < this.techIds.length; m++) {
          this.techArray.push({
            workOrderId: this.selected[t].id,
            technicianId: this.techIds[m],
            isCaptain: true,
          });
        }
      }

      this.loading = true;
      let obj = {
        emailTemplateId: this.templateId,
        workOrderTech: this.techArray,
      };
      dataService.create("api/WorkOrders/assign-technicians", obj, () => {
        this.loading = false;
        this.templateId = 0;
        this.techArray = [];
        this.selected = [];
      });
    },
    async loadTechs(term, pointer) {
      this.technicians = await this.getTechnicians(term || "", pointer);
    },

    getTechnicians(value, pointer) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyTech(value, pointer);
        }, 1000);
      } else {
        self.applyTech(value, pointer);
      }
    },

    applyTech(value, pointer) {
      dataService.getList(
        "api/Technicians/get-all",
        {
          pageNumber: 1,
          pageSize: 100,
          search: value,
        },
        (response) => {
          this.technicians = response.data.data.items;
          if (value) {
            if (pointer == 1)
              this.$nextTick(() => this.$refs.techsearch.focus());
            if (pointer == 2) this.$nextTick(() => this.$refs.diffTech.focus());
          }
        }
      );
    },
    getClientsFromApi() {
      dataService.getAll(
        "api/Clients/get-all",

        (response) => {
          this.clients = response.data;
        }
      );
    },
  },
  watch: {
    SDFrom() {
      this.dateFormatted = this.formatDate(this.SDFrom);
    },
    SDTo() {
      this.dateFormattedEnd = this.formatDate(this.SDTo);
    },
  },
  computed: {
    computedStartDateFormatted() {
      return this.formatDate(this.SDFrom);
    },
    computedEndDateFormatted() {
      return this.formatDate(this.SDTo);
    },

    ...mapGetters({
      userInfo: "AuthModule/userData",
    }),

    techsearch: {
      get() {
        return this.queryTerm;
      },

      set(searchInput) {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput;
          this.loadTechs(this.queryTerm, 1);
        }
      },
    },

    diffTech: {
      get() {
        return this.diffTechTerm;
      },

      set(searchInput) {
        if (this.diffTechTerm !== searchInput) {
          this.diffTechTerm = searchInput;
          this.loadTechs(this.diffTechTerm, 2);
        }
      },
    },
    locationSearch: {
      get() {
        return this.locationTerm;
      },

      set(searchInput) {
        if (this.locationTerm !== searchInput) {
          this.locationTerm = searchInput;
          this.loadLocations();
        }
      },
    },

    accountsearch: {
      get() {
        return this.accountTerm;
      },

      set(searchInput) {
        if (this.accountTerm !== searchInput) {
          this.accountTerm = searchInput;
          this.loadAccounts();
        }
      },
    },
  },
  mounted() {
    this.getTemplate();
    this.getWorkOrders(1);
    this.getStatus();
    this.getClientsFromApi();
    this.loadTechs();
    this.nextDay.setDate(this.nextDay.getDate() + 1);

    if (this.userInfo.userTypeId == 201 || this.userInfo.userTypeId == 202) {
      //debugger; // eslint-disable-line no-debugger
      this.Status = [];
      this.Status = this.WoStatus;
    } else {
      this.Status.push({
        id: 6,
        name: "Cancelled",
      });
    }
  },
  created() {
    this.loadAccounts();
    this.loadLocations();
  },
};
</script>
<style>
.text-size {
  min-width: 100px;
}
</style>
