<template>
  <div id="work-order" class="handle-test">
    <div>
      <v-row>
        <v-col cols="12" md="10">
          <p class="table-title">Contacts List</p>
        </v-col>

        <v-col md="1" cols="12">
          <v-btn to="/contact/create" class="btn-creation wo-diff-style" color="#D92020">Create Contact</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="pad0">
          <v-text-field solo label="Search Contact " append-icon="mdi-magnify" v-model="ContactFilter.subString"
            @keyup="realSearch(1)" ref="objet"></v-text-field>
        </v-col>

        <v-col cols="12" md="8" class="pad0">
          <v-row>
            <v-col cols="12" md="4">
              <v-autocomplete :items="countries" :item-text="'name'" :item-value="'id'" label="Country" solo
                v-model="ContactFilter.countryId" class="mb-3" @change="onchange()">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete no-filter :items="states" :item-text="'name'" :item-value="'id'" label="State" solo
                @change="SearchInCity()" v-model="ContactFilter.stateId" :search-input.sync="searchState" class="mb-3"
                ref="stateFocus">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete :items="cities" :item-text="'name'" :item-value="'id'" label="City" solo
                :search-input.sync="searchCity" v-model="ContactFilter.cityId" @change="realSearch(1)" class="mb-3"
                ref="cityFocus">
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="pad0"> </v-col>

        <v-col cols="12" md="8" class="pad0">
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field v-model="ContactFilter.zipCode" v-on:keyup.enter="realSearch(1)" solo
                label="Zip Code"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select :items="contactTypes" :item-value="'id'" :item-text="'name'" v-model="ContactFilter.type"
                @change="realSearch(1)" label="Contact Type" solo></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn @click="resetFilter" class="btn-creation diff-style mt-1" color="#D92020">Reset Filter</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <div>
      <v-data-table :loading="isLoaded" loading-text="Loading... Please wait" :headers="headers" :items="data"
        class="elevation-1" item-key="id" hide-default-footer :items-per-page="ContactFilter.pageSize"
        @update:options="handleOptionsUpdate">
        <template v-slot:item.client="{ item }">

          <span v-if="item.client">{{ item.client.name }}</span>

        </template>
        <template v-slot:item.company="{ item }">

          <span v-if="item.company">{{ item.company.name }}</span>

        </template>
        <template v-slot:item.account="{ item }">

          <span v-if="item.account">{{ item.account.name }}</span>

        </template>
        <template v-slot:item.branch="{ item }">

          <span v-if="item.branch">{{ item.branch.name }}</span>

        </template>
        <template v-slot:footer>
          <v-row class="mt-5 fit-width">
            <v-col md="2">
              <v-select :items="sizeOptions" v-model="ContactFilter.pageSize" :item-value="'id'" :item-text="'name'"
                @change="realSearch(1)" solo></v-select>
            </v-col>
            <v-col md="5">
              <v-pagination v-model="ContactFilter.pageNumber" :length="totalPages"
                @input="realSearch(ContactFilter.pageNumber)" total-visible="6"></v-pagination>
            </v-col>
          </v-row>
        </template>

        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>contacts</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="700px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Edit Contact</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col md="12">
                        <v-text-field tabindex="1" :class="{
                          'is-invalid':
                            submitted && $v.contact.firstName.$error,
                        }" v-model="contact.firstName" outlined class="mb-3" label="Full Name"></v-text-field>
                        <p v-if="submitted && !$v.contact.firstName.required" class="invalid-feedback">
                          First Name Is Required
                        </p>


                      </v-col>


                      <v-col cols="12" md="6">
                        <v-autocomplete tabindex="2" ref="clientSearch" outlined label="Select Client" :items="clients"
                          item-text="name" item-value="id" v-model="contact.clientId">
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-autocomplete tabindex="3" :items="accounts" item-text="name" item-value="id"
                          v-model="contact.accountId" outlined label="Select Account" :search-input.sync="accountsearch">
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-autocomplete tabindex="4" outlined v-model="contact.companyId" label="Select Company"
                          :items="companies" :item-value="'id'" :item-text="'name'" :search-input.sync="Companysearch"
                          ref="lcn">
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-autocomplete tabindex="5" ref="locationSearch" :items="locations" item-text="name"
                          item-value="id" v-model="contact.branchId" outlined label="Select Location"
                          :search-input.sync="locationSearch"></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-autocomplete tabindex="6" :items="contactTypes" :item-text="'name'" :item-value="'id'"
                          label="Contact Type" outlined v-model="contact.contactTypeId" :class="{
                            'is-invalid':
                              submitted && $v.contact.contactTypeId.$error,
                          }">
                        </v-autocomplete>
                        <p v-if="submitted && !$v.contact.contactTypeId.required" class="invalid-feedback">
                          Contact Type Is Required
                        </p>
                      </v-col>
                      <v-col cols="12" md="6">

                      </v-col>
                    </v-row>


                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field tabindex="7" outlined class="mb-3" v-model="primary.phoneNumber"
                          label="Primary Phone" @blur="formatPhoneNumber(1)"></v-text-field>

                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field tabindex="8" outlined class="mb-3" label="Secondary Phone"
                          v-model="Secondry.phoneNumber" @blur="formatPhoneNumber(2)"></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- ================================================================ -->
                    <v-row>
                      <v-col md="6">
                        <v-text-field tabindex="9" outlined class="mb-3" v-model="contact.email" label="Email" :class="{
                          'is-invalid': submitted && $v.contact.email.$error,
                        }"></v-text-field>
                        <p v-if="submitted && !$v.contact.email.required" class="invalid-feedback">
                          Email Is Required
                        </p>
                        <p v-if="submitted && !$v.contact.email.email" class="invalid-feedback">
                          Email Is Not Valid
                        </p>
                      </v-col>
                      <v-col md="6" style="margin-top: -3%;">
                        <v-row style="">
                          <v-col md="3" style="max-width: 49% !important;">
                            <v-checkbox tabindex="10" class="" v-model="contact.isInvoiceApproval"
                              label="Approve Invoice"></v-checkbox>
                          </v-col>
                          <v-col md="3" style="max-width: 49% !important;">
                            <v-checkbox class="" tabindex="11" v-model="contact.isTimesheetApproval"
                              label="Approve Timesheet">
                            </v-checkbox>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-10"></v-divider>
                    <div>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field tabindex="12" outlined class="mb-3" v-model="contact.address.street"
                            label="Street"></v-text-field>
                          <!-- <p :class="{
                              'is-invalid':
                                submitted && $v.contact.address.street.$error,
                            }"
                            v-if="
                              submitted && !$v.contact.address.street.required
                            "
                            class="invalid-feedback"
                          >
                            Street Is Required
                          </p> -->
                          <v-autocomplete tabindex="14" :items="countries" :item-text="'name'" :item-value="'id'"
                            label="Country" outlined v-model="contact.address.countryId" class="mb-3"
                            @change="Editonchange()">
                          </v-autocomplete>



                          <v-autocomplete tabindex="16" :items="Editcities" :item-text="'name'" :item-value="'id'"
                            label="City" outlined :search-input.sync="searchEditCity" class="mb-3"
                            v-model="contact.address.cityId">
                          </v-autocomplete>
                          <v-text-field tabindex="17" v-model="contact.address.details" outlined class="mb-3"
                            label="Details"></v-text-field>



                          <!-- <p:class="{
                              'is-invalid':
                                submitted && $v.contact.address.cityId.$error,
                            }"
                            v-if="
                              submitted && !$v.contact.address.cityId.required
                            "
                            class="invalid-feedback"
                          >
                            City Is Required
                          </p> -->
                        </v-col>
                        <v-col cols="12" md="6">
                          <!-- <v-text-field
                            v-model="contact.address.buildingNumber"
                            outlined
                            class="mb-3"
                            label="Building Number"
                           
                          ></v-text-field> -->
                          <!-- <p :class="{
                              'is-invalid':
                                submitted &&
                                $v.contact.address.buildingNumber.$error,
                            }"
                            v-if="
                              submitted &&
                              !$v.contact.address.buildingNumber.required
                            "
                            class="invalid-feedback"
                          >
                            Building Number Is Required
                          </p> -->
                          <v-text-field tabindex="13" v-model="contact.address.postalCode" outlined class="mb-3"
                            label="ZIP / Postal Code"></v-text-field>
                          <!-- <p :class="{
                              'is-invalid':
                                submitted &&
                                $v.contact.address.postalCode.$error,
                            }"
                            v-if="
                              submitted &&
                              !$v.contact.address.postalCode.required
                            "
                            class="invalid-feedback"
                          >
                            Postal Code Is Required
                          </p> -->
                          <v-autocomplete tabindex="15" :items="EditStates" :item-text="'name'" :item-value="'id'"
                            label="State" outlined @change="EditSearchInCity()" :search-input.sync="searchEditState"
                            v-model="contact.address.stateId" class="mb-3">
                          </v-autocomplete>

                        </v-col>
                      </v-row>
                    </div>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn tabindex="18" text class="cancel-update-btn" @click="close">Cancel</v-btn>

                  <v-btn tabindex="19" class="create-order-btn mr-1" @click="save">Update</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            {{ snackText }}

            <template v-slot:action="{ attrs }">
              <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
            </template>
          </v-snackbar>
        </template>

        <template v-slot:item.clientPhones="{ item }">
          <p v-for="(ph, index) in item.clientPhones" :key="index">
            <span :class="{
              'is-Primary': ph.isPrimary,
            }">{{ ph.phoneNumber }}</span>
          </p>
        </template>

        <template v-slot:item.contactType="{ item }">
          <p>
            <span>{{ item.contactType.name }}</span>
          </p>
        </template>
        <template v-slot:item.address="{ item }">
          <p v-if="item.address">
            <span>{{ item.address.street }}</span>
            <!-- - <span>{{ item.address.buildingNumber }}</span
            >
            - -->
            -<span v-if="item.address.city">{{
              item.address.city.name
            }}</span>
            -<span v-if="item.address.state">{{
              item.address.state.target
            }}</span>-<span v-if="item.address.country">
              {{ item.address.country.name }}</span>
          </p>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2 edit-icon" @click="editItem(item)">
            far fa-edit
          </v-icon>
          <v-icon class="delete-icon" small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm = false">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { dataService } from "@/services/data-service";
import { required, email } from "vuelidate/lib/validators";
import store from "@/store";
//import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      //============ me
      isprimary: 1,
      checkbox2: false,
      checkbox1: false,
      accounts: [],
      accountTerm: "",
      clients: [],
      CompanyqueryTerm: "",
      companies: [],
      ContactOnId: null,
      ContactOn: [
        {
          id: 1,
          name: "location",
        },
        {
          id: 2,
          name: "Company",
        },
        {
          id: 3,
          name: "Client",
        },
        {
          id: 4,
          name: "Account",
        },
      ],
      //======================
      searchEditCityTerm: "",
      Editcities: [],
      searchEditStateTerm: "",
      EditStates: [],
      searchCityTerm: "",
      searchStateTerm: "",
      locationTerm: "",
      stateID: 0,
      dialog: false,
      contact: {
        address: {
          id: 0,
          street: "",
          buildingNumber: "",
          postalCode: "",
          details: "",

          stateId: 0,
          cityId: 0,
          countryId: 0,
        },
        branchId: null,
        companyId: null,
        accountId: null,
        clientId: null,
      },
      locations: [],

      primary: {
        phoneNumber: "",
        isPrimary: true,
      },
      Secondry: {
        phoneNumber: "",
        isPrimary: false,
      },
      snack: false,
      snackColor: "",
      snackText: "",

      submitted: false,
      isLoaded: true,
      ContactFilter: {
        pageNumber: 1,
        pageSize: 10,
        subString: null,
        countryId: 0,
        stateId: 0,
        cityId: 0,
        zipCode: null,
        type: 0,
        columnIndex: 0,
        isDec: false,
      },

      contactTypes: [
        {
          id: 1,
          name: "On_Site",
        },
        {
          id: 2,
          name: "Technical",
        },
        {
          id: 3,
          name: "Account Manager",
        },
      ],
      queryTerm: null,
      cityQueryTerm: null,
      countries: [],
      states: [],
      cities: [],
      dialogDelete: false,
      sizeOptions: [
        {
          id: 10,
          name: 10,
        },
        {
          id: 20,
          name: 20,
        },
        {
          id: 30,
          name: 30,
        },
        {
          id: 50,
          name: 50,
        },
        {
          id: 100,
          name: 100,
        },
      ],
      totalPages: 0,

      value: null,
      headers: [
        { text: "Name", value: "firstName" },
        { text: "Email", value: "email" },

        { text: "Contact Type", value: "contactType" },
        { text: "Contact Address", value: "address" },
        { text: "Client", value: "client" },
        { text: "Account", value: "account" },
        { text: "Company", value: "company" },
        { text: "Location", value: "branch" },
        { text: "Actions", value: "actions" },
      ],
      data: [],
      editedIndex: null,
    };
  },

  methods: {
    formatPhoneNumber(isprimary) {
      if (isprimary == 1) {

        var cleanedprimaryphonenumber = ('' + this.primary.phoneNumber).replace(/\D/g, '');
        var matchprimaryphonenumber = cleanedprimaryphonenumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (matchprimaryphonenumber) {
          var intlCodeprimaryphonenumber = (matchprimaryphonenumber[1] ? '+1 ' : '');
          this.primary.phoneNumber = [intlCodeprimaryphonenumber, '(', matchprimaryphonenumber[2], ') ', matchprimaryphonenumber[3], '-', matchprimaryphonenumber[4]].join('');
        }
      }
      else {

        var cleanedsecondryphonenumber = ('' + this.Secondry.phoneNumber).replace(/\D/g, '');
        var matchsecondryphonenumber = cleanedsecondryphonenumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (matchsecondryphonenumber) {
          var intlCodesecondryphonenumber = (matchsecondryphonenumber[1] ? '+1 ' : '');
          this.Secondry.phoneNumber = [intlCodesecondryphonenumber, '(', matchsecondryphonenumber[2], ') ', matchsecondryphonenumber[3], '-', matchsecondryphonenumber[4]].join('');
        }
      }

    },
    //======================================
    onchange() {
      this.ContactFilter.stateId = 0;

      this.SearchInStates();
    },
    SearchInStates() {
      this.cities = [];
      this.ContactFilter.cityId = 0;
      if (this.ContactFilter.countryId == null)
        this.ContactFilter.countryId = 0;
      if (this.searchStateTerm == null) this.searchStateTerm = "";

      dataService.getList(
        "api/States/search_by_name-countryId",
        {
          pageNumber: 1,
          pageSize: 1000,
          subString: this.searchStateTerm,
          countryId: this.ContactFilter.countryId,
        },
        (response) => {
          this.states = response.data.data.items;

          if (this.searchStateTerm) {
            this.$nextTick(() => this.$refs.stateFocus.focus());
          }
        }
      );
    },
    SearchInCity() {
      if (this.searchCityTerm == null) this.searchCityTerm = "";
      if (this.ContactFilter.stateId == null) this.ContactFilter.stateId = 0;

      dataService.getList(
        "api/Cities/search_sateId_name",

        {
          pageNumber: 1,
          pageSize: 1000,
          subString: this.searchCityTerm,
          stateId: this.ContactFilter.stateId,
        },
        (response) => {
          this.cities = response.data.data.items;

          if (this.searchCityTerm) {
            this.$nextTick(() => this.$refs.stateFocus.focus());
          }
        }
      );
    },

    Editonchange() {
      this.contact.address.stateId = 0;

      this.EditSearchInStates();
    },
    EditSearchInStates() {
      this.Editcities = [];

      if (this.contact.address.countryId == null)
        this.contact.address.countryId = 0;
      if (this.searchEditStateTerm == null) this.searchEditStateTerm = "";

      dataService.getList(
        "api/States/search_by_name-countryId",
        {
          pageNumber: 1,
          pageSize: 1000,
          subString: this.searchEditStateTerm,
          countryId: this.contact.address.countryId,
        },
        (response) => {
          this.EditStates = response.data.data.items;
        }
      );
    },

    EditSearchInCity() {
      if (this.searchEditCityTerm == null) this.searchEditCityTerm = "";
      if (this.contact.address.stateId == null)
        this.contact.address.stateId = 0;

      dataService.getList(
        "api/Cities/search_sateId_name",

        {
          pageNumber: 1,
          pageSize: 1000,
          subString: this.searchEditCityTerm,
          stateId: this.contact.address.stateId,
        },
        (response) => {
          this.Editcities = response.data.data.items;
        }
      );
    },

    deleteItem(item) {
      this.editedIndex = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      dataService.postDelete(
        "api/Contacts/delete/" + this.editedIndex,
        (response) => {
          if (response.data.succeeded) {
            store.commit("SHOW_MESSAGE", {
              text: "Deleted Successfully",
              color: "success",
              timeout: 3000,
            });
            this.dialogDelete = false;
            this.realSearch(1);
          }
        }
      );
    },

    realSearch(size) {
      // clear timeout variable
      this.isLoaded = true;
      var self = this;
      self.ContactFilter.pageNumber = size;
      dataService.getList(
        "api/Contacts/get-all",
        self.ContactFilter,
        (response) => {
          this.isLoaded = false;
          self.data = response.data.data.items;

          self.totalPages = response.data.data.totalPages;
        }
      );
    },
    handleOptionsUpdate({ sortBy, sortDesc }) {
      if (sortBy.length > 0) {
        if (sortBy[0] == "name") this.ContactFilter.columnIndex = 0;
        if (sortBy[0] == "email") this.ContactFilter.columnIndex = 1;
        if (sortBy[0] == "branch") this.ContactFilter.columnIndex = 2;
        if (sortBy[0] == "contactType") this.ContactFilter.columnIndex = 4;

        this.ContactFilter.isDec = sortDesc[0];

        this.realSearch(1);
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },

    applyLocations(queryTerm) {
      dataService.getList(
        "api/Branches/search_by_name",
        {
          pageNumber: 1,
          pageSize: 200,
          subString: queryTerm,
          branchId: this.contact.branchId,
        },
        (response) => {
          this.locations = response.data.data.items;
          this.$nextTick(() => this.$refs.locationSearch.focus());
        }
      );
    },
    getContactOn() {
      if (this.ContactOnId == 1) {
        this.contact.companyId = null;
        this.contact.accountId = null;
        this.contact.clientId = null;
      } else if (this.ContactOnId == 2) {
        this.contact.branchId = null;
        this.contact.accountId = null;
        this.contact.clientId = null;
      } else if (this.ContactOnId == 3) {
        this.contact.companyId = null;
        this.contact.accountId = null;
        this.contact.branchId = null;
      } else if (this.ContactOnId == 4) {
        this.contact.companyId = null;
        this.contact.clientId = null;
        this.contact.branchId = null;
      }
    },
    editItem(item) {
      this.loadCompany();
      this.loadAccounts();

      this.editedIndex = item.id;
      dataService.getAll("api/Contacts/" + item.id, (response) => {

        this.contact = response.data;


        if (this.contact.address == null) {
          this.contact.address = {
            id: 0,
            street: "",
            buildingNumber: "",
            postalCode: "",
            details: "",

            stateId: 0,
            cityId: 0,
            countryId: 0,
          };
        }

        this.EditSearchInStates();
        this.EditSearchInCity();

        this.applyLocations("");

        this.contact.contactPhones.forEach((element) => {
          if (element.isPrimary) {
            this.primary = element;
          } else {
            this.Secondry = element;
          }
        });
        //api call
        if (this.contact.branchId != null) {
          this.ContactOnId = 1;
        } else if (this.contact.companyId != null) {
          this.ContactOnId = 2;
        } else if (this.contact.clientId != null) {
          this.ContactOnId = 3;
        } else if (this.contact.accountId != null) {
          this.ContactOnId = 4;
        }


        this.dialog = true;
      });
    },

    getLocations(queryTerm) {
      var self = this;
      if (queryTerm) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyLocations(queryTerm);
        }, 1000);
      } else {
        self.applyLocations(queryTerm);
      }
    },

    save() {

      this.submitted = true;
      this.$v.contact.$touch();
      if (this.$v.contact.$invalid) {
        return;
      } else {
        let ClientPhones = [];
        ClientPhones.push(this.primary);

        ClientPhones.push(this.Secondry);
        this.contact.contactPhones = ClientPhones;
        dataService.update(
          "api/Contacts/update",
          { contact: this.contact },
          (response) => {
            if (response.data.succeeded) {
              this.dialog = false;
              this.snack = true;
              this.snackColor = "success";
              this.snackText = "Data Updated";
              this.realSearch(1);
            }
          }
        );
      }
    },

    resetFilter() {
      this.ContactFilter = {
        pageNumber: 1,
        pageSize: 10,
        search: null,
        countryId: 0,
        stateId: 0,
        cityId: 0,
        zipCode: null,
        type: 0,
        columnIndex: 0,
        isDec: false,
      };
      this.realSearch(1);
    },

    getContactsSearch() {
      dataService.getList(
        "api/Contacts/get-all",
        this.ContactFilter,
        (response) => {
          this.data = response.data.data.items;
          this.$nextTick(() => this.$refs.objet.focus());
          this.totalPages = response.data.data.totalPages;
        }
      );
    },

    getCountries() {
      dataService.getAll("/api/Countries", (response) => {
        this.countries = response.data;
        this.isLoaded = false;
      });
    },
    //=======================================
    applyCompanies(value) {
      dataService.getList(
        "api/Companies/search_by_name",
        {
          pageNumber: 1,
          pageSize: 1000,
          subString: value,
          companyId: this.contact.CompanyId,
        },
        (response) => {
          this.companies = response.data.data.items;
          this.$nextTick(() => this.$refs.lcn.focus());
        }
      );
    },
    allCompanies(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyCompanies(value);
        }, 1000);
        self.$nextTick(() => self.$refs.lcn.focus());
      } else {
        self.applyCompanies(value);
      }
    },
    async loadCompany() {
      await this.allCompanies(this.queryTerm || "");
    },
    async loadEntries() {
      await this.getLocations(this.queryTerm || "");
    },

    //==============================================================
    getClientsFromApi() {
      dataService.getAll(
        "api/Clients/get-all",

        (response) => {
          this.clients = response.data;
        }
      );
    },
    //=====================
    applyAccounts(value) {
      dataService.getList(
        "api/Accounts/search_by_name",
        {
          pageNumber: 1,
          pageSize: 100,
          subString: value,
          accountId: this.contact.accountId,
        },
        (response) => {
          this.accounts = response.data.data.items;
          if (value) {
            this.$nextTick(() => this.$refs.accountSearch.focus());
          }
        }
      );
    },

    getAccounts(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyAccounts(value);
        }, 1000);
      } else {
        self.applyAccounts(value);
      }
    },

    async loadAccounts() {

      await this.getAccounts(this.accountTerm || "");
    },
    //==============================================================
  },

  validations: {
    contact: {
      firstName: { required },

      email: { required, email },
      contactTypeId: { required },
      // branchId: { required },

      // address: {
      //   street: { required },
      //   buildingNumber: { required },
      //   postalCode: { required },
      //   cityId: { required },
      // },
    },
  },

  mounted() {
    this.getClientsFromApi();
    this.realSearch(1);
    this.getCountries();
  },
  created() { },

  computed: {
    Companysearch: {
      get() {
        return this.CompanyqueryTerm;
      },

      set(searchInput) {
        if (this.CompanyqueryTerm !== searchInput) {
          this.CompanyqueryTerm = searchInput;
          this.loadCompany();
        }
      },
    },
    //====================================
    accountsearch: {
      get() {
        return this.accountTerm;
      },

      set(searchInput) {
        if (this.accountTerm !== searchInput) {
          this.accountTerm = searchInput;
          this.loadAccounts();
        }
      },
    },

    //=============================

    locationSearch: {
      get() {
        return this.locationTerm;
      },

      set(searchInput) {
        if (this.locationTerm !== searchInput) {
          this.locationTerm = searchInput;
          this.applyLocations(this.locationTerm);
        }
      },
    },
    searchState: {
      get() {
        return this.searchStateTerm;
      },

      set(searchInput) {


        if (this.searchStateTerm !== searchInput) {
          this.searchStateTerm = searchInput;
          this.SearchInStates();
        }
      },
    },
    searchCity: {
      get() {
        return this.searchCityTerm;
      },

      set(searchInput) {


        if (this.searchCityTerm !== searchInput) {
          this.searchCityTerm = searchInput;
          this.SearchInCity();
        }
      },
    },

    searchEditState: {
      get() {
        return this.searchEditStateTerm;
      },

      set(searchInput) {


        if (this.searchEditStateTerm !== searchInput) {
          this.searchEditStateTerm = searchInput;
          this.EditSearchInStates();
        }
      },
    },
    searchEditCity: {
      get() {
        return this.searchEditCityTerm;
      },

      set(searchInput) {


        if (this.searchEditCityTerm !== searchInput) {
          this.searchEditCityTerm = searchInput;
          this.EditSearchInCity();
        }
      },
    },

    search: {
      get() {
        return this.queryTerm;
      },

      set(searchInput) {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput;
          this.loadEntries();
        }
      },
    },
  },
};
</script>