<template>
    <div>
        <div v-if="isOpenB" class="test">
            <v-icon @click="click" class="btnbar">X</v-icon>
            <br>
            <br>
            <div class="px-4 py-0">
                <v-row>
                    <v-col cols="12" md="8" class="FinRepo-sty" >
                        <p class="table-title">Financial Report</p>
                    </v-col>
                </v-row>
                <!-- List Custom Date -->

                <p class="Report-color">Service date :</p><br>
                <v-row>
                    <v-col cols="12" md="12" style="margin-bottom:-14px ;" class="FinRepo-sty">
                        <v-autocomplete class="" ref="clientSearch" multiple outlined label="Custom date"
                            :items="clients" item-text="name" item-value="id" v-model="clientIds"
                            @change="handleClientFilter">
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <!-- From -->
                <v-row style="margin-bottom:-35px ;">
                    <v-col cols="6" md="6" class="FinRepo-sty">
                        <v-autocomplete class="" ref="DateFrom" prepend-inner-icon="mdi-calendar" multiple outlined
                            label="From" :items="From" item-text="name" item-value="id" v-model="clientIds"
                            @change="handleClientFilter">
                        </v-autocomplete>
                    </v-col>
                    <!-- Date To -->
                    <v-col cols="6" md="6" class="FinRepo-sty">
                        <v-autocomplete class="" ref="DateTo" prepend-inner-icon="mdi-calendar" multiple outlined
                            label="To" :items="To" item-text="name" item-value="id" v-model="clientIds"
                            @change="handleClientFilter">
                        </v-autocomplete>
                    </v-col>
                </v-row>

                <hr />
                <br>
                <!-- ======= Filter by Client ======= -->
                <v-row>
                    <v-col cols="12" md="8" class="">
                        <p class="FinRepo-sty">Filter By Client</p>
                    </v-col>
                </v-row>
                <!-- List TECH -->
                <v-col cols="12" md="12" class="FinRepo-sty">
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-autocomplete class="" style="margin-bottom:-35px ;" ref="clientSearch" multiple outlined
                                label="Select" :items="clients" item-text="name" item-value="id" v-model="clientIds"
                                @change="handleClientFilter">
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- ============= Filter By Account  -->
                <hr />
                <br />
                <v-row>
                    <v-col cols="12" md="8" class="">
                        <p class="FinRepo-sty">Filter By Account</p>
                    </v-col>
                </v-row>
                <!-- List TECH -->
                <v-col cols="12" md="12" class="FinRepo-sty">
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-autocomplete class="" style="margin-bottom:-35px ;" ref="clientSearch" multiple outlined
                                label="Select" :items="clients" item-text="name" item-value="id" v-model="clientIds"
                                @change="handleClientFilter">
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- ======= Filter by Location ======= -->
                <hr />
                <br />
                <v-row>
                    <v-col cols="12" md="8" class="">
                        <p class="FinRepo-sty">Filter By Location</p>
                    </v-col>
                </v-row>
                <!-- List TECH -->
                <v-col cols="12" md="12" class="FinRepo-sty">
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-autocomplete class="" style="margin-bottom:-35px ;" ref="clientSearch" multiple outlined
                                label="Select" :items="clients" item-text="name" item-value="id" v-model="clientIds"
                                @change="handleClientFilter">
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- ============= Filter By Vendor  -->
                <hr />
                <br />
                <v-row>
                    <v-col cols="12" md="8" class="">
                        <p class="FinRepo-sty">Filter By Vendor</p>
                    </v-col>
                </v-row>
                <!-- List TECH -->
                <v-col cols="12" md="12" class="FinRepo-sty">
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-autocomplete class="" style="margin-bottom:-29px ;" ref="clientSearch" multiple outlined
                                label="TECH" :items="clients" item-text="name" item-value="id" v-model="clientIds"
                                @change="handleClientFilter">
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-col>

            </div>
        </div>

        <div id="fixed" class="test2">
            <v-icon v-if="!isOpenB" @click="click">fa-regular fa-bars</v-icon>
            <span>
                <span class="FinancialTab" style="text-align:center; padding: 20%;">
                    <span style="color:#1B7BF9;">Report from</span> Jan 1,2022 <span style="color:#1B7BF9;">to</span>
                    March 20,2022 <span style="color: lightslategrey;">Custom date</span>
                </span>
                <a href="#" class="FinancialTab"><b>Invoces</b></a>
                <table style="text-align: center;display: block;height: 100%;width: 100%; text-align: center;" class="table table-hover">
                    <thead style="text-align: center;">
                        <tr>
                            <th  class="colsrowpad px-8" scope="col">Client</th>
                            <th class="colsrowpad px-8" scope="col">time</th>
                            <th class="colsrowpad px-8" scope="col">time</th>
                            <th class="colsrowpad px-8" scope="col">time</th>
                            <th class="colsrowpad px-8" scope="col">time</th>
                            <th class="colsrowpad px-8" scope="col">time</th>
                            <th class="colsrowpad px-8" scope="col">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Stefanini</td>
                            <td class="FinancialTabColor">$192</td>
                            <td class="FinancialTabColor">$234</td>
                            <td class="FinancialTabColor">$378</td>
                            <td class="FinancialTabColor">$120</td>
                            <td class="FinancialTabColor">$120</td>
                            <td class="FinancialTabColor">$1.044</td>
                        </tr>
                        <tr>
                            <td>LVN</td>
                            <td class="FinancialTabColor">$192</td>
                            <td class="FinancialTabColor">$234</td>
                            <td class="FinancialTabColor">$378</td>
                            <td class="FinancialTabColor">$120</td>
                            <td class="FinancialTabColor">$120</td>
                            <td class="FinancialTabColor">$1.044</td>
                        </tr>
                        <tr>
                            <td>Nike</td>
                            <td class="FinancialTabColor">$192</td>
                            <td class="FinancialTabColor">$234</td>
                            <td class="FinancialTabColor">$378</td>
                            <td class="FinancialTabColor">$120</td>
                            <td class="FinancialTabColor">$120</td>
                            <td class="FinancialTabColor">$1.044</td>
                        </tr>
                        <tr>
                            <td>Refund</td>
                            <td class="FinancialTabColor">$192</td>
                            <td class="FinancialTabColor">$234</td>
                            <td class="FinancialTabColor">$378</td>
                            <td class="FinancialTabColor">$120</td>
                            <td class="FinancialTabColor">$120</td>
                            <td class="FinancialTabColor">$1.044</td>
                        </tr>
                    </tbody>
                </table>
            </span>


        </div>
    </div>
</template>




<script>
export default {
    data() {
        return {
            isOpenB: false,
        };
    },
    methods: {
        click() {
            this.isOpenB = !this.isOpenB;
            if (this.isOpenB) {
                const elem = document.getElementById("fixed");
                elem.style.width = "75%";
                elem.style.float = "right";
            } else {
                const elem = document.getElementById("fixed");
                elem.style.width = "100%";
                elem.style.float = "none";
            }
        },

        currentDateTime() {
            const current = new Date();
            const date = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
            const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
            const dateTime = date + ' ' + time;

            return dateTime;
        },
        // fulldate() {
        //   const current = new Date();
        //   return current;
        // },
    },
};
</script>