var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "handle-test" }, [
    _c("p", { staticClass: "table-title" }, [_vm._v("New Company")]),
    _c(
      "div",
      { staticClass: "innner-container" },
      [
        _c(
          "v-row",
          [
            _c("v-col", { attrs: { md: "10" } }),
            _c(
              "v-col",
              { attrs: { md: "2" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "resetWO-btn",
                    on: { click: _vm.emptyButtons },
                  },
                  [
                    _c("i", { staticClass: "fas fa-redo" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-1" }, [_vm._v("Reset")]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [
                _c("v-autocomplete", {
                  ref: "objet",
                  staticClass: "mb-3",
                  class: {
                    "is-invalid":
                      _vm.submitted && _vm.$v.company.accountId.$error,
                  },
                  attrs: {
                    items: _vm.accounts,
                    "item-text": "name",
                    "item-value": "id",
                    label: "Select Account",
                    outlined: "",
                    "search-input": _vm.search,
                  },
                  on: {
                    "update:searchInput": function ($event) {
                      _vm.search = $event
                    },
                    "update:search-input": function ($event) {
                      _vm.search = $event
                    },
                  },
                  model: {
                    value: _vm.company.accountId,
                    callback: function ($$v) {
                      _vm.$set(_vm.company, "accountId", $$v)
                    },
                    expression: "company.accountId",
                  },
                }),
                _vm.submitted && !_vm.$v.company.accountId.required
                  ? _c("p", { staticClass: "invalid-feedback" }, [
                      _vm._v(" Account is Required "),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [
                _c("v-text-field", {
                  staticClass: "mb-3",
                  class: {
                    "is-invalid": _vm.submitted && _vm.$v.company.name.$error,
                  },
                  attrs: { outlined: "", label: "Name" },
                  model: {
                    value: _vm.company.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.company, "name", $$v)
                    },
                    expression: "company.name",
                  },
                }),
                _vm.submitted && !_vm.$v.company.name.required
                  ? _c("p", { staticClass: "invalid-feedback" }, [
                      _vm._v(" Name is Required "),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c("v-divider", { staticClass: "mb-10" }),
        _c(
          "div",
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c("v-text-field", {
                      staticClass: "mb-3",
                      class: {
                        "is-invalid": _vm.submitted && _vm.v.phoneNumber.$error,
                      },
                      attrs: {
                        outlined: "",
                        label: "Primary Phone",
                        set: (_vm.v = _vm.$v.company.companyPhones.$each[0]),
                      },
                      on: {
                        blur: function ($event) {
                          return _vm.formatPhoneNumber(1)
                        },
                      },
                      model: {
                        value: _vm.company.companyPhones[0].phoneNumber,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.company.companyPhones[0],
                            "phoneNumber",
                            $$v
                          )
                        },
                        expression: "company.companyPhones[0].phoneNumber",
                      },
                    }),
                    _vm.submitted && !_vm.v.phoneNumber.required
                      ? _c("p", { staticClass: "invalid-feedback" }, [
                          _vm._v(" Primary Phone is Required "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c("v-text-field", {
                      staticClass: "mb-3",
                      attrs: { outlined: "", label: "Secondary Phone" },
                      on: {
                        blur: function ($event) {
                          return _vm.formatPhoneNumber(2)
                        },
                      },
                      model: {
                        value: _vm.secondaryPhone,
                        callback: function ($$v) {
                          _vm.secondaryPhone = $$v
                        },
                        expression: "secondaryPhone",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "btn-container" },
          [
            _c(
              "v-btn",
              {
                staticClass: "create-order-btn mr-1",
                on: { click: _vm.check },
              },
              [_vm._v("Create Company")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }