<template>
  <div id="report-timsheet" class="handle-test">
    <div>
      <v-row>
        <v-col cols="12" md="10">
          <p v-if="requestBody.isPending" class="table-title">
            Pending Timesheets
          </p>
          <p v-else class="table-title">Rejected TimeSheets</p>
        </v-col>

        <v-col md="1" cols="12"> </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="pad0">
          <v-row style="margin-bottom: 2%;"> 
            <v-col cols="6" md="4">
               <p  ><img  src="@/assets/images/Pending.png" /></p>
              <v-btn @click="GetPending()" class="filter-btn assigned-btn">{{
                jellyBeans.pendingCount
              }}</v-btn>
            </v-col>

            <v-col  cols="6" md="4">
               <p  ><img  src="@/assets/images/Disapproved.png" /></p>
              <v-btn @click="GetDisapproved()" class="filter-btn fh-btn">{{
                jellyBeans.disApprovedCount
              }}</v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="8" class="pad0">
          <v-row>
            <v-col cols="12" md="4">

                <v-autocomplete
                multiple
                outlined
                label="Select Clients"
                :items="clients"
                item-text="name"
                item-value="id"
                v-model="clientIds"
                @change="handleClientFilter"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="removeClient(data.item.id)"
                  >
                    {{ data.item.name }}
                  </v-chip>
                </template>
              </v-autocomplete>
            
            </v-col>
            <v-col cols="12" md="4">
               <v-autocomplete
                ref="accountSearch"
                :items="accounts"
                item-text="name"
                item-value="id"
                v-model="accountIds"
                multiple
                outlined
                label="Select Accounts"
                :search-input.sync="accountsearch"
                @change="handleAccountFilter"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="removeAccount(data.item.id)"
                  >
                    {{ data.item.name }}
                  </v-chip>
                </template>
              </v-autocomplete>
              
            </v-col>
            <v-col cols="12" md="4">

               <v-autocomplete
                ref="locationSearch"
                :items="locations"
                item-text="name"
                item-value="id"
                v-model="locationIds"
                multiple
                outlined
                label="Select Locations"
                @change="handleLocationFilter"
                :search-input.sync="locationSearch"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="removeLocation(data.item.id)"
                  >
                    {{ data.item.name }}
                  </v-chip>
                </template>
              </v-autocomplete>
            
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="pad0">
          <v-text-field
            solo
            label="Search "
            append-icon="mdi-magnify"
            v-model="requestBody.subString"
            @keyup="getData()"
            ref="objet"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="8" class="pad0">
          <v-row>
            <v-col cols="12" md="4">
               <v-menu
                ref="menu"
                v-model="menuStartDate"
                :close-on-content-click="false"
                :return-value.sync="SDFrom"
                transition="scale-transition"
                offset-y
                min-width="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                  hint="MM/DD/YYYY format"
                @blur="SDFrom = parseDate(SDFrom)"
                v-model="dateFormatted"
                  
                    label="Creation / Start Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="SDFrom"
                  no-title
                  scrollable
                  @input="$refs.menu.save(SDFrom)"
                  @change="getTimesheetByDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuStartDate = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(SDFrom)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4">
             <v-menu
                ref="menu2"
                v-model="menuEndDate"
                :close-on-content-click="false"
                :return-value.sync="SDTo"
                transition="scale-transition"
                offset-y
                min-width="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                  hint="MM/DD/YYYY format"
                @blur="SDTo = parseDate(SDTo)"
                v-model="dateFormattedEnd"
              
                    label="Creation / End Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="SDTo"
                  no-title
                  scrollable
                  @input="$refs.menu2.save(SDTo)"
                  @change="getTimesheetByDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuEndDate = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu2.save(SDTo)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn
                @click="reset"
                class="btn-creation diff-style mt-1"
                color="#D92020"
                >Reset Filter</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-data-table
        :loading="isLoaded"
        loading-text="Loading... Please wait"
        :headers="headers"
        :items="data"
        @click:row="rowClick"
        class="elevation-1"
        item-key="id"
        :server-items-length="totalLength"
        show-select
        v-model="Selection"
        @update:options="handleSorting"
        show-expand
        hide-default-footer
        :expanded.sync="expanded"
      >
        <template #expanded-item="{ headers, item }">
          <td class="px-0 py-2" :colspan="headers.length">
        
            <v-icon class="plus-padding" @click="ShowExpenseDialog(item)" small>
              fa-solid fa-plus
            </v-icon>
        
            <v-data-table
              :headers="timeSheetHeaders"
              :server-items-length="item.timeSheetExpenses.length"
              :items="item.timeSheetExpenses"
              class="elevation-1"
              hide-default-footer
              :colspan="7"
            >
            </v-data-table>
          </td>
        </template>
        <template v-slot:item.totalHours="{ item }">
          <p>
            {{ item.totalHours + " hr" }}
          </p>
        </template>
        <template v-slot:item.totalExpenseHoures="{ item }">
          <p>
            {{ item.totalExpenseHoures + " hr" }}
          </p>
        </template>
        <template v-slot:item.creationDate="{ item }">
          <p>
            {{
              convertUTCDateToLocalDate(
                new Date(item.creationDate)
              ).toLocaleString()
            }}
          </p>
        </template>
        <template v-slot:item.payRate="{ item }">
          <p>
            {{ "$ " + item.payRate }}
          </p>
        </template>
        <template v-slot:item.totalPayRate="{ item }">
          <p>
            {{ "$ " + item.totalPayRate }}
          </p>
        </template>
        <template v-slot:item.totalExpenses="{ item }">
          <p>
            {{ "$ " + item.totalExpenses }}
          </p>
        </template>
        <template v-slot:item.billHourlyRate="{ item }">
          <p>
            {{ "$ " + item.billHourlyRate }}
          </p>
        </template>
        <template v-slot:footer>
          <v-row class="mt-5 fit-width">
            <v-col md="2">
              <v-select
                :items="sizeOptions"
                v-model="requestBody.pageSize"
                :item-value="'id'"
                :item-text="'name'"
                @change="resetPageNumber()"
                solo
              ></v-select>
            </v-col>
            <v-col md="5">
              <v-pagination
                v-model="requestBody.pageNumber"
                :length="totalPages"
                @input="getData()"
                total-visible="6"
              >
              </v-pagination>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </div>

    <div v-if="Selection.length > 0" class="btn-container">
      <v-btn class="float btn-creation wo-diff-style" @click="Approval(true)"
        >Approve All</v-btn
      >
      <v-btn
        v-if="!disapproved"
        class="float2 btn-creation wo-diff-style"
        @click="Approval(false)"
        color="red"
      >
        Disapprove All</v-btn
      >
    </div>

    <v-btn
      v-if="disapproved && Selection.length > 0"
      class="float2 btn-creation wo-diff-style"
      @click="RetrunToPending()"
      color="#a6c8a7 !important"
      >Retrun To Pending</v-btn
    >

    <!-- ========================================== -->
    <v-dialog v-model="expenseDialoge" max-width="700px">
      <v-card>
        <v-card-title class="text-h5">New Expense</v-card-title>

        <v-card-text>
          <br />

          <v-select
            :items="expensesTypes"
            :item-text="'name'"
            :item-value="'id'"
            label="Add Fees, Discount, Tax, ... "
            solo
            v-model="expenseTypeId"
            :class="{
              'is-invalid': submittedExpense && $v.expenseTypeId.$error,
            }"
          ></v-select>
          <p
            v-if="submittedExpense && !$v.expenseTypeId.required"
            class="invalid-feedback mt-2"
          >
            Expense Type Is Required
          </p>
          <br />
          <v-row>
            <v-col md="3" cols="2">
              <v-select
                solo
                :item-text="'name'"
                :item-value="'id'"
                :items="amountTypes"
                label="$"
                v-model="feild.amountTypeId"
                :class="{
                  'is-invalid':
                    submittedExpense && $v.feild.amountTypeId.$error,
                }"
              ></v-select>
              <p
                v-if="submittedExpense && !$v.feild.amountTypeId.required"
                class="invalid-feedback mt-2"
              >
                Amount Type Is Required
              </p>
            </v-col>

            <v-col md="9" cols="10">
              <v-text-field
                v-model="feild.expenseAmount"
                outlined
                label="Amount"
                :class="{
                  'is-invalid':
                    submittedExpense && $v.feild.expenseAmount.$error,
                }"
              >
              </v-text-field>
              <p
                v-if="submittedExpense && !$v.feild.expenseAmount.required"
                class="invalid-feedback mt-2"
              >
                Amount Is Required
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                placeholder="Description"
                v-model="feild.description"
                :label="feild.name"
                :class="{
                  'is-invalid': submittedExpense && $v.feild.description.$error,
                }"
              ></v-text-field>
              <p
                v-if="submittedExpense && !$v.feild.description.required"
                class="invalid-feedback mt-2"
              >
                Description Is Required
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="blue darken-1" text @click="addFeild">Add</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { dataService } from "@/services/data-service";
import { required } from "vuelidate/lib/validators";
import store from "@/store";

export default {
  data: () => ({
    dateFormattedEnd:null,
    dateFormatted:null,
    totalPages: 0,
    isLoaded: true,
    jellyBeans: { pendingCount: 0, disApprovedCount: 0 },
    approved: false,
    disapproved: false,
    Selection: [],
    expanded: [],
    accounts: [],
    accountIds: [],
    expenseTypeId: null,
    submittedExpense: false,
    expenseDialoge: false,
    //////////////////////////////
    // color:"red",

    amountTypes: [
      {
        id: 2,
        name: "$",
      },
      {
        id: 3,
        name: "Hr",
      },
    ],
    feild: {
      workOrderExpenseType: null,
      description: null,
      expenseAmount: null,
    },
    expenses: {},
    TimesheetId: 0,
    expensesTypes: [],

    menuStartDate: false,
    menuEndDate: false,
    SDTo: null,
    SDFrom: null,
    clients: [],
    clientIds: [],

    locations: [],
    locationIds: [],
    search: "",

    headers: [
      { text: "Timesheet ID", value: "id" },
      { text: "Master Work Order ID", value: "orderId" },

      { text: "Sub Work Order ID", value: "tecWoId" },

      { text: "Technician Name", value: "technicianName" },
      { text: "Client", value: "clientName" },
      { text: "Account", value: "accountName" },
      { text: "Location", value: "location" },
      { text: "Creation Date", value: "creationDate" },

      { text: "Pay Rate", value: "payRate" },
      { text: "Σ Payable Hours", value: "totalHours" },
      { text: "Σ Payable Amount", value: "totalPayRate" },
      { text: "Bill Rate", value: "billHourlyRate" },
      { text: "Σ Expenses Hours", value: "totalExpenseHoures" },
      { text: "Σ Expenses Amount", value: "totalExpenses" },
    ],

    timeSheetHeaders: [
      { text: "Expense Type", value: "workOrderExpenseTypeName" },
      { text: "Amount", value: "expenseAmount" },
      { text: "Amount Type", value: "amountTypeName" },
      { text: "Description", value: "description" },
    ],
    sizeOptions: [
      {
        id: 10,
        name: 10,
      },
      {
        id: 20,
        name: 20,
      },
      {
        id: 30,
        name: 30,
      },
      {
        id: 50,
        name: 50,
      },
      {
        id: 100,
        name: 100,
      },
    ],
    accountTerm: "",
    data: [],
    totalLength: 0,
    locationTerm: "",
    requestBody: {
      pageNumber: 1,
      pageSize: 10,
      subString: "",
      isPending: true,
    },
    showInvoiceComponent: false,
  }),
  methods: {
    formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },

    removeAccount(item) {
      const index = this.accountIds.indexOf(item);
      if (index >= 0) this.accountIds.splice(index, 1);
      this.requestBody.accountIds = this.accountIds;
      this.getData();
    },
    removeLocation(item) {
      const index = this.locationIds.indexOf(item);
      if (index >= 0) this.locationIds.splice(index, 1);
      this.requestBody.branchIds = this.locationIds;
      this.getData();
    },
    removeClient(item) {
      const index = this.clientIds.indexOf(item);
      if (index >= 0) this.clientIds.splice(index, 1);
      this.requestBody.clientIds = this.clientIds;
      this.getData();
    },

    handleSorting({ sortBy, sortDesc }) {
      //  
  
      if (sortBy.length > 0) {
        if (sortBy[0] == "id") this.requestBody.sortedColumnIndex = 0;
        if (sortBy[0] == "orderId") this.requestBody.sortedColumnIndex = 1;
        if (sortBy[0] == "tecWoId") this.requestBody.sortedColumnIndex = 2;

        if (sortBy[0] == "technicianName")
          this.requestBody.sortedColumnIndex = 3;
        if (sortBy[0] == "clientName") this.requestBody.sortedColumnIndex = 4;
        if (sortBy[0] == "accountName") this.requestBody.sortedColumnIndex = 5;
        if (sortBy[0] == "location") this.requestBody.sortedColumnIndex = 6;

        if (sortBy[0] == "creationDate") this.requestBody.sortedColumnIndex = 7;
        if (sortBy[0] == "payRate") this.requestBody.sortedColumnIndex = 8;
        if (sortBy[0] == "totalHours") this.requestBody.sortedColumnIndex = 9;
        if (sortBy[0] == "totalPayRate")
          this.requestBody.sortedColumnIndex = 10;
        if (sortBy[0] == "billHourlyRate")
          this.requestBody.sortedColumnIndex = 11;

        if (sortBy[0] == "totalExpenseHoures")
          this.requestBody.sortedColumnIndex = 12;
        if (sortBy[0] == "totalExpenses")
          this.requestBody.sortedColumnIndex = 13;

        this.requestBody.isDec = sortDesc[0];
        this.getData();
      }
    },

    RetrunToPending() {
      let selectedList = [];
      this.Selection.forEach((element) => {
        selectedList.push(element.id);
      });

      dataService.create(
        "api/Invoices/change-time-sheet-status",
        {
          timeSheetIds: selectedList,
          approved: this.approved,
          retrunToPending: true,
        },
        () => {
          this.GetPending();
          this.Selection = [];
        }
      );
    },
    Approval(flag) {
      let selectedList = [];
      this.Selection.forEach((element) => {
        selectedList.push(element.id);
      });

      this.approved = flag;

      dataService.create(
        "api/Invoices/change-time-sheet-status",
        {
          timeSheetIds: selectedList,
          approved: this.approved,
        },
        () => {
          this.getData();
          this.Selection = [];
        }
      );
    },
    resetPageNumber() {
      this.requestBody.pageNumber = 1;
      this.getData();
    },
    getAccounts(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyAccounts(value);
        }, 1000);
      } else {
        self.applyAccounts(value);
      }
    },
    applyAccounts(value) {
      dataService.getList(
        "api/Accounts/search_by_name",
        {
          pageNumber: 1,
          pageSize: 100,
          subString: value,
        },
        (response) => {
          this.accounts = response.data.data.items;
          if (value) {
            this.$nextTick(() => this.$refs.accountSearch.focus());
          }
        }
      );
    },
    GetPending() {
      this.requestBody.isPending = true;
      localStorage.isPending = true;
      this.Selection = [];
      this.disapproved = false;
      this.getData();
    },
    GetDisapproved() {
      this.requestBody.isPending = false;
      this.Selection = [];
      this.disapproved = true;
      localStorage.isPending = false;
      this.getData();
    },
    handleAccountFilter() {
      var companyIds = [];
      this.requestBody.accountIds = this.accountIds;
      if (this.accountIds.length > 0) {
        dataService.create(
          "api/Companies/accounts",
          this.accountIds,
          (response) => {
            this.companies = response.data.data;
            for (var c = 0; c < this.companies.length; c++) {
              companyIds.push(this.companies[c].id);
            }
            dataService.create(
              "api/Branches/companies",
              companyIds,
              (response) => {
                this.locations = response.data.data;
              }
            );
            this.getData();
          }
        );
      } else {
        this.loadAccounts();
        this.loadLocations();
        this.getData();
      }
    },
    async loadAccounts() {
      this.accounts = await this.getAccounts(this.accountTerm || "");
    },
    convertUTCDateToLocalDate(date) {
      var newDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60 * 1000
      );
      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();
      newDate.setHours(hours - offset);
      return newDate;
    },
    sendToInvoice() {
      this.showInvoiceComponent = true;
    },
    change() {
      this.showInvoiceComponent = false;
    },
    reset() {
      (this.menuStartDate = false),
        (this.menuEndDate = false),
        (this.SDTo = null),
        (this.SDFrom = null),
        (this.accounts = []),
        (this.accountIds = []),
        (this.clientIds = []),
        (this.locationIds = []),
        (this.requestBody = {
          pageNumber: 1,
          pageSize: 10,
          subString: "",
        });
      this.getData();
    },
    realSearch() {
      this.requestBody.pageNumber = 1;
      clearTimeout(this.timeout);
      var self = this;
      this.timeout = setTimeout(function () {
        self.getData();
      }, 1000);
    },
    getTimesheetByDate() {
      if (this.SDFrom && !this.SDTo) {
        return;
      }
      if (!this.SDFrom) {
        store.commit("SHOW_MESSAGE", {
          text: "Please Select Start Date first",
          color: "error",
          timeout: 3000,
        });
        return;
      } else {
        this.requestBody.creationDate = {
          from: this.SDFrom + "T00:00:00Z",
          to: this.SDTo + "T00:00:00Z",
        };
        this.getData();
      }
    },
    handleClientFilter() {
      this.requestBody.clientIds = this.clientIds;
      this.getData();
    },
    getClients() {
      dataService.getAll("api/Clients/get-all", (response) => {
        this.clients = response.data;
      });
    },
    handleLocationFilter() {
      this.requestBody.branchIds = this.locationIds;
      this.getData();
    },
    rowClick: function (item, row) {
      //  
      row.select(true);
 
      localStorage.isPending = true;
      this.$router.push("/reports/TimeSheetDetails/" + item.id);
    },
    async loadLocations() {
      this.accounts = await this.getLocations(this.locationTerm || "");
    },
    addFeild() {
      
      this.submittedExpense = true;

      this.$v.$touch();
      

      if (this.$v.expenseTypeId.$invalid) {
        return;
      }

      if (this.$v.feild.$invalid) {
        return;
      }
      this.expenseDialoge = false;
      this.expensesTypes.forEach((element) => {
        if (element.id == this.feild.expenseTypeId)
          this.feild.workOrderExpenseType = element.name;
      });
      this.feild.expenseTypeId = this.expenseTypeId;
      this.expenses.push(this.feild);
 
      dataService.create(
        "api/TimeSheetExpense/Create",
        {
          timeSheetId: this.TimesheetId,
          createTimeSheetExpenseDto: this.expenses,
        },
        () => {
          this.getData();
          this.feild = {
            workOrderExpenseType: null,
            description: null,
            expenseAmount: null,
          };
          this.expenseTypeId = null;
        }
      );
    },
    getExpenses() {
      dataService.getAll(
        "api/WorkOrders/get_time_sheet_expense_details/" +
          this.$router.currentRoute.params.id,
        (response) => {
          this.expenses = response.data;
          
    
        }
      );
    },
    ShowExpenseDialog(item) {
      this.expenses = item.timeSheetExpenses;
      this.TimesheetId = item.id;

      this.expenseDialoge = true;
    },
    getExpensesTypes() {
      dataService.getAll("api/Enums/work-order-expense-types", (response) => {
        this.expensesTypes = response.data;
      });
    },

    removeFeild(item) {
      dataService.postDelete(
        "/api/TimeSheetExpense/delete-timesheetexpense/" + item.id,
        (response) => {
          if (response.data.succeeded == true) {
            this.getExpenses();
          }
        }
      );
    },
    getLocations(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyLocations(value);
        }, 1000);
      } else {
        self.applyLocations(value);
      }
    },
    applyLocations(value) {
      dataService.getList(
        "api/Branches/search_by_name",
        {
          pageNumber: 1,
          pageSize: 200,
          subString: value,
        },
        (response) => {
          this.locations = response.data.data.items;
          if (value) {
            this.$nextTick(() => this.$refs.locationSearch.focus());
          }
        }
      );
    },
    handleOptionsUpdate({ page, itemsPerPage }) {
      this.requestBody.pageNumber = page;
      this.requestBody.pageSize = itemsPerPage;
      if (itemsPerPage == -1) {
        this.requestBody.pageNumber = 1;
        this.requestBody.pageSize = this.totalLength;
      }
      this.getData();
    },
    getData() {
      this.isLoaded = true;

      dataService.getList(
        "api/Invoices/get-time-sheet-to-approve",
        this.requestBody,
        (response) => {
          this.isLoaded = false;

          this.data = response.data.data.timeSheetList;
          this.jellyBeans = response.data.data.jellyBeans;
          this.totalLength = response.data.data.totalCount;
          this.totalPages = response.data.data.totalPages;
          // if(response.data.data.totalHours<=1)
          // {
          //    response.data.data.totalHours="style='color:red;'";
          // }
          if (this.requestBody.subString) {
            this.$nextTick(() => this.$refs.objet.focus());
          }
        }
      );
    },
  },

  validations: {
    feild: {
      amountTypeId: { required },
      expenseAmount: { required },
      description: { required },
    },
    expenseTypeId: { required },
  },

  //=================
  mounted() {
    localStorage.isPending = true;
    this.getData();
    this.getClients();
    this.getExpenses();
    this.getExpensesTypes();
    //===================
  },
  created() {
    this.loadLocations();
  }, watch: {
    SDFrom () {
        this.dateFormatted = this.formatDate(this.SDFrom)
      },
      SDTo(){
        this.dateFormattedEnd = this.formatDate(this.SDTo)
      }
    },

  computed: {
    computedStartDateFormatted () {
        return this.formatDate(this.SDFrom)
      },
      computedEndDateFormatted () {
        return this.formatDate(this.SDTo)
      },

    accountsearch: {
      get() {
        return this.accountTerm;
      },
      set(searchInput) {
        if (this.accountTerm !== searchInput) {
          this.accountTerm = searchInput;
          this.loadAccounts();
        }
      },
    },
    locationSearch: {
      get() {
        return this.locationTerm;
      },
      set(searchInput) {
        if (this.locationTerm !== searchInput) {
          this.locationTerm = searchInput;
          this.loadLocations();
        }
      },
    },
  },
};
</script>


