var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "handle-test", attrs: { id: "report-timsheet" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12", md: "10" } }, [
            _c("p", { staticClass: "table-title" }, [_vm._v("Invoices List")]),
          ]),
          _c("v-col", { attrs: { md: "1", cols: "12" } }),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pad0", attrs: { cols: "12", md: "4" } },
            [
              _c("v-text-field", {
                ref: "objet",
                attrs: {
                  solo: "",
                  label: "Search ",
                  "append-icon": "mdi-magnify",
                },
                on: {
                  keyup: function ($event) {
                    return _vm.getData()
                  },
                },
                model: {
                  value: _vm.requestBody.subString,
                  callback: function ($$v) {
                    _vm.$set(_vm.requestBody, "subString", $$v)
                  },
                  expression: "requestBody.subString",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pad0", attrs: { cols: "12", md: "8" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c(
                        "v-menu",
                        {
                          ref: "menu",
                          attrs: {
                            "close-on-content-click": false,
                            "return-value": _vm.SDFrom,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "300",
                          },
                          on: {
                            "update:returnValue": function ($event) {
                              _vm.SDFrom = $event
                            },
                            "update:return-value": function ($event) {
                              _vm.SDFrom = $event
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            hint: "MM/DD/YYYY format",
                                            label: "Start Date",
                                            "prepend-inner-icon":
                                              "mdi-calendar",
                                            readonly: "",
                                            outlined: "",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              _vm.SDFrom = _vm.parseDate(
                                                _vm.SDFrom
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.dateFormatted,
                                            callback: function ($$v) {
                                              _vm.dateFormatted = $$v
                                            },
                                            expression: "dateFormatted",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.menuStartDate,
                            callback: function ($$v) {
                              _vm.menuStartDate = $$v
                            },
                            expression: "menuStartDate",
                          },
                        },
                        [
                          _c(
                            "v-date-picker",
                            {
                              attrs: { "no-title": "", scrollable: "" },
                              on: {
                                input: function ($event) {
                                  return _vm.$refs.menu.save(_vm.SDFrom)
                                },
                                change: _vm.getTimesheetByDate,
                              },
                              model: {
                                value: _vm.SDFrom,
                                callback: function ($$v) {
                                  _vm.SDFrom = $$v
                                },
                                expression: "SDFrom",
                              },
                            },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.menuStartDate = false
                                    },
                                  },
                                },
                                [_vm._v(" Cancel ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs.menu.save(_vm.SDFrom)
                                    },
                                  },
                                },
                                [_vm._v(" OK ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c(
                        "v-menu",
                        {
                          ref: "menu2",
                          attrs: {
                            "close-on-content-click": false,
                            "return-value": _vm.SDTo,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "300",
                          },
                          on: {
                            "update:returnValue": function ($event) {
                              _vm.SDTo = $event
                            },
                            "update:return-value": function ($event) {
                              _vm.SDTo = $event
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            hint: "MM/DD/YYYY format",
                                            label: "End Date",
                                            "prepend-inner-icon":
                                              "mdi-calendar",
                                            readonly: "",
                                            outlined: "",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              _vm.SDTo = _vm.parseDate(_vm.SDTo)
                                            },
                                          },
                                          model: {
                                            value: _vm.dateFormattedEnd,
                                            callback: function ($$v) {
                                              _vm.dateFormattedEnd = $$v
                                            },
                                            expression: "dateFormattedEnd",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.menuEndDate,
                            callback: function ($$v) {
                              _vm.menuEndDate = $$v
                            },
                            expression: "menuEndDate",
                          },
                        },
                        [
                          _c(
                            "v-date-picker",
                            {
                              attrs: { "no-title": "", scrollable: "" },
                              on: {
                                input: function ($event) {
                                  return _vm.$refs.menu2.save(_vm.SDTo)
                                },
                                change: _vm.getTimesheetByDate,
                              },
                              model: {
                                value: _vm.SDTo,
                                callback: function ($$v) {
                                  _vm.SDTo = $$v
                                },
                                expression: "SDTo",
                              },
                            },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.menuEndDate = false
                                    },
                                  },
                                },
                                [_vm._v(" Cancel ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs.menu2.save(_vm.SDTo)
                                    },
                                  },
                                },
                                [_vm._v(" OK ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          multiple: "",
                          outlined: "",
                          label: "Select Clients",
                          items: _vm.clients,
                          "item-text": "name",
                          "item-value": "id",
                        },
                        on: { change: _vm.handleClientFilter },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function (data) {
                              return [
                                _c(
                                  "v-chip",
                                  _vm._b(
                                    {
                                      attrs: {
                                        "input-value": data.selected,
                                        close: "",
                                      },
                                      on: {
                                        click: data.select,
                                        "click:close": function ($event) {
                                          return _vm.removeClient(data.item.id)
                                        },
                                      },
                                    },
                                    "v-chip",
                                    data.attrs,
                                    false
                                  ),
                                  [_vm._v(" " + _vm._s(data.item.name) + " ")]
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.clientIds,
                          callback: function ($$v) {
                            _vm.clientIds = $$v
                          },
                          expression: "clientIds",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c(
                "v-row",
                { staticStyle: { "margin-bottom": "2%" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4", md: "4" } },
                    [
                      _c("p", { staticStyle: { "padding-left": "18%" } }, [
                        _c("img", {
                          attrs: { src: require("@/assets/images/All.png") },
                        }),
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "filter-btn all-btn",
                          on: {
                            click: function ($event) {
                              return _vm.GetAll()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.jellyBeans.allCount))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4", md: "4" } },
                    [
                      _c("p", [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/Pending.png"),
                          },
                        }),
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "filter-btn assigned-btn",
                          on: {
                            click: function ($event) {
                              return _vm.GetPending()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.jellyBeans.pendingCount))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4", md: "4" } },
                    [
                      _c("p", { staticStyle: { "padding-left": "15%" } }, [
                        _c("img", {
                          attrs: { src: require("@/assets/images/Paid.png") },
                        }),
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "filter-btn fh-btn",
                          on: {
                            click: function ($event) {
                              return _vm.GetPaid()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.jellyBeans.paidCount))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pad0", attrs: { cols: "12", md: "8" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12", md: "4" } }),
                  _c("v-col", { attrs: { cols: "12", md: "4" } }),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-creation diff-style mt-1",
                          attrs: { color: "#D92020" },
                          on: { click: _vm.reset },
                        },
                        [_vm._v("Reset Filter")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          "hide-default-footer": "",
          loading: _vm.isLoaded,
          "loading-text": "Loading... Please wait",
          headers: _vm.headers,
          items: _vm.data,
          "server-items-length": _vm.totalLength,
        },
        on: {
          "click:row": _vm.rowClick,
          "update:options": _vm.handleSorting,
          change: function ($event) {
            return _vm.resetPageNumber()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item.totalBillRate",
            fn: function ({ item }) {
              return [
                _c("p", [
                  _vm._v(" " + _vm._s("$ " + item.totalBillRate) + " "),
                ]),
              ]
            },
          },
          {
            key: "item.totalExpenses",
            fn: function ({ item }) {
              return [
                _c("p", [
                  _vm._v(" " + _vm._s("$ " + item.totalExpenses) + " "),
                ]),
              ]
            },
          },
          {
            key: "item.totalHours",
            fn: function ({ item }) {
              return [
                _c("p", [_vm._v(" " + _vm._s(item.totalHours + " hr") + " ")]),
              ]
            },
          },
          {
            key: "item.creationDate",
            fn: function ({ item }) {
              return [
                _c("p", [
                  _vm._v(_vm._s(new Date(item.creationDate).toLocaleString())),
                ]),
              ]
            },
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "v-row",
                  { staticClass: "mt-5 fit-width" },
                  [
                    _c(
                      "v-col",
                      { attrs: { md: "2" } },
                      [
                        _c("v-select", {
                          attrs: {
                            items: _vm.sizeOptions,
                            "item-value": "id",
                            "item-text": "name",
                            solo: "",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.resetPageNumber()
                            },
                          },
                          model: {
                            value: _vm.requestBody.pageSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.requestBody, "pageSize", $$v)
                            },
                            expression: "requestBody.pageSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { md: "5" } },
                      [
                        _c("v-pagination", {
                          attrs: {
                            length: _vm.totalPages,
                            "total-visible": "6",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.getData()
                            },
                          },
                          model: {
                            value: _vm.requestBody.pageNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.requestBody, "pageNumber", $$v)
                            },
                            expression: "requestBody.pageNumber",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.requestBody.pageSize,
          callback: function ($$v) {
            _vm.$set(_vm.requestBody, "pageSize", $$v)
          },
          expression: "requestBody.pageSize",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }