<template>
  <div class="handle-test">
    <p class="table-title">New Company</p>
    <div class="innner-container">

     <!-- =========== btn reset me =======-->
        <v-row>
          <v-col md="10"></v-col>
          <v-col md="2">
            <v-btn @click="emptyButtons" class="resetWO-btn">
              <i class="fas fa-redo"></i> <span class="ml-1">Reset</span>
            </v-btn>
          </v-col>
        </v-row>


      <v-row>
        <v-col cols="12" md="6">
          <v-autocomplete
            ref="objet"
            :items="accounts"
            :item-text="'name'"
            :item-value="'id'"
            label="Select Account"
            outlined
            class="mb-3"
            v-model="company.accountId"
            :class="{
              'is-invalid': submitted && $v.company.accountId.$error,
            }"
            :search-input.sync="search"
          >
          </v-autocomplete>
          <p
            v-if="submitted && !$v.company.accountId.required"
            class="invalid-feedback"
          >
            Account is Required
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="company.name"
            :class="{
              'is-invalid': submitted && $v.company.name.$error,
            }"
            outlined
            class="mb-3"
            label="Name"
          ></v-text-field>
          <p
            v-if="submitted && !$v.company.name.required"
            class="invalid-feedback"
          >
            Name is Required
          </p>
        </v-col>
      </v-row>
      <v-divider class="mb-10"></v-divider>
      <div>
        <v-row>
          <v-col cols="12" md="6">
          
            <v-text-field
              outlined
              class="mb-3"
              label="Primary Phone"
              v-model="company.companyPhones[0].phoneNumber"
              @blur="formatPhoneNumber(1)"
              :set="(v = $v.company.companyPhones.$each[0])"
              :class="{
                'is-invalid': submitted && v.phoneNumber.$error,
              }"
            ></v-text-field>
            <p
              v-if="submitted && !v.phoneNumber.required"
              class="invalid-feedback"
            >
              Primary Phone is Required
            </p>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              class="mb-3"
              label="Secondary Phone"
              v-model="secondaryPhone"
              @blur="formatPhoneNumber(2)"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
     
      <div class="btn-container">
        <v-btn class="create-order-btn mr-1" @click="check"
          >Create Company</v-btn
        >
      </div>
    </div>
  </div>
</template>
<script>
import { dataService } from "@/services/data-service";
import { required } from "vuelidate/lib/validators";
import store from "@/store";

export default {
  data() {
    return {
      queryTerm: "",
      submitted: false,
      accounts: [],
      secondaryPhone: "",
      company: {
        name: "",
        accountId: null,
        companyPhones: [
          {
            phoneNumber: "",
            isPrimary: true,
          },
        ],
      },
    };
  },
  validations: {
    company: {
      name: { required },
      accountId: { required },

      companyPhones: {
        $each: {
          phoneNumber: { required },
        },
      },
    },
  },
  methods: {
        // ========= btn reset mee ==========
    emptyButtons(){
            this.secondaryPhone= null,
     this.company= {
        name: null,
        accountId: null,
        companyPhones: [
          {
            phoneNumber: null,
            isPrimary: true,
          },
        ],
      }
    },

    formatPhoneNumber(isprimary) {
      if (isprimary == 1) {
      
        var cleanedprimaryphonenumber = ('' + this.company.companyPhones[0].phoneNumber).replace(/\D/g, '');
        var matchprimaryphonenumber = cleanedprimaryphonenumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (matchprimaryphonenumber) {
          var intlCodeprimaryphonenumber = (matchprimaryphonenumber[1] ? '+1 ' : '');
          this.company.companyPhones[0].phoneNumber = [intlCodeprimaryphonenumber, '(', matchprimaryphonenumber[2], ') ', matchprimaryphonenumber[3], '-', matchprimaryphonenumber[4]].join('');
        }
      }
      else {
      
        var cleanedsecondryphonenumber = ('' + this.secondaryPhone).replace(/\D/g, '');
        var matchsecondryphonenumber = cleanedsecondryphonenumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (matchsecondryphonenumber) {
          var intlCodesecondryphonenumber = (matchsecondryphonenumber[1] ? '+1 ' : '');
          this.secondaryPhone = [intlCodesecondryphonenumber, '(', matchsecondryphonenumber[2], ') ', matchsecondryphonenumber[3], '-', matchsecondryphonenumber[4]].join('');
        }
      }

    },
    //======================================
    getAccounts(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyAccount(value);
        }, 1000);
      } else {
        self.applyAccount(value);
      }
    },
    applyAccount(queryTerm) {
      dataService.getList(
        "api/Accounts/search_by_name",
        {
          pageNumber: 1,
          pageSize: 100,
          subString: queryTerm,
        },
        (response) => {
          this.accounts = response.data.data.items;
          this.$nextTick(() => this.$refs.objet.focus());
        }
      );
    },
    check() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.createCompany();
      }
    },
    createCompany() {
      if (this.secondaryPhone) {
        this.company.companyPhones.push({
          phoneNumber: this.secondaryPhone,
          isPrimary: false,
        });
      }
      dataService.create("api/Companies/create", this.company, () => {
        //console.log(response);
        store.commit("SHOW_MESSAGE", {
          text: "Created Successfully",
          color: "success",
          timeout: 3000,
        });
      });
      this.$router.push("/companies/list");
    },
    async loadEntries() {
      this.clients = await this.getAccounts(this.queryTerm || "");
    },
  },
  computed: {
    search: {
      get() {
        return this.queryTerm;
      },

      set(searchInput) {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput;
          this.loadEntries();
        }
      },
    },
  },
  created() {
    this.loadEntries();
  },
};
</script>