<template>
  <div class="upload-section" id="users">
    <SideMenu v-if="!$vuetify.breakpoint.xsOnly"></SideMenu>
    <div class="user-creation ml-4 mt-4">
      <p class="table-title">{{ title }}</p>

      <v-row>
        <v-col md="12">
          <v-text-field outlined v-model="template.name" :class="{
            'is-invalid': Submitted && $v.template.name.$error,
          }" label="Template Name"></v-text-field>
          <p v-if="Submitted && !$v.template.name.required" class="invalid-feedback mt-2">
            Name is Required
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-text-field outlined v-model="template.description" label="Description"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-autocomplete multiple :items="clients" item-text="name" item-value="id" v-model="template.clientIds"
            outlined label="Select Clients">
          </v-autocomplete>
        </v-col>
      </v-row>


      <v-row class="styling-selection-box">
        <v-chip style="" v-for="item in items" :key="item.id" @click="AddTechName(item.value)"
          class="hoverele-class ma-2" color="indigo darken-3" outlined>
          {{ item.name }}
        </v-chip>
      </v-row>

      <div>
        <v-btn v-if="id>5" style="z-index: 1; bottom: 4%; right: 7%" class="float btn-creation wo-diff-style"
          @click="UpdateEmailTemp">
          Update
        </v-btn>
        <br />
        <editor v-model="template.content" :api-key="apiKey" :init="{
          height: 700,
        
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'table',
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
                   alignleft aligncenter alignright alignjustify | \
                   bullist numlist outdent indent | removeformat | help | \ table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
        }" />
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { dataService } from "@/services/data-service";
import SideMenu from "@/components/SettingsSideMenu.vue";
import Editor from "@tinymce/tinymce-vue";
export default {
  data() {
    return {
      id:this.$route.params.id,
      clients: [],
      Submitted: false,
      template: { id: 0, name: "", description: "", content: "", clientIds: [] },
      title: "",
      items: [],
      apiKey: "wr0wwetnxiw85h19dzyabmnqrvx3r5p025xik9y1wb5lio0a",
      emailTemp: "",
    };
  },
  validations: {
    template: {
      name: { required },
    },
  },
  methods: {
    getClientsFromApi() {
      dataService.getAll(
        "api/Clients/get-all",

        (response) => {
          this.clients = response.data;
        }
      );
    },

    UpdateEmailTemp() {

      this.Submitted = true;

      this.$v.template.$touch();
      if (this.$v.template.$invalid) {
        return;
      }
      dataService.update("api/Mails/update-template", this.template, () => {
        this.Submitted = false;
        this.$router.push("/settings/EmailSettings");

      });
    },

    AddTechName(value) {
      navigator.clipboard.writeText(value);
      navigator.clipboard.readText();
      this.template.content = this.template.content + value
      this.emailTemp = this.emailTemp + value;
    },

    getData() {
      dataService.getAll(
        "api/Mails/GetTemplate/" + this.$route.params.id,

        (response) => {
          this.items = response.data.emailTemplateVariables;
          //console.log(response.data, "=============response=================")
          this.emailTemp = response.data.content;


          this.template.name = response.data.name;
          this.template.type = response.data.type;
          this.template.id = response.data.id;
          this.template.emailTemplateVariables = response.data.emailTemplateVariables;
          this.template.description = response.data.description;
          this.template.content = response.data.content;
          this.template.id = response.data.id;
         

          response.data.clientEmailTemplates.forEach(element => {
            this.template.clientIds.push(element.clientId)

          });

          //console.log(this.template, "=============template=================")

         
        }
      );
    },
  },
  mounted() {
    this.getClientsFromApi();
    this.getData();

    this.title = localStorage.emailtemplate + " Email Template";
  },

  components: {
    SideMenu,
    editor: Editor,
  },
};
</script>