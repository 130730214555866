var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload-section", attrs: { id: "users" } },
    [
      !_vm.$vuetify.breakpoint.xsOnly ? _c("SideMenu") : _vm._e(),
      _c("div", { staticClass: "user-creation ml-4 mt-4" }, [
        _c("p", { staticClass: "page-label" }, [_vm._v("Create User")]),
        _c(
          "div",
          { staticClass: "profile-card" },
          [
            _c(
              "v-row",
              { attrs: { id: "user-email" } },
              [
                _c(
                  "v-col",
                  { attrs: { md: "6", cols: "12", id: "user-email" } },
                  [
                    _c("v-text-field", {
                      class: {
                        "is-invalid": _vm.submitted && _vm.$v.user.email.$error,
                      },
                      attrs: {
                        outlined: "",
                        label: "Enter User Email Address",
                      },
                      model: {
                        value: _vm.user.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "email", $$v)
                        },
                        expression: "user.email",
                      },
                    }),
                    _vm.submitted && !_vm.$v.user.email.required
                      ? _c("p", { staticClass: "invalid-feedback mt-2" }, [
                          _vm._v(" Email is Required "),
                        ])
                      : _vm._e(),
                    _vm.submitted && !_vm.$v.user.email.email
                      ? _c("p", { staticClass: "invalid-feedback mt-2" }, [
                          _vm._v(" Email is Invalid "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "profile-card" },
          [
            _c("v-row", { staticStyle: { margin: "0px" } }, [
              _c(
                "span",
                {
                  staticClass: "Profile-data",
                  staticStyle: {
                    color: "rgb(23, 36, 80)",
                    "font-size": "18px",
                  },
                },
                [_vm._v(" System Settings")]
              ),
            ]),
            _c("br"),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c("v-autocomplete", {
                      attrs: {
                        items: _vm.userTypes,
                        "item-value": "id",
                        "item-text": "name",
                        outlined: "",
                        label: "Select Type",
                      },
                      on: { change: _vm.getGroups },
                      model: {
                        value: _vm.user.userTypes[0],
                        callback: function ($$v) {
                          _vm.$set(_vm.user.userTypes, 0, $$v)
                        },
                        expression: "user.userTypes[0]",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "profile-card" },
          [
            _c("v-row", { staticStyle: { margin: "0px" } }, [
              _c(
                "span",
                {
                  staticClass: "Profile-data",
                  staticStyle: {
                    color: "rgb(23, 36, 80)",
                    "font-size": "18px",
                  },
                },
                [_vm._v(" Client Settings")]
              ),
            ]),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { md: "12" } },
                  [
                    _c("v-autocomplete", {
                      attrs: {
                        outlined: "",
                        items: _vm.clients,
                        "item-value": "id",
                        "item-text": "name",
                        multiple: "",
                        label: "Select Client",
                      },
                      on: { change: _vm.getAccounts },
                      scopedSlots: _vm._u([
                        {
                          key: "selection",
                          fn: function (data) {
                            return [
                              _c(
                                "v-chip",
                                _vm._b(
                                  {
                                    attrs: {
                                      "input-value": data.selected,
                                      color: "#7cab9a54",
                                      close: "",
                                    },
                                    on: {
                                      click: data.select,
                                      "click:close": function ($event) {
                                        return _vm.RemoveClient(data.item.id)
                                      },
                                    },
                                  },
                                  "v-chip",
                                  data.attrs,
                                  false
                                ),
                                [_vm._v(" " + _vm._s(data.item.name) + " ")]
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.selectedClients,
                        callback: function ($$v) {
                          _vm.selectedClients = $$v
                        },
                        expression: "selectedClients",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { md: "12" } },
                  [
                    _c("v-autocomplete", {
                      attrs: {
                        outlined: "",
                        items: _vm.accounts,
                        "item-value": "id",
                        "item-text": "name",
                        label: "Select Account",
                        multiple: "",
                      },
                      on: { change: _vm.getCompanies },
                      scopedSlots: _vm._u([
                        {
                          key: "selection",
                          fn: function (data) {
                            return [
                              _c(
                                "v-chip",
                                _vm._b(
                                  {
                                    attrs: {
                                      color: "#7cab9a54",
                                      "input-value": data.selected,
                                      close: "",
                                    },
                                    on: {
                                      click: data.select,
                                      "click:close": function ($event) {
                                        return _vm.RemoveAccount(data.item.id)
                                      },
                                    },
                                  },
                                  "v-chip",
                                  data.attrs,
                                  false
                                ),
                                [_vm._v(" " + _vm._s(data.item.name) + " ")]
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.selectedAccounts,
                        callback: function ($$v) {
                          _vm.selectedAccounts = $$v
                        },
                        expression: "selectedAccounts",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { md: "12" } },
                  [
                    _c("v-autocomplete", {
                      attrs: {
                        outlined: "",
                        items: _vm.companies,
                        "item-value": "id",
                        "item-text": "name",
                        label: "Select Company",
                        multiple: "",
                      },
                      on: { change: _vm.getLocations },
                      scopedSlots: _vm._u([
                        {
                          key: "selection",
                          fn: function (data) {
                            return [
                              _c(
                                "v-chip",
                                _vm._b(
                                  {
                                    attrs: {
                                      "input-value": data.selected,
                                      close: "",
                                      color: "#7cab9a54",
                                    },
                                    on: {
                                      click: data.select,
                                      "click:close": function ($event) {
                                        return _vm.RemoveCompany(data.item.id)
                                      },
                                    },
                                  },
                                  "v-chip",
                                  data.attrs,
                                  false
                                ),
                                [_vm._v(" " + _vm._s(data.item.name) + " ")]
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.selectedCompanies,
                        callback: function ($$v) {
                          _vm.selectedCompanies = $$v
                        },
                        expression: "selectedCompanies",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { md: "12" } },
                  [
                    _c("v-autocomplete", {
                      attrs: {
                        outlined: "",
                        items: _vm.locations,
                        "item-value": "id",
                        "item-text": "name",
                        label: "Select Location",
                        multiple: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "selection",
                          fn: function (data) {
                            return [
                              _c(
                                "v-chip",
                                _vm._b(
                                  {
                                    attrs: {
                                      color: "#7cab9a54",
                                      "input-value": data.selected,
                                      close: "",
                                    },
                                    on: {
                                      click: data.select,
                                      "click:close": function ($event) {
                                        return _vm.RemoveLocation(data.item.id)
                                      },
                                    },
                                  },
                                  "v-chip",
                                  data.attrs,
                                  false
                                ),
                                [_vm._v(" " + _vm._s(data.item.name) + " ")]
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.selectedLocations,
                        callback: function ($$v) {
                          _vm.selectedLocations = $$v
                        },
                        expression: "selectedLocations",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "btn-container" },
          [
            _c(
              "v-btn",
              { staticClass: "create-order-btn", on: { click: _vm.check } },
              [_vm._v("Create User")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }