<template>
  <div class="upload-section" id="users">
    <SideMenu v-if="!$vuetify.breakpoint.xsOnly"></SideMenu>
    <div class="user-creation ml-4 mt-4">
      <v-row>
        <v-col md="9"></v-col>
        <v-col cols="12" md="3">
          <v-btn to="/settings/smtp" class="btn-creation creation-style mb-4"
            >Create SMTP</v-btn
          >
        </v-col>
      </v-row>
      <div>
        <v-data-table
          :headers="headers"
          :items="data"
          class="elevation-1"
          item-key="id"
        
        >
          <template v-slot:item.actions="{ item }">
           
            <v-icon small class="mr-2 edit-icon" @click="editItem(item)">
              far fa-edit
            </v-icon>
            <v-icon class="delete-icon" small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import { dataService } from "@/services/data-service";
import SideMenu from "@/components/SettingsSideMenu.vue";
import store from "@/store";

export default {
  data() {
    return {
      selectedUsers: [],

      value: null,
      headers: [
        {
          text: "From Email",

          value: "fromEmail",
        },

        // { text: "Actions", value: "actions" },
      ],
      data: [],
      dialogDelete: false,
      itemID: null,
    };
  },
  methods: {
    editItem(item) {
      this.$router.push("/users/edit/" + item.id);
    },
    deleteItemConfirm() {
      dataService.delete(
        "api/SystemUsers/delete",
        {
          userId: this.itemID,
        },
        (response) => {
          if (response.data.succeeded) {
            store.commit("SHOW_MESSAGE", {
              text: "Deleted Successfully",
              color: "success",
              timeout: 3000,
            });
            this.dialogDelete = false;
            this.getData();
          }
        }
      );
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteItem(item) {
      this.itemID = item.id;
      this.dialogDelete = true;
    },

    getData() {
      dataService.getAll(
        "api/Mails/smtp-setting",

        (response) => {
          this.data = response.data;
        }
      );
    },
  },
  mounted() {
    this.getData();
  },
  components: {
    SideMenu,
  },
};
</script>