import Vue from "vue";
import router from "@/router";
import auth from "../../services/auth-service";
// import { email } from "vuelidate/lib/validators";

const sucessLoginSignUp = (commit, data) => {


  const bearerToken = "Bearer " + data.data.token;

  Vue.prototype.$http.defaults.headers.common[
    "Authorization"
  ] = bearerToken;
  commit("SET_AUTH_USER", data);
  commit("SET_TOKEN", data.data.token);
  commit("SET_ROLE", data.data.userTypeId);
  commit("SET_USER_DATA", data);
  localStorage.setItem("userType", data.data.userTypeId);
  router.push("/work-order-list");

  router.go(0);



};
export default {
  signIn({ commit }, userData) {
    return new Promise((resolve, reject) => {
      auth
        .login(userData)
        .then((resp) => {
        
          if (resp.data.succeeded == true) {
            var Requires2FA = resp.data.data.requires2FA
            // Requires2FA = false
            if (Requires2FA == true) {
              localStorage.setItem("verificationEmail", userData.email)
              router.push("/verify")
              return;
            }
            else { 
              sucessLoginSignUp(commit, resp.data)

              router.push("/work-order-list");

            }


          }



          resolve(resp);
        })
        .catch((err) => {
          commit("REMOVE_USER_DATA");

          return reject(err);
        });
    });
  },

  signUp({ commit }, userData) {
    return new Promise((resolve, reject) => {
      auth
        .signUp(userData)

        .then((resp) => {
        
          if (resp.data.succeeded == true) {
            router.push("/");
            // commit("SET_USER_EMAIL", userData.email);

          }


          resolve(resp);



        })
        .catch((resp) => {
          commit("REMOVE_USER_DATA");

          return reject(resp);
        });
    });
  },

  logout({ commit }) {
    commit("REMOVE_USER_DATA");
    router.go(0);
    // router.push("/login");

    delete Vue.prototype.$http.defaults.headers.common["Authorization"];

  },

 
};
