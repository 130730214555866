<template>
  <div class="handle-test">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <!-- 
    <template v-slot:extension>
      <v-tabs v-model="tabs" fixed-tabs>
        <v-tab
            href="#mobile-tabs-5-2"
            class="primary--text"
          >
            <v-icon>mdi-heart</v-icon>
          </v-tab>


      </v-tabs>
    </template>


    <v-tabs-items v-model="tabs">
      <v-tab-item v-for="i in 3" :key="i" :value="'tabs-' + i"> -->

    <!-- <v-card flat>
          <v-card-text v-text="text"></v-card-text>
        </v-card> -->
    <!--         
      </v-tab-item>
    </v-tabs-items> -->

    <v-row>
      <v-col md="10">
        <p class="table-title">Create New Work Order</p>
      </v-col>
      <v-col md="2">
        <v-btn @click="emptyButtons" class="resetWO-btn">
          <i class="fas fa-redo"></i> <span class="ml-1">Reset</span>
        </v-btn>
      </v-col>
    </v-row>
    <div class="outer-container">
      <div class="innner-container">
        <v-row>
          <v-col md="6" cols="12">
            <v-autocomplete tabindex="1" :items="clients" :item-text="'name'" :item-value="'id'" label="Select Client"
              outlined v-model="workOrder.clientId" @change="getAccounts" class="mb-3" :class="{
                'is-invalid': submitted && $v.workOrder.clientId.$error,
              }">
            </v-autocomplete>
            <v-row class="mrgn-ngtv" :class="{
              'invalid-ngtv': submitted && $v.workOrder.clientId.$error,
            }">
              <v-col md="9">
                <p v-if="submitted && !$v.workOrder.clientId.required" class="invalid-feedback">
                  Client is Required
                </p>
              </v-col>
              <v-col md="3"> </v-col>
            </v-row>
          </v-col>
          <v-col md="6" cols="12">
            <v-autocomplete tabindex="2" :items="accounts" :item-text="'name'" :item-value="'id'" label="Select Account"
              outlined v-model="workOrder.accountId" @change="getCompanies" class="mb-3" :class="{
                'is-invalid': submitted && $v.workOrder.accountId.$error,
              }" :search-input.sync="accountsearch" ref="accountsearch">
            </v-autocomplete>
            <v-row class="invalid-ngtv" :class="{
              'mrgn-ngtv': submitted && $v.workOrder.accountId.$error,
            }">
              <v-col md="9">
                <p v-if="submitted && !$v.workOrder.accountId.required" class="invalid-feedback">
                  Account is Required
                </p>
              </v-col>
              <v-col md="3">
                <div class="text-center">
                  <v-dialog v-model="dialog1" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-show="workOrder.clientId != null" color="#162450" class="on-fly-btn" dark v-bind="attrs"
                        v-on="on">
                        Add New
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        Create Account
                      </v-card-title>

                      <v-card-text>
                        <v-text-field v-model="accountName" outlined label="Name" class="mt-3" :class="{
                          'is-invalid': submitted1 && $v.accountName.$error,
                        }"></v-text-field>
                        <p class="invalid-feedback mt-3" v-if="submitted1 && !$v.accountName.required">
                          Account Name is required
                        </p>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="createAccount">
                          Create
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete tabindex="3" :items="companies" :item-text="'name'" :item-value="'id'"
              label="Select Company" outlined class="mb-3 mt-5" v-model="workOrder.companyId" @change="getBranches"
              :class="{
                'is-invalid mt-9': submitted && $v.workOrder.companyId.$error,
              }" :search-input.sync="companysearch" ref="companyysearch">
            </v-autocomplete>

            <v-row class="invalid-ngtv" :class="{
              'mrgn-ngtv': submitted && $v.workOrder.companyId.$error,
            }">
              <v-col md="9">
                <p v-if="submitted && !$v.workOrder.companyId.required" class="invalid-feedback">
                  Company is Required
                </p>
              </v-col>
              <v-col md="3">
                <div class="text-center">
                  <v-dialog v-model="dialog3" width="700">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-show="workOrder.accountId != null" color="#162450" class="on-fly-btn" dark v-bind="attrs"
                        v-on="on">
                        Add New
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        Create Company
                      </v-card-title>

                      <v-card-text>
                        <v-text-field v-model="company.name" outlined label="Name" class="mt-3" :class="{
                          'is-invalid': submitted3 && $v.company.name.$error,
                        }"></v-text-field>
                        <p class="invalid-feedback" v-if="submitted3 && !$v.company.name.required">
                          Company Name is required
                        </p>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field outlined label="Primary Phone" v-model="company.primaryPhone" :class="{
                              'is-invalid':
                                submitted3 && $v.company.primaryPhone.$error,
                            }"></v-text-field>
                            <p class="invalid-feedback" v-if="
                              submitted3 && !$v.company.primaryPhone.required
                            ">
                              Primary Phone is required
                            </p>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field outlined label="Secondary Phone" v-model="company.secondaryPhone">
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="createCompany">
                          Create
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete tabindex="4" :items="branches" :item-text="'name'" :item-value="'id'"
              label="Select Location" outlined class="mb-3 mt-5" v-model="workOrder.branchId" :class="{
                'is-invalid': submitted && $v.workOrder.branchId.$error,
              }" @change="getBranchContacts" :search-input.sync="locationSearch" ref="locationSearch">
            </v-autocomplete>
            <v-row class="invalid-ngtv" :class="{
              'mrgn-ngtv': submitted && $v.workOrder.branchId.$error,
            }">
              <v-col md="9">
                <p v-if="submitted && !$v.workOrder.branchId.required" class="invalid-feedback">
                  Location is Required
                </p>
              </v-col>
              <v-col md="3">
                <div class="text-center">
                  <v-dialog v-model="dialog5" width="900">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-show="workOrder.companyId != null" color="#162450" class="on-fly-btn" dark v-bind="attrs"
                        v-on="on">
                        Add New
                      </v-btn>
                    </template>

                    <v-card class="px-5">
                      <v-card-title class="text-h5 grey lighten-2">
                        Create Location
                      </v-card-title>

                      <v-card-text>
                        <v-text-field v-model="branch.name" outlined label="Name" class="mb-3 mt-3" :class="{
                          'is-invalid': submitted4 && $v.branch.name.$error,
                        }"></v-text-field>
                        <p class="invalid-feedback" v-if="submitted4 && !$v.branch.name.required">
                          Branch Name is required
                        </p>
                      </v-card-text>

                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field outlined class="mb-3" :class="{
                            'is-invalid':
                              submitted4 && $v.branch.address.street.$error,
                          }" v-model="branch.address.street" label="Street"></v-text-field>
                          <p v-if="
                            submitted4 && !$v.branch.address.street.required
                          " class="invalid-feedback">
                            Street is Required
                          </p>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field :class="{
                            'is-invalid':
                              submitted4 &&
                              $v.branch.address.postalCode.$error,
                          }" v-model="branch.address.postalCode" outlined class="mb-3" label="ZIP / Postal Code">
                          </v-text-field>
                          <p v-if="
                            submitted4 &&
                            !$v.branch.address.postalCode.required
                          " class="invalid-feedback">
                            Postal Code is Required
                          </p>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field v-model="branch.address.details" outlined class="mb-3" label="Details">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6"></v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="6">
                          <v-autocomplete :items="countries" :item-text="'name'" :item-value="'id'" label="Country"
                            outlined v-model="branch.address.countryId" class="mb-3" :class="{
                              'is-invalid':
                                submitted4 &&
                                $v.branch.address.countryId.$error,
                            }" @change="getStates(branch.address.countryId)">
                          </v-autocomplete>
                          <p v-if="
                            submitted4 &&
                            !$v.branch.address.countryId.required
                          " class="invalid-feedback">
                            Country is Required
                          </p>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete :items="states" :item-text="'name'" :item-value="'id'" label="State" outlined
                            v-model="branch.address.stateId" class="mb-3" :class="{
                              'is-invalid':
                                submitted4 && $v.branch.address.stateId.$error,
                            }" @change="getCities(branch.address.stateId)" :search-input.sync="StateSearch"
                            hide-selected persistent-hint v-on:keyup.enter="createState">


                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    No results matching "<strong>{{ StateSearch }}</strong>". Press <kbd>enter</kbd> to
                                    create a new
                                    one
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-autocomplete>



                          <p v-if="
                            submitted4 && !$v.branch.address.stateId.required
                          " class="invalid-feedback">
                            State is Required
                          </p>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="6">

                          <v-combobox :item-text="'name'" :item-value="'id'" label="City" outlined class="mb-3" :class="{
                            'is-invalid': submitted4 && $v.branch.address.cityId.$error,
                          }" v-model="branch.address.cityId" :items="cities" :search-input.sync="CitySearch" hide-selected
                            persistent-hint v-on:keyup.enter="createCity" @change="getCities(contact.address.stateId)">
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    No results matching "<strong>{{ CitySearch }}</strong>". Press <kbd>enter</kbd> to
                                    create a new
                                    one
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-combobox>


                          <!-- <v-autocomplete :items="cities" :item-text="'name'" :item-value="'id'" label="City" outlined
                            class="mb-3" :class="{
  'is-invalid':
    submitted4 && $v.branch.address.cityId.$error,
}" v-model="branch.address.cityId">
                          </v-autocomplete> -->
                          <p v-if="
                            submitted4 && !$v.branch.address.cityId.required
                          " class="invalid-feedback">
                            City is Required
                          </p>
                        </v-col>
                        <v-col cols="12" md="6"></v-col>
                      </v-row>

                      <!-- <v-row>
                        <v-col cols="12" md="6">
                        </v-col>
                        <v-col cols="12" md="6"> -->
                      <!-- <v-text-field :class="{
                            'is-invalid':
                              submitted4 &&
                              $v.branch.address.buildingNumber.$error,
                          }" v-model="branch.address.buildingNumber" outlined class="mb-3" label="Building Number">
                          </v-text-field>
                          <p v-if="
                            submitted4 &&
                            !$v.branch.address.buildingNumber.required
                          " class="invalid-feedback">
                            Building Number is Required
                          </p> -->
                      <!-- </v-col>
                      </v-row> -->

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="createBranch">
                          Create
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <br />
        <p class="table-title mt-5">contact Information :</p>

        <v-row>
          <v-col>
            <v-autocomplete tabindex="5" :items="onSiteContacts" :item-text="(item) => item.firstName"
              :item-value="'id'" v-model="workOrder.onSitePrimaryContact" label="On-Site Primary Contact" outlined
              class="mb-3" :class="{
                'is-invalid':
                  submitted && $v.workOrder.onSitePrimaryContact.$error,
              }" ref="onsitecontact">
            </v-autocomplete>
            <v-row class="invalid-ngtv" :class="{
              'mrgn-ngtv':
                submitted && $v.workOrder.onSitePrimaryContact.$error,
            }">
              <v-col md="9">
                <p v-if="
                  submitted && !$v.workOrder.onSitePrimaryContact.required
                " class="invalid-feedback">
                  Primary Contact is Required
                </p>
              </v-col>

              <v-col md="3">
                <div class="text-center">
                  <v-dialog v-model="onSitePrimContactDialog" width="700">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-show="workOrder.branchId != null" color="#162450" class="on-fly-btn" dark v-bind="attrs"
                        v-on="on">
                        Add New
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        Create On-Site Primary contact
                      </v-card-title>

                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-text-field :class="{
                              'is-invalid':
                                primaryContactSubmitted &&
                                $v.contact.firstName.$error,
                            }" v-model="contact.firstName" outlined class="mb-3" label="Full Name"></v-text-field>
                            <p v-if="
                              primaryContactSubmitted &&
                              !$v.contact.firstName.required
                            " class="invalid-feedback">
                              Full Name is Required
                            </p>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field outlined class="mb-3" v-model="contact.contactPhones[0].phoneNumber"
                              :set="(c = $v.contact.contactPhones.$each[0])" :class="{
                                'is-invalid':
                                  primaryContactSubmitted &&
                                  c.phoneNumber.$error,
                              }" label="PrimaryPhone"></v-text-field>
                            <p v-if="
                              primaryContactSubmitted &&
                              !c.phoneNumber.required
                            " class="invalid-feedback">
                              Primary Phone is Required
                            </p>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field outlined class="mb-3" v-model="contactSecondaryPhone" label="SecondaryPhone">
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field :class="{
                              'is-invalid':
                                primaryContactSubmitted &&
                                $v.contact.email.$error,
                            }" v-model="contact.email" outlined class="mb-3" label="Email"></v-text-field>
                            <p v-if="
                              primaryContactSubmitted &&
                              !$v.contact.email.required
                            " class="invalid-feedback">
                              Email is Required
                            </p>
                            <p v-if="
                              primaryContactSubmitted &&
                              !$v.contact.email.email
                            " class="invalid-feedback">
                              Email is not valid
                            </p>
                          </v-col>
                          <v-col cols="12" md="6"></v-col>
                        </v-row>

                        <v-divider class="mb-10"></v-divider>
                        <div>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field outlined class="mb-3" v-model="contact.address.street"
                                label="Street"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field v-model="contact.address.postalCode" outlined class="mb-3"
                                label="ZIP / Postal Code">
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field v-model="contact.address.details" outlined class="mb-3" label="Details">
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6"></v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="6">
                              <v-autocomplete :items="countries" :item-text="'name'" :item-value="'id'" label="Country"
                                outlined v-model="contact.address.countryId" class="mb-3"
                                @change="getStates(contact.address.countryId)">
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-autocomplete :items="states" :item-text="'name'" :item-value="'id'" label="State"
                                outlined v-model="contact.address.stateId" class="mb-3"
                                @change="getCities(contact.address.stateId)">
                              </v-autocomplete>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="6">
                              <v-autocomplete :items="cities" :item-text="'name'" :item-value="'id'" label="City"
                                outlined class="mb-3" v-model="contact.address.cityId">
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6"></v-col>
                          </v-row>
                        </div>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="createContact(1)">
                          Create
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-autocomplete tabindex="6" :items="onSiteContacts" :item-text="(item) => item.firstName"
              :item-value="'id'" v-model="workOrder.onSiteSecondaryContact" label="On-Site Secondary Contact" outlined
              class="mb-3" :class="{
                'is-invalid':
                  submitted && $v.workOrder.onSiteSecondaryContact.$error,
              }">
            </v-autocomplete>

            <v-row class="invalid-ngtv" :class="{
              'mrgn-ngtv':
                submitted && $v.workOrder.onSiteSecondaryContact.$error,
            }">
              <v-col md="9">
                <p v-if="
                  submitted && !$v.workOrder.onSiteSecondaryContact.required
                " class="invalid-feedback">
                  Secondary Contact is Required
                </p>
              </v-col>

              <v-col md="3">
                <div class="text-center">
                  <v-dialog v-model="onSiteSecContactDialog" width="700">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-show="workOrder.branchId != null" color="#162450" class="on-fly-btn" dark v-bind="attrs"
                        v-on="on">
                        Add New
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        Create On-Site Secondary contact
                      </v-card-title>

                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-text-field :class="{
                              'is-invalid':
                                secondaryContactSubmitted &&
                                $v.contact.firstName.$error,
                            }" v-model="contact.firstName" outlined class="mb-3" label="Full Name"></v-text-field>
                            <p v-if="
                              secondaryContactSubmitted &&
                              !$v.contact.firstName.required
                            " class="invalid-feedback">
                              Full Name is Required
                            </p>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field outlined class="mb-3" v-model="contact.contactPhones[0].phoneNumber"
                              :set="(c = $v.contact.contactPhones.$each[0])" :class="{
                                'is-invalid':
                                  secondaryContactSubmitted &&
                                  c.phoneNumber.$error,
                              }" label="PrimaryPhone"></v-text-field>
                            <p v-if="
                              secondaryContactSubmitted &&
                              !c.phoneNumber.required
                            " class="invalid-feedback">
                              Primary Phone is Required
                            </p>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field outlined class="mb-3" v-model="contactSecondaryPhone" label="SecondaryPhone">
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field :class="{
                              'is-invalid':
                                secondaryContactSubmitted &&
                                $v.contact.email.$error,
                            }" v-model="contact.email" outlined class="mb-3" label="Email"></v-text-field>
                            <p v-if="
                              secondaryContactSubmitted &&
                              !$v.contact.email.required
                            " class="invalid-feedback">
                              Email is Required
                            </p>
                            <p v-if="
                              secondaryContactSubmitted &&
                              !$v.contact.email.email
                            " class="invalid-feedback">
                              Email is not valid
                            </p>
                          </v-col>
                          <v-col cols="12" md="6"></v-col>
                        </v-row>

                        <v-divider class="mb-10"></v-divider>
                        <div>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field outlined class="mb-3" v-model="contact.address.street"
                                label="Street"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field v-model="contact.address.postalCode" outlined class="mb-3"
                                label="ZIP / Postal Code">
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field v-model="contact.address.details" outlined class="mb-3" label="Details">
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6"></v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="6">
                              <v-autocomplete :items="countries" :item-text="'name'" :item-value="'id'" label="Country"
                                outlined v-model="contact.address.countryId" class="mb-3"
                                @change="getStates(contact.address.countryId)">
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-autocomplete :items="states" :item-text="'name'" :item-value="'id'" label="State"
                                outlined v-model="contact.address.stateId" class="mb-3"
                                @change="getCities(contact.address.stateId)">
                              </v-autocomplete>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="6">
                              <v-autocomplete :items="cities" :item-text="'name'" :item-value="'id'" label="City"
                                outlined class="mb-3" v-model="contact.address.cityId">
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6"></v-col>
                          </v-row>
                        </div>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="createContact(2)">
                          Create
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-autocomplete tabindex="7" :items="techContacts" :item-text="(item) => item.firstName" :item-value="'id'"
              v-model="workOrder.technicalContact" label="Technical Contact" outlined class="mb-3" :class="{
                'is-invalid': submitted && $v.workOrder.technicalContact.$error,
              }">
            </v-autocomplete>
            <v-row class="invalid-ngtv" :class="{
              'mrgn-ngtv': submitted && $v.workOrder.technicalContact.$error,
            }">
              <v-col md="9">
                <p v-if="submitted && !$v.workOrder.technicalContact.required" class="invalid-feedback">
                  Technical Contact is Required
                </p>
              </v-col>

              <v-col md="3">
                <div class="text-center">
                  <v-dialog v-model="onSiteTechContactDialog" width="700">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-show="workOrder.branchId != null" color="#162450" class="on-fly-btn" dark v-bind="attrs"
                        v-on="on">
                        Add New
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        Create Technical contact
                      </v-card-title>

                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-text-field :class="{
                              'is-invalid':
                                techContactSubmitted &&
                                $v.contact.firstName.$error,
                            }" v-model="contact.firstName" outlined class="mb-3" label="Full Name"></v-text-field>
                            <p v-if="
                              techContactSubmitted &&
                              !$v.contact.firstName.required
                            " class="invalid-feedback">
                              Full Name is Required
                            </p>
                          </v-col>
                          <!-- <v-col cols="12" md="6">
                             <v-text-field outlined v-model="contact.lastName" class="mb-3" :class="{
                               'is-invalid':
                                 techContactSubmitted &&
                                 $v.contact.lastName.$error,
                             }" label="Last Name"></v-text-field>
                             <p v-if="
                               techContactSubmitted &&
                               !$v.contact.lastName.required
                             " class="invalid-feedback">
                               Last Name is Required
                             </p>
                           </v-col> -->
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field outlined class="mb-3" v-model="contact.contactPhones[0].phoneNumber"
                              :set="(c = $v.contact.contactPhones.$each[0])" :class="{
                                'is-invalid':
                                  techContactSubmitted && c.phoneNumber.$error,
                              }" label="PrimaryPhone"></v-text-field>
                            <p v-if="
                              techContactSubmitted && !c.phoneNumber.required
                            " class="invalid-feedback">
                              Primary Phone is Required
                            </p>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field outlined class="mb-3" v-model="contactSecondaryPhone" label="SecondaryPhone">
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field :class="{
                              'is-invalid':
                                techContactSubmitted &&
                                $v.contact.email.$error,
                            }" v-model="contact.email" outlined class="mb-3" label="Email"></v-text-field>
                            <p v-if="
                              techContactSubmitted &&
                              !$v.contact.email.required
                            " class="invalid-feedback">
                              Email is Required
                            </p>
                            <p v-if="
                              techContactSubmitted && !$v.contact.email.email
                            " class="invalid-feedback">
                              Email is not valid
                            </p>
                          </v-col>
                          <v-col cols="12" md="6"></v-col>
                        </v-row>

                        <v-divider class="mb-10"></v-divider>
                        <div>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field outlined class="mb-3" v-model="contact.address.street"
                                label="Street"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field v-model="contact.address.postalCode" outlined class="mb-3"
                                label="ZIP / Postal Code">
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field v-model="contact.address.details" outlined class="mb-3" label="Details">
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6"></v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="6">
                              <v-autocomplete :items="countries" :item-text="'name'" :item-value="'id'" label="Country"
                                outlined v-model="contact.address.countryId" class="mb-3"
                                @change="getStates(contact.address.countryId)">
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-autocomplete :items="states" :item-text="'name'" :item-value="'id'" label="State"
                                outlined v-model="contact.address.stateId" class="mb-3"
                                @change="getCities(contact.address.stateId)">
                              </v-autocomplete>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="6">
                              <v-autocomplete :items="cities" :item-text="'name'" :item-value="'id'" label="City"
                                outlined class="mb-3" v-model="contact.address.cityId">
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6"></v-col>
                          </v-row>
                        </div>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="createContact(3)">
                          Create
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-autocomplete tabindex="6" :items="AccountManagerContacts" :item-text="(item) => item.firstName"
              :item-value="'id'" v-model="workOrder.accontManagerContact" label="Account Manager Contact" outlined
              class="mb-3" :class="{
                'is-invalid':
                  submitted && $v.workOrder.accontManagerContact.$error,
              }">
            </v-autocomplete>

            <v-row class="invalid-ngtv" :class="{
              'mrgn-ngtv':
                submitted && $v.workOrder.accontManagerContact.$error,
            }">
              <v-col md="9">
                <p v-if="
                  submitted && !$v.workOrder.accontManagerContact.required
                " class="invalid-feedback">
                  Accont Manager Contact is Required
                </p>
              </v-col>

              <v-col md="3">
                <div class="text-center">
                  <v-dialog v-model="AccountManagerDialog" width="700">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-show="workOrder.branchId != null" color="#162450" class="on-fly-btn" dark v-bind="attrs"
                        v-on="on">
                        Add New
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        Create Account Manager contact
                      </v-card-title>

                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-text-field :class="{
                              'is-invalid':
                                AccountManagerContactSubmitted &&
                                $v.contact.firstName.$error,
                            }" v-model="contact.firstName" outlined class="mb-3" label="Full Name"></v-text-field>
                            <p v-if="
                              AccountManagerContactSubmitted &&
                              !$v.contact.firstName.required
                            " class="invalid-feedback">
                              Full Name is Required
                            </p>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field outlined class="mb-3" v-model="contact.contactPhones[0].phoneNumber"
                              :set="(c = $v.contact.contactPhones.$each[0])" :class="{
                                'is-invalid':
                                  AccountManagerContactSubmitted &&
                                  c.phoneNumber.$error,
                              }" label="PrimaryPhone"></v-text-field>
                            <p v-if="
                              AccountManagerContactSubmitted &&
                              !c.phoneNumber.required
                            " class="invalid-feedback">
                              Primary Phone is Required
                            </p>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field outlined class="mb-3" v-model="contactSecondaryPhone" label="SecondaryPhone">
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field :class="{
                              'is-invalid':
                                AccountManagerContactSubmitted &&
                                $v.contact.email.$error,
                            }" v-model="contact.email" outlined class="mb-3" label="Email"></v-text-field>
                            <p v-if="
                              AccountManagerContactSubmitted &&
                              !$v.contact.email.required
                            " class="invalid-feedback">
                              Email is Required
                            </p>
                            <p v-if="
                              AccountManagerContactSubmitted &&
                              !$v.contact.email.email
                            " class="invalid-feedback">
                              Email is not valid
                            </p>
                          </v-col>
                          <v-col cols="12" md="6"></v-col>
                        </v-row>

                        <v-divider class="mb-10"></v-divider>
                        <div>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field outlined class="mb-3" v-model="contact.address.street"
                                label="Street"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field v-model="contact.address.postalCode" outlined class="mb-3"
                                label="ZIP / Postal Code">
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field v-model="contact.address.details" outlined class="mb-3" label="Details">
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6"></v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="6">
                              <v-autocomplete :items="countries" :item-text="'name'" :item-value="'id'" label="Country"
                                outlined v-model="contact.address.countryId" class="mb-3"
                                @change="getStates(contact.address.countryId)">
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-autocomplete :items="states" :item-text="'name'" :item-value="'id'" label="State"
                                outlined v-model="contact.address.stateId" class="mb-3"
                                @change="getCities(contact.address.stateId)">
                              </v-autocomplete>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="6">
                              <v-autocomplete :items="cities" :item-text="'name'" :item-value="'id'" label="City"
                                outlined class="mb-3" v-model="contact.address.cityId">
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6"></v-col>
                          </v-row>
                        </div>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="createContact(4)">
                          Create
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="innner-container">
        <p class="table-title">Order Details :</p>

        <v-row>
          <v-col cols="12" md="6">
            <v-text-field tabindex="8" v-model="workOrder.orderId" label="Order ID" outlined class="mb-3" :class="{
              'is-invalid': submitted && $v.workOrder.orderId.$error,
            }">
            </v-text-field>
            <p v-if="submitted && !$v.workOrder.orderId.required" class="invalid-feedback">
              Order ID is Required
            </p>
            <v-text-field tabindex="10" outlined class="mb-3" label="Number Of Technicians"
              v-model="workOrder.workOrderTecLevels[0].numberOfTechnician"
              :set="(v = $v.workOrder.workOrderTecLevels.$each[0])" :class="{
                'is-invalid': submitted && v.numberOfTechnician.$error,
              }"></v-text-field>
            <p v-if="submitted && !v.numberOfTechnician.required" class="invalid-feedback">
              Number of technician is Required
            </p>

            <v-combobox tabindex="12" v-model="workOrder.problemTypeId" :items="problemTypes" :item-text="'name'"
              :item-value="'id'" label="Problem Category" outlined :search-input.sync="problemSearch" hide-selected
              persistent-hint v-on:keyup.enter="createProblemType" :class="{
                'is-invalid': submitted && $v.workOrder.problemTypeId.$error,
              }">
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ problemSearch }}</strong>". Press <kbd>enter</kbd> to create a new
                      one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
          <v-col md="6">
            <v-combobox tabindex="9" v-model="workOrder.orderTypeId" :items="orderTypes" :item-text="'name'"
              :item-value="'id'" label="Select Order Type" :search-input.sync="orderSearch" hide-selected
              persistent-hint class="mb-3" outlined v-on:keyup.enter="createOrderType" :class="{
                'is-invalid': submitted && $v.workOrder.orderTypeId.$error,
              }">
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ orderSearch }}</strong>". Press <kbd>enter</kbd> to create a new
                      one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
            <v-row class="invalid-ngtv" :class="{
              'mrgn-ngtv': submitted && $v.workOrder.orderTypeId.$error,
            }">
              <v-col md="9">
                <p v-if="submitted && !$v.workOrder.orderTypeId.required" class="invalid-feedback">
                  Order type is Required
                </p>
              </v-col>
            </v-row>

            <v-autocomplete tabindex="11" :items="levels" :item-text="'name'" :item-value="'id'"
              label="Technician Level" outlined style="margin-top: 5% !important" class="mb-3 mt-2"
              v-model="workOrder.workOrderTecLevels[0].technicianLevelId"
              :set="(v = $v.workOrder.workOrderTecLevels.$each[0])" :class="{
                'is-invalid': submitted && v.technicianLevelId.$error,
              }">
            </v-autocomplete>
            <p v-if="submitted && !v.technicianLevelId.required" class="invalid-feedback">
              Technician level is Required
            </p>
          </v-col>
        </v-row>
        <v-row v-if="userInfo.userTypeId == 201 || userInfo.userTypeId == 202">
          <v-col cols="12" md="6">
            <v-text-field tabindex="8" v-model="workOrder.fieldNationId" label="field Nation ID" outlined class="mb-3">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <v-textarea tabindex="13" v-model="workOrder.scopeOfWork" outlined label="Scope Of Work" :class="{
              'invalid-text-area':
                submitted && $v.workOrder.scopeOfWork.$error,
            }" class="mb-3"></v-textarea>
            <p v-if="submitted && !$v.workOrder.scopeOfWork.required" class="invalid-feedback">
              Scope of work is Required
            </p>
          </v-col>
        </v-row>
      </div>
      <div class="innner-container">
        <p class="table-title">Service Date :</p>

        <div>
          <v-radio-group tabindex="14" @change="reset" v-model="workOrder.serviceDateTypeId">
            <v-radio v-for="srvc in serviceDates" :key="srvc.id" color="#707070" :label="srvc.name" :value="srvc.id"
              class="ml-7 col-md-sm-2"></v-radio>
          </v-radio-group>
          <p v-if="submitted && !$v.workOrder.serviceDateTypeId.required" class="invalid-feedback">
            Service Date is Required
          </p>
        </div>
        <!-- range -->
        <div v-if="workOrder.serviceDateTypeId && workOrder.serviceDateTypeId == 3">
          <v-row>
            <v-col cols="12" md="4">
              <FunctionalCalendar :configs="calendarConfigs1" v-model="rangeDates"></FunctionalCalendar>
            </v-col>
            <v-col cols="12" md="8">
              <v-row>
                <v-col>
                  <label>From</label>
                </v-col>
                <v-col style="padding: 16px 1px">
                  <v-text-field v-model="RangFromHour" type="number" label="HH" min="1" max="12" solo></v-text-field>
                </v-col>

                <label style="padding-top: 1%; font-size: 30px">:</label>

                <v-col style="padding: 16px 1px">
                  <v-text-field v-model="RangFromMin" type="number" label="MM" min="0" max="59"
                    solo></v-text-field></v-col>
                <v-col style="padding: 16px 1px">
                  <v-select :items="Time" v-model="RangFromAmPmTime" solo></v-select></v-col>

                <v-col>
                  <label>To</label>
                </v-col>
                <v-col style="padding: 16px 1px">
                  <v-text-field v-model="RangToHour" type="number" label="HH" min="1" max="12" solo></v-text-field>
                </v-col>

                <label style="padding-top: 1%; font-size: 30px">:</label>

                <v-col style="padding: 16px 1px">
                  <v-text-field v-model="RangToMin" type="number" label="MM" min="0" max="59"
                    solo></v-text-field></v-col>
                <v-col style="padding: 16px 1px">
                  <v-select :items="Time" v-model="RangToAmPmTime" solo></v-select></v-col>
              </v-row>
            </v-col>
          </v-row>

          <div>
            <v-checkbox v-model="isLongTerm" label="Long Term" color="#4B5568"></v-checkbox>
          </div>
        </div>
        <!-- fixed dates -->
        <div v-if="workOrder.serviceDateTypeId && workOrder.serviceDateTypeId == 2">
          <v-row>
            <v-col cols="12" md="6">
              <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
                transition="scale-transition" offset-y min-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field hint="MM/DD/YYYY format" @blur="date = parseDate(date)" v-model="dateFormatted"
                    label="Start Date" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                    outlined></v-text-field>
                </template>
                <v-date-picker @input="$refs.menu.save(date)" @click="menu = false" v-model="date" no-title scrollable>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <label>From</label>
            </v-col>
            <v-col style="padding: 16px 1px">
              <v-text-field v-model="FromHour" type="number" label="HH" min="1" max="12" solo></v-text-field>
            </v-col>

            <label style="padding-top: 1%; font-size: 30px">:</label>

            <v-col style="padding: 16px 1px">
              <v-text-field v-model="FromMin" type="number" label="MM" min="0" max="59" solo></v-text-field></v-col>
            <v-col style="padding: 16px 1px">
              <v-select :items="Time" v-model="FromAmPmTime" solo></v-select></v-col>

            <v-col>
              <label>To</label>
            </v-col>
            <v-col style="padding: 16px 1px">
              <v-text-field v-model="ToHour" type="number" label="HH" min="1" max="12" solo></v-text-field>
            </v-col>

            <label style="padding-top: 1%; font-size: 30px">:</label>

            <v-col style="padding: 16px 1px">
              <v-text-field v-model="ToMin" type="number" label="MM" min="0" max="59" solo></v-text-field></v-col>
            <v-col style="padding: 16px 1px">
              <v-select :items="Time" v-model="ToAmPmTime" solo></v-select></v-col>
          </v-row>
        </div>
        <!-- repeating -->
        <div v-if="workOrder.serviceDateTypeId && workOrder.serviceDateTypeId == 5">
          <v-row>
            <v-col md="6">
              <v-menu ref="menu" v-model="menuStartDate" :close-on-content-click="false"
                :return-value.sync="workOrder.repetitionDetails.startDate" transition="scale-transition" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="workOrder.repetitionDetails.startDate" label="Start Date"
                    prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                </template>
                <v-date-picker v-model="workOrder.repetitionDetails.startDate" no-title scrollable @input="
                  $refs.menu.save(workOrder.repetitionDetails.startDate)
                ">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuStartDate = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="
                    $refs.menu.save(workOrder.repetitionDetails.startDate)
                  ">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-select :items="frequencies" :item-text="'name'" :item-value="'id'"
                v-model="workOrder.repetitionDetails.frequencyId" outlined label="Select Frequency"></v-select>
              <div>
                <v-date-picker v-model="
                  workOrder.repetitionDetails.repetitionSchedules[0].dates
                " no-title multiple>
                </v-date-picker>
              </div>
            </v-col>
            <v-col md="6">
              <v-menu ref="menuEndDate" v-model="menuEndDate" :close-on-content-click="false"
                :return-value.sync="workOrder.repetitionDetails.endDate" transition="scale-transition" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="workOrder.repetitionDetails.endDate" label="End Date"
                    prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                </template>
                <v-date-picker v-model="workOrder.repetitionDetails.endDate" no-title scrollable @input="
                  $refs.menuEndDate.save(workOrder.repetitionDetails.endDate)
                ">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuEndDate = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="
                    $refs.menuEndDate.save(
                      workOrder.repetitionDetails.endDate
                    )
                  ">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-row class="mt-15">
                <v-col md="6">
                  <label class="time-label">From Time</label>
                  <vue-timepicker manual-input hide-dropdown class="time-picker" v-model="fromTime"></vue-timepicker>
                </v-col>
                <v-col md="6">
                  <label class="time-label">To Time</label>
                  <vue-timepicker class="time-picker" manual-input hide-dropdown v-model="toTime"></vue-timepicker>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        <v-divider class="mb-10"></v-divider>
        <div>
          <p class="table-title">Payment Methods :</p>
          <div>
            <v-radio-group tabindex="15" v-model="workOrder.paymentMethodId" @change="checkPurchase">
              <v-radio v-for="method in paymentMethods" :key="method.id" color="#707070" :label="method.name"
                :value="method.id" class="ml-5"></v-radio>
            </v-radio-group>
            <p v-if="submitted && !$v.workOrder.paymentMethodId.required" class="invalid-feedback">
              Payment Method is Required
            </p>
          </div>

          <v-row>
            <v-col md="6" v-if="workOrder.paymentMethodId == 3">
              <v-col>
                <h4 style="font-family: Roboto, sans-serif">Po Color Codes:</h4>
                <ul style="display: inline-flex">
                  <li style="
                      padding-right: 15%;
                      color: #bbba82;
                      font-weight: bolder;
                    ">
                    Client
                  </li>
                  <li style="
                      padding-right: 15%;
                      color: #aa5e68;
                      font-weight: bolder;
                    ">
                    Account
                  </li>
                  <li style="
                      padding-right: 15%;
                      color: #7cab9a;
                      font-weight: bolder;
                    ">
                    Company
                  </li>
                  <li style="
                      padding-right: 15%;
                      color: #5f6b9b;
                      font-weight: bolder;
                    ">
                    Location
                  </li>
                </ul>
              </v-col>
              <v-col>
                <v-select :items="purchaseOrders" :item-text="
                  (item) =>
                    item.poId + '' + ' With amount ' + '' + item.orderAmount
                " :item-value="'id'" outlined label="Purchase order" v-model="workOrder.purchaseOrderId"
                  class="mb-2">
                  <template #item="{ item }">
                    <span :class="[
                      { 'yellow-custom': item.keyTypeId == 1 },
                      { 'red-custom': item.keyTypeId == 2 },
                      { 'green-custom': item.keyTypeId == 3 },
                      { 'blue-custom': item.keyTypeId == 4 },
                    ]">
                      {{ item.poId + "/ " + "$ " + item.orderAmount }}
                    </span>
                  </template>
                </v-select>
                <v-row>
                  <v-col md="9"> </v-col>
                  <v-col md="3">
                    <v-dialog v-model="poDialog" width="500">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="#162450" class="on-fly-btn mrgn-ngtv" dark v-bind="attrs" v-on="on">
                          Add New
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          Create Purchase Order
                        </v-card-title>

                        <v-card-text>
                          <v-row>
                            <v-col md="12">
                              <v-text-field class="mt-3" outlined label="PO Id" v-model="po.poId" :class="{
                                'is-invalid':
                                  poSubmitted && $v.po.poId.$error,
                              }"></v-text-field>
                              <p v-if="poSubmitted && !$v.po.poId.required" class="invalid-feedback mt-2">
                                Purchase Order Id is Required
                              </p>
                              <v-text-field outlined label="Order Amount" v-model="po.orderAmount" :class="{
                                'is-invalid':
                                  poSubmitted && $v.po.orderAmount.$error,
                              }"></v-text-field>
                              <p v-if="
                                poSubmitted && !$v.po.orderAmount.required
                              " class="invalid-feedback mt-2">
                                Purchase Order Amount is Required
                              </p>
                              <v-autocomplete :items="keyTypes" :item-text="'name'" :item-value="'id'" label="Key Type"
                                outlined v-model="po.keyTypeId" @change="getKeyId" class="mb-3" :class="{
                                  'is-invalid':
                                    poSubmitted && $v.po.keyTypeId.$error,
                                }">
                              </v-autocomplete>
                              <p v-if="poSubmitted && !$v.po.keyTypeId.required" class="invalid-feedback mt-2">
                                Purchase Order Key Type Id is Required
                              </p>
                              <v-autocomplete :items="keyIds" :item-text="'name'" :item-value="'id'" label="Key Id"
                                outlined v-model="po.keyId" class="mb-3" :class="{
                                  'is-invalid':
                                    poSubmitted && $v.po.keyId.$error,
                                }">
                              </v-autocomplete>
                              <p v-if="poSubmitted && !$v.po.keyId.required" class="invalid-feedback mt-2">
                                Purchase Order Key Id is Required
                              </p>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" text @click="createPO">
                            Create
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-col>
            </v-col>

            <v-col md="6" v-else> </v-col>
          </v-row>
          <p class="table-title">Payment Type :</p>

          <v-row>
            <v-col cols="12" md="4">
              <v-radio-group tabindex="16" v-model="workOrder.paymentTypeId" row>
                <v-radio v-for="type in paymentTypes" :key="type.id" color="#707070" :label="type.name" :value="type.id"
                  class="ml-3"></v-radio>
              </v-radio-group>
              <p v-if="submitted && !$v.workOrder.paymentTypeId.required" class="invalid-feedback">
                Payment Type is Required
              </p>
            </v-col>
            <v-col id="paymeny-amount" class="handle-space2" cols="4" md="2">
              <span class="money">$</span>
              <v-select v-show="false" class="mb-3 payment-resize" solo :item-text="'name'" :item-value="'id'"
                :items="amountTypes" label="$" v-model="workOrder.paymentAmountTypeId" :class="{
                  'is-invalid':
                    submitted && $v.workOrder.paymentAmountTypeId.$error,
                }"></v-select>
              <p v-if="submitted && !$v.workOrder.paymentAmountTypeId.required" class="invalid-feedback">
                Payment amount type is Required
              </p>
            </v-col>
            <v-col cols="8" md="4">
              <v-text-field type="number" class="mb-3 ml-5" v-model="workOrder.paymentAmount" outlined label="Payment Amount" :class="{
                'is-invalid': submitted && $v.workOrder.paymentAmount.$error,
              }"></v-text-field>
              <p v-if="submitted && !$v.workOrder.paymentAmount.required" class="invalid-feedback">
                Payment amount is Required
              </p>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row>
            <v-col md="4">
              <v-select :items="expensesTypes" :item-text="'name'" :item-value="'id'"
                label="Add Fees, Discount, Tax, ... " dense v-model="workOrderExpenseTypeId" prepend-icon="mdi-plus"
                @change="addFeild(workOrderExpenseTypeId)"></v-select>
            </v-col>
          </v-row>

          <v-row v-for="(feild, index) in paymentFeilds" :key="index">
            <v-col cols="12" md="6">
              <v-text-field v-model="feild.description" :label="feild.name"></v-text-field>
            </v-col>
            <v-col md="2" cols="4" class="handle-space">
              <v-select class="payment-resize" outlined :item-text="'name'" :item-value="'id'" :items="amountTypes"
                v-model="feild.amountTypeId" label="$">
              </v-select>
            </v-col>

            <v-col md="3" cols="7">
              <v-text-field type="number" v-model="feild.expenseAmount" outlined label="Amount">
              </v-text-field>
            </v-col>
            <v-col md="1" cols="1">
              <v-icon @click="removeRow(index)" class="remove-item-icon">mdi-delete</v-icon>
            </v-col>
          </v-row>

          <!-- ============  select Templete -->
          <br />
          <hr />
          <br />
          <p class="table-title">Template Types:</p>
          <br />
          <v-row>
            <!-- <v-col cols="12" md="6">
              <v-autocomplete :items="accounts" :item-text="'name'" :item-value="'id'" label="Select Template" outlined
                v-model="workOrder.accountId" @change="getCompanies" class="mb-3">
              </v-autocomplete>
            </v-col> -->
            <v-col cols="12" md="6">
              <v-autocomplete tabindex="17" :items="template" :item-text="'name'" :item-value="'id'"
                label="Select Template" outlined v-model="workOrder.creationEmailTemplateId" :class="{
                  'is-invalid':
                    submitted && $v.workOrder.creationEmailTemplateId.$error,
                }" class="mb-3">
              </v-autocomplete>
              <p v-if="
                submitted && !$v.workOrder.creationEmailTemplateId.required
              " class="invalid-feedback">
                Email Template is Required
              </p>
            </v-col>
            <v-col></v-col>
          </v-row>
        </div>
      </div>

      <div class="btn-container">
        <v-btn tabindex="18" class="create-order-btn" @keyup.enter="check()" @click="check">Create Order</v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { dataService } from "@/services/data-service";
import { required, email } from "vuelidate/lib/validators";
import store from "@/store";
import { FunctionalCalendar } from "vue-functional-calendar";
import VueTimepicker from "vue2-timepicker";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      RangToMin: "00",
      RangToHour: "00",
      RangFromHour: "00",
      RangFromMin: "00",
      RangFromAmPmTime: "",
      RangToAmPmTime: "",
      ToMin: "00",
      ToHour: "00",
      FromHour: "00",
      FromMin: "00",
      FromAmPmTime: "",
      ToAmPmTime: "",
      Time: [" AM", " PM"],
      AccountManagerContacts: [],
      tabs: null,
      loading: false,
      orderSearch: null,
      problemSearch: null,
      orderTypeName: null,
      orderSubmitted: false,
      orderDialog: false,
      poDialog: false,
      poSubmitted: false,
      templatedata: {
        typeId: 9,
      },
      po: {
        poId: "",
        orderAmount: null,
        keyId: null,
        keyTypeId: null,
      },
      AccountManagerContactSubmitted: false,
      primaryContactSubmitted: false,
      secondaryContactSubmitted: false,
      techContactSubmitted: false,
      contactSecondaryPhone: "",
      contact: {
        firstName: "",

        email: "",
        address: {
          street: "",
          // buildingNumber: "",
          postalCode: "",
          details: "",
          cityId: null,
        },
        branchId: null,
        contactTypeId: null,
        contactPhones: [
          {
            phoneNumber: "",
            isPrimary: true,
          },
        ],
      },
      AccountManagerDialog: false,
      onSitePrimContactDialog: false,
      onSiteSecContactDialog: false,
      onSiteTechContactDialog: false,
      calendarConfigs: {
        sundayStart: true,
        dateFormat: "mm-dd-yyyy",
        isDatePicker: true,
        isDateRange: false,
      },
      calendarConfigs1: {
        sundayStart: true,
        dateFormat: "mm-dd-yyyy",
        isDatePicker: false,
        isDateRange: true,
        isMultipleDateRange: true,
      },
      serviceDatesArray: [
        {
          serviceDateTypeId: [],
        },
      ],

      date: null,
      dateFormatted: null,

      menu: false,

      fixedDates: {},
      rangeDates: {},

      paymentFeilds: [],
      expensesTypes: [],

      menu2: false,
      date2: null,
      menu3: false,
      timeRangeFrom: null,
      timeRangeTo: null,
      timeFixedFrom: null,
      fixedFrom: null,
      fixedToo: null,
      timeFixedTo: null,
      rangeFrom: false,
      rangeTo: false,

      fixedTo: false,
      time2: null,

      isLongTerm: false,
      workOrderExpenseTypeId: null,
      orderTypes: [],
      problemTypes: [],
      paymentMethods: [
        {
          id: 1,
          name: "Post Paid / Invoice",
        },
        {
          id: 2,
          name: "Pre Paid",
        },
        {
          id: 3,
          name: "Purchase Order",
        },
      ],
      paymentTypes: [],
      serviceDates: [
        {
          id: 1,
          name: "Within 4 Hours",
        },
        {
          id: 2,
          name: "Fixed Date",
        },
        {
          id: 3,
          name: "Range",
        },
        // {
        //   id: 5,
        //   name: "Repeating",
        // },
      ],
      keyIds: [],
      clients: [],
      accounts: [],
      companies: [],
      branches: [],
      amountTypes: [
        {
          id: 1,
          name: "%",
        },
        {
          id: 2,
          name: "$",
        },
        {
          id: 3,
          name: "Hr",
        },
      ],
      levels: [],
      countries: [],
      cities: [],
      states: [],
      onSiteContacts: [],
      techContacts: [],
      purchaseOrders: [],
      problemName: "",
      accountName: "",
      dialog: false,
      dialog1: false,
      dialog3: false,
      dialog5: false,
      submitted: false,
      submitted1: false,
      submitted2: false,
      submitted3: false,
      submitted4: false,
      row: null,
      rate: null,

      company: {
        name: "",
        primaryPhone: "",
        secondaryPhone: "",
      },
      branch: {
        name: "",
        companyId: null,
        address: {
          street: "",
          // buildingNumber: "",
          postalCode: "",
          details: "",
          stateId: null,
          countryId: null,
          cityId: null,
        },
      },
      frequencies: [
        {
          id: 1,
          name: "Weekly",
        },
        {
          id: 2,
          name: "Monthly By Days",
        },
        {
          id: 3,
          name: "Monthly By Date",
        },
      ],
      menuStartDate: false,
      menuEndDate: false,
      fromTime: {
        hh: "00",
        mm: "00",
      },
      toTime: {
        hh: "00",
        mm: "00",
      },
      workOrder: {
        clientId: null,
        accountId: null,
        companyId: null,
        branchId: null,
        scopeOfWork: "",
        orderId: "",
        fieldNationId: "",
        orderTypeId: null,
        problemTypeId: null,
        paymentMethodId: null,
        serviceDateTypeId: null,
        serviceDate: [],
        purchaseOrderId: null,
        onSitePrimaryContact: null,
        onSiteSecondaryContact: null,
        technicalContact: null,
        paymentAmount: 0,
        paymentAmountTypeId: 2,
        workOrderTecLevels: [
          {
            numberOfTechnician: null,
            technicianLevelId: null,
          },
        ],
        repetitionDetails: {
          frequencyId: null,
          startDate: null,
          endDate: null,
          repetitionSchedules: [
            {
              dates: [],
              fromTime: {
                hours: 0,
                minutes: 0,
              },
              toTime: {
                hours: 0,
                minutes: 0,
              },
            },
          ],
        },
      },
      //===== template
      template: [],
      templateobj: {
        templateId: null,
      },
      //==================

      keyTypes: [],
      newTag: "",

      queryTerm: "",
      locationTerm: "",
      companyTerm: "",
      accountTerm: "",
    };
  },

  validations: {
    po: {
      poId: { required },
      orderAmount: { required },
      keyId: { required },
      keyTypeId: { required },
    },
    company: {
      name: { required },
      primaryPhone: { required },
    },
    problemName: { required },
    orderTypeName: { required },
    accountName: { required },

    workOrder: {
      creationEmailTemplateId: { required },
      clientId: { required },
      accountId: { required },
      companyId: { required },
      paymentAmountTypeId: { required },
      paymentAmount: { required },
      branchId: { required },
      scopeOfWork: { required },
      orderId: { required },

      orderTypeId: { required },
      problemTypeId: { required },
      paymentMethodId: { required },
      serviceDateTypeId: { required },
      accontManagerContact: { required },
      onSitePrimaryContact: { required },
      onSiteSecondaryContact: { required },

      technicalContact: { required },

      paymentTypeId: { required },

      workOrderTecLevels: {
        $each: {
          numberOfTechnician: { required },
          technicianLevelId: { required },
        },
      },
    },
    branch: {
      name: { required },

      address: {
        street: { required },
        // buildingNumber: { required },
        postalCode: { required },

        stateId: { required },
        countryId: { required },
        cityId: { required },
      },
    },
    contact: {
      firstName: { required },

      email: { required, email },

      contactPhones: {
        $each: {
          phoneNumber: { required },
        },
      },
    },
  },
  methods: {
    createState() {
     //debugger; // eslint-disable-line no-debugger
      dataService.create(
        "api/States/create",
        {
          name: this.StateSearch,
          countryId: this.branch.address.countryId
        },
        (response) => {

          dataService.getAll(
            "api/States/get_state/" + this.branch.address.countryId,
            (response) => {
              this.states = response.data;
            }
          );




          this.branch.address.stateId = response.data.data;
        }
      );
    },
    createCity() {
      dataService.create(
        "api/Cities/create",
        {
          name: this.CitySearch,
          stateId: this.branch.address.stateId,
        },
        (response) => {
          dataService.getAll(
            "api/Cities/get_city/" + this.branch.address.stateId,
            (res) => {
              this.cities = res.data;
              this.branch.address.cityId = response.data.data;
            }
          );
        }
      );
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    //=======================
    getTemplate() {
      this.templatedata.clientId = this.workOrder.clientId;

      dataService.getList(
        "api/Mails/get-template-bytype",
        this.templatedata,
        (response) => {
          this.template = response.data.data;
          //console.log(this.template, "data");
        }
      );
    },

    //=======================
    getKeyId() {
      if (this.po.keyTypeId == 1) {
        dataService.getAll("api/Clients/get-all", (response) => {
          this.keyIds = response.data;
          this.po.keyId = this.workOrder.clientId;
        });
      } else if (this.po.keyTypeId == 2) {
        dataService.getAll(
          "api/Accounts/client/" + this.workOrder.clientId,
          (response) => {
            this.keyIds = response.data;
            this.po.keyId = this.workOrder.accountId;
          }
        );
      } else if (this.po.keyTypeId == 3) {
        dataService.getAll(
          "api/Companies/account/" + this.workOrder.accountId,

          (response) => {
            this.keyIds = response.data;
            this.po.keyId = this.workOrder.companyId;
          }
        );
      } else if (this.po.keyTypeId == 4) {
        dataService.getAll(
          "api/Branches/company/" + this.workOrder.companyId,

          (response) => {
            this.keyIds = response.data;
            this.po.keyId = this.workOrder.branchId;
          }
        );
      }
    },
    getKeyTypes() {
      dataService.getAll("api/Enums/key-types", (response) => {
        this.keyTypes = response.data;
      });
    },
    resetBranchData() {
      this.workOrder.clientId = null;
      this.workOrder.accountId = null;
      this.workOrder.companyId = null;
      this.workOrder.branchId = null;
    },
    emptyButtons() {
      this.workOrder = {
        creationEmailTemplateId: null,
        clientId: null,
        accountId: null,
        companyId: null,
        branchId: null,
        scopeOfWork: "",
        orderId: "",
        orderTypeId: null,
        problemTypeId: null,
        paymentMethodId: null,
        serviceDateTypeId: null,
        serviceDate: [],
        purchaseOrderId: null,
        onSitePrimaryContact: null,
        onSiteSecondaryContact: null,
        technicalContact: null,
        paymentAmount: "",
        paymentAmountTypeId: 2,
        workOrderTecLevels: [
          {
            numberOfTechnician: null,
            technicianLevelId: null,
          },
        ],
        repetitionDetails: {
          frequencyId: null,
          startDate: null,
          endDate: null,
          repetitionSchedules: [
            {
              dates: [],
              fromTime: {
                hours: 0,
                minutes: 0,
              },
              toTime: {
                hours: 0,
                minutes: 0,
              },
            },
          ],
        },
      };
    },
    createOrderType() {
      // this.orderSubmitted = true;
      // this.$v.orderTypeName.$touch();
      // if (this.$v.orderTypeName.$error) {
      //   return;
      // } else {
      dataService.create(
        "api/orderType/create",
        {
          name: this.orderSearch,
        },
        (response) => {
          this.getOrderTypes();
          this.workOrder.orderTypeId = response.data.data;

          // this.orderDialog = false;
          store.commit("SHOW_MESSAGE", {
            text: "Created Successfully",
            color: "success",
            timeout: 3000,
          });
        }
      );
      // }
    },
    createPO() {
      this.poSubmitted = true;

      this.$v.po.$touch();
      if (this.$v.po.$invalid) {
        return;
      } else {
        dataService.create("api/PurchaseOrders/create", this.po, (response) => {
          this.checkPurchase();
          this.workOrder.purchaseOrderId = response.data.data;
          if (response.data.succeeded == true) {
            store.commit("SHOW_MESSAGE", {
              text: "Created Successfully",
              color: "success",
              timeout: 3000,
            });

            this.workOrder.purchaseOrderId = response.data.data;
            this.poDialog = false;

            this.po = {
              poId: "",
              orderAmount: null,
              keyId: null,
              keyTypeId: null,
            };
          }
        });
      }
    },
    createContact(id) {
      if (id == 1) {
        this.primaryContactSubmitted = true;
        this.contact.contactTypeId = 1;
      }
      if (id == 2) {
        this.secondaryContactSubmitted = true;
        this.contact.contactTypeId = 1;
      }
      if (id == 3) {
        this.techContactSubmitted = true;
        this.contact.contactTypeId = 2;
      }
      if (id == 4) {
        this.AccountManagerContactSubmitted = true;
        this.contact.contactTypeId = 3;
      }
      this.$v.contact.$touch();
      if (this.$v.contact.$invalid) {
        return;
      } else {
        if (this.contactSecondaryPhone) {
          this.contact.contactPhones.push({
            phoneNumber: this.contactSecondaryPhone,
            isPrimary: false,
          });
        }
        this.contact.branchId = this.workOrder.branchId;

        dataService.create("api/Contacts/create", this.contact, (response) => {
          store.commit("SHOW_MESSAGE", {
            text: "Created Successfully",
            color: "success",
            timeout: 3000,
          });
          if (id == 1) {
            this.getOnsiteContacts();
            this.workOrder.onSitePrimaryContact = response.data.data;
            this.onSitePrimContactDialog = false;
          }
          if (id == 2) {
            this.getOnsiteContacts();

            this.workOrder.onSiteSecondaryContact = response.data.data;
            this.onSiteSecContactDialog = false;
          }
          if (id == 3) {
            this.getTechContacts();

            this.workOrder.technicalContact = response.data.data;
            this.onSiteTechContactDialog = false;
          }
          if (id == 4) {
            this.getAccountManagerContacts();
            this.workOrder.accontManagerContact = response.data.data;
            this.AccountManagerDialog = false;
          }
          this.contactSecondaryPhone = "";
          this.contact = {
            firstName: "",

            email: "",
            address: {
              street: "",
              // buildingNumber: "",
              postalCode: "",
              details: "",
              cityId: null,
            },
            branchId: null,
            contactTypeId: null,
            contactPhones: [
              {
                phoneNumber: "",
                isPrimary: true,
              },
            ],
          };
        });
      }
    },
    reset() {
      this.rangeDates = {};
      this.date = null;
    },

    removeRow(id) {
      this.paymentFeilds.splice(id, 1);
    },
    addFeild(workOrderExpenseTypeId) {
      var name = this.expensesTypes.find(
        (x) => x.id === workOrderExpenseTypeId
      ).name;

      this.paymentFeilds.push({
        description: "",
        amountTypeId: "",
        expenseAmount: "",
        name: name,
        workOrderExpenseTypeId: workOrderExpenseTypeId,
      });
    },
    getExpensesTypes() {
      dataService.getAll("api/Enums/work-order-expense-types", (response) => {
        this.expensesTypes = response.data;
      });
    },
    checkPurchase() {
      if (this.workOrder.paymentMethodId == 3) {
        if (this.workOrder.branchId != null) {
          dataService.create(
            "api/PurchaseOrders/get-by-key-type",
            {
              clientId: this.workOrder.clientId,
              accountId: this.workOrder.accountId,
              branchId: this.workOrder.branchId,
              companyId: this.workOrder.companyId,
            },
            (response) => {
              this.purchaseOrders = response.data.data;
            }
          );
        }
      }
    },
    getPurchase() {
      dataService.getAll("api/PurchaseOrders", (response) => {
        this.purchaseOrders = response.data;
      });
    },
    getOnsiteContacts() {
      dataService.getAll(
        "api/Contacts/" + this.workOrder.branchId + "/1",
        (response) => {
          this.onSiteContacts = response.data;
        }
      );
    },
    getTechContacts() {
      dataService.getAll(
        "api/Contacts/" + this.workOrder.branchId + "/2",
        (response) => {
          this.techContacts = response.data;
        }
      );
    },
    getAccountManagerContacts() {
      dataService.getAll(
        "api/Contacts/" + this.workOrder.branchId + "/3",
        (response) => {
          this.AccountManagerContacts = response.data;
        }
      );
    },
    getBranchContacts() {
      this.getTechContacts();
      this.getOnsiteContacts();
      this.getAccountManagerContacts();
      if (!this.workOrder.companyId) {
        dataService.getList(
          "api/Clients/auto_fill",
          {
            autofillDTO: {
              keyType: 4,
              keyValues: [this.workOrder.branchId],
            },
          },
          (response) => {
            var keys = response.data.data;

            for (var k = 0; k < keys.length; k++) {
              if (keys[k].keyType == 1) {
                this.workOrder.clientId = keys[k].keyValues[0];
              }
              if (keys[k].keyType == 2) {
                this.workOrder.accountId = keys[k].keyValues[0];
              }
              if (keys[k].keyType == 3) {
                this.workOrder.companyId = keys[k].keyValues[0];
              }
            }
            this.checkPurchase();
            this.getTemplate();
          }
        );
      }
    },
    createBranch() {
     //debugger; // eslint-disable-line no-debugger
      //console.log(this.branch)
      this.submitted4 = true;
      this.$v.branch.$touch();
      if (this.$v.branch.$invalid) {
        return;
      } else {
        this.branch.companyId = this.workOrder.companyId;
        this.branch.address.cityId = this.branch.address.cityId.id;
        dataService.create("api/Branches/create", {
          name: this.branch.name,
          companyId: this.branch.companyId,
          address: this.branch.address
        }, (response) => {
          this.dialog5 = false;

          this.getBranches();
          this.workOrder.branchId = response.data.data;
          store.commit("SHOW_MESSAGE", {
            text: "Created Successfully",
            color: "success",
            timeout: 3000,
          });
        });
      }
    },
    createProblemType() {
      // this.submitted2 = true;
      // this.$v.problemName.$touch();
      // if (this.$v.problemName.$error) {
      //   return;
      // } else {
      dataService.create(
        "api/ProblemTypes/create",
        {
          name: this.problemSearch,
        },
        (response) => {
          this.getProblemTypes();
          this.workOrder.problemTypeId = response.data.data;

          // this.dialog = false;
          store.commit("SHOW_MESSAGE", {
            text: "Created Successfully",
            color: "success",
            timeout: 3000,
          });
        }
      );
      // }
    },
    createAccount() {
      this.submitted1 = true;
      this.$v.accountName.$touch();
      if (this.$v.accountName.$error) {
        return;
      } else {
        dataService.create(
          "api/Accounts/create",
          {
            clientId: this.workOrder.clientId,
            name: this.accountName,
          },
          (response) => {
            this.getAccounts();
            this.workOrder.accountId = response.data.data;

            this.dialog1 = false;
            store.commit("SHOW_MESSAGE", {
              text: "Created Successfully",
              color: "success",
              timeout: 3000,
            });
          }
        );
      }
    },
    createCompany() {
      this.submitted3 = true;
      if (this.$v.company.$invalid) {
        return;
      } else {
        var companyPhones = [
          {
            phoneNumber: this.company.primaryPhone,
            isPrimary: true,
          },
        ];
        if (this.company.secondaryPhone) {
          companyPhones.push({
            phoneNumber: this.company.secondaryPhone,
            isPrimary: false,
          });
        }

        dataService.create(
          "api/Companies/create",
          {
            name: this.company.name,
            accountId: this.workOrder.accountId,
            companyPhones: companyPhones,
          },
          (response) => {
            this.getCompanies();
            this.workOrder.companyId = response.data.data;

            this.dialog3 = false;
            store.commit("SHOW_MESSAGE", {
              text: "Created Successfully",
              color: "success",
              timeout: 3000,
            });
          }
        );
      }
    },
    check() {
      this.submitted = true;

      this.$v.workOrder.$touch();

      if (this.$v.workOrder.$invalid) {
        return;
      } else {
        this.loading = true;
        this.createWorkOrder();
      }
    },
    createWorkOrder() {
      //range

      if (this.workOrder.serviceDateTypeId == 3) {
        for (var r = 0; r < this.rangeDates.multipleDateRange.length; r++) {
          this.workOrder.serviceDate.push({
            serviceDateFromDate: new Date(
              this.rangeDates.multipleDateRange[r].start.split("T")[0] +
              " " +
              "10:00:00"
            ),
            serviceDateToDate: new Date(
              this.rangeDates.multipleDateRange[r].end.split("T")[0] +
              " " +
              "11:00:00"
            ),
            fromTime: this.computedRangFromTime,
            toTime: this.computedRangToTime,
          });
        }

        if (this.isLongTerm == true) {
          this.workOrder.serviceDateTypeId = 4;
        }
        this.repetitionDetails = null;
      }

      if (this.workOrder.serviceDateTypeId == 2) {
       //debugger; // eslint-disable-line no-debugger

        this.workOrder.serviceDate.push({
          serviceDateFromDate: this.date,
          serviceDateToDate: this.date,
          fromTime: this.computedFromTime,
          toTime: this.computedToTime,
        });

        this.repetitionDetails = null;
      }

      if (this.workOrder.serviceDateTypeId != 5) {
        this.workOrder.repetitionDetails = null;
      }
      if (this.workOrder.serviceDateTypeId == 5) {
        this.fromTime.HH =
          this.fromTime.HH && this.fromTime.HH != "" ? this.fromTime.HH : "00";
        this.fromTime.mm =
          this.fromTime.mm && this.fromTime.mm != "" ? this.fromTime.mm : "00";

        this.toTime.HH =
          this.toTime.HH && this.toTime.HH != "" ? this.toTime.HH : "00";
        this.fromTime.mm =
          this.toTime.mm && this.toTime.mm != "" ? this.toTime.mm : "00";

        this.workOrder.repetitionDetails.repetitionSchedules[0].fromTime.hours =
          this.fromTime.HH;
        this.workOrder.repetitionDetails.repetitionSchedules[0].fromTime.minutes =
          this.fromTime.mm;

        this.workOrder.repetitionDetails.repetitionSchedules[0].toTime.hours =
          this.toTime.HH;
        this.workOrder.repetitionDetails.repetitionSchedules[0].toTime.minutes =
          this.toTime.mm;
        let from = this.fromTime.HH + ":" + this.fromTime.mm + ":00";
        let testStartTime = new Date(
          this.workOrder.repetitionDetails.startDate.split("T")[0] + " " + from
        );
        let to = this.toTime.HH + ":" + this.toTime.mm + ":00";
        let testEndTime = new Date(
          this.workOrder.repetitionDetails.startDate.split("T")[0] + " " + to
        );
        this.workOrder.repetitionDetails.repetitionSchedules[0].fromDateTime =
          testStartTime;
        this.workOrder.repetitionDetails.repetitionSchedules[0].toDateTime =
          testEndTime;
        this.workOrder.repetitionDetails.startDate = new Date(
          this.workOrder.repetitionDetails.startDate.split("T")[0]
        );

        this.workOrder.repetitionDetails.endDate = new Date(
          this.workOrder.repetitionDetails.endDate.split("T")[0]
        );

        for (
          var d = 0;
          d <
          this.workOrder.repetitionDetails.repetitionSchedules[0].dates.length;
          d++
        ) {
          this.workOrder.repetitionDetails.repetitionSchedules[0].dates[d] =
            new Date(
              this.workOrder.repetitionDetails.repetitionSchedules[0].dates[
                d
              ].split("T")[0]
            );
        }
      }

      let expenses = [];
      this.paymentFeilds.forEach((ex) => {
        expenses.push(ex);
      });

      expenses.push({
        description: "",
        amountTypeId: this.workOrder.paymentAmountTypeId,
        expenseAmount: this.workOrder.paymentAmount,
        name: "",
        workOrderExpenseTypeId: 6,
      });
      this.workOrder["workOrderExpense"] = expenses;
      var typeId = "";
      typeId = this.workOrder.orderTypeId.id;
      this.workOrder.orderTypeId = typeId;

      var problemId = "";
      problemId = this.workOrder.problemTypeId.id;
      this.workOrder.problemTypeId = problemId;

      dataService.create("api/WorkOrders/create", this.workOrder, () => {
        this.loading = true;
        store.commit("SHOW_MESSAGE", {
          text: "Created Successfully",
          color: "success",
          timeout: 3000,
        });
        this.$router.push("/work-order-list");
      });
    },
    getOrderTypes() {
      dataService.getAll("api/Enums/order-types", (response) => {
        this.orderTypes = response.data;
      });
    },
    getProblemTypes() {
      dataService.getAll("api/ProblemTypes", (response) => {
        this.problemTypes = response.data;
      });
    },

    getPaymentTypes() {
      dataService.getAll("api/Enums/payment-types", (response) => {
        this.paymentTypes = response.data;
      });
    },

    getClients() {
      dataService.getAll(
        "api/Clients/get-all",

        (response) => {
          this.clients = response.data;
        }
      );
    },

    getAccounts() {
      this.getTemplate();
      dataService.getAll(
        "api/Accounts/client/" + this.workOrder.clientId,
        (response) => {
          this.accounts = response.data;
          //this.workOrder.clientId=null
          this.workOrder.accountId = null;
          this.workOrder.companyId = null;
          this.workOrder.branchId = null;
        }
      );
    },
    getCompanies() {
      dataService.getAll(
        "api/Companies/account/" + this.workOrder.accountId,
        (response) => {
          this.companies = response.data;
          //this.workOrder.accountId=null
          this.workOrder.companyId = null;
          this.workOrder.branchId = null;
          if (!this.workOrder.clientId) {
            dataService.getList(
              "api/Clients/auto_fill",
              {
                autofillDTO: {
                  keyType: 2,
                  keyValues: [this.workOrder.accountId],
                },
              },
              (response) => {
                var keys = response.data.data;
                for (var k = 0; k < keys.length; k++) {
                  if (keys[k].keyType == 1) {
                    this.workOrder.clientId = keys[k].keyValues[0];
                  }
                }
              }
            );
          }
        }
      );
    },
    getBranches() {
      dataService.getAll(
        "api/Branches/company/" + this.workOrder.companyId,
        (response) => {
          this.branches = response.data;

          this.workOrder.branchId = null;
          if (!this.workOrder.accountId) {
            dataService.getList(
              "api/Clients/auto_fill",
              {
                autofillDTO: {
                  keyType: 3,
                  keyValues: [this.workOrder.companyId],
                },
              },
              (response) => {
                var keys = response.data.data;
                for (var k = 0; k < keys.length; k++) {
                  if (keys[k].keyType == 1) {
                    this.workOrder.clientId = keys[k].keyValues[0];
                  }
                  if (keys[k].keyType == 2) {
                    this.workOrder.accountId = keys[k].keyValues[0];
                  }
                }
              }
            );
          }
        }
      );
    },

    getLevels() {
      dataService.getAll("api/Enums/levels", (response) => {
        this.levels = response.data;
      });
    },
    getCountries() {
      dataService.getAll("/api/Countries", (response) => {
        this.countries = response.data;
      });
    },

    getCities(id) {
      dataService.getAll("api/Cities/get_city/" + id, (response) => {
        this.cities = response.data;
      });
    },
    getStates(id) {
      dataService.getAll("api/States/get_state/" + id, (response) => {
        this.states = response.data;
      });
    },
    async loadLocations() {
      this.branches = await this.getAllLocations(this.locationTerm || "");
    },

    getAllLocations(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyLocations(value);
        }, 0);
        this.$refs.locationSearch.focus();
      } else {
        self.applyLocations(value);
      }
    },
    applyLocations(value) {
      dataService.getList(
        "api/Branches/search_by_name",
        {
          pageNumber: 1,
          pageSize: 2000,
          subString: value,
        },
        (response) => {
          this.branches = response.data.data.items;
        }
      );
    },

    async loadCompanies() {
      this.companies = await this.getAllCompanies(this.companyTerm || "");
    },
    getAllCompanies(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyCompanies(value);
        }, 0);
        self.$refs.companyysearch.focus();
      } else {
        self.applyCompanies(value);
      }
    },
    applyCompanies(value) {
      dataService.getList(
        "api/Companies/search_by_name",
        {
          pageNumber: 1,
          pageSize: 1000,
          subString: value,
        },
        (response) => {
          this.companies = response.data.data.items;
        }
      );
    },
    async loadAccounts() {
      this.accounts = await this.getAllAccounts(this.accountTerm || "");
    },
    getAllAccounts(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyAccounts(value);
        }, 0.05);

        self.$refs.accountsearch.focus();
        this.timeout = setTimeout(function () {
          self.setFouces();
        }, 0.05);
      } else {
        self.applyAccounts(value);
      }
    },
    setFouces() {
      var self = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        self.$refs.onsitecontact.focus();
      }, 0);
    },

    applyAccounts(value) {
      dataService.getList(
        "api/Accounts/search_by_name",
        {
          pageNumber: 1,
          pageSize: 500,
          subString: value,
        },
        (response) => {
          this.accounts = response.data.data.items;
        }
      );
    },
  },
  components: {
    VueTimepicker,
    FunctionalCalendar,
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  computed: {
    computedRangFromTime() {
      let Time =
        this.RangFromHour + ":" + this.RangFromMin + "" + this.RangFromAmPmTime;

      return Time;
    },
    computedRangToTime() {
      let Time =
        this.RangToHour + ":" + this.RangToMin + "" + this.RangToAmPmTime;

      return Time;
    },
    computedFromTime() {
      let Time = this.FromHour + ":" + this.FromMin + "" + this.FromAmPmTime;

      return Time;
    },
    computedToTime() {
      let Time = this.ToHour + ":" + this.ToMin + "" + this.ToAmPmTime;

      return Time;
    },
    computedStartDateFormatted() {
      return this.formatDate(this.date);
    },
    ...mapGetters({
      userInfo: "AuthModule/userData",
    }),
    locationSearch: {
      get() {
        return this.locationTerm;
      },

      set(searchInput) {
        if (this.locationTerm !== searchInput) {
          this.locationTerm = searchInput;
          this.loadLocations();
        }
      },
    },
    companysearch: {
      get() {
        return this.companyTerm;
      },

      set(searchInput) {
        if (this.companyTerm !== searchInput) {
          this.companyTerm = searchInput;
          this.loadCompanies();
        }
      },
    },
    accountsearch: {
      get() {
        return this.accountTerm;
      },

      set(searchInput) {
        if (this.accountTerm !== searchInput) {
          this.accountTerm = searchInput;
          this.loadAccounts();
        }
      },
    },
  },

  mounted() {
    //==========

    this.getTemplate();

    this.getExpensesTypes();
    this.getOrderTypes();
    this.getProblemTypes();
    this.getPaymentTypes();
    this.getClients();
    this.getLevels();
    this.getCountries();

    this.getKeyTypes();
  },
  created() {
    this.loadAccounts();
    this.loadLocations();
    this.loadCompanies();
  },
};
</script>