<template>
  <v-app>
    <v-progress-linear
      v-if="modalShow"
      indeterminate
      color="primary"
      value="15"
    ></v-progress-linear>

    <v-main v-if="isLoggining && this.$route.meta.notRequiresAuth != true">
      <v-card class="overflow-hidden">
        <v-app-bar
          absolute
          elevate-on-scroll
          scroll-target="#scrolling-techniques-7"
          color="#22336B"
          v-if="!$vuetify.breakpoint.xsOnly"
        >
          <v-toolbar-title>
            <img
              class="header-logo"
              src="@/assets/images/AXA LOGO TRANPARENT.png"
            />
          </v-toolbar-title>

          <!-- workOrders -->
          <template>
            <v-btn class="ml-8" text dark :to="workOrder.to">
              <span class="header-item"> workOrders </span>
            </v-btn>
          </template>

          <!-- technicians -->
          <template v-if="userInfo.userTypeId != 205 && userInfo.userTypeId != 203 && userInfo.userTypeId != 204">
            <v-btn class="ml-1" text dark :to="tec.to">
              <span class="header-item"> Technicians </span>
            </v-btn>
          </template>

          <!-- Contacts -->
          <template v-if="userInfo.userTypeId != 205 || userInfo.userTypeId == 203 || userInfo.userTypeId == 204">
            <v-btn class="ml-1" text dark :to="Contacts.to">
              <span class="header-item"> Contacts </span>
            </v-btn>
          </template>

          <!-- Clients -->
          <v-menu v-if="userInfo.userTypeId != 205 && userInfo.userTypeId != 203 && userInfo.userTypeId != 204" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-1" text dark v-bind="attrs" v-on="on">
                <span class="header-item">Clients</span>
                <i class="fas fa-caret-down ml-1"></i>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in companyList" :key="index">
                <!-- <v-list-item-title>{{ item.title }}</v-list-item-title> -->
                <v-btn :to="item.to" text class="dropdown-item menu-links">
                  <p class="router-link-deco">
                    <span class="ml-2">{{ item.title }}</span>
                  </p>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>


              <!-- Clients Admin $$  Client Rep  -->
              <v-menu v-if="userInfo.userTypeId != 201 && userInfo.userTypeId != 202 && userInfo.userTypeId != 205" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-1" text dark v-bind="attrs" v-on="on">
                <span class="header-item">Accounts</span>
                <i class="fas fa-caret-down ml-1"></i>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in ClientList" :key="index">
                <!-- <v-list-item-title>{{ item.title }}</v-list-item-title> -->
                <v-btn :to="item.to" text class="dropdown-item menu-links">
                  <p class="router-link-deco">
                    <span class="ml-2">{{ item.title }}</span>
                  </p>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>


          <!-- TimeSheets/Invoices -->
          <v-menu v-if="userInfo.userTypeId != 205 && userInfo.userTypeId != 203 && userInfo.userTypeId != 204" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text dark v-bind="attrs" v-on="on">
                <span class="header-item"> TimeSheets/Invoices</span
                ><i class="fas fa-caret-down ml-1"></i>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in reportList" :key="index">
                <v-btn :to="item.to" text class="dropdown-item menu-links">
                  <p class="router-link-deco">
                    <span class="ml-2">{{ item.title }}</span>
                  </p>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- Reports Reports -->
          <v-menu v-if="userInfo.userTypeId != 205 && userInfo.userTypeId != 203 && userInfo.userTypeId != 204" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-1" text dark v-bind="attrs" v-on="on">
                <span class="header-item">Reports</span>
                <i class="fas fa-caret-down ml-3"></i>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in Reports" :key="index">
                <v-btn :to="item.to" text class="dropdown-item menu-links ml-1">
                  <p class="router-link-deco">
                    <span class="ml-2">{{ item.title }}</span>
                  </p>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>

          <!-- <v-btn icon>
            <v-badge color="#D92020" overlap dot>
              <v-icon class="notification-icon">mdi-bell</v-icon>
            </v-badge>
          </v-btn> -->

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text dark v-bind="attrs" v-on="on">
                <span class="header-item"> {{ userInfo.userName }} </span>
                <i class="fas fa-caret-down ml-1"></i>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in userList" :key="index">
                <v-btn :to="item.to" text class="dropdown-item menu-links pb-4">
                  <router-link class="router-link-deco" :to="item.to">
                    <span class="ml-2">{{ item.title }}</span>
                  </router-link>
                </v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn @click="logout" text class="dropdown-item menu-links">
                  <p class="router-link-deco">
                    <span class="ml-2">Logout</span>
                  </p>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>

        <v-app-bar
          absolute
          elevate-on-scroll
          scroll-target="#scrolling-techniques-7"
          color="#172450"
          v-else
        >
          <v-app-bar-nav-icon
            @click="rspnsvMenu = !rspnsvMenu"
            color="white"
          ></v-app-bar-nav-icon>
          <div v-if="rspnsvMenu" class="responsive-menu">
            <v-list>
              <!-- workOrders -->
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-home</v-icon>
                </v-list-item-icon>

                <v-list-item-title>
                  <v-btn text class="dropdown-item menu-links">
                    <router-link class="router-link-deco" :to="workOrder.to">
                      <span class="ml-2">WorkOrders</span>
                    </router-link>
                  </v-btn>
                </v-list-item-title>
              </v-list-item>

              <!-- technicians -->
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-home</v-icon>
                </v-list-item-icon>

                <v-list-item-title>
                  <v-btn text class="dropdown-item menu-links">
                    <router-link class="router-link-deco" :to="tec.to">
                      <span class="ml-2">Technicians</span>
                    </router-link>
                  </v-btn>
                </v-list-item-title>
              </v-list-item>

              <!-- Contacts -->
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-home</v-icon>
                </v-list-item-icon>

                <v-list-item-title>
                  <v-btn text class="dropdown-item menu-links">
                    <router-link class="router-link-deco" :to="Contacts.to">
                      <span class="ml-2">Contacts</span>
                    </router-link>
                  </v-btn>
                </v-list-item-title>
              </v-list-item>

              <!-- Clients -->
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-home</v-icon>
                </v-list-item-icon>

                <v-list-item-title>
                  <v-btn text class="dropdown-item menu-links">
                    <router-link class="router-link-deco" to="/clients/list">
                      <span class="ml-2">Clients</span>
                    </router-link>
                  </v-btn>
                </v-list-item-title>
              </v-list-item>

              <!-- Accounts -->
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-home</v-icon>
                </v-list-item-icon>

                <v-list-item-title >
                  <v-btn text class="dropdown-item menu-links">
                    <router-link class="router-link-deco" to="/accounts/list">
                      <span class="ml-2">Accounts</span>
                    </router-link>
                  </v-btn>
                </v-list-item-title>
              </v-list-item>

              <!-- Companies -->
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-home</v-icon>
                </v-list-item-icon>

                <v-list-item-title>
                  <v-btn text class="dropdown-item menu-links">
                    <router-link class="router-link-deco" to="/companies/list">
                      <span class="ml-2">Companies</span>
                    </router-link>
                  </v-btn>
                </v-list-item-title>
              </v-list-item>

              <!-- Locations -->
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-home</v-icon>
                </v-list-item-icon>

                <v-list-item-title>
                  <v-btn text class="dropdown-item menu-links">
                    <router-link class="router-link-deco" to="/locations/list">
                      <span class="ml-2">Locations</span>
                    </router-link>
                  </v-btn>
                </v-list-item-title>
              </v-list-item>

              <v-list-group :value="true" prepend-icon="mdi-account-circle">
                <template v-slot:activator>
                  <v-list-item-title>Settings</v-list-item-title>
                </template>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-home</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Notification</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-home</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Permissions</v-list-item-title>
                </v-list-item>

                <v-list-group :value="true" no-action sub-group>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Users</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item>
                    <v-list-item-title>Create User</v-list-item-title>
                  </v-list-item>
                </v-list-group>
              </v-list-group>
            </v-list>
          </div>
        </v-app-bar>
        <v-sheet
          id="scrolling-techniques-7"
          class="overflow-y-auto"
          max-height="100vh"
        >
          <div class="main-view" style="height: 100vh; margin-top: 4%">
            <router-view />
          </div>
        </v-sheet>
      </v-card>
    </v-main>
    <v-main v-if="!isLoggining || this.$route.meta.notRequiresAuth == true">
      <router-view />
    </v-main>
    <Snackbar />
    <Loader v-if="loading" />
  </v-app>
</template>

<script>
// import Header from "@/components/Header.vue";
import Loader from "./components/Loader.vue";
import Snackbar from "./components/SuccessMsg.vue";
import store from "@/store";
import { mapGetters } from "vuex";
// import firebaseHelper from "./plugins/firebase";

export default {
  name: "App",

  data: () => ({
    rspnsvMenu: false,
    // admins: [
    //   ["Management", "mdi-account-multiple-outline"],
    //   ["Settings", "mdi-cog-outline"],
    // ],
    // cruds: [
    //   ["Create", "mdi-plus-outline"],
    //   ["Read", "mdi-file-outline"],
    //   ["Update", "mdi-update"],
    //   ["Delete", "mdi-delete"],
    // ],
    Contacts: {
      title: " Contacts",
      icon: "far fa-list-alt",
      to: "/contacts/list",
    },
    isLoggining: store.getters["AuthModule/isLoggedIn"],
    locationsList: [
      {
        title: " Contacts",
        icon: "far fa-list-alt",
        to: "/contacts/list",
      },
    ],
    tec: {
      title: "Technicians",
      icon: "far fa-list-alt",
      to: "/technicians/list",
    },

    workOrder: {
      title: "Work orders",
      icon: "far fa-list-alt",
      to: "/work-order-list",
    },
    workOrderList: [
      {
        title: "Work orders",
        icon: "far fa-list-alt",
        to: "/work-order-list",
      },
      // {
      //   title: "New Work Order",
      //   icon: "far fa-list-alt",
      //   to: "/create-work-order",
      // },
    ],
    companyList: [
      {
        title: "Clients",
        icon: "far fa-list-alt",
        to: "/clients/list",
      },

      {
        title: "Accounts",
        icon: "far fa-list-alt",
        to: "/accounts/list",
      },

      {
        title: "Companies",
        icon: "far fa-list-alt",
        to: "/companies/list",
      },
      {
        title: " Locations",
        icon: "far fa-list-alt",
        to: "/locations/list",
      },
    ],

    //========= client Admin && client rep 
    ClientList: [
      {
        title: "Accounts",
        icon: "far fa-list-alt",
        to: "/accounts/list",
      },

      {
        title: "Companies",
        icon: "far fa-list-alt",
        to: "/companies/list",
      },
      {
        title: " Locations",
        icon: "far fa-list-alt",
        to: "/locations/list",
      },
    ],


    techList: [
      {
        title: "Technicians",
        icon: "far fa-list-alt",
        to: "/technicians/list",
      },
    ],
    userList: [
      {
        title: "Settings",

        to: "/settings/profile",
      },
    ],
    reportList: [
      {
        title: "Timesheets",
        to: "/reports/timesheets",
      },
      {
        title: "Ready To Invoice",
        to: "/reports/ApprovedTimesheets",
      },
      {
        title: "Invoices",
        to: "/invoice/list",
      },
    ],

    //========Reports Me
    Reports: [
      // {
      //   title: "Financial Reports",
      //   icon: "far fa-list-alt",
      //   to: "/Reports/FinancialReports",
      // }
      // ,

      {
        title: "Payroll",
        icon: "far fa-list-alt",
        to: "/Reports/Payroll",
      },
      // {
      //   title: "System Wide",
      //   icon: "far fa-list-alt",
      //   to: "..",
      // },
      // {
      //   title: "Security Reports",
      //   icon: "far fa-list-alt",
      //   to: "..",
      // },
      // {
      //   title: "HR Reports",
      //   icon: "far fa-list-alt",
      //   to: "..",
      // },
      // {
      //   title: "Timesheet / Invoices",
      //   icon: "far fa-list-alt",
      //   to: "..",
      // },
    ],
    items: [
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me 2" },
    ],
  }),
  components: {
    Loader,
    Snackbar,
    // Header,
  },
  computed: {
    modalShow() {
      return this.$store.getters.getPreloader;
    },
    loading() {
      return this.$store.getters.getSpinner;
    },

    ...mapGetters({
      userInfo: "AuthModule/userData",
    }),
  },
  
  methods: {
    logout() {
      this.$store.dispatch("AuthModule/logout");
    },
  },
};
</script>

