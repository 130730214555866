var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-background newPadding" }, [
    _c(
      "div",
      [
        _c(
          "v-stepper",
          {
            staticClass: "login-form newHeight",
            model: {
              value: _vm.e1,
              callback: function ($$v) {
                _vm.e1 = $$v
              },
              expression: "e1",
            },
          },
          [
            _c(
              "v-stepper-items",
              [
                _c(
                  "v-stepper-content",
                  { attrs: { step: "1", id: "stepOne" } },
                  [
                    _c(
                      "div",
                      { staticClass: "mb-12" },
                      [
                        _c("p", { staticClass: "login-label" }, [
                          _vm._v("Activate your account"),
                        ]),
                        _c("v-text-field", {
                          class: {
                            "is-invalid":
                              _vm.activationSubmite &&
                              _vm.$v.user.systemUserInfo.firstName.$error,
                          },
                          attrs: { solo: "", label: "First Name" },
                          model: {
                            value: _vm.user.systemUserInfo.firstName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.user.systemUserInfo,
                                "firstName",
                                $$v
                              )
                            },
                            expression: "user.systemUserInfo.firstName",
                          },
                        }),
                        _vm.activationSubmite &&
                        !_vm.$v.user.systemUserInfo.firstName.required
                          ? _c("p", { staticClass: "invalid-feedback mt-3" }, [
                              _vm._v(" First Name is Required "),
                            ])
                          : _vm._e(),
                        _c("v-text-field", {
                          class: {
                            "is-invalid":
                              _vm.activationSubmite &&
                              _vm.$v.user.systemUserInfo.lastName.$error,
                          },
                          attrs: {
                            type: "text",
                            solo: "",
                            label: " Last Name",
                          },
                          model: {
                            value: _vm.user.systemUserInfo.lastName,
                            callback: function ($$v) {
                              _vm.$set(_vm.user.systemUserInfo, "lastName", $$v)
                            },
                            expression: "user.systemUserInfo.lastName",
                          },
                        }),
                        _vm.activationSubmite &&
                        !_vm.$v.user.systemUserInfo.lastName.required
                          ? _c("p", { staticClass: "invalid-feedback mt-3" }, [
                              _vm._v(" Last Name is Required "),
                            ])
                          : _vm._e(),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { right: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-text-field",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            class: {
                                              "is-invalid":
                                                _vm.activationSubmite &&
                                                _vm.$v.user.newPassword.$error,
                                            },
                                            attrs: {
                                              label: "Password",
                                              solo: "",
                                              type: "password",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.show = true
                                              },
                                            },
                                            model: {
                                              value: _vm.user.newPassword,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "newPassword",
                                                  $$v
                                                )
                                              },
                                              expression: "user.newPassword",
                                            },
                                          },
                                          "v-text-field",
                                          attrs,
                                          false
                                        ),
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.show,
                              callback: function ($$v) {
                                _vm.show = $$v
                              },
                              expression: "show",
                            },
                          },
                          [
                            _c("div", [
                              _c("p", [_vm._v("Password need at least :")]),
                              _c("ul", [
                                _c("li", { staticClass: "validation-list" }, [
                                  _c("i", {
                                    staticClass: "fas fa-check-circle mr-2",
                                    class: {
                                      "is-checked":
                                        _vm.user.newPassword.length >= 8,
                                    },
                                  }),
                                  _c("span", [_vm._v(" 8 characters")]),
                                ]),
                                _c("li", { staticClass: "validation-list" }, [
                                  _c("i", {
                                    staticClass: "fas fa-check-circle mr-2",
                                    class: {
                                      "is-checked": _vm.checkAlphanumeric(
                                        _vm.user.newPassword
                                      ),
                                    },
                                  }),
                                  _c("span", [
                                    _vm._v("contain alphanumeric characters "),
                                  ]),
                                ]),
                                _c(
                                  "li",
                                  { staticClass: "validation-list mb-7" },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-check-circle mr-2",
                                      class: {
                                        "is-checked": _vm.containUpper(
                                          _vm.user.newPassword
                                        ),
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        "contain uppercase and lowercase characters "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _vm.activationSubmite &&
                        !_vm.$v.user.newPassword.required
                          ? _c("p", { staticClass: "invalid-feedback mt-3" }, [
                              _vm._v(" Password is Required "),
                            ])
                          : _vm._e(),
                        _vm.activationSubmite &&
                        !_vm.$v.user.newPassword.minLength
                          ? _c("p", { staticClass: "invalid-feedback mt-3" }, [
                              _vm._v(
                                " Password must be at least 8 characters and must contain alphanumeric and uppercase characters "
                              ),
                            ])
                          : _vm._e(),
                        _c("v-text-field", {
                          class: {
                            "is-invalid":
                              _vm.activationSubmite &&
                              _vm.$v.user.confirmationPassword.$error,
                          },
                          attrs: {
                            label: "Confirm Password",
                            type: "password",
                            solo: "",
                          },
                          model: {
                            value: _vm.user.confirmationPassword,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "confirmationPassword", $$v)
                            },
                            expression: "user.confirmationPassword",
                          },
                        }),
                        _vm.activationSubmite &&
                        !_vm.$v.user.confirmationPassword.required
                          ? _c("p", { staticClass: "invalid-feedback mt-3" }, [
                              _vm._v(" Confirmation Password is Required "),
                            ])
                          : _vm._e(),
                        _vm.activationSubmite &&
                        !_vm.$v.user.confirmationPassword.sameAsPassword
                          ? _c("p", { staticClass: "invalid-feedback mt-3" }, [
                              _vm._v(
                                " Confirmation Password must match Password "
                              ),
                            ])
                          : _vm._e(),
                        _c(
                          "v-text-field",
                          {
                            class: {
                              "is-invalid":
                                _vm.activationSubmite &&
                                _vm.$v.user.systemUserInfo.phoneNumber.$error,
                            },
                            attrs: { label: "phone", solo: "" },
                            on: {
                              blur: function ($event) {
                                return _vm.formatPhoneNumber()
                              },
                            },
                            model: {
                              value: _vm.user.systemUserInfo.phoneNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.user.systemUserInfo,
                                  "phoneNumber",
                                  $$v
                                )
                              },
                              expression: "user.systemUserInfo.phoneNumber",
                            },
                          },
                          [
                            _vm.activationSubmite &&
                            !_vm.$v.user.systemUserInfo.phoneNumber.required
                              ? _c(
                                  "p",
                                  { staticClass: "invalid-feedback mt-3" },
                                  [_vm._v(" Phone Number is Required ")]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "nextBtn",
                            attrs: { color: "#172450" },
                            on: { click: _vm.validateActivation },
                          },
                          [_vm._v(" Done ")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c("v-stepper-content", { attrs: { step: "2" } }, [
                  _c(
                    "div",
                    { staticClass: "mb-12" },
                    [
                      _c("p", { staticClass: "login-label" }, [
                        _vm._v("Login"),
                      ]),
                      _c("p", { staticClass: "confirmation-link" }, [
                        _vm._v(
                          " Please Check your email for the Confirmation Link "
                        ),
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "nextBtn",
                          attrs: { color: "#172450" },
                          on: {
                            click: function ($event) {
                              _vm.e1 = 1
                            },
                          },
                        },
                        [_vm._v(" Done ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("v-stepper-content", { attrs: { step: "3" } }, [
                  _c(
                    "div",
                    { staticClass: "mb-12" },
                    [
                      _c("p", { staticClass: "login-label" }, [
                        _vm._v("Login"),
                      ]),
                      _c("v-text-field", {
                        class: {
                          "is-invalid": _vm.submitted && _vm.$v.email.$error,
                        },
                        attrs: { solo: "", label: "Enter your email" },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.validateStepOne.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.email,
                          callback: function ($$v) {
                            _vm.email = $$v
                          },
                          expression: "email",
                        },
                      }),
                      _vm.submitted && !_vm.$v.email.required
                        ? _c("p", { staticClass: "invalid-feedback mt-3" }, [
                            _vm._v(" email is Required "),
                          ])
                        : _vm._e(),
                      _vm.submitted && !_vm.$v.email.email
                        ? _c("p", { staticClass: "invalid-feedback mt-3" }, [
                            _vm._v(" email is Invalid "),
                          ])
                        : _vm._e(),
                      _c(
                        "a",
                        {
                          staticClass: "fp-link",
                          on: { click: _vm.forgetPassword },
                        },
                        [_vm._v("Forget Password")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "nextBtn",
                          attrs: { color: "#172450" },
                          on: { click: _vm.validateStepOne },
                        },
                        [_vm._v(" Next ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "v-stepper-content",
                  { attrs: { step: "4", id: "stepOne" } },
                  [
                    _c(
                      "div",
                      { staticClass: "mb-12" },
                      [
                        _c("p", { staticClass: "login-label" }, [
                          _vm._v("Login"),
                        ]),
                        _c("v-text-field", {
                          class: {
                            "is-invalid":
                              _vm.submitted && _vm.$v.password.$error,
                          },
                          attrs: {
                            solo: "",
                            type: "password",
                            label: "Enter Your Password",
                          },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.login.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.password,
                            callback: function ($$v) {
                              _vm.password = $$v
                            },
                            expression: "password",
                          },
                        }),
                        _vm.submitted && !_vm.$v.password.required
                          ? _c("p", { staticClass: "invalid-feedback mt-3" }, [
                              _vm._v(" Password is Invalid "),
                            ])
                          : _vm._e(),
                        _c(
                          "v-btn",
                          {
                            staticClass: "nextBtn",
                            attrs: { color: "#172450" },
                            on: { click: _vm.login },
                          },
                          [_vm._v(" Login ")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }