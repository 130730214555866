
import Vue from "vue";

export default {

  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_AUTH_USER(state, user) {

    state.user = user

  },
  SET_ROLE(state, role) {
    state.role = role

  },

  SET_USER_DATA(data) {

    localStorage.setItem("tod_token", data.token);
    localStorage.setItem("tod_user", JSON.stringify(data.user));
    localStorage.setItem("user_permissions", JSON.stringify(data.user.data.groupIds));



  },
  SET_USER_EMAIL(data) {
    localStorage.setItem("user_email", data);

  },
  REMOVE_USER_DATA(state) {

    localStorage.removeItem("tod_token");
    localStorage.removeItem("tod_user");
    localStorage.removeItem("teachers");
    localStorage.removeItem("userType");
    localStorage.removeItem("student_id");





    state.token = ''
    state.user = ''
    state.role = ''

    delete Vue.prototype.$http.defaults.headers.common["Authorization"];


  }

}
