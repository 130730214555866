<template>
  <div>
    <div class="mb-7">
      <v-row class="handle-timesheet" style="margin-bottom: -1%">
        <v-col cols="6" md="9">
          <p class="table-title ml-10 mt-10">
            {{ tecData.tecName + " TimeSheet Details" }}
          </p>
        </v-col>

        <v-col md="3" cols="6" class="mt-8">
          <span class="Value2-data"
            >TimeSheet Hr: {{ totalHours + totalHr }} hr</span
          >
          <br />
          <span class="Value2-data">Clocking Hr: {{ totalHours }} hr</span>
        </v-col>
      </v-row>

      <div style="margin-left: 6%">
        <v-row class="handle-timesheet">
          <v-col cols="4" md="4" style="padding-left: 2%">
            <span>
              <span class="label-key" style="margin-right: 2%">
                TimeSheetId:
              </span>

              <span class="label-key" style="margin-right: 2%">
                {{ tecData.TimesheetId }}
              </span>
            </span>
          </v-col>
          <v-col cols="4" md="4">
            <span>
              <span class="label-key" style="margin-right: 2%">
                Master Work OrderId :
              </span>

              <span class="label-key" style="margin-right: 2%">
                {{ tecData.MasterWorkorderId }}
              </span>
            </span>
          </v-col>
          <v-col cols="4" md="4">
            <span>
              <span class="label-key" style="margin-right: 2%">
                Sub Work OrderId :
              </span>

              <span class="label-key" style="margin-right: 2%">
                {{ tecData.tecWoId }}
              </span>
            </span>
          </v-col>
        </v-row>

        <v-row class="handle-timesheet">
          <v-col cols="4" md="4" style="padding-left: 2%">
            <span>
              <span class="label-key" style="margin-right: 2%"> Client : </span>

              <span class="label-key" style="margin-right: 2%">
                {{ tecData.Client }}
              </span>
            </span>
          </v-col>
          <v-col cols="4" md="4">
            <span>
              <span class="label-key" style="margin-right: 2%">
                Account :
              </span>

              <span class="label-key" style="margin-right: 2%">
                {{ tecData.Account }}
              </span>
            </span>
          </v-col>
          <v-col cols="4" md="4">
            <span>
              <span class="label-key" style="margin-right: 2%">
                Location :
              </span>

              <span class="label-key" style="margin-right: 1%">
                {{ tecData.Location }}
              </span>
            </span>
          </v-col>
        </v-row>
        <!-- ======================================================================= -->

        <v-row class="handle-timesheet">
          <v-col cols="4" md="4" style="padding-left: 2%">
            <span>
              <span class="label-key" style="margin-right: 2%">
                Technical Contact :
              </span>
              <span class="label-key" style="margin-right: 2%">
                {{ tecData.TechContact }}
              </span>
            </span>
          </v-col>

          <v-col cols="4" md="4">
            <span>
              <span class="label-key" style="margin-right: 2%">
                On Site Contact :
              </span>
              <span class="label-key" style="margin-right: 2%">
                {{ tecData.SiteContact }}
              </span>
            </span>
          </v-col>

          <v-col cols="4" md="4">
            <span>
              <span class="label-key" style="margin-right: 2%">
                Location Address :
              </span>
              <span class="label-key" style="margin-right: 2%">
                {{ tecData.LocationAddress }}
              </span>
            </span>
          </v-col>
        </v-row>

        <v-row class="handle-timesheet">
          <v-col style="padding-left: 2%; margin-top: -1%">
            <span>
              <span class="label-key" style="margin-right: 2%">
                Scope Of Work :
              </span>
              <span class="label-key" style="margin-right: 2%">
                {{ tecData.ScopeWork }}
              </span>
            </span>
          </v-col>
        </v-row>
        <!-- ============================================================= -->
      </div>
    </div>

    <div class="mb-7">
      <div class="top-box">
        <v-row>
          <v-col md="9">
            <p class="clocking-date">{{ convertUTCDateToLocalDate(
                  new Date(item.date)
                ).toLocaleString().substr(0, 10)  }}</p>
          </v-col>
          <v-col md="3"> </v-col>
        </v-row>
      </div>
      <div class="clocking-box">
        <v-row>
          <v-col md="1" class="resize"> </v-col>
          <v-col md="5">
            <v-text-field
              outlined
              label="Closing Notes"
              v-model="item.scopeOfFinishWork"
              :class="{
                'is-invalid': submitted && $v.item.scopeOfFinishWork.$error,
              }"
            ></v-text-field>
            <p
              v-if="submitted && !$v.item.scopeOfFinishWork.required"
              class="invalid-feedback mt-2"
            >
             Closing Notes Required
            </p>
          </v-col>
          <v-col md="1" id="time-box">
            <vue-timepicker
              @blur="calcutateDiffTime"
              v-model="item.startTime"
              manual-input
              hide-dropdown
              :class="{
                'is-invalid': submitted && $v.item.startTime.$error,
              }"
            >
            </vue-timepicker>
            <p
              v-if="submitted && !$v.item.startTime.required"
              class="invalid-feedback"
            >
              startTime is Required
            </p>
          </v-col>
          <v-col md="1" id="time-box">
            <vue-timepicker
              @blur="calcutateDiffTime"
              v-model="item.endTime"
              manual-input
              hide-dropdown
              :class="{
                'is-invalid': submitted && $v.item.endTime.$error,
              }"
            ></vue-timepicker>
            <p
              v-if="submitted && !$v.item.startTime.required"
              class="invalid-feedback"
            >
              endTime is Required
            </p>
          </v-col>
          <v-col md="1">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <i
                  class="fas fa-calendar-alt day-icon"
                  v-bind="attrs"
                  v-on="on"
                ></i>
              </template>
              <v-date-picker v-model="item.date" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="item.menu = false">
                  Cancel
                </v-btn>
              </v-date-picker>
            </v-menu>
            <p
              v-if="submitted && !$v.item.date.required"
              class="invalid-feedback"
            >
              date is Required
            </p>
          </v-col>
          <v-col md="2" id="time-box">
            <vue-timepicker
              disabled
              manual-input
              hide-dropdown
              v-model="item.total"
            ></vue-timepicker>
          </v-col>

          <v-col md="1">
            <v-btn
              class="ma-2"
              style="width: 130px; justify-content: flex-start; padding: 0 10px"
              @click="AddClocking()"
              color="primary"
              dark
            >
              Add Clocking

              <v-icon dark right> </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="5"></v-col>
          <v-col>
            <p v-if="VaildationError2" class="invalid-feedback">
              endTime should be greater than start time
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="mb-7" v-for="clock in woClocking" :key="clock.date">
      <div class="top-box">
        <v-row>
          <v-col md="9">
            <p class="clocking-date">{{ 
              convertUTCDateToLocalDate(
                  new Date( clock.date )
                ).toLocaleString().substr(0, 10)
              
             }}</p>
          </v-col>
          <v-col md="3"> </v-col>
        </v-row>
      </div>
      <div
        v-for="(item, index) in clock.items"
        :key="item.id"
        class="clocking-box"
      >
        <v-row>
          <v-col md="1" class="resize"> </v-col>
          <v-col md="5">
            <v-text-field
              outlined
              @blur="UpdateClocking(item)"
              class="description-feild"
              label="Closing Notes"
              v-model="item.scopeOfFinishWork"
            ></v-text-field>
          </v-col>
          <v-col md="1" id="time-box">
            <vue-timepicker
              v-model="item.startTime"
              manual-input
              hide-dropdown
              @blur="UpdateClocking(item)"
            >
            </vue-timepicker>
          </v-col>
          <v-col md="1" id="time-box">
            <vue-timepicker
              v-model="item.endTime"
              manual-input
              hide-dropdown
              @blur="UpdateClocking(item)"
            >
            </vue-timepicker>
          </v-col>
          <v-col md="1">
            <v-menu
              :ref="index"
              v-model="item.menu"
              :close-on-content-click="false"
              :return-value.sync="clock.date"
              transition="scale-transition"
              offset-y
              min-width="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <i
                  class="fas fa-calendar-alt day-icon"
                  v-bind="attrs"
                  v-on="on"
                ></i>
              </template>
              <v-date-picker
                v-model="clock.date"
                @input="item.menu = false"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="item.menu = false">
                  Cancel
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="2" id="time-box">
            <vue-timepicker
              disabled
              manual-input
              hide-dropdown
              v-model="item.total"
            ></vue-timepicker>
          </v-col>
          <v-col md="1">
            <v-icon
              @click="deleteItem(item)"
              class="delete-timesheet"
              color="#A23D3D"
              >mdi-delete</v-icon
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col md="5"></v-col>
          <v-col>
            <p v-if="VaildationError" class="invalid-feedback">
              endTime should be greater than start time
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-if="isPending" class="btn-container">
      <v-btn class="float btn-creation wo-diff-style" @click="Approval(true)"
        >Approve</v-btn
      >
      <v-btn
        v-if="isPending"
        class="float2 btn-creation wo-diff-style"
        @click="Approval(false)"
        color="red"
        >Disapprove
      </v-btn>
    </div>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="expenseDialoge" max-width="700px">
      <v-card>
        <v-card-title class="text-h5">New Expense</v-card-title>

        <v-card-text>
          <br />

          <v-select
            :items="expensesTypes"
            :item-text="'name'"
            :item-value="'id'"
            label="Add Fees,Discount,Tax, ... "
            solo
            v-model="expenseTypeId"
            :class="{
              'is-invalid': submittedExpense && $v.expenseTypeId.$error,
            }"
          ></v-select>
          <p
            v-if="submittedExpense && !$v.expenseTypeId.required"
            class="invalid-feedback mt-2"
          >
            Expense Type is required
          </p>
          <br />
          <v-row>
            <v-col md="3" cols="2">
              <v-select
                solo
                :item-text="'name'"
                :item-value="'id'"
                :items="amountTypes"
                label="$"
                v-model="feild.amountTypeId"
                :class="{
                  'is-invalid':
                    submittedExpense && $v.feild.amountTypeId.$error,
                }"
              ></v-select>
              <p
                v-if="submittedExpense && !$v.feild.amountTypeId.required"
                class="invalid-feedback mt-2"
              >
                amount Type is required
              </p>
            </v-col>

            <v-col md="9" cols="10">
              <v-text-field
                v-model="feild.expenseAmount"
                outlined
                label="Amount"
                :class="{
                  'is-invalid':
                    submittedExpense && $v.feild.expenseAmount.$error,
                }"
              >
              </v-text-field>
              <p
                v-if="submittedExpense && !$v.feild.expenseAmount.required"
                class="invalid-feedback mt-2"
              >
                amount is required
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                placeholder="Description"
                v-model="feild.description"
                :label="feild.name"
                :class="{
                  'is-invalid': submittedExpense && $v.feild.description.$error,
                }"
              ></v-text-field>
              <p
                v-if="submittedExpense && !$v.feild.description.required"
                class="invalid-feedback mt-2"
              >
                description is required
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="blue darken-1" text @click="addFeild">Add</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <br />
    <div class="mb-7">
      <div class="top-box">
        <v-row>
          <v-col md="2" cols="10">
            <p class="clocking-date">Timesheet Expenses</p>
          </v-col>
          <v-col md="5" cols="2">
            <v-icon class="ml-5" @click="ShowExpenseDialog" small>
              fa-solid fa-plus
            </v-icon>
          </v-col>
          <v-col md="4" cols="12">
            <span>
              <span class="Value2-data" style="margin-left: 7%">{{
                "Total Hr : " +
                totalHr +
                " hr &nbsp; &nbsp; &nbsp; Total Amount :$" +
                totalAmount
              }}</span>
            </span>
          </v-col>
        </v-row>
        <v-row v-for="expense in expenses" :key="expense.id">
          <v-col md="3" cols="0" class="resize"></v-col>
          <v-col md="2" cols="12">
            <p class="label-key-Timesheet" style="padding-left: 0%">
              {{ expense.workOrderExpenseTypeName }}
            </p>
          </v-col>
          <v-col md="2" cols="6">
            <span v-if="expense.amountTypeId == 2">
              <span class="label-keycolorRed">{{
                expense.amountTypeName
              }}</span>
              <span class="label-keycolorRed">{{ expense.expenseAmount }}</span>
            </span>
            <span v-else>
              <span class="label-keycolorRed">{{ expense.expenseAmount }}</span>
              <span class="label-keycolorRed">{{
                expense.amountTypeName
              }}</span>
            </span>
          </v-col>
          <v-col md="2" cols="3">
            <p class="label-keycolorBlue">{{ expense.description }}</p>
          </v-col>

          <v-col md="3">
            <v-icon @click="removeFeild(expense)" class="" color="#A23D3D"
              >mdi-delete</v-icon
            >
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import VueTimepicker from "vue2-timepicker";
import { dataService } from "@/services/data-service";
import store from "@/store";
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      VaildationError2: false,
      VaildationError: false,
      totalHours: 0,
      tecData: {
        tecName: "",
        tecWoId: "",
        LocationAddress: " ",
        SiteContact: " ",
        ScopeWork: " ",
        TechContact: " ",
      },
      amountTypes: [],
      totalHr: 0,
      totalAmount: 0,
      expenseTypeId: null,
      isPending: false,
      approved: false,
      submittedExpense: false,
      expensesTypes: [],
      expenseDialoge: false,
      expenses: {},
      newItem: {
        TimesheetId: 0,
        workOrderTechId: 0,
        clockIn: "",
        clockOut: "",
        scopeOfFinishWork: "string",
      },
      submitted: false,
      item: {
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        endTime: {},
        scopeOfFinishWork: "",
        startTime: {},
      },
      feild: {
        workOrderExpenseType: "",
        description: "",
      },
      clock: "",
      dialogDelete: false,
      menu: false,
      woId: this.$route.params.woId,
      woClocking: [],
      itemID: null,
      //====== me
      // editedNoteObj: {},

      selected: [],
      timesheet: [],
      updateDto: {
        id: 0,
        clockIn: "",
        clockOut: "",
        scopeOfFinishWork: "string",
      },
    };
  },
  methods: {
    Approval(flag) {
      this.approved = flag;
  
      let selectedList = [];
      selectedList.push(this.$router.currentRoute.params.id);
      dataService.create(
        "api/Invoices/change-time-sheet-status",
        {
          timeSheetIds: selectedList,
          approved: this.approved,
        },
        () => {
          this.$router.push({
            name: "timesheetNeedToApproved",
          });
        }
      );
    },
    getExpensesTypes() {
      dataService.getAll("api/Enums/work-order-expense-types", (response) => {
        this.expensesTypes = response.data;
      });
    },
    addFeild() {
      this.submittedExpense = true;
 
      this.$v.$touch();
      if (this.$v.expenseTypeId.$invalid) {
        return;
      }

      if (this.$v.feild.$invalid) {
        return;
      }
      this.expenseDialoge = false;
      this.feild.expenseTypeId = this.expenseTypeId;
      this.expenses.push(this.feild);
      dataService.create(
        "api/TimeSheetExpense/Create",
        {
          timeSheetId: this.$router.currentRoute.params.id,
          createTimeSheetExpenseDto: this.expenses,
        },
        () => {
           this.feild = {
            workOrderExpenseType: null,
            description: null,
            expenseAmount: null,
          };
          this.expenseTypeId = null;
            this.getExpenses();
        }
      );
    },

    removeFeild(item) {
   
      dataService.postDelete(
        "/api/TimeSheetExpense/delete-timesheetexpense/" + item.id,
        (response) => {
          if (response.data.succeeded == true) {
            this.getExpenses();
          }
        }
      );
    },
    ShowExpenseDialog() {
      this.expenseDialoge = true;
    },
    getExpenses() {
      dataService.getAll(
        "api/WorkOrders/get_time_sheet_expense_details/" +
          this.$router.currentRoute.params.id,
        (response) => {
          this.expenses = response.data.timeSheetExpense;
          this.totalAmount = response.data.totalAmount;
          this.totalHr = response.data.totalHr;
  
        }
      );
    },

    CreateExpense() {
      dataService.create(
        "api/TimeSheetExpense/Create",
        {
          timeSheetId: this.$router.currentRoute.params.id,
          createTimeSheetExpenseDto: this.expenses,
        },
        () => {
          this.getExpenses();
        }
      );
    },

    convertUTCDateToLocalDate(date) {
      var newDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60 * 1000
      );

      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();

      newDate.setHours(hours - offset);

      return newDate;
    },
    UpdateClocking(item) {
      this.VaildationError = false;
      
      if (item.endTime < item.startTime) {
        this.VaildationError = true;
      } else {
        let end = item.endTime + ":00";
        let clockOut = new Date(item.clockingOutDate.split("T")[0] + " " + end);
        let start = item.startTime + ":00";

        let clockIn = new Date(item.clockingInDate.split("T")[0] + " " + start);
        this.updateDto.id = item.id;
        this.updateDto.clockIn = clockIn;
        this.updateDto.clockOut = clockOut;
        this.updateDto.scopeOfFinishWork = item.scopeOfFinishWork;

        dataService.update(
          "api/WorkOrders/update-clocking",
          this.updateDto,
          () => {
            //console.log(response);

            this.getClocking();
          }
        );
      }
    },
    AddClocking() {
      
      //map item to the newitem formate
      this.submitted = true;
      this.$v.item.$touch();
      if (
        this.item.endTime.HH < this.item.startTime.HH ||
        (this.item.endTime.HH === this.item.startTime.HH &&
          this.item.endTime.mm < this.item.startTime.mm)
      ) {
        this.VaildationError2 = true;
        return;
      }

      if (this.$v.item.$invalid) {
        return;
      } else {
    

        this.item.endTime.HH =
          this.item.endTime.HH && this.item.endTime.HH != ""
            ? this.item.endTime.HH
            : "00";
        this.item.endTime.mm =
          this.item.endTime.mm && this.item.endTime.mm != ""
            ? this.item.endTime.mm
            : "00";
        this.item.startTime.HH =
          this.item.startTime.HH && this.item.startTime.HH != ""
            ? this.item.startTime.HH
            : "00";
        this.item.startTime.mm =
          this.item.startTime.mm && this.item.startTime.mm != ""
            ? this.item.startTime.mm
            : "00";

        let end = this.item.endTime.HH + ":" + this.item.endTime.mm + ":00";
        let clockOut = new Date(this.item.date + " " + end);
        let start =
          this.item.startTime.HH + ":" + this.item.startTime.mm + ":00";

        let clockIn = new Date(this.item.date + " " + start);
        //console.log(localStorage.tecWoId,"techwo")
        this.newItem.workOrderTechId =  this.tecData.tecWoId ;
        this.newItem.TimesheetId = this.$route.params.id;
        this.newItem.clockIn = clockIn;
        this.newItem.clockOut = clockOut;
        this.newItem.scopeOfFinishWork = this.item.scopeOfFinishWork;
        this.item.date = new Date(this.item.date);
      

        dataService.create(
          "api/WorkOrders/clocking_by_timesheetid",
          this.newItem,
          () => {
            //console.log(response);
            (this.item.date = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .substr(0, 10)),
              (this.item.scopeOfFinishWork = "");
            this.item.endTime.HH = "00";
            this.item.endTime.mm = "00";
            this.item.startTime.HH = "00";
            this.item.startTime.mm = "00";
            this.submitted = false;

            this.getClocking();
          }
        );
        // /WorkOrders/update-clocking
      }
    },
    addHoursToDate(objDate, intHours) {
      var numberOfMlSeconds = objDate.getTime();
      var addMlSeconds = intHours * 60 * 60 * 1000;
      var newDateObj = new Date(numberOfMlSeconds + addMlSeconds);

      return newDateObj;
    },
    pushClock(item) {
      if (item.check == true) {
        this.timesheet.push(item);
      } else {
        var removeIndex = this.timesheet
          .map((item) => item.id)
          .indexOf(item.id);

        ~removeIndex && this.timesheet.splice(removeIndex, 1);
      }
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteItem(item) {
      this.itemID = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      dataService.postDelete(
        "api/WorkOrders/delete_clocking_record/" + this.itemID,

        (response) => {
          if (response.data.succeeded) {
            store.commit("SHOW_MESSAGE", {
              text: "Deleted Successfully",
              color: "success",
              timeout: 3000,
            });
            this.dialogDelete = false;
            this.getClocking();
          }
        }
      );
    },
    calcutateDiffTime() {
      this.VaildationError2 = false;
      
      if (
        this.item.endTime.HH < this.item.startTime.HH ||
        (this.item.endTime.HH === this.item.startTime.HH &&
          this.item.endTime.mm < this.item.startTime.mm)
      ) {
        // this.VaildationError2 = false;
        this.VaildationError2 = true;
      } else {
        if (this.item.endTime.HH || this.item.endTime.mm) {
          // this.VaildationError2 = false;
          //this.VaildationError2 = true;
          let start =
            this.item.startTime.HH + ":" + this.item.startTime.mm + ":00";
          let end = this.item.endTime.HH + ":" + this.item.endTime.mm + ":00";
          let clockIn = new Date(this.item.date + " " + start);
          let clockout = new Date(this.item.date + " " + end);
          var diff = clockout.getTime() - clockIn.getTime();
          var hours = Math.floor(diff / 3.6e6);
          if (hours < 10) {
            hours = "0" + hours;
          }

          var minutes = Math.floor((diff % 3.6e6) / 6e4);
          if (minutes < 10) {
            minutes = "0" + minutes;
          }

          var duration = hours + ":" + minutes;
          this.item.total = duration;
        }
      }
    },

    getClocking() {
      dataService.getAll(
        "api/WorkOrders/get_time_sheet_details/" +
          this.$router.currentRoute.params.id,
        (response) => {
          var tempClocking = response.data.clocking;
          this.totalHours = response.data.totalHours;
          const groups = tempClocking.reduce((groups, game) => {
            const date = game.clockingInDate.split("T")[0];
            if (!groups[date]) {
              groups[date] = [];
            }
            groups[date].push(game);
            return groups;
          }, {});

          // Edit: to add it in the array format instead
          const groupArrays = Object.keys(groups).map((date) => {
            return {
              date,
              items: groups[date],
            };
          });
          this.woClocking = groupArrays;

          for (var w = 0; w < this.woClocking.length; w++) {
            for (var c = 0; c < this.woClocking[w].items.length; c++) {
              var tempTime = new Date(
                this.woClocking[w].items[c].clockingInDate
              );
              tempTime = this.convertUTCDateToLocalDate(tempTime);
              var tempTime2 = new Date(
                this.woClocking[w].items[c].clockingOutDate
              );
              tempTime2 = this.convertUTCDateToLocalDate(tempTime2);
              var startHours = tempTime.getHours();
              var startMins = tempTime.getMinutes();

              if (startHours < 10) {
                startHours = "0" + startHours;
              }
              if (startMins < 10) {
                startMins = "0" + startMins;
              }

              var endHours = tempTime2.getHours();
              var endMins = tempTime2.getMinutes();

              if (endHours < 10) {
                endHours = "0" + endHours;
              }
              if (endMins < 10) {
                endMins = "0" + endMins;
              }

              this.woClocking[w].items[c].startTime =
                startHours + ":" + startMins;
              this.woClocking[w].items[c].endTime = endHours + ":" + endMins;
              var diff = tempTime2 - tempTime;
              var hours = Math.floor(diff / 3.6e6);
              if (hours < 10) {
                hours = "0" + hours;
              }

              var minutes = Math.floor((diff % 3.6e6) / 6e4);
              if (minutes < 10) {
                minutes = "0" + minutes;
              }

              var seconds = Math.floor((diff % 6e4) / 1000);
              if (seconds < 10) {
                seconds = "0" + seconds;
              }
              var duration = hours + ":" + minutes + ":" + seconds;
              this.woClocking[w].items[c].total = duration;
              this.woClocking[w].items[c].menu = false;
              this.woClocking[w].items[c].check = false;
            }
          }
        }
      );
    },

    diff_mins(dt2, dt1) {
      var diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60;
      return Math.abs(Math.round(diff));
    },

    TechData() {
      dataService.getAll(
        "api/Invoices/get_timesheet_details/" +
          this.$router.currentRoute.params.id,
        (response) => {
          

          this.tecData.tecName = response.data.technicianName;
          this.tecData.tecWoId = response.data.tecWoId;
          this.tecData.Client = response.data.clientName;
          this.tecData.Account = response.data.accountName;
          this.tecData.Location = response.data.location;
          this.tecData.MasterWorkorderId = response.data.orderId;
          this.tecData.TimesheetId = localStorage.timeSheetId;

          this.tecData.TechContact = response.data.technicalContact;
          this.tecData.ScopeWork = response.data.scopeOfWork;
          this.tecData.SiteContact = response.data.onSiteContact;
          this.tecData.LocationAddress = response.data.locationAddress;
      

          if (response.data.paymentTypeId == 1) {
            this.amountTypes = [
              {
                id: 2,
                name: "$",
              },
            ];
          } else {
            this.amountTypes = [
              {
                id: 2,
                name: "$",
              },
              {
                id: 3,
                name: "Hr",
              },
            ];
          }

        
        }
      );
    },
  },
  validations: {
    item: {
      date: { required },
      endTime: { required },
      scopeOfFinishWork: { required },
      startTime: { required },
    },
    feild: {
      amountTypeId: { required },
      expenseAmount: { required },
      description: { required },
    },
    expenseTypeId: { required },
  },

  mounted() {
    this.TechData();
  
    this.getClocking();
    this.getExpenses();
    this.getExpensesTypes();
  },
  created() {
    if (localStorage.isPending === "true") {
      this.isPending = true;
    } else {
      this.isPending = false;
    }
  },
  components: {
    VueTimepicker,
  },
};
</script>