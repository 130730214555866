

export default {
  isLoggining: false,
  status: "",
  token: localStorage.getItem("tod_token") || "",
  user: JSON.parse(localStorage.getItem("tod_user")) || {
    data: {
      role: ""
    }
  },
  role: localStorage.getItem("userType") || "",
  userEmail: localStorage.getItem("user_email") || "",




}
