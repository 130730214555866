
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/messaging";
import http from "../http"

let firebaseInit = {
    methods: {
        firebaseInit: function () {

            const firebaseConfig = {

                apiKey: "AIzaSyBXKX7Fo9oDVfWno4X0DJutdVJisw1s010",
                authDomain: "axa-notifications.firebaseapp.com",
                projectId: "axa-notifications",
                storageBucket: "axa-notifications.appspot.com",
                messagingSenderId: "1025520572507",
                appId: "1:1025520572507:web:94c6e79828ccad060b477e",
                measurementId: "G-36QLPS3B5B"
            };
            firebase.initializeApp(firebaseConfig);
            if ("Notification" in window && firebase.messaging.isSupported()) {

                const messaging = firebase.messaging();
                try {
                    messaging
                        .getToken({
                            vapidKey: "BEkJcblLWzp-L7SC1xBieIqQqOxwv-eGkJdUU9H9w9rX63mr-sZfwMgJWfJ9Ly4XdpH-B_Dho2PnQuWP2hGw4qY",
                        })
                        .then((currentToken) => {
                            if (currentToken) {
                             
                                this.sendTokenToServer(currentToken);
                            } else {
                                console.warn("Failed to get token.");
                            }
                        })
                        .catch(() => {
                            //console.log( "An error occurred while retrieving token. ", err);
                            this.setTokenSentToServer(false);
                        });
                } catch (e) {
                    //console.log(e);
                }
                messaging.onMessage((payload) => {
                    //console.log("Message received. firebase.js ", payload);
                    new Notification(
                        payload.notification.title,
                        payload.notification
                    );
                });
            }
        },
        isTokenSentToServer: function (currentToken) {
            // check if current token (locale storage) the same as the recieved token from firebase
            //if the same do nothing
            // else call update function from server with parameters (old token , new token and user number)
            //if updated reset the locale storage variablw with new token
            return (
                window.localStorage.getItem("sentFirebaseMessagingToken") ===
                currentToken
            );
        },
        setTokenSentToServer: function (currentToken) {
            window.localStorage.setItem(
                "sentFirebaseMessagingToken",
                currentToken ? currentToken : ""
            );
        },
        sendTokenToServer: function (currentToken) {

            if (!this.isTokenSentToServer(currentToken)) {
                http
                    .post("api/UserNotificationTokens", {
                        "userId": 2,
                        "token": currentToken
                    })
                    .then(() => {
                        //console.log(data)
                        this.setTokenSentToServer(currentToken);

                    });
            }
        },
    },
};
export default {
    firebaseInit,
};