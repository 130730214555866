var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "handle-test", attrs: { id: "work-order" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12", md: "10" } }, [
            _c("p", { staticClass: "table-title" }, [_vm._v("Client List")]),
          ]),
          _c(
            "v-col",
            { attrs: { md: "1", cols: "12" } },
            [
              _vm.can(20)
                ? _c(
                    "v-btn",
                    {
                      staticClass: "btn-creation wo-diff-style",
                      attrs: { to: "/clients/create", color: "#D92020" },
                    },
                    [_vm._v("Create Client ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pad0", attrs: { cols: "12", md: "4" } },
            [
              _c("v-text-field", {
                ref: "objet",
                attrs: {
                  solo: "",
                  label: "Search Client ",
                  "append-icon": "mdi-magnify",
                  type: "text",
                },
                on: {
                  keyup: function ($event) {
                    return _vm.realSearch(1)
                  },
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "search",
                },
              }),
            ],
            1
          ),
          _c("v-col", { staticClass: "pad0", attrs: { cols: "12", md: "8" } }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              loading: _vm.isLoaded,
              "loading-text": "Loading... Please wait",
              headers: _vm.headers,
              items: _vm.data,
              "item-key": "name",
              "hide-default-footer": "",
              "items-per-page": _vm.pagination.pageSize,
            },
            on: { "update:options": _vm.handleOptionsUpdate },
            scopedSlots: _vm._u([
              {
                key: "footer",
                fn: function () {
                  return [
                    _c(
                      "v-row",
                      { staticClass: "mt-5 fit-width" },
                      [
                        _c(
                          "v-col",
                          { attrs: { md: "2" } },
                          [
                            _c("v-select", {
                              attrs: {
                                items: _vm.sizeOptions,
                                "item-value": "id",
                                "item-text": "name",
                                solo: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.realSearch(1)
                                },
                              },
                              model: {
                                value: _vm.pagination.pageSize,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pagination, "pageSize", $$v)
                                },
                                expression: "pagination.pageSize",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { md: "5" } },
                          [
                            _c("v-pagination", {
                              attrs: {
                                length: _vm.totalPages,
                                "total-visible": "6",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.realSearch(
                                    _vm.pagination.pageNumber
                                  )
                                },
                              },
                              model: {
                                value: _vm.pagination.pageNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pagination, "pageNumber", $$v)
                                },
                                expression: "pagination.pageNumber",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "top",
                fn: function () {
                  return [
                    _c(
                      "v-toolbar",
                      { attrs: { flat: "" } },
                      [
                        _c("v-toolbar-title", [_vm._v("Clients")]),
                        _c("v-divider", {
                          staticClass: "mx-4",
                          attrs: { inset: "", vertical: "" },
                        }),
                        _c("v-spacer"),
                        _c(
                          "v-dialog",
                          {
                            attrs: { "max-width": "700px" },
                            model: {
                              value: _vm.dialog,
                              callback: function ($$v) {
                                _vm.dialog = $$v
                              },
                              expression: "dialog",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c("v-card-title", [
                                  _c("span", { staticClass: "text-h5" }, [
                                    _vm._v("Edit Client"),
                                  ]),
                                ]),
                                _c(
                                  "v-card-text",
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { md: "12" } },
                                              [
                                                _c("v-text-field", {
                                                  class: {
                                                    "is-invalid":
                                                      _vm.submitted &&
                                                      _vm.$v.client.name.$error,
                                                  },
                                                  attrs: {
                                                    tabindex: "1",
                                                    outlined: "",
                                                    label: "Full Name",
                                                  },
                                                  model: {
                                                    value: _vm.client.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.client,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "client.name",
                                                  },
                                                }),
                                                _vm.submitted &&
                                                !_vm.$v.client.name.required
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "invalid-feedback mt-2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Name is Required "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "6" },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  staticClass: "mb-3",
                                                  class: {
                                                    "is-invalid":
                                                      _vm.submitted &&
                                                      _vm.v.phoneNumber.$error,
                                                  },
                                                  attrs: {
                                                    tabindex: "2",
                                                    outlined: "",
                                                    set: (_vm.v =
                                                      _vm.$v.client.clientPhones.$each[0]),
                                                    label: "Primary Phone",
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.formatPhoneNumber(
                                                        1
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.primary.phoneNumber,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.primary,
                                                        "phoneNumber",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "primary.phoneNumber",
                                                  },
                                                }),
                                                _vm.submitted &&
                                                !_vm.v.phoneNumber.required
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "invalid-feedback",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Primary Phone is Required "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c("v-text-field", {
                                                  staticClass: "mb-3",
                                                  class: {
                                                    "is-invalid":
                                                      _vm.submitted &&
                                                      _vm.$v.client.email
                                                        .$error,
                                                  },
                                                  attrs: {
                                                    tabindex: "4",
                                                    outlined: "",
                                                    label: "Email",
                                                  },
                                                  model: {
                                                    value: _vm.client.email,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.client,
                                                        "email",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "client.email",
                                                  },
                                                }),
                                                _vm.submitted &&
                                                !_vm.$v.client.email.required
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "invalid-feedback",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Email is Required "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.submitted &&
                                                !_vm.$v.client.email.email
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "invalid-feedback",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Email is not valid "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "6" },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  staticClass: "mb-3",
                                                  attrs: {
                                                    tabindex: "3",
                                                    outlined: "",
                                                    label: "Secondary Phone",
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.formatPhoneNumber(
                                                        2
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.Secondry.phoneNumber,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.Secondry,
                                                        "phoneNumber",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "Secondry.phoneNumber",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          staticClass: "mb-10",
                                        }),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "mb-3",
                                                      class: {
                                                        "is-invalid":
                                                          _vm.submitted &&
                                                          _vm.$v.client.address
                                                            .street.$error,
                                                      },
                                                      attrs: {
                                                        tabindex: "5",
                                                        outlined: "",
                                                        label: "Street",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.client.address
                                                            .street,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.client.address,
                                                            "street",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "client.address.street",
                                                      },
                                                    }),
                                                    _vm.submitted &&
                                                    !_vm.$v.client.address
                                                      .street.required
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "invalid-feedback",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Street is Required "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "mb-3",
                                                      class: {
                                                        "is-invalid":
                                                          _vm.submitted &&
                                                          _vm.$v.client.address
                                                            .postalCode.$error,
                                                      },
                                                      attrs: {
                                                        tabindex: "6",
                                                        outlined: "",
                                                        label:
                                                          "ZIP / Postal Code",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.client.address
                                                            .postalCode,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.client.address,
                                                            "postalCode",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "client.address.postalCode",
                                                      },
                                                    }),
                                                    _vm.submitted &&
                                                    !_vm.$v.client.address
                                                      .postalCode.required
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "invalid-feedback",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Postal Code is Required "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "mb-3",
                                                      attrs: {
                                                        tabindex: "7",
                                                        outlined: "",
                                                        label: "Details",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.client.address
                                                            .details,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.client.address,
                                                            "details",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "client.address.details",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c("v-col", {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-autocomplete", {
                                                      staticClass: "mb-3",
                                                      class: {
                                                        "is-invalid":
                                                          _vm.submitted &&
                                                          _vm.$v.client.address
                                                            .country.id.$error,
                                                      },
                                                      attrs: {
                                                        tabindex: "8",
                                                        items: _vm.countries,
                                                        "item-text": "name",
                                                        "item-value": "id",
                                                        label: "Country",
                                                        outlined: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.client.address
                                                            .country.id,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.client.address
                                                              .country,
                                                            "id",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "client.address.country.id",
                                                      },
                                                    }),
                                                    _vm.submitted &&
                                                    !_vm.$v.client.address
                                                      .countryId.required
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "invalid-feedback",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Country is Required "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-autocomplete", {
                                                      staticClass: "mb-3",
                                                      class: {
                                                        "is-invalid":
                                                          _vm.submitted &&
                                                          _vm.$v.client.address
                                                            .state.id.$error,
                                                      },
                                                      attrs: {
                                                        tabindex: "9",
                                                        items: _vm.states,
                                                        "item-text": "name",
                                                        "item-value": "id",
                                                        label: "State",
                                                        outlined: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.client.address
                                                            .state.id,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.client.address
                                                              .state,
                                                            "id",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "client.address.state.id",
                                                      },
                                                    }),
                                                    _vm.submitted &&
                                                    !_vm.$v.client.address.state
                                                      .id.required
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "invalid-feedback",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " State is Required "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-autocomplete", {
                                                      staticClass: "mb-3",
                                                      class: {
                                                        "is-invalid":
                                                          _vm.submitted &&
                                                          _vm.$v.client.address
                                                            .city.id.$error,
                                                      },
                                                      attrs: {
                                                        tabindex: "10",
                                                        items: _vm.cities,
                                                        "item-text": "name",
                                                        "item-value": "id",
                                                        label: "City",
                                                        outlined: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.client.address
                                                            .city.id,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.client.address
                                                              .city,
                                                            "id",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "client.address.city.id",
                                                      },
                                                    }),
                                                    _vm.submitted &&
                                                    !_vm.$v.client.address.city
                                                      .id.required
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "invalid-feedback",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " City is Required "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c("v-col", {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "cancel-update-btn",
                                        attrs: { tabindex: "11", text: "" },
                                        on: { click: _vm.close },
                                      },
                                      [_vm._v("Cancel")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "create-order-btn mr-1",
                                        attrs: { tabindex: "12" },
                                        on: { click: _vm.save },
                                      },
                                      [_vm._v("Update")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-snackbar",
                      {
                        attrs: { timeout: 3000, color: _vm.snackColor },
                        scopedSlots: _vm._u([
                          {
                            key: "action",
                            fn: function ({ attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._b(
                                    {
                                      attrs: { text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.snack = false
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  [_vm._v(" Close ")]
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.snack,
                          callback: function ($$v) {
                            _vm.snack = $$v
                          },
                          expression: "snack",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.snackText) + " ")]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "item.clientPhones",
                fn: function ({ item }) {
                  return _vm._l(item.clientPhones, function (ph, index) {
                    return _c("p", { key: index }, [
                      _c(
                        "span",
                        {
                          class: {
                            "is-Primary": ph.isPrimary,
                          },
                        },
                        [_vm._v(_vm._s(ph.phoneNumber))]
                      ),
                    ])
                  })
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _vm.can(21)
                      ? _c(
                          "v-icon",
                          {
                            staticClass: "mr-2 edit-icon",
                            attrs: { small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.editItem(item)
                              },
                            },
                          },
                          [_vm._v(" far fa-edit ")]
                        )
                      : _vm._e(),
                    _vm.can(21)
                      ? _c(
                          "v-icon",
                          {
                            staticClass: "delete-icon",
                            attrs: { small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteItem(item)
                              },
                            },
                          },
                          [_vm._v(" mdi-delete ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "500px" },
              model: {
                value: _vm.dialogDelete,
                callback: function ($$v) {
                  _vm.dialogDelete = $$v
                },
                expression: "dialogDelete",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "text-h5" }, [
                    _vm._v("Are you sure you want to delete this item?"),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: { click: _vm.closeDelete },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: { click: _vm.deleteItemConfirm },
                        },
                        [_vm._v("OK")]
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }