var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload-section", attrs: { id: "users" } },
    [
      !_vm.$vuetify.breakpoint.xsOnly ? _c("SideMenu") : _vm._e(),
      _c(
        "v-overlay",
        { attrs: { value: _vm.loading } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "user-creation ml-4 mt-4", attrs: { id: "security" } },
        [
          _c("v-switch", {
            attrs: {
              inset: "",
              label: "Two Factor Authentication",
              color: "#2A8D3D",
            },
            on: {
              change: function ($event) {
                return _vm.Mainswitcht()
              },
            },
            model: {
              value: _vm.faSwitch,
              callback: function ($$v) {
                _vm.faSwitch = $$v
              },
              expression: "faSwitch",
            },
          }),
          _vm.faSwitch
            ? _c("v-switch", {
                attrs: {
                  inset: "",
                  label: "Send Code via Email",
                  color: "#2A8D3D",
                },
                on: {
                  click: function ($event) {
                    return _vm.switchtwofact(1, _vm.emailCode)
                  },
                },
                model: {
                  value: _vm.emailCode,
                  callback: function ($$v) {
                    _vm.emailCode = $$v
                  },
                  expression: "emailCode",
                },
              })
            : _vm._e(),
          _vm.faSwitch
            ? _c("v-switch", {
                attrs: {
                  inset: "",
                  label: "Send Code via Phone Number",
                  color: "#2A8D3D",
                },
                on: {
                  click: function ($event) {
                    return _vm.switchtwofact(2, _vm.phoneCode)
                  },
                },
                model: {
                  value: _vm.phoneCode,
                  callback: function ($$v) {
                    _vm.phoneCode = $$v
                  },
                  expression: "phoneCode",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }