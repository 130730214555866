var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload-section", attrs: { id: "users" } },
    [
      !_vm.$vuetify.breakpoint.xsOnly ? _c("SideMenu") : _vm._e(),
      _c(
        "div",
        { staticClass: "user-creation ml-4 mt-4" },
        [
          _c("p", { staticClass: "table-title" }, [_vm._v(_vm._s(_vm.title))]),
          _c(
            "v-row",
            { staticClass: "styling-selection-box" },
            [
              _c(
                "v-col",
                { attrs: { col: "12" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      disabled: _vm.disabled,
                      items: _vm.TemplateTypes,
                      "item-text": "name",
                      "item-value": "id",
                      outlined: "",
                      label: "Select Template Type",
                    },
                    on: { change: _vm.GetTemplateVariables },
                    model: {
                      value: _vm.TypeId,
                      callback: function ($$v) {
                        _vm.TypeId = $$v
                      },
                      expression: "TypeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "6", col: "12" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      multiple: "",
                      disabled: _vm.disabled,
                      items: _vm.clients,
                      "item-text": "name",
                      "item-value": "id",
                      outlined: "",
                      label: "Select Clients",
                    },
                    model: {
                      value: _vm.template.clientIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.template, "clientIds", $$v)
                      },
                      expression: "template.clientIds",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "styling-selection-box" },
            _vm._l(_vm.items, function (item) {
              return _c(
                "v-chip",
                {
                  key: item.id,
                  staticClass: "hoverele-class ma-2",
                  attrs: { color: "indigo darken-3", outlined: "" },
                  on: {
                    click: function ($event) {
                      return _vm.AddTechName(item.value)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.name) + " ")]
              )
            }),
            1
          ),
          _c(
            "div",
            [
              _vm.isVisable
                ? _c(
                    "v-btn",
                    {
                      staticClass: "float btn-creation wo-diff-style",
                      staticStyle: {
                        "z-index": "1",
                        bottom: "4%",
                        right: "7%",
                      },
                      on: { click: _vm.CreateEmailTemp },
                    },
                    [_vm._v(" Create ")]
                  )
                : _vm._e(),
              _c("br"),
              _c("editor", {
                attrs: {
                  "api-key": _vm.apiKey,
                  init: {
                    height: 700,

                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "table",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help |  table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
                  },
                },
                model: {
                  value: _vm.emailTemp,
                  callback: function ($$v) {
                    _vm.emailTemp = $$v
                  },
                  expression: "emailTemp",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "text-h5" }, [
                  _vm._v("Create Template"),
                ]),
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "12" } },
                            [
                              _c("v-text-field", {
                                class: {
                                  "is-invalid":
                                    _vm.Submitted &&
                                    _vm.$v.template.name.$error,
                                },
                                attrs: { outlined: "", label: "Template Name" },
                                model: {
                                  value: _vm.template.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.template, "name", $$v)
                                  },
                                  expression: "template.name",
                                },
                              }),
                              _vm.Submitted && !_vm.$v.template.name.required
                                ? _c(
                                    "p",
                                    { staticClass: "invalid-feedback mt-2" },
                                    [_vm._v(" Name is Required ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: { outlined: "", label: "Description" },
                                model: {
                                  value: _vm.template.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.template, "description", $$v)
                                  },
                                  expression: "template.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "mb-10" }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "cancel-update-btn",
                      attrs: { text: "" },
                      on: { click: _vm.close },
                    },
                    [_vm._v("Discard")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "create-order-btn mr-1",
                      on: { click: _vm.save },
                    },
                    [_vm._v("Create")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }