var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload-section", attrs: { id: "users" } },
    [
      !_vm.$vuetify.breakpoint.xsOnly ? _c("SideMenu") : _vm._e(),
      _c("div", { staticClass: "user-creation ml-4 mt-4" }, [
        _c(
          "div",
          [
            _c("p", { staticClass: "table-title" }, [_vm._v("Users List")]),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "3" } },
                  [
                    _c("v-text-field", {
                      ref: "objet",
                      attrs: {
                        solo: "",
                        label: "Search... ",
                        "append-icon": "mdi-magnify",
                      },
                      on: {
                        keyup: function ($event) {
                          return _vm.realSearch(1)
                        },
                      },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
                  ],
                  1
                ),
                _c("v-col", { attrs: { cols: "12", md: "3" } }),
                _c("v-col", { attrs: { cols: "12", md: "3" } }),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "3" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn-creation creation-style",
                        attrs: { to: "/users/create" },
                      },
                      [_vm._v("Create User")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("v-data-table", {
              staticClass: "elevation-1",
              attrs: {
                headers: _vm.headers,
                items: _vm.data,
                "item-key": "id",
                "hide-default-footer": "",
                "items-per-page": _vm.pagination.pageSize,
                "show-select": "",
              },
              scopedSlots: _vm._u(
                [
                  _vm.selectedUsers.length != 0
                    ? {
                        key: "top",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "wo-actions" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "border-item",
                                        attrs: { md: "4" },
                                      },
                                      [
                                        _vm.selectedUsers.length > 0
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "btn-creation create-user-btn",
                                                on: {
                                                  click: _vm.sendResetEmails,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Send Reset Password Email"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "border-item",
                                        attrs: { md: "4" },
                                      },
                                      [
                                        _vm.selectedUsers.length > 0
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "btn-creation create-user-btn",
                                                on: {
                                                  click: _vm.sendWelcomeEmail,
                                                },
                                              },
                                              [_vm._v("Send Welcome Email")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { md: "4" } },
                                      [
                                        _c("v-switch", {
                                          staticClass: "ml-3",
                                          attrs: {
                                            inset: "",
                                            label: "Two-factor authentication",
                                            color: "#2A8D3D",
                                          },
                                          model: {
                                            value: _vm.faSwitch,
                                            callback: function ($$v) {
                                              _vm.faSwitch = $$v
                                            },
                                            expression: "faSwitch",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "v-row",
                          { staticClass: "mt-5 fit-width" },
                          [
                            _c(
                              "v-col",
                              { attrs: { md: "2" } },
                              [
                                _c("v-select", {
                                  attrs: {
                                    items: _vm.sizeOptions,
                                    "item-value": "id",
                                    "item-text": "name",
                                    solo: "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.realSearch(1)
                                    },
                                  },
                                  model: {
                                    value: _vm.pagination.pageSize,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.pagination, "pageSize", $$v)
                                    },
                                    expression: "pagination.pageSize",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { md: "5" } },
                              [
                                _c("v-pagination", {
                                  attrs: {
                                    length: _vm.totalPages,
                                    "total-visible": "6",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.realSearch(
                                        _vm.pagination.pageNumber
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.pagination.pageNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.pagination,
                                        "pageNumber",
                                        $$v
                                      )
                                    },
                                    expression: "pagination.pageNumber",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "item.systemUserTypes",
                    fn: function ({ item }) {
                      return _vm._l(item.systemUserTypes, function (ph, index) {
                        return _c("p", { key: index }, [
                          _c("span", [_vm._v(_vm._s(ph.name))]),
                        ])
                      })
                    },
                  },
                  {
                    key: "item.account",
                    fn: function ({ item }) {
                      return [
                        _c("p", [
                          _c("span", [_vm._v(_vm._s(item.account.name))]),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-2 edit-icon",
                            attrs: { small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.editItem(item)
                              },
                            },
                          },
                          [_vm._v(" far fa-edit ")]
                        ),
                        _c(
                          "v-icon",
                          {
                            staticClass: "delete-icon",
                            attrs: { small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteItem(item)
                              },
                            },
                          },
                          [_vm._v(" mdi-delete ")]
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.selectedUsers,
                callback: function ($$v) {
                  _vm.selectedUsers = $$v
                },
                expression: "selectedUsers",
              },
            }),
            _c(
              "v-dialog",
              {
                attrs: { "max-width": "500px" },
                model: {
                  value: _vm.dialogDelete,
                  callback: function ($$v) {
                    _vm.dialogDelete = $$v
                  },
                  expression: "dialogDelete",
                },
              },
              [
                _c(
                  "v-card",
                  [
                    _c("v-card-title", { staticClass: "text-h5" }, [
                      _vm._v("Are you sure you want to delete this item?"),
                    ]),
                    _c(
                      "v-card-actions",
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "blue darken-1", text: "" },
                            on: { click: _vm.closeDelete },
                          },
                          [_vm._v("Cancel")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "blue darken-1", text: "" },
                            on: { click: _vm.deleteItemConfirm },
                          },
                          [_vm._v("OK")]
                        ),
                        _c("v-spacer"),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }