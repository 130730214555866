var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "handle-test", attrs: { id: "report-timsheet" } },
    [
      _c(
        "div",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12", md: "10" } }, [
                _vm.requestBody.isPending
                  ? _c("p", { staticClass: "table-title" }, [
                      _vm._v(" Pending Timesheets "),
                    ])
                  : _c("p", { staticClass: "table-title" }, [
                      _vm._v("Rejected TimeSheets"),
                    ]),
              ]),
              _c("v-col", { attrs: { md: "1", cols: "12" } }),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pad0", attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-row",
                    { staticStyle: { "margin-bottom": "2%" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6", md: "4" } },
                        [
                          _c("p", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/Pending.png"),
                              },
                            }),
                          ]),
                          _c(
                            "v-btn",
                            {
                              staticClass: "filter-btn assigned-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.GetPending()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.jellyBeans.pendingCount))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6", md: "4" } },
                        [
                          _c("p", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/Disapproved.png"),
                              },
                            }),
                          ]),
                          _c(
                            "v-btn",
                            {
                              staticClass: "filter-btn fh-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.GetDisapproved()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.jellyBeans.disApprovedCount))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pad0", attrs: { cols: "12", md: "8" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              multiple: "",
                              outlined: "",
                              label: "Select Clients",
                              items: _vm.clients,
                              "item-text": "name",
                              "item-value": "id",
                            },
                            on: { change: _vm.handleClientFilter },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "v-chip",
                                      _vm._b(
                                        {
                                          attrs: {
                                            "input-value": data.selected,
                                            close: "",
                                          },
                                          on: {
                                            click: data.select,
                                            "click:close": function ($event) {
                                              return _vm.removeClient(
                                                data.item.id
                                              )
                                            },
                                          },
                                        },
                                        "v-chip",
                                        data.attrs,
                                        false
                                      ),
                                      [
                                        _vm._v(
                                          " " + _vm._s(data.item.name) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.clientIds,
                              callback: function ($$v) {
                                _vm.clientIds = $$v
                              },
                              expression: "clientIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-autocomplete", {
                            ref: "accountSearch",
                            attrs: {
                              items: _vm.accounts,
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              outlined: "",
                              label: "Select Accounts",
                              "search-input": _vm.accountsearch,
                            },
                            on: {
                              "update:searchInput": function ($event) {
                                _vm.accountsearch = $event
                              },
                              "update:search-input": function ($event) {
                                _vm.accountsearch = $event
                              },
                              change: _vm.handleAccountFilter,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "v-chip",
                                      _vm._b(
                                        {
                                          attrs: {
                                            "input-value": data.selected,
                                            close: "",
                                          },
                                          on: {
                                            click: data.select,
                                            "click:close": function ($event) {
                                              return _vm.removeAccount(
                                                data.item.id
                                              )
                                            },
                                          },
                                        },
                                        "v-chip",
                                        data.attrs,
                                        false
                                      ),
                                      [
                                        _vm._v(
                                          " " + _vm._s(data.item.name) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.accountIds,
                              callback: function ($$v) {
                                _vm.accountIds = $$v
                              },
                              expression: "accountIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-autocomplete", {
                            ref: "locationSearch",
                            attrs: {
                              items: _vm.locations,
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              outlined: "",
                              label: "Select Locations",
                              "search-input": _vm.locationSearch,
                            },
                            on: {
                              change: _vm.handleLocationFilter,
                              "update:searchInput": function ($event) {
                                _vm.locationSearch = $event
                              },
                              "update:search-input": function ($event) {
                                _vm.locationSearch = $event
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "v-chip",
                                      _vm._b(
                                        {
                                          attrs: {
                                            "input-value": data.selected,
                                            close: "",
                                          },
                                          on: {
                                            click: data.select,
                                            "click:close": function ($event) {
                                              return _vm.removeLocation(
                                                data.item.id
                                              )
                                            },
                                          },
                                        },
                                        "v-chip",
                                        data.attrs,
                                        false
                                      ),
                                      [
                                        _vm._v(
                                          " " + _vm._s(data.item.name) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.locationIds,
                              callback: function ($$v) {
                                _vm.locationIds = $$v
                              },
                              expression: "locationIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pad0", attrs: { cols: "12", md: "4" } },
                [
                  _c("v-text-field", {
                    ref: "objet",
                    attrs: {
                      solo: "",
                      label: "Search ",
                      "append-icon": "mdi-magnify",
                    },
                    on: {
                      keyup: function ($event) {
                        return _vm.getData()
                      },
                    },
                    model: {
                      value: _vm.requestBody.subString,
                      callback: function ($$v) {
                        _vm.$set(_vm.requestBody, "subString", $$v)
                      },
                      expression: "requestBody.subString",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pad0", attrs: { cols: "12", md: "8" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menu",
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.SDFrom,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "300",
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  _vm.SDFrom = $event
                                },
                                "update:return-value": function ($event) {
                                  _vm.SDFrom = $event
                                },
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                hint: "MM/DD/YYYY format",
                                                label: "Creation / Start Date",
                                                "prepend-inner-icon":
                                                  "mdi-calendar",
                                                readonly: "",
                                                outlined: "",
                                              },
                                              on: {
                                                blur: function ($event) {
                                                  _vm.SDFrom = _vm.parseDate(
                                                    _vm.SDFrom
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.dateFormatted,
                                                callback: function ($$v) {
                                                  _vm.dateFormatted = $$v
                                                },
                                                expression: "dateFormatted",
                                              },
                                            },
                                            "v-text-field",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.menuStartDate,
                                callback: function ($$v) {
                                  _vm.menuStartDate = $$v
                                },
                                expression: "menuStartDate",
                              },
                            },
                            [
                              _c(
                                "v-date-picker",
                                {
                                  attrs: { "no-title": "", scrollable: "" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$refs.menu.save(_vm.SDFrom)
                                    },
                                    change: _vm.getTimesheetByDate,
                                  },
                                  model: {
                                    value: _vm.SDFrom,
                                    callback: function ($$v) {
                                      _vm.SDFrom = $$v
                                    },
                                    expression: "SDFrom",
                                  },
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.menuStartDate = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.menu.save(_vm.SDFrom)
                                        },
                                      },
                                    },
                                    [_vm._v(" OK ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menu2",
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.SDTo,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "300",
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  _vm.SDTo = $event
                                },
                                "update:return-value": function ($event) {
                                  _vm.SDTo = $event
                                },
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                hint: "MM/DD/YYYY format",
                                                label: "Creation / End Date",
                                                "prepend-inner-icon":
                                                  "mdi-calendar",
                                                readonly: "",
                                                outlined: "",
                                              },
                                              on: {
                                                blur: function ($event) {
                                                  _vm.SDTo = _vm.parseDate(
                                                    _vm.SDTo
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.dateFormattedEnd,
                                                callback: function ($$v) {
                                                  _vm.dateFormattedEnd = $$v
                                                },
                                                expression: "dateFormattedEnd",
                                              },
                                            },
                                            "v-text-field",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.menuEndDate,
                                callback: function ($$v) {
                                  _vm.menuEndDate = $$v
                                },
                                expression: "menuEndDate",
                              },
                            },
                            [
                              _c(
                                "v-date-picker",
                                {
                                  attrs: { "no-title": "", scrollable: "" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$refs.menu2.save(_vm.SDTo)
                                    },
                                    change: _vm.getTimesheetByDate,
                                  },
                                  model: {
                                    value: _vm.SDTo,
                                    callback: function ($$v) {
                                      _vm.SDTo = $$v
                                    },
                                    expression: "SDTo",
                                  },
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.menuEndDate = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.menu2.save(_vm.SDTo)
                                        },
                                      },
                                    },
                                    [_vm._v(" OK ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-creation diff-style mt-1",
                              attrs: { color: "#D92020" },
                              on: { click: _vm.reset },
                            },
                            [_vm._v("Reset Filter")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              loading: _vm.isLoaded,
              "loading-text": "Loading... Please wait",
              headers: _vm.headers,
              items: _vm.data,
              "item-key": "id",
              "server-items-length": _vm.totalLength,
              "show-select": "",
              "show-expand": "",
              "hide-default-footer": "",
              expanded: _vm.expanded,
            },
            on: {
              "click:row": _vm.rowClick,
              "update:options": _vm.handleSorting,
              "update:expanded": function ($event) {
                _vm.expanded = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "expanded-item",
                fn: function ({ headers, item }) {
                  return [
                    _c(
                      "td",
                      {
                        staticClass: "px-0 py-2",
                        attrs: { colspan: headers.length },
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "plus-padding",
                            attrs: { small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.ShowExpenseDialog(item)
                              },
                            },
                          },
                          [_vm._v(" fa-solid fa-plus ")]
                        ),
                        _c("v-data-table", {
                          staticClass: "elevation-1",
                          attrs: {
                            headers: _vm.timeSheetHeaders,
                            "server-items-length":
                              item.timeSheetExpenses.length,
                            items: item.timeSheetExpenses,
                            "hide-default-footer": "",
                            colspan: 7,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "item.totalHours",
                fn: function ({ item }) {
                  return [
                    _c("p", [
                      _vm._v(" " + _vm._s(item.totalHours + " hr") + " "),
                    ]),
                  ]
                },
              },
              {
                key: "item.totalExpenseHoures",
                fn: function ({ item }) {
                  return [
                    _c("p", [
                      _vm._v(
                        " " + _vm._s(item.totalExpenseHoures + " hr") + " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "item.creationDate",
                fn: function ({ item }) {
                  return [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm
                              .convertUTCDateToLocalDate(
                                new Date(item.creationDate)
                              )
                              .toLocaleString()
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "item.payRate",
                fn: function ({ item }) {
                  return [
                    _c("p", [_vm._v(" " + _vm._s("$ " + item.payRate) + " ")]),
                  ]
                },
              },
              {
                key: "item.totalPayRate",
                fn: function ({ item }) {
                  return [
                    _c("p", [
                      _vm._v(" " + _vm._s("$ " + item.totalPayRate) + " "),
                    ]),
                  ]
                },
              },
              {
                key: "item.totalExpenses",
                fn: function ({ item }) {
                  return [
                    _c("p", [
                      _vm._v(" " + _vm._s("$ " + item.totalExpenses) + " "),
                    ]),
                  ]
                },
              },
              {
                key: "item.billHourlyRate",
                fn: function ({ item }) {
                  return [
                    _c("p", [
                      _vm._v(" " + _vm._s("$ " + item.billHourlyRate) + " "),
                    ]),
                  ]
                },
              },
              {
                key: "footer",
                fn: function () {
                  return [
                    _c(
                      "v-row",
                      { staticClass: "mt-5 fit-width" },
                      [
                        _c(
                          "v-col",
                          { attrs: { md: "2" } },
                          [
                            _c("v-select", {
                              attrs: {
                                items: _vm.sizeOptions,
                                "item-value": "id",
                                "item-text": "name",
                                solo: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.resetPageNumber()
                                },
                              },
                              model: {
                                value: _vm.requestBody.pageSize,
                                callback: function ($$v) {
                                  _vm.$set(_vm.requestBody, "pageSize", $$v)
                                },
                                expression: "requestBody.pageSize",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { md: "5" } },
                          [
                            _c("v-pagination", {
                              attrs: {
                                length: _vm.totalPages,
                                "total-visible": "6",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.getData()
                                },
                              },
                              model: {
                                value: _vm.requestBody.pageNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.requestBody, "pageNumber", $$v)
                                },
                                expression: "requestBody.pageNumber",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.Selection,
              callback: function ($$v) {
                _vm.Selection = $$v
              },
              expression: "Selection",
            },
          }),
        ],
        1
      ),
      _vm.Selection.length > 0
        ? _c(
            "div",
            { staticClass: "btn-container" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "float btn-creation wo-diff-style",
                  on: {
                    click: function ($event) {
                      return _vm.Approval(true)
                    },
                  },
                },
                [_vm._v("Approve All")]
              ),
              !_vm.disapproved
                ? _c(
                    "v-btn",
                    {
                      staticClass: "float2 btn-creation wo-diff-style",
                      attrs: { color: "red" },
                      on: {
                        click: function ($event) {
                          return _vm.Approval(false)
                        },
                      },
                    },
                    [_vm._v(" Disapprove All")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.disapproved && _vm.Selection.length > 0
        ? _c(
            "v-btn",
            {
              staticClass: "float2 btn-creation wo-diff-style",
              attrs: { color: "#a6c8a7 !important" },
              on: {
                click: function ($event) {
                  return _vm.RetrunToPending()
                },
              },
            },
            [_vm._v("Retrun To Pending")]
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700px" },
          model: {
            value: _vm.expenseDialoge,
            callback: function ($$v) {
              _vm.expenseDialoge = $$v
            },
            expression: "expenseDialoge",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v("New Expense"),
              ]),
              _c(
                "v-card-text",
                [
                  _c("br"),
                  _c("v-select", {
                    class: {
                      "is-invalid":
                        _vm.submittedExpense && _vm.$v.expenseTypeId.$error,
                    },
                    attrs: {
                      items: _vm.expensesTypes,
                      "item-text": "name",
                      "item-value": "id",
                      label: "Add Fees, Discount, Tax, ... ",
                      solo: "",
                    },
                    model: {
                      value: _vm.expenseTypeId,
                      callback: function ($$v) {
                        _vm.expenseTypeId = $$v
                      },
                      expression: "expenseTypeId",
                    },
                  }),
                  _vm.submittedExpense && !_vm.$v.expenseTypeId.required
                    ? _c("p", { staticClass: "invalid-feedback mt-2" }, [
                        _vm._v(" Expense Type Is Required "),
                      ])
                    : _vm._e(),
                  _c("br"),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "3", cols: "2" } },
                        [
                          _c("v-select", {
                            class: {
                              "is-invalid":
                                _vm.submittedExpense &&
                                _vm.$v.feild.amountTypeId.$error,
                            },
                            attrs: {
                              solo: "",
                              "item-text": "name",
                              "item-value": "id",
                              items: _vm.amountTypes,
                              label: "$",
                            },
                            model: {
                              value: _vm.feild.amountTypeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.feild, "amountTypeId", $$v)
                              },
                              expression: "feild.amountTypeId",
                            },
                          }),
                          _vm.submittedExpense &&
                          !_vm.$v.feild.amountTypeId.required
                            ? _c(
                                "p",
                                { staticClass: "invalid-feedback mt-2" },
                                [_vm._v(" Amount Type Is Required ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "9", cols: "10" } },
                        [
                          _c("v-text-field", {
                            class: {
                              "is-invalid":
                                _vm.submittedExpense &&
                                _vm.$v.feild.expenseAmount.$error,
                            },
                            attrs: { outlined: "", label: "Amount" },
                            model: {
                              value: _vm.feild.expenseAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.feild, "expenseAmount", $$v)
                              },
                              expression: "feild.expenseAmount",
                            },
                          }),
                          _vm.submittedExpense &&
                          !_vm.$v.feild.expenseAmount.required
                            ? _c(
                                "p",
                                { staticClass: "invalid-feedback mt-2" },
                                [_vm._v(" Amount Is Required ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-text-field", {
                            class: {
                              "is-invalid":
                                _vm.submittedExpense &&
                                _vm.$v.feild.description.$error,
                            },
                            attrs: {
                              placeholder: "Description",
                              label: _vm.feild.name,
                            },
                            model: {
                              value: _vm.feild.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.feild, "description", $$v)
                              },
                              expression: "feild.description",
                            },
                          }),
                          _vm.submittedExpense &&
                          !_vm.$v.feild.description.required
                            ? _c(
                                "p",
                                { staticClass: "invalid-feedback mt-2" },
                                [_vm._v(" Description Is Required ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.addFeild },
                    },
                    [_vm._v("Add")]
                  ),
                  _c("v-spacer"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }