var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { md: "10" } }),
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "float btn-creation wo-diff-style",
                  staticStyle: { "z-index": "1" },
                  on: { click: _vm.createInvoice },
                },
                [_vm._v(" Create Invoice")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "mail-box" }, [
        _vm._m(0),
        _c(
          "div",
          { staticStyle: { "overflow-y": "scroll", height: "74px" } },
          _vm._l(_vm.sendtoarr, function (it) {
            return _c(
              "v-chip",
              {
                key: it.id,
                staticStyle: { margin: "2px" },
                attrs: { close: "" },
                on: {
                  click: _vm.data.select,
                  "click:close": function ($event) {
                    return _vm.RemoveContact(it.id)
                  },
                },
              },
              [_vm._v(" " + _vm._s(it.firstName) + " ")]
            )
          }),
          1
        ),
      ]),
      _c(
        "v-row",
        { staticStyle: { "margin-bottom": "2%" } },
        [
          _c(
            "v-col",
            { staticStyle: { "margin-top": "-2%" }, attrs: { md: "12" } },
            [
              _c("v-autocomplete", {
                ref: "ContactSearch",
                staticStyle: { height: "46px" },
                attrs: {
                  items: _vm.Contacts,
                  "item-text": "firstName",
                  "item-value": "id",
                  multiple: "",
                  outlined: "",
                  placeholder: "Send Email To",
                  "search-input": _vm.ContactSearch,
                },
                on: {
                  "update:searchInput": function ($event) {
                    _vm.ContactSearch = $event
                  },
                  "update:search-input": function ($event) {
                    _vm.ContactSearch = $event
                  },
                  change: (event) => _vm.AddContact(event),
                },
                model: {
                  value: _vm.ContactsIds,
                  callback: function ($$v) {
                    _vm.ContactsIds = $$v
                  },
                  expression: "ContactsIds",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-expansion-panels",
        { staticClass: "expansion-panels-custom mt-5" },
        _vm._l(_vm.data, function (item) {
          return _c(
            "v-expansion-panel",
            { key: item.id },
            [
              _c(
                "v-expansion-panel-header",
                { staticClass: "v-card" },
                [
                  _c(
                    "v-card",
                    { staticClass: "collapse-box" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("span", { staticStyle: { color: "#6276b8" } }, [
                              _c("b", [_vm._v("Master Work OrderId: ")]),
                              _vm._v(_vm._s(item.orderId)),
                            ]),
                          ]),
                          _c("v-col", [
                            _c("span", [
                              _c("b", [_vm._v("Client: ")]),
                              _vm._v(_vm._s(item.clientName)),
                            ]),
                            _vm._v(", "),
                            _c("span", [
                              _c("b", [_vm._v("Account: ")]),
                              _vm._v(_vm._s(item.accountName)),
                            ]),
                            _vm._v(", "),
                            _c("span", [
                              _c("b", [_vm._v("Location: ")]),
                              _vm._v(_vm._s(item.location)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            item.totalProfit < 0
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#aa5e68" } },
                                  [
                                    _c("b", [_vm._v("Profit: ")]),
                                    _c(
                                      "b",
                                      {
                                        staticStyle: {
                                          "font-size": "17px",
                                          "font-family": "'Montserrat'",
                                        },
                                      },
                                      [_vm._v("$ " + _vm._s(item.totalProfit))]
                                    ),
                                  ]
                                )
                              : _c(
                                  "span",
                                  { staticStyle: { color: "green" } },
                                  [
                                    _c("b", [_vm._v("Profit: ")]),
                                    _c(
                                      "b",
                                      {
                                        staticStyle: {
                                          "font-size": "17px",
                                          "font-family": "'Montserrat'",
                                        },
                                      },
                                      [_vm._v("$ " + _vm._s(item.totalProfit))]
                                    ),
                                  ]
                                ),
                          ]),
                          _c("v-col", [
                            _c("span", { staticStyle: { color: "#aa5e68" } }, [
                              _c("b", [_vm._v("Payment Type: ")]),
                              _vm._v(_vm._s(item.paymentTypeName)),
                            ]),
                            _vm._v(", "),
                            _c("span", [
                              _c("b", [_vm._v("Bill Rate: ")]),
                              _vm._v("$ " + _vm._s(item.billRate)),
                            ]),
                            _vm._v(", "),
                            _c("span", [
                              _c("b", [_vm._v("Billable Hours: ")]),
                              _vm._v(_vm._s(item.totalHours) + "hr"),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("span", [
                              _c("b", [_vm._v("Billable Amount: ")]),
                              _vm._v("$ " + _vm._s(item.totalBillRate)),
                            ]),
                            _vm._v(", "),
                            _c("span", [
                              _c("b", [_vm._v("Expenses Amount: ")]),
                              _vm._v("$ " + _vm._s(item.totalExpense)),
                            ]),
                            _vm._v(", "),
                            _c("span", [
                              _c("b", [_vm._v("Payable Amount: ")]),
                              _vm._v(
                                "$ " + _vm._s(item.totalTechniciansPayRates)
                              ),
                            ]),
                          ]),
                          _c("v-col"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel-content",
                _vm._l(item.timeSheets, function (item2) {
                  return _c("div", { key: item2.id }, [
                    _c(
                      "div",
                      { staticClass: "sty-div-expense" },
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-col", [
                              _c(
                                "span",
                                { staticStyle: { color: "#6276b8" } },
                                [
                                  _c("b", [_vm._v("Technician Name: ")]),
                                  _vm._v(_vm._s(item2.technicianName)),
                                ]
                              ),
                            ]),
                            _c("v-col"),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c("v-col", [
                              _c(
                                "span",
                                { staticStyle: { color: "#7cab9a" } },
                                [
                                  _c("b", [_vm._v("Payable Amount: ")]),
                                  _vm._v("$ " + _vm._s(item2.totalPayRate)),
                                ]
                              ),
                            ]),
                            _c("v-col", [
                              _c(
                                "span",
                                { staticStyle: { color: "#7cab9a" } },
                                [
                                  _c("b", [_vm._v("Pay Rate: ")]),
                                  _vm._v("$ " + _vm._s(item2.payRate)),
                                ]
                              ),
                              _vm._v(", "),
                              _c("span", [
                                _c("b", [_vm._v("Expenses Hours ")]),
                                _vm._v("$ " + _vm._s(item2.totalExpenseHoures)),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c("span", [
                          _c("span", [
                            _c("b", [_vm._v("Billable Hours: ")]),
                            _vm._v(_vm._s(item2.totalBillaleHours) + " hr"),
                          ]),
                          _vm._v(", "),
                          _c("span", [
                            _c("b", [_vm._v("TimeSheet Hours: ")]),
                            _vm._v(_vm._s(item2.totalHours) + " hr"),
                          ]),
                          _vm._v(", "),
                          _c("span", [
                            _c("b", [_vm._v("Expenses Amount: ")]),
                            _vm._v(_vm._s(item2.totalExpenses) + " hr"),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c("br"),
                  ])
                }),
                0
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticStyle: { "margin-left": "-38px", "margin-bottom": "1%" } },
      [_c("b", [_vm._v("Send To : ")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }