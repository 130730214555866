var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload-section", attrs: { id: "users" } },
    [
      !_vm.$vuetify.breakpoint.xsOnly ? _c("SideMenu") : _vm._e(),
      _c("div", { staticClass: "user-creation ml-4 mt-4" }, [
        _c("p", { staticClass: "page-label" }, [_vm._v("General Setting")]),
        _c(
          "div",
          [
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { md: "3" } }, [
                  _c("p", { staticClass: "smtp-key" }, [_vm._v("NickName")]),
                  _c("p", { staticClass: "smtp-key fn-mt" }, [
                    _vm._v("From Email Address"),
                  ]),
                  _c("p", { staticClass: "smtp-key fn-mt" }, [
                    _vm._v("From Name"),
                  ]),
                  _c("p", { staticClass: "smtp-key fn-mt" }, [
                    _vm._v("Reply To Email"),
                  ]),
                  _c("p", { staticClass: "smtp-key tn-mt" }, [
                    _vm._v("Reply To Name"),
                  ]),
                  _c("p", { staticClass: "smtp-key fn-mt" }, [
                    _vm._v("Address"),
                  ]),
                ]),
                _c("v-col", { attrs: { md: "3" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.smtp.nickname,
                        expression: "smtp.nickname",
                      },
                    ],
                    staticClass: "smtp-input",
                    class: {
                      "is-invalid-smtp":
                        _vm.submitted && _vm.$v.smtp.nickname.$error,
                    },
                    domProps: { value: _vm.smtp.nickname },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.smtp, "nickname", $event.target.value)
                      },
                    },
                  }),
                  _vm.submitted && !_vm.$v.smtp.nickname.required
                    ? _c("p", { staticClass: "invalid-feedback mt-1" }, [
                        _vm._v(" Nickname is Required "),
                      ])
                    : _vm._e(),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.smtp.from.email,
                        expression: "smtp.from.email",
                      },
                    ],
                    staticClass: "smtp-input",
                    class: {
                      "is-invalid-smtp":
                        _vm.submitted && _vm.$v.smtp.from.email.$error,
                    },
                    domProps: { value: _vm.smtp.from.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.smtp.from, "email", $event.target.value)
                      },
                    },
                  }),
                  !_vm.smtp.from.email.includes("@axapro.com")
                    ? _c("p", { staticClass: "hint" }, [
                        _vm._v(" From Email must end With @axapro.com "),
                      ])
                    : _vm._e(),
                  _vm.submitted && !_vm.$v.smtp.from.email.required
                    ? _c("p", { staticClass: "invalid-feedback mt-1" }, [
                        _vm._v(" Email is Required "),
                      ])
                    : _vm._e(),
                  _vm.submitted && !_vm.$v.smtp.from.email.email
                    ? _c("p", { staticClass: "invalid-feedback mt-1" }, [
                        _vm._v(" Email is Invalid "),
                      ])
                    : _vm._e(),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.smtp.from.name,
                        expression: "smtp.from.name",
                      },
                    ],
                    staticClass: "smtp-input",
                    class: {
                      "is-invalid-smtp":
                        _vm.submitted && _vm.$v.smtp.from.name.$error,
                    },
                    domProps: { value: _vm.smtp.from.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.smtp.from, "name", $event.target.value)
                      },
                    },
                  }),
                  _vm.submitted && !_vm.$v.smtp.from.name.required
                    ? _c("p", { staticClass: "invalid-feedback mt-1" }, [
                        _vm._v(" From Name is Required "),
                      ])
                    : _vm._e(),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.smtp.reply_to.email,
                        expression: "smtp.reply_to.email",
                      },
                    ],
                    staticClass: "smtp-input",
                    class: {
                      "is-invalid-smtp":
                        _vm.submitted && _vm.$v.smtp.reply_to.email.$error,
                    },
                    domProps: { value: _vm.smtp.reply_to.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.smtp.reply_to,
                          "email",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm.submitted && !_vm.$v.smtp.reply_to.email.required
                    ? _c("p", { staticClass: "invalid-feedback mt-1" }, [
                        _vm._v(" Email is Required "),
                      ])
                    : _vm._e(),
                  _vm.submitted && !_vm.$v.smtp.reply_to.email.email
                    ? _c("p", { staticClass: "invalid-feedback mt-1" }, [
                        _vm._v(" Email is Invalid "),
                      ])
                    : _vm._e(),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.smtp.reply_to.name,
                        expression: "smtp.reply_to.name",
                      },
                    ],
                    staticClass: "smtp-input",
                    class: {
                      "is-invalid-smtp":
                        _vm.submitted && _vm.$v.smtp.reply_to.name.$error,
                    },
                    domProps: { value: _vm.smtp.reply_to.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.smtp.reply_to, "name", $event.target.value)
                      },
                    },
                  }),
                  _vm.submitted && !_vm.$v.smtp.reply_to.name.required
                    ? _c("p", { staticClass: "invalid-feedback mt-1" }, [
                        _vm._v(" To Name is Required "),
                      ])
                    : _vm._e(),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.smtp.address,
                        expression: "smtp.address",
                      },
                    ],
                    staticClass: "smtp-input",
                    class: {
                      "is-invalid-smtp":
                        _vm.submitted && _vm.$v.smtp.address.$error,
                    },
                    domProps: { value: _vm.smtp.address },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.smtp, "address", $event.target.value)
                      },
                    },
                  }),
                  _vm.submitted && !_vm.$v.smtp.address.required
                    ? _c("p", { staticClass: "invalid-feedback mt-1" }, [
                        _vm._v(" Address is Required "),
                      ])
                    : _vm._e(),
                ]),
                _c("v-col", { attrs: { md: "3" } }, [
                  _c("p", { staticClass: "smtp-key ml-10" }, [
                    _vm._v("Secondary Address"),
                  ]),
                  _c("p", { staticClass: "smtp-key fn-mt ml-10" }, [
                    _vm._v("Country"),
                  ]),
                  _c("p", { staticClass: "smtp-key fn-mt ml-10" }, [
                    _vm._v("City"),
                  ]),
                  _c("p", { staticClass: "smtp-key fn-mt ml-10" }, [
                    _vm._v("State"),
                  ]),
                  _c("p", { staticClass: "smtp-key fn-mt ml-10" }, [
                    _vm._v("Zip Code"),
                  ]),
                ]),
                _c(
                  "v-col",
                  { attrs: { md: "3" } },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.smtp.address_2,
                          expression: "smtp.address_2",
                        },
                      ],
                      staticClass: "smtp-input",
                      class: {
                        "is-invalid-smtp":
                          _vm.submitted && _vm.$v.smtp.address_2.$error,
                      },
                      domProps: { value: _vm.smtp.address_2 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.smtp, "address_2", $event.target.value)
                        },
                      },
                    }),
                    _vm.submitted && !_vm.$v.smtp.address_2.required
                      ? _c("p", { staticClass: "invalid-feedback mt-1" }, [
                          _vm._v(" Secondary Address is Required "),
                        ])
                      : _vm._e(),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.smtp.country,
                          expression: "smtp.country",
                        },
                      ],
                      staticClass: "smtp-input",
                      class: {
                        "is-invalid-smtp":
                          _vm.submitted && _vm.$v.smtp.country.$error,
                      },
                      domProps: { value: _vm.smtp.country },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.smtp, "country", $event.target.value)
                        },
                      },
                    }),
                    _vm.submitted && !_vm.$v.smtp.country.required
                      ? _c("p", { staticClass: "invalid-feedback mt-1" }, [
                          _vm._v(" Country is Required "),
                        ])
                      : _vm._e(),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.smtp.city,
                          expression: "smtp.city",
                        },
                      ],
                      staticClass: "smtp-input",
                      class: {
                        "is-invalid-smtp":
                          _vm.submitted && _vm.$v.smtp.city.$error,
                      },
                      domProps: { value: _vm.smtp.city },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.smtp, "city", $event.target.value)
                        },
                      },
                    }),
                    _vm.submitted && !_vm.$v.smtp.city.required
                      ? _c("p", { staticClass: "invalid-feedback mt-1" }, [
                          _vm._v(" City is Required "),
                        ])
                      : _vm._e(),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.smtp.state,
                          expression: "smtp.state",
                        },
                      ],
                      staticClass: "smtp-input",
                      class: {
                        "is-invalid-smtp":
                          _vm.submitted && _vm.$v.smtp.state.$error,
                      },
                      domProps: { value: _vm.smtp.state },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.smtp, "state", $event.target.value)
                        },
                      },
                    }),
                    _vm.submitted && !_vm.$v.smtp.state.required
                      ? _c("p", { staticClass: "invalid-feedback mt-1" }, [
                          _vm._v(" State is Required "),
                        ])
                      : _vm._e(),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.smtp.zip,
                          expression: "smtp.zip",
                        },
                      ],
                      staticClass: "smtp-input",
                      class: {
                        "is-invalid-smtp":
                          _vm.submitted && _vm.$v.smtp.zip.$error,
                      },
                      domProps: { value: _vm.smtp.zip },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.smtp, "zip", $event.target.value)
                        },
                      },
                    }),
                    _vm.submitted && !_vm.$v.smtp.zip.required
                      ? _c("p", { staticClass: "invalid-feedback mt-1" }, [
                          _vm._v(" ZIP is Required "),
                        ])
                      : _vm._e(),
                    _c(
                      "v-btn",
                      {
                        staticClass: "create-smtp",
                        on: { click: _vm.createSmtp },
                      },
                      [_vm._v("Create")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }