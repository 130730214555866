<template>
  <div class="outer-page" style="padding-top: 0.5% !important">
    <div class="mb-7">
      <v-row>
        <v-btn
          style="padding-top: 1%; padding-left: 6%"
          text
          color="primary"
          @click="goback"
        >
          Go Back
        </v-btn></v-row
      >
      <v-row class="handle-timesheet" style="margin-bottom: -1%">
        <v-col cols="6" md="9">
          <p class="table-title ml-10 mt-10">
            {{ clockingData.TechName + " Clocking Details" }}
          </p>
        </v-col>
      </v-row>

      <div style="margin-left: 6%">
        <v-row class="handle-timesheet">
          <v-col cols="4" md="4">
            <span>
              <span class="label-key" style="margin-right: 2%">
                Master WorkOrderId :
              </span>

              <span class="label-key" style="margin-right: 2%">
                {{ clockingData.MasterWorkOrderId }}
              </span>
            </span>
          </v-col>
          <v-col cols="4" md="4"> </v-col>
        </v-row>

        <v-row class="handle-timesheet">
          <v-col cols="4" md="4" style="padding-left: 2%">
            <span>
              <span class="label-key" style="margin-right: 2%"> Client : </span>

              <span class="label-key" style="margin-right: 2%">
                {{ clockingData.Client }}
              </span>
            </span>
          </v-col>
          <v-col cols="4" md="4">
            <span>
              <span class="label-key" style="margin-right: 2%">
                Account :
              </span>

              <span class="label-key" style="margin-right: 2%">
                {{ clockingData.Account }}
              </span>
            </span>
          </v-col>
          <v-col cols="4" md="4">
            <span>
              <span class="label-key" style="margin-right: 2%">
                Location :
              </span>

              <span class="label-key" style="margin-right: 2%">
                {{ clockingData.Location }}
              </span>
            </span>
          </v-col>
        </v-row>
        <v-row class="handle-timesheet">
          <v-col style="padding-left: 2%">
            <span>
              <span class="label-key" style="margin-right: 1%">
                Scope Of Work :
              </span>
              <span class="label-key" style="margin-right: 2%">
                hhh
                <!-- {{ clockingData.ScopeOfWork}} -->
              </span>
            </span>
          </v-col>
        </v-row>
        <!-- ============================================================= -->
      </div>
    </div>

    <!-- ============================================================ -->
    <div class="mb-7">
      <div class="top-box">
        <v-row>
          <v-col md="9">
            <p class="clocking-date">
              {{
                convertUTCDateToLocalDate(new Date(item.date))
                  .toLocaleString()
                  .substr(0, 10)
              }}
            </p>
          </v-col>
          <v-col md="3"> </v-col>
        </v-row>
      </div>
      <div class="clocking-box">
        <v-row>
          <v-col md="1" class="resize"> </v-col>
          <v-col md="5">
            <v-text-field
              outlined
              label="Closing Notes"
              v-model="item.scopeOfFinishWork"
              :class="{
                'is-invalid': submitted && $v.item.scopeOfFinishWork.$error,
              }"
            ></v-text-field>
            <p
              v-if="submitted && !$v.item.scopeOfFinishWork.required"
              class="invalid-feedback mt-2"
            >
              Closing Notes Required
            </p>
          </v-col>
          <v-col md="1" id="time-box">
            <vue-timepicker
              @change="calcutateDiffTime"
              v-model="item.startTime"
              manual-input
              hide-dropdown
              :class="{
                'is-invalid': submitted && $v.item.startTime.$error,
              }"
            >
            </vue-timepicker>
            <p
              v-if="submitted && !$v.item.startTime.required"
              class="invalid-feedback"
            >
              startTime is Required
            </p>
          </v-col>
          <v-col md="1" id="time-box">
            <vue-timepicker
              @change="calcutateDiffTime"
              v-model="item.endTime"
              manual-input
              hide-dropdown
              :class="{
                'is-invalid': submitted && $v.item.endTime.$error,
              }"
            ></vue-timepicker>
            <p
              v-if="submitted && !$v.item.startTime.required"
              class="invalid-feedback"
            >
              endTime is Required
            </p>
          </v-col>
          <v-col md="1">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <i
                  class="fas fa-calendar-alt day-icon"
                  v-bind="attrs"
                  v-on="on"
                ></i>
              </template>
              <v-date-picker v-model="item.date" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="item.menu = false">
                  Cancel
                </v-btn>
              </v-date-picker>
            </v-menu>
            <p
              v-if="submitted && !$v.item.date.required"
              class="invalid-feedback"
            >
              date is Required
            </p>
          </v-col>
          <v-col md="2" id="time-box">
            <vue-timepicker
              disabled
              manual-input
              hide-dropdown
              v-model="item.total"
            ></vue-timepicker>
          </v-col>
          <v-col md="1">
            <v-btn
              class="ma-2"
              style="width: 130px; justify-content: flex-start; padding: 0 10px"
              @click="AddClocking()"
              color="primary"
              dark
            >
              Add Clocking

              <v-icon dark right> </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="5"></v-col>
          <v-col>
            <p v-if="VaildationError2" class="invalid-feedback">
              endTime should be greater than start time
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="mb-7" v-for="clock in woClocking" :key="clock.date">
      <div class="top-box">
        <v-row>
          <v-col md="9">
            <p class="clocking-date">
              {{
                convertUTCDateToLocalDate(new Date(clock.date))
                  .toLocaleString()
                  .substr(0, 10)
              }}
            </p>
          </v-col>
          <v-col md="3"> </v-col>
        </v-row>
      </div>
      <div
        v-for="(item, index) in clock.items"
        :key="item.id"
        class="clocking-box"
      >
        <v-row>
          <v-col md="1" class="resize">
            <v-checkbox
              @click="pushClock(item)"
              color="green"
              v-model="item.check"
            ></v-checkbox>
          </v-col>
          <v-col md="5">
            <v-text-field
              outlined
              @blur="UpdateClocking(item)"
              class="description-feild"
              label="Closing Notes"
              v-model="item.scopeOfFinishWork"
            ></v-text-field>
          </v-col>
          <v-col md="1" id="time-box">
            <vue-timepicker
              v-model="item.startTime"
              manual-input
              hide-dropdown
              @blur="UpdateClocking(item)"
            ></vue-timepicker>
          </v-col>
          <v-col md="1" id="time-box">
            <vue-timepicker
              v-model="item.endTime"
              manual-input
              hide-dropdown
              @blur="UpdateClocking(item)"
            ></vue-timepicker>
          </v-col>
          <v-col md="1">
            <v-menu
              :ref="index"
              v-model="item.menu"
              :close-on-content-click="false"
              :return-value.sync="clock.date"
              transition="scale-transition"
              offset-y
              min-width="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <i
                  class="fas fa-calendar-alt day-icon"
                  v-bind="attrs"
                  v-on="on"
                ></i>
              </template>
              <v-date-picker
                v-model="clock.date"
                @input="item.menu = false"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="item.menu = false">
                  Cancel
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="2" id="time-box">
            <vue-timepicker
              disabled
              manual-input
              hide-dropdown
              v-model="item.total"
            ></vue-timepicker>
          </v-col>
          <v-col md="1">
            <v-icon
              @click="deleteItem(item)"
              class="delete-timesheet"
              color="#A23D3D"
              >mdi-delete</v-icon
            ></v-col
          >
        </v-row>
      </div>
    </div>
    <div class="btn-container">
      <v-btn
        v-if="timesheet.length > 0"
        class="create-order-btn float3"
        :to="'/timesheet/' + JSON.stringify(timesheet)"
        >Create Timesheet</v-btn
      >
    </div>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div style="padding: 10px 8px 60px 8px; border-radius: 10px; margin: 12px">
      <h3 class="header-styling" style="font-size: 25px; color: #172450">
        Created Timesheets
      </h3>
      <div v-for="timesheet in timesheets" :key="timesheet.id">
        <div
          class="top-box"
          style="border-top-left-radius: 0; border-top-right-radius: 0"
        >
          <v-row>
            <v-col md="9">
              <p class="clocking-date">
                {{
                  convertUTCDateToLocalDate(new Date(timesheet.created))
                    .toLocaleString()
                    .substr(0, 10)
                }}
              </p>
            </v-col>
            <v-col md="3" style="color: #172450">
              {{
                timesheet.workorderTechnicianClockings.reduce(
                  (total, obj) => total + obj.totalAttend,
                  0
                ) / 60
              }}
              hr
            </v-col>
          </v-row>
        </div>
        <div
          class="clocking-box"
          v-for="item in timesheet.workorderTechnicianClockings"
          :key="item.id"
        >
          <v-row>
            <v-col md="3">
              <p class="clocking-date">
                Closing Notes : {{ item.scopeOfFinishWork }}
              </p>
            </v-col>
            <v-col md="3">
              <p class="clocking-date">
                Clocking In :
                {{
                  new Date(
                    convertUTCDateToLocalDate(
                      new Date(item.clockingInDate)
                    ).toLocaleString()
                  )
                    .getHours()
                    .toString()
                    .padStart(2, "0") +
                  ":" +
                  new Date(
                    convertUTCDateToLocalDate(
                      new Date(item.clockingInDate)
                    ).toLocaleString()
                  )
                    .getMinutes()
                    .toString()
                    .padStart(2, "0")
                }}
              </p>
            </v-col>
            <v-col md="3">
              <p class="clocking-date">
                Clocking Out :
                {{
                  new Date(
                    convertUTCDateToLocalDate(
                      new Date(item.clockingOutDate)
                    ).toLocaleString()
                  )
                    .getHours()
                    .toString()
                    .padStart(2, "0") +
                  ":" +
                  new Date(
                    convertUTCDateToLocalDate(
                      new Date(item.clockingOutDate)
                    ).toLocaleString()
                  )
                    .getMinutes()
                    .toString()
                    .padStart(2, "0")
                }}
              </p>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueTimepicker from "vue2-timepicker";
import { dataService } from "@/services/data-service";
import store from "@/store";
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      clockingData: {
        TechName: "",
        SubWorkOrder: "",
        MasterWorkOrderId: "",
        ScopeOfWork: "",
        Client: "",
        Account: "",
        Location: "",
      },

      VaildationError2: false,

      newItem: {
        workOrderTechId: 0,
        clockIn: "",
        clockOut: "",
        scopeOfFinishWork: "string",
      },
      submitted: false,
      item: {
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        endTime: {},
        scopeOfFinishWork: "",
        startTime: {},
      },
      clock: "",
      dialogDelete: false,
      menu: false,
      woId: this.$route.params.woId,
      woClocking: [],
      itemID: null,
      selected: [],
      timesheet: [],
      updateDto: {
        id: 0,
        clockIn: "",
        clockOut: "",
        scopeOfFinishWork: "string",
      },
      timesheets: [],
    };
  },
  methods: {
    convertUTCDateToLocalDate(date) {
      var newDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60 * 1000
      );

      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();

      newDate.setHours(hours - offset);

      return newDate;
    },
    UpdateClocking(item) {
      let end = item.endTime + ":00";
      let clockOut = new Date(item.clockOut.split("T")[0] + " " + end);
      let start = item.startTime + ":00";

      let clockIn = new Date(item.clockIn.split("T")[0] + " " + start);
      this.updateDto.id = item.id;
      this.updateDto.clockIn = clockIn;
      this.updateDto.clockOut = clockOut;
      this.updateDto.scopeOfFinishWork = item.scopeOfFinishWork;

      dataService.update(
        "api/WorkOrders/update-clocking",
        this.updateDto,
        () => {
          //console.log(response);

          this.getClocking();
        }
      );
    },
    AddClocking() {
      //map item to the newitem formate
      this.submitted = true;
      this.$v.item.$touch();
      if (
        this.item.endTime.HH < this.item.startTime.HH ||
        (this.item.endTime.HH === this.item.startTime.HH &&
          this.item.endTime.mm < this.item.startTime.mm)
      ) {
        this.VaildationError2 = true;
        return;
      }

      if (this.$v.item.$invalid) {
        return;
      } else {
        this.item.endTime.HH =
          this.item.endTime.HH && this.item.endTime.HH != ""
            ? this.item.endTime.HH
            : "00";
        this.item.endTime.mm =
          this.item.endTime.mm && this.item.endTime.mm != ""
            ? this.item.endTime.mm
            : "00";
        this.item.startTime.HH =
          this.item.startTime.HH && this.item.startTime.HH != ""
            ? this.item.startTime.HH
            : "00";
        this.item.startTime.mm =
          this.item.startTime.mm && this.item.startTime.mm != ""
            ? this.item.startTime.mm
            : "00";

        let end = this.item.endTime.HH + ":" + this.item.endTime.mm + ":00";
        let clockOut = new Date(this.item.date + " " + end);
        let start =
          this.item.startTime.HH + ":" + this.item.startTime.mm + ":00";

        let clockIn = new Date(this.item.date + " " + start);
        this.newItem.workOrderTechId = this.$route.params.woId;
        this.newItem.clockIn = clockIn;
        this.newItem.clockOut = clockOut;
        this.newItem.scopeOfFinishWork = this.item.scopeOfFinishWork;
        this.item.date = new Date(this.item.date);

        //
        dataService.create("api/WorkOrders/clocking", this.newItem, () => {
          //console.log(response);
          (this.item.date = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10)),
            (this.item.scopeOfFinishWork = "");
          this.item.endTime.HH = "00";
          this.item.endTime.mm = "00";
          this.item.startTime.HH = "00";
          this.item.startTime.mm = "00";
          this.submitted = false;

          this.getClocking();
        });
      }
      // /WorkOrders/update-clocking
    },
    addHoursToDate(objDate, intHours) {
      var numberOfMlSeconds = objDate.getTime();
      var addMlSeconds = intHours * 60 * 60 * 1000;
      var newDateObj = new Date(numberOfMlSeconds + addMlSeconds);

      return newDateObj;
    },
    pushClock(item) {
      if (item.check == true) {
        this.timesheet.push(item);
      } else {
        var removeIndex = this.timesheet
          .map((item) => item.id)
          .indexOf(item.id);

        ~removeIndex && this.timesheet.splice(removeIndex, 1);
      }
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteItem(item) {
      this.itemID = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      dataService.postDelete(
        "api/WorkOrders/delete_clocking_record/" + this.itemID,

        (response) => {
          if (response.data.succeeded) {
            store.commit("SHOW_MESSAGE", {
              text: "Deleted Successfully",
              color: "success",
              timeout: 3000,
            });
            this.dialogDelete = false;
            this.getClocking();
          }
        }
      );
    },
    calcutateDiffTime() {
      this.VaildationError2 = false;

      if (
        this.item.endTime.HH < this.item.startTime.HH ||
        (this.item.endTime.HH === this.item.startTime.HH &&
          this.item.endTime.mm < this.item.startTime.mm)
      ) {
        // this.VaildationError2 = false;
        this.VaildationError2 = true;
      } else {
        if (this.item.endTime.HH || this.item.endTime.mm) {
          let start =
            this.item.startTime.HH + ":" + this.item.startTime.mm + ":00";
          let end = this.item.endTime.HH + ":" + this.item.endTime.mm + ":00";
          let clockIn = new Date(this.item.date + " " + start);
          let clockout = new Date(this.item.date + " " + end);
          var diff = clockout.getTime() - clockIn.getTime();
          var hours = Math.floor(diff / 3.6e6);
          if (hours < 10) {
            hours = "0" + hours;
          }

          var minutes = Math.floor((diff % 3.6e6) / 6e4);
          if (minutes < 10) {
            minutes = "0" + minutes;
          }

          var duration = hours + ":" + minutes;
          this.item.total = duration;
        }
      }
    },
    goback() {
      this.$router.push("/show-work-order/" + localStorage.WorkOrderId);
    },
    getClocking() {
      localStorage.setItem("WOID", this.woId);
      dataService.getAll(
        "api/WorkOrders/get_tec_clocking/" + this.woId,
        (response) => {
          var tempClocking = response.data;
          const groups = tempClocking.reduce((groups, game) => {
            const date = game.clockIn.split("T")[0];
            if (!groups[date]) {
              groups[date] = [];
            }
            groups[date].push(game);
            return groups;
          }, {});

          // Edit: to add it in the array format instead
          const groupArrays = Object.keys(groups).map((date) => {
            return {
              date,
              items: groups[date],
            };
          });
          this.woClocking = groupArrays;
          this.getTimeSheets();
          for (var w = 0; w < this.woClocking.length; w++) {
            for (var c = 0; c < this.woClocking[w].items.length; c++) {
              var tempTime = new Date(this.woClocking[w].items[c].clockIn);
              tempTime = this.convertUTCDateToLocalDate(tempTime);
              var tempTime2 = new Date(this.woClocking[w].items[c].clockOut);
              tempTime2 = this.convertUTCDateToLocalDate(tempTime2);
              var startHours = tempTime.getHours();
              var startMins = tempTime.getMinutes();

              if (startHours < 10) {
                startHours = "0" + startHours;
              }
              if (startMins < 10) {
                startMins = "0" + startMins;
              }

              var endHours = tempTime2.getHours();
              var endMins = tempTime2.getMinutes();

              if (endHours < 10) {
                endHours = "0" + endHours;
              }
              if (endMins < 10) {
                endMins = "0" + endMins;
              }

              this.woClocking[w].items[c].startTime =
                startHours + ":" + startMins;
              this.woClocking[w].items[c].endTime = endHours + ":" + endMins;
              var diff = tempTime2 - tempTime;
              var hours = Math.floor(diff / 3.6e6);
              if (hours < 10) {
                hours = "0" + hours;
              }

              var minutes = Math.floor((diff % 3.6e6) / 6e4);
              if (minutes < 10) {
                minutes = "0" + minutes;
              }

              var seconds = Math.floor((diff % 6e4) / 1000);
              if (seconds < 10) {
                seconds = "0" + seconds;
              }
              var duration = hours + ":" + minutes + ":" + seconds;
              this.woClocking[w].items[c].total = duration;
              this.woClocking[w].items[c].menu = false;
              this.woClocking[w].items[c].check = false;
            }
          }
        }
      );
    },
    getTimeSheets() {
      localStorage.setItem("WOID", this.woId);
      dataService.getAll(
        "api/WorkOrders/GetTimeSheets/" + this.woId,
        (response) => {
          this.timesheets = response.data;
          console.log(this.timesheets);
        }
      );
    },

    diff_mins(dt2, dt1) {
      var diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60;
      return Math.abs(Math.round(diff));
    },
  },
  validations: {
    item: {
      date: { required },
      endTime: { required },
      scopeOfFinishWork: { required },
      startTime: { required },
    },
  },

  mounted() {
    this.getClocking();
    this.clockingData.TechName = localStorage.TechName;

    this.clockingData.SubWorkOrder = localStorage.SubWorkOrder;
    this.clockingData.MasterWorkOrderId = localStorage.MasterWorkOrderId;
    this.clockingData.ScopeOfWork = localStorage.ScopeOfWork;
    this.clockingData.Client = localStorage.Client;
    this.clockingData.Account = localStorage.Account;
    this.clockingData.Location = localStorage.Location;
  },
  components: {
    VueTimepicker,
  },
};
</script>
<style>
.header-styling {
  margin: 20px 35px;
}
</style>