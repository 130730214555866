<template>
  <div id="work-order" class="handle-test">
    <v-row>
      <v-col cols="12" md="10">
        <p class="table-title">Client List</p>
      </v-col>

      <v-col md="1" cols="12">
        <v-btn v-if="can(20)" to="/clients/create" class="btn-creation wo-diff-style" color="#D92020">Create Client
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4" class="pad0">
        <v-text-field solo label="Search Client " append-icon="mdi-magnify" type="text" v-model.trim="search"
          @keyup="realSearch(1)" ref="objet"></v-text-field>
      </v-col>

      <v-col cols="12" md="8" class="pad0"> </v-col>
    </v-row>

    <div>
      <v-data-table :loading="isLoaded" loading-text="Loading... Please wait" :headers="headers" :items="data"
        class="elevation-1" item-key="name" hide-default-footer :items-per-page="pagination.pageSize"
        @update:options="handleOptionsUpdate">
        <template v-slot:footer>
          <v-row class="mt-5 fit-width">
            <v-col md="2">
              <v-select :items="sizeOptions" v-model="pagination.pageSize" :item-value="'id'" :item-text="'name'"
                @change="realSearch(1)" solo></v-select>
            </v-col>
            <v-col md="5">
              <v-pagination v-model="pagination.pageNumber" :length="totalPages"
                @input="realSearch(pagination.pageNumber)" total-visible="6"></v-pagination>
            </v-col>
          </v-row>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Clients</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="700px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Edit Client</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col md="12">
                        <v-text-field tabindex="1" outlined v-model="client.name" :class="{
                          'is-invalid': submitted && $v.client.name.$error,
                        }" label="Full Name"></v-text-field>
                        <p v-if="submitted && !$v.client.name.required" class="invalid-feedback mt-2">
                          Name is Required
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field tabindex="2" outlined class="mb-3" :set="(v = $v.client.clientPhones.$each[0])"
                          v-model="primary.phoneNumber" @blur="formatPhoneNumber(1)" :class="{
                            'is-invalid': submitted && v.phoneNumber.$error,
                          }" label="Primary Phone"></v-text-field>
                        <p v-if="submitted && !v.phoneNumber.required" class="invalid-feedback">
                          Primary Phone is Required
                        </p>

                        <v-text-field tabindex="4" outlined class="mb-3" :class="{
                          'is-invalid': submitted && $v.client.email.$error,
                        }" v-model="client.email" label="Email"></v-text-field>
                        <p v-if="submitted && !$v.client.email.required" class="invalid-feedback">
                          Email is Required
                        </p>
                        <p v-if="submitted && !$v.client.email.email" class="invalid-feedback">
                          Email is not valid
                        </p>

                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field tabindex="3" outlined class="mb-3" label="Secondary Phone"
                          v-model="Secondry.phoneNumber" @blur="formatPhoneNumber(2)"></v-text-field>

                      </v-col>
                    </v-row>
                    <v-divider class="mb-10"></v-divider>
                    <div>

                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field tabindex="5" outlined class="mb-3" :class="{
                            'is-invalid':
                              submitted && $v.client.address.street.$error,
                          }" v-model="client.address.street" label="Street"></v-text-field>
                          <p v-if="
                            submitted && !$v.client.address.street.required
                          " class="invalid-feedback">
                            Street is Required
                          </p>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field tabindex="6" :class="{
                            'is-invalid':
                              submitted &&
                              $v.client.address.postalCode.$error,
                          }" v-model="client.address.postalCode" outlined class="mb-3" label="ZIP / Postal Code">
                          </v-text-field>
                          <p v-if="
                            submitted &&
                            !$v.client.address.postalCode.required
                          " class="invalid-feedback">
                            Postal Code is Required
                          </p>

                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field tabindex="7" v-model="client.address.details" outlined class="mb-3"
                            label="Details">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6"></v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="6">

                          <v-autocomplete tabindex="8" :items="countries" :item-text="'name'" :item-value="'id'"
                            label="Country" outlined v-model="client.address.country.id" class="mb-3" :class="{
                              'is-invalid':
                                submitted &&
                                $v.client.address.country.id.$error,
                            }">
                          </v-autocomplete>
                          <p v-if="
                            submitted && !$v.client.address.countryId.required
                          " class="invalid-feedback">
                            Country is Required
                          </p>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete tabindex="9" :items="states" :item-text="'name'" :item-value="'id'"
                            label="State" outlined v-model="client.address.state.id" class="mb-3" :class="{
                              'is-invalid':
                                submitted && $v.client.address.state.id.$error,
                            }">
                          </v-autocomplete>
                          <p v-if="
                            submitted && !$v.client.address.state.id.required
                          " class="invalid-feedback">
                            State is Required
                          </p>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="6">
                          <v-autocomplete tabindex="10" :items="cities" :item-text="'name'" :item-value="'id'"
                            label="City" outlined class="mb-3" :class="{
                              'is-invalid':
                                submitted && $v.client.address.city.id.$error,
                            }" v-model="client.address.city.id">
                          </v-autocomplete>
                          <p v-if="
                            submitted && !$v.client.address.city.id.required
                          " class="invalid-feedback">
                            City is Required
                          </p>
                        </v-col>
                        <v-col cols="12" md="6"></v-col>
                      </v-row>

                      <!-- <v-row>
                        <v-col cols="12" md="6">
                        </v-col>
                        <v-col cols="12" md="6"> -->
                      <!-- <v-text-field
                            :class="{
                              'is-invalid':
                                submitted &&
                                $v.client.address.buildingNumber.$error,
                            }"
                            v-model="client.address.buildingNumber"
                            outlined
                            class="mb-3"
                            label="Building Number"
                          >
                          </v-text-field>
                          <p
                            v-if="
                              submitted &&
                              !$v.client.address.buildingNumber.required
                            "
                            class="invalid-feedback"
                          >
                            Building Number is Required
                          </p> -->
                      <!-- </v-col>
                      </v-row> -->

                    </div>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn tabindex="11" text class="cancel-update-btn" @click="close">Cancel</v-btn>

                  <v-btn tabindex="12" class="create-order-btn mr-1" @click="save">Update</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            {{ snackText }}

            <template v-slot:action="{ attrs }">
              <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
            </template>
          </v-snackbar>
        </template>
        <template v-slot:item.clientPhones="{ item }">
          <p v-for="(ph, index) in item.clientPhones" :key="index">
            <span :class="{
              'is-Primary': ph.isPrimary,
            }">{{ ph.phoneNumber }}</span>
          </p>
        </template>

        <template v-slot:item.actions="{ item }">

          <v-icon v-if="can(21)" small class="mr-2 edit-icon" @click="editItem(item)">
            far fa-edit
          </v-icon>
          <v-icon v-if="can(21)" class="delete-icon" small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { dataService } from "@/services/data-service";
import { required, email } from "vuelidate/lib/validators";


export default {
  data() {
    return {
      isprimary: 1,
      primary: {
        phoneNumber: "",
        isPrimary: true,
      },
      Secondry: {
        phoneNumber: "",
        isPrimary: false,
      },
      isLoaded: true,
      snack: false,
      snackColor: "",
      snackText: "",
      columnIndex: 0,
      isDec: false,
      sizeOptions: [
        {
          id: 10,
          name: 10,
        },
        {
          id: 20,
          name: 20,
        },
        {
          id: 30,
          name: 30,
        },
        {
          id: 50,
          name: 50,
        },
        {
          id: 100,
          name: 100,
        },
      ],
      totalPages: 0,
      pagination: {
        pageNumber: 1,
        pageSize: 10,
      },
      checkbox: false,
      search: null,
      items: ["foo", "bar", "fizz", "buzz"],
      value: null,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Email", value: "email" },

        { text: "Phone Number", value: "clientPhones" },
        { text: "Country", value: "country" },
        { text: "State", value: "state" },

        { text: "City", value: "city" },

        { text: "ZIP code ", value: "postalCode" },

        { text: "Actions", value: "actions" },
      ],
      client: {
        name: "",

        email: "",
        userTypeId: 2,
        address: {
          street: "",
          buildingNumber: "",
          postalCode: "",
          details: "",
          state: {
            id: null,
          },
          country: {
            id: null,
          },
          city: {
            id: null,
          },
        },
        clientPhones: [
          {
            phoneNumber: "",
            isPrimary: true,
          },
        ],
      },
      data: [],
      editedIndex: null,
      countries: [],
      cities: [],
      levels: [],
      states: [],
      submitted: false,
      secondaryPhone: "",
      dialog: false,
      dialogDelete: false,
    };
  },
  validations: {
    client: {
      name: { required },

      email: { required, email },

      address: {
        street: { required },

        postalCode: { required },
        stateId: { required },
        countryId: { required },
        cityId: { required },
      },
      clientPhones: {
        $each: {
          phoneNumber: { required },
        },
      },
    },
  },
  methods: {
    formatPhoneNumber(isprimary) {
      if (isprimary == 1) {

        var cleanedprimaryphonenumber = ('' + this.primary.phoneNumber).replace(/\D/g, '');
        var matchprimaryphonenumber = cleanedprimaryphonenumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (matchprimaryphonenumber) {
          var intlCodeprimaryphonenumber = (matchprimaryphonenumber[1] ? '+1 ' : '');
          this.primary.phoneNumber = [intlCodeprimaryphonenumber, '(', matchprimaryphonenumber[2], ') ', matchprimaryphonenumber[3], '-', matchprimaryphonenumber[4]].join('');
        }
      }
      else {

        var cleanedsecondryphonenumber = ('' + this.Secondry.phoneNumber).replace(/\D/g, '');
        var matchsecondryphonenumber = cleanedsecondryphonenumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (matchsecondryphonenumber) {
          var intlCodesecondryphonenumber = (matchsecondryphonenumber[1] ? '+1 ' : '');
          this.Secondry.phoneNumber = [intlCodesecondryphonenumber, '(', matchsecondryphonenumber[2], ') ', matchsecondryphonenumber[3], '-', matchsecondryphonenumber[4]].join('');
        }
      }

    },
    //======================================
    deleteItem(item) {
      this.editedIndex = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      dataService.postDelete(
        "api/Clients/delete/" + this.editedIndex,
        () => {
         
            this.dialogDelete = false;
            this.realSearch(1);
          
        }
      );
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    handleOptionsUpdate({ sortBy, sortDesc }) {
      if (sortBy.length > 0) {
        if (sortBy[0] == "name") this.columnIndex = 0;
        if (sortBy[0] == "email") this.columnIndex = 1;
        if (sortBy[0] == "country") this.columnIndex = 3;
        if (sortBy[0] == "city") this.columnIndex = 4;
        if (sortBy[0] == "state") this.columnIndex = 5;
        if (sortBy[0] == "postalCode") this.columnIndex = 6;

        this.isDec = sortDesc[0];

        this.realSearch(1);
      }
    },

    realSearch(size) {
      // clear timeout variable

      this.isLoaded = true;
      var self = this;
      self.pagination.pageNumber = size;
      dataService.getList(
        "api/Clients/search_by_name",
        {
          pageNumber: size,
          pageSize: self.pagination.pageSize,
          subString: self.search,
          sortedColumnIndex: self.columnIndex,
          isDec: self.isDec,
        },
        (response) => {
          this.isLoaded = false;
          self.data = response.data.data.items;
          for (var i = 0; i < self.data.length; i++) {
            self.data[i]["state"] = self.data[i].address.state
              ? self.data[i].address.state.name
              : "";
            self.data[i]["country"] = self.data[i].address.country
              ? self.data[i].address.country.name
              : "";
            self.data[i]["city"] = self.data[i].address.city
              ? self.data[i].address.city.name
              : "";

            self.data[i]["postalCode"] = self.data[i].address.postalCode;
          }
          self.$nextTick(() => self.$refs.objet.focus());
          self.totalPages = response.data.data.totalPages;
        }
      );
    },

    editItem(item) {
      this.getCountries();
      this.getCities();
      this.getSataes();
      this.editedIndex = item.id;
      dataService.getAll("api/Clients/" + item.id, (response) => {
        this.client = response.data;


        if (this.client.address.country == null) {
          this.client.address.country = {
            id: null,
          };
        }
        if (this.client.address.state == null) {
          this.client.address.state = {
            id: null,
          };
        }
        if (this.client.address.city == null) {
          this.client.address.city = {
            id: null,
          };
        }

        this.client.clientPhones.forEach((element) => {
          if (element.isPrimary) {
            this.primary = element;
          } else {
            this.Secondry = element;
          }
        });



        this.dialog = true;
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    getCountries() {
      dataService.getAll("/api/Countries", (response) => {
        this.countries = response.data;
      });
    },
    getCities() {
      dataService.getAll("api/Cities", (response) => {
        this.cities = response.data;
      });
    },
    getSataes() {
      dataService.getAll("api/States", (response) => {
        this.states = response.data;
      });
    },
    save() {
      this.client.address.cityId = this.client.address.city.id;
      let ClientPhones = [];
      ClientPhones.push(this.primary);

      ClientPhones.push(this.Secondry);
      this.client.clientPhones = ClientPhones;
      dataService.update(
        "api/Clients/update",
        { client: this.client },
        (response) => {
          if (response.data.succeeded) {
            this.dialog = false;
            this.snack = true;
            this.snackColor = "success";
            this.snackText = "Data Updated";
            this.realSearch(1);
          }
        }
      );

      // this.submitted = true;
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   return;
      // } else {

      // }
    },
  },
  mounted() {
    this.realSearch(1);
  },
};
</script>