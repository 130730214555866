<template>
  <div class="login-container">
    <div>
      <img class="axa-logo" src="@/assets/images/AXA LOGO TRANPARENT.png" />
    </div>
    <div class="login-box">
      <v-row>
        <v-col cols="12" md="6">
          <div class="st-box" id="login-feild">
            <p class="login-text">Authenticate Your Account</p>
            <v-text-field
              :class="{ 'is-invalid': submitted && $v.code.$error }"
              v-model="code"
              solo
              label="Please Enter your verification code"
              v-on:keyup.enter="handleSubmit"
            >
            </v-text-field>
            <p
              v-if="submitted && !$v.code.required"
              class="invalid-feedback mt-3"
            >
              Verification Code is Required
            </p>

            <v-btn @click="handleSubmit()" class="signin-btn">Verify</v-btn>
          </div>
        </v-col>
        <v-col class="rspnsv-hide" md="6">
          <img class="nd-box" src="@/assets/images/work.png" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      loading:false,
      invalidData: false,
      submitted: false,
      code: "",
    };
  },
  validations: {
    code: {
      required,
    },
  },
  methods: {
    handleSubmit() {
      this.submitted = true;
      const code = this.code;
      const email = localStorage.getItem("verificationEmail");
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
      
        this.$store
          .dispatch("AuthModule/signIn", {
            email,
            IsTwoFA: true,
            TwoFACode: code,
          })
          .catch((err) => {
            if (err) {
              this.invalidData = err.message;
            }
          });

        // this.$router.push("dashboard");
      }
    },
  },
  computed: {
    isLoggining() {
      return this.$store.getters.authStatus;
    },
  },
};
</script>