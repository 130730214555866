var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "handle-test" }, [
    _c("p", { staticClass: "table-title" }, [_vm._v("New Technician")]),
    _c(
      "div",
      { staticClass: "innner-container" },
      [
        _c(
          "v-row",
          [
            _c("v-col", { attrs: { md: "10" } }),
            _c(
              "v-col",
              { attrs: { md: "2" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "resetWO-btn",
                    on: { click: _vm.emptyButtons },
                  },
                  [
                    _c("i", { staticClass: "fas fa-redo" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-1" }, [_vm._v("Reset")]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [
                _c("v-text-field", {
                  staticClass: "mb-3",
                  class: {
                    "is-invalid": _vm.submitted && _vm.$v.tech.firstName.$error,
                  },
                  attrs: { tabindex: "1", outlined: "", label: "First Name" },
                  model: {
                    value: _vm.tech.firstName,
                    callback: function ($$v) {
                      _vm.$set(_vm.tech, "firstName", $$v)
                    },
                    expression: "tech.firstName",
                  },
                }),
                _vm.submitted && !_vm.$v.tech.firstName.required
                  ? _c("p", { staticClass: "invalid-feedback" }, [
                      _vm._v(" First Name is Required "),
                    ])
                  : _vm._e(),
                _c("v-text-field", {
                  staticClass: "mb-3",
                  class: {
                    "is-invalid": _vm.submitted && _vm.$v.tech.email.$error,
                  },
                  attrs: { tabindex: "3", outlined: "", label: "Email" },
                  model: {
                    value: _vm.tech.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.tech, "email", $$v)
                    },
                    expression: "tech.email",
                  },
                }),
                _vm.submitted && !_vm.$v.tech.email.required
                  ? _c("p", { staticClass: "invalid-feedback" }, [
                      _vm._v(" Email is Required "),
                    ])
                  : _vm._e(),
                _vm.submitted && !_vm.$v.tech.email.email
                  ? _c("p", { staticClass: "invalid-feedback" }, [
                      _vm._v(" Email is not valid "),
                    ])
                  : _vm._e(),
                _c("v-text-field", {
                  staticClass: "mb-3",
                  attrs: {
                    tabindex: "5",
                    outlined: "",
                    label: "Secondary Phone",
                  },
                  on: {
                    blur: function ($event) {
                      return _vm.formatPhoneNumber(2)
                    },
                  },
                  model: {
                    value: _vm.secondaryPhone,
                    callback: function ($$v) {
                      _vm.secondaryPhone = $$v
                    },
                    expression: "secondaryPhone",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [
                _c("v-text-field", {
                  staticClass: "mb-3",
                  class: {
                    "is-invalid": _vm.submitted && _vm.$v.tech.lastName.$error,
                  },
                  attrs: { tabindex: "2", outlined: "", label: "Last Name" },
                  model: {
                    value: _vm.tech.lastName,
                    callback: function ($$v) {
                      _vm.$set(_vm.tech, "lastName", $$v)
                    },
                    expression: "tech.lastName",
                  },
                }),
                _vm.submitted && !_vm.$v.tech.lastName.required
                  ? _c("p", { staticClass: "invalid-feedback" }, [
                      _vm._v(" Last Name is Required "),
                    ])
                  : _vm._e(),
                _c("v-text-field", {
                  staticClass: "mb-3",
                  class: {
                    "is-invalid": _vm.submitted && _vm.v.phoneNumber.$error,
                  },
                  attrs: {
                    tabindex: "4",
                    outlined: "",
                    set: (_vm.v = _vm.$v.tech.technicianPhones.$each[0]),
                    label: "Primary Phone",
                  },
                  on: {
                    blur: function ($event) {
                      return _vm.formatPhoneNumber(1)
                    },
                  },
                  model: {
                    value: _vm.tech.technicianPhones[0].phoneNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.tech.technicianPhones[0], "phoneNumber", $$v)
                    },
                    expression: "tech.technicianPhones[0].phoneNumber",
                  },
                }),
                _vm.submitted && !_vm.v.phoneNumber.required
                  ? _c("p", { staticClass: "invalid-feedback" }, [
                      _vm._v(" Primary Phone is Required "),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c("v-divider", { staticClass: "mb-10" }),
        _c(
          "div",
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c("v-text-field", {
                      staticClass: "mb-3",
                      attrs: { tabindex: "6", outlined: "", label: "Street" },
                      model: {
                        value: _vm.tech.address.street,
                        callback: function ($$v) {
                          _vm.$set(_vm.tech.address, "street", $$v)
                        },
                        expression: "tech.address.street",
                      },
                    }),
                    _c("v-text-field", {
                      staticClass: "mb-3",
                      attrs: { tabindex: "8", outlined: "", label: "Details" },
                      model: {
                        value: _vm.tech.address.details,
                        callback: function ($$v) {
                          _vm.$set(_vm.tech.address, "details", $$v)
                        },
                        expression: "tech.address.details",
                      },
                    }),
                    _c("v-autocomplete", {
                      staticClass: "mb-3",
                      attrs: {
                        tabindex: "10",
                        items: _vm.states,
                        "item-text": "name",
                        "item-value": "id",
                        label: "State",
                        outlined: "",
                      },
                      on: { change: _vm.getCities },
                      model: {
                        value: _vm.tech.address.stateId,
                        callback: function ($$v) {
                          _vm.$set(_vm.tech.address, "stateId", $$v)
                        },
                        expression: "tech.address.stateId",
                      },
                    }),
                    _c("v-autocomplete", {
                      staticClass: "mb-3",
                      class: {
                        "is-invalid":
                          _vm.submitted && _vm.$v.tech.levelId.$error,
                      },
                      attrs: {
                        tabindex: "12",
                        items: _vm.levels,
                        "item-text": "name",
                        "item-value": "id",
                        label: "Level",
                        outlined: "",
                      },
                      model: {
                        value: _vm.tech.levelId,
                        callback: function ($$v) {
                          _vm.$set(_vm.tech, "levelId", $$v)
                        },
                        expression: "tech.levelId",
                      },
                    }),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { md: "9" } }, [
                          _vm.submitted && !_vm.$v.tech.levelId.required
                            ? _c(
                                "p",
                                { staticClass: "invalid-feedback ml-3" },
                                [_vm._v(" Level is Required ")]
                              )
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                    _c("v-text-field", {
                      staticClass: "mb-3",
                      class: {
                        "is-invalid":
                          _vm.submitted && _vm.$v.tech.hourlyRate.$error,
                      },
                      attrs: {
                        tabindex: "14",
                        outlined: "",
                        label: "Hourly Rate",
                      },
                      model: {
                        value: _vm.tech.hourlyRate,
                        callback: function ($$v) {
                          _vm.$set(_vm.tech, "hourlyRate", $$v)
                        },
                        expression: "tech.hourlyRate",
                      },
                    }),
                    _vm.submitted && !_vm.$v.tech.hourlyRate.required
                      ? _c("p", { staticClass: "invalid-feedback" }, [
                          _vm._v(" Hourly Rate is Required "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c("v-text-field", {
                      staticClass: "mb-3",
                      attrs: {
                        tabindex: "7",
                        outlined: "",
                        label: "ZIP / Postal Code",
                      },
                      model: {
                        value: _vm.tech.address.postalCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.tech.address, "postalCode", $$v)
                        },
                        expression: "tech.address.postalCode",
                      },
                    }),
                    _c("v-autocomplete", {
                      staticClass: "mb-3",
                      attrs: {
                        tabindex: "9",
                        items: _vm.countries,
                        "item-text": "name",
                        "item-value": "id",
                        label: "Country",
                        outlined: "",
                      },
                      on: { change: _vm.getStates },
                      model: {
                        value: _vm.tech.address.countryId,
                        callback: function ($$v) {
                          _vm.$set(_vm.tech.address, "countryId", $$v)
                        },
                        expression: "tech.address.countryId",
                      },
                    }),
                    _c("v-autocomplete", {
                      staticClass: "mb-3",
                      attrs: {
                        tabindex: "11",
                        items: _vm.cities,
                        "item-text": "name",
                        "item-value": "id",
                        label: "City",
                        outlined: "",
                      },
                      model: {
                        value: _vm.tech.address.cityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.tech.address, "cityId", $$v)
                        },
                        expression: "tech.address.cityId",
                      },
                    }),
                    _c("v-autocomplete", {
                      staticClass: "mb-3",
                      class: {
                        "is-invalid":
                          _vm.submitted && _vm.$v.tech.technicianTypeId.$error,
                      },
                      attrs: {
                        tabindex: "13",
                        items: _vm.techTypes,
                        "item-text": "name",
                        "item-value": "id",
                        label: "Type",
                        outlined: "",
                      },
                      model: {
                        value: _vm.tech.technicianTypeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.tech, "technicianTypeId", $$v)
                        },
                        expression: "tech.technicianTypeId",
                      },
                    }),
                    _vm.submitted && !_vm.$v.tech.technicianTypeId.required
                      ? _c("p", { staticClass: "invalid-feedback ml-3" }, [
                          _vm._v(" Technician Type is Required "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "btn-container" },
          [
            _c(
              "v-btn",
              {
                staticClass: "create-order-btn mr-1",
                attrs: { tabindex: "15" },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.check()
                  },
                  click: _vm.check,
                },
              },
              [_vm._v("Create Technician")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }