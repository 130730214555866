var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mb-7" },
        [
          _c(
            "v-row",
            {
              staticClass: "handle-timesheet",
              staticStyle: { "margin-bottom": "-1%" },
            },
            [
              _c("v-col", { attrs: { cols: "6", md: "9" } }, [
                _c("p", { staticClass: "table-title ml-10 mt-10" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.tecData.tecName + " TimeSheet Details") +
                      " "
                  ),
                ]),
              ]),
              _c(
                "v-col",
                { staticClass: "mt-8", attrs: { md: "3", cols: "6" } },
                [
                  _c("span", { staticClass: "Value2-data" }, [
                    _vm._v(
                      "TimeSheet Hr: " +
                        _vm._s(_vm.totalHours + _vm.totalHr) +
                        " hr"
                    ),
                  ]),
                  _c("br"),
                  _c("span", { staticClass: "Value2-data" }, [
                    _vm._v("Clocking Hr: " + _vm._s(_vm.totalHours) + " hr"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-left": "6%" } },
            [
              _c(
                "v-row",
                { staticClass: "handle-timesheet" },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "padding-left": "2%" },
                      attrs: { cols: "4", md: "4" },
                    },
                    [
                      _c("span", [
                        _c(
                          "span",
                          {
                            staticClass: "label-key",
                            staticStyle: { "margin-right": "2%" },
                          },
                          [_vm._v(" TimeSheetId: ")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "label-key",
                            staticStyle: { "margin-right": "2%" },
                          },
                          [_vm._v(" " + _vm._s(_vm.tecData.TimesheetId) + " ")]
                        ),
                      ]),
                    ]
                  ),
                  _c("v-col", { attrs: { cols: "4", md: "4" } }, [
                    _c("span", [
                      _c(
                        "span",
                        {
                          staticClass: "label-key",
                          staticStyle: { "margin-right": "2%" },
                        },
                        [_vm._v(" Master Work OrderId : ")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "label-key",
                          staticStyle: { "margin-right": "2%" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.tecData.MasterWorkorderId) + " "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("v-col", { attrs: { cols: "4", md: "4" } }, [
                    _c("span", [
                      _c(
                        "span",
                        {
                          staticClass: "label-key",
                          staticStyle: { "margin-right": "2%" },
                        },
                        [_vm._v(" Sub Work OrderId : ")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "label-key",
                          staticStyle: { "margin-right": "2%" },
                        },
                        [_vm._v(" " + _vm._s(_vm.tecData.tecWoId) + " ")]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "handle-timesheet" },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "padding-left": "2%" },
                      attrs: { cols: "4", md: "4" },
                    },
                    [
                      _c("span", [
                        _c(
                          "span",
                          {
                            staticClass: "label-key",
                            staticStyle: { "margin-right": "2%" },
                          },
                          [_vm._v(" Client : ")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "label-key",
                            staticStyle: { "margin-right": "2%" },
                          },
                          [_vm._v(" " + _vm._s(_vm.tecData.Client) + " ")]
                        ),
                      ]),
                    ]
                  ),
                  _c("v-col", { attrs: { cols: "4", md: "4" } }, [
                    _c("span", [
                      _c(
                        "span",
                        {
                          staticClass: "label-key",
                          staticStyle: { "margin-right": "2%" },
                        },
                        [_vm._v(" Account : ")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "label-key",
                          staticStyle: { "margin-right": "2%" },
                        },
                        [_vm._v(" " + _vm._s(_vm.tecData.Account) + " ")]
                      ),
                    ]),
                  ]),
                  _c("v-col", { attrs: { cols: "4", md: "4" } }, [
                    _c("span", [
                      _c(
                        "span",
                        {
                          staticClass: "label-key",
                          staticStyle: { "margin-right": "2%" },
                        },
                        [_vm._v(" Location : ")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "label-key",
                          staticStyle: { "margin-right": "1%" },
                        },
                        [_vm._v(" " + _vm._s(_vm.tecData.Location) + " ")]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "handle-timesheet" },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "padding-left": "2%" },
                      attrs: { cols: "4", md: "4" },
                    },
                    [
                      _c("span", [
                        _c(
                          "span",
                          {
                            staticClass: "label-key",
                            staticStyle: { "margin-right": "2%" },
                          },
                          [_vm._v(" Technical Contact : ")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "label-key",
                            staticStyle: { "margin-right": "2%" },
                          },
                          [_vm._v(" " + _vm._s(_vm.tecData.TechContact) + " ")]
                        ),
                      ]),
                    ]
                  ),
                  _c("v-col", { attrs: { cols: "4", md: "4" } }, [
                    _c("span", [
                      _c(
                        "span",
                        {
                          staticClass: "label-key",
                          staticStyle: { "margin-right": "2%" },
                        },
                        [_vm._v(" On Site Contact : ")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "label-key",
                          staticStyle: { "margin-right": "2%" },
                        },
                        [_vm._v(" " + _vm._s(_vm.tecData.SiteContact) + " ")]
                      ),
                    ]),
                  ]),
                  _c("v-col", { attrs: { cols: "4", md: "4" } }, [
                    _c("span", [
                      _c(
                        "span",
                        {
                          staticClass: "label-key",
                          staticStyle: { "margin-right": "2%" },
                        },
                        [_vm._v(" Location Address : ")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "label-key",
                          staticStyle: { "margin-right": "2%" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.tecData.LocationAddress) + " "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "handle-timesheet" },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: {
                        "padding-left": "2%",
                        "margin-top": "-1%",
                      },
                    },
                    [
                      _c("span", [
                        _c(
                          "span",
                          {
                            staticClass: "label-key",
                            staticStyle: { "margin-right": "2%" },
                          },
                          [_vm._v(" Scope Of Work : ")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "label-key",
                            staticStyle: { "margin-right": "2%" },
                          },
                          [_vm._v(" " + _vm._s(_vm.tecData.ScopeWork) + " ")]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "mb-7" }, [
        _c(
          "div",
          { staticClass: "top-box" },
          [
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { md: "9" } }, [
                  _c("p", { staticClass: "clocking-date" }, [
                    _vm._v(
                      _vm._s(
                        _vm
                          .convertUTCDateToLocalDate(new Date(_vm.item.date))
                          .toLocaleString()
                          .substr(0, 10)
                      )
                    ),
                  ]),
                ]),
                _c("v-col", { attrs: { md: "3" } }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "clocking-box" },
          [
            _c(
              "v-row",
              [
                _c("v-col", { staticClass: "resize", attrs: { md: "1" } }),
                _c(
                  "v-col",
                  { attrs: { md: "5" } },
                  [
                    _c("v-text-field", {
                      class: {
                        "is-invalid":
                          _vm.submitted && _vm.$v.item.scopeOfFinishWork.$error,
                      },
                      attrs: { outlined: "", label: "Closing Notes" },
                      model: {
                        value: _vm.item.scopeOfFinishWork,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "scopeOfFinishWork", $$v)
                        },
                        expression: "item.scopeOfFinishWork",
                      },
                    }),
                    _vm.submitted && !_vm.$v.item.scopeOfFinishWork.required
                      ? _c("p", { staticClass: "invalid-feedback mt-2" }, [
                          _vm._v(" Closing Notes Required "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { md: "1", id: "time-box" } },
                  [
                    _c("vue-timepicker", {
                      class: {
                        "is-invalid":
                          _vm.submitted && _vm.$v.item.startTime.$error,
                      },
                      attrs: { "manual-input": "", "hide-dropdown": "" },
                      on: { blur: _vm.calcutateDiffTime },
                      model: {
                        value: _vm.item.startTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "startTime", $$v)
                        },
                        expression: "item.startTime",
                      },
                    }),
                    _vm.submitted && !_vm.$v.item.startTime.required
                      ? _c("p", { staticClass: "invalid-feedback" }, [
                          _vm._v(" startTime is Required "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { md: "1", id: "time-box" } },
                  [
                    _c("vue-timepicker", {
                      class: {
                        "is-invalid":
                          _vm.submitted && _vm.$v.item.endTime.$error,
                      },
                      attrs: { "manual-input": "", "hide-dropdown": "" },
                      on: { blur: _vm.calcutateDiffTime },
                      model: {
                        value: _vm.item.endTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "endTime", $$v)
                        },
                        expression: "item.endTime",
                      },
                    }),
                    _vm.submitted && !_vm.$v.item.startTime.required
                      ? _c("p", { staticClass: "invalid-feedback" }, [
                          _vm._v(" endTime is Required "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { md: "1" } },
                  [
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          "close-on-content-click": false,
                          transition: "scale-transition",
                          "offset-y": "",
                          "min-width": "300",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "i",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass:
                                          "fas fa-calendar-alt day-icon",
                                      },
                                      "i",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c(
                          "v-date-picker",
                          {
                            attrs: { "no-title": "", scrollable: "" },
                            model: {
                              value: _vm.item.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "date", $$v)
                              },
                              expression: "item.date",
                            },
                          },
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "primary" },
                                on: {
                                  click: function ($event) {
                                    _vm.item.menu = false
                                  },
                                },
                              },
                              [_vm._v(" Cancel ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.submitted && !_vm.$v.item.date.required
                      ? _c("p", { staticClass: "invalid-feedback" }, [
                          _vm._v(" date is Required "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { md: "2", id: "time-box" } },
                  [
                    _c("vue-timepicker", {
                      attrs: {
                        disabled: "",
                        "manual-input": "",
                        "hide-dropdown": "",
                      },
                      model: {
                        value: _vm.item.total,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "total", $$v)
                        },
                        expression: "item.total",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { md: "1" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "ma-2",
                        staticStyle: {
                          width: "130px",
                          "justify-content": "flex-start",
                          padding: "0 10px",
                        },
                        attrs: { color: "primary", dark: "" },
                        on: {
                          click: function ($event) {
                            return _vm.AddClocking()
                          },
                        },
                      },
                      [
                        _vm._v(" Add Clocking "),
                        _c("v-icon", { attrs: { dark: "", right: "" } }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { md: "5" } }),
                _c("v-col", [
                  _vm.VaildationError2
                    ? _c("p", { staticClass: "invalid-feedback" }, [
                        _vm._v(" endTime should be greater than start time "),
                      ])
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._l(_vm.woClocking, function (clock) {
        return _c(
          "div",
          { key: clock.date, staticClass: "mb-7" },
          [
            _c(
              "div",
              { staticClass: "top-box" },
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { md: "9" } }, [
                      _c("p", { staticClass: "clocking-date" }, [
                        _vm._v(
                          _vm._s(
                            _vm
                              .convertUTCDateToLocalDate(new Date(clock.date))
                              .toLocaleString()
                              .substr(0, 10)
                          )
                        ),
                      ]),
                    ]),
                    _c("v-col", { attrs: { md: "3" } }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._l(clock.items, function (item, index) {
              return _c(
                "div",
                { key: item.id, staticClass: "clocking-box" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", {
                        staticClass: "resize",
                        attrs: { md: "1" },
                      }),
                      _c(
                        "v-col",
                        { attrs: { md: "5" } },
                        [
                          _c("v-text-field", {
                            staticClass: "description-feild",
                            attrs: { outlined: "", label: "Closing Notes" },
                            on: {
                              blur: function ($event) {
                                return _vm.UpdateClocking(item)
                              },
                            },
                            model: {
                              value: item.scopeOfFinishWork,
                              callback: function ($$v) {
                                _vm.$set(item, "scopeOfFinishWork", $$v)
                              },
                              expression: "item.scopeOfFinishWork",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "1", id: "time-box" } },
                        [
                          _c("vue-timepicker", {
                            attrs: { "manual-input": "", "hide-dropdown": "" },
                            on: {
                              blur: function ($event) {
                                return _vm.UpdateClocking(item)
                              },
                            },
                            model: {
                              value: item.startTime,
                              callback: function ($$v) {
                                _vm.$set(item, "startTime", $$v)
                              },
                              expression: "item.startTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "1", id: "time-box" } },
                        [
                          _c("vue-timepicker", {
                            attrs: { "manual-input": "", "hide-dropdown": "" },
                            on: {
                              blur: function ($event) {
                                return _vm.UpdateClocking(item)
                              },
                            },
                            model: {
                              value: item.endTime,
                              callback: function ($$v) {
                                _vm.$set(item, "endTime", $$v)
                              },
                              expression: "item.endTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "1" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: index,
                              refInFor: true,
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": clock.date,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "300",
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  return _vm.$set(clock, "date", $event)
                                },
                                "update:return-value": function ($event) {
                                  return _vm.$set(clock, "date", $event)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "i",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "fas fa-calendar-alt day-icon",
                                              },
                                              "i",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: item.menu,
                                callback: function ($$v) {
                                  _vm.$set(item, "menu", $$v)
                                },
                                expression: "item.menu",
                              },
                            },
                            [
                              _c(
                                "v-date-picker",
                                {
                                  attrs: { "no-title": "", scrollable: "" },
                                  on: {
                                    input: function ($event) {
                                      item.menu = false
                                    },
                                  },
                                  model: {
                                    value: clock.date,
                                    callback: function ($$v) {
                                      _vm.$set(clock, "date", $$v)
                                    },
                                    expression: "clock.date",
                                  },
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          item.menu = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "2", id: "time-box" } },
                        [
                          _c("vue-timepicker", {
                            attrs: {
                              disabled: "",
                              "manual-input": "",
                              "hide-dropdown": "",
                            },
                            model: {
                              value: item.total,
                              callback: function ($$v) {
                                _vm.$set(item, "total", $$v)
                              },
                              expression: "item.total",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "1" } },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "delete-timesheet",
                              attrs: { color: "#A23D3D" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteItem(item)
                                },
                              },
                            },
                            [_vm._v("mdi-delete")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { md: "5" } }),
                      _c("v-col", [
                        _vm.VaildationError
                          ? _c("p", { staticClass: "invalid-feedback" }, [
                              _vm._v(
                                " endTime should be greater than start time "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
          ],
          2
        )
      }),
      _vm.isPending
        ? _c(
            "div",
            { staticClass: "btn-container" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "float btn-creation wo-diff-style",
                  on: {
                    click: function ($event) {
                      return _vm.Approval(true)
                    },
                  },
                },
                [_vm._v("Approve")]
              ),
              _vm.isPending
                ? _c(
                    "v-btn",
                    {
                      staticClass: "float2 btn-creation wo-diff-style",
                      attrs: { color: "red" },
                      on: {
                        click: function ($event) {
                          return _vm.Approval(false)
                        },
                      },
                    },
                    [_vm._v("Disapprove ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.dialogDelete,
            callback: function ($$v) {
              _vm.dialogDelete = $$v
            },
            expression: "dialogDelete",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v("Are you sure you want to delete this item?"),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.closeDelete },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.deleteItemConfirm },
                    },
                    [_vm._v("OK")]
                  ),
                  _c("v-spacer"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700px" },
          model: {
            value: _vm.expenseDialoge,
            callback: function ($$v) {
              _vm.expenseDialoge = $$v
            },
            expression: "expenseDialoge",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v("New Expense"),
              ]),
              _c(
                "v-card-text",
                [
                  _c("br"),
                  _c("v-select", {
                    class: {
                      "is-invalid":
                        _vm.submittedExpense && _vm.$v.expenseTypeId.$error,
                    },
                    attrs: {
                      items: _vm.expensesTypes,
                      "item-text": "name",
                      "item-value": "id",
                      label: "Add Fees,Discount,Tax, ... ",
                      solo: "",
                    },
                    model: {
                      value: _vm.expenseTypeId,
                      callback: function ($$v) {
                        _vm.expenseTypeId = $$v
                      },
                      expression: "expenseTypeId",
                    },
                  }),
                  _vm.submittedExpense && !_vm.$v.expenseTypeId.required
                    ? _c("p", { staticClass: "invalid-feedback mt-2" }, [
                        _vm._v(" Expense Type is required "),
                      ])
                    : _vm._e(),
                  _c("br"),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "3", cols: "2" } },
                        [
                          _c("v-select", {
                            class: {
                              "is-invalid":
                                _vm.submittedExpense &&
                                _vm.$v.feild.amountTypeId.$error,
                            },
                            attrs: {
                              solo: "",
                              "item-text": "name",
                              "item-value": "id",
                              items: _vm.amountTypes,
                              label: "$",
                            },
                            model: {
                              value: _vm.feild.amountTypeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.feild, "amountTypeId", $$v)
                              },
                              expression: "feild.amountTypeId",
                            },
                          }),
                          _vm.submittedExpense &&
                          !_vm.$v.feild.amountTypeId.required
                            ? _c(
                                "p",
                                { staticClass: "invalid-feedback mt-2" },
                                [_vm._v(" amount Type is required ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "9", cols: "10" } },
                        [
                          _c("v-text-field", {
                            class: {
                              "is-invalid":
                                _vm.submittedExpense &&
                                _vm.$v.feild.expenseAmount.$error,
                            },
                            attrs: { outlined: "", label: "Amount" },
                            model: {
                              value: _vm.feild.expenseAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.feild, "expenseAmount", $$v)
                              },
                              expression: "feild.expenseAmount",
                            },
                          }),
                          _vm.submittedExpense &&
                          !_vm.$v.feild.expenseAmount.required
                            ? _c(
                                "p",
                                { staticClass: "invalid-feedback mt-2" },
                                [_vm._v(" amount is required ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-text-field", {
                            class: {
                              "is-invalid":
                                _vm.submittedExpense &&
                                _vm.$v.feild.description.$error,
                            },
                            attrs: {
                              placeholder: "Description",
                              label: _vm.feild.name,
                            },
                            model: {
                              value: _vm.feild.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.feild, "description", $$v)
                              },
                              expression: "feild.description",
                            },
                          }),
                          _vm.submittedExpense &&
                          !_vm.$v.feild.description.required
                            ? _c(
                                "p",
                                { staticClass: "invalid-feedback mt-2" },
                                [_vm._v(" description is required ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.addFeild },
                    },
                    [_vm._v("Add")]
                  ),
                  _c("v-spacer"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c("div", { staticClass: "mb-7" }, [
        _c(
          "div",
          { staticClass: "top-box" },
          [
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { md: "2", cols: "10" } }, [
                  _c("p", { staticClass: "clocking-date" }, [
                    _vm._v("Timesheet Expenses"),
                  ]),
                ]),
                _c(
                  "v-col",
                  { attrs: { md: "5", cols: "2" } },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "ml-5",
                        attrs: { small: "" },
                        on: { click: _vm.ShowExpenseDialog },
                      },
                      [_vm._v(" fa-solid fa-plus ")]
                    ),
                  ],
                  1
                ),
                _c("v-col", { attrs: { md: "4", cols: "12" } }, [
                  _c("span", [
                    _c(
                      "span",
                      {
                        staticClass: "Value2-data",
                        staticStyle: { "margin-left": "7%" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            "Total Hr : " +
                              _vm.totalHr +
                              " hr       Total Amount :$" +
                              _vm.totalAmount
                          )
                        ),
                      ]
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _vm._l(_vm.expenses, function (expense) {
              return _c(
                "v-row",
                { key: expense.id },
                [
                  _c("v-col", {
                    staticClass: "resize",
                    attrs: { md: "3", cols: "0" },
                  }),
                  _c("v-col", { attrs: { md: "2", cols: "12" } }, [
                    _c(
                      "p",
                      {
                        staticClass: "label-key-Timesheet",
                        staticStyle: { "padding-left": "0%" },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(expense.workOrderExpenseTypeName) + " "
                        ),
                      ]
                    ),
                  ]),
                  _c("v-col", { attrs: { md: "2", cols: "6" } }, [
                    expense.amountTypeId == 2
                      ? _c("span", [
                          _c("span", { staticClass: "label-keycolorRed" }, [
                            _vm._v(_vm._s(expense.amountTypeName)),
                          ]),
                          _c("span", { staticClass: "label-keycolorRed" }, [
                            _vm._v(_vm._s(expense.expenseAmount)),
                          ]),
                        ])
                      : _c("span", [
                          _c("span", { staticClass: "label-keycolorRed" }, [
                            _vm._v(_vm._s(expense.expenseAmount)),
                          ]),
                          _c("span", { staticClass: "label-keycolorRed" }, [
                            _vm._v(_vm._s(expense.amountTypeName)),
                          ]),
                        ]),
                  ]),
                  _c("v-col", { attrs: { md: "2", cols: "3" } }, [
                    _c("p", { staticClass: "label-keycolorBlue" }, [
                      _vm._v(_vm._s(expense.description)),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { md: "3" } },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { color: "#A23D3D" },
                          on: {
                            click: function ($event) {
                              return _vm.removeFeild(expense)
                            },
                          },
                        },
                        [_vm._v("mdi-delete")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }