var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "handle-test" }, [
    _c("p", { staticClass: "table-title" }, [_vm._v("Invoice")]),
    _c(
      "div",
      { staticClass: "innner-invoice-container" },
      [
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { md: "8", sm: "8" } },
              [
                _c(
                  "v-col",
                  { attrs: { md: "4" } },
                  [
                    _c("p", { staticClass: "label2-key" }, [
                      _vm._v("Accounts"),
                    ]),
                    _vm._l(_vm.invoice.workOrders, function (item) {
                      return _c(
                        "span",
                        { key: item.id, staticClass: "Value-data" },
                        [_vm._v(" " + _vm._s(item.account.name) + ",")]
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { md: "4", sm: "4" } },
              [
                _c(
                  "v-col",
                  { staticClass: "auto", attrs: { md: "10" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn-inv",
                        on: { click: _vm.sendByemail },
                      },
                      [_vm._v("Send by Email")]
                    ),
                    _c("p", { staticClass: "lbl-inv" }, [
                      _vm._v(
                        "Order Status: " + _vm._s(_vm.invoice.status.name)
                      ),
                    ]),
                    _c("p", { staticClass: "lbl-inv" }, [
                      _vm._v(
                        "creation Date: " +
                          _vm._s(
                            new Date(_vm.invoice.creationDate).toLocaleString()
                          )
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("v-col", { attrs: { md: "4", sm: "4" } }),
          ],
          1
        ),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { sm: "12", md: "12" } },
              [
                _c("v-card", { attrs: { flat: "" } }, [
                  _c(
                    "div",
                    { staticClass: "invoice-card" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { md: "3" } }, [
                            _c("p", { staticClass: "invoice-table-headers" }, [
                              _vm._v("Invoice Number"),
                            ]),
                            _c("p", { staticClass: "invoice-table-values" }, [
                              _vm._v(
                                " " + _vm._s(_vm.invoice.invoiceNumber) + " "
                              ),
                            ]),
                          ]),
                          _c(
                            "v-col",
                            {
                              staticClass: "invoice-table-headers",
                              attrs: { md: "2" },
                            },
                            [
                              _c("p", [_vm._v("Total Invoice cost")]),
                              _c("p", { staticClass: "invoice-table-values" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.invoice.totalBillRate) + " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "invoice-table-headers",
                              attrs: { md: "2" },
                            },
                            [
                              _c("p", [_vm._v("Expenses cost")]),
                              _c("p", { staticClass: "invoice-table-values" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.invoice.totalExpenses) + " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "invoice-table-headers",
                              attrs: { md: "2" },
                            },
                            [
                              _c("p", [_vm._v("No. of hrs")]),
                              _c("p", { staticClass: "invoice-table-values" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.invoice.totalHours) + " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "invoice-table-headers",
                              attrs: { md: "2" },
                            },
                            [
                              _c("p", [_vm._v("No. of Technician")]),
                              _c("p", { staticClass: "invoice-table-values" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.invoice.numberOfTechnicians) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { md: "4", sm: "4" } },
              [
                _c(
                  "v-col",
                  { staticClass: "auto", attrs: { md: "8", sm: "12" } },
                  [
                    _c("span", { staticClass: "Value2-data" }, [
                      _vm._v(
                        "Total :" + _vm._s(_vm.invoice.totalBillRate) + " $"
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c("v-col", { attrs: { md: "4", sm: "4" } }),
            _c(
              "v-col",
              { attrs: { md: "4", sm: "4" } },
              [
                _c(
                  "v-col",
                  { staticClass: "auto", attrs: { md: "10" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn-inv",
                        on: { click: _vm.backToTimeSheet },
                      },
                      [_vm._v("Back to TimeSheet")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }