var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "handle-test", attrs: { id: "work-order" } },
    [
      _c(
        "div",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12", md: "10" } }, [
                _c("p", { staticClass: "table-title" }, [
                  _vm._v("Accounts List"),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { md: "1", cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-creation wo-diff-style",
                      attrs: { to: "/account/create", color: "#D92020" },
                    },
                    [_vm._v("Create Account")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pad0", attrs: { cols: "12", md: "4" } },
                [
                  _c("v-text-field", {
                    ref: "objet",
                    attrs: {
                      solo: "",
                      label: "Search Account ",
                      "append-icon": "mdi-magnify",
                    },
                    on: {
                      keyup: function ($event) {
                        return _vm.realSearch(1)
                      },
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
              _c("v-col", {
                staticClass: "pad0",
                attrs: { cols: "12", md: "8" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              loading: _vm.isLoaded,
              "loading-text": "Loading... Please wait",
              headers: _vm.headers,
              items: _vm.data,
              "item-key": "name",
              "hide-default-footer": "",
              "items-per-page": _vm.pagination.pageSize,
            },
            on: { "update:options": _vm.handleOptionsUpdate },
            scopedSlots: _vm._u([
              {
                key: "footer",
                fn: function () {
                  return [
                    _c(
                      "v-row",
                      { staticClass: "mt-5 fit-width" },
                      [
                        _c(
                          "v-col",
                          { attrs: { md: "2" } },
                          [
                            _c("v-select", {
                              attrs: {
                                items: _vm.sizeOptions,
                                "item-value": "id",
                                "item-text": "name",
                                solo: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.realSearch(1)
                                },
                              },
                              model: {
                                value: _vm.pagination.pageSize,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pagination, "pageSize", $$v)
                                },
                                expression: "pagination.pageSize",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { md: "5" } },
                          [
                            _c("v-pagination", {
                              attrs: {
                                length: _vm.totalPages,
                                "total-visible": "6",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.realSearch(
                                    _vm.pagination.pageNumber
                                  )
                                },
                              },
                              model: {
                                value: _vm.pagination.pageNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pagination, "pageNumber", $$v)
                                },
                                expression: "pagination.pageNumber",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "top",
                fn: function () {
                  return [
                    _c(
                      "v-toolbar",
                      { attrs: { flat: "" } },
                      [
                        _c("v-toolbar-title", [_vm._v("Accounts")]),
                        _c("v-divider", {
                          staticClass: "mx-4",
                          attrs: { inset: "", vertical: "" },
                        }),
                        _c("v-spacer"),
                        _c(
                          "v-dialog",
                          {
                            attrs: { "max-width": "500px" },
                            model: {
                              value: _vm.dialog,
                              callback: function ($$v) {
                                _vm.dialog = $$v
                              },
                              expression: "dialog",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c("v-card-title", [
                                  _c("span", { staticClass: "text-h5" }, [
                                    _vm._v("Edit Account"),
                                  ]),
                                ]),
                                _c(
                                  "v-card-text",
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { md: "12" } },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    solo: "",
                                                    label: "Full Name",
                                                  },
                                                  model: {
                                                    value: _vm.account.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.account,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "account.name",
                                                  },
                                                }),
                                                _c("v-autocomplete", {
                                                  attrs: {
                                                    solo: "",
                                                    label: "Select Client",
                                                    items: _vm.clients,
                                                    "item-value": "id",
                                                    "item-text": "name",
                                                  },
                                                  model: {
                                                    value: _vm.account.clientId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.account,
                                                        "clientId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "account.clientId",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "cancel-update-btn",
                                        attrs: { text: "" },
                                        on: { click: _vm.close },
                                      },
                                      [_vm._v("Cancel")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "create-order-btn mr-1",
                                        on: { click: _vm.save },
                                      },
                                      [_vm._v("Update")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "item.client",
                fn: function ({ item }) {
                  return [
                    _c("p", [_c("span", [_vm._v(_vm._s(item.client.name))])]),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-2 edit-icon",
                        attrs: { small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.editItem(item)
                          },
                        },
                      },
                      [_vm._v(" far fa-edit ")]
                    ),
                    _c(
                      "v-icon",
                      {
                        staticClass: "delete-icon",
                        attrs: { small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteItem(item)
                          },
                        },
                      },
                      [_vm._v(" mdi-delete ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "500px" },
              model: {
                value: _vm.dialogDelete,
                callback: function ($$v) {
                  _vm.dialogDelete = $$v
                },
                expression: "dialogDelete",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "text-h5" }, [
                    _vm._v("Are you sure you want to delete this item?"),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: { click: _vm.closeDelete },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: { click: _vm.deleteItemConfirm },
                        },
                        [_vm._v("OK")]
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: { timeout: 3000, color: _vm.snackColor },
              scopedSlots: _vm._u([
                {
                  key: "action",
                  fn: function ({ attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._b(
                          {
                            attrs: { text: "" },
                            on: {
                              click: function ($event) {
                                _vm.snack = false
                              },
                            },
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        [_vm._v(" Close ")]
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.snack,
                callback: function ($$v) {
                  _vm.snack = $$v
                },
                expression: "snack",
              },
            },
            [_vm._v(" " + _vm._s(_vm.snackText) + " ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }