<template>
  <div id="work-order" class="handle-test">
    <div>
      <v-row>
        <v-col cols="12" md="10">
          <p class="table-title">Company List</p>
        </v-col>

        <v-col md="1" cols="12">
          <v-btn
            v-if="can(23)"
            to="/company/create"
            class="btn-creation wo-diff-style"
            color="#D92020"
            >Create Company</v-btn
          >
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="4" class="pad0">
          <v-text-field
            solo
            label="Search Company "
            append-icon="mdi-magnify"
            v-model="search"
            @keyup="realSearch(1)"
            ref="objet"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="8" class="pad0"> </v-col>
      </v-row>
    </div>
    <div>
      <v-data-table
      :loading="isLoaded" loading-text="Loading... Please wait"
        :headers="headers"
        :items="data"
        class="elevation-1"
        item-key="name"
        hide-default-footer
        :items-per-page="pagination.pageSize"
        @update:options="handleOptionsUpdate"
      >
       
        <template v-slot:footer>
          <v-row class="mt-5 fit-width">
            <v-col md="2">
              <v-select
                :items="sizeOptions"
                v-model="pagination.pageSize"
                :item-value="'id'"
                :item-text="'name'"
                @change="realSearch(1)"
                solo
              ></v-select>
            </v-col>
            <v-col md="5">
              <v-pagination
                v-model="pagination.pageNumber"
                :length="totalPages"
                @input="realSearch(pagination.pageNumber)"
                total-visible="6"
              ></v-pagination>
            </v-col>
          </v-row>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Companies</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="700px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Edit Company</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col md="12">
                        <v-text-field
                          outlined
                          v-model="company.name"
                          :class="{
                            'is-invalid': submitted && $v.company.name.$error,
                          }"
                          label="Full Name"
                        ></v-text-field>
                        <p
                          v-if="submitted && !$v.company.name.required"
                          class="invalid-feedback mt-2"
                        >
                          Name is Required
                        </p>
                        <v-autocomplete
                          ref="objet"
                          :items="accounts"
                          :item-text="'name'"
                          :item-value="'id'"
                          label="Select Account"
                          outlined
                          class="mb-3"
                          v-model="company.accountId"
                          :class="{
                            'is-invalid':
                              submitted && $v.company.accountId.$error,
                          }"
                          :search-input.sync="accountSearch"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          outlined
                          class="mb-3"
                          :set="(v = $v.company.companyPhones.$each[0])"
                          v-model="company.companyPhones[0].phoneNumber"
                          @blur="formatPhoneNumber(1)"
                          :class="{
                            'is-invalid': submitted && v.phoneNumber.$error,
                          }"
                          label="Primary Phone"
                        ></v-text-field>
                        <p
                          v-if="submitted && !v.phoneNumber.required"
                          class="invalid-feedback"
                        >
                          Primary Phone is Required
                        </p>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          outlined
                          class="mb-3"
                          label="Secondary Phone"
                          v-model="company.companyPhones[1].phoneNumber"
                          @blur="formatPhoneNumber(2)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-10"></v-divider>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn text class="cancel-update-btn" @click="close"
                    >Cancel</v-btn
                  >

                  <v-btn class="create-order-btn mr-1" @click="save"
                    >Update</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            {{ snackText }}

            <template v-slot:action="{ attrs }">
              <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
            </template>
          </v-snackbar>
        </template>
        <template v-slot:item.clientPhones="{ item }">
          <p v-for="(ph, index) in item.clientPhones" :key="index">
            <span
              :class="{
                'is-Primary': ph.isPrimary,
              }"
              >{{ ph.phoneNumber }}</span
            >
          </p>
        </template>
        <template v-slot:item.account="{ item }">
          <p v-if="item.account">
            <span>{{ item.account.name }}</span>
          </p>
        </template>
        <template v-slot:item.actions="{ item }">
        
          <v-icon
          
            small
            class="mr-2 edit-icon"
            @click="editItem(item)"
          >
            far fa-edit
          </v-icon>
          <v-icon
          
            class="delete-icon"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { dataService } from "@/services/data-service";
import { required } from "vuelidate/lib/validators";


export default {
  data() {
    return {
      //=============
      isprimary: 1,
      //============
      isLoaded: true,
      queryTerm: "",
      snack: false,
      snackColor: "",
      snackText: "",
      dialog: false,
      submitted: false,
      company: {
        accountId: null,
        companyPhones: [
          {
            phoneNumber: "",
            isPrimary: true,
          },
          {
            phoneNumber: "",
            isPrimary: false,
          },
        ],

        name: "",
      },
      columnIndex: 0,
      isDec: false,
      sizeOptions: [
        {
          id: 10,
          name: 10,
        },
        {
          id: 20,
          name: 20,
        },
        {
          id: 30,
          name: 30,
        },
        {
          id: 50,
          name: 50,
        },
        {
          id: 100,
          name: 100,
        },
      ],
      totalPages: 0,
      pagination: {
        pageNumber: 1,
        pageSize: 10,
      },
      search: null,
      items: ["foo", "bar", "fizz", "buzz"],
      value: null,
      headers: [
        {
          text: "Name",

          value: "name",
        },
        { text: "Account", value: "account" },
        { text: "Phone Number", value: "clientPhones" },
        { text: "Actions", value: "actions" },
      ],
      data: [],
      editedIndex: null,
      accounts: [],
      dialogDelete: false,
    };
  },
  validations: {
    company: {
      name: { required },
      accountId: { required },
      companyPhones: {
        $each: {
          phoneNumber: { required },
        },
      },
    },
  },
  methods: {
    formatPhoneNumber(isprimary) {
      if (isprimary == 1) {
      
        var cleanedprimaryphonenumber = ('' + this.company.companyPhones[0].phoneNumber).replace(/\D/g, '');
        var matchprimaryphonenumber = cleanedprimaryphonenumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (matchprimaryphonenumber) {
          var intlCodeprimaryphonenumber = (matchprimaryphonenumber[1] ? '+1 ' : '');
          this.company.companyPhones[0].phoneNumber= [intlCodeprimaryphonenumber, '(', matchprimaryphonenumber[2], ') ', matchprimaryphonenumber[3], '-', matchprimaryphonenumber[4]].join('');
        }
      }
      else {
      
        var cleanedsecondryphonenumber = ('' + this.company.companyPhones[1].phoneNumber).replace(/\D/g, '');
        var matchsecondryphonenumber = cleanedsecondryphonenumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (matchsecondryphonenumber) {
          var intlCodesecondryphonenumber = (matchsecondryphonenumber[1] ? '+1 ' : '');
          this.company.companyPhones[1].phoneNumber = [intlCodesecondryphonenumber, '(', matchsecondryphonenumber[2], ') ', matchsecondryphonenumber[3], '-', matchsecondryphonenumber[4]].join('');
        }
      }

    },
    //======================================
    deleteItem(item) {
      this.editedIndex = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      dataService.postDelete(
        "api/Companies/delete/" + this.editedIndex,
        () => {
          
            this.dialogDelete = false;
            this.realSearch(1);
          }
        
      );
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    async loadEntries() {
      this.clients = await this.getAccounts(this.queryTerm || "");
    },
    getAccounts(value) {
      var self = this;
      
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyAccount(value);
        }, 1000);
      } else {
        self.applyAccount(value);
      }
    },
    applyAccount(queryTerm) {
      dataService.getList(
        "api/Accounts/search_by_name",
        {
          pageNumber: 1,
          pageSize: 1000,
          subString: queryTerm,
        },
        (response) => {
          this.accounts = response.data.data.items;
          this.$nextTick(() => this.$refs.objet.focus());
        }
      );
    },
    editItem(item) {
      this.editedIndex = item.id;
      this.loadEntries();
      dataService.getAll("api/Companies/" + item.id, (response) => {
        this.company = response.data;
        if (this.company.companyPhones.length == 0) {
          this.company.companyPhones = [
            {
              phoneNumber: "",
              isPrimary: true,
            },
            {
              phoneNumber: "",
              isPrimary: false,
            },
          ];
        }
        if (this.company.companyPhones.length == 1) {
          this.company.companyPhones[1] = {
            phoneNumber: "",
            isPrimary: false,
          };
        }
        this.dialog = true;
      });
    },
    save() {
      dataService.update(
        "api/Companies/update",
        { company: this.company },
        (response) => {
          if (response.data.succeeded) {
            this.dialog = false;
            this.snack = true;
            this.snackColor = "success";
            this.snackText = "Data Updated";
            this.realSearch(1);
          }
        }
      );
    },
       close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    handleOptionsUpdate({ sortBy, sortDesc }) {
      if (sortBy.length > 0) {
        if (sortBy[0] == "name") this.columnIndex = 0;
        if (sortBy[0] == "account") this.columnIndex = 1;

        this.isDec = sortDesc[0];

        this.realSearch(1);
      }
    },
    realSearch(size) {
      // clear timeout variable
       this.isLoaded = true;
  
      var self = this;
      self.pagination.pageNumber = size;


         dataService.getList(
          "api/Companies/search_by_name",
          {
            pageNumber: size,
            pageSize: self.pagination.pageSize,
            subString: self.search,
            sortedColumnIndex: self.columnIndex,
            isDec: self.isDec,
          },
          (response) => {
            this.isLoaded = false;
            self.data = response.data.data.items;
            self.$nextTick(() => self.$refs.objet.focus());
            self.totalPages = response.data.data.totalPages;
          }
        );
     
    },
  },
  computed: {
    accountSearch: {
      get() {
        return this.queryTerm;
      },

      set(searchInput) {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput;
          this.loadEntries();
        }
      },
    },
  },

  mounted() {
    this.realSearch(1);
  },
};
</script>