<template>
  <div id="work-order" class="handle-test">
    <div>
      <v-row>
        <v-col cols="12" md="10">
          <p class="table-title">Locations List</p>
        </v-col>

        <v-col md="1" cols="12">
          <v-btn
            to="/location/create"
            class="btn-creation wo-diff-style"
            color="#D92020"
            >Create Location</v-btn
          >
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="4" class="pad0">
          <v-text-field
            solo
            label="Search Location "
            append-icon="mdi-magnify"
            v-model="search"
            @keyup="realSearch(1)"
            ref="objet"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="8" class="pad0"> </v-col>
      </v-row>
    </div>
    <div>
      <v-data-table
        :loading="isLoaded"
        loading-text="Loading... Please wait"
        :headers="headers"
        :items="data"
        class="elevation-1"
        item-key="id"
        hide-default-footer
        :items-per-page="pagination.pageSize"
        @update:options="handleOptionsUpdate"
      >
        <template v-slot:footer>
          <v-row class="mt-5 fit-width">
            <v-col md="2">
              <v-select
                :items="sizeOptions"
                v-model="pagination.pageSize"
                :item-value="'id'"
                :item-text="'name'"
                @change="realSearch(1)"
                solo
              ></v-select>
            </v-col>
            <v-col md="5">
              <v-pagination
                v-model="pagination.pageNumber"
                :length="totalPages"
                @input="realSearch(pagination.pageNumber)"
                total-visible="6"
              ></v-pagination>
            </v-col>
          </v-row>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Locations</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="700px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Edit Branch</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col md="12">
                        <v-text-field
                          tabindex="1"
                          outlined
                          v-model="branch.name"
                          :class="{
                            'is-invalid': submitted && $v.branch.name.$error,
                          }"
                          label="Full Name"
                        ></v-text-field>
                        <p
                          v-if="submitted && !$v.branch.name.required"
                          class="invalid-feedback mt-2"
                        >
                          Name is Required
                        </p>
                        <v-autocomplete
                          tabindex="2"
                          outlined
                          v-model="branch.companyId"
                          label="Select Company"
                          :items="companies"
                          :item-value="'id'"
                          :item-text="'name'"
                          :search-input.sync="locationSearch"
                          ref="lcn"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-divider class="mb-10"></v-divider>
                    <div>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            tabindex="3"
                            outlined
                            class="mb-3"
                            :class="{
                              'is-invalid':
                                submitted && $v.branch.address.street.$error,
                            }"
                            v-model="branch.address.street"
                            label="Street"
                          ></v-text-field>
                          <p
                            v-if="
                              submitted && !$v.branch.address.street.required
                            "
                            class="invalid-feedback"
                          >
                            Street is Required
                          </p>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            tabindex="4"
                            :class="{
                              'is-invalid':
                                submitted &&
                                $v.branch.address.postalCode.$error,
                            }"
                            v-model="branch.address.postalCode"
                            outlined
                            class="mb-3"
                            label="ZIP / Postal Code"
                          >
                          </v-text-field>
                          <p
                            v-if="
                              submitted &&
                              !$v.branch.address.postalCode.required
                            "
                            class="invalid-feedback"
                          >
                            Postal Code is Required
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            tabindex="5"
                            v-model="branch.address.details"
                            outlined
                            class="mb-3"
                            label="Details"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6"></v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            tabindex="6"
                            :items="countries"
                            :item-text="'name'"
                            :item-value="'id'"
                            label="Country"
                            outlined
                            v-model="branch.address.countryId"
                            class="mb-3"
                            :class="{
                              'is-invalid':
                                submitted && $v.branch.address.countryId.$error,
                            }"
                            @change="getStates"
                          >
                          </v-autocomplete>
                          <!-- <v-autocomplete tabindex="6" :items="countries" :item-text="'name'" :item-value="'id'" label="Country"
                            outlined v-model="branch.address.countryId" class="mb-3" @change="Editonchange()" :class="{
                              'is-invalid':
                                submitted && $v.branch.address.countryId.$error,
                            }">
                          </v-autocomplete> -->
                          <p
                            v-if="
                              submitted && !$v.branch.address.countryId.required
                            "
                            class="invalid-feedback"
                          >
                            Country is Required
                          </p>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            tabindex="7"
                            :items="EditStates"
                            :item-text="'name'"
                            :item-value="'id'"
                            label="State"
                            outlined
                            v-model="branch.address.stateId"
                            class="mb-3"
                            :class="{
                              'is-invalid':
                                submitted && $v.branch.address.stateId.$error,
                            }"
                            @change="getCities"
                            :search-input.sync="StateSearch"
                            hide-selected
                            persistent-hint
                            v-on:keyup.enter="createState"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    No results matching "<strong>{{
                                      StateSearch
                                    }}</strong
                                    >". Press <kbd>enter</kbd> to create a new
                                    one
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-autocomplete>

                          <!-- <v-autocomplete
                            tabindex="7"
                            :items="EditStates"
                            :item-text="'name'"
                            :item-value="'id'"
                            label="State"
                            outlined
                            @change="EditSearchInCity()"
                            :search-input.sync="searchEditState"
                            v-model="branch.address.stateId"
                            class="mb-3"
                            :class="{
                              'is-invalid':
                                submitted && $v.branch.address.stateId.$error,
                            }"
                          >
                          </v-autocomplete> -->
                          <p
                            v-if="
                              submitted && !$v.branch.address.stateId.required
                            "
                            class="invalid-feedback"
                          >
                            State is Required
                          </p>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            tabindex="8"
                            :items="Editcities"
                            :item-text="'name'"
                            :item-value="'id'"
                            label="City"
                            outlined
                            class="mb-3"
                            :class="{
                              'is-invalid':
                                submitted && $v.branch.address.cityId.$error,
                            }"
                            v-model="branch.address.cityId"
                            :search-input.sync="CitySearch"
                            hide-selected
                            persistent-hint
                            v-on:keyup.enter="createCity"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    No results matching "<strong>{{
                                      CitySearch
                                    }}</strong
                                    >". Press <kbd>enter</kbd> to create a new
                                    one
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-autocomplete>
                          <p
                            v-if="
                              submitted && !$v.branch.address.cityId.required
                            "
                            class="invalid-feedback"
                          >
                            City is Required
                          </p>
                        </v-col>
                        <v-col cols="12" md="6"></v-col>
                      </v-row>

                      <!-- <v-row>
                        <v-col cols="12" md="6">
                        </v-col>
                        <v-col cols="12" md="6"> -->
                      <!-- <v-text-field
                            :class="{
                              'is-invalid':
                                submitted &&
                                $v.branch.address.buildingNumber.$error,
                            }"
                            v-model="branch.address.buildingNumber"
                            outlined
                            class="mb-3"
                            label="Building Number"
                          ></v-text-field>
                          <p
                            v-if="
                              submitted &&
                              !$v.branch.address.buildingNumber.required
                            "
                            class="invalid-feedback"
                          >
                            Building Number is Required
                          </p> -->

                      <!-- </v-col>
                      </v-row> -->
                    </div>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    tabindex="9"
                    text
                    class="cancel-update-btn"
                    @click="close"
                    >Cancel</v-btn
                  >

                  <v-btn
                    tabindex="10"
                    class="create-order-btn mr-1"
                    @click="save"
                    >Update</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            {{ snackText }}

            <template v-slot:action="{ attrs }">
              <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
            </template>
          </v-snackbar>
        </template>
        <template v-slot:item.company="{ item }">
          <p>
            <span>{{ item.company.name }}</span>
          </p>
        </template>
        <template v-slot:item.address="{ item }">
          <p v-if="item.address">
            <span>{{ item.address.street }}</span>
            <!-- -<span v-if="item.address.buildingNumber">{{
              item.address.buildingNumber
            }}</span
            > -->
            -<span v-if="item.address.city"> {{ item.address.city.name }}</span
            >-<span v-if="item.address.state">{{
              item.address.state.target
            }}</span
            >-<span v-if="item.address.country">
              {{ item.address.country.name }}</span
            >
          </p>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2 edit-icon" @click="editItem(item)">
            far fa-edit
          </v-icon>
          <v-icon class="delete-icon" small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { dataService } from "@/services/data-service";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      searchEditStateTerm: "",
      searchEditCityTerm: "",
      Editcities: [],
      EditStates: [],
      dialogDelete: false,
      isLoaded: true,
      companies: [],
      queryTerm: "",
      dialog: false,
      submitted: false,
      snack: false,
      snackColor: "",
      snackText: "",
      sizeOptions: [
        {
          id: 10,
          name: 10,
        },
        {
          id: 20,
          name: 20,
        },
        {
          id: 30,
          name: 30,
        },
        {
          id: 50,
          name: 50,
        },
        {
          id: 100,
          name: 100,
        },
      ],
      totalPages: 0,
      pagination: {
        pageNumber: 1,
        pageSize: 10,
      },

      search: null,
      items: ["foo", "bar", "fizz", "buzz"],
      value: null,
      headers: [
        {
          text: "Name",

          value: "name",
        },
        {
          text: "Company",

          value: "company",
        },

        { text: "Address", value: "address" },

        { text: "Actions", value: "actions" },
      ],
      data: [],
      editedIndex: null,
      branch: {
        companyId: null,
        address: {
          stateId: null,
          countryId: null,
          cityId: null,
          street: "",
          buildingNumber: "",
          postalCode: "",
          details: "",
        },

        name: "",
      },
      countries: [],
      states: [],
      cities: [],
    };
  },
  validations: {
    branch: {
      name: { required },

      address: {
        street: { required },
        // buildingNumber: { required },
        postalCode: { required },
        stateId: { required },
        countryId: { required },
        cityId: { required },
      },
    },
  },
  methods: {
    getStates() {
      this.Editcities = [];
      dataService.getAll(
        "api/States/get_state/" + this.branch.address.countryId,
        (response) => {
          this.EditStates = response.data;
        }
      );
    },
     createState(){
      dataService.create(
        "api/States/create",
        {
          name: this.StateSearch,
          countryId :this.branch.address.countryId
        },
        (response) => {
       
          dataService.getAll(
        "api/States/get_state/" + this.branch.address.countryId,
        (response) => {
          this.EditStates = response.data;
        }
      );
         

          
        
          this.branch.address.stateId = response.data.data;
        }
      );
    },
    createCity() {
      dataService.create(
        "api/Cities/create",
        {
          name: this.CitySearch,
          stateId: this.branch.address.stateId,
        },
        (response) => {
          dataService.getAll(
            "api/Cities/get_city/" + this.branch.address.stateId,
            (res) => {
              this.Editcities = res.data;
              this.branch.address.cityId = response.data.data;
            }
          );
        }
      );
    },
    deleteItem(item) {
      this.editedIndex = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      dataService.postDelete("api/Branches/delete/" + this.editedIndex, () => {
        this.dialogDelete = false;
        this.realSearch(1);
      });
    },
    Editonchange() {
      this.branch.address.stateId = 0;

      this.EditSearchInStates();
    },
    EditSearchInStates() {
      this.Editcities = [];

      if (this.branch.address.countryId == null)
        this.branch.address.countryId = 0;
      if (this.searchEditStateTerm == null) this.searchEditStateTerm = "";

      dataService.getList(
        "api/States/search_by_name-countryId",
        {
          pageNumber: 1,
          pageSize: 1000,
          subString: this.searchEditStateTerm,
          countryId: this.branch.address.countryId,
        },
        (response) => {
          this.EditStates = response.data.data.items;
        }
      );
    },

    EditSearchInCity() {
      if (this.searchEditCityTerm == null) this.searchEditCityTerm = "";
      if (this.branch.address.stateId == null) this.branch.address.stateId = 0;

      dataService.getList(
        "api/Cities/search_sateId_name",

        {
          pageNumber: 1,
          pageSize: 1000,
          subString: this.searchEditCityTerm,
          stateId: this.branch.address.stateId,
        },
        (response) => {
          this.Editcities = response.data.data.items;
        }
      );
    },

    allCompanies(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyCompanies(value);
        }, 1000);
        self.$nextTick(() => self.$refs.lcn.focus());
      } else {
        self.applyCompanies(value);
      }
    },
    applyCompanies(value) {
      dataService.getList(
        "api/Companies/search_by_name",
        {
          pageNumber: 1,
          pageSize: 1000,
          subString: value,
        },
        (response) => {
          this.companies = response.data.data.items;
          this.$nextTick(() => this.$refs.lcn.focus());
        }
      );
    },
    async loadEntries() {
      this.clients = await this.allCompanies(this.queryTerm || "");
    },
    editItem(item) {
      this.dialog = true;
      this.getCountries();
      this.getCities();
      this.getSataes();
      this.loadEntries();
      this.editedIndex = item.id;

      this.branch = item;
      this.branch.companyId = item.company.id;
      this.branch.address.countryId = item.address.country.id;
      this.branch.address.stateId = item.address.state.id;
      this.branch.address.cityId = item.address.city.id;

      this.EditSearchInStates();
      this.EditSearchInCity();
    },
    save() {
      dataService.update(
        "api/Branches/update",
        { branch: this.branch },
        (response) => {
          if (response.data.succeeded) {
            this.dialog = false;
            this.snack = true;
            this.snackColor = "success";
            this.snackText = "Data Updated";
            this.realSearch(1);
          }
        }
      );
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    getCountries() {
      dataService.getAll("/api/Countries", (response) => {
        this.countries = response.data;
      });
    },
    getCities() {
      this.Editcities=[]
     dataService.getAll(
        "api/Cities/get_city/" + this.branch.address.stateId,
        (response) => {
          this.Editcities = response.data;
        }
      );
    },
    getSataes() {
      dataService.getAll("api/States", (response) => {
        this.EditStates = response.data;
      });
    },
    handleOptionsUpdate({ sortBy, sortDesc }) {
      if (sortBy.length > 0) {
        if (sortBy[0] == "company") this.columnIndex = 0;

        this.isDec = sortDesc[0];

        this.realSearch(1);
      }
    },
    realSearch(size) {
      this.isLoaded = true;

      var self = this;
      self.pagination.pageNumber = size;

      dataService.getList(
        "api/Branches/search_by_name",
        {
          pageNumber: size,
          pageSize: self.pagination.pageSize,
          subString: self.search,
          sortedColumnIndex: self.columnIndex,
          isDec: self.isDec,
        },
        (response) => {
          this.isLoaded = false;
          self.data = response.data.data.items;
          self.$nextTick(() => self.$refs.objet.focus());
          self.totalPages = response.data.data.totalPages;
        }
      );
    },
  },
  computed: {
    searchEditState: {
      get() {
        return this.searchEditStateTerm;
      },

      set(searchInput) {
        if (this.searchEditStateTerm !== searchInput) {
          this.searchEditStateTerm = searchInput;
          this.EditSearchInStates();
        }
      },
    },
    searchEditCity: {
      get() {
        return this.searchEditCityTerm;
      },

      set(searchInput) {
        if (this.searchEditCityTerm !== searchInput) {
          this.searchEditCityTerm = searchInput;
          this.EditSearchInCity();
        }
      },
    },

    locationSearch: {
      get() {
        return this.queryTerm;
      },

      set(searchInput) {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput;
          this.loadEntries();
        }
      },
    },
  },
  mounted() {
    this.realSearch(1);
    //this.getCountries();
  },
};
</script>