import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import vuetify from './plugins/vuetify'
import SweetAlert from './plugins/SweetAlert'
import Vuelidate from './plugins/Vuelidate'
import '@/assets/style/index.css'
import './http.js'
import firebase from './plugins/firebase'
import 'vue2-timepicker/dist/VueTimepicker.css'
import VueTimepicker from 'vue2-timepicker'
import '@mdi/font/css/materialdesignicons.css'
import excel from 'vue-excel-export'

Vue.use(excel)

Vue.use(VueTimepicker);

//import "bootstrap/dist/css/bootstrap.min.css";

// import './config.js'






library.add(faUserSecret)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  SweetAlert,
  Vuelidate,
  firebase,


  render: h => h(App)
}).$mount('#app')
