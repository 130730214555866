<template>
  <div class="handle-test">
    <p class="table-title">Create New Location</p>
    <div class="innner-container">
      <!-- =========== btn reset me =======-->
      <v-row>
        <v-col md="10"></v-col>
        <v-col md="2">
          <v-btn @click="emptyButtons" class="resetWO-btn">
            <i class="fas fa-redo"></i> <span class="ml-1">Reset</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-autocomplete
            tabindex="1"
            :class="{
              'is-invalid': submitted4 && $v.branch.companyId.$error,
            }"
            outlined
            v-model="branch.companyId"
            label="Select Company"
            :items="companies"
            :item-value="'id'"
            :item-text="'name'"
            :search-input.sync="search"
            ref="lcn"
          >
          </v-autocomplete>
          <p
            v-if="submitted4 && !$v.branch.companyId.required"
            class="invalid-feedback"
          >
            Company is Required
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            tabindex="2"
            v-model="branch.name"
            outlined
            label="Name"
            :class="{
              'is-invalid': submitted4 && $v.branch.name.$error,
            }"
          ></v-text-field>
          <p
            class="invalid-feedback"
            v-if="submitted4 && !$v.branch.name.required"
          >
            Branch Name is required
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            tabindex="3"
            outlined
            class="mb-3"
            :class="{
              'is-invalid': submitted4 && $v.branch.address.street.$error,
            }"
            v-model="branch.address.street"
            label="Street"
          ></v-text-field>
          <p
            v-if="submitted4 && !$v.branch.address.street.required"
            class="invalid-feedback"
          >
            Street is Required
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            tabindex="5"
            :class="{
              'is-invalid': submitted4 && $v.branch.address.postalCode.$error,
            }"
            v-model="branch.address.postalCode"
            outlined
            class="mb-3"
            label="ZIP / Postal Code"
          ></v-text-field>
          <p
            v-if="submitted4 && !$v.branch.address.postalCode.required"
            class="invalid-feedback"
          >
            Postal Code is Required
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            tabindex="6"
            v-model="branch.address.details"
            outlined
            class="mb-3"
            label="Details"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6"></v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-autocomplete
            tabindex="7"
            :items="countries"
            :item-text="'name'"
            :item-value="'id'"
            label="Country"
            outlined
            v-model="branch.address.countryId"
            class="mb-3"
            :class="{
              'is-invalid': submitted4 && $v.branch.address.countryId.$error,
            }"
            @change="getStates"
          >
          </v-autocomplete>
          <p
            v-if="submitted4 && !$v.branch.address.countryId.required"
            class="invalid-feedback"
          >
            Country is Required
          </p>
        </v-col>
        <v-col cols="12" md="6">


          <v-combobox 
          tabindex="8"
            :items="states"
            :item-text="'name'"
            :item-value="'id'"
            label="State"
            outlined
            v-model="branch.address.stateId"
            class="mb-3"
            :class="{
              'is-invalid': submitted4 && $v.branch.address.stateId.$error,
            }"
            @change="getCities"
           :search-input.sync="StateSearch" hide-selected
              persistent-hint v-on:keyup.enter="createState"  >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ StateSearch }}</strong>". Press <kbd>enter</kbd> to create a new
                      one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          <!-- <v-autocomplete
            tabindex="8"
            :items="states"
            :item-text="'name'"
            :item-value="'id'"
            label="State"
            outlined
            v-model="branch.address.stateId"
            class="mb-3"
            :class="{
              'is-invalid': submitted4 && $v.branch.address.stateId.$error,
            }"
            @change="getCities"
          >
          </v-autocomplete> -->
          <p
            v-if="submitted4 && !$v.branch.address.stateId.required"
            class="invalid-feedback"
          >
            State is Required
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          
          <v-combobox 
             tabindex="9"   
            :item-text="'name'"
            :item-value="'id'"
            label="City"
            outlined
            class="mb-3"
            :class="{
              'is-invalid': submitted4 && $v.branch.address.cityId.$error,
            }"
            v-model="branch.address.cityId" :items="cities"
           :search-input.sync="CitySearch" hide-selected
              persistent-hint v-on:keyup.enter="createCity" 
               @change="getCities">
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ CitySearch }}</strong>". Press <kbd>enter</kbd> to create a new
                      one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          <!-- <v-autocomplete
            tabindex="9"
            :items="cities"
            :item-text="'name'"
            :item-value="'id'"
            label="City"
            outlined
            class="mb-3"
            :class="{
              'is-invalid': submitted4 && $v.branch.address.cityId.$error,
            }"
            v-model="branch.address.cityId"
          >
          </v-autocomplete> -->
          <p
            v-if="submitted4 && !$v.branch.address.cityId.required"
            class="invalid-feedback"
          >
            City is Required
          </p>
        </v-col>
        <v-col cols="12" md="6"></v-col>
      </v-row>

      <!-- <v-row>
        <v-col cols="12" md="6">   
        </v-col>
        <v-col cols="12" md="6"> -->

      <!-- <v-text-field
          tabindex="4"
            :class="{
              'is-invalid':
                submitted4 && $v.branch.address.buildingNumber.$error,
            }"
            v-model="branch.address.buildingNumber"
            outlined
            class="mb-3"
            label="Building Number"
          ></v-text-field>
          <p
            v-if="submitted4 && !$v.branch.address.buildingNumber.required"
            class="invalid-feedback"
          >
            Building Number is Required
          </p> -->
      <!-- </v-col>
      </v-row> -->

      <div class="btn-container">
        <v-btn
          tabindex="10"
          class="create-order-btn"
          @keyup.enter="check()"
          @click="check"
          >Create Location</v-btn
        >
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { dataService } from "@/services/data-service";


export default {
  data() {
    return {
      StateSearch:"",

      CitySearch:"",
      queryTerm: "",
      submitted4: false,
      companies: [],
      countries: [],
      cities: [],
      states: [],
      branch: {
        name: "",
        companyId: null,
        address: {
          street: "",
          buildingNumber: "",
          postalCode: "",
          details: "",
          stateId: null,
          countryId: null,
          cityId: null,
        },
      },
    };
  },
  validations: {
    branch: {
      companyId: { required },
      name: { required },

      address: {
        street: { required },
        // buildingNumber: { required },
        postalCode: { required },

        stateId: { required },
        countryId: { required },
        cityId: { required },
      },
    },
  },
  computed: {
    search: {
      get() {
        return this.queryTerm;
      },

      set(searchInput) {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput;
          this.loadEntries();
        }
      },
    },
  },
  created() {
    this.loadEntries();
  },
  methods: {
    // ========= btn reset mee ==========
    emptyButtons() {
      this.branch = {
        name: null,
        companyId: null,
        address: {
          street: null,
          buildingNumber: null,
          postalCode: null,
          details: null,
          stateId: null,
          countryId: null,
          cityId: null,
        },
      };
    },

    allCompanies(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyCompanies(value);
        }, 1000);
        self.$nextTick(() => self.$refs.lcn.focus());
      } else {
        self.applyCompanies(value);
      }
    },
    applyCompanies(value) {
      dataService.getList(
        "api/Companies/search_by_name",
        {
          pageNumber: 1,
          pageSize: 1000,
          subString: value,
        },
        (response) => {
          this.companies = response.data.data.items;
          this.$nextTick(() => this.$refs.lcn.focus());
        }
      );
    },
    createState(){
      dataService.create(
        "api/States/create",
        {
          name: this.StateSearch,
          countryId :this.branch.address.countryId
        },
        (response) => {
       
          dataService.getAll(
        "api/States/get_state/" + this.branch.address.countryId,
        (response) => {
          this.states = response.data;
        }
      );
         

          
        
          this.branch.address.stateId.id = response.data.data;
        }
      );
    },
    createCity(){
      dataService.create(
        "api/Cities/create",
        {
          name: this.CitySearch,
          stateId:this.branch.address.stateId.id
        },
        (response) => {
          dataService.getAll(
        "api/Cities/get_city/" + this.branch.address.stateId.id,
        (res) => {
          this.cities = res.data;
          this.branch.address.cityId = response.data.data;
        }
      );
         

          
        
        
        }
      );
    },
    async loadEntries() {
      this.clients = await this.allCompanies(this.queryTerm || "");
    },
    getCountries() {
      dataService.getAll("/api/Countries", (response) => {
        this.countries = response.data;
      });
    },
    getCities() {
      dataService.getAll(
        "api/Cities/get_city/" + this.branch.address.stateId.id,
        (response) => {
          this.cities = response.data;
        }
      );
    },
    getStates() {
      this.cities = [];
      dataService.getAll(
        "api/States/get_state/" + this.branch.address.countryId,
        (response) => {
          this.states = response.data;
        }
      );
    },
    check() {
     //debugger; // eslint-disable-line no-debugger
      this.submitted4 = true;
      this.$v.branch.$touch();

      if (this.$v.branch.$invalid) {
        return;
      } else {
        this.branch.address.cityId=this.branch.address.cityId.id;
        this.createLocation();
      }
    },
    createLocation() {
     //debugger; // eslint-disable-line no-debugger
      dataService.create("api/Branches/create", this.branch, (res) => {
        if (res.data.succeeded == true) {
          this.$router.push("/locations/list");
        }
      
      });

     
    },
  },
  mounted() {
    this.getCountries();
  },
};
</script>