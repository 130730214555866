<template>
  <div id="report-timsheet" class="handle-test">
    <v-row style="margin-bottom: 1%;margin-top: -18px;">
      <v-icon v-if="showInvoiceComponent" @click="change">fa fa-arrow-left</v-icon>

      <br />
    </v-row>
    <div v-if="!showInvoiceComponent">
      <v-row>
        <v-col cols="12" md="10">
          <p class="table-title">Ready To Invoice</p>
        </v-col>

        <v-col md="1" cols="12" class="Gml-3">
          <v-btn @click="exportData" class="btn-creation wo-diff-style">
            <img style="padding-right: 10%" src="@/assets/images/export.png" />
            <!-- <v-icon>fa-solid fa-paperclip</v-icon> -->
            Export File
          </v-btn>

          <!--  -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="pad0">
          <v-text-field solo label="Search " append-icon="mdi-magnify" v-model="requestBody.subString" @keyup="getData()"
            ref="objet"></v-text-field>
        </v-col>

        <v-col cols="12" md="8" class="pad0">
          <v-row>
            <v-col cols="12" md="4">

              <v-autocomplete multiple outlined label="Select Clients" :items="clients" item-text="name" item-value="id"
                v-model="clientIds" @change="handleClientFilter">
                <template v-slot:selection="data">
                  <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select"
                    @click:close="removeClient(data.item.id)">
                    {{ data.item.name }}
                  </v-chip>
                </template>
              </v-autocomplete>



            </v-col>
            <v-col cols="12" md="4">

              <v-autocomplete ref="accountSearch" :items="accounts" item-text="name" item-value="id" v-model="accountIds"
                multiple outlined label="Select Accounts" :search-input.sync="accountsearch"
                @change="handleAccountFilter">
                <template v-slot:selection="data">
                  <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select"
                    @click:close="removeAccount(data.item.id)">
                    {{ data.item.name }}
                  </v-chip>
                </template>
              </v-autocomplete>

            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete ref="locationSearch" :items="locations" item-text="name" item-value="id"
                v-model="locationIds" multiple outlined label="Select Locations" @change="handleLocationFilter"
                :search-input.sync="locationSearch">
                <template v-slot:selection="data">
                  <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select"
                    @click:close="removeLocation(data.item.id)">
                    {{ data.item.name }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="pad0">
        </v-col>

        <v-col cols="12" md="8" class="pad0">
          <v-row>
            <v-col cols="12" md="4">
              <v-menu ref="menu" v-model="menuStartDate" :close-on-content-click="false" :return-value.sync="SDFrom"
                transition="scale-transition" offset-y min-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field hint="MM/DD/YYYY format" @blur="SDFrom = parseDate(SDFrom)" v-model="dateFormatted"
                    label="TimeSheet Approval / Start Date" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs"
                    v-on="on" outlined></v-text-field>
                </template>
                <v-date-picker v-model="SDFrom" no-title scrollable @input="$refs.menu.save(SDFrom)"
                  @change="getTimesheetByDate">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuStartDate = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(SDFrom)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4">
              <v-menu ref="menu2" v-model="menuEndDate" :close-on-content-click="false" :return-value.sync="SDTo"
                transition="scale-transition" offset-y min-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field hint="MM/DD/YYYY format" @blur="SDTo = parseDate(SDTo)" v-model="dateFormattedEnd"
                    label="TimeSheet Approval / End Date" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs"
                    v-on="on" outlined></v-text-field>
                </template>
                <v-date-picker v-model="SDTo" no-title scrollable @input="$refs.menu2.save(SDTo)"
                  @change="getTimesheetByDate">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuEndDate = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu2.save(SDTo)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn @click="reset" class="btn-creation diff-style mt-1" color="#D92020">Reset Filter</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-btn v-if="childSelected.length > 0" class="float btn-creation wo-diff-style" @click="sendToInvoice">send To
        Invoice</v-btn>
      <v-data-table :loading="isLoaded" loading-text="Loading... Please wait" :headers="headers" :items="data"
        class="elevation-1" item-key="id" :server-items-length="totalLength" @input="enterSelect($event)"
        v-model="parentSelected" @update:options="handleSorting" show-expand show-select :expanded.sync="expanded"
        hide-default-footer>
        <template v-slot:item.billRate="{ item }">
          <p>
            {{ "$ " + item.billRate }}
          </p>
        </template>


        <template v-slot:item.orderId="{ item }">
          <label style="color: #1976d2cf;
      text-decoration: underline;" @click="navgate(item.id)"> {{ item.orderId }}</label>




        </template>
        <template v-slot:item.totalHours="{ item }">
          <p>
            {{ item.totalHours + " hr" }}
          </p>
        </template>
        <template v-slot:item.totalBillRate="{ item }">
          <p>
            {{ "$ " + item.totalBillRate }}
          </p>
        </template>
        <template v-slot:item.totalTechniciansPayRates="{ item }">
          <p>
            {{ "$ " + item.totalTechniciansPayRates }}
          </p>
        </template>
        <template v-slot:item.totalProfit="{ item }">
          <p>
            {{ "$ " + item.totalProfit }}
          </p>
        </template>

        <!-- ======= color profit========== -->
        <template v-slot:item.totalProfit="{ item }" style="background: red">
          <p v-bind:style="
            item.totalProfit < 0
              ? 'color:#aa5e68; font-weight: bold;'
              : 'color:#b2d4b3;font-weight: bold;'
          ">
            {{ "$ " + item.totalProfit }}
          </p>
        </template>

        <template v-slot:item.paymentTypeName="{ item }" style="background: red">
          <p v-bind:style="
            item.paymentTypeName == 'Hourly'
              ? 'color:#b2d4b3 ;font-weight: bold;'
              : 'color:#aa5e68;font-weight: bold;'
          ">
            {{ item.paymentTypeName }}
          </p>
        </template>

        <template v-slot:item.totalExpense="{ item }">
          <p>
            {{ "$ " + item.totalExpense }}
          </p>
        </template>
        <template #expanded-item="{ headers, item }">
          <td class="px-0 py-2" :colspan="headers.length">
            <v-data-table :sort-by="[
              'id',
              'tecWoId',
              'technicianName',
              'approvalDate',
              'payRate',
              'totalHours',
              'totalPayRate',
              'billHourlyRate',
              'totalExpenseHoures',
              'totalBillaleHours',
              'totalExpenses',
            ]" :server-items-length="item.timeSheets.length" :sort-desc="[false, true]" @click:row="rowClick"
              selectable-key="item" :headers="timeSheetHeaders" :items="item.timeSheets" class="elevation-1" show-select
              item-key="id" hide-default-footer v-model="childSelected" :colspan="7">
              <template v-slot:item.approvalDate="{ item }">
                <p>
                  {{
                    convertUTCDateToLocalDate(
                      new Date(item.approvalDate)
                    ).toLocaleString()
                  }}
                </p>
              </template>
              <template v-slot:item.payRate="{ item }">
                <p>
                  {{ "$" + item.payRate }}
                </p>
              </template>
              <template v-slot:item.totalPayRate="{ item }">
                <p>
                  {{ "$" + item.totalPayRate }}
                </p>
              </template>
              <template v-slot:item.billHourlyRate="{ item }">
                <p>
                  {{ "$" + item.billHourlyRate }}
                </p>
              </template>
              <template v-slot:item.totalExpenses="{ item }">
                <p>
                  {{ "$" + item.totalExpenses }}
                </p>
              </template>
              <template v-slot:item.totalBillaleHours="{ item }">
                <p>
                  {{ item.totalBillaleHours + " hr" }}
                </p>
              </template>
              <template v-slot:item.totalExpenseHoures="{ item }">
                <p>
                  {{ item.totalExpenseHoures + " hr" }}
                </p>
              </template>
              <template v-slot:item.totalHours="{ item }">
                <p>
                  {{ item.totalHours + " hr" }}
                </p>
              </template>
            </v-data-table>
          </td>
        </template>
        <template v-slot:footer>
          <v-row class="mt-5 fit-width">
            <v-col md="2">
              <v-select :items="sizeOptions" v-model="requestBody.pageSize" :item-value="'id'" :item-text="'name'"
                @change="resetPageNumber()" solo></v-select>
            </v-col>
            <v-col md="5">
              <v-pagination v-model="requestBody.pageNumber" :length="totalPages" @input="getData()"
                total-visible="6"></v-pagination>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </div>
    <CreateInvoice v-if="showInvoiceComponent" :childSelected="childSelected"></CreateInvoice>
  </div>
</template>
<script>
import { dataService } from "@/services/data-service";
import CreateInvoice from "./CreateInvoice.vue";
import { excelParser } from "@/excel-parser";
import store from "@/store";
export default {
  data: () => ({
    dateFormattedEnd: null,
    dateFormatted: null,
    accountTerm: "",
    isLoaded: true,
    childSelected: [],
    parentSelected: [],
    expanded: [],
    menuStartDate: false,
    menuEndDate: false,
    SDTo: null,
    SDFrom: null,
    clients: [],
    accounts: [],
    accountIds: [],
    clientIds: [],
    locations: [],
    locationIds: [],
    sizeOptions: [
      {
        id: 2,
        name: 2,
      },
      {
        id: 10,
        name: 10,
      },
      {
        id: 20,
        name: 20,
      },
      {
        id: 30,
        name: 30,
      },
      {
        id: 50,
        name: 50,
      },
      {
        id: 100,
        name: 100,
      },
    ],
    search: "",
    headers: [

      { text: "Master Work OrderId", value: "orderId" },
      { text: "No. Of Technician", value: "numberOfTech" },
      { text: "Client", value: "clientName" },
      { text: "Account", value: "accountName" },
      { text: "Location", value: "location" },
      { text: "Payment Type", value: "paymentTypeName" },
      { text: "Σ Payable Hours", value: "totalPayableHours" },
      { text: "Σ Payable Amount", value: "totalTechniciansPayRates" },
      { text: "Bill Rate", value: "billRate" },
      { text: "Σ Billable Hours", value: "totalHours" },
      { text: "Σ Expenses Amount", value: "totalExpense" },
      { text: "Σ Billable Amount", value: "totalBillRate" },
      { text: "Σ Profit", value: "totalProfit" },
    ],

    timeSheetHeaders: [
      { text: "Timesheet ID", value: "id" },
      { text: "Sub WorkOrderId", value: "tecWoId" },
      { text: "Technician Name", value: "technicianName" },
      { text: "approval Date", value: "approvalDate" },
      { text: "Pay Rate", value: "payRate" },
      { text: "Σ TimeSheet Hours", value: "totalHours" },
      { text: "Σ Payable Amount", value: "totalPayRate" },

      { text: "Bill Rate", value: "billHourlyRate" },

      { text: "Σ Expenses Hours", value: "totalExpenseHoures" },
      { text: "Σ Billable Hours", value: "totalBillaleHours" },

      { text: "Σ Expenses Amount", value: "totalExpenses" },
    ],

    ExpenseHeaders: [
      { text: "Expense Type", value: "workOrderExpenseTypeName" },
      { text: "Amount", value: "expenseAmount" },
      { text: "Amount Type", value: "amountTypeName" },
      { text: "Description", value: "description" },
    ],
    data: [],
    totalPages: 0,
    totalLength: 0,
    locationTerm: "",
    requestBody: {
      pageNumber: 1,
      pageSize: 10,
      subString: "",
    },
    showInvoiceComponent: false,
  }),

  methods: {
    exportData() {
      let sampleData = [

      ];



      this.isLoaded = true;


      dataService.getList(
        "api/Invoices/ready-to-invoice",
        this.requestBody,
        (response) => {

          this.isLoaded = false;
          console.log(response)

          debugger; // eslint-disable-line no-debugger

          sampleData = response.data.data;




          sampleData.forEach(element => {

            element.approvalDate = this.convertUTCDateToLocalDate(
              new Date(element.approvalDate)
            ).toLocaleString()

            const approvedDateArray = element.approvalDate.split(",");
            let ApprovedDate = approvedDateArray[0];
            element.approvalDate = ApprovedDate
          })

          var currentdate = new Date();
          var fileName =
            "report " +
            currentdate.getDate() +
            "/" +
            (currentdate.getMonth() + 1) +
            "/" +
            currentdate.getFullYear();

          excelParser().exportDataFromJSON(sampleData, fileName, null);
        }



      );



    },
    navgate(item) {
      this.$router.push("/show-work-order/" + item);

    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    removeAccount(item) {
      const index = this.accountIds.indexOf(item);
      if (index >= 0) this.accountIds.splice(index, 1);
      this.requestBody.accountIds = this.accountIds;
      this.getData();
    },
    removeLocation(item) {
      const index = this.locationIds.indexOf(item);
      if (index >= 0) this.locationIds.splice(index, 1);
      this.requestBody.branchIds = this.locationIds;
      this.getData();
    },
    removeClient(item) {
      const index = this.clientIds.indexOf(item);
      if (index >= 0) this.clientIds.splice(index, 1);
      this.requestBody.clientIds = this.clientIds;
      this.getData();
    },

    enterSelect(event) {

      let workOrderDetails = event;

      this.childSelected = [];
      workOrderDetails.forEach((wo) => {
        wo.timeSheets.forEach((timeSheet) => {

          this.childSelected.push(timeSheet);
        });
      });


    },
    handleSorting({ sortBy, sortDesc }) {


      if (sortBy.length > 0) {
        if (sortBy[0] == "orderId") this.requestBody.sortedColumnIndex = 1;
        if (sortBy[0] == "numberOfTech") this.requestBody.sortedColumnIndex = 2;
        if (sortBy[0] == "clientName") this.requestBody.sortedColumnIndex = 3;

        if (sortBy[0] == "accountName") this.requestBody.sortedColumnIndex = 4;
        if (sortBy[0] == "location") this.requestBody.sortedColumnIndex = 5;
        if (sortBy[0] == "paymentTypeName")
          this.requestBody.sortedColumnIndex = 6;
        if (sortBy[0] == "totalPayableHours")
          this.requestBody.sortedColumnIndex = 7;

        if (sortBy[0] == "totalTechniciansPayRates")
          this.requestBody.sortedColumnIndex = 8;
        if (sortBy[0] == "billRate") this.requestBody.sortedColumnIndex = 9;
        if (sortBy[0] == "totalHours") this.requestBody.sortedColumnIndex = 10;
        if (sortBy[0] == "totalExpense")
          this.requestBody.sortedColumnIndex = 11;
        if (sortBy[0] == "totalBillRate")
          this.requestBody.sortedColumnIndex = 12;

        if (sortBy[0] == "totalProfit") this.requestBody.sortedColumnIndex = 13;

        this.requestBody.isDec = sortDesc[0];
        this.getData();
      }
    },

    itemRowBackground: function (item) {

      var styleClass = "";
      if (item.paymentTypeName === "Hourly") {
        styleClass = "HourlyType";
      } else {
        styleClass = "FixedType";
      }
      return styleClass;
    },
    async loadAccounts() {
      this.accounts = await this.getAccounts(this.accountTerm || "");
    },
    convertUTCDateToLocalDate(date) {
      var newDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60 * 1000
      );
      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();
      newDate.setHours(hours - offset);
      return newDate;
    },
    rowClick: function (item, row) {
      row.select(true);


      localStorage.tecWoId = item.tecWoId;
      localStorage.tecName = item.technicianName;
      localStorage.isPending = false;
      localStorage.timeSheetId = item.id;
      this.$router.push("/reports/TimeSheetDetails/" + item.id);
    },

    resetPageNumber() {
      this.requestBody.pageNumber = 1;
      this.getData();
    },
    sendToInvoice() {
      this.showInvoiceComponent = true;
    },
    change() {
      this.showInvoiceComponent = false;
    },
    reset() {
      (this.menuStartDate = false),
        (this.menuEndDate = false),
        (this.SDTo = null),
        (this.SDFrom = null),
        (this.clientIds = []),
        (this.locationIds = []),
        (this.requestBody = {
          pageNumber: 1,
          pageSize: 10,
          subString: "",
        });
      this.getData();
    },
    realSearch() {
      this.requestBody.pageNumber = 1;
      clearTimeout(this.timeout);
      var self = this;

      this.timeout = setTimeout(function () {
        self.getData();
      }, 1000);
    },
    getTimesheetByDate() {
      if (this.SDFrom && !this.SDTo) {
        return;
      }
      if (!this.SDFrom) {
        store.commit("SHOW_MESSAGE", {
          text: "Please Select Start Date first",
          color: "error",
          timeout: 3000,
        });
        return;
      } else {
        this.requestBody.creationDate = {
          from: this.SDFrom + "T00:00:00Z",
          to: this.SDTo + "T00:00:00Z",
        };

        this.getData();
      }
    },
    handleClientFilter() {
      this.requestBody.clientIds = this.clientIds;
      this.getData();
    },
    getClients() {
      dataService.getAll(
        "api/Clients/get-all",

        (response) => {
          this.clients = response.data;
        }
      );
    },

    handleLocationFilter() {
      this.requestBody.branchIds = this.locationIds;
      this.getData();
    },
    async loadLocations() {
      this.accounts = await this.getLocations(this.locationTerm || "");
    },
    getLocations(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyLocations(value);
        }, 1000);
      } else {
        self.applyLocations(value);
      }
    },
    applyLocations(value) {
      dataService.getList(
        "api/Branches/search_by_name",
        {
          pageNumber: 1,
          pageSize: 200,
          subString: value,
        },
        (response) => {
          this.locations = response.data.data.items;
          if (value) {
            this.$nextTick(() => this.$refs.locationSearch.focus());
          }
        }
      );
    },

    handleAccountFilter() {
      var companyIds = [];

      this.requestBody.accountIds = this.accountIds;
      if (this.accountIds.length > 0) {
        dataService.create(
          "api/Companies/accounts",
          this.accountIds,
          (response) => {
            this.companies = response.data.data;
            for (var c = 0; c < this.companies.length; c++) {
              companyIds.push(this.companies[c].id);
            }
            dataService.create(
              "api/Branches/companies",
              companyIds,
              (response) => {
                this.locations = response.data.data;
              }
            );
            this.getData();
          }
        );
      } else {
        this.loadAccounts();
        this.loadLocations();
        this.getData();
      }
    },
    getAccounts(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyAccounts(value);
        }, 1000);
      } else {
        self.applyAccounts(value);
      }
    },
    applyAccounts(value) {
      dataService.getList(
        "api/Accounts/search_by_name",
        {
          pageNumber: 1,
          pageSize: 100,
          subString: value,
        },
        (response) => {
          this.accounts = response.data.data.items;
          if (value) {
            this.$nextTick(() => this.$refs.accountSearch.focus());
          }
        }
      );
    },
    handleOptionsUpdate({ page, itemsPerPage }) {
      this.requestBody.pageNumber = page;
      this.requestBody.pageSize = itemsPerPage;
      if (itemsPerPage == -1) {
        this.requestBody.pageNumber = 1;
        this.requestBody.pageSize = this.totalLength;
      }
      this.getData();
    },
    getData() {
      this.isLoaded = true;
      dataService.getList(
        "api/Invoices/get-time-sheet-list",
        this.requestBody,
        (response) => {
          this.isLoaded = false;
          this.data = response.data.data.items;
          this.totalLength = response.data.data.totalCount;
          this.totalPages = response.data.data.totalPages;

          if (this.requestBody.subString) {
            this.$nextTick(() => this.$refs.objet.focus());
          }
        }
      );
    },
  },
  mounted() {
    this.getData();
    this.getClients();
  },
  created() {
    this.loadLocations();
  },
  components: {
    CreateInvoice,
  },
  watch: {
    SDFrom() {
      this.dateFormatted = this.formatDate(this.SDFrom)
    },
    SDTo() {
      this.dateFormattedEnd = this.formatDate(this.SDTo)
    }
  },
  computed: {
    computedStartDateFormatted() {
      return this.formatDate(this.SDFrom)
    },
    computedEndDateFormatted() {
      return this.formatDate(this.SDTo)
    },

    accountsearch: {
      get() {
        return this.accountTerm;
      },
      set(searchInput) {
        if (this.accountTerm !== searchInput) {
          this.accountTerm = searchInput;
          this.loadAccounts();
        }
      },
    },
    locationSearch: {
      get() {
        return this.locationTerm;
      },

      set(searchInput) {
        if (this.locationTerm !== searchInput) {
          this.locationTerm = searchInput;
          this.loadLocations();
        }
      },
    },
  },
};
</script>


