<template>
  <div>
    <v-row>
      <v-col md="3" cols="3">
        <SideMenu></SideMenu>
      </v-col>
      <v-col md="9" cols="9" id="notification">
        <v-simple-table class="mt-9 mr-15">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left"></th>

                <!-- <th class="text-left header-name">Push</th> -->
                <th class="text-left header-name">Email</th>
                <th class="text-left header-name">Text</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in topics" :key="item.id"
             >
                <td  v-if="item.topic.id<6">
                  <p class="body-name">{{ item.topic.name }}</p>
                </td>
                <!-- <td>
                  <v-switch
                    color="#2A8D3D"
                    inset
                    v-model="item.push"
                    @change="updateNotification(item)"
                  ></v-switch>
                </td> -->
                <td  v-if="item.topic.id<6">
                  <v-switch
                    color="#2A8D3D"
                    inset
                    v-model="item.email"
                    @change="updateNotification(item)"
                  ></v-switch>
                </td>
                <td  v-if="item.topic.id<6">
                  <v-switch
                  v-if="item.disabled"
                    color="#2A8D3D"
                    inset
                    v-model="item.text"
                    @change="updateNotification(item)"
                  ></v-switch>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SideMenu from "@/components/SettingsSideMenu.vue";
import { dataService } from "@/services/data-service";

export default {
  data() {
    return {
      userNumber: 0,
      topics: [],
    };
  },
  components: {
    SideMenu,
  },
  methods: {
    getUserNumber() {
      dataService.getAll("api/SystemUsers/current_user_number", (response) => {
        this.userNumber = response.data;
        this.getTopics();
      });
    },
    getTopics() {
      dataService.getAll("api/Notifications/topic", (response) => {
        var result = response.data;
        this.topics = [];
        for (var i = 0; i < result.length; i++) {
          if (result[i].id == 4) {
            this.topics.push({
              topic: {
                id: result[i].id,
                name: result[i].name,
              },
              email: false,
              push: false,
              text: false,
              disabled: true,
            });
          } else {
            this.topics.push({
              topic: {
                id: result[i].id,
                name: result[i].name,
              },
              email: false,
              push: false,
              text: false,
              disabled: false,
            });
          }
        }

        dataService.getAll(
          "api/Notifications/user-topic/" + this.userNumber,
          (response) => {
            for (var t = 0; t < this.topics.length; t++) {
              for (var ut = 0; ut < response.data.length; ut++) {
                if (this.topics[t].topic.id == response.data[ut].topic.id) {
                  this.topics[t].push = response.data[ut].push;
                  this.topics[t].email = response.data[ut].email;
                  this.topics[t].text = response.data[ut].text;
                }
              }
            }
          }
        );
      });
    },
    updateNotification(item) {
      dataService.update(
        "api/Notifications/user-topic",
        {
          userNumber: this.userNumber,
          email: item.email,
          push: item.push,
          text: item.text,
          topic: item.topic.id,
        },
        () => {
          //console.log(response);
          this.getTopics();
        }
      );
    },
  },
  mounted() {
    this.getUserNumber();
  },
};
</script>