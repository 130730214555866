<template>
  <div class="handle-test">
    <p class="table-title">New Technician</p>
    <div class="innner-container">
      <!-- =========== btn reset me =======-->
      <v-row>
        <v-col md="10"></v-col>
        <v-col md="2">
          <v-btn @click="emptyButtons" class="resetWO-btn">
            <i class="fas fa-redo"></i> <span class="ml-1">Reset</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            tabindex="1"
            :class="{
              'is-invalid': submitted && $v.tech.firstName.$error,
            }"
            v-model="tech.firstName"
            outlined
            class="mb-3"
            label="First Name"
          ></v-text-field>
          <p
            v-if="submitted && !$v.tech.firstName.required"
            class="invalid-feedback"
          >
            First Name is Required
          </p>
          <v-text-field
            tabindex="3"
            :class="{
              'is-invalid': submitted && $v.tech.email.$error,
            }"
            v-model="tech.email"
            outlined
            class="mb-3"
            label="Email"
          ></v-text-field>
          <p
            v-if="submitted && !$v.tech.email.required"
            class="invalid-feedback"
          >
            Email is Required
          </p>
          <p v-if="submitted && !$v.tech.email.email" class="invalid-feedback">
            Email is not valid
          </p>

          <v-text-field
            tabindex="5"
            outlined
            class="mb-3"
            v-model="secondaryPhone"
            label="Secondary Phone"
            @blur="formatPhoneNumber(2)"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            tabindex="2"
            outlined
            v-model="tech.lastName"
            class="mb-3"
            :class="{
              'is-invalid': submitted && $v.tech.lastName.$error,
            }"
            label="Last Name"
          ></v-text-field>
          <p
            v-if="submitted && !$v.tech.lastName.required"
            class="invalid-feedback"
          >
            Last Name is Required
          </p>

          <v-text-field
            tabindex="4"
            outlined
            class="mb-3"
            v-model="tech.technicianPhones[0].phoneNumber"
            :set="(v = $v.tech.technicianPhones.$each[0])"
            :class="{
              'is-invalid': submitted && v.phoneNumber.$error,
            }"
            label="Primary Phone"
            @blur="formatPhoneNumber(1)"
          ></v-text-field>
          <p
            v-if="submitted && !v.phoneNumber.required"
            class="invalid-feedback"
          >
            Primary Phone is Required
          </p>
        </v-col>
      </v-row>
      <v-divider class="mb-10"></v-divider>
      <div>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              tabindex="6"
              outlined
              class="mb-3"
              v-model="tech.address.street"
              label="Street"
            ></v-text-field>
            <!-- <p
             :class="{
                'is-invalid': submitted && $v.tech.address.street.$error,
              }"
              v-if="submitted && !$v.tech.address.street.required"
              class="invalid-feedback"
            >
              Street is Required
            </p> -->
            <v-text-field
              tabindex="8"
              v-model="tech.address.details"
              outlined
              class="mb-3"
              label="Details"
            ></v-text-field>

            <v-autocomplete
              tabindex="10"
              :items="states"
              :item-text="'name'"
              :item-value="'id'"
              label="State"
              outlined
              v-model="tech.address.stateId"
              class="mb-3"
              @change="getCities"
            >
            </v-autocomplete>

            <!-- <p
            :class="{
                'is-invalid': submitted && $v.tech.address.countryId.$error,
              }"
              v-if="submitted && !$v.tech.address.countryId.required"
              class="invalid-feedback"
            >
              Country is Required
            </p> -->

            <v-autocomplete
              tabindex="12"
              :items="levels"
              :item-text="'name'"
              :item-value="'id'"
              label="Level"
              outlined
              class="mb-3"
              :class="{
                'is-invalid': submitted && $v.tech.levelId.$error,
              }"
              v-model="tech.levelId"
            >
            </v-autocomplete>

            <v-row>
              <v-col md="9">
                <p
                  v-if="submitted && !$v.tech.levelId.required"
                  class="invalid-feedback ml-3"
                >
                  Level is Required
                </p>
              </v-col>
            </v-row>

            <!-- <p
             :class="{
                'is-invalid': submitted && $v.tech.address.cityId.$error,
              }"
              v-if="submitted && !$v.tech.address.cityId.required"
              class="invalid-feedback"
            >
              City is Required
            </p> -->
            <v-text-field
              tabindex="14"
              v-model="tech.hourlyRate"
              outlined
              :class="{
                'is-invalid': submitted && $v.tech.hourlyRate.$error,
              }"
              class="mb-3"
              label="Hourly Rate"
            ></v-text-field>
            <p
              v-if="submitted && !$v.tech.hourlyRate.required"
              class="invalid-feedback"
            >
              Hourly Rate is Required
            </p>
          </v-col>
          <v-col cols="12" md="6">
            <!-- <v-text-field
            tabindex="7"
              v-model="tech.address.buildingNumber"
              outlined
              class="mb-3"
              label="Building Number"
            ></v-text-field> -->
            <!-- <p
            :class="{
                'is-invalid':
                  submitted && $v.tech.address.buildingNumber.$error,
              }"
              v-if="submitted && !$v.tech.address.buildingNumber.required"
              class="invalid-feedback"
            >
              Building Number is Required
            </p> -->
            <v-text-field
              tabindex="7"
              v-model="tech.address.postalCode"
              outlined
              class="mb-3"
              label="ZIP / Postal Code"
            ></v-text-field>
            <!-- <p
            :class="{
                'is-invalid': submitted && $v.tech.address.postalCode.$error,
              }"
              v-if="submitted && !$v.tech.address.postalCode.required"
              class="invalid-feedback"
            >
              Postal Code is Required
            </p> -->

            <v-autocomplete
              tabindex="9"
              :items="countries"
              :item-text="'name'"
              :item-value="'id'"
              label="Country"
              outlined
              v-model="tech.address.countryId"
              class="mb-3"
              @change="getStates"
            >
            </v-autocomplete>

            <!-- <p
             :class="{
                'is-invalid': submitted && $v.tech.address.stateId.$error,
              }"
              v-if="submitted && !$v.tech.address.stateId.required"
              class="invalid-feedback"
            >
              State is Required
            </p> -->

            <v-autocomplete
              tabindex="11"
              :items="cities"
              :item-text="'name'"
              :item-value="'id'"
              label="City"
              outlined
              class="mb-3"
              v-model="tech.address.cityId"
            >
            </v-autocomplete>

            <v-autocomplete
              tabindex="13"
              :items="techTypes"
              :item-text="'name'"
              :item-value="'id'"
              label="Type"
              outlined
              class="mb-3"
              :class="{
                'is-invalid': submitted && $v.tech.technicianTypeId.$error,
              }"
              v-model="tech.technicianTypeId"
            >
            </v-autocomplete>
            <p
              v-if="submitted && !$v.tech.technicianTypeId.required"
              class="invalid-feedback ml-3"
            >
              Technician Type is Required
            </p>
          </v-col>
        </v-row>
      </div>
      <div class="btn-container">
        <v-btn
          tabindex="15"
          @keyup.enter="check()"
          class="create-order-btn mr-1"
          @click="check"
          >Create Technician</v-btn
        >
      </div>
    </div>
  </div>
</template>
<script>
import { dataService } from "@/services/data-service";
import { required, email } from "vuelidate/lib/validators";
import store from "@/store";

export default {
  data() {
    return {
      isprimary: 1,
      countries: [],
      cities: [],
      levels: [],
      states: [],
      submitted: false,
      dialog: false,
      secondaryPhone: "",
      techTypes: [
        {
          id: 1,
          name: "W-2",
        },
        {
          id: 2,
          name: "1099",
        },
        {
          id: 3,
          name: "TEMP",
        },
        {
          id: 4,
          name: "Vendor",
        },
      ],
      tech: {
        firstName: "",
        lastName: "",
        email: "",
        levelId: null,
        technicianTypeId: null,
        hourlyRate: null,

        address: {
          street: "",
          buildingNumber: "",
          postalCode: "",
          details: "",
          stateId: null,
          countryId: null,
          cityId: null,
        },
        technicianPhones: [
          {
            phoneNumber: "",
            isPrimary: true,
          },
        ],
      },
    };
  },
  validations: {
    tech: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      levelId: { required },
      hourlyRate: { required },
      technicianTypeId: { required },
      // address: {
      //   street: { required },
      //   buildingNumber: { required },
      //   postalCode: { required },
      //   // details: { required },
      //   stateId: { required },
      //   countryId: { required },
      //   cityId: { required },
      // },
      technicianPhones: {
        $each: {
          phoneNumber: { required },
        },
      },
    },
  },
  methods: {
    //========  btn reset ===============
    emptyButtons() {
      (this.secondaryPhone = null),
        (this.tech = {
          firstName: null,
          lastName: null,
          email: null,
          levelId: null,
          technicianTypeId: null,
          hourlyRate: null,

          address: {
            street: null,
            buildingNumber: null,
            postalCode: null,
            details: null,
            stateId: null,
            countryId: null,
            cityId: null,
          },
          technicianPhones: [
            {
              phoneNumber: null,
              isPrimary: true,
            },
          ],
        });
    },
    //============================

    formatPhoneNumber(isprimary) {
      if (isprimary == 1) {
        var cleanedprimaryphonenumber = (
          "" + this.tech.technicianPhones[0].phoneNumber
        ).replace(/\D/g, "");
        var matchprimaryphonenumber = cleanedprimaryphonenumber.match(
          /^(1|)?(\d{3})(\d{3})(\d{4})$/
        );
        if (matchprimaryphonenumber) {
          var intlCodeprimaryphonenumber = matchprimaryphonenumber[1]
            ? "+1 "
            : "";
          this.tech.technicianPhones[0].phoneNumber = [
            intlCodeprimaryphonenumber,
            "(",
            matchprimaryphonenumber[2],
            ") ",
            matchprimaryphonenumber[3],
            "-",
            matchprimaryphonenumber[4],
          ].join("");
        }
      } else {
        var cleanedsecondryphonenumber = ("" + this.secondaryPhone).replace(
          /\D/g,
          ""
        );
        var matchsecondryphonenumber = cleanedsecondryphonenumber.match(
          /^(1|)?(\d{3})(\d{3})(\d{4})$/
        );
        if (matchsecondryphonenumber) {
          var intlCodesecondryphonenumber = matchsecondryphonenumber[1]
            ? "+1 "
            : "";
          this.secondaryPhone = [
            intlCodesecondryphonenumber,
            "(",
            matchsecondryphonenumber[2],
            ") ",
            matchsecondryphonenumber[3],
            "-",
            matchsecondryphonenumber[4],
          ].join("");
        }
      }
    },
    //======================================
    check() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.createTech();
      }
    },
    createTech() {
      if (this.secondaryPhone) {
        this.tech.technicianPhones.push({
          phoneNumber: this.secondaryPhone,
          isPrimary: false,
        });
      }
      dataService.create("api/Technicians/create", this.tech, (response) => {
        //console.log(response);
        if (response.data.succeeded) {
          store.commit("SHOW_MESSAGE", {
            text: "Created Successfully",
            color: "success",
            timeout: 3000,
          });
          this.$router.push("/technicians/list");
        }
      });
    },
    getLevels() {
      dataService.getAll("api/Enums/levels", (response) => {
        this.levels = response.data;
      });
    },
    getCountries() {
      dataService.getAll("/api/Countries", (response) => {
        this.countries = response.data;
      });
    },
    getCities() {
      dataService.getAll(
        "api/Cities/get_city/" + this.tech.address.stateId,
        (response) => {
          this.cities = response.data;
        }
      );
    },
    getStates() {
      dataService.getAll(
        "api/States/get_state/" + this.tech.address.countryId,
        (response) => {
          this.states = response.data;
        }
      );
    },
  },
  mounted() {
    this.getCountries();

    this.getLevels();
  },
};
</script>