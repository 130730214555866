<template>
  <div class="upload-section" id="users">
    <SideMenu v-if="!$vuetify.breakpoint.xsOnly"></SideMenu>
    <div class="user-creation ml-4 mt-4">
      <div>
        <div class="profile-card">
          <i @click="editMode = !editMode" class="fas fa-edit edit-profile"></i>
          <br />
         
          <v-avatar color="#172450" style="width: 48px; height: 48px">
            <span v-if="profilepic" class="white--text text-h6">{{
              profilepic
            }}</span>
          </v-avatar>
          <span
            class="Profile-data"
            style="color: rgb(23, 36, 80); font-size: 18px"
          >
            {{ profileName }}
          </span>

         

          <v-row v-if="editMode != true" style="margin-left: 9%">
            <v-col md="2">
              <p class="info-lebel">First Name</p>
              <p class="info-lebel">Last Name</p>
              <p class="info-lebel">Address</p>

              <p class="info-lebel">Email</p>
              <p class="info-lebel">Phone</p>
            </v-col>
            <v-col md="3">
              <p v-if="userProfile.firstName" class="info-value">
                {{ userProfile.firstName }}
              </p>
              <p v-else class="info-value">-</p>
              <p v-if="userProfile.lastName" class="info-value">
                {{ userProfile.lastName }}
              </p>
              <p v-else class="info-value">-</p>
              <p v-if="userProfile.address" class="info-value">
                {{ userProfile.address }}
              </p>

              <p v-else class="info-value">-</p>
              <p v-if="userProfile.email" class="info-value">
                {{ userProfile.email }}
              </p>
              <p v-else class="info-value">-</p>
              <p v-if="userProfile.phoneNumber" class="info-value">
                {{ userProfile.phoneNumber }}
              </p>
              <p v-else class="info-value">-</p>
            </v-col>
          </v-row>
          <v-row v-else style="margin-left: 9%">
            <v-col md="2">
              <p class="info-lebel">First Name</p>
              <p class="info-lebel mt-11">Last Name</p>
              <p class="info-lebel mt-11">Address</p>
              <p class="info-lebel">Phone</p>
            </v-col>

            <v-col id="editProfile" md="3">
              <v-text-field
                class="mt-7"
                solo
                v-model="userProfile.firstName"
              ></v-text-field>
              <v-text-field solo v-model="userProfile.lastName"></v-text-field>
              <v-text-field solo v-model="userProfile.address"></v-text-field>
              <v-text-field
                solo
                v-model="userProfile.phoneNumber"
                @blur="formatPhoneNumber()"
              ></v-text-field>

              <v-btn @click="updateProfileData" class="edit-profile-btn"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        
          <span
        v-if="userProfile.lastResetURL!== ''"
           
            style="color: rgb(23, 36, 80); font-size: 18px"
          >
         
<a :href="userProfile.lastResetURL"> Reset Link Is available: Please right click and copy link </a>
      
          </span>
        </div>


        <div class="profile-card">
          <div>
            <v-row style="margin: 0px">
              <span
                class="Profile-data"
                style="color: rgb(23, 36, 80); font-size: 18px"
              >
                System Settings</span
              >
              <i
                @click="editModeSyatemSettings = !editModeSyatemSettings"
                class="fas fa-edit edit-profile"
              ></i
            ></v-row>

            <br />
            <div v-if="editModeSyatemSettings == true">
              <v-row>
                <v-col md="6">
                  <v-autocomplete
                    v-if="userProfile.systemUserTypes"
                    :items="userTypes"
                    :item-value="'id'"
                    :item-text="'name'"
                    outlined
                    label="Select Type"
                    v-model="userProfile.systemUserTypes[0].id"
                    @change="getGroups"
                  ></v-autocomplete>
                </v-col>
                <v-col md="6"></v-col>
              </v-row>
              <!-- <div id="creae-user">
                <v-tabs v-model="tab" align-with-title>
                  <v-tabs-slider color="white"></v-tabs-slider>
                  <v-tab
                    active-class="activeTab"
                    v-for="item in permissions"
                    :key="item.id"
                    class="non-active"
                  >
                    {{ item.name }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <div v-for="item in permissions" :key="item.id + 'I'">
                    <v-tab-item>
                      <v-checkbox
                        v-for="group in item.groups"
                        :key="group.id + 'G'"
                        color="#3ac127"
                        v-model="group.checkBox"
                        :label="group.description"
                        class="role-name"
                        @click="pushRoles(group)"
                      ></v-checkbox>
                    </v-tab-item>
                  </div>
                </v-tabs-items>
              </div> -->
              <v-row style="margin-top: 2%">
                <v-col md="4"></v-col>
                <v-col md="4"></v-col>
                <!-- ====================================== -->
                <v-col md="4">
                  <v-btn @click="updatePermission" class="edit-profile-btn"
                    >Save</v-btn
                  >
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <v-row>
                <v-chip
                  class=""
                  style="margin-bottom: 4%; margin-left: 5%; margin-top: 1%"
                  color="green"
                  text-color="white"
                >
                  {{ userProfile.systemUserTypes[0].name }}
                </v-chip>
              </v-row>

          
              <!-- <div id="creae-user" style="margin-left: 3%">
                <v-tabs v-model="tab" align-with-title>
                  <v-tabs-slider color="white"></v-tabs-slider>
                  <v-tab
                    active-class="activeTab"
                    v-for="item in permissions"
                    :key="item.id"
                    class="non-active"
                  >
                    {{ item.name }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <div v-for="item in permissions" :key="item.id + 'I'">
                    <v-tab-item>
                      <v-checkbox
                        v-for="group in item.groups"
                        disabled
                        :key="group.id + 'G'"
                        color="#3ac127"
                        v-model="group.checkBox"
                        :label="group.description"
                        class="role-name"
                        @click="pushRoles(group)"
                      ></v-checkbox>
                    </v-tab-item>
                  </div>
                </v-tabs-items>
              </div> -->
            </div>
          </div>
        </div>

        <div class="profile-card">
          <v-row style="margin: 0px">
            <span
              class="Profile-data"
              style="color: rgb(23, 36, 80); font-size: 18px"
            >
              Client Settings</span
            >
            <i
              @click="editClientsSettings = !editClientsSettings"
              class="fas fa-edit edit-profile"
            ></i
          ></v-row>
          <div v-if="editClientsSettings == true" style="margin-top: 2%">
            <v-row>
              <v-col md="12">
                <v-autocomplete
                  ref="clientSearch"
                  multiple
                  outlined
                  label="Select Clients"
                  :items="clients"
                  item-text="name"
                  item-value="id"
                  v-model="clientPrivillages"
                  @change="getAccounts"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="RemoveClient(data.item.id)"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col md="12">
                <v-autocomplete
                  ref="accountsearch"
                  v-model="accountPrivillages"
                  outlined
                  chips
                  :items="accounts"
                  :item-value="'id'"
                  :item-text="'name'"
                  @change="getCompanies"
                  label="Select Account"
                  multiple
                  :search-input.sync="accountsearch"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      color="#7cab9a54"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="RemoveAccount(data.item.id)"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col md="12">
                <v-autocomplete
                  v-model="companyPrivillages"
                  outlined
                  chips
                  :items="companies"
                  :item-value="'id'"
                  :item-text="'name'"
                  @change="getLocations"
                  label="Select Company"
                  multiple
                  :search-input.sync="companysearch"
                  ref="companysearch"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                        color="#7cab9a54"
                      @click="data.select"
                      @click:close="RemoveCompany(data.item.id)"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              
              <v-col md="12">
                <v-autocomplete
                  v-model="locationPrivillages"
                  outlined
                  chips
                  :items="locations"
                  :item-value="'id'"
                  :item-text="'name'"
                  label="Select Location"
                  multiple
                  :search-input.sync="locationSearch"
                  ref="locationSearch"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      color="#7cab9a54"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="RemoveLocation(data.item.id)"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="4"></v-col>
              <v-col md="4"></v-col>
              <v-col md="4">
                <v-btn @click="updateDataPrivillages" class="edit-profile-btn"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </div>
          <div v-else style="margin-top: 2%">
            <v-row>
              <v-col md="12">
                <v-autocomplete
                  ref="clientSearch"
                  disabled
                  multiple
                  outlined
                  label="Select Clients"
                  :items="clients"
                  item-text="name"
                  item-value="id"
                  v-model="clientPrivillages"
                  @change="getAccounts"
                >
                 <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                     color="#7cab9a54"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
             
              <v-col md="12">
                <v-autocomplete
                  disabled
                  ref="accountsearch"
                  v-model="accountPrivillages"
                  outlined
                  chips
                  :items="accounts"
                  :item-value="'id'"
                  :item-text="'name'"
                  @change="getCompanies"
                  label="Select Account"
                  multiple
                  :search-input.sync="accountsearch"
                >
                 <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                     color="#7cab9a54"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
               <v-col md="12">
                <v-autocomplete
                  disabled
                  v-model="companyPrivillages"
                  outlined
                  chips
                  :items="companies"
                  :item-value="'id'"
                  :item-text="'name'"
                  @change="getLocations"
                  label="Select Company"
                  multiple
                  :search-input.sync="companysearch"
                  ref="companysearch"
                >
                <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                     color="#7cab9a54"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col md="12">
                <v-autocomplete
                  disabled
                  v-model="locationPrivillages"
                  outlined
                  chips
                  :items="locations"
                  :item-value="'id'"
                  :item-text="'name'"
                  label="Select Location"
                  multiple
                  :search-input.sync="locationSearch"
                  ref="locationSearch"
                >
                 <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                     color="#7cab9a54"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>

           
          </div>
        </div>
      </div>

    
    </div>
  </div>
</template>
<script>
import SideMenu from "@/components/SettingsSideMenu.vue";
import { dataService } from "@/services/data-service";
import store from "@/store";
import { required, email } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      editModeSyatemSettings: false,
      editClientsSettings: false,
      editMode: false,
      profilepic: "",
      profileName: "",
      locationTerm: "",
      accountTerm: "",
      companyTerm: "",
      clientPrivillages: [],
      accountPrivillages: [],
      locationPrivillages: [],
      companyPrivillages: [],

      userProfile: {
        rolIds: [],
      },
      selectedClients: [],
      selectedAccounts: [],
      selectedCompanies: [],
      selectedLocations: [],
      clients: [],
      accounts: [],
      companies: [],
      locations: [],
      submitted: false,
      queryTerm: "",
      userPermissions: [],
      permissions: [],
      newArr: [],
      allGroups: [],
      tab: null,
      accessLevels: [
        {
          id: 1,
          name: "Client",
        },
        {
          id: 2,
          name: "Account",
        },
        {
          id: 3,
          name: "Company",
        },
        {
          id: 4,
          name: "Branch",
        },
      ],
      userTypes: [
        {
          id: 201,
          name: "AXA Admin",
        },
        {
          id: 202,
          name: "AXA Rep",
        },
        {
          id: 203,
          name: " Client Admin",
        },
        {
          id: 204,
          name: " Client Rep",
        },
        {
          id: 205,
          name: " Technician",
        },
      ],
      groupIds: [],
      tempRoles: [],
      user: {
        email: "",
        userTypes: [],
        rolIds: [],
        privileges: [],
      },
    };
  },
  validations: {
    userProfile: {
      email: { required, email },
      firstName: { required },
      lastName: { required },
    },
    user: {
      email: { required, email },
    },
    accessLevelsFeilds: {
      $each: {
        levelID: { required },
        data: { required },
        dataIds: { required },
      },
    },
  },

  methods: {
    formatPhoneNumber() {

      
        var cleanedprimaryphonenumber = ('' + this.userProfile.phoneNumber).replace(/\D/g, '');
        var matchprimaryphonenumber = cleanedprimaryphonenumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (matchprimaryphonenumber) {
          var intlCodeprimaryphonenumber = (matchprimaryphonenumber[1] ? '+1 ' : '');
          this.userProfile.phoneNumber = [intlCodeprimaryphonenumber, '(', matchprimaryphonenumber[2], ') ', matchprimaryphonenumber[3], '-', matchprimaryphonenumber[4]].join('');
        }
      
     

    },
    //======================================
    RemoveClient(item) {
      const index = this.clientPrivillages.indexOf(item);
      if (index >= 0) this.clientPrivillages.splice(index, 1);
    },
    RemoveCompany(item) {
      const index = this.companyPrivillages.indexOf(item);
      if (index >= 0) this.companyPrivillages.splice(index, 1);
    },
    RemoveAccount(item) {
      const index = this.accountPrivillages.indexOf(item);
      if (index >= 0) this.accountPrivillages.splice(index, 1);
    },
    RemoveLocation(item) {
      const index = this.locationPrivillages.indexOf(item);
      if (index >= 0) this.locationPrivillages.splice(index, 1);
    },
    getUserProfile() {
      var self = this;
      dataService.getAll(
        "api/SystemUsers/" + this.$route.params.userId,

        (response) => {
          self.userProfile = response.data;
           this.clientPrivillages=[];
         this.companyPrivillages=[];
          this.accountPrivillages=[];
           this.locationPrivillages=[];
          self.profileName =
            self.userProfile.firstName.charAt(0).toUpperCase() +
            self.userProfile.firstName.slice(1) +
            " " +
            self.userProfile.lastName.charAt(0).toUpperCase() +
            self.userProfile.lastName.slice(1);
          self.profilepic =
            self.userProfile.firstName.charAt(0).toUpperCase() +
            self.userProfile.lastName.charAt(0).toUpperCase();
          if (self.userProfile) {
            for (
              var p = 0;
              p < this.userProfile.sytemUserPrivileges.length;
              p++
            ) {
              if (self.userProfile.sytemUserPrivileges[p].keyTypeId == 1) {
                self.clientPrivillages.push(
                  self.userProfile.sytemUserPrivileges[p].keyId
                );
              }
              if (self.userProfile.sytemUserPrivileges[p].keyTypeId == 2) {
                self.accountPrivillages.push(
                  self.userProfile.sytemUserPrivileges[p].keyId
                );
              }
              if (self.userProfile.sytemUserPrivileges[p].keyTypeId == 3) {
                self.companyPrivillages.push(
                  self.userProfile.sytemUserPrivileges[p].keyId
                );
              }
              if (self.userProfile.sytemUserPrivileges[p].keyTypeId == 4) {
               //debugger; // eslint-disable-line no-debugger
                self.locationPrivillages.push(
                  
                  self.userProfile.sytemUserPrivileges[p].keyId
                );
              }
            }

            if (self.userProfile.roles) {
              for (var r = 0; r < self.userProfile.roles.length; r++) {
                this.groupIds.push(self.userProfile.roles[r].id);
              }
              var userRoles = self.userProfile.roles;
              for (var t = 0; t < this.permissions.length; t++) {
                for (var pg = 0; pg < this.permissions[t].groups.length; pg++) {
                  for (var ur = 0; ur < userRoles.length; ur++) {
                    if (this.permissions[t].groups[pg].id == userRoles[ur].id) {
                      this.permissions[t].groups[pg].checkBox = true;
                      this.user.rolIds.push(userRoles[ur].roles);
                    }
                  }
                }
              }
            }
          }
        }
      );
    },

    pushRoles(group) {
  
      if (group.checkBox == true) {
        this.groupIds.push(group.id);
      } else {
        const index = this.groupIds.indexOf(group.id);
        if (index >= 0) this.groupIds.splice(index, 1);
      }
    },
    getUseType() {
      dataService.getAll(
        "api/Enums/Axa-User",
      
        (response) => {
          this.userTypes = response.data;

          //console.log( this.userTypes,"types")
        }
      );
    },
    getGroups() {
      this.permissions = [];
      this.groupIds = [];
      dataService.getAll("api/SystemUsers/groups", (response) => {
        var allPermissions = response.data;
     
        for (var pr = 0; pr < allPermissions.length; pr++) {
    
          this.permissions.push({
            id: allPermissions[pr].id,
            name: allPermissions[pr].name,
            groups: allPermissions[pr].groups,
          });
        }
    
     
        if (this.userProfile.systemUserTypes) {
          dataService.getAll(
            "api/SystemUsers/role-by_systemtype/" +
              this.userProfile.systemUserTypes[0].id,
            (response) => {
              var userRoles = response.data.data;
              for (var t = 0; t < this.permissions.length; t++) {
                for (var pg = 0; pg < this.permissions[t].groups.length; pg++) {
                  for (var ur = 0; ur < userRoles.length; ur++) {
                    if (this.permissions[t].groups[pg].id == userRoles[ur].id) {
                      this.permissions[t].groups[pg].checkBox = true;
                      // this.user.rolIds.push(userRoles[ur].roles);
                    }
                  }
                }
              }
            }
          );
        }
      });
    },

    //========
    updateProfileData() {
   
      let obj = {
        isCurrentUser: false,
        userId: this.$route.params.userId,
        firstName: this.userProfile.firstName,
        lastName: this.userProfile.lastName,
        phoneNumber: this.userProfile.phoneNumber,
        address: this.userProfile.address,
        email: this.userProfile.email,
      };
      dataService.update("api/SystemUsers/update-user", obj, () => {
        //this.$router.push("/settings/EmailSettings");
     this.editMode=false;
     this.getUserProfile();
      });
    },
    //==================================================================
    updateDataPrivillages() {
      let obj = {
        userId: this.$route.params.userId,
        privileges: [],
      };
     //debugger; // eslint-disable-line no-debugger

      if (this.locationPrivillages.length > 0) {
        for (var l = 0; l < this.locationPrivillages.length; l++) {
          if (this.locationPrivillages[l].id) {
            obj.privileges.push({
              keyId: this.locationPrivillages[l].id,
              keyTypeId: 4,
            });
          } else {
            obj.privileges.push({
              keyId: this.locationPrivillages[l],
              keyTypeId: 4,
            });
          }
        }
      }
     else if (
        this.locationPrivillages.length == 0 &&
        this.companyPrivillages.length > 0
      ) {
        for (var c = 0; c < this.companyPrivillages.length; c++) {
          if (this.companyPrivillages[c].id) {
            obj.privileges.push({
              keyId: this.companyPrivillages[c].id,
              keyTypeId: 3,
            });
          } else {
            obj.privileges.push({
              keyId: this.companyPrivillages[c],
              keyTypeId: 3,
            });
          }
        }
      }
    else  if (
        this.locationPrivillages.length == 0 &&
        this.companyPrivillages.length == 0 &&
        this.accountPrivillages.length > 0
      ) {
        for (var a = 0; a < this.accountPrivillages.length; a++) {
          if (this.accountPrivillages[a].id) {
            obj.privileges.push({
              keyId: this.accountPrivillages[a].id,
              keyTypeId: 2,
            });
          } else {
            obj.privileges.push({
              keyId: this.accountPrivillages[a],
              keyTypeId: 2,
            });
          }
        }
      }

      else if (
        this.locationPrivillages.length == 0 &&
        this.companyPrivillages.length == 0 &&
        this.accountPrivillages.length == 0 &&
        this.clientPrivillages.length > 0
      ) {
        for (var t = 0; t < this.clientPrivillages.length; t++) {
          if (this.clientPrivillages[t].id) {
            obj.privileges.push({
              keyId: this.clientPrivillages[t].id,
              keyTypeId: 1,
            });
          } else {
            obj.privileges.push({
              keyId: this.clientPrivillages[t],
              keyTypeId: 1,
            });
          }
        }
      }
     //debugger; // eslint-disable-line no-debugger
      dataService.update("api/SystemUsers/update-user-privilege", obj, async() => {
 
        this.editClientsSettings=false;
       
    await this.getUserProfile();
        //this.$router.push("/settings/EmailSettings");
      });
    },

    updatePermission() {
      let objupdateusergroup = {
        userId: this.$route.params.userId,
        userTypes: [this.userProfile.systemUserTypes[0].id],
        groupsIds: this.groupIds,
      };
      dataService.update(
        "api/SystemUsers/update-user-groups",
        objupdateusergroup,
        () => {
              this.editModeSyatemSettings=false;
  this.getUserProfile();
          //this.$router.push("/settings/EmailSettings");
        }
      );
    },
    check() {

      this.submitted = true;
      this.$v.userProfile.$touch();
      if (this.$v.userProfile.$invalid) {
        return;
      } else {
        var tempPrivileges = [];

        if (this.locationPrivillages.length > 0) {
          for (var l = 0; l < this.locationPrivillages.length; l++) {
            tempPrivileges.push({
              keyId: this.locationPrivillages[l],
              keyTypeId: 4,
            });
          }
        }
       else if (
          this.locationPrivillages.length == 0 &&
          this.companyPrivillages.length > 0
        ) {
          for (var c = 0; c < this.companyPrivillages.length; c++) {
            tempPrivileges.push({
              keyId: this.companyPrivillages[c],
              keyTypeId: 3,
            });
          }
        }
        else if (
          this.locationPrivillages.length == 0 &&
          this.companyPrivillages.length == 0 &&
          this.accountPrivillages.length > 0
        ) {
          for (var a = 0; a < this.accountPrivillages.length; a++) {
            tempPrivileges.push({
              keyId: this.accountPrivillages[a],
              keyTypeId: 2,
            });
          }
        }

     else 
       if (
          this.locationPrivillages.length == 0 &&
          this.companyPrivillages.length == 0 &&
          this.accountPrivillages.length == 0 &&
          this.clientPrivillages.length > 0
        ) {
          for (var t = 0; t < this.clientPrivillages.length; t++) {
            tempPrivileges.push({
              keyId: this.clientPrivillages[t],
              keyTypeId: 1,
            });
          }
        }
       //debugger; // eslint-disable-line no-debugger

        this.userProfile.privileges = tempPrivileges;

        // this.userProfile.privileges = this.userProfile.sytemUserPrivileges;
        this.userProfile.userId = this.$route.params.userId;
        this.userProfile.userTypes = [this.userProfile.systemUserTypes[0].id];
        this.userProfile.rolIds = this.userProfile.roles;
        this.userProfile.groupIds = this.groupIds;

        this.userProfile.roles = [];
        var tempArr = [];
        for (var r = 0; r < this.userProfile.rolIds.length; r++) {
          if (
            this.userProfile.rolIds[r].roles &&
            this.userProfile.rolIds[r].roles.length > 0
          ) {
            for (var p = 0; p < this.userProfile.rolIds[r].roles.length; p++) {
              tempArr.push(this.userProfile.rolIds[r].roles[p]);
            }
          } else if (this.userProfile.rolIds[r].roleId) {
            tempArr.push(this.userProfile.rolIds[r].roleId);
          }
        }
        this.userProfile.rolIds = tempArr;

        this.userProfile.sytemUserPrivileges = [];
        dataService.create(
          "api/SystemUsers/roles-update",
          this.userProfile,
          (response) => {
            if (response.data.succeeded == true) {
              store.commit("SHOW_MESSAGE", {
                text: "Updated Successfully",
                color: "success",
                timeout: 3000,
              });
              //   this.$router.push("/settings/users");
            } else {
              store.commit("SHOW_MESSAGE", {
                text: "error !",
                color: "error",
                timeout: 3000,
              });
            }
          }
        );
      }
    },
    getClients() {
      dataService.getAll(
        "api/Clients/get-all",

        (response) => {
          this.clients = response.data;
        }
      );
    },
    async loadAccounts() {
      this.accounts = await this.getAllAccounts(this.accountTerm || "");
    },
    async loadLocations() {
      this.locations = await this.getAllLocations(this.locationTerm || "");
    },
    async loadCompanies() {
      this.companies = await this.getAllCompanies(this.companyTerm || "");
    },
    getAccounts() {
      dataService.create(
        "api/Accounts/clients",
        this.clientPrivillages,
        (response) => {
          this.accounts = response.data.data;
        }
      );
    },
    getCompanies() {
      dataService.create(
        "api/Companies/accounts",
        this.accountPrivillages,
        (response) => {
          this.companies = response.data.data;
       
          // for (var c = 0; c < this.companies.length; c++) {
          //   this.companyPrivillages.push(this.companies[c]);
          // }
        }
      );
    },
    getLocations() {
      dataService.create(
        "api/Branches/companies",
        this.companyPrivillages,
        (response) => {
          this.locations = response.data.data;
        }
      );
    },
    updatePrivillages(index) {
      for (var p = 0; p < this.accessLevelsFeilds[index].dataIds.length; p++) {
        this.user.privileges.push({
          keyId: this.accessLevelsFeilds[index].levelID,
          keyTypeId: this.accessLevelsFeilds[index].dataIds[p],
        });
      }
      var makeUnique = (array = [], keys = []) => {
        if (!keys.length || !array.length) return [];
        return array.reduce((list, item) => {
          const hasItem = list.find((listItem) =>
            keys.every((key) => listItem[key] === item[key])
          );
          if (!hasItem) list.push(item);
          return list;
        }, []);
      };
      this.newArr = makeUnique(this.user.privileges, ["keyId", "keyTypeId"]);
    },
    getAllAccounts(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyAccounts(value);
        }, 1000);
        self.$nextTick(() => self.$refs.accountsearch.focus());
      } else {
        self.applyAccounts(value);
      }
    },
    applyAccounts(value) {
      dataService.getList(
        "api/Accounts/search_by_name",
        {
          pageNumber: 1,
          pageSize: 500,
          subString: value,
        },
        (response) => {
          this.accounts = response.data.data.items;
        }
      );
    },
    getAllCompanies(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyCompanies(value);
        }, 1000);
        self.$nextTick(() => self.$refs.companysearch.focus());
      } else {
        self.applyCompanies(value);
      }
    },
    applyCompanies(value) {
      dataService.getList(
        "api/Companies/search_by_name",
        {
          pageNumber: 1,
          pageSize: 1000,
          subString: value,
        },
        (response) => {
          this.companies = response.data.data.items;
        }
      );
    },
    getAllLocations(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyLocations(value);
        }, 1000);
        this.$nextTick(() => this.$refs.locationSearch.focus());
      } else {
        self.applyLocations(value);
      }
    },
    applyLocations(value) {
      dataService.getList(
        "api/Branches/search_by_name",
        {
          pageNumber: 1,
          pageSize: 5000,
          subString: value,
        },
        (response) => {
          this.locations = response.data.data.items;
        }
      );
    },
  },
  components: {
    SideMenu,
  },
  computed: {
    accountsearch: {
      get() {
        return this.accountTerm;
      },

      set(searchInput) {
        if (this.accountTerm !== searchInput) {
          this.accountTerm = searchInput;
          this.loadAccounts();
        }
      },
    },
    locationSearch: {
      get() {
        return this.locationTerm;
      },

      set(searchInput) {
        if (this.locationTerm !== searchInput) {
          this.locationTerm = searchInput;
          this.loadLocations();
        }
      },
    },

    companysearch: {
      get() {
        return this.companyTerm;
      },

      set(searchInput) {
        if (this.companyTerm !== searchInput) {
          this.companyTerm = searchInput;
          this.loadCompanies();
        }
      },
    },
  },
  async mounted() {
    this.getUseType();
    await this.getGroups();
    this.getClients();
    await this.getUserProfile();
  },
  created() {
    this.loadAccounts();
    this.loadLocations();
    this.loadCompanies();
  },
};
</script>