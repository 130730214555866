<template>
  <div class="upload-section" id="users">
    <SideMenu v-if="!$vuetify.breakpoint.xsOnly"></SideMenu>
    <div class="user-creation ml-4 mt-4">
      <div>
        <p class="table-title">Users List</p>
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              solo
              label="Search... "
              append-icon="mdi-magnify"
              v-model="search"
              @keyup="realSearch(1)"
              ref="objet"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3"> </v-col>
          <v-col cols="12" md="3"> </v-col>
          <v-col cols="12" md="3">
            <v-btn to="/users/create" class="btn-creation creation-style"
              >Create User</v-btn
            >
          </v-col>
        </v-row>
      </div>
      <div>
        <v-data-table
          :headers="headers"
          :items="data"
          class="elevation-1"
          item-key="id"
          hide-default-footer
          :items-per-page="pagination.pageSize"
          show-select
          v-model="selectedUsers"
        >
          <template v-if="selectedUsers.length != 0" v-slot:top>
            <div>
              <v-row class="wo-actions">
                <v-col md="4" class="border-item">
                  <v-btn
                    v-if="selectedUsers.length > 0"
                    class="btn-creation create-user-btn"
                    @click="sendResetEmails"
                    >Send Reset Password Email</v-btn
                  >
                </v-col>
                <v-col md="4" class="border-item">
                  <v-btn
                    v-if="selectedUsers.length > 0"
                    class="btn-creation create-user-btn"
                    @click="sendWelcomeEmail"
                    >Send Welcome Email</v-btn
                  >
                </v-col>

                <v-col md="4">
                  <v-switch
                    class="ml-3"
                    v-model="faSwitch"
                    inset
                    label="Two-factor authentication"
                    color="#2A8D3D"
                  ></v-switch>
                </v-col>
              </v-row>
            </div>
          </template>

          <template v-slot:footer>
            <v-row class="mt-5 fit-width">
              <v-col md="2">
                <v-select
                  :items="sizeOptions"
                  v-model="pagination.pageSize"
                  :item-value="'id'"
                  :item-text="'name'"
                  @change="realSearch(1)"
                  solo
                ></v-select>
              </v-col>
              <v-col md="5">
                <v-pagination
                  v-model="pagination.pageNumber"
                  :length="totalPages"
                  @input="realSearch(pagination.pageNumber)"
                  total-visible="6"
                ></v-pagination>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.systemUserTypes="{ item }">
            <p v-for="(ph, index) in item.systemUserTypes" :key="index">
              <span>{{ ph.name }}</span>
            </p>
          </template>
          <template v-slot:item.account="{ item }">
            <p>
              <span>{{ item.account.name }}</span>
            </p>
          </template>
          <template v-slot:item.actions="{ item }">
         
            <v-icon small class="mr-2 edit-icon" @click="editItem(item)">
              far fa-edit
            </v-icon>
            <v-icon class="delete-icon" small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import { dataService } from "@/services/data-service";
import SideMenu from "@/components/SettingsSideMenu.vue";
import store from "@/store";

export default {
  data() {
    return {
      faSwitch: true,
      selectedUsers: [],
      sizeOptions: [
        {
          id: 10,
          name: 10,
        },
        {
          id: 20,
          name: 20,
        },
        {
          id: 30,
          name: 30,
        },
        {
          id: 50,
          name: 50,
        },
        {
          id: 100,
          name: 100,
        },
      ],
      totalPages: 0,
      pagination: {
        pageNumber: 1,
        pageSize: 10,
      },
      search: null,
      items: ["foo", "bar", "fizz", "buzz"],
      value: null,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Mobile", value: "mobile" },
        { text: "Email", value: "email" },
        { text: "Type", value: "systemUserTypes" },
        { text: "Actions", value: "actions" },
      ],
      data: [],
      dialogDelete: false,
      itemID: null,
    };
  },
  methods: {
    sendWelcomeEmail() {
      var userIds = [];
      for (var u = 0; u < this.selectedUsers.length; u++) {
        userIds.push(this.selectedUsers[u].id);
      }
      dataService.create(
        "api/Authentication/send-welcome-mail",
        userIds,
        (response) => {
          if (response.data.succeeded) {
            store.commit("SHOW_MESSAGE", {
              text: "Emails are sent Successfully",
              color: "success",
              timeout: 3000,
            });
          }
        }
      );
    },
    sendResetEmails() {
      var userIds = [];
      for (var u = 0; u < this.selectedUsers.length; u++) {
        userIds.push(this.selectedUsers[u].id);
      }

      dataService.create(
        "api/SystemUsers/reset-Passwrod",
        { userIds: userIds },
        (response) => {
          if (response.data.succeeded) {
            store.commit("SHOW_MESSAGE", {
              text: "Emails are sent Successfully",
              color: "success",
              timeout: 3000,
            });
          }
        }
      );
    },
    editItem(item) {
      this.$router.push("/users/edit/" + item.id);
    },
    deleteItemConfirm() {
      dataService.delete(
        "api/SystemUsers/delete",
        {
          userId: this.itemID,
        },
        (response) => {
          if (response.data.succeeded) {
            store.commit("SHOW_MESSAGE", {
              text: "Deleted Successfully",
              color: "success",
              timeout: 3000,
            });
            this.dialogDelete = false;
            this.getUsers();
          }
        }
      );
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteItem(item) {
      this.itemID = item.id;
      this.dialogDelete = true;
    },

    realSearch(size) {
      // clear timeout variable
      clearTimeout(this.timeout);
      var self = this;
      self.pagination.pageNumber = size;
      this.timeout = setTimeout(function () {
        // enter this block of code after 1 second
        //  call search API .

        dataService.getList(
          "api/SystemUsers/search_by_fname_or_lname",
          {
            pageNumber: size,
            pageSize: self.pagination.pageSize,
            subString: self.search,
          },
          (response) => {
            self.data = response.data.data.items;
            self.totalPages = response.data.data.totalPages;
          }
        );
      }, 1000);
      self.$nextTick(() => self.$refs.objet.focus());
    },
    getUsers() {
      dataService.getList(
        "api/SystemUsers/get_all",
        this.pagination,
        (response) => {
          this.totalPages = response.data.data.totalPages;
          this.data = response.data.data.items;
        }
      );
    },
  },
  mounted() {
    this.getUsers();
  },
  components: {
    SideMenu,
  },
};
</script>