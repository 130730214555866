var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload-section", attrs: { id: "users" } },
    [
      !_vm.$vuetify.breakpoint.xsOnly ? _c("SideMenu") : _vm._e(),
      _c(
        "div",
        { staticClass: "user-creation ml-4 mt-4" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { md: "9" } }),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-creation creation-style mb-4",
                      attrs: { to: "/settings/smtp" },
                    },
                    [_vm._v("Create SMTP")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.data,
                  "item-key": "id",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-2 edit-icon",
                            attrs: { small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.editItem(item)
                              },
                            },
                          },
                          [_vm._v(" far fa-edit ")]
                        ),
                        _c(
                          "v-icon",
                          {
                            staticClass: "delete-icon",
                            attrs: { small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteItem(item)
                              },
                            },
                          },
                          [_vm._v(" mdi-delete ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "500px" },
                  model: {
                    value: _vm.dialogDelete,
                    callback: function ($$v) {
                      _vm.dialogDelete = $$v
                    },
                    expression: "dialogDelete",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", { staticClass: "text-h5" }, [
                        _vm._v("Are you sure you want to delete this item?"),
                      ]),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "blue darken-1", text: "" },
                              on: { click: _vm.closeDelete },
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "blue darken-1", text: "" },
                              on: { click: _vm.deleteItemConfirm },
                            },
                            [_vm._v("OK")]
                          ),
                          _c("v-spacer"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }