<template>
  <div id="report-timsheet" class="handle-test">
    <v-row>
      <v-col cols="12" md="10">
        <p class="table-title">Invoices List</p>
      </v-col>
      <v-col md="1" cols="12"> </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4" class="pad0">
        <v-text-field
          solo
          label="Search "
          append-icon="mdi-magnify"
          v-model="requestBody.subString"
          @keyup="getData()"
          ref="objet"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="8" class="pad0">
        <v-row>
          <v-col cols="12" md="4">
            <v-menu
              ref="menu"
              v-model="menuStartDate"
              :close-on-content-click="false"
              :return-value.sync="SDFrom"
              transition="scale-transition"
              offset-y
              min-width="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                hint="MM/DD/YYYY format"
                @blur="SDFrom = parseDate(SDFrom)"
                v-model="dateFormatted"
                  label="Start Date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="SDFrom"
                no-title
                scrollable
                @input="$refs.menu.save(SDFrom)"
                @change="getTimesheetByDate"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuStartDate = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(SDFrom)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4">
            <v-menu
              ref="menu2"
              v-model="menuEndDate"
              :close-on-content-click="false"
              :return-value.sync="SDTo"
              transition="scale-transition"
              offset-y
              min-width="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                hint="MM/DD/YYYY format"
                @blur="SDTo = parseDate(SDTo)"
                v-model="dateFormattedEnd"
              
                  label="End Date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="SDTo"
                no-title
                scrollable
                @input="$refs.menu2.save(SDTo)"
                @change="getTimesheetByDate"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuEndDate = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu2.save(SDTo)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              multiple
              outlined
              label="Select Clients"
              :items="clients"
              item-text="name"
              item-value="id"
              v-model="clientIds"
              @change="handleClientFilter"
            >
             <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeClient(data.item.id)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" >
           <v-row style="margin-bottom: 2%;"> 
          <v-col  cols="4" md="4">
           <p style="padding-left: 18%;" ><img  src="@/assets/images/All.png" /></p>
            <v-btn @click="GetAll()" class="filter-btn all-btn">{{
              jellyBeans.allCount
            }}</v-btn>
          </v-col>
          <v-col  cols="4" md="4">
             <p  ><img  src="@/assets/images/Pending.png" /></p>
            <v-btn @click="GetPending()" class="filter-btn assigned-btn">{{
              jellyBeans.pendingCount
            }}</v-btn>
          </v-col>

          <v-col cols="4" md="4">
            <p  style="padding-left: 15%;" ><img  src="@/assets/images/Paid.png" /></p>
            <v-btn @click="GetPaid()" class="filter-btn fh-btn">{{
              jellyBeans.paidCount
            }}</v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="8" class="pad0">
        <v-row>
          <v-col cols="12" md="4"> </v-col>
          <v-col cols="12" md="4">
          
          </v-col>
          <v-col cols="12" md="4">
            <v-btn
              @click="reset"
              class="btn-creation diff-style mt-1"
              color="#D92020"
              >Reset Filter</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
<br/>

    <v-data-table
      hide-default-footer
      :loading="isLoaded"
      loading-text="Loading... Please wait"
      @click:row="rowClick"
      @update:options="handleSorting"
      :headers="headers"
      :items="data"
      :server-items-length="totalLength"
      class="elevation-1"
      v-model="requestBody.pageSize"
      @change="resetPageNumber()"
    >

  
               <template v-slot:item.totalBillRate="{ item }">
                <p>
                  {{ "$ "+item.totalBillRate }}
                </p>
              </template>
              <template v-slot:item.totalExpenses="{ item }">
                <p>
                  {{ "$ "+item.totalExpenses}}
                </p>
              </template>
              <template v-slot:item.totalHours="{ item }">
                <p>
                  {{ item.totalHours+" hr" }}
                </p>
              </template>



      <template v-slot:item.creationDate="{ item }">
        <p>{{ new Date(item.creationDate).toLocaleString() }}</p>
      </template>
      <template v-slot:footer>
        <v-row class="mt-5 fit-width">
          <v-col md="2">
            <v-select
              :items="sizeOptions"
              v-model="requestBody.pageSize"
              :item-value="'id'"
              :item-text="'name'"
              @change="resetPageNumber()"
              solo
            ></v-select>
          </v-col>
          <v-col md="5">
            <v-pagination
              v-model="requestBody.pageNumber"
              :length="totalPages"
              @input="getData()"
              total-visible="6"
            >
            </v-pagination>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { dataService } from "@/services/data-service";
import store from "@/store";

export default {
  data: () => ({
    dateFormatted:null,
    dateFormattedEnd:null,
    isLoaded: true,
    menuStartDate: false,
    menuEndDate: false,
    SDTo: null,
    SDFrom: null,
    clients: [],
    clientIds: [],
    locations: [],
    locationIds: [],
    jellyBeans: {
      allCount: 0,
      paidCount: 0,
      pendingCount: 0,
    },
    search: "",
    sizeOptions: [
      {
        id: 10,
        name: 10,
      },
      {
        id: 20,
        name: 20,
      },
      {
        id: 30,
        name: 30,
      },
      {
        id: 50,
        name: 50,
      },
      {
        id: 100,
        name: 100,
      },
    ],

    headers: [
      // { text: "Invoice Id", value: "id" },
      { text: "Invoice Number", value: "invoiceNumber" },
      { text: "Creation Date", value: "creationDate" },
      { text: "Σ Billable Hours", value: "totalHours" },
      { text: "Σ Expenses Amount", value: "totalExpenses" },
      { text: "Σ Billable Amount", value: "totalBillRate" },
    
      { text: "Status", value: "status.name" },
    ],

    data: [],
    totalPages: 0,
    totalLength: 0,
    locationTerm: "",
    requestBody: {
      pageNumber: 1,
      pageSize: 10,
      subString: "",
      statusId: 3,
    },
  }),
//  components: {
//     'editor': Editor
//   },

  methods: {
    formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },

removeClient(item) {
      const index = this.clientIds.indexOf(item);
      if (index >= 0) this.clientIds.splice(index, 1);
        this.requestBody.branchIds = this.locationIds;
         this.getData();
    },


      handleSorting({ sortBy, sortDesc }) {
    

      if (sortBy.length > 0) {
        if (sortBy[0] == "invoiceNumber") this.requestBody.sortedColumnIndex = 0;
        if (sortBy[0] == "creationDate") this.requestBody.sortedColumnIndex = 1;
        if (sortBy[0] == "totalHours") this.requestBody.sortedColumnIndex = 2;

        if (sortBy[0] == "totalExpenses") this.requestBody.sortedColumnIndex = 3;
        if (sortBy[0] == "totalBillRate") this.requestBody.sortedColumnIndex = 4;
        if (sortBy[0] == "status.name")this.requestBody.sortedColumnIndex = 5;

        this.requestBody.isDec = sortDesc[0];
        this.getData();
      }
    },
    resetPageNumber() {
      this.requestBody.pageNumber = 1;
      this.getData();
    },

    GetPending() {
      this.requestBody.statusId = 1;

      this.getData();
    },
    GetAll() {
      this.requestBody.statusId = 3;

      this.getData();
    },
    GetPaid() {
      this.requestBody.statusId = 2;

      this.getData();
    },
    reset() {
   
      (this.menuStartDate = false),
        (this.menuEndDate = false),
        (this.SDTo = null),
        (this.SDFrom = null),
        (this.clientIds = []),
        (this.locationIds = []),
        (this.requestBody = {
          pageNumber: 1,
          pageSize: 10,
          subString: "",
          statusId: 3,
        });
      this.getData();
  
    },
    rowClick(e) {
    
      this.$router.push({ name: "showInvoice", params: { id: e.id } });
    },
    realSearch() {
      this.requestBody.pageNumber = 1;
      clearTimeout(this.timeout);
      var self = this;

      this.timeout = setTimeout(function () {
        self.getData();
      }, 1000);
    },
    getTimesheetByDate() {
      if (this.SDFrom && !this.SDTo) {
        return;
      }
      if (!this.SDFrom) {
        store.commit("SHOW_MESSAGE", {
          text: "Please Select Start Date first",
          color: "error",
          timeout: 3000,
        });
        return;
      } else {
        this.requestBody.creationDate = {
          from: this.SDFrom + "T00:00:00Z",
          to: this.SDTo + "T00:00:00Z",
        };

        this.getData();
      }
    },
    handleClientFilter() {
      this.requestBody.clientIds = this.clientIds;
      this.getData();
    },
    getClients() {
      dataService.getAll(
        "api/Clients/get-all",

        (response) => {
          this.clients = response.data;
        }
      );
    },
    handleLocationFilter() {
      this.requestBody.branchIds = this.locationIds;
      this.getData();
    },
    async loadLocations() {
      this.accounts = await this.getLocations(this.locationTerm || "");
    },
    getLocations(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyLocations(value);
        }, 1000);
      } else {
        self.applyLocations(value);
      }
    },
    applyLocations(value) {
      dataService.getList(
        "api/Branches/search_by_name",
        {
          pageNumber: 1,
          pageSize: 200,
          subString: value,
        },
        (response) => {
          this.locations = response.data.data.items;
          if (value) {
            this.$nextTick(() => this.$refs.locationSearch.focus());
          }
        }
      );
    },
    handleOptionsUpdate({ page, itemsPerPage }) {
      this.requestBody.pageNumber = page;
      this.requestBody.pageSize = itemsPerPage;
      if (itemsPerPage == -1) {
        this.requestBody.pageNumber = 1;
        this.requestBody.pageSize = this.totalLength;
      }
      this.getData();
    },
    getData() {
      this.isLoaded = true;
      dataService.getList(
        "api/Invoices/get-invoices",
        this.requestBody,
        (response) => {
          this.data = response.data.data.invoiceDetails;
          this.isLoaded = false;
          this.totalPages = response.data.data.totalPages;
          this.jellyBeans = response.data.data.jellyBeans;
        
          this.totalLength = response.data.data.totalCount;
          if (this.requestBody.subString) {
            this.$nextTick(() => this.$refs.objet.focus());
          }
        }
      );
    },
  },
  mounted() {
    this.getData();
    this.getClients();
  },
  created() {
    this.loadLocations();
  },
  watch: {
    SDFrom () {
        this.dateFormatted = this.formatDate(this.SDFrom)
      },
      SDTo(){
        this.dateFormattedEnd = this.formatDate(this.SDTo)
      }
    },
  computed: {
    computedStartDateFormatted () {
        return this.formatDate(this.SDFrom)
      },
      computedEndDateFormatted () {
        return this.formatDate(this.SDTo)
      },

    locationSearch: {
      get() {
        return this.locationTerm;
      },

      set(searchInput) {
        if (this.locationTerm !== searchInput) {
          this.locationTerm = searchInput;
          this.loadLocations();
        }
      },
    },
  },
};
</script>