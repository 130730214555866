<template>
  <div class="outer-page">
    <p class="timesheet-lable">Your Timesheet</p>
    <div>
      <div class="mb-6" v-for="(item, index) in timesheet" :key="item.id">
        <div class="top-box">
          <v-row>
            <v-col md="9">
              <p class="clocking-date">{{ item.date }}</p>
            </v-col>
            <v-col md="3">
        
            </v-col>
          </v-row>
        </div>
        <div class="clocking-box">
          <v-row>
            <v-col md="5">
              <v-text-field
                outlined
                class="description-feild"
                label="What are you working on?"
                v-model="item.scopeOfFinishWork"
              ></v-text-field>
            </v-col>
            <v-col md="1" id="time-box">
              <vue-timepicker
                v-model="item.startTime"
                format="HH:mm"
                manual-input
              ></vue-timepicker>
            </v-col>
            <v-col md="1" id="time-box">
              <vue-timepicker
                v-model="item.endTime"
                format="HH:mm"
                manual-input
              ></vue-timepicker>
            </v-col>
            <v-col md="1">
              <v-menu
                :ref="index"
                v-model="item.menu"
                :close-on-content-click="false"
                :return-value.sync="item.date"
                transition="scale-transition"
                offset-y
                min-width="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <i
                    class="fas fa-calendar-alt day-icon"
                    v-bind="attrs"
                    v-on="on"
                  ></i>
                </template>
                <v-date-picker
                  v-model="item.date"
                  @input="item.menu = false"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="item.menu = false">
                    Cancel
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col md="2" id="time-box">
              <vue-timepicker
                format="HH:mm:ss"
                v-model="item.total"
                manual-input
              ></vue-timepicker>
            </v-col>
            <v-col md="1">
              <v-icon
                @click="deleteItem(item)"
                class="delete-timesheet"
                color="#A23D3D"
                >mdi-delete</v-icon
              ></v-col
            >
          </v-row>
        </div>
      </div>
    </div>
    <div class="btn-container">
      <v-btn class="create-order-btn" @click="createTimeSheet"
        >Send To Approve</v-btn
      >
    </div>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this item From
          Timesheet?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import VueTimepicker from "vue2-timepicker";
import { dataService } from "@/services/data-service";
import store from "@/store";

export default {
  data() {
    return {
      submittedExpense: false,
      timesheet: [],
      
        expensesTypes: [],

      date: "",
      clockingIds: [],
      itemID: null,
      dialogDelete: false,
    };
  },
  methods: {
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteItem(item) {
      this.itemID = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      var removeIndex = this.timesheet
        .map((item) => item.id)
        .indexOf(this.itemID);

      ~removeIndex && this.timesheet.splice(removeIndex, 1);

      var clockindex = this.clockingIds
        .map((item) => item)
        .indexOf(this.itemID);

      ~clockindex && this.clockingIds.splice(clockindex, 1);
      this.dialogDelete = false;
    },

    createTimeSheet() {
      dataService.create(
        "api/WorkOrders/create-timesheet",
        {
          clockingIds: this.clockingIds,
        },
        (response) => {
          if (response.data.succeeded == true) {
            store.commit("SHOW_MESSAGE", {
              text: "Created Successfully",
              color: "success",
              timeout: 3000,
            });
          }
          var workOrderId = localStorage.getItem("WOID");
          this.$router.push("/clocking/" + workOrderId);
        }
      );
    },
    loadTimesheet() {
      this.clockingIds = [];
      this.timesheet = JSON.parse(this.$route.params.selectedClocks);
      for (var t = 0; t < this.timesheet.length; t++) {
        this.timesheet[t].date = this.timesheet[t].clockIn.split("T")[0];
        this.clockingIds.push(this.timesheet[t].id);
      }
    },
  },
  mounted() {
    this.loadTimesheet();
     this.getExpensesTypes();
  },
  components: {
    VueTimepicker,
  },
};
</script>