<template>
  <div>
    <v-row>
      <v-col md="3">
        <SideMenu></SideMenu>
      </v-col>
      <v-col md="9" class="mt-7 handle-sizing">
        <div>
        
          <div class="upload-section">
            <v-avatar color="#172450" style="width:70px;height:70px">
              <span v-if="profilepic" class="white--text text-h5"
                >{{profilepic}}</span
              >
            </v-avatar>
            <span class="Profile-data">
              {{profileName}}
              
            </span>
  <i
              @click="editMode = !editMode"
              class="fas fa-edit edit-profile"
            ></i>
          
          </div>
        </div>
        <v-divider class="mt-9 mb-3"></v-divider>
        <div>
          <v-row v-if="editMode != true">
            <v-col md="2">
              <p class="info-lebel">First Name</p>
              <p class="info-lebel">Last Name</p>
              <p class="info-lebel">Address</p>

              <p class="info-lebel">Email</p>
              <p class="info-lebel">Phone</p>
            </v-col>
            <v-col md="3">
              <p class="info-value">
                {{ profile.firstName }}
              </p>
              <p class="info-value">
                {{ profile.lastName }}
              </p>
              <p v-if="profile.address" class="info-value">
                {{ profile.address }}
              </p>
              <p v-else class="info-value">-</p>
              <p class="info-value">{{ profile.email }}</p>
              <p class="info-value">{{ profile.phoneNumber }}</p>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col md="2">
              <p class="info-lebel">First Name</p>
              <p class="info-lebel mt-11">Last Name</p>
              <p class="info-lebel mt-11">Address</p>
              <p class="info-lebel mt-11">Phone</p>
            </v-col>

            <v-col id="editProfile" md="3">
              <v-text-field
                class="mt-7"
                solo
                v-model="profile.firstName"
              ></v-text-field>
              <v-text-field solo v-model="profile.lastName"></v-text-field>
              <v-text-field solo v-model="profile.address"></v-text-field>
              <v-text-field @blur="formatPhoneNumber()"
               solo v-model="profile.phoneNumber"></v-text-field>


              <v-btn @click="updateProfile" class="edit-profile-btn"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SideMenu from "@/components/SettingsSideMenu.vue";
import { dataService } from "@/services/data-service";
import store from "@/store";

export default {
  data() {
    return {
      profilepic:"",
      profileName:"",
      profile: {},
      editMode: false,
    };
  },
  components: {
    SideMenu,
  },
  methods: {
     formatPhoneNumber() {
    
      
         
  var cleaned = ('' + this.profile.phoneNumber).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '');
   this.profile.phoneNumber= [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
      
      
     
    },
    getProfile() {
      dataService.getAll("api/SystemUsers/current", (response) => {
        this.profile = response.data;
         this.profileName= this.profile.firstName.charAt(0).toUpperCase() + this.profile.firstName.slice(1)+" "+this.profile.lastName.charAt(0).toUpperCase() + this.profile.lastName.slice(1);
             this.profilepic=this.profile.firstName.charAt(0).toUpperCase()+
                this.profile.lastName.charAt(0).toUpperCase() ;
                //console.log(this.profile)
      });
    },
    updateProfile() {
    
      let obj=
      {
        isCurrentUser:false,
        userId:this.profile.id,
        firstName:this.profile.firstName,
        lastName:this.profile.lastName,
        address:this.profile.address,
        phoneNumber:this.profile.phoneNumber


      }

 //{"isCurrentUser":true,"userId":21,"firstName":"merna","lastName":"azmy","address":null,"phoneNumber":"(424) 413-3789"}

     // this.profile.isCurrentUser=true;

      //console.log(this.profile)
      this.editMode = false; 
      dataService.update("api/SystemUsers/update-user", obj, (response) => {
         
        if (response.data.succedded) {
             
          store.commit("SHOW_MESSAGE", {
            text: "Updated Successfully",
            color: "success",
            timeout: 3000,
          });
       
        }
      });
    },
  },
  mounted() {
    this.getProfile();
  },
};
</script>