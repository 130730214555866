var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "settings" } },
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { height: "100vh", "mini-variant": _vm.mini, permanent: "" },
          on: {
            "update:miniVariant": function ($event) {
              _vm.mini = $event
            },
            "update:mini-variant": function ($event) {
              _vm.mini = $event
            },
          },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-icon",
                    [
                      !_vm.mini
                        ? _c("div", { staticClass: "user-info" }, [
                            _c("p", { staticStyle: { width: "209%" } }, [
                              _vm._v(_vm._s(_vm.userInfo.userName)),
                            ]),
                            _c("p", { staticStyle: { width: "209%" } }, [
                              _vm._v(_vm._s(_vm.userInfo.email)),
                            ]),
                          ])
                        : _vm._e(),
                      _c(
                        "v-icon",
                        {
                          staticClass: "pointer",
                          on: {
                            click: function ($event) {
                              _vm.mini = !_vm.mini
                            },
                          },
                        },
                        [_vm._v("fas fa-bars")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.userInfo.userTypeId == 201
                ? _c(
                    "div",
                    { staticClass: "mt-7" },
                    [
                      _c("v-divider", { staticClass: "pt-4" }),
                      _vm._l(_vm.items, function (item) {
                        return _c(
                          "div",
                          { key: item.title },
                          [
                            _c(
                              "v-list-item",
                              {
                                class: [
                                  _vm.$route.fullPath == item.to
                                    ? "active-item"
                                    : "",
                                ],
                                attrs: { link: "", to: item.to },
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        class: [
                                          _vm.$route.fullPath == item.to
                                            ? "active-color"
                                            : "",
                                        ],
                                        attrs: { color: "#5B5B5B" },
                                      },
                                      [_vm._v(_vm._s(item.icon) + " ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c(
                                      "v-list-item-title",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "side-menu-item",
                                            class: [
                                              _vm.$route.fullPath == item.to
                                                ? "active-color"
                                                : "",
                                            ],
                                            attrs: { to: item.to },
                                          },
                                          [_vm._v(_vm._s(item.title) + " ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                : _vm.userInfo.userTypeId == 204 ||
                  _vm.userInfo.userTypeId == 203 ||
                  _vm.userInfo.userTypeId == 202
                ? _c(
                    "div",
                    { staticClass: "mt-7" },
                    [
                      _c("v-divider", { staticClass: "pt-4" }),
                      _vm._l(_vm.itemsClientAdminRep, function (item) {
                        return _c(
                          "div",
                          { key: item.title },
                          [
                            _c(
                              "v-list-item",
                              {
                                class: [
                                  _vm.$route.fullPath == item.to
                                    ? "active-item"
                                    : "",
                                ],
                                attrs: { link: "", to: item.to },
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        class: [
                                          _vm.$route.fullPath == item.to
                                            ? "active-color"
                                            : "",
                                        ],
                                        attrs: { color: "#5B5B5B" },
                                      },
                                      [_vm._v(_vm._s(item.icon) + " ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c(
                                      "v-list-item-title",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "side-menu-item",
                                            class: [
                                              _vm.$route.fullPath == item.to
                                                ? "active-color"
                                                : "",
                                            ],
                                            attrs: { to: item.to },
                                          },
                                          [_vm._v(_vm._s(item.title) + " ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                : _c(
                    "div",
                    { staticClass: "mt-7" },
                    [
                      _c("v-divider", { staticClass: "pt-4" }),
                      _vm._l(_vm.itemsUser, function (item) {
                        return _c(
                          "div",
                          { key: item.title },
                          [
                            _c(
                              "v-list-item",
                              {
                                class: [
                                  _vm.$route.fullPath == item.to
                                    ? "active-item"
                                    : "",
                                ],
                                attrs: { link: "", to: item.to },
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        class: [
                                          _vm.$route.fullPath == item.to
                                            ? "active-color"
                                            : "",
                                        ],
                                        attrs: { color: "#5B5B5B" },
                                      },
                                      [_vm._v(_vm._s(item.icon) + " ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c(
                                      "v-list-item-title",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "side-menu-item",
                                            class: [
                                              _vm.$route.fullPath == item.to
                                                ? "active-color"
                                                : "",
                                            ],
                                            attrs: { to: item.to },
                                          },
                                          [_vm._v(_vm._s(item.title) + " ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }