<template>
  <div>
    <div v-if="isOpenB" class="test">
      <v-icon @click="click" class="btnbar">X</v-icon>
      <div class="px-3 py-2">
        <v-row>
          <v-col cols="12" md="8" class="FinRepo-sty">
            <p class="table-title">Financial Report</p>
          </v-col>
        </v-row>

        <!-- List Custom Date -->
        <p class="Report-color">Service date :</p>
        <br />
        <v-row>
          <v-col cols="12" md="12" style="margin-bottom: -14px" class="FinRepo-sty">
            <v-autocomplete class="" ref="clientSearch" multiple outlined label="Custom date" :items="clients"
              item-text="name" item-value="id" v-model="clientIds" @change="handleClientFilter">
            </v-autocomplete>
          </v-col>
        </v-row>

        <!-- From -->
        <v-row style="margin-bottom: -28px">
          <v-col cols="6" md="6" class="FinRepo-sty">
            <v-autocomplete class="" ref="DateFrom" prepend-inner-icon="mdi-calendar" multiple outlined label="From"
              :items="From" item-text="name" item-value="id" v-model="clientIds" @change="handleClientFilter">
            </v-autocomplete>
          </v-col>
          <!-- Date To -->
          <v-col cols="6" md="6" class="FinRepo-sty">
            <v-autocomplete class="" ref="DateTo" prepend-inner-icon="mdi-calendar" multiple outlined label="To"
              :items="To" item-text="name" item-value="id" v-model="clientIds" @change="handleClientFilter">
            </v-autocomplete>
          </v-col>
        </v-row>
        <hr />
        <br />
        <!-- ======= Payment Type ======= -->
        <v-row>
          <v-col cols="12" md="8" class="reportCols">
            <p class="Report-color" style="padding: 15px">Payment Type:</p>
            <br />
          </v-col>
          <v-col cols="12" md="12" class="pad0">
            <v-row>
              <v-col cols="12" md="4" class="reportCols" style="margin-bottom: -16px">
                <v-checkbox class="reportCols" v-model="isLongTerm" label="w2" color="#4B5568">
                </v-checkbox>
                <v-checkbox class="reportCols" v-model="isLongTerm" label="1099" color="#4B5568">
                </v-checkbox>
                <v-checkbox class="reportCols" v-model="isLongTerm" label="vendor" color="#4B5568">
                </v-checkbox>
                <v-checkbox class="reportCols" v-model="isLongTerm" label="TEMP" color="#4B5568">
                </v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <br />
        <!-- ============= Filter By technician  -->
        <hr />
        <br />
        <v-row>
          <v-col cols="12" md="8" class="">
            <p class="FinRepo-sty">Filter By technician</p>
          </v-col>
        </v-row>
        <!-- List TECH -->
        <v-col cols="12" md="12" class="FinRepo-sty">
          <v-row>
            <v-col cols="12" md="12">
              <v-autocomplete v-model="techIds" :items="technicians" class="mb-2" chips solo color="blue-grey lighten-2"
                label="Select a technician" :item-text="
                  (item) => item.firstName + ' ' + item.lastName
                " :item-value="'id'" multiple :search-input.sync="search" ref="techassign">
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-col>

        <!-- Grouped by Account -->
        <hr />
        <br />
        <v-row style="padding-top: 3%">
          <v-col cols="12" md="8" class="FinRepo-sty">
            <p class="">Grouped by Account</p>
          </v-col>
        </v-row>
        <v-col cols="12" md="12" class="FinRepo-sty">
          <v-row>
            <v-col cols="12" md="12">
              <v-autocomplete ref="accountSearch" :items="accounts" item-text="name" item-value="id"
                v-model="accountIds" multiple outlined label="select Accounts" :search-input.sync="accountsearch"
                @change="getData()"></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </div>
    </div>

    <!-- ========= second div ======= -->
    <div id="fixed" class="test2">
      <v-icon v-if="!isOpenB" @click="click">fa-regular fa-bars click</v-icon>
      <span style="margin: auto;">
        <span class="FinancialTab" style="text-align: center; padding: 20%">
          <span style="color: #1b7bf9">Report from</span> Jan 1,2022
          <span style="color: #1b7bf9">to</span> March 20,2022
          <span style="color: lightslategrey">Custom date</span>
        </span>

        <table style="text-align: center;margin: auto; margin-top: 5%;" class="FinancialTab table table-hover">
          <thead style="height: 50px">
            <tr>
              <th class="colsrowpad" scope="col">Account</th>
              <th class="colsrowpad" scope="col">Date</th>
              <th class="colsrowpad" scope="col">tech name / Type</th>

              <th class="colsrowpad" scope="col">Bill Rate</th>
              <th class="colsrowpad" scope="col">Pay rate</th>
            </tr>
          </thead>
          <tbody>


            <tr v-for="item in Report" :key="item.invoiceId">
              <td style="padding-right: 100px ">{{ item.accountName }}</td>
              <td style="padding-bottom: 4% !important;     padding-top: 4%;" class="colsrowpad  ">
                {{ item.invoiceCreationDate }}</td>
              <td style="padding-bottom: 4% !important;     padding-top: 4%;" class="colsrowpad  ">
                <div v-for="item2 in item.techPaymentTypeDtos" :key="item2.technicianName">
                  {{ item2.technicianName + " " + item2.paymentType }}
                </div>

              </td>

              <td style="padding-bottom: 4% !important;     padding-top: 4%;" class="colsrowpad">{{ item.totalBillRate }}
              </td>
              <td style="padding-bottom: 4% !important;     padding-top: 4%;" class="colsrowpad">{{ item.totalPayRates }}
              </td>
            </tr>
          </tbody>
        </table>
      </span>
    </div>
  </div>
</template>

<script>
import { dataService } from "@/services/data-service";
export default {
  data() {
    return {
      Report: {},
      isOpenB: false,
      accounts: [],
      accountIds: [],
      techIds: [],
      technicians: [],
    };
  },
  methods: {
    getTechnicians(queryTerm) {
      var self = this;
      if (queryTerm) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyTech(queryTerm);
        }, 1000);
      } else {
        self.applyTech(queryTerm);
      }
    },
    applyTech(value) {
      var self = this;
      dataService.getList(
        "api/Technicians/get-all",
        {
          pageNumber: 1,
          pageSize: 100,
          search: value,
        },
        (response) => {
          this.technicians = response.data.data.items;
          self.$nextTick(() => self.$refs.techassign.focus());
        }
      );
    },
    getData() {

      dataService.getList(
        "api/Reports/get-financial-report",
        {

        },
        (response) => {
          this.Report = response.data.data;
    

        }
      );

    },
    getAccounts(value) {
      var self = this;
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          self.applyAccounts(value);
        }, 1000);
      } else {
        self.applyAccounts(value);
      }
    },
    applyAccounts(value) {

      dataService.getList(
        "api/Accounts/search_by_name",
        {
          pageNumber: 1,
          pageSize: 100,
          subString: value,
        },
        (response) => {
          this.accounts = response.data.data.items;
          if (value) {
            this.$nextTick(() => this.$refs.accountSearch.focus());
          }
        }
      );
    },
    click() {
      this.isOpenB = !this.isOpenB;
      if (this.isOpenB) {
        const elem = document.getElementById("fixed");
        elem.style.width = "75%";
        elem.style.float = "right";
      } else {
        const elem = document.getElementById("fixed");
        elem.style.width = "100%";
        elem.style.float = "none";
      }
    },
    currentDateTime() {
      const current = new Date();
      const date =
        current.getFullYear() +
        "-" +
        (current.getMonth() + 1) +
        "-" +
        current.getDate();
      const time =
        current.getHours() +
        ":" +
        current.getMinutes() +
        ":" +
        current.getSeconds();
      const dateTime = date + " " + time;

      return dateTime;
    },
    fulldate() {
      const current = new Date();
      return current;
    },
    async loadEntries() {
      this.technicians = await this.getTechnicians(this.queryTerm || "");
    },
  },
  mounted() {
    this.applyTech("")
    this.getData()
    this.applyAccounts("")
  }
};
</script>
